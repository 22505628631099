import React, { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';
import { TariffVersion } from '../../state/tariff/tariffHistorySlice';
import { Genability, types } from '@genability/api';
import { GenApiClient } from '../../GenApiClient';
import { GetLookupsRequest } from '@genability/api/dist/api';
import { SortOrder } from '@genability/api/dist/rest-client';
import TariffRow, { RowColor } from './TariffRow';
import { Col, Row } from 'react-bootstrap';
import { formatDateMD, getValidDate, isBetweenDates } from '../../utils/dateUtils';
import SeasonChanges from './SeasonChanges';
import { TariffItemTypes } from './TariffItemTypeFilter';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/rootReducer';
import { padStart } from 'lodash';
import { DateTime } from 'luxon';
import {
  LoadServingEntity,
  LoadServingEntityState,
} from '../../state/loadServingEntity/loadServingEntitySlice';

interface TariffDetailsProps {
  tariff: types.Tariff;
  version: TariffVersion;
  seasonGroupIds: { lseId: number; seasonGroupId: number }[];
  dateFrom?: string;
  dateUpTo?: string;
  priorDate?: string;
  allLookupValues: types.LookupValue[];
  addLookupValues: (lookupValue: types.LookupValue[]) => void;
  selectedDates: { date: string; tariffId: number }[];
  onDateSelected: (event: ChangeEvent<HTMLInputElement>, tariffId: number) => void;
  maxSelectedDates?: number;
  selectedTariffTypes: Set<string>;
}

const TariffDetails: FC<TariffDetailsProps> = ({
  tariff,
  version,
  seasonGroupIds,
  dateFrom,
  dateUpTo,
  priorDate,
  allLookupValues,
  addLookupValues,
  selectedDates,
  onDateSelected,
  maxSelectedDates,
  selectedTariffTypes,
}) => {
  const dashEnv = process.env.REACT_APP_DASH_URL;
  const [lookupValues, setLookupValues] = useState<types.LookupValue[]>([]);
  const [rowDates, setRowDates] = useState<string[]>([]);
  const { seasonsByLse, loading: loadingSeasons } = useSelector(
    (state: RootState): LoadServingEntityState => state.loadServingEntity
  );
  const [seasonsChanges, setSeasonsChanges] = useState<
    { seasonChange: string; seasons: types.Season[] }[]
  >([]);

  const isTariffTypeSelected = (tariffType: string) => {
    const showAllTypes = selectedTariffTypes.size === 0;
    return showAllTypes || selectedTariffTypes.has(tariffType);
  };

  const getLookupValues = async (
    client: Genability,
    propertyKey: string,
    fromDate: string,
    toDate: string
  ) => {
    const request = new GetLookupsRequest();
    request.fromDateTime = fromDate;
    request.toDateTime = toDate;
    request.sortOn = ['fromDateTime'];
    request.sortOrder = [SortOrder.DESC];
    const { results } = await client.lookups.getPropertyLookupValues(propertyKey, request);
    // Remove zero values
    return results?.filter(value => value.actualValue);
  };

  const getSeasonsChanges = (fromDate: string, toDate: string | undefined) => {
    const seasonDates: { seasonChange: string; seasons: types.Season[] }[] = [];
    const fromYear = DateTime.fromISO(fromDate).year;
    const todayDate = DateTime.now().toISODate();
    const toYear = DateTime.fromISO(toDate ? toDate : todayDate).year;

    for (let year = fromYear; year <= toYear; year++) {
      seasonGroupIds.forEach(({ lseId, seasonGroupId }) => {
        const groupFound = seasonsByLse[lseId]?.find(item => item.seasonGroupId == seasonGroupId);
        const seasons = groupFound?.seasons || [];
        seasons.forEach(season => {
          const day = padStart(`${season.seasonFromDay}`, 2, '0');
          const month = padStart(`${season.seasonFromMonth}`, 2, '0');
          const seasonChange = `${year}-${month}-${day}`;
          if (seasonChange <= todayDate) {
            seasonDates.push({ seasonChange, seasons });
          }
        });
      });
    }
    return seasonDates;
  };

  const filterAllValueDates = (
    seasonsChanges: { seasonChange: string; seasons: types.Season[] }[]
  ) => {
    const lookupDates = isTariffTypeSelected(TariffItemTypes.LOOKUPS)
      ? lookupValues.map(value => value.fromDateTime)
      : [];
    const ridersDates = isTariffTypeSelected(TariffItemTypes.RIDER_REVISIONS)
      ? version.riderVersions
          .filter(rider => rider.riderId === rider.riderTariffId)
          .map(rider => rider.effectiveDate)
      : [];

    const seasonDates = seasonsChanges.map(seasons => seasons.seasonChange);
    const allDates: string[] = [...lookupDates, ...ridersDates, ...seasonDates];

    if (
      isTariffTypeSelected(TariffItemTypes.TARIFF_REVISIONS) ||
      isTariffTypeSelected(TariffItemTypes.SEASONS)
    ) {
      allDates.push(version.effectiveDate);
    }
    // filter dates between effectiveDate AND next tariff or uptoDate
    const filteredDates = allDates
      .map(date => date.slice(0, 10))
      .filter(
        date =>
          date >= version.effectiveDate &&
          (!priorDate || date < priorDate) &&
          isBetweenDates(getValidDate(date), getValidDate(dateFrom), getValidDate(dateUpTo))
      );

    const uniqueDateList = new Set(filteredDates);
    return [...uniqueDateList].sort().reverse();
  };

  const extractYearsDescending = (dateList: string[]): string[] => {
    return [...new Set(dateList.map(date => date.slice(0, 4)))].sort().reverse();
  };

  const isSameDay = (date1: string, date2: string): boolean => {
    return date1.slice(0, 10) === date2.slice(0, 10);
  };

  const getAllLookupValues = async () => {
    const genabilityClient = await GenApiClient();
    const thisLookupValues = await Promise.all(
      version.lookupVariableRates.map(
        async lookupVariable =>
          await getLookupValues(
            genabilityClient,
            lookupVariable.propertyKey,
            lookupVariable.fromDate,
            lookupVariable.toDate
          )
      )
    );
    const allLookupValuesFlat = thisLookupValues.flat();
    // Prevents `undefined` error from delayed request when the component is unmounted
    if (!allLookupValuesFlat.every(lookupValues => lookupValues)) {
      return;
    }
    setLookupValues(allLookupValuesFlat);
    addLookupValues(allLookupValuesFlat);
  };

  useEffect(() => {
    getAllLookupValues(); // Get all lookupValues
  }, []);

  useEffect(() => {
    const seasonsChanges = getSeasonsChanges(version.effectiveDate, priorDate);
    setSeasonsChanges(seasonsChanges);

    setRowDates(filterAllValueDates(seasonsChanges));
  }, [lookupValues, selectedTariffTypes, dateFrom, dateUpTo, loadingSeasons]);

  return (
    <>
      {extractYearsDescending(rowDates).map(year => (
        <Fragment key={year}>
          {year !== priorDate?.slice(0, 4) && (
            <Row>
              <Col>
                <h3>{year}</h3>
              </Col>
            </Row>
          )}
          {rowDates
            .filter(date => date.startsWith(year))
            .map(date => (
              <Row key={date}>
                <Col className="col-3">
                  <label>
                    <input
                      type="checkbox"
                      aria-label={date}
                      value={date}
                      checked={selectedDates.some(selected => selected.date === date)}
                      onChange={event => onDateSelected(event, version.tariffId)}
                      disabled={
                        !selectedDates.some(selected => selected.date === date) &&
                        maxSelectedDates !== undefined &&
                        maxSelectedDates === selectedDates.length
                      }
                    />
                    <span className="mx-2">{formatDateMD(date)}</span>
                  </label>
                </Col>
                <Col className="col-9 my-n1">
                  {isTariffTypeSelected(TariffItemTypes.LOOKUPS) &&
                    allLookupValues
                      .filter(value => isSameDay(value.fromDateTime, date))
                      .map(value => (
                        <TariffRow
                          key={`${value.propertyKey}-${value.fromDateTime}`}
                          rowStyle={RowColor.ColorLookupValue}
                          link={`/directory/property-keys/${value.propertyKey}`}
                        >
                          {value.propertyKey}: (${value.actualValue}){' '}
                        </TariffRow>
                      ))}

                  {isTariffTypeSelected(TariffItemTypes.RIDER_REVISIONS) &&
                    version.riderVersions
                      .filter(
                        rider =>
                          rider.riderId === rider.riderTariffId &&
                          isSameDay(rider.effectiveDate, date)
                      )
                      .map(rider => (
                        <TariffRow
                          key={`New-Rider-${rider.riderId}`}
                          rowStyle={RowColor.ColorNewRider}
                          link={`${dashEnv}/explorer/tariffs/${rider.riderId}?effectiveOn=${rider.effectiveDate}`}
                        >
                          New rider: (ID: {rider.riderId})
                        </TariffRow>
                      ))}

                  {isTariffTypeSelected(TariffItemTypes.TARIFF_REVISIONS) &&
                    isSameDay(version.effectiveDate, date) && (
                      <TariffRow
                        rowStyle={RowColor.ColorVersion}
                        link={`${dashEnv}/explorer/tariff/${tariff.masterTariffId}?effectiveOn=${version.effectiveDate}`}
                      >
                        {tariff?.tariffId === version.tariffId ? 'Current' : 'New'} tariff version
                        (ID: {version.tariffId})
                      </TariffRow>
                    )}

                  {isTariffTypeSelected(TariffItemTypes.SEASONS) &&
                    seasonsChanges
                      .filter(season => isSameDay(season.seasonChange, date))
                      .map(season => (
                        <SeasonChanges key={`seasonChange-${date}`} seasonChanges={season} />
                      ))}
                </Col>
              </Row>
            ))}
        </Fragment>
      ))}
    </>
  );
};

export default TariffDetails;
