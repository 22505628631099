import { Text } from '@arcadiapower/shrike';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { TaskAssignment } from '../../../task-api/v2/types/TaskV2';
import { Table, Th, Tr, Td } from '@arcadiapower/gen-react-lib';
import { format, parseISO } from 'date-fns';
import AssignTask from '../../AssignTask/AssignTask';
import { CurrentUser, selectCurrentUser } from '../../../state/currentUser/currentUserSlice';
import { useSelector } from 'react-redux';
import { AssignmentTypes } from '../../../utils/constants';
import { selectedWorkFlowConfigDetails } from '../../../state/workflowConfig/WorkFlowConfigSlice';
import { allowAssignment } from '../../../utils/utilityFunction';
import { TaskType } from '../../../task-api/v2/types/TaskType';
export interface TaskAssignmentParams {
  taskId: string;
  taskType: TaskType;
  taskAssignments?: TaskAssignment[] | null;
  workflowId?: string;
  workflowConfigId?: string;
  reviewerId?: string | null;
  reviewer?: string | null;
  workerId?: string | null;
  worker?: string | null;
  taskStatus?: string | null;
  currentWorkflowStatus?: string | null;
  refreshTaskDetails?: () => void;
}

const ASSIGNMENT_DETAILS_COLUMNS = [
  'Assignment Id',
  'Created on',
  'Worker Id',
  'Worker Name',
  'Reviewer Id',
  'Reviewer Name',
  'Assignment Status',
  'Last Updated By',
];

const TaskAssignments: FC<TaskAssignmentParams> = ({
  taskId,
  taskType,
  taskAssignments,
  workflowId,
  workflowConfigId,
  reviewerId,
  reviewer,
  worker,
  workerId,
  currentWorkflowStatus,
}) => {
  const { existingConfigDetails } = useSelector(selectedWorkFlowConfigDetails);
  const currentUser: CurrentUser = useSelector(selectCurrentUser);
  const [modellerAssignmentAllowed, setModellerAssignmentAllowed] = useState<boolean>(false);
  const [reviewerAssignmentAllowed, setReviewerAssignmentAllowed] = useState<boolean>(false);
  const dateFormatter = useCallback((date: string) => format(parseISO(date), 'dd-MMM-yyyy'), []);
  useEffect(() => {
    setReviewerAssignmentAllowed(
      allowAssignment(
        currentWorkflowStatus,
        workflowConfigId,
        AssignmentTypes.REVIEWER,
        currentUser.username,
        reviewer,
        existingConfigDetails
      )
    );
    setModellerAssignmentAllowed(
      allowAssignment(
        currentWorkflowStatus,
        workflowConfigId,
        AssignmentTypes.MODELLER,
        currentUser.username,
        worker,
        existingConfigDetails
      )
    );
  }, [existingConfigDetails, currentUser]);

  const mappedAssignmentValuesRows = useCallback(() => {
    return taskAssignments?.length
      ? taskAssignments.map(taskAssignment => (
          <Tr key={taskAssignment.taskAssignmentId}>
            <td>{taskAssignment?.taskAssignmentId}</td>
            <td>{dateFormatter(String(taskAssignment?.createdDate))}</td>
            <td>{workerId}</td>
            <td>
              {worker}
              {modellerAssignmentAllowed && (
                <div>
                  <AssignTask
                    taskType={taskType}
                    taskId={taskId}
                    className="m-1"
                    workflowId={workflowId?.toString()}
                    username={worker ? undefined : currentUser.username}
                    text={worker ? 'Re-Assign' : 'Assign'}
                  />
                </div>
              )}
            </td>

            <td>{reviewerId}</td>
            <td>
              {reviewer}
              {reviewerAssignmentAllowed && (
                <AssignTask
                  taskId={taskId}
                  taskType={taskType}
                  reviewer
                  className="m-1"
                  workflowId={workflowId?.toString()}
                  text={reviewer ? 'Re-Assign' : 'Assign'}
                />
              )}
            </td>
            <td>{taskAssignment?.assignmentStatus}</td>
            <td>{dateFormatter(String(taskAssignment?.lastUpdatedDate))}</td>
          </Tr>
        ))
      : null;
  }, [taskAssignments, reviewerAssignmentAllowed, modellerAssignmentAllowed]);

  return (
    <>
      <Row>
        <Text color="primary" opacity="high" tag="h3" textStyle="heading700" className="ml-4 mt-3">
          Task Assignments
        </Text>
      </Row>
      <Row className="mt-3 ml-2 ">
        <Col xs={10} className="text-center">
          <Table bordered hover responsive>
            <thead>
              <tr>
                {ASSIGNMENT_DETAILS_COLUMNS.map(cols => (
                  <Th key={cols}>{cols}</Th>
                ))}
              </tr>
            </thead>
            <tbody>
              {!taskAssignments || taskAssignments.length === 0 ? (
                <Tr className="text-center">
                  <td colSpan={ASSIGNMENT_DETAILS_COLUMNS.length}>No results found.</td>
                </Tr>
              ) : (
                mappedAssignmentValuesRows()
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default TaskAssignments;
