import { restClient } from '@genability/api';
import { Task } from '../task-api/v2/types/TaskV2';
import { TaskFilter } from '../state/taskFilter/taskFilterSlice';
import { TaskStatusType } from '../task-api/v2/types/TaskStatusType';

import { TaskDetails } from '../task-api/v2/types/TaskDetails';
import { WorkFlowConfigResponse } from '../task-api/v2/types/WorkflowConfigResponse';
import { User } from '../task-api/v2/types/User';
import { TaskStats } from '../task-api/v2/types/TaskStats';
import { WorkflowStateResponse } from '../task-api/v2/types/WorkflowStateResponse';
import { Tariff } from '../task-api/v2/types/TariffDetails';
export const ASSIGNMENT_SUCCESS = 'Assignment updated successfully';
export const ASSIGNMENT_ERROR = 'Error while updating Assignment';

export const STAGED_ENTITY_JSON = 'stagedEntityJson';
export function getUnpublishedAnswers(task: Task): string | null {
  if (task.taskAssignmentAnswers) {
    const foundAnswer = task.taskAssignmentAnswers.find(
      taskAssignmenentAnswer => taskAssignmenentAnswer.answerField === STAGED_ENTITY_JSON
    );
    if (foundAnswer) {
      return foundAnswer.answerValue;
    }
  }
  return null;
}

export interface TasksApiState {
  apiStatus: 'idle' | 'pending' | 'notfound' | 'resolved' | 'rejected';
  count: number;
  results: Task[];
  errors: restClient.ResponseError[] | undefined;
  assignmentApiState: 'idle' | 'pending' | 'notfound' | 'resolved' | 'rejected' | undefined;
  assignmentApiResult: typeof ASSIGNMENT_SUCCESS | typeof ASSIGNMENT_ERROR | null;
  assignmentApiError: restClient.ResponseError[] | null;
}
export interface TaskCommentApiState {
  apiStatus: 'idle' | 'pending' | 'notfound' | 'resolved' | 'rejected';
  count: number;
  errors: restClient.ResponseError[] | undefined;
}
export interface TaskAssignmentApiState {
  apiState: 'idle' | 'pending' | 'notfound' | 'resolved' | 'rejected' | undefined;
  apiResult: typeof ASSIGNMENT_SUCCESS | typeof ASSIGNMENT_ERROR | null;
  apiError: restClient.ResponseError[] | null;
}
export interface TasksDetailsApiState {
  apiStatus: 'idle' | 'pending' | 'notfound' | 'resolved' | 'rejected';
  count: number;
  results: TaskDetails[];
  errors: restClient.ResponseError[] | undefined;
}
export interface WorkflowConfigApiState {
  wfConfigApiStatus: 'idle' | 'pending' | 'notfound' | 'resolved' | 'rejected';
  wfStatesApiStatus: 'idle' | 'pending' | 'notfound' | 'resolved' | 'rejected';
  wfStatesCount: number;
  data: WorkFlowConfigResponse | null;
  wfStatesData: WorkflowStateResponse[] | null;
  wfStateErrors: restClient.ResponseError[] | undefined;
  existingConfigDetails: { [key: string]: WorkFlowConfigResponse };
  errors: restClient.ResponseError[] | undefined;
}
export interface UserListState {
  apiStatus: 'idle' | 'pending' | 'notfound' | 'resolved' | 'rejected';
  count: number;
  results: User[];
  errors: restClient.ResponseError[] | undefined;
}
export interface TaskStatsApiState {
  apiStatus: 'idle' | 'pending' | 'notfound' | 'resolved' | 'rejected';
  count: number;
  results: TaskStats[];
  errors: restClient.ResponseError[] | undefined;
}
export interface TariffDetailApiState {
  apiStatus: 'idle' | 'pending' | 'notfound' | 'resolved' | 'rejected';
  count: number;
  results: Tariff[];
  errors: restClient.ResponseError[] | undefined;
}
export const openTaskStatus = () => {
  return Object.keys(TaskStatusType)
    .filter(key => TaskStatusType[key as keyof typeof TaskStatusType] !== TaskStatusType.CLOSED)
    .map(key => TaskStatusType[key as keyof typeof TaskStatusType]);
};

export const allTaskStatus = () => {
  return Object.keys(TaskStatusType).map(key => TaskStatusType[key as keyof typeof TaskStatusType]);
};

export const TASK_FILTER_MAPPINGS = {
  [TaskFilter.UNASSIGNED]: openTaskStatus(),
  [TaskFilter.COMPLETED]: [TaskStatusType.CLOSED],
  [TaskFilter.MY_TASKS]: openTaskStatus(),
  [TaskFilter.ALL_TASKS]: allTaskStatus(),
};
