import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { restApis, restClient, types } from '@genability/api';
import { NotificationLevel, KeySpace, Family } from '@arcadiapower/gen-react-lib';
import { TaskApiV2Client } from '../../GenApiClient';
import { addNotification } from '../notification/notificationSlice';
import { RootState } from '../rootReducer';
import { AppThunk } from '../store';
import { PropertyKeyV2 } from '../../task-api/v2/types/PropertyKeyV2';
import { PropertyKeyField } from './propertyKeysSlice';
import { GetPropertyKeysRequest } from '../../task-api/v2/api/task-api-v2';

export interface PropertyKeysV2State {
  propertyKeys: PropertyKeyV2[];
  count: number;
  isLoading: boolean;
  error: string | null;
}

export interface IPropertySearchOption {
  searchTerm: string;
  startsWith: boolean;
  propertyDataType: types.PropertyDataType | null;
  propertyKeySpace: KeySpace | null;
  propertyFamily: Family | null;
  includeGlobalProperties?: boolean | undefined;
  filterByLseId?: boolean | undefined;
  pageNumber?: number;
  scope?: string;
  lseId?: number | undefined;
}

interface getPropertyKeysSuccess {
  propertyKeys: PropertyKeyV2[];
  count: number;
}

export const initialState: PropertyKeysV2State = {
  propertyKeys: [],
  count: 0,
  isLoading: false,
  error: '',
};

function getPropertyKeysStartLoading(state: PropertyKeysV2State) {
  state.isLoading = true;
}

function getPropertyKeysLoadingFailed(state: PropertyKeysV2State, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

export const task = createSlice({
  name: 'getPropertyKeys',
  initialState,
  reducers: {
    getPropertyKeysStart: getPropertyKeysStartLoading,
    getPropertyKeysSuccess(state, { payload }: PayloadAction<getPropertyKeysSuccess>) {
      const { propertyKeys, count } = payload;
      state.isLoading = false;
      state.error = null;
      state.propertyKeys = propertyKeys;
      state.count = count;
    },
    getPropertyKeysFailure: getPropertyKeysLoadingFailed,
  },
});

export const { getPropertyKeysStart, getPropertyKeysSuccess, getPropertyKeysFailure } =
  task.actions;

export default task.reducer;

export const getPropertyKeys =
  (options: Partial<IPropertySearchOption>): AppThunk =>
  async dispatch => {
    try {
      dispatch(getPropertyKeysStart());
      const {
        lseId,
        propertyDataType,
        searchTerm,
        startsWith,
        propertyKeySpace,
        propertyFamily,
        includeGlobalProperties,
        filterByLseId,
        scope,
        pageNumber,
      } = options;
      dispatch(getPropertyKeysStart());
      const client = await TaskApiV2Client();
      const request: GetPropertyKeysRequest = new GetPropertyKeysRequest();
      if (lseId !== undefined && filterByLseId) {
        request.entityType = 'LSE';
        request.entityId = lseId;
      }
      if (propertyDataType != null) {
        request.dataType = propertyDataType;
      }
      request.excludeGlobal =
        includeGlobalProperties === undefined ? false : !includeGlobalProperties;
      request.pageCount = ((pageNumber || 0) + 1) * 25;
      if (searchTerm) {
        request.search = searchTerm;
        request.searchOn = [PropertyKeyField.KEY_NAME];
      }
      if (startsWith) {
        request.startsWith = startsWith;
      }
      if (propertyKeySpace) {
        request.keySpace = propertyKeySpace;
      }
      if (propertyFamily) {
        request.family = propertyFamily;
      }
      if (scope) {
        request.scope = scope;
      }

      const { results, errors, count }: restClient.PagedResponse<PropertyKeyV2> =
        await client.taskV2.getPropertyKeys(request);

      if (errors) {
        throw new Error(errors[0].message);
      }

      dispatch(getPropertyKeysSuccess({ propertyKeys: results, count }));
    } catch (err) {
      if (err) {
        const errorMessage = (err instanceof Error && err.message) || String(err);
        dispatch(getPropertyKeysFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };

export const selectpropertyKeys = (state: RootState): PropertyKeysV2State => {
  return state.propertyKeysV2;
};
