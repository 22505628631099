import React, { FC, useEffect, useMemo, useState } from 'react';
import { Loading, Text } from '@arcadiapower/shrike';
import { types } from '@genability/api';
import { DiffCalcResultList } from '@arcadiapower/gen-react-lib';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import TariffFormControls, {
  TariffFormControlsState,
} from './TariffFormControls/TariffFormControls';
import {
  runMultipleCalculations,
  selectMemoizedMultipleCalculations,
} from '../../state/costCalculation/costCalculation';
import { LoadingState } from '../../state/reduxUtils';
import { fetchTariff, selectTariff } from '../../state/tariff/tariffSlice';
import { fetchTariffHistory, selectTariffHistory } from '../../state/tariff/tariffHistorySlice';

const { GroupBy, DetailLevel } = types;

export interface TariffCalculatorProps {
  masterTariffId: number;
  tariffIds: number[];
}

const TariffCalculator: FC<TariffCalculatorProps> = ({ masterTariffId, tariffIds }) => {
  const [form, setForm] = useState<TariffFormControlsState>({ isBillingPeriod: false });
  const [effectiveDates, setEffectiveDates] = useState<string[]>([]);
  const [calculationRangeInvalid, setCalculationRangeInvalid] = useState(false);
  const [calculateDisabled, setCalculateDisabled] = useState(false);
  const { tariff } = useSelector(selectTariff);
  const { tariffHistory } = useSelector(selectTariffHistory);
  const { multipleResults, loading: multipleCalculationsLoading } = useSelector(
    selectMemoizedMultipleCalculations
  );
  const isMultipleCalculationsLoading = multipleCalculationsLoading === LoadingState.PENDING;

  const dispatch = useDispatch();

  const resetForm = () => {
    setForm({
      isBillingPeriod: false,
      detailLevel: '',
      groupBy: '',
    });
  };

  useEffect(() => {
    const firstMonthDay = DateTime.now().set({ day: 1 });
    setFormState({
      startDate: firstMonthDay.minus({ month: 1 }),
      endDate: firstMonthDay,
      detailLevel: DetailLevel.RATE,
      groupBy: GroupBy.ALL,
    });
  }, []);

  useEffect(() => {
    dispatch(fetchTariff(masterTariffId));
    dispatch(fetchTariffHistory(masterTariffId));
  }, [dispatch, masterTariffId]);

  useEffect(() => {
    setEffectiveDates(
      tariffIds
        .map(
          tariffId =>
            tariffHistory?.tariffVersions?.find(version => version.tariffId === tariffId)
              ?.effectiveDate
        )
        .filter(effectiveDate => effectiveDate) as string[]
    );
  }, [tariffHistory]);

  const isValidGroupBy = (startDate: DateTime, endDate: DateTime, groupBy: string) => {
    let rangeInvalid = false;
    const diffDays = Math.trunc(endDate.diff(startDate, 'days').days);
    const diffMonths = Math.trunc(endDate.diff(startDate, 'months').months);
    const diffYears = Math.trunc(endDate.diff(startDate, 'years').years);

    switch (groupBy) {
      case GroupBy.QTRHOUR:
        rangeInvalid = diffDays > 1 || diffMonths > 0 || diffYears > 0;
        break;
      case GroupBy.HOUR:
        rangeInvalid = diffMonths > 0 || diffYears > 0;
        break;
      case GroupBy.DAY:
        rangeInvalid = diffYears > 0;
        break;
      default:
        rangeInvalid = diffYears > 4;
    }
    return !rangeInvalid;
  };

  useEffect(() => {
    const { startDate, endDate, groupBy } = form;
    if (masterTariffId && startDate && endDate && groupBy) {
      const invalidGroupBy = !isValidGroupBy(startDate, endDate, groupBy);
      setCalculationRangeInvalid(invalidGroupBy);
      setCalculateDisabled(invalidGroupBy);
    } else {
      setCalculationRangeInvalid(false);
      setCalculateDisabled(true);
    }
  }, [masterTariffId, form]);

  const setFormState = (value: Partial<TariffFormControlsState>) => setForm({ ...form, ...value });

  const onRunCalculation = () => {
    const { startDate, endDate, detailLevel, groupBy } = form;
    if (!startDate || !endDate) {
      return;
    }
    const requests = effectiveDates.map(tariffEffectiveOn => ({
      masterTariffId,
      fromDateTime: DateTime.fromISO(startDate.toISODate(), { zone: tariff?.timeZone }).toISO(),
      toDateTime: DateTime.fromISO(endDate.toISODate(), { zone: tariff?.timeZone }).toISO(),
      // TODO: These inputs will be modified by TT-405
      propertyInputs: [
        {
          keyName: 'baselineType',
          dataValue: 'typicalElectricity',
          operator: '+',
          dataFactor: 1,
        },
        {
          keyName: 'buildingId',
          dataValue: 'RESIDENTIAL',
        },
      ] as types.PropertyData[],
      tariffEffectiveOn,
      detailLevel: detailLevel as types.DetailLevel,
      groupBy: groupBy as types.GroupBy,
    }));
    dispatch(runMultipleCalculations(requests));
  };

  /**
   * Get the Base Effective Date (The earliest date) from a list of Dates
   * @param effectiveDates list of date strings
   * @returns the earliest date
   */
  const getBaseEffectiveDate = (effectiveDates: string[]): string =>
    effectiveDates.reduce((min, date) => (min > date ? date : min), effectiveDates[0]);

  const { baseCalculatedCostItems, comparisonCalculatedCostItems } = useMemo(() => {
    let baseEffectiveDate = '';
    let comparisonEffectiveDate = '';

    if (!isEmpty(multipleResults)) {
      const resultEffectiveDates = Object.keys(multipleResults);
      baseEffectiveDate = getBaseEffectiveDate(resultEffectiveDates) || '';
      comparisonEffectiveDate =
        resultEffectiveDates.find(effectiveDate => effectiveDate !== baseEffectiveDate) || '';
    }

    return {
      baseEffectiveDate,
      comparisonEffectiveDate,
      baseCalculatedCostItems:
        (!isEmpty(multipleResults) && multipleResults[baseEffectiveDate]) || null,
      comparisonCalculatedCostItems:
        (!isEmpty(multipleResults) && multipleResults[comparisonEffectiveDate]) || null,
    };
  }, [multipleResults]);

  return (
    <Container>
      <Row className="py-3">
        <Text tag="h2" textStyle="heading900">
          Tariff Calculator
        </Text>
      </Row>
      <TariffFormControls
        formState={form}
        setFormState={setFormState}
        resetForm={resetForm}
        runCalculation={onRunCalculation}
        calculationRangeInvalid={calculationRangeInvalid}
        calculateDisabled={calculateDisabled || isMultipleCalculationsLoading}
      />
      <Row className="pb-5">
        <Col>
          {isMultipleCalculationsLoading && <Loading />}
          {!isMultipleCalculationsLoading && isEmpty(multipleResults) && (
            <div className="border p-5">
              <Text tag="h3" textStyle="heading800">
                Run a calculation to see the results here.
              </Text>
            </div>
          )}
          {!isMultipleCalculationsLoading && !isEmpty(multipleResults) && (
            <div className="border p-5">
              <DiffCalcResultList
                baseCalculatedCostItems={baseCalculatedCostItems?.result?.items || []}
                comparisonCalculatedCostItems={comparisonCalculatedCostItems?.result?.items || []}
                detailLevel={baseCalculatedCostItems?.request?.detailLevel || types.DetailLevel.ALL}
                groupBy={baseCalculatedCostItems?.request?.groupBy || types.GroupBy.ALL}
                showVariancePercentage={true}
              />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default TariffCalculator;
