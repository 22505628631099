import { Select } from '@arcadiapower/shrike';
import { KeySpace } from '@arcadiapower/gen-react-lib';
import React, { FC, useState } from 'react';

interface KeySpaceSelectorProps {
  value: KeySpace;
  className?: string;
  handleSelected: (keyspace: KeySpace) => void;
}

const KeySpaceSelector: React.FC<KeySpaceSelectorProps> = props => {
  const { handleSelected, value, className } = props;
  const [keySpace, setKeySpace] = useState<KeySpace>(value);

  return (
    <>
      <Select
        value={keySpace}
        label="Key Space"
        minHeight="50px"
        className={className}
        required
        loadingMessage="Loading..."
        name="Key Space"
        options={Object.keys(KeySpace).map(key => ({
          text: KeySpace[key as keyof typeof KeySpace],
          value: KeySpace[key as keyof typeof KeySpace],
        }))}
        onChange={value => {
          setKeySpace(value as KeySpace);
          handleSelected(value as KeySpace);
        }}
      />
    </>
  );
};

export default KeySpaceSelector;
