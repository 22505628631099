export const AssignmentTypes = {
  MODELLER: 'modeller',
  REVIEWER: 'reviewer',
};

export const WorkFlow_Config_Mapping: { [key: string]: string } = {
  '1': 'ADD_LOOKUP_AUTO',
  '2': 'EDIT_LOOKUP_AUTO',
};

export const varianceCheckNotificationMessages: { [key: string]: string } = {
  PENDING:
    'This Tariff is enabled in the Production phase; Auto-populating the charge rate is in progress. Please check back after some time for the results',
  FAILED:
    'AI attempted to auto-populate the charge rates, but the variance check failed. Please verify and update the charge rate manually.',
};

export const AI_INPUT_MODE_VALUES = {
  NONE: 'none',
  AUTO_AUTHOR_MODE: 'auto_author_mode',
  COPILOT_MODE: 'copilot_mode',
};
