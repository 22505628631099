import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types, restApis, restClient } from '@genability/api';
import { AppThunk } from '../store';
import { RootState } from '../rootReducer';
import { GenApiClient } from '../../GenApiClient';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { addNotification } from '../notification/notificationSlice';
import { TaskDocument } from '@arcadiapower/gen-react-lib/build/types/TaskDocument';

export interface DocumentState {
  documentBySectionId: Record<number, TaskDocument>;
  documentById: Record<number, TaskDocument>;
  isLoading: boolean;
  error: string | null;
}

interface DocumentSuccess {
  searchId: number;
  document: TaskDocument;
}

export const initialState: DocumentState = {
  documentBySectionId: {},
  documentById: {},
  isLoading: false,
  error: null,
};

function startLoading(state: DocumentState) {
  state.isLoading = true;
}

function loadingFailed(state: DocumentState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

export const document = createSlice({
  name: 'document',
  initialState,
  reducers: {
    getDocumentStart: startLoading,
    getDocumentSuccess(state, { payload }: PayloadAction<DocumentSuccess>) {
      const { searchId, document } = payload;
      state.documentBySectionId[searchId] = document;
      state.documentById[document.documentId] = document;
      state.isLoading = false;
      state.error = null;
    },
    getDocumentFailure: loadingFailed,
  },
});

export const { getDocumentStart, getDocumentSuccess, getDocumentFailure } = document.actions;

export default document.reducer;

export const fetchDocument =
  (searchId: number, searchKey: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(getDocumentStart());
      const client = await GenApiClient();
      const documentParams = new restApis.GetDocumentRequest();
      documentParams.searchKey = searchKey;
      documentParams.populateDocumentSections = true;
      const response: restClient.SingleResponse<TaskDocument> = await client.documents.getDocument(
        searchId,
        documentParams
      );
      if (response.errors) {
        const errorMessage = response.errors[0].message;
        dispatch(getDocumentFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      } else if (response.result) {
        dispatch(getDocumentSuccess({ searchId, document: response.result }));
      }
    } catch (err: any) {
      if (err) {
        const errorMessage = err.message ? err.message : err.toString();
        dispatch(getDocumentFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };

export const selectDocumentBySectionId = (
  documentSectionId: number
): ((state: RootState) => TaskDocument | null) => {
  return (state: RootState): TaskDocument | null =>
    documentSectionId ? state.document.documentBySectionId[documentSectionId] : null;
};
