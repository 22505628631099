import { Button, CheckInput, ConfirmModal } from '@arcadiapower/gen-react-lib';
import React, { useEffect, useState } from 'react';

import { ButtonGroup } from 'react-bootstrap';
import styles from './PanelNavigationButtons.module.scss';

interface PanelNavigationButtonsProps {
  onClickPrevious?: () => void;
  dirty?: boolean;
  onClickNext?: () => void;
  onClickReset?: () => void;
  onClickNoChanges?: (checked: boolean) => void;
  nextLabel?: string;
  disabled?: boolean;
  noChangesChecked?: boolean;
  nextSpin?: boolean;
}

const PanelNavigationButtons: React.FC<PanelNavigationButtonsProps> = ({
  onClickPrevious,
  onClickNext,
  dirty = false,
  onClickReset,
  onClickNoChanges,
  nextLabel = 'Save & Next',
  disabled,
  noChangesChecked,
  nextSpin = false,
}: PanelNavigationButtonsProps) => {
  const isNextDisabled = () => {
    if (onClickNext === undefined) {
      return true;
    }
    if (disabled !== undefined && disabled) {
      return disabled;
    }
    if (nextSpin) {
      return true;
    }
    if (dirty) {
      return false;
    }
    return !noChangesChecked;
  };

  const isPrevDisabled = () => {
    if (disabled !== undefined) {
      return disabled;
    }
    if (dirty) {
      return true;
    }
    return false;
  };

  const handleConfirmNoChanges = async (isChecked: boolean) => {
    let userConfirmSelection = true;

    if (isChecked) {
      userConfirmSelection = await ConfirmModal.show({
        title: 'Confirm No Changes',
        question: 'Confirm no changes in this section?',
      });
    }

    if (userConfirmSelection) {
      if (dirty) {
        onClickNoChanges && onClickNoChanges(false);
      } else {
        onClickNoChanges && onClickNoChanges(!noChangesChecked);
      }
    }
  };

  const handleOnChange = async (event: React.SyntheticEvent<EventTarget>) => {
    event.preventDefault();
    const targetChecked = (event.target as HTMLInputElement).checked;
    await handleConfirmNoChanges(targetChecked);
  };

  // It's used as hack to reset checkbox state when *noChangesChecked* changes
  const [noChangesKey, setNoChangesKey] = useState(false);

  useEffect(() => {
    setNoChangesKey(prevState => !prevState);
  }, [noChangesChecked]);

  return (
    <div className={styles.buttonBar}>
      {onClickPrevious && (
        <div className={styles.prevButton}>
          <Button variant="secondary" action={onClickPrevious} disabled={isPrevDisabled()}>
            Previous
          </Button>
        </div>
      )}

      <div className={styles.noChangesNextContainer}>
        {!dirty && onClickNoChanges /* Only show this button if the button does something */ && (
          <ButtonGroup toggle>
            <CheckInput
              key={`${noChangesKey}`}
              checked={noChangesChecked}
              name="noChangeSelected"
              onClick={handleOnChange}
              label="No Changes"
              id="id-nochanges"
              disabled={disabled}
              inline
            />
          </ButtonGroup>
        )}

        <div className={styles.nextButton}>
          {onClickReset && dirty && (
            <Button
              variant="link"
              type="reset"
              action={() => onClickReset()}
              disabled={disabled != undefined ? disabled : !dirty}
            >
              Reset
            </Button>
          )}
          <Button
            variant="primary"
            type="submit"
            action={onClickNext}
            disabled={isNextDisabled()}
            spin={nextSpin}
          >
            {nextLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PanelNavigationButtons;
