import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { restClient, types, restApis } from '@genability/api';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { GenApiClient } from '../../GenApiClient';
import { addNotification } from '../notification/notificationSlice';
import { RootState } from '../rootReducer';
import { AppThunk } from '../store';
import { Fields, SortOrder } from '@genability/api/dist/rest-client';

export interface GenLookupValuesState {
  lookupValuesByPropertyKey: Record<string, types.LookupValue>;
  isLoading: boolean;
  error: string | null;
}

interface GenLookupValuesSuccess {
  lookupValues: types.LookupValue[];
}

export const initialState: GenLookupValuesState = {
  lookupValuesByPropertyKey: {},
  isLoading: false,
  error: null,
};

function startLoading(state: GenLookupValuesState) {
  state.isLoading = true;
}

function loadingFailed(state: GenLookupValuesState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

export const lookupValues = createSlice({
  name: 'lookupValues',
  initialState,
  reducers: {
    getLookupValuesStart: startLoading,
    getLookupValuesSuccess(state, { payload }: PayloadAction<GenLookupValuesSuccess>) {
      const { lookupValues } = payload;
      state.isLoading = false;
      state.error = null;
      lookupValues.forEach(lookupValue => {
        state.lookupValuesByPropertyKey[lookupValue.propertyKey] = lookupValue;
      });
    },
    getLookupValuesFailure: loadingFailed,
  },
});

export const { getLookupValuesStart, getLookupValuesSuccess, getLookupValuesFailure } =
  lookupValues.actions;

export default lookupValues.reducer;

// It fetches the values for a particular property key
export const fetchLookupValues =
  (
    propertyKey?: string | null,
    subPropertyKey?: string | null,
    fromDateTime?: string | null,
    toDateTime?: string | null,
    pageNumber?: number,
    sortOn?: string[],
    sortOrder?: SortOrder[],
    fields?: Fields
  ): AppThunk =>
  async dispatch => {
    try {
      dispatch(getLookupValuesStart());
      const client = await GenApiClient();
      const request: restApis.GetLookupsRequest = new restApis.GetLookupsRequest();
      if (propertyKey) {
        request.propertyKey = propertyKey;
      }
      if (subPropertyKey) {
        request.subPropertyKey = subPropertyKey;
      }
      if (fromDateTime) {
        request.fromDateTime = fromDateTime;
      }
      if (toDateTime) {
        request.toDateTime = toDateTime;
      }
      if (sortOn) {
        request.sortOn = sortOn;
      }
      if (sortOrder) {
        request.sortOrder = sortOrder;
      }
      if (fields) {
        request.fields = fields;
      }
      request.pageCount = ((pageNumber || 0) + 1) * 25;
      request.pageStart = pageNumber;

      const response: restClient.PagedResponse<types.LookupValue> =
        await client.lookups.getLookupValues(request);
      const lookupValues: types.LookupValue[] = response.results;
      dispatch(getLookupValuesSuccess({ lookupValues }));
    } catch (err) {
      if (err) {
        const errorMessage = (err instanceof Error && err.message) || String(err);
        dispatch(getLookupValuesFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };

export const selectLookupValuesLoading = (state: RootState): boolean => {
  return state.lookupValues.isLoading;
};

export const selectLookupValue = (propertyKey: string | undefined) => {
  return (state: RootState): types.LookupValue | null =>
    propertyKey ? state.lookupValues.lookupValuesByPropertyKey[propertyKey] : null;
};

export const selectAllLookupValueByPropertyKey = (
  state: RootState
): Record<string, types.LookupValue> => {
  return state.lookupValues.lookupValuesByPropertyKey;
};

export const selectLookupValuesList = (state: RootState): types.LookupValue[] => {
  const list: types.LookupValue[] = [];
  if (state.lookupValues.lookupValuesByPropertyKey) {
    for (const propertyKey in state.lookupValues.lookupValuesByPropertyKey) {
      list.push(state.lookupValues.lookupValuesByPropertyKey[propertyKey]);
    }
  }
  return list;
};
