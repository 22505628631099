import React, { FormEvent, useEffect, useState } from 'react';
import { types } from '@genability/api';
import { useSelector } from 'react-redux';
import PanelNavigationButtons from '../PanelNavigationButtons/PanelNavigationButtons';
import TariffPropertyForm from '../../TariffPropertyForm/TariffPropertyHookForm';
import styles from './RateCriteriaPanel.module.scss';
import { isRateCriteriaDefaultExpected } from '../../../utils/taskUtils';
import {
  selectTaskAssignmentTariff,
  updateTaskAssignmentTariff,
} from '../../../state/taskAssignmentTariffs/taskAssignmentTariffsSlice';
import { useAppDispatch } from '../../../state/store';
import useTariffAnswer from '../../../utils/useTariffAnswer';
import { DoStepPanelProps } from '../work-body';
import { useFieldArray, useForm, FormProvider } from 'react-hook-form';
import { Form } from '@arcadiapower/gen-react-lib';
import { selectAllPropertyKeysByKeyName } from '../../../state/propertyKeys/propertyKeysSlice';
import { mapApiErrors } from '../../../utils/formUtils';
import ApiFormError, { FormErrorObject } from '../../ApiFormError/ApiFormError';
import {
  fetchDQCheck,
  isDQCheckOverride,
  selectDQCheckErrors,
  selectDQCheckOverrides,
} from '../../../state/dqCheck/dqCheckSlice';
import { isErrorMapToField, isErrorMapToTariffProperty } from '../../../utils/errorUtils';
import { knownDqErrors } from '../../../utils/dqErrorUtil';
import { TaskTariff } from '../../../task-api/types/task';

const RateCriteriaPanel: React.FC<DoStepPanelProps> = ({
  taskAssignment,
  onPrevious,
  onNext,
}: DoStepPanelProps) => {
  const cleanTariff: TaskTariff | undefined = useSelector(selectTaskAssignmentTariff);
  const dqCheckErrors = useSelector(selectDQCheckErrors);
  const dqOverrides = useSelector(selectDQCheckOverrides);

  useEffect(() => {
    dispatch(fetchDQCheck(taskAssignment.taskAssignmentId));
  }, []);

  if (!cleanTariff) {
    return null;
  }
  const {
    tariffAnswer,
    resetAnswer,
    tariffFields,
    extractTariffFields,
    setTariffProperties,
    getTariffFields,
  } = useTariffAnswer(cleanTariff, ['properties']);
  if (!tariffFields) {
    return null;
  }

  const [noChangesChecked, setNoChangesChecked] = useState(false);
  const [nextSpin, setNextSpin] = useState(false);
  const dispatch = useAppDispatch();
  const propertyKeysByKeyName = useSelector(selectAllPropertyKeysByKeyName);
  const getRateCriteriaProps = (properties: types.TariffProperty[] | undefined) => {
    if (!properties) {
      return [];
    }
    // Filter RATE_CRITERIA and add default operator if the operator is null and the data type
    // is not one with a selectable operator. Also add isDefault=true to the RATE_CRITERIA properties so that the API
    // will accept the values entered in this panel.
    return properties.flatMap(prop => {
      if (
        types.TariffPropertyType.RATE_CRITERIA == prop.propertyTypes &&
        !(
          prop.keyName == 'demand' ||
          prop.keyName == 'consumption' ||
          prop.dataType == types.PropertyDataType.DEMAND
        )
      ) {
        if (
          !prop.operator &&
          ![
            types.PropertyDataType.DATE,
            types.PropertyDataType.DECIMAL,
            types.PropertyDataType.INTEGER,
            types.PropertyDataType.DEMAND,
          ].includes(prop.dataType)
        ) {
          prop.operator = '=';
        }

        prop.isDefault = true;

        return [prop];
      } else {
        return [];
      }
    });
  };

  type CriteriaFields = Pick<TaskTariff, (typeof tariffFields)[number]>;
  const handleNoChanges = (checked: boolean) => {
    setNoChangesChecked(checked);
  };

  const methods = useForm<CriteriaFields>({
    mode: 'onBlur',
    defaultValues: {
      properties: getRateCriteriaProps(tariffAnswer.properties),
    },
  });

  const { fields } = useFieldArray({
    control: methods.control, // This is optional but helps satisfy type contraints
    name: 'properties',
  });

  const { reset, formState, handleSubmit, setError, clearErrors } = methods;
  const { isDirty, errors } = formState;

  const onSubmit = (formData: CriteriaFields) => {
    const { properties } = extractTariffFields(formData);
    const { didUpdate, updatedTariffAnswer } = setTariffProperties(properties);

    if (!didUpdate.includes(false)) {
      return saveAndNext(updatedTariffAnswer);
    }
  };

  const saveAndNext = async (updatedTariff: TaskTariff) => {
    setNextSpin(true);
    const { result, errors: apiErrors } = await dispatch(
      updateTaskAssignmentTariff({
        taskAssignmentId: taskAssignment.taskAssignmentId,
        tariff: updatedTariff,
      })
    ).unwrap();
    if (apiErrors) {
      mapApiErrors<CriteriaFields>(apiErrors, setError, updatedTariff);
    } else if (result) {
      const dqCheck = await dispatch(fetchDQCheck(taskAssignment.taskAssignmentId)).unwrap();
      const isErrorsInForm = dqCheck.errors?.some(error => isErrorMapToField(tariffAnswer, error));
      if (!isErrorsInForm) {
        resetAnswer(result);
        setNextSpin(false);
        onNext();
      }
    }
    setNextSpin(false);
  };

  const getUniqueApplicabilityValues = (keyName: string): string[] => {
    const rates = tariffAnswer?.rates?.filter(rate => rate.applicabilityKey === keyName);
    if (!rates?.length) return [];
    const flatValues = rates.flatMap(
      r => r.rateBands?.flatMap(rb => rb.applicabilityValue) || []
    ) as string[];
    return [...new Set(flatValues)];
  };

  useEffect(() => {
    const properties = getRateCriteriaProps(tariffAnswer.properties);

    const dqCheckErrorsInForm = dqCheckErrors.filter(
      error =>
        isErrorMapToTariffProperty({ properties }, error) ||
        knownDqErrors.rateCriteria.find(
          knownError =>
            error.propertyName === knownError.propertyName && error.message === knownError.message
        )
    );

    mapApiErrors<CriteriaFields>(
      dqCheckErrorsInForm.filter(error => !isDQCheckOverride(error, dqOverrides)),
      setError,
      getTariffFields()
    );
  }, [dqCheckErrors]);

  const handleOnSubmit = (d: FormEvent) => {
    d.preventDefault();
    clearErrors();
    handleSubmit(onSubmit)();
  };

  return (
    <>
      <h2>Rate Criteria</h2>
      <p>Select default values for the required properties specified in the Rates section.</p>
      <FormProvider {...methods}>
        <Form
          onSubmit={handleOnSubmit}
          onReset={e => {
            e.preventDefault();
            reset();
          }}
        >
          <ApiFormError apiErrors={errors as FormErrorObject} />
          {fields.length > 0 ? (
            <div className={styles.cardList}>
              {fields.map((property: types.TariffProperty & { id: string }, index: number) => {
                if (isRateCriteriaDefaultExpected(property)) {
                  const uniqueValues = getUniqueApplicabilityValues(property.keyName);
                  return (
                    <div
                      className={styles.tariffPropertyTarget}
                      id={property.keyName}
                      key={property.id}
                      tabIndex={index}
                    >
                      <TariffPropertyForm
                        // Make sure the property is fully populated -- if a new rate criteria is added,
                        // its choices won't be populated until after refresh.

                        // TODO: Properties should always be fully populated when they come back
                        // from the API
                        tariffProperty={{
                          ...property,
                          ...propertyKeysByKeyName[property.keyName],
                        }}
                        operatorRequired
                        name={`properties.${index}`}
                        className={`${styles.cardItem} ${
                          index == fields.length - 1 ? '' : styles.noBorderBottom
                        }`}
                        bands={uniqueValues}
                      />
                    </div>
                  );
                }
              })}
            </div>
          ) : (
            <div className={styles.noResults}>No Rate Criteria Properties.</div>
          )}
          <div className="mt-3">
            <PanelNavigationButtons
              onClickPrevious={onPrevious}
              onClickReset={reset}
              onClickNext={isDirty ? () => true : onNext}
              nextLabel={isDirty ? 'Save & Next' : 'Next'}
              dirty={isDirty}
              nextSpin={nextSpin}
              noChangesChecked={noChangesChecked}
              onClickNoChanges={handleNoChanges}
            />
          </div>
        </Form>
      </FormProvider>
    </>
  );
};

export default RateCriteriaPanel;
