/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FormEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { types } from '@genability/api';
import { Button, Form, TextInput, ConfirmModal } from '@arcadiapower/gen-react-lib';
import PropertyKeyPicker from '../../PropertyKeyPicker/PropertyKeyPicker';
import PanelNavigationButtons from '../PanelNavigationButtons/PanelNavigationButtons';
import TariffPropertyForm from '../../TariffPropertyForm/TariffPropertyHookForm';
import { selectAllPropertyKeysByKeyName } from '../../../state/propertyKeys/propertyKeysSlice';
import capitalize from '../../../utils/capitalize';
import { DoStepPanelProps } from '../work-body';
import useTariffAnswer from '../../../utils/useTariffAnswer';
import {
  selectTaskAssignmentTariff,
  updateTaskAssignmentTariff,
} from '../../../state/taskAssignmentTariffs/taskAssignmentTariffsSlice';
import { useAppDispatch } from '../../../state/store';
import styles from './EligibilityPanel.module.scss';
import { useFieldArray, useForm, FormProvider, UseFieldArrayReturn } from 'react-hook-form';
import { mapApiErrors, withErrors } from '../../../utils/formUtils';
import ApiFormError, { FormErrorObject } from '../../ApiFormError/ApiFormError';
import {
  fetchDQCheck,
  selectDQCheckErrors,
  selectDQCheckOverrides,
  isDQCheckOverride,
} from '../../../state/dqCheck/dqCheckSlice';
import { isErrorMapToField } from '../../../utils/errorUtils';
import {
  PropertyKeyPickerContextType,
  usePropertyKeyPickerContext,
} from '../../../context/propertyKeyPickerContext';
import { knownDqErrors } from '../../../utils/dqErrorUtil';
import { TaskTariff } from '../../../task-api/types/task';

// Property Group utilities to avoid repetition later

// Property Group Keys as an array, so we can iterate over them when we need to.
// Adding another key to this list will add to the categories of property keys
// displayed on the Eligibility page.
const PropertyGroupKeys = [
  types.TariffPropertyType.APPLICABILITY as string,
  types.TariffPropertyType.SERVICE_TERMS as string,
  types.TariffPropertyType.INFO as string,
];

// Property Group Keys as literal, so it can be used in the below type
const PropertyGroupLiteral = [...PropertyGroupKeys] as const;

// Mapped type for defining form defaults and extracting properties
// from the properties array
export type PropertyGroups = {
  [Property in (typeof PropertyGroupLiteral)[number]]: types.TariffProperty[];
};

// Constructs an object with the Property Group Keys as keys, and the values
// as a derivative of the key returned from the provided callback.

// This gets used to extract the properties we want to edit from the properties
// array, and also to build the fieldArrays for the form
const propertyKeyGroupsToObject = (callback: (propertyGroupKey: string) => any) => {
  return PropertyGroupKeys.reduce(
    (
      returnObject: {
        [Property in keyof PropertyGroups]: ReturnType<typeof callback>;
      },
      propertyKey: keyof PropertyGroups
    ) => {
      return { ...returnObject, [propertyKey]: callback(propertyKey) };
    },
    {}
  );
};

export const ELIGIBILITY_PROPERTIES = [
  'maxMonthlyConsumption',
  'maxMonthlyDemand',
  'minMonthlyConsumption',
  'minMonthlyDemand',
] as (keyof types.Tariff)[];

const EligibilityPanel: React.FC<DoStepPanelProps> = ({
  taskAssignment,
  onPrevious,
  onNext,
}: DoStepPanelProps) => {
  const cleanTariff = useSelector(selectTaskAssignmentTariff);
  const dqCheckErrors = useSelector(selectDQCheckErrors);
  const dqOverrides = useSelector(selectDQCheckOverrides);

  if (!cleanTariff) {
    return null;
  }
  const {
    tariffAnswer,
    deleteTariffProperty,
    addTariffProperty,
    setTariffProperties,
    setTariffFieldsFromForm,
    resetAnswer,
    getTariffFields,
    tariffFields,
  } = useTariffAnswer(cleanTariff, ELIGIBILITY_PROPERTIES);
  if (!tariffFields) {
    return null;
  }

  const [noChangesChecked, setNoChangesChecked] = useState(false);
  const [nextSpin, setNextSpin] = useState(false);

  const [showPropertyKeyPicker, setShowPropertyKeyPicker] = useState(false);
  const [propertyType, setPropertyType] = useState<types.TariffPropertyType>();
  const propertyKeysByKeyName = useSelector(selectAllPropertyKeysByKeyName);

  const dispatch = useAppDispatch();
  const { setPicker } = usePropertyKeyPickerContext() as PropertyKeyPickerContextType;

  useEffect(() => {
    dispatch(fetchDQCheck(taskAssignment.taskAssignmentId));
    setPicker({
      title: 'Tariff Info Property Key Picker',
      propertyDataTypes: [
        types.PropertyDataType.BOOLEAN,
        types.PropertyDataType.CHOICE,
        types.PropertyDataType.INTEGER,
        types.PropertyDataType.DECIMAL,
      ],
      keySpaces: undefined,
      family: undefined,
      globalProperties: true,
      saved: false,
    });
  }, []);

  const openPropertyKeyPickerModal = (propertyType: types.TariffPropertyType) => {
    setShowPropertyKeyPicker(true);
    setPropertyType(propertyType);
    let title = '';

    if (propertyType === types.TariffPropertyType.APPLICABILITY) {
      title = 'Applicability Property Key Picker';
    }

    if (propertyType === types.TariffPropertyType.SERVICE_TERMS) {
      title = 'Service Terms Property Key Picker';
    }

    if (propertyType === types.TariffPropertyType.INFO) {
      title = 'Tariff Info Property Key Picker';
    }

    setPicker({
      title,
      propertyDataTypes: [
        types.PropertyDataType.BOOLEAN,
        types.PropertyDataType.CHOICE,
        types.PropertyDataType.INTEGER,
        types.PropertyDataType.DECIMAL,
      ],
      keySpaces: undefined,
      family: undefined,
      globalProperties: true,
      saved: false,
    });
  };

  const closePropertyKeyPickerModal = () => {
    setShowPropertyKeyPicker(false);
    setPropertyType(undefined);
  };

  const onSelectedProperty = (
    property: types.GenPropertyKey,
    propertyType: types.TariffPropertyType
  ) => {
    const tariffProperty: types.TariffProperty = {
      ...property,
      propertyTypes: propertyType,
      operator: '=',
      isDefault: false,
    };
    if (addTariffProperty(tariffProperty)) {
      fieldArrays[propertyType as string].append(tariffProperty);
      setShowPropertyKeyPicker(false);
      setPropertyType(undefined);
    }
  };

  const onDeleteProperty = async (
    propertyToDelete: types.TariffProperty | undefined,
    index: number
  ) => {
    if (!propertyToDelete) {
      // No Property or tariffProperties, nothing to delete
      return;
    }
    let question = 'Are you sure you want to delete this property';
    if (propertyToDelete.propertyTypes === types.TariffPropertyType.APPLICABILITY) {
      question = 'Are you sure you want to delete this Applicability property?';
    } else if (propertyToDelete.propertyTypes === types.TariffPropertyType.SERVICE_TERMS) {
      question = 'Are you sure you want to delete this Service Terms property?';
    } else if (propertyToDelete.propertyTypes === types.TariffPropertyType.INFO) {
      question = 'Are you sure you want to delete this Info property?';
    }

    const result = await ConfirmModal.show({
      title: 'Delete Tariff Property',
      question: question,
    });
    if (result) {
      deleteTariffProperty(propertyToDelete) &&
        fieldArrays[propertyToDelete.propertyTypes].remove(index);
    }
  };

  const resetChanges = () => {
    // Reset to defaults
    setShowPropertyKeyPicker(false);
    setPropertyType(undefined);
    resetAnswer(cleanTariff);
    reset();
  };

  // TODO: This still needs to display when there are no properties
  const getPropertyGroupsToRender = (properties: types.TariffProperty[] | undefined) => {
    const emptyPropertyGroups = propertyKeyGroupsToObject(() => []);

    if (!properties) return emptyPropertyGroups;

    return properties.reduce((propertyGroups: PropertyGroups, property: types.TariffProperty) => {
      if (
        property.keyName === 'chargeClass' &&
        property.propertyTypes === types.TariffPropertyType.SERVICE_TERMS
      ) {
        // Do nothing
      } else if (PropertyGroupKeys.includes(property.propertyTypes)) {
        // TODO: TariffProperties returned from the API should be fully populated.
        // Remove this once https://genability.atlassian.net/browse/TT-301 is complete
        const propertyDetails = propertyKeysByKeyName[property.keyName];
        propertyGroups[property.propertyTypes].push({
          ...propertyDetails,
          ...property,
        });
      }
      return propertyGroups;
    }, emptyPropertyGroups);
  };

  const handleNoChanges = (checked: boolean) => {
    setNoChangesChecked(checked);
  };

  interface EligibilityFields extends Pick<TaskTariff, (typeof tariffFields)[number]> {
    propertyGroups: PropertyGroups;
    dqOverridesSaved: boolean;
  }

  const methods = useForm<EligibilityFields>({
    mode: 'onChange',
    defaultValues: {
      ...getTariffFields(),
      propertyGroups: getPropertyGroupsToRender(tariffAnswer.properties),
      dqOverridesSaved: false,
    },
  });

  const fieldArrays: {
    [Property in keyof PropertyGroups]: UseFieldArrayReturn;
  } = propertyKeyGroupsToObject((keyName: keyof PropertyGroups) => {
    if (PropertyGroupKeys.includes(keyName)) {
      return useFieldArray({
        control: methods.control, // This is optional but helps satisfy type contraints
        name: `propertyGroups.${keyName}` as const, // 'as const' needed to satisfy type constraints
      });
    }
  });

  const {
    register: useFormRegister,
    reset,
    formState,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
  } = methods;
  const { isDirty, errors } = formState;

  const register = withErrors(useFormRegister, { errors });

  const saveAndNext = async (formData: EligibilityFields) => {
    // Extract properties to update from the property groups form data
    const updatedProperties = Object.values(formData.propertyGroups).reduce(
      (propertiesList: types.TariffProperty[], properties) => {
        return [...propertiesList, ...properties];
      },
      []
    );

    // Update properties
    const { didUpdate, updatedTariffAnswer } = setTariffProperties(updatedProperties);

    if (didUpdate.includes(false)) {
      // TODO: Something went wrong, throw an error
      return false;
    }

    setNextSpin(true);
    // Extract all the other tariff fields besides properties from the form to update,
    // since we've already updated the properties
    const { ...remainingFormValues } = formData;

    // Update the tariff, using the form data and the update tariff answer with new and
    // updated properties
    const updatedTariff = setTariffFieldsFromForm(remainingFormValues, updatedTariffAnswer);

    // Save the result
    const { result, errors } = await dispatch(
      updateTaskAssignmentTariff({
        taskAssignmentId: taskAssignment.taskAssignmentId,
        tariff: updatedTariff,
      })
    ).unwrap();
    if (errors) {
      mapApiErrors<EligibilityFields>(errors, setError, formData);
    } else if (result) {
      const dqCheck = await dispatch(fetchDQCheck(taskAssignment.taskAssignmentId)).unwrap();
      const isErrorsInForm = dqCheck.errors?.some(
        error => !isErrorMapToField(getValues(), error) && isDQCheckOverride(error, dqOverrides)
      );
      if (!isErrorsInForm) {
        resetAnswer(result);
      }
    }
    setNextSpin(false);
    onNext();
  };

  const renderPropertyGroupProperties = (properties: any[], propertyGroupName: string) => {
    return (
      <>
        <h4>{capitalize(propertyGroupName, '_')}</h4>
        <div className={styles.cardList}>
          {properties.map((property: types.TariffProperty & { id: string }, index: number) => {
            return (
              <div
                className={styles.tariffPropertyTarget}
                id={`${property.keyName}`}
                key={property.id}
                tabIndex={index}
              >
                <TariffPropertyForm
                  operatorRequired={
                    property.propertyTypes == types.TariffPropertyType.APPLICABILITY
                  }
                  name={`propertyGroups.${property.propertyTypes}.${index}`}
                  tariffProperty={{
                    ...property,
                    ...propertyKeysByKeyName[property.keyName],
                  }}
                  className={`${styles.cardItem} ${
                    index == properties.length - 1 ? '' : styles.noBorderBottom
                  }`}
                  onDelete={() => onDeleteProperty(property, index)}
                />
              </div>
            );
          })}
        </div>
      </>
    );
  };

  useEffect(() => {
    const editForm = document.getElementById('eligibility-header');
    const hasUnmatchedProperties = Object.keys(errors).some(error =>
      error.startsWith('noMatchedProperty')
    );
    if (hasUnmatchedProperties && editForm) {
      editForm.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errors]);

  useEffect(() => {
    if (dqCheckErrors) {
      const filteredCheckErrors = dqCheckErrors.filter(
        error =>
          isErrorMapToField(getValues(), error) ||
          knownDqErrors.elegibility.find(
            knownError =>
              error.propertyName === knownError.propertyName &&
              error.message.includes(knownError.message)
          )
      );
      mapApiErrors<EligibilityFields>(filteredCheckErrors, setError, methods.getValues());
    }
  }, [dqCheckErrors]);

  const handleOnSubmit = (d: FormEvent) => {
    d.preventDefault();
    clearErrors();
    handleSubmit(saveAndNext)();
  };

  return (
    <React.Fragment>
      <h2 id="eligibility-header">Eligibility etc</h2>
      <FormProvider {...methods}>
        <Form
          onSubmit={handleOnSubmit}
          onReset={e => {
            e.preventDefault();
            resetChanges();
          }}
        >
          <ApiFormError apiErrors={errors as FormErrorObject} />
          <Row id="consumption" className={styles.targetRow} tabIndex={0}>
            <Col>
              <div className={styles.width150px}>Consumption (kWh)</div>
            </Col>
            <Col>
              <TextInput type="number" label="Minimum" {...register('minMonthlyConsumption')} />
            </Col>
            <Col>
              <TextInput type="number" label="Maximum" {...register('maxMonthlyConsumption')} />
            </Col>
          </Row>
          <br />
          <Row id="demand" className={styles.targetRow} tabIndex={1}>
            <Col>
              <div className={styles.width150px}>Demand (kW)</div>
            </Col>
            <Col>
              <TextInput type="number" label="Minimum" {...register('minMonthlyDemand')} />
            </Col>
            <Col>
              <TextInput type="number" label="Maximum" {...register('maxMonthlyDemand')} />
            </Col>
          </Row>
          {Object.entries(fieldArrays).map(([propertyGroupName, { fields }]) => {
            return (
              <div key={propertyGroupName} className={styles.propertyGroup}>
                {renderPropertyGroupProperties(fields, propertyGroupName)}
                <Row>
                  <div className={styles.prevButton}>
                    <Button
                      variant="link"
                      action={() => {
                        openPropertyKeyPickerModal(
                          types.TariffPropertyType[
                            propertyGroupName as keyof typeof types.TariffPropertyType
                          ]
                        );
                      }}
                    >
                      + Add {capitalize(propertyGroupName, '_')}
                    </Button>
                  </div>
                </Row>
              </div>
            );
          })}
          <br />
          <Row>
            <Col>
              <PanelNavigationButtons
                onClickPrevious={onPrevious}
                onClickReset={resetChanges}
                onClickNext={isDirty ? () => true : onNext}
                nextLabel={isDirty ? 'Save & Next' : 'Next'}
                nextSpin={nextSpin}
                dirty={isDirty}
                noChangesChecked={noChangesChecked}
                onClickNoChanges={handleNoChanges}
              />
            </Col>
          </Row>
        </Form>
      </FormProvider>
      {showPropertyKeyPicker && propertyType ? (
        <div>
          <PropertyKeyPicker
            show={propertyType && showPropertyKeyPicker}
            onSelected={(property: types.GenPropertyKey) => {
              propertyType && onSelectedProperty(property, propertyType);
            }}
            onClose={() => closePropertyKeyPickerModal()}
          />
        </div>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

export default EligibilityPanel;
