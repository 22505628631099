import React, { useEffect, useState, useRef } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import { Input } from '@arcadiapower/shrike';
import { ListGroup, ListGroupItem, Popover } from 'react-bootstrap';

export interface SelectInputProps {
  label: string;
  placeholder?: string;
  id?: string;
  selected: string;
  className?: string;
  options: string[];
  style?: React.CSSProperties;
  popoverStyle?: React.CSSProperties;
  setSelected: (value: string | undefined) => void;
  onChange: (value: string | undefined) => void;
}

const DropdownSelect: React.FC<SelectInputProps> = props => {
  const {
    label,
    placeholder,
    id,
    selected,
    setSelected,
    className,
    options,
    style,
    popoverStyle,
    onChange,
  } = props;

  const [clicked, setClicked] = useState(false);
  const [show, setShow] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.autocomplete = 'off';
    }
  }, [inputRef]);

  return (
    <div>
      <Input
        label={label}
        placeholder={placeholder || 'Search'}
        id={id}
        style={style}
        className={className}
        name={''}
        ref={inputRef}
        value={selected}
        type="text"
        onFocus={() => {
          if (clicked) {
            setClicked(false);
            return;
          }
          setShow(true);
        }}
        onBlur={() => {
          if (clicked) {
            setClicked(false);
            return;
          }
          setShow(false);
        }}
        onMouseDown={() => setClicked(true)}
        onClick={() => setShow(true)}
        onChange={value => {
          onChange(value);
          setShow(true);
        }}
      />

      <Overlay
        show={show}
        target={inputRef.current?.parentElement || inputRef.current}
        placement="bottom-start"
        transition={true}
        rootClose
        onHide={() => setShow(false)}
      >
        <Popover
          style={{
            minWidth: inputRef.current?.parentElement?.clientWidth,
            maxHeight: '30vh',
            overflowY: 'auto',
            ...popoverStyle,
          }}
          id="select"
        >
          <ListGroup>
            {options.map(value => (
              <ListGroupItem
                style={{ minHeight: '50px' }}
                onMouseDown={() => setClicked(true)}
                onClick={() => {
                  setShow(false);
                  setSelected(value);
                }}
                key={value}
              >
                {value}
              </ListGroupItem>
            ))}
          </ListGroup>
        </Popover>
      </Overlay>
    </div>
  );
};

export default DropdownSelect;
