import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TaskApiV2Client } from '../../GenApiClient';
import { TaskStatsRequest } from '../../task-api/v2/api/task-api-v2';
import { handleUnexpectedThunkException } from '../reduxUtils';
import { RootState } from '../rootReducer';
import { restClient } from '@genability/api';

import { TaskStatsApiState } from '../../utils/taskV2Utils';
import { TaskStats } from '../../task-api/v2/types/TaskStats';

const initialState: TaskStatsApiState = {
  apiStatus: 'idle',
  count: 0,
  results: [],
  errors: undefined,
};

export const fetchTaskStats = createAsyncThunk(
  'v2/tasks/fetchTaskStats',
  async (request: TaskStatsRequest, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiV2Client();
      const response: restClient.PagedResponse<TaskStats> = await client.taskV2.getTaskStats(
        request
      );
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      return response;
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'Task', dispatch));
    }
  }
);

export const taskStatsSlice = createSlice({
  name: 'taskStats',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchTaskStats.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(
      fetchTaskStats.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<TaskStats>>) => {
        const { count, results, errors } = action.payload;
        if (errors) {
          state.apiStatus = 'rejected';
          state.errors = errors;
        } else {
          state.apiStatus = 'resolved';
          state.count = count;
          state.results = results;
          state.errors = undefined;
        }
      }
    );
  },
});

export const selectTaskStats = (state: RootState): TaskStatsApiState => {
  return state.taskStats;
};

export default taskStatsSlice.reducer;
