import React, { useEffect, useState } from 'react';
import { Provider, Tabs } from '@arcadiapower/shrike';
import TariffTasks from '../../components/TariffTasks/TariffTasks';
import LookUpTasks from '../../components/LookUpTasks/LookUpTasks';
import { useDispatch } from 'react-redux';
import {
  LOOKUPS,
  LSE,
  TARIFF,
  ZIPCODES,
  updateActiveSelectedTaskType,
  PROPERTY_KEYS,
} from '../../state/taskFilter/taskFilterSlice';
import PropertyKeyTasks from '../../components/PropertyKeyTasks/PropertyKeyTasks';

const TaskContent = () => {
  const dispatch = useDispatch();
  const [activeTabState, setActiveTabState] = useState(TARIFF.toLowerCase());

  useEffect(() => {
    dispatch(updateActiveSelectedTaskType(activeTabState));
  }, [dispatch, activeTabState]);

  const TABLES = [
    {
      name: TARIFF,
      content: <TariffTasks />,
    },
    {
      name: LOOKUPS,
      content: <LookUpTasks />,
    },
    {
      name: ZIPCODES,
      content: null,
    },
    {
      name: LSE,
      content: null,
    },
    {
      name: PROPERTY_KEYS,
      content: <PropertyKeyTasks />,
    },
  ];

  return (
    <Provider>
      <Tabs activeTab={activeTabState} onClick={tabName => setActiveTabState(tabName)}>
        <Tabs.Bar aria-label="Task Content">
          {TABLES.map(table => (
            <Tabs.Tab key={table.name} name={table.name.toLowerCase()}>
              {table.name}
            </Tabs.Tab>
          ))}
        </Tabs.Bar>
        {TABLES.map(table => (
          <Tabs.Content key={table.name} contentFor={table.name.toLowerCase()}>
            {table.content}
          </Tabs.Content>
        ))}
      </Tabs>
    </Provider>
  );
};

export default TaskContent;
