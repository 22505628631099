import React from 'react';
import { Button } from '@arcadiapower/shrike';

interface TaskFilterPanelProps {
  activeMenu: string;
  filterItems: string[];
  updateActiveMenu: (selectedMenu: string) => void;
}
const TaskFilterPanel: React.FC<TaskFilterPanelProps> = (props: TaskFilterPanelProps) => {
  const { activeMenu, filterItems, updateActiveMenu } = props;

  return (
    <>
      {filterItems.map((item, index) => (
        <Button
          fullWidth
          key={item}
          className="mt-5"
          backgroundColor={activeMenu === item ? 'primaryInverse' : 'primary'}
          onClick={() => updateActiveMenu(item)}
          size="medium"
        >
          {item}
        </Button>
      ))}
    </>
  );
};

export default TaskFilterPanel;
