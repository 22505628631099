import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { restClient, types, restApis } from '@genability/api';
import { AppThunk } from '../store';
import { GenApiClient } from '../../GenApiClient';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { addNotification } from '../notification/notificationSlice';
import { RootState } from '../rootReducer';
import { IPropertySearchOption } from '@arcadiapower/gen-react-lib';

export interface GenPropertyKeysState {
  propertyKeysByKeyName: Record<string, types.GenPropertyKey>;
  count: number;
  filteredPropertyKeys: types.GenPropertyKey[];
  isLoading: boolean;
  error: string | null;
}

interface GenPropertyKeysSuccess {
  propertyKeys: types.GenPropertyKey[];
  count: number;
}

interface GenPropertyKeySuccess {
  propertyKey: types.GenPropertyKey;
}

export const initialState: GenPropertyKeysState = {
  propertyKeysByKeyName: {},
  count: 0,
  filteredPropertyKeys: [],
  isLoading: false,
  error: null,
};

function startLoading(state: GenPropertyKeysState) {
  state.isLoading = true;
}

function loadingFailed(state: GenPropertyKeysState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

export enum PropertyKeyField {
  KEY_NAME = 'keyName',
  DESCRIPTION = 'description',
  DISPLAY_NAME = 'displayName',
}

export const propertyKeys = createSlice({
  name: 'propertyKeys',
  initialState,
  reducers: {
    getPropertyKeysStart: startLoading,
    getPropertyKeysSuccess(state, { payload }: PayloadAction<GenPropertyKeysSuccess>) {
      const { propertyKeys, count } = payload;
      state.isLoading = false;
      state.error = null;
      propertyKeys.forEach(propertyKey => {
        state.propertyKeysByKeyName[propertyKey.keyName] = propertyKey;
      });
      state.filteredPropertyKeys = propertyKeys;
      state.count = count;
    },
    getPropertyKeySuccess(state, { payload }: PayloadAction<GenPropertyKeySuccess>) {
      const { propertyKey } = payload;
      state.isLoading = false;
      state.error = null;
      state.propertyKeysByKeyName[propertyKey.keyName] = propertyKey;
    },
    getPropertyKeysFailure: loadingFailed,
  },
});

export const {
  getPropertyKeysStart,
  getPropertyKeysSuccess,
  getPropertyKeySuccess,
  getPropertyKeysFailure,
} = propertyKeys.actions;

export default propertyKeys.reducer;

export const fetchPropertyKey =
  (keyName: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(getPropertyKeysStart());
      const client = await GenApiClient();
      const response: restClient.SingleResponse<types.GenPropertyKey> =
        await client.properties.getPropertyKey(keyName);
      if (response.errors) {
        const errorMessage = response.errors[0].message;
        dispatch(getPropertyKeysFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      } else if (response.result) {
        dispatch(getPropertyKeySuccess({ propertyKey: response.result }));
      }
    } catch (err: any) {
      if (err) {
        const errorMessage = err.message ? err.message : err.toString();
        dispatch(getPropertyKeysFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };

export const fetchPropertyKeys =
  (options: Partial<IPropertySearchOption>): AppThunk =>
  async dispatch => {
    try {
      const {
        lseId,
        propertyDataType,
        searchTerm,
        startsWith,
        propertyKeySpace,
        propertyFamily,
        includeGlobalProperties,
        filterByLseId,
        pageNumber,
      } = options;
      dispatch(getPropertyKeysStart());
      const client = await GenApiClient();
      const request: restApis.GetPropertyKeysRequest = new restApis.GetPropertyKeysRequest();
      if (lseId !== undefined && filterByLseId) {
        request.entityType = 'LSE';
        request.entityId = lseId;
      }
      if (propertyDataType != null) {
        request.dataType = propertyDataType;
      }
      request.excludeGlobal =
        includeGlobalProperties === undefined ? false : !includeGlobalProperties;
      request.pageCount = 25;
      request.pageStart = (pageNumber || 0) * 25;
      if (searchTerm) {
        request.search = searchTerm;
        request.searchOn = [
          PropertyKeyField.KEY_NAME,
          PropertyKeyField.DESCRIPTION,
          PropertyKeyField.DISPLAY_NAME,
        ];
      }
      if (startsWith) {
        request.startsWith = startsWith;
      }
      if (propertyKeySpace) {
        request.keySpace = propertyKeySpace;
      }
      if (propertyFamily) {
        request.family = propertyFamily;
      }

      const response: restClient.PagedResponse<types.GenPropertyKey> =
        await client.properties.getPropertyKeys(request);
      const propertyKeys: types.GenPropertyKey[] = response.results;
      const count = response.count;
      dispatch(getPropertyKeysSuccess({ propertyKeys, count }));
    } catch (err: any) {
      if (err) {
        const errorMessage = err.message ? err.message : err.toString();
        dispatch(getPropertyKeysFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };

export const fetchTariffPropertyKeys =
  (tariff: types.Tariff): AppThunk =>
  async dispatch => {
    const propertyKeys: Set<string> = types.uniquePropertyKeys(tariff);
    propertyKeys.forEach((key: string) => {
      dispatch(fetchPropertyKey(key));
    });
  };

export const selectPropertyKeysLoading = (state: RootState): boolean => {
  return state.propertyKeys.isLoading;
};

export const selectPropertyKey = (keyName: string | undefined) => {
  return (state: RootState): types.GenPropertyKey | null =>
    keyName ? state.propertyKeys.propertyKeysByKeyName[keyName] : null;
};

export const selectAllPropertyKeysByKeyName = (
  state: RootState
): Record<string, types.GenPropertyKey> => {
  return state.propertyKeys.propertyKeysByKeyName;
};

export const selectAllPropertyKeysList = (state: RootState): types.GenPropertyKey[] => {
  const list: types.GenPropertyKey[] = [];
  if (state.propertyKeys.propertyKeysByKeyName) {
    for (const keyName in state.propertyKeys.propertyKeysByKeyName) {
      list.push(state.propertyKeys.propertyKeysByKeyName[keyName]);
    }
  }
  return list;
};

export const selectFilteredPropertyKeys = (state: RootState): types.GenPropertyKey[] => {
  return state.propertyKeys.filteredPropertyKeys;
};

export const selectFilteredPropertyKeyCount = (state: RootState): number => {
  return state.propertyKeys.count;
};
