import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Badge, Icon, Switch, Tooltip, Text } from '@arcadiapower/shrike';
import { TaskStatusType } from '../../task-api/v2/types/TaskStatusType';
import {
  WorkFlowConfigResponse,
  WorkFlowConfigState,
} from '../../task-api/v2/types/WorkflowConfigResponse';
import { WorkflowStateResponse } from '../../task-api/v2/types/WorkflowStateResponse';

interface ProgressTrackerProps {
  workflowConfig: WorkFlowConfigResponse | null;
  workflowStates: WorkflowStateResponse[] | null;
  currentTaskState?: string;
  className?: string;
  fullWidth?: boolean;
}

const TASK_STATUS = Object.values(TaskStatusType).map(filter => filter);
const SHOW_WORKFLOW_STATUS = 'Show Workflow Status';
const SHOW_TASK_STATUS = 'Show Task Status';

const ProgressTracker: React.FC<ProgressTrackerProps> = ({
  workflowConfig,
  currentTaskState,
  className,
  workflowStates,
  fullWidth = true,
}) => {
  const divRef = useRef<HTMLDivElement | null>(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [cursorStyle, setCursorStyle] = useState('pointer');

  const [showWorkFlowState, setShowWorkFlowState] = useState(false);
  const [toggleBarLabel, setToggleBarLabel] = useState(SHOW_WORKFLOW_STATUS);

  const currentWorkflowState: string | null =
    workflowStates == null ? null : workflowStates[workflowStates?.length - 1].workflowStateName;

  const filteredWorkflowConfigStates: WorkFlowConfigState[] | null = workflowConfig?.states
    ? Object.values(workflowConfig.states).filter(state => {
        //Skip the state if it has toBehidden or isSkipped flag.
        const workflowState = workflowStates?.find(ws => ws.workflowStateName === state.name);
        return (
          (state.toBeHidden == null || state.toBeHidden === false) &&
          (workflowState == null || workflowState.isSkipped === false)
        );
      })
    : null;

  const currentIndex =
    currentWorkflowState && filteredWorkflowConfigStates
      ? filteredWorkflowConfigStates.findIndex(state => state.name === currentWorkflowState)
      : -1;

  const currentTaskStatusIndex = currentTaskState
    ? TASK_STATUS.findIndex(state => state === currentTaskState)
    : -1;

  const totalStates = TASK_STATUS.length;
  const hasProcessBeenCompleted = currentTaskState === TASK_STATUS[totalStates - 1];
  // const totalSteps = totalStates;
  // const processCompletionPercentage = Math.round(((currentTaskStatusIndex + 1) / totalSteps) * 100);
  // const maxValue = 100;
  const taskStatusLength = Math.min(totalStates + 1.5, 12);
  const progressBarLength = Math.min(totalStates + 1, 12);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsMouseDown(true);
    setStartX(e.pageX - (divRef.current?.offsetLeft ?? 0));
    setScrollLeft(divRef.current?.scrollLeft ?? 0);
    setCursorStyle('grabbing');
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
    setCursorStyle('pointer');
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isMouseDown) return;
    e.preventDefault();
    const offsetLeft = divRef.current?.offsetLeft ?? 0;
    const x = e.pageX - offsetLeft;
    const walk = (x - startX) * 3;
    const currentDiv = divRef.current;
    if (currentDiv) {
      currentDiv.scrollLeft = scrollLeft - walk;
    }
  };

  useEffect(() => {
    const toggleBarLabel = showWorkFlowState ? SHOW_TASK_STATUS : SHOW_WORKFLOW_STATUS;
    setToggleBarLabel(toggleBarLabel);
  }, [showWorkFlowState]);

  return (
    <Container fluid className={className}>
      <Row>
        <Col xs={6} className="d-flex flex-row">
          <Text
            color="primary"
            opacity="high"
            tag="h3"
            textStyle="heading700"
            className="ml-2 mt-3"
          >
            Task/Workflow Progress
          </Text>
        </Col>
        <Col xs={6} className="d-flex flex-row-reverse">
          <Switch
            className="mt-4"
            checked={showWorkFlowState}
            label={toggleBarLabel}
            onChange={() => setShowWorkFlowState(!showWorkFlowState)}
          />
        </Col>
      </Row>
      {showWorkFlowState && (
        <Row className="mt-5 d-flex justify-content-center">
          <Col xs={12}>
            <div
              className="d-flex flex-nowrap pb-4"
              style={{ overflowY: 'auto', cursor: cursorStyle }}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
              onMouseMove={handleMouseMove}
              ref={divRef}
            >
              {filteredWorkflowConfigStates &&
                filteredWorkflowConfigStates.map((state, index) => (
                  <React.Fragment key={state.name}>
                    <Tooltip
                      backgroundColor="primary"
                      content={state.label}
                      label={state.name}
                      tooltipId={`Workflow ${state.name}.`}
                      place="top"
                      tooltippedElementWrapperWidth="fit-content"
                      fontSize={400}
                    >
                      <Badge
                        backgroundColor={
                          currentIndex > index || hasProcessBeenCompleted
                            ? 'primaryInverse'
                            : currentIndex === index
                            ? 'accent8'
                            : 'tertiary'
                        }
                        size="small"
                        className="p-3"
                      >
                        {state.name}
                      </Badge>
                    </Tooltip>
                    {index !== filteredWorkflowConfigStates.length - 1 && (
                      <Icon
                        color="primary"
                        icon="LinkArrow"
                        className="mt-2 pt-1"
                        opacity="high"
                        scale={3}
                        title="ChevronRight"
                      />
                    )}
                  </React.Fragment>
                ))}
            </div>
          </Col>
        </Row>
      )}

      {!showWorkFlowState && (
        <>
          <Row className={`d-flex ${fullWidth ? 'justify-content-center' : 'ml-2'}`}>
            {/* <Col xs={12} sm={12} md={11} lg={10}> */}
            <Col xs={taskStatusLength}>
              <div>
                {TASK_STATUS.map((status, index) => (
                  <React.Fragment key={index}>
                    <Badge
                      backgroundColor={
                        currentTaskStatusIndex > index || hasProcessBeenCompleted
                          ? 'primaryInverse'
                          : currentTaskStatusIndex === index
                          ? 'accent8'
                          : 'tertiary'
                      }
                      size="small"
                      className="p-3"
                    >
                      {status}
                    </Badge>
                    {index !== TASK_STATUS.length - 1 && (
                      <Icon
                        color="primary"
                        icon="LinkArrow"
                        className="mt-5 pt-2"
                        opacity="high"
                        scale={3}
                        title="ChevronRight"
                      />
                    )}
                  </React.Fragment>
                ))}
                {/* <ProgressBar
                  className="mt-4"
                  currentValue={processCompletionPercentage}
                  complete={hasProcessBeenCompleted}
                /> */}
              </div>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col xs={progressBarLength}>
              {/* <ProgressBar
                className="mt-4"
                currentValue={processCompletionPercentage}
                complete={hasProcessBeenCompleted}
                maxValue={maxValue}
              /> */}
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default ProgressTracker;
