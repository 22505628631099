import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { restClient } from '@genability/api';
import { TaskApiClient } from '../../GenApiClient';
import { FetchSingleApiResponse } from '../../utils/apiResponseTypes';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { addNotification } from '../notification/notificationSlice';
import { handleUnexpectedThunkException } from '../reduxUtils';
import { RootState } from '../rootReducer';
import { IWorker } from '../../task-api/types/worker';

export interface WorkerState {
  apiStatus: 'idle' | 'pending' | 'notfound' | 'resolved' | 'rejected';
  result: IWorker | undefined;
  errors?: Array<restClient.ResponseError> | null;
}

export const initialState: WorkerState = {
  apiStatus: 'idle',
  result: undefined,
  errors: null,
};

interface WorkerOptionsProps {
  username: string;
}

export const fetchWorker = createAsyncThunk(
  'worker/fetchWorker',
  async (options: WorkerOptionsProps, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiClient();
      const response: restClient.SingleResponse<IWorker> = await client.worker.getWorker(
        options.username
      );
      if (response?.errors) {
        const errorMessage = response.errors[0].message;
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
      return { result: response.result, errors: response.errors || [] };
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'SingleWorker', dispatch));
    }
  }
);

export const WorkerSlice = createSlice({
  name: 'worker',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchWorker.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(
      fetchWorker.fulfilled,
      (state, action: PayloadAction<FetchSingleApiResponse<IWorker>>) => {
        const { result, errors } = action.payload;
        if (errors?.length) {
          if (errors[0] && errors[0].code == 'ObjectNotFound') {
            state.apiStatus = 'notfound';
          } else {
            state.apiStatus = 'rejected';
          }
          state.errors = errors;
          state.result = undefined;
        } else {
          state.apiStatus = 'resolved';
          if (result) state.result = result;
          state.errors = undefined;
        }
      }
    );
    builder.addCase(fetchWorker.rejected, state => {
      state.apiStatus = 'rejected';
      state.result = undefined;
    });
  },
});

export const selectWorkerApiStatus = (state: RootState): string => {
  return state.worker.apiStatus;
};

export const selectWorker = (state: RootState): IWorker | undefined => {
  return state.worker.result;
};
export default WorkerSlice.reducer;
