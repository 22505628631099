import { restClient } from '@genability/api';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { OpsManagementApiClient } from '../../../GenApiClient';
import { handleUnexpectedThunkException } from '../../reduxUtils';
import { RootState } from '../../rootReducer';

export interface TariffConfigStatsType {
  totalLse: number;
  totalActiveTariff: number;
  assigendTariff: number;
  unAssigendTariff: number;
  totalLseAssignedToCurrentUser: number;
  totalActiveTariffAssignedToCurrentUser: number;
}
export interface TariffConfigStatsResponse {
  results: TariffConfigStatsType[];
  apiStatus: 'idle' | 'pending' | 'resolved' | 'rejected';
  errors: restClient.ResponseError[] | undefined;
  count: number;
}
const initialState: TariffConfigStatsResponse = {
  results: [],
  apiStatus: 'pending',
  count: 0,
  errors: undefined,
};

export const fetchTariffConfigStats = createAsyncThunk(
  'opsManagement/config/stats/tariff',
  async (request: any, { dispatch, rejectWithValue }) => {
    try {
      const client = await OpsManagementApiClient();
      const response: restClient.PagedResponse<TariffConfigStatsType> =
        await client.opsData.tariffConfigStats();
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
      return response;
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'TariffConfigStats', dispatch));
    }
  }
);

export const TariffConfigStatsSlice = createSlice({
  name: 'tariffConfigStats',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchTariffConfigStats.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(
      fetchTariffConfigStats.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<TariffConfigStatsType>>) => {
        const { count, results, errors } = action.payload;
        if (errors) {
          state.apiStatus = 'rejected';
          state.errors = errors;
        } else {
          state.apiStatus = 'resolved';
          state.count = count;
          state.results = results;
          state.errors = undefined;
        }
      }
    );
    builder.addCase(fetchTariffConfigStats.rejected, state => {
      state.apiStatus = 'rejected';
    });
  },
});

export default TariffConfigStatsSlice.reducer;

export const selectTariffConfigStats = (state: RootState): TariffConfigStatsResponse => {
  return state.tariffConfigStatsData;
};
