import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../rootReducer';

import { TaskCommentApiState, TasksApiState } from '../../utils/taskV2Utils';
import { restClient } from '@genability/api';
import { TaskApiV2Client } from '../../GenApiClient';
import { handleUnexpectedThunkException } from '../reduxUtils';

const initialState: TaskCommentApiState = {
  apiStatus: 'idle',
  count: 0,
  errors: undefined,
};

export const addComment = createAsyncThunk(
  'v2/tasks/addComment',
  async (
    { taskId, comment }: { taskId: number; comment: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const client = await TaskApiV2Client();
      const response: restClient.SingleResponse<void> = await client.taskV2.addComment(taskId, {
        comment,
      });
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      return response;
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'Task', dispatch));
    }
  }
);

export const taskCommentSlice = createSlice({
  name: 'taskComment',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(addComment.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(
      addComment.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<void>>) => {
        const { errors } = action.payload;
        if (errors) {
          state.apiStatus = 'rejected';
          state.errors = errors;
        } else {
          state.apiStatus = 'resolved';
        }
      }
    );
    builder.addCase(addComment.rejected, state => {
      state.apiStatus = 'rejected';
    });
  },
});

export const selectTaskComment = (state: RootState): TaskCommentApiState => {
  return state.taskComment;
};

export default taskCommentSlice.reducer;
