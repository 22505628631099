const appConstants = {
  routes: {
    completed: '/completed',
    do: '/do',
    draft: '/draft',
    review: '/review',
    settings: '/settings',
    directory: '/directory',
    dashboard: '/dashboard',
    taskDetails: '/task-details',
    propertykeyDirectory: '/directory/property-keys',
    tariff: '/directory/tariffs',
    opsManagement: '/opsManagement',
  },
  allGroups: [
    'GenabilityDataAdmin',
    'GenabilityDataManager',
    'TeamTariffReviewer',
    'TeamTariffRookie',
    'TeamTariffWorker',
  ],
  viewJsonDropdown: {
    taskAssignment: 'Task Assignment',
    document: 'Document',
    tariffAnswer: 'Tariff Answer',
    tariffSource: 'Tariff Source',
    riders: 'Riders',
    territories: 'Territories',
    seasons: 'Seasons',
    timeOfUse: 'Time Of Use',
  },
  menuDropdown: {
    viewJson: 'View JSON',
    motherLinksHeader: 'Mother links',
    copyTariff: 'Copy Tariff',
  },
  motherLinks: {
    task: 'Task',
    taskAssignment: 'Task Assignment',
    lse: 'LSE',
  },
  addedTariffRateIds: {
    min: 1,
    max: 500,
  },
  statsFilterTableHeader: {
    'My Tasks': {
      title: 'Your Tasks by status stats :',
      tableHeader: ['To Do', 'In Progress', 'Posted/Submitted', 'Under Review'],
    },
    Unassigned: {
      title: 'Open Tasks by Task stats :',
      tableHeader: ['Add Tariff', 'Edit Tariff', 'Revise Tariff'],
    },
    Completed: {
      title: 'Your Tasks Completion stats :',
      tableHeader: ['Last Month', 'This Month', 'Last Week', 'This Week'],
    },
  },
  statusScreen: {
    dueInDays: 5,
  },
};

export default appConstants;
