import React from 'react';
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FieldErrors,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegisterReturn,
  UseFormSetError,
} from 'react-hook-form';
import { get, isString } from 'lodash';
import { restClient } from '@genability/api';
import { PropertyGroups } from '../components/WorkBody/Panels/EligibilityPanel';
import { FormErrorObject } from '../components/ApiFormError/ApiFormError';
import DQContextMenu from '../components/DQOverride/DQContextMenu';

interface WithErrorsOptions {
  errors: FieldErrors;
  errorPrefix?: string;
}

export enum ApiErrorType {
  SYSTEM = 'SystemError',
}

/**
 * Maps Api errors with corresponding fields from any form
 * @param {restClient.ResponseError[]} errors errors returned by the api
 * @param {UseFormSetError} setError handler from useFormHook to set errors on fields
 * @param {string[] | Partial} fields names list
 */
export const mapApiErrors = <TFieldValues extends FieldValues = FieldValues>(
  errors: restClient.ResponseError[],
  setError: UseFormSetError<TFieldValues>,
  fields: string[] | Partial<TFieldValues>
): void => {
  errors.forEach(error => {
    const isArray = Array.isArray(fields);
    if (error.code.toLowerCase() === ApiErrorType.SYSTEM.toLowerCase() || !error.propertyName)
      return;
    let matchCount = 0;
    if (!isArray) {
      if (Object.keys(fields).some(key => key === error.propertyName?.split('.')[0])) {
        matchCount++;
        setError(
          error.propertyName as Path<TFieldValues>,
          { type: error.code, message: error.message },
          { shouldFocus: true }
        );
      } else if ('propertyGroups' in fields) {
        // TODO: Top-level field errors currently don't return useful info from the
        // server, but property errors do.
        const propertyGroups = fields['propertyGroups'] as PropertyGroups;
        Object.entries(propertyGroups).some(([propertyGroupKey, properties]) => {
          if (matchCount) return true;
          return properties?.some((property, index) => {
            if (property.keyName == error.propertyName) {
              matchCount++;
              setError(
                `propertyGroups.${propertyGroupKey}.${index}.propertyValue` as Path<TFieldValues>,
                {
                  type: error.code,
                  message: error.message,
                },
                { shouldFocus: true }
              );
              return true;
            }
          });
        });
      }
    } else if (fields.some(key => key === error.propertyName?.split('.')[0])) {
      matchCount++;
      setError(
        error.propertyName as Path<TFieldValues>,
        { type: error.code, message: error.message },
        { shouldFocus: true }
      );
    }
    if (!matchCount) {
      setError(
        `noMatchedProperty_${error.propertyName}` as Path<TFieldValues>,
        {
          type: error.code,
          message: error.message,
        },
        { shouldFocus: true }
      );
    }
  });
};

export const withErrors = function (
  register: (fieldName: any, options?: Partial<RegisterOptions>) => Partial<UseFormRegisterReturn>,
  { errors, errorPrefix }: WithErrorsOptions
) {
  return function (
    fieldName: Parameters<typeof register>[0],
    options: Parameters<typeof register>[1] = {}
  ): ReturnType<typeof register> & {
    isInvalid: boolean;
    feedback: string | undefined;
    feedbackAs?: React.ElementType;
    errorProps?: { errorType: string; errorPrefix?: string; propertyName: string };
  } {
    const props = register(fieldName, options);
    const fieldError = get(errors, fieldName, false);

    let feedback = '';
    let errorType = '';
    if (fieldError) {
      feedback = isString(fieldError.message) ? fieldError.message : '';
      errorType = isString(fieldError.type) ? fieldError.type : '';
    }

    return {
      ...props,
      isInvalid: !!fieldError,
      feedback,
      errorProps: {
        errorType,
        propertyName: fieldName,
        errorPrefix,
      },
      feedbackAs: errorType === 'DQFailure' ? DQContextMenu : undefined,
    };
  };
};

interface WithFieldArrayOptions {
  field: any;
  name: string;
}

export const withFieldArray = function (
  register: (fieldName: any, options?: Partial<RegisterOptions>) => Partial<UseFormRegisterReturn>,
  { field, name }: WithFieldArrayOptions
) {
  return function (
    fieldName: keyof typeof field,
    options: Parameters<typeof register>[1] & { check?: string } = {}
  ): ReturnType<typeof register> & {
    defaultValue?: string;
    defaultChecked?: boolean;
  } {
    const { check, ...registerOptions } = options;
    const props = register(`${name}.${String(fieldName)}`, registerOptions);
    return {
      ...props,
      defaultValue: !check ? (field?.[fieldName] as string) || undefined : undefined,
      defaultChecked: check ? (field?.[fieldName] as string) == check : false,
    };
  };
};

/**
 * Method to map api response errors to form errors.
 */
export const mapToFormErrorObject = (errors: restClient.ResponseError[]): FormErrorObject => {
  const errorObj: FormErrorObject = {};
  errors.forEach(
    error =>
      (errorObj[`noMatchedProperty_${error.code}`] = {
        type: error.code,
        message: error.message,
      })
  );
  return errorObj;
};
