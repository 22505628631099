import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import { Notification } from '@arcadiapower/gen-react-lib';
import notifications from './notification/notificationSlice';
import currentUser, { CurrentUser } from './currentUser/currentUserSlice';
import task, { TaskState } from './task/taskSlice';
import tasks, { TasksState } from './tasks/tasksSlice';
import userList from './userList/userListSlice';
import taskAssignmentV2 from './taskAssignment_V2/taskAssignmentV2Slice';
import taskComment from './taskComment_V2/taskCommentSlice';
import tariffTasks from './tariffTasks/tariffTasksSlice';
import lookupTasks from './lookup/lookupSlice';
import tariffDetails from './tariffDetails/tariffDetailsSlice';
import taskFilter, { TaskFilterState } from './taskFilter/taskFilterSlice';
import dashboardFilter, { DashboardFilterState } from './dashboardFilter/dashboardFilterSlice';
import taskDetails from './taskDetails/TaskDetailSlice';
import taskStats from './taskStats/taskStatsSlice';
import workFlowConfig from './workflowConfig/WorkFlowConfigSlice';
import taskAssignments, { TaskAssignmentsState } from './taskAssignments/taskAssignmentsSlice';
import taskAssignment, { TaskAssignmentState } from './taskAssignment/taskAssignmentSlice';
import territories, { TerritoryState } from './territories/territoriesSlice';
import timeOfUses, { TimeOfUseState } from './timeOfUses/timeOfUseSlice';
import taskAssignmentTariffs, {
  TaskAssignmentTariffState,
} from './taskAssignmentTariffs/taskAssignmentTariffsSlice';
import riderTariffs, { RiderTariffsState } from './riderTariffs/riderTariffsSlice';
import taskComments, { TaskCommentsState } from './taskComments/taskCommentsSlice';
import document, { DocumentState } from './document/documentSlice';
import propertyKeys, { GenPropertyKeysState } from './propertyKeys/propertyKeysSlice';
import lookupValues, { GenLookupValuesState } from './lookupValues/lookupValuesSlice';
import workers, { WorkersState } from './workers/workersSlice';
import worker, { WorkerState } from './worker/workerSlice';
import dQCheck, { DQCheckState } from './dqCheck/dqCheckSlice';
import loadServingEntity, {
  LoadServingEntityState,
} from './loadServingEntity/loadServingEntitySlice';
import tariffs, { ITariffState } from './tariffs/tariffsSlice';
import tariff, { TariffState } from './tariff/tariffSlice';
import costCalculation, { ICostCalculationState } from './costCalculation/costCalculation';
import tariffHistory, { TariffHistoryState } from './tariff/tariffHistorySlice';
import tariffDiff, { TariffDiffState } from './tariff/tariffDiffSlice';
import utilities, { IUtilitiesState } from './utilities/utilitiesSlice';
import loadServingEntityById, {
  LoadServingEntityByIdState,
} from './loadServingEntity/loadServingEntityByIdSlice';
import {
  TaskAssignmentApiState,
  TaskCommentApiState,
  TaskStatsApiState,
  TasksApiState,
  TasksDetailsApiState,
  UserListState,
  WorkflowConfigApiState,
  TariffDetailApiState,
} from '../utils/taskV2Utils';
import lookupValuesByPropertyKey, {
  GenLookupValuesByPropertyKeyState,
} from './lookupValues/lookupValuesByPropertyKey';
import lookupStats, { GenLookupStatsState } from './lookupStats/lookupStatsSlice';
import taskWorkflowEntity, { TaskWorkflowState } from './tasksV2/CreateLookupTaskSlice';
import unpublishedLookupTasks, {
  UnpublishedLookupTaskState,
} from './tasksV2/UnpublishedLookupTaskSlice';
import intermediateSaveTask, { IntermediateSaveState } from './tasksV2/intermediateSaveTaskSlice';
import taskWorkflowEntityPostAction, { TasksActionState } from './tasksV2/TasksActionSlice';
import workflow, { WorkflowState } from './tasksV2/WorkflowStateSlice';
import updatedTaskAssignmentAnswer, {
  LookupGapFillingState,
} from './tasksV2/LookupsGapFillingSlice';
import propertyKeysV2, { PropertyKeysV2State } from './propertyKeys/propertyKeysV2Slice';
import reportingConfig, { ReportingConfigState } from './reportingConfig/reportingConfigSlice';
import documentV2, { DocumentV2State } from './document-v2/documentSlice';
import documentSectionV2, { DocumentSectionV2State } from './document-v2/documentSectionSlice';
import propertykeyTasks from './propertyKeyTasksSlice/propertyKeyTasksSlice';
import workersV2, { WorkersV2State } from './workers-v2/workersSlice';
import quickSearchData, { QuickSearchResponse } from './QuickSearch/QuickSearchSlice';
import lookupConfigData, {
  LookupConfigDataResponse,
} from './OpsManagement/Config/LookupConfigSlice';
import tariffConfigData, {
  TariffConfigDataResponse,
} from './OpsManagement/Config/TariffConfigSlice';
import lookupStatusData, {
  LookupStatusDataResponse,
} from './OpsManagement/Status/LookupStatusSlice';
import tariffStatusData, {
  TariffStatusDataResponse,
} from './OpsManagement/Status/TariffStatusSlice';
import unpublishTariff, { unpublishTariffType } from './UnpublishTariffSlice/UnpublishTariffSlice';
import tariffsByMtid from './tariffs/tariffsByMtid';
import lookupConfigStatsData, {
  LookupConfigStatsResponse,
} from './OpsManagement/Config/LookupConfigStatsSlice';
import tariffConfigStatsData, {
  TariffConfigStatsResponse,
} from './OpsManagement/Config/TariffConfigStatsSlice';
import lookupStatusStatsData, {
  LookupStatusStatsResponse,
} from './OpsManagement/Status/LookupStatusStatsSlice';
import tariffStatusStatsData, {
  TariffStatusStatsResponse,
} from './OpsManagement/Status/TariffStatusStatsSlice';
import updatedMonitoringStatusData, {
  UpdatedMonitoringStatusResponse,
} from './OpsManagement/Status/UpdateStatusSlice';
import monitoringHistory, {
  MonitoringHistoryState,
} from './OpsManagement/Status/MonitoringHistorySlice';
import monitoringStatusSentinelDates from '../state/OpsManagement/Status/SentinelDatesSlice';

const allReducers = combineReducers({
  notifications,
  currentUser,
  task,
  tasks,
  taskAssignment,
  taskAssignmentTariffs,
  taskAssignments,
  riderTariffs,
  taskComments,
  territories,
  timeOfUses,
  document,
  propertyKeys,
  lookupStats,
  lookupValues,
  lookupValuesByPropertyKey,
  worker,
  workers,
  dQCheck,
  loadServingEntity,
  loadServingEntityById,
  tariffs,
  tariffsByMtid,
  tariff,
  costCalculation,
  tariffHistory,
  tariffDiff,
  utilities,
  tariffTasks,
  taskFilter,
  dashboardFilter,
  lookupTasks,
  taskWorkflowEntity,
  intermediateSaveTask,
  unpublishedLookupTasks,
  taskWorkflowEntityPostAction,
  userList,
  taskDetails,
  taskAssignmentV2,
  workFlowConfig,
  workflow,
  taskComment,
  updatedTaskAssignmentAnswer,
  taskStats,
  tariffDetails,
  propertyKeysV2,
  reportingConfig,
  documentV2,
  documentSectionV2,
  propertykeyTasks,
  workersV2,
  tariffConfigData,
  lookupConfigData,
  unpublishTariff,
  tariffStatusData,
  lookupStatusData,
  quickSearchData,
  lookupConfigStatsData,
  tariffConfigStatsData,
  lookupStatusStatsData,
  tariffStatusStatsData,
  updatedMonitoringStatusData,
  monitoringHistory,
  monitoringStatusSentinelDates,
});

interface CombinedStateType {
  notifications: Notification[];
  currentUser: CurrentUser;
  task: TaskState;
  tasks: TasksState;
  taskAssignment: TaskAssignmentState;
  taskAssignmentTariffs: TaskAssignmentTariffState;
  taskAssignments: TaskAssignmentsState;
  riderTariffs: RiderTariffsState;
  taskComments: TaskCommentsState;
  territories: TerritoryState;
  timeOfUses: TimeOfUseState;
  document: DocumentState;
  propertyKeys: GenPropertyKeysState;
  lookupStats: GenLookupStatsState;
  lookupValues: GenLookupValuesState;
  lookupValuesByPropertyKey: GenLookupValuesByPropertyKeyState;
  worker: WorkerState;
  workers: WorkersState;
  dQCheck: DQCheckState;
  loadServingEntity: LoadServingEntityState;
  loadServingEntityById: LoadServingEntityByIdState;
  tariffs: ITariffState;
  tariffsByMtid: ITariffState;
  tariff: TariffState;
  costCalculation: ICostCalculationState;
  tariffHistory: TariffHistoryState;
  tariffDiff: TariffDiffState;
  utilities: IUtilitiesState;
  tariffTasks: TasksApiState;
  taskFilter: TaskFilterState;
  dashboardFilter: DashboardFilterState;
  lookupTasks: TasksApiState;
  taskWorkflowEntity: TaskWorkflowState;
  unpublishedLookupTasks: UnpublishedLookupTaskState;
  intermediateSaveTask: IntermediateSaveState;
  taskWorkflowEntityPostAction: TasksActionState;
  userList: UserListState;
  taskDetails: TasksDetailsApiState;
  taskAssignmentV2: TaskAssignmentApiState;
  workFlowConfig: WorkflowConfigApiState;
  workflow: WorkflowState;
  taskComment: TaskCommentApiState;
  updatedTaskAssignmentAnswer: LookupGapFillingState;
  taskStats: TaskStatsApiState;
  tariffDetails: TariffDetailApiState;
  propertyKeysV2: PropertyKeysV2State;
  reportingConfig: ReportingConfigState;
  documentV2: DocumentV2State;
  documentSectionV2: DocumentSectionV2State;
  propertykeyTasks: TasksApiState;
  workersV2: WorkersV2State;
  tariffConfigData: TariffConfigDataResponse;
  lookupConfigData: LookupConfigDataResponse;
  unpublishTariff: unpublishTariffType;
  tariffStatusData: TariffStatusDataResponse;
  lookupStatusData: LookupStatusDataResponse;
  quickSearchData: QuickSearchResponse;
  lookupConfigStatsData: LookupConfigStatsResponse;
  tariffConfigStatsData: TariffConfigStatsResponse;
  lookupStatusStatsData: LookupStatusStatsResponse;
  tariffStatusStatsData: TariffStatusStatsResponse;
  updatedMonitoringStatusData: UpdatedMonitoringStatusResponse;
  monitoringHistory: MonitoringHistoryState;
  monitoringStatusSentinelDates: TariffStatusDataResponse;
}

const rootReducer = (
  state: CombinedStateType | undefined,
  action: AnyAction
): CombinedStateType => {
  if (action.type === 'currentUser/signOutSuccess') {
    return allReducers(undefined, action);
  }
  return allReducers(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
