import React, { useState, useEffect } from 'react';
import { withRouter, useHistory, useLocation, RouteComponentProps } from 'react-router-dom';
import { ToggleButton, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import {
  Table,
  Th,
  Tr,
  Pagination,
  useSortableData,
  IconButton,
} from '@arcadiapower/gen-react-lib';
import { Task, TaskTypeId } from '../../task-api/types/task';
import { GetTasksRequest } from '../../task-api/api/task-api';
import appConstants from '../../app-constants';
import styles from './Draft.module.scss';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { selectCurrentUser } from '../../state/currentUser/currentUserSlice';
import { onAt } from '../../utils/dateUtils';
import { fetchDraftTasks, selectTasks } from '../../state/tasks/tasksSlice';

const Draft: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const searchParams = new URLSearchParams(props.location.search);
  const history = useHistory();
  const location = useLocation();
  const { results, apiStatus, count } = useSelector(selectTasks);
  const tasks: Task[] = results || [];
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(true);
  const [selected, setSelected] = useState<string | null>('All types');
  const [toggleRefresh, setToggleRefresh] = useState(true);

  const initialPage = Number(searchParams.get('page')) || 1;
  const [page, setPage] = useState(initialPage);
  const username: string = useSelector(selectCurrentUser).username;
  const totalTasks = Number(count);
  const remainder = totalTasks % 100;
  const extraOnePage = remainder === 0 ? 0 : 1;
  const totalPageCount = Math.trunc(totalTasks / 100) + extraOnePage;

  const { sortedItems, sortConfig, handleSort } = useSortableData(tasks);
  const insertUrlParam = (key: string, value: string): void => {
    const pathname = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value);
    history.push(`${pathname}?${searchParams.toString()}`);
  };

  useEffect(() => {
    const request = new GetTasksRequest();
    if (checked) {
      request.worker = username;
    }
    request.populateTaskInputs = true;
    request.pageCount = 100;
    request.pageStart = page * request.pageCount - request.pageCount;
    request.populateTaskAssignments = true;
    dispatch(fetchDraftTasks(request));
  }, [dispatch, page, checked, username, toggleRefresh]);

  const handleSelect = (e: string | null) => {
    setSelected(e);
  };

  const handleSelectPage = (page: number) => {
    insertUrlParam('page', String(page));
    setPage(page);
  };

  const dropDownOptions: string[] = [];
  tasks.forEach(task => {
    if (!dropDownOptions.includes(`${task.taskType.taskTypeId}`)) {
      dropDownOptions.push(`${task.taskType.taskTypeId}`);
    }
  });

  dropDownOptions.unshift('All types');
  const filterBy = (list: Task[], taskType: string | null) => {
    if (taskType !== 'All types') {
      list = list.filter(
        (item: { taskType: { taskTypeId: TaskTypeId | undefined } }) =>
          item.taskType.taskTypeId === taskType
      );
    }
    return list;
  };

  const columns = [
    { key: 'taskId', label: 'Task ID' },
    {
      key: 'taskTypeName',
      label: 'Task Type',
      accessor: (task: Task) => task.taskType.taskTypeName,
    },
    { key: 'createdBy', label: 'Drafted By' },
    { key: 'createdDate', label: 'Drafted On' },
    { key: 'requiredPlurality', label: 'Plurality' },
    { key: 'lseId', label: 'LSE' },
  ];

  return (
    <div className={styles.wrapper}>
      {
        <>
          <div className="row">
            <div className="col d-flex">
              <h1>Draft Tasks </h1>
            </div>
            <div className={`${styles.filter} col justify-content-end`}>
              <div className={styles.checkbox}>
                <ButtonGroup toggle>
                  <ToggleButton
                    type="checkbox"
                    variant="default"
                    checked={checked}
                    value="1"
                    onChange={e => setChecked(e.currentTarget.checked)}
                    block
                  >
                    My Drafts
                  </ToggleButton>
                </ButtonGroup>
              </div>
              <div className={styles.taskType}>
                <DropdownButton
                  alignRight
                  variant="default"
                  title="Task Type"
                  id="dropdown-menu-align-right"
                  onSelect={handleSelect}
                >
                  {dropDownOptions.map(option => (
                    <Dropdown.Item key={option} eventKey={option} active={option === selected}>
                      {option}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>
              <div>
                <IconButton
                  icon="refresh"
                  spin={apiStatus === 'idle' || apiStatus === 'pending'}
                  onClick={() => setToggleRefresh(!toggleRefresh)}
                />
              </div>
            </div>
          </div>
          <div>
            <div>
              <Table hover>
                <thead>
                  <tr>
                    {columns.map((column, i) => (
                      <Th
                        key={i}
                        sortConfig={sortConfig}
                        sortKey={column.key}
                        onClick={() => handleSort(column.key, column.accessor)}
                      >
                        {column.label}
                      </Th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filterBy(sortedItems, selected).map((task: Task) => {
                    return (
                      <Tr
                        key={task.taskId}
                        onClick={() => history.push(`${appConstants.routes.draft}/${task.taskId}`)}
                      >
                        <td>{task.taskId}</td>
                        <td>{task.taskType.taskTypeName}</td>
                        <td>{task.createdBy}</td>
                        <td>{onAt(task.createdDate)}</td>
                        <td>{task.requiredPlurality}</td>
                        <td>{task.lseId}</td>
                      </Tr>
                    );
                  })}
                  {tasks.length === 0 && (
                    <tr>
                      <td className="no-results" colSpan={12}>
                        No results
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <Pagination
              activePage={page}
              totalPages={totalPageCount}
              onSelectPage={handleSelectPage}
            />
          </div>
        </>
      }
    </div>
  );
};

export default withRouter(Draft);
