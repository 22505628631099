import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { restClient, types, restApis } from '@genability/api';
import { GenApiClient } from '../../GenApiClient';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { addNotification } from '../notification/notificationSlice';
import { AppThunk } from '../store';
import { RootState } from '../rootReducer';

export interface RiderTariffsState {
  tariffs: types.Tariff[] | [];
  altLses?: types.TerritoryLse[];
  count: number;
  isLoading: boolean;
  error: string | null;
}

export interface RiderTariffsSuccess {
  count: number;
  tariffs: types.Tariff[];
  altLses?: types.TerritoryLse[];
}

export interface RiderTariffsSelectorFilterOptions {
  effectiveDate?: string;
  serviceType?: types.ServiceType;
  customerClass?: types.CustomerClass | null;
}

const initialState: RiderTariffsState = {
  tariffs: [],
  altLses: [],
  count: 0,
  isLoading: false,
  error: null,
};

function startLoading(state: RiderTariffsState) {
  state.isLoading = true;
}

function loadingFailed(state: RiderTariffsState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

export const riderTariffs = createSlice({
  name: 'riderTariffs',
  initialState,
  reducers: {
    getRiderTariffsStart: startLoading,
    getRiderTariffsSuccess(state, { payload }: PayloadAction<RiderTariffsSuccess>) {
      const { tariffs, count } = payload;
      state.count = count;
      state.tariffs = tariffs;
      state.isLoading = false;
      state.error = null;
    },
    getRiderTariffsFailure: loadingFailed,
  },
});

export const { getRiderTariffsStart, getRiderTariffsSuccess, getRiderTariffsFailure } =
  riderTariffs.actions;

export default riderTariffs.reducer;

interface RidersRequestParams {
  lseId: number | undefined;
  populateProperties: boolean;
  populateRates: boolean;
  territoryId?: number;
  effectiveDate?: string;
  serviceType?: types.ServiceType;
  customerClass?: types.CustomerClass;
  pageStart?: number;
  pageCount?: number;
}

function createRidersRequest(params: RidersRequestParams) {
  const tariffsParams = new restApis.GetTariffsRequest();

  tariffsParams.tariffTypes = [types.TariffType.RIDER];

  tariffsParams.pageCount = params.pageCount || 25;
  tariffsParams.pageStart = params.pageStart || 0;
  tariffsParams.lseId = params.lseId;
  tariffsParams.populateProperties = params.populateProperties;
  tariffsParams.populateRates = params.populateRates;
  if (params.effectiveDate) tariffsParams.effectiveOn = params.effectiveDate;
  if (params.serviceType) tariffsParams.serviceTypes = [params.serviceType];
  if (params.customerClass) tariffsParams.customerClasses = [params.customerClass];
  if (params.territoryId) tariffsParams.territoryId = params.territoryId;

  return tariffsParams;
}

/**
 * Fetches LSEs that this tariff's territory are connected to, for cases
 * such as deregulated utilities or CCAs
 * @param {number} territoryId - The territoryId of the tariff for which we're fetching riders.
 */
export const fetchAltLses = async (territoryId: number): Promise<types.TerritoryLse[]> => {
  const client = await GenApiClient();
  const territoriesRequest = new restApis.GetTerritoriesRequest();

  territoriesRequest.populateLses = true;

  const response: restClient.PagedResponse<types.Territory> = await client.territories.getTerritory(
    territoryId,
    territoriesRequest
  );

  if (response.results.length === 0) return [];

  return response.results[0].territoryLses?.list || [];
};

export const fetchAllRiderTariffs =
  (params: RidersRequestParams): AppThunk =>
  async dispatch => {
    try {
      dispatch(getRiderTariffsStart());
      let count;
      let pageStart = 0;
      const pageCount = 100;
      let loop = true;
      const results: types.Tariff[] = [];
      const altLses = params.territoryId ? await fetchAltLses(params.territoryId) : [];
      const altLseIds = altLses?.map((altLse: types.TerritoryLse) => altLse.lseId);
      const client = await GenApiClient();

      // TODO: Get the first one for the count, then get the rest via Promise.all instead of awaiting each result
      while (loop) {
        const ridersRequest = createRidersRequest({
          ...params,
          pageStart: pageStart,
          pageCount: pageCount,
        });
        const response: restClient.PagedResponse<types.Tariff> = await client.tariffs.getTariffs(
          ridersRequest
        );
        if (!count) {
          count = response.count;
        }
        results.push(...response.results);

        pageStart += pageCount;

        if (count < pageStart) {
          if (!altLseIds || altLseIds.length === 0) {
            loop = false;
          } else {
            params.lseId = altLseIds.shift();
            count = null;
            pageStart = 0;
          }
        }
      }

      return dispatch(
        getRiderTariffsSuccess({
          count: count || 0,
          tariffs: results,
          altLses,
        })
      );
    } catch (err: any) {
      const errorMessage = err.message ? err.message : err.toString();
      dispatch(addNotification(errorMessage, NotificationLevel.Error));
      return dispatch(getRiderTariffsFailure(errorMessage));
    }
  };

export const selectRiderTariffs = createSelector(
  (state: RootState) => state.riderTariffs.tariffs,
  (_: RootState, filterOptions: RiderTariffsSelectorFilterOptions) => {
    return filterOptions;
  },
  (tariffs: types.Tariff[], filterOptions: RiderTariffsSelectorFilterOptions) => {
    return tariffs.filter(tariff => {
      if (
        filterOptions.customerClass &&
        tariff.customerClass != undefined &&
        tariff.customerClass != null &&
        filterOptions?.customerClass != tariff.customerClass
      ) {
        return false;
      }

      if (
        filterOptions.effectiveDate &&
        tariff.effectiveDate != undefined &&
        tariff.effectiveDate != null &&
        tariff.effectiveDate < filterOptions.effectiveDate
      ) {
        return false;
      }

      if (
        filterOptions.serviceType &&
        tariff.serviceType != null &&
        tariff.serviceType != filterOptions.serviceType
      ) {
        return false;
      }

      return true;
    });
  }
);
