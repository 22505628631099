import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { restClient, types } from '@genability/api';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { TaskApiV2Client } from '../../GenApiClient';
import { addNotification } from '../notification/notificationSlice';
import { RootState } from '../rootReducer';
import { AppThunk } from '../store';
import { TaskWorkflowEntity } from '../../task-api/v2/types/TaskWorkflowEntity';
import { WorkflowStateType } from '../../task-api/v2/types/WorkflowStatesTypes';
import axios from 'axios';

export interface TasksActionState {
  taskWorkflowEntityPostAction: TaskWorkflowEntity | null;
  published: boolean;
  submitLoading: boolean;
  cancelLoading: boolean;
  approveLoading: boolean;
  sendForReworkLoading: boolean;
  publishLoading: boolean;
  proceedToWorkLoading: boolean;
  proceedToReviewLoading: boolean;
  uploadLoading: boolean;
  submitError: string | null;
  cancelError: string | null;
  approveError: string | null;
  sendForReworkError: string | null;
  publishError: string | null;
  proceedToWorkError: string | null;
  proceedToReviewError: string | null;
  uploadError: string | null;
}

interface lookupTasksActionSuccess {
  taskWorkflowEntityPostAction: TaskWorkflowEntity | null;
}

export const initialState: TasksActionState = {
  taskWorkflowEntityPostAction: null,
  published: false,
  submitLoading: false,
  cancelLoading: false,
  approveLoading: false,
  sendForReworkLoading: false,
  publishLoading: false,
  proceedToWorkLoading: false,
  proceedToReviewLoading: false,
  uploadLoading: false,
  submitError: '',
  cancelError: '',
  approveError: '',
  sendForReworkError: '',
  publishError: '',
  proceedToWorkError: '',
  proceedToReviewError: '',
  uploadError: '',
};

function submitStartLoading(state: TasksActionState) {
  state.submitLoading = true;
}

function submitLoadingFailed(state: TasksActionState, action: PayloadAction<string>) {
  state.submitLoading = false;
  state.submitError = action.payload;
}

function cancelStartLoading(state: TasksActionState) {
  state.cancelLoading = true;
}

function cancelLoadingFailed(state: TasksActionState, action: PayloadAction<string>) {
  state.cancelLoading = false;
  state.cancelError = action.payload;
}

function approveStartLoading(state: TasksActionState) {
  state.approveLoading = true;
}

function approveLoadingFailed(state: TasksActionState, action: PayloadAction<string>) {
  state.approveLoading = false;
  state.approveError = action.payload;
}

function sendForReworkStartLoading(state: TasksActionState) {
  state.sendForReworkLoading = true;
}

function sendForReworkLoadingFailed(state: TasksActionState, action: PayloadAction<string>) {
  state.sendForReworkLoading = false;
  state.sendForReworkError = action.payload;
}

function publishStartLoading(state: TasksActionState) {
  state.published = false;
  state.publishLoading = true;
}

function publishLoadingFailed(state: TasksActionState, action: PayloadAction<string>) {
  state.publishLoading = false;
  state.publishError = action.payload;
}

function proceedToWorkLoading(state: TasksActionState) {
  state.proceedToWorkLoading = true;
}

function proceedToWorkFailed(state: TasksActionState, action: PayloadAction<string>) {
  state.proceedToWorkLoading = false;
  state.proceedToWorkError = action.payload;
}

function proceedToReviewLoading(state: TasksActionState) {
  state.proceedToReviewLoading = true;
}

function proceedToReviewFailed(state: TasksActionState, action: PayloadAction<string>) {
  state.proceedToReviewLoading = false;
  state.proceedToReviewError = action.payload;
}

function uploadDocumentStartLoading(state: TasksActionState) {
  state.uploadLoading = true;
}

function uploadDocumentLoadingFailed(state: TasksActionState, action: PayloadAction<string>) {
  state.uploadLoading = false;
  state.uploadError = action.payload;
}

export const task = createSlice({
  name: 'taskAction',
  initialState,
  reducers: {
    submitTaskActionStart: submitStartLoading,
    cancelTaskActionStart: cancelStartLoading,
    approveTaskActionStart: approveStartLoading,
    sendForReworkTaskActionStart: sendForReworkStartLoading,
    publishtTaskActionStart: publishStartLoading,
    proceedToWorkActionStart: proceedToWorkLoading,
    proceedToReviewActionStart: proceedToReviewLoading,
    uploadTaskActionStart: uploadDocumentStartLoading,
    tasksActionSuccess(state, { payload }: PayloadAction<lookupTasksActionSuccess>) {
      const { taskWorkflowEntityPostAction } = payload;
      state.taskWorkflowEntityPostAction = taskWorkflowEntityPostAction;
      state.submitLoading = false;
      state.cancelLoading = false;
      state.uploadLoading = false;
      state.approveLoading = false;
      state.sendForReworkLoading = false;
      state.publishLoading = false;
      state.proceedToWorkLoading = false;
      state.proceedToReviewLoading = false;
      state.submitError = '';
      state.cancelError = '';
      state.uploadError = '';
      state.approveError = '';
      state.sendForReworkError = '';
      state.publishError = '';
      state.proceedToWorkError = '';
      state.proceedToReviewError = '';
    },
    publishSuccess(state) {
      state.published = true;
    },
    submitTaskActionFailure: submitLoadingFailed,
    cancelTaskActionFailure: cancelLoadingFailed,
    approveTaskActionFailure: approveLoadingFailed,
    sendForReworkTaskActionFailure: sendForReworkLoadingFailed,
    publishTaskActionFailure: publishLoadingFailed,
    proceedToWorkActionFailure: proceedToWorkFailed,
    proceedToReviewActionFailure: proceedToReviewFailed,
    uploadTaskActionFailure: uploadDocumentLoadingFailed,
  },
});

export const {
  submitTaskActionStart,
  cancelTaskActionStart,
  approveTaskActionStart,
  sendForReworkTaskActionStart,
  publishtTaskActionStart,
  proceedToWorkActionStart,
  proceedToReviewActionStart,
  uploadTaskActionStart,
  tasksActionSuccess,
  submitTaskActionFailure,
  cancelTaskActionFailure,
  approveTaskActionFailure,
  sendForReworkTaskActionFailure,
  publishTaskActionFailure,
  proceedToWorkActionFailure,
  proceedToReviewActionFailure,
  uploadTaskActionFailure,
  publishSuccess,
} = task.actions;

export default task.reducer;

export const submitTask =
  (taskId: number | null, workflowId: number | null, taskType: string | undefined): AppThunk =>
  async dispatch => {
    if (taskId === null || workflowId === null || taskType === undefined) {
      throw new Error('Invalid input: taskId, workflowId, or taskType is null');
    }

    try {
      dispatch(submitTaskActionStart());
      const client = await TaskApiV2Client();
      const {
        results: workflowTransitionResult,
        errors,
      }: restClient.SingleResponse<TaskWorkflowEntity> = await client.taskV2.workflowTransition(
        workflowId,
        taskId,
        taskType,
        WorkflowStateType.DE_READY_FOR_REVIEW
      );
      const taskWorkflowEntityPostAction = workflowTransitionResult[0];

      if (errors) {
        throw new Error(errors[0].message);
      }
      dispatch(tasksActionSuccess({ taskWorkflowEntityPostAction }));
    } catch (err) {
      if (err) {
        const errorMessage = (err instanceof Error && err.message) || String(err);
        dispatch(submitTaskActionFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };

export const cancelTask =
  (
    taskId: number | null,
    workflowId: number | null,
    taskType: string | undefined,
    closureCode: string | undefined,
    closureComment: string | undefined
  ): AppThunk =>
  async dispatch => {
    if (taskId === null || workflowId === null || taskType === undefined) {
      throw new Error('Invalid input: taskId, workflowId, or taskType is null');
    }

    try {
      dispatch(cancelTaskActionStart());
      const client = await TaskApiV2Client();
      const {
        results: workflowTransitionResult,
        errors,
      }: restClient.SingleResponse<TaskWorkflowEntity> = await client.taskV2.workflowTransition(
        workflowId,
        taskId,
        taskType,
        WorkflowStateType.DE_CLOSED,
        { closureCode, closureComment }
      );
      if (errors) {
        throw new Error(errors[0].message);
      }
      const taskWorkflowEntityPostAction = workflowTransitionResult[0];
      dispatch(tasksActionSuccess({ taskWorkflowEntityPostAction }));
    } catch (err) {
      if (err) {
        const errorMessage = (err instanceof Error && err.message) || String(err);
        dispatch(cancelTaskActionFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };
export const approveTask =
  (taskId: number | null, workflowId: number | null, taskType: string | undefined): AppThunk =>
  async dispatch => {
    if (taskId === null || workflowId === null || taskType === undefined) {
      throw new Error('Invalid input: taskId, workflowId, or taskType is null');
    }

    try {
      dispatch(approveTaskActionStart());
      const client = await TaskApiV2Client();
      const {
        results: workflowTransitionResult,
        errors,
      }: restClient.SingleResponse<TaskWorkflowEntity> = await client.taskV2.workflowTransition(
        workflowId,
        taskId,
        taskType,
        WorkflowStateType.DE_APPROVED
      );
      if (errors) {
        throw new Error(errors[0].message);
      }
      const taskWorkflowEntityPostAction = workflowTransitionResult[0];
      dispatch(tasksActionSuccess({ taskWorkflowEntityPostAction }));
    } catch (err) {
      if (err) {
        const errorMessage = (err instanceof Error && err.message) || String(err);
        dispatch(approveTaskActionFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };
export const sendForRework =
  (taskId: number | null, workflowId: number | null, taskType: string | undefined): AppThunk =>
  async dispatch => {
    if (taskId === null || workflowId === null || taskType === undefined) {
      throw new Error('Invalid input: taskId, workflowId, or taskType is null');
    }

    try {
      dispatch(sendForReworkTaskActionStart());
      const client = await TaskApiV2Client();
      const {
        results: workflowTransitionResult,
        errors,
      }: restClient.SingleResponse<TaskWorkflowEntity> = await client.taskV2.workflowTransition(
        workflowId,
        taskId,
        taskType,
        WorkflowStateType.DE_OPEN
      );
      if (errors) {
        throw new Error(errors[0].message);
      }
      const taskWorkflowEntityPostAction = workflowTransitionResult[0];
      dispatch(tasksActionSuccess({ taskWorkflowEntityPostAction }));
    } catch (err) {
      if (err) {
        const errorMessage = (err instanceof Error && err.message) || String(err);
        dispatch(sendForReworkTaskActionFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };

export const publishTask =
  (taskId: number | null, workflowId: number | null, taskType: string | undefined): AppThunk =>
  async dispatch => {
    if (taskId === null || workflowId === null || taskType === undefined) {
      throw new Error('Invalid input: taskId, workflowId, or taskType is null');
    }

    try {
      dispatch(publishtTaskActionStart());
      const client = await TaskApiV2Client();
      const {
        results: workflowTransitionResult,
        errors,
      }: restClient.SingleResponse<TaskWorkflowEntity> = await client.taskV2.workflowTransition(
        workflowId,
        taskId,
        taskType,
        WorkflowStateType.DE_PUBLISHED
      );
      if (errors) {
        throw new Error(errors[0].message);
      }
      const taskWorkflowEntityPostAction = workflowTransitionResult[0];
      dispatch(tasksActionSuccess({ taskWorkflowEntityPostAction }));
      dispatch(publishSuccess());
    } catch (err) {
      if (err) {
        const errorMessage = (err instanceof Error && err.message) || String(err);
        dispatch(publishTaskActionFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };

export const proceedToWork =
  (taskId: number | null, workflowId: number | null, taskType: string | undefined): AppThunk =>
  async dispatch => {
    if (taskId === null || workflowId === null || taskType === undefined) {
      throw new Error('Invalid input: taskId, workflowId, or taskType is null');
    }

    try {
      dispatch(proceedToWorkActionStart());
      const client = await TaskApiV2Client();
      const {
        results: workflowTransitionResult,
        errors,
      }: restClient.SingleResponse<TaskWorkflowEntity> = await client.taskV2.workflowTransition(
        workflowId,
        taskId,
        taskType,
        WorkflowStateType.DE_IN_PROGRESS
      );
      if (errors) {
        throw new Error(errors[0].message);
      }
      const taskWorkflowEntityPostAction = workflowTransitionResult[0];
      dispatch(tasksActionSuccess({ taskWorkflowEntityPostAction }));
    } catch (err) {
      if (err) {
        const errorMessage = (err instanceof Error && err.message) || String(err);
        dispatch(proceedToWorkActionFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };
export const proceedToReview =
  (taskId: number | null, workflowId: number | null, taskType: string | undefined): AppThunk =>
  async dispatch => {
    if (taskId === null || workflowId === null || taskType === undefined) {
      throw new Error('Invalid input: taskId, workflowId, or taskType is null');
    }

    try {
      dispatch(proceedToReviewActionStart());
      const client = await TaskApiV2Client();
      const {
        results: workflowTransitionResult,
        errors,
      }: restClient.SingleResponse<TaskWorkflowEntity> = await client.taskV2.workflowTransition(
        workflowId,
        taskId,
        taskType,
        WorkflowStateType.DE_REVIEW_IN_PROGRESS
      );
      if (errors) {
        throw new Error(errors[0].message);
      }
      const taskWorkflowEntityPostAction = workflowTransitionResult[0];
      dispatch(tasksActionSuccess({ taskWorkflowEntityPostAction }));
    } catch (err) {
      if (err) {
        const errorMessage = (err instanceof Error && err.message) || String(err);
        dispatch(proceedToReviewActionFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };

export const uploadDocument =
  (data: FormData | null, taskId: number | null, workflowId: number | null): AppThunk =>
  async dispatch => {
    if (taskId === null || workflowId === null || data === null) {
      throw new Error('Invalid input: taskId, workflowId, or data is null');
    }

    try {
      dispatch(uploadTaskActionStart());
      const client = await TaskApiV2Client();

      const {
        results: presignedURLResult,
        errors: presignedURLError,
      }: restClient.SingleResponse<string> = await client.taskV2.getPresignedUrl(
        workflowId,
        taskId
      );

      if (presignedURLError) {
        throw new Error(presignedURLError[0].message);
      }

      const uploadResponse = await axios.put(presignedURLResult[0], data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (uploadResponse.status !== 200) {
        throw new Error('Failed to upload document');
      }

      const {
        results: uploadDocumentResult,
        errors,
      }: restClient.SingleResponse<TaskWorkflowEntity> = await client.taskV2.uploadDocument(
        { presignedurl: presignedURLResult[0] },
        workflowId,
        taskId
      );
      if (errors) {
        throw new Error(errors[0].message);
      }
      const taskWorkflowEntityPostAction = uploadDocumentResult[0];
      dispatch(tasksActionSuccess({ taskWorkflowEntityPostAction }));
    } catch (err) {
      if (err) {
        const errorMessage = (err instanceof Error && err.message) || String(err);
        dispatch(uploadTaskActionFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };

export const selectTaskWorkflowEntityPostAction = (state: RootState): TasksActionState => {
  return state.taskWorkflowEntityPostAction;
};
