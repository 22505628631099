import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { restClient, types, restApis } from '@genability/api';
import { RootState } from '../rootReducer';
import { GenApiClient } from '../../GenApiClient';
import { LoadingState } from '../reduxUtils';
import { GetMassCalculationRequest } from '@genability/api/dist/api/on-demand-cost-calculation-api';

/**
 * Calculated Cost Result grouped by effective date
 */
export interface IMultipleResult {
  /**
   * Effective Date
   */
  [effectiveDate: string]: {
    /**
     * Calculated Cost Result
     */
    result: types.CalculatedCost | null;
    /**
     * Error details if the request fails
     */
    errors: restClient.ResponseError[] | [];
    /**
     * Request Parameters
     */
    request: Partial<restApis.GetCalculatedCostRequest>;
  };
}

export interface ICostCalculationState {
  results: types.CalculatedCost[] | null;
  multipleResults: IMultipleResult | null;
  massResults: types.CalculatedCost | null;
  errors: restClient.ResponseError[] | null;
  currentRequestId: string | undefined;
  loading: LoadingState;
}

export const initialState: ICostCalculationState = {
  results: [],
  multipleResults: null,
  massResults: null,
  errors: [],
  currentRequestId: undefined,
  loading: LoadingState.IDLE,
};

const createCostCalculationRequest = (
  params: Partial<restApis.GetCalculatedCostRequest>
): restApis.GetCalculatedCostRequest => {
  const costCalculationRequest: restApis.GetCalculatedCostRequest =
    new restApis.GetCalculatedCostRequest();
  Object.keys(params).forEach((k: string) => {
    const prop = k as keyof restApis.GetCalculatedCostRequest;
    costCalculationRequest[prop] = params[prop] as never;
  });
  return costCalculationRequest;
};

const createMassCalculationRequest = (
  params: Partial<GetMassCalculationRequest>
): GetMassCalculationRequest => {
  const massCalculationRequest: GetMassCalculationRequest = new GetMassCalculationRequest();
  Object.keys(params).forEach((k: string) => {
    const prop = k as keyof GetMassCalculationRequest;
    massCalculationRequest[prop] = params[prop] as never;
  });
  return massCalculationRequest;
};

export const runCalculation = createAsyncThunk<
  restClient.PagedResponse<types.CalculatedCost> | void,
  Partial<restApis.GetCalculatedCostRequest>,
  {
    state: { costCalculation: ICostCalculationState };
    rejectValue: restClient.ResponseError[];
  }
>(
  'costCalculationSlice/runCalculation',
  async (
    params: Partial<restApis.GetCalculatedCostRequest>,
    { getState, requestId, rejectWithValue }
  ) => {
    const { currentRequestId, loading } = getState().costCalculation;
    if (loading !== LoadingState.PENDING || requestId !== currentRequestId) {
      return;
    }

    const costCalculationRequest = createCostCalculationRequest(params);

    const client = await GenApiClient();
    const response = await client.calculation.runCalculation(costCalculationRequest);
    if (response?.errors?.length) {
      return rejectWithValue(response.errors);
    }
    return { ...response };
  }
);

export const runMassCalculation = createAsyncThunk<
  restClient.PagedResponse<types.CalculatedCost> | void,
  Partial<GetMassCalculationRequest>,
  {
    state: { costCalculation: ICostCalculationState };
    rejectValue: restClient.ResponseError[];
  }
>(
  'costCalculationSlice/runMassCalculation',
  async (params: Partial<GetMassCalculationRequest>, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().costCalculation;
    if (loading !== LoadingState.PENDING || requestId !== currentRequestId) {
      return;
    }

    const massCalculationRequest = createMassCalculationRequest(params);

    const client = await GenApiClient();
    const response = await client.calculation.runMassCalculation(massCalculationRequest);
    if (response?.errors?.length) {
      return rejectWithValue(response.errors);
    }
    return { ...response };
  }
);

export const runMultipleCalculations = createAsyncThunk<
  IMultipleResult | void,
  Partial<restApis.GetCalculatedCostRequest>[],
  {
    state: { costCalculation: ICostCalculationState };
    rejectValue: restClient.ResponseError[];
  }
>(
  'costCalculationSlice/runMultipleCalculation',
  async (
    requests: Partial<restApis.GetCalculatedCostRequest>[],
    { getState, requestId, rejectWithValue }
  ) => {
    const { currentRequestId, loading } = getState().costCalculation;
    if (loading !== LoadingState.PENDING || requestId !== currentRequestId) {
      return;
    }

    const client = await GenApiClient();
    const genRequests: Promise<IMultipleResult>[] = requests.map(async request => {
      const mappedRequest = createCostCalculationRequest(request);
      const response = await client.calculation.runCalculation(mappedRequest);
      return new Promise(resolve =>
        resolve({
          [`${request.tariffEffectiveOn}`]: {
            request,
            result: response.result || null,
            errors: response.errors || [],
          },
        })
      );
    });
    const responses: IMultipleResult[] = await Promise.all<Promise<IMultipleResult>[]>(genRequests);

    let generated: IMultipleResult = {};
    responses.forEach(res => (generated = { ...generated, ...res }));
    const withErrors = Object.keys(generated)
      .filter(key => generated[key].errors?.length)
      .map(key => generated[key].errors);
    if (withErrors?.length) {
      const errors = withErrors.flatMap(errors => errors) as restClient.ResponseError[];
      return rejectWithValue(errors);
    }
    return generated;
  }
);

export const costCalculationSlice = createSlice({
  name: 'costCalculation',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(runCalculation.pending, (state, action) => {
        state.loading = LoadingState.PENDING;
        state.currentRequestId = action.meta.requestId;
        state.errors = [];
      })
      .addCase(runCalculation.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.currentRequestId !== requestId) {
          return; // ignore stale
        }
        state.loading = LoadingState.SUCCEEDED;
        if (action.payload) {
          state.results = action.payload?.results;
        }
        state.currentRequestId = undefined;
        state.errors = [];
      })
      .addCase(runCalculation.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.currentRequestId != requestId) {
          return; // ignore state
        }
        state.loading = LoadingState.FAILED;
        state.results = [];
        state.currentRequestId = undefined;
        state.errors = action.payload || [];
      })
      .addCase(runMassCalculation.pending, (state, action) => {
        state.loading = LoadingState.PENDING;
        state.currentRequestId = action.meta.requestId;
        state.errors = [];
      })
      .addCase(runMassCalculation.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.currentRequestId !== requestId) {
          return; // ignore stale
        }
        state.loading = LoadingState.SUCCEEDED;
        if (action.payload) {
          state.massResults = action.payload?.results[0];
        }
        state.currentRequestId = undefined;
        state.errors = [];
      })
      .addCase(runMassCalculation.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.currentRequestId != requestId) {
          return; // ignore state
        }
        state.loading = LoadingState.FAILED;
        state.massResults = null;
        state.currentRequestId = undefined;
        state.errors = action.payload || [];
      })
      .addCase(runMultipleCalculations.pending, (state, action) => {
        state.loading = LoadingState.PENDING;
        state.currentRequestId = action.meta.requestId;
        state.errors = [];
      })
      .addCase(runMultipleCalculations.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.currentRequestId !== requestId) {
          return; // ignore stale
        }
        state.loading = LoadingState.SUCCEEDED;
        if (action.payload) {
          state.multipleResults = action.payload;
        }
        state.currentRequestId = undefined;
        state.errors = [];
      })
      .addCase(runMultipleCalculations.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.currentRequestId != requestId) {
          return; // ignore state
        }
        state.loading = LoadingState.FAILED;
        state.multipleResults = null;
        state.currentRequestId = requestId;
        state.errors = action.payload || [];
      });
  },
});

export default costCalculationSlice.reducer;

// Memoized selector
export const selectMemoizedCostCalculationByMasterTariffId = createSelector(
  [
    (state: RootState) => state.costCalculation.results,
    (state: RootState, masterTariffId: number) => masterTariffId,
  ],
  (calculations, masterTariffId) => {
    return calculations?.find(
      (calculation: types.CalculatedCost) => Number(calculation.masterTariffId) === masterTariffId
    );
  }
);

export const selectMemoizedMassCalculations = createSelector(
  [(state: RootState) => state.costCalculation.massResults],
  calculations => {
    return calculations;
  }
);

export const selectMemoizedMultipleCalculations = createSelector(
  [
    (state: RootState) => ({
      multipleResults: state.costCalculation.multipleResults,
      loading: state.costCalculation.loading,
    }),
  ],
  calculations => {
    return calculations;
  }
);
