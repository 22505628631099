import { restClient, types, restApis } from '@genability/api';
import {
  DQCheck,
  TaskAssignment,
  TaskAssignmentAnswer,
  TaskTypeId,
  TaskAssignmentStatus,
} from '../types/task';
import { TaskTariff } from '../types/task';

export class GetTaskAssignmentsRequest extends restClient.BasePagedRequest {
  public worker?: string;
  public taskTypeId?: TaskTypeId;
  public assignmentStatuses?: TaskAssignmentStatus[];
  public populateTask?: boolean;
  public taskId?: number;
  public populateComments?: boolean;
  public populateAssignmentAnswers?: boolean;

  addParams(addParam: restClient.AddParamCallback): void {
    addParam('worker', this.worker);
    addParam('assignmentStatuses', this.assignmentStatuses);
    addParam('populateTask', this.populateTask);
    addParam('taskId', this.taskId);
    addParam('populateComments', this.populateComments);
    addParam('populateAssignmentAnswers', this.populateAssignmentAnswers);
  }
}

export interface TaskAssignmentAnswerBody {
  answerField?: string;
  answerValue?: string;
  taskAnswerId?: number;
}

export interface DQOverrideReason {
  dqField: string;
  dqMessage: string;
  overrideReason: string;
}

export interface DQOverridesBody {
  skipDQ: boolean;
  dqOverrides?: DQOverrideReason[];
}

export class TaskAssignmentApi extends restClient.RestApiClient {
  public async getTaskAssignments(
    request?: GetTaskAssignmentsRequest
  ): Promise<restClient.PagedResponse<TaskAssignment>> {
    return this.getPaged(`/v1/taskassignments`, { params: request });
  }

  public async getTaskAssignment(
    taskAssignmentId: number,
    request?: GetTaskAssignmentsRequest
  ): Promise<restClient.SingleResponse<TaskAssignment>> {
    return this.getSingle(`/v1/taskassignments/${taskAssignmentId}`, {
      params: request,
    });
  }

  public async getTaskAssignmentAnswer(
    taskAssignmentId: number,
    answerField: string,
    request?: GetTaskAssignmentsRequest
  ): Promise<restClient.SingleResponse<TaskAssignmentAnswer>> {
    return this.getSingle(`/v1/taskassignments/${taskAssignmentId}/answers/${answerField}`, {
      params: request,
    });
  }

  public async getDQCheck(taskAssignmentId: number): Promise<restClient.PagedResponse<DQCheck>> {
    return this.getPaged(`/v1/taskassignments/${taskAssignmentId}/dq`);
  }

  public async deleteTaskAssignmentAnswer(
    taskAssignmentId: number,
    answerField: string,
    request?: GetTaskAssignmentsRequest
  ): Promise<restClient.SingleResponse<TaskAssignment>> {
    return this.delete(`/v1/taskassignments/${taskAssignmentId}/answers/${answerField}`, {
      params: request,
    });
  }

  public async getTaskAssignmentTariff(
    taskAssignmentId: number,
    request?: GetTaskAssignmentsRequest
  ): Promise<restClient.SingleResponse<TaskTariff>> {
    return this.getSingle(
      `/v1/taskassignments/${taskAssignmentId}/tariff`,
      { params: request },
      restApis.tariffResponseInterceptor
    );
  }

  public async submitTaskAssignment(
    taskAssignmentId: number,
    body?: DQOverridesBody
  ): Promise<restClient.SingleResponse<TaskAssignment>> {
    return this.post(`/v1/taskassignments/${taskAssignmentId}`, body ?? {});
  }

  public async updateTaskAssignmentAnswer(
    taskAssignmentId: number | undefined | null,
    body?: TaskAssignmentAnswerBody
  ): Promise<restClient.SingleResponse<TaskAssignmentAnswer>> {
    return this.put(
      `/v1/taskassignments/${taskAssignmentId}/answers`,
      body,
      undefined,
      restApis.tariffResponseInterceptor
    );
  }

  public async updateTaskAssignmentTariff(
    taskAssignmentId: number,
    body?: types.Tariff
  ): Promise<restClient.SingleResponse<TaskTariff>> {
    const updatedTariff = body ? types.toApiFromTariff(body) : body;
    return this.put(
      `/v1/taskassignments/${taskAssignmentId}/tariff`,
      updatedTariff,
      undefined,
      restApis.tariffResponseInterceptor
    );
  }

  public async persistTaskAssignment(taskAssignmentId: number): Promise<unknown> {
    return this.post(`/v1/taskassignments/${taskAssignmentId}/persist/tariff`);
  }
}
