import React, { FC, useRef } from 'react';
import { createPortal } from 'react-dom';
import Alert from 'react-bootstrap/Alert';
import DQOverrideDialog from '../DQOverrideDialog';
import { useSelector } from 'react-redux';
import { selectDQCheckOverrides } from '../../../state/dqCheck/dqCheckSlice';
import { Row, Col } from 'react-bootstrap';
import { Icon } from '@arcadiapower/gen-react-lib';

export interface DQOverrideConfirmDialogProps {
  onContinue?: () => void;
  onCancel?: () => void;
  isVisible: boolean;
}

const DQOverrideConfirmDialog: FC<DQOverrideConfirmDialogProps> = ({
  isVisible,
  onContinue,
  onCancel,
}) => {
  const dqCheckOverrides = useSelector(selectDQCheckOverrides);
  const rowsRef = useRef<HTMLDivElement[]>([]);

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleContinue = () => {
    onContinue && onContinue();
  };

  const renderDqOverrides = () => {
    if (!dqCheckOverrides?.length) return null;

    return dqCheckOverrides.map((override, idx) => (
      <Row key={override.objectName} ref={(el: HTMLDivElement) => (rowsRef.current[idx] = el)}>
        <Col xs={1} className="d-flex align-items-center">
          <Icon iconName="chevronRight" />
        </Col>
        <Col xs={11} className="d-flex align-items-center">
          <Alert variant="danger" className="w-100 mb-0">
            {override.message}
          </Alert>
        </Col>
        <Col xs={12}>
          <p className="font-weight-bold">Reason: {override.reason}</p>
        </Col>
      </Row>
    ));
  };

  return createPortal(
    <DQOverrideDialog
      title="Submit assignment with DQ Errors"
      isVisible={isVisible}
      onCancel={handleCancel}
      onContinue={handleContinue}
      hideModal={handleCancel}
    >
      <p>You are submitting this assignment for review with the following DQ overrides:</p>
      {renderDqOverrides()}
    </DQOverrideDialog>,
    document.body
  );
};

export default DQOverrideConfirmDialog;
