import { types } from '@genability/api';
import { TariffPropertyType } from '@genability/api/dist/types';
import { Operation, ChangeOperation, pathLastPosition, Op } from '../task-api/types/resource-diff';
import capitalize from './capitalize';

export type DisplayInfo = {
  displayName: string;
  targetPage?: string;
  position?: number;
  from?: number;
};

export type LinkDisplayInfo = {
  displayName: string;
  isLink: boolean;
  link?: string;
};

export enum SectionType {
  ELIGIBILITY = 'Eligibility',
  HEADER = 'Header',
  RATES = 'Rates',
}

const PATH_NAMES: { [key: string]: DisplayInfo } = {
  '/tariffCode': { displayName: 'Tariff Code', targetPage: SectionType.HEADER },
  '/tariffName': { displayName: 'Tariff Name', targetPage: SectionType.HEADER },
  '/lseCode': { displayName: 'LSE Code', targetPage: '' },
  '/tariffBookName': {
    displayName: 'Book Name',
    targetPage: SectionType.HEADER,
  },
  '/priorTariffId': {
    displayName: 'Source Tariff Id',
    targetPage: SectionType.HEADER,
  },
  '/effectiveDate': {
    displayName: 'Effective Date',
    targetPage: SectionType.HEADER,
  },
  '/endDate': { displayName: 'End Date', targetPage: SectionType.HEADER },
  '/closedDate': { displayName: 'Closed Date', targetPage: SectionType.HEADER },
  '/customerCount': {
    displayName: 'Customer Count',
    targetPage: SectionType.HEADER,
  },
  '/customerCountSource': {
    displayName: 'Count Source',
    targetPage: SectionType.HEADER,
  },
  '/chargePeriod': {
    displayName: 'Charge Period',
    targetPage: SectionType.HEADER,
  },
  '/minMonthlyConsumption': {
    displayName: 'Minimum Consumption',
    targetPage: SectionType.ELIGIBILITY,
  },
  '/maxMonthlyConsumption': {
    displayName: 'Maximum Consumption',
    targetPage: SectionType.ELIGIBILITY,
  },
  '/minMonthlyDemand': {
    displayName: 'Minimum Demand',
    targetPage: SectionType.ELIGIBILITY,
  },
  '/maxMonthlyDemand': {
    displayName: 'Maximum Demand',
    targetPage: SectionType.ELIGIBILITY,
  },
  '/rates': { displayName: 'Rate', targetPage: SectionType.ELIGIBILITY },
  '/rateBands': {
    displayName: 'Rate Band',
    targetPage: SectionType.ELIGIBILITY,
  },
  '/tariffSequenceNumber': {
    displayName: 'Tariff Sequence Number',
    targetPage: SectionType.ELIGIBILITY,
  },
  '/hasConsumptionLimit': {
    displayName: 'Has Consumption Limit',
    targetPage: SectionType.ELIGIBILITY,
  },
  '/hasDemandLimit': {
    displayName: 'Has Demand Limit',
    targetPage: SectionType.ELIGIBILITY,
  },
  '/demandUpperLimit': {
    displayName: 'Demand Upper Limit',
    targetPage: SectionType.ELIGIBILITY,
  },
  '/rateAmount': {
    displayName: 'Rate Amount',
    targetPage: SectionType.ELIGIBILITY,
  },
  '/applicabilityValue': {
    displayName: 'Applicability Value',
    targetPage: SectionType.ELIGIBILITY,
  },
  '/applicabilityKey': {
    displayName: 'Applicability Key',
    targetPage: SectionType.ELIGIBILITY,
  },
  '/tariffRateBandId': {
    displayName: 'Tariff Rate Band Id',
    targetPage: SectionType.ELIGIBILITY,
  },
  '/tariffRateId': {
    displayName: 'Tariff Rate Id',
    targetPage: SectionType.ELIGIBILITY,
  },
  '/privacy': { displayName: 'Privacy', targetPage: '' },
  '/chargeTypes': { displayName: 'Charge Type', targetPage: '' },
  '/hasTieredRates': {
    displayName: 'Has Tiered Rates',
    targetPage: SectionType.HEADER,
  },
  '/hasTariffApplicability': {
    displayName: 'Has Tariff Applicability',
    targetPage: SectionType.HEADER,
  },
  '/hasNetMetering': {
    displayName: 'Has Net Metering',
    targetPage: SectionType.HEADER,
  },
};

const UNKNOWN_PROPERTIES: string[] = ['chargeClass'];

// Identifies if the operation has riderId field
export const hasRiderId = (operation: ChangeOperation<any>): boolean => {
  const { value } = operation;
  return value && 'riderId' in value;
};

// Identifies if the operation path startsWith /rates
export const isRateOperation = (operation: Operation): boolean => {
  return operation.path.startsWith('/rates');
};

// Identifies if the operation path startsWith /properties
export const isPropertyOperation = (operation: Operation): boolean => {
  return operation.path.startsWith('/properties');
};

// Is Header Section
export const isHeaderSection = (operation: Operation): boolean => {
  return !isPropertyOperation(operation) && !isRateOperation(operation);
};

// It matches with the propertyTypes
export const isPropertyTypesSection = (
  operation: ChangeOperation<types.TariffProperty>,
  tariff: types.Tariff | undefined,
  propertyTypes: string
): boolean => {
  let { value } = operation;
  if (!value?.keyName) {
    value = getPropertyFromTariff(tariff, operation.path);
  }
  return isPropertyOperation(operation) && !!(value && propertyTypes === value?.propertyTypes);
};

export const getDisplayName = (
  operation: Operation,
  tariff: types.Tariff | undefined
): DisplayInfo => {
  if (operation.path in PATH_NAMES) {
    return PATH_NAMES[operation.path];
  }

  const arrayEle = operation.path.replace('/', '').split('/');
  if (!arrayEle.length) return { displayName: operation.path };

  const path = `/${arrayEle[0]}`;
  const position = getPosition(arrayEle);
  if (position === null) {
    return { displayName: operation.path };
  }

  if (!PATH_NAMES[path]) {
    const property = path.includes('properties') ? getPropertyDisplayName(operation, tariff) : null;
    const displayValue = property?.displayName || path;
    if (!property && !displayValue) {
      setTimeout(() => {
        throw new Error(
          `[ResourceDiffPatches] Path not matched [${path}] from position ${position}`
        );
      });
    }
    return { displayName: displayValue };
  }

  return { ...PATH_NAMES[path], position: Number(position) };
};

export const getPreviousPositionDisplayName = (
  operation: Operation,
  tariff: types.Tariff | undefined
): DisplayInfo => {
  const arrayEle = operation?.from?.replace('/', '').split('/');
  if (!arrayEle) {
    return { displayName: operation.path, from: Number(operation.from) };
  }
  const path = `/${arrayEle[0]}`;
  const position = getPosition(arrayEle);
  if (position === null) {
    return { displayName: operation.path, from: Number(operation.from) };
  }

  if (!PATH_NAMES[path]) {
    const property = path.includes('properties') ? getPropertyDisplayName(operation, tariff) : null;
    const displayValue = property?.displayName || path;
    if (!property && !displayValue) {
      setTimeout(() => {
        throw new Error(
          `[ResourceDiffPatches] Path not matched [${path}] from position ${position}`
        );
      });
    }
    return {
      displayName: displayValue,
      from: Number(position),
    };
  }

  return { ...PATH_NAMES[path], from: Number(position) };
};

const formatRateDisplayName = (
  rateName: string,
  rateGroupName: string,
  fallbackDisplayName: string,
  riderId?: number | null,
  position?: number | undefined
): string => {
  const riderDisplayName = riderId && 'Rider';
  const initialText = riderDisplayName || fallbackDisplayName;
  const groupText = rateGroupName ? `of group ${rateGroupName}` : '';
  const withPosition = `${initialText} - ${rateName || ''} ${groupText} at position ${position}`;
  return position !== undefined ? withPosition : fallbackDisplayName;
};

export const getRateDisplay = (
  operation: ChangeOperation<types.TariffRate>,
  tariff: types.Tariff | undefined
): DisplayInfo => {
  const pathArray = operation.path.replace('/', '').split('/');
  const position = getPosition(pathArray);

  const value = operation.value as types.TariffRate;
  const fallbackDisplayName = PATH_NAMES[`/${pathArray[0]}`]?.displayName || operation.path;
  if (value) {
    return {
      displayName: formatRateDisplayName(
        value.rateName,
        value.rateGroupName,
        fallbackDisplayName,
        value.riderId,
        position
      ),
      position,
    };
  }
  if (position === undefined || !tariff || !tariff.rates?.length) {
    return {
      displayName: fallbackDisplayName,
    };
  }

  const rateName = tariff.rates[position]?.rateName;
  const rateGroupName = tariff.rates[position]?.rateGroupName;
  const riderId = tariff.rates[position]?.riderId;

  return {
    displayName: formatRateDisplayName(
      rateName,
      rateGroupName,
      fallbackDisplayName,
      riderId,
      position
    ),
    position,
  };
};

export const getNestedOpDisplay = (
  operation: Operation,
  tariff: types.Tariff | undefined
): DisplayInfo => {
  const arrayEle = operation.path.replace('/', '').split('/');
  if (!arrayEle || arrayEle.length <= 1) {
    return getDisplayName(operation, tariff);
  }
  const parent = PATH_NAMES[`/${arrayEle[0]}`];
  const position = Number(arrayEle[1]);
  const child = PATH_NAMES[`/${arrayEle[2]}`];
  if (!parent || !child || !position) {
    return { displayName: operation.path };
  }

  return {
    displayName: `"${child?.displayName}" flag of ${parent?.displayName} at position ${position}`,
    targetPage: parent.targetPage,
    position,
  };
};
/**
 * Return a Tariff property by index
 * @param tariff
 * @param path Property's index from operation.path. Ie. /properties/3
 */
export const getPropertyFromTariff = (
  tariff: types.Tariff | undefined,
  path: string
): types.TariffProperty | undefined => {
  const propertyIndex = pathLastPosition(path);
  if (tariff?.properties && propertyIndex !== null && tariff?.properties?.[propertyIndex]) {
    return tariff.properties[propertyIndex];
  }
};

/**
 * Return the property's display name from 2 sources: operation.value or the tariff properties
 */
export const getPropertyDisplayName = (
  operation: ChangeOperation<types.TariffProperty>,
  tariff: types.Tariff | undefined
): DisplayInfo | null => {
  const { value } = operation;
  if (value?.keyName) {
    // keyName property is in operation.value
    return {
      displayName: `${capitalize(value?.propertyTypes, '_')}: ${value?.keyName}`,
      targetPage: SectionType.ELIGIBILITY,
    };
  } else {
    // keyName property is on Redux state
    const tariffProperty = getPropertyFromTariff(tariff, operation.path);
    if (tariffProperty) {
      return {
        displayName: `${capitalize(tariffProperty?.propertyTypes, '_')}: ${
          tariffProperty?.keyName
        }`,
        targetPage: `${
          tariffProperty?.propertyTypes === TariffPropertyType.APPLICABILITY
            ? SectionType.ELIGIBILITY
            : ''
        }`,
      };
    }
  }
  return null;
};

const getPosition = (parts: string[]): number | undefined => {
  const lastElement = parts[parts.length - 1];
  if (!new RegExp(/[0-9]+/).test(lastElement)) {
    return undefined;
  }

  return Number(lastElement);
};

const getPropertyKeyFromDisplayName = (displayName: string | undefined) => {
  if (displayName) {
    const text = displayName.split(':');
    return text[1].trim();
  }
  return null;
};

const getRateGroupPosition = (rates: types.TariffRate[] | undefined, position: number) => {
  const rateGroups: { [key: string]: number[] } = {};
  rates?.forEach((rate: types.TariffRate, index: number) => {
    const key = rate.rateGroupName;
    if (rateGroups[key]?.length) {
      rateGroups[key]?.push(index);
    } else {
      rateGroups[key] = [index];
    }
  });

  let groupPosition = -1;

  Object.keys(rateGroups).forEach((k: string, index: number) => {
    const hasIndex = rateGroups[k].includes(position);
    groupPosition = groupPosition === -1 && hasIndex ? index : groupPosition;
    return;
  });
  return groupPosition;
};

export const rateLinkDisplay = (
  operation: Operation,
  tariff: types.Tariff | undefined
): LinkDisplayInfo => {
  const display = getRateDisplay(operation, tariff);
  const displayName = display?.displayName || operation.path;
  const position = display?.position !== undefined ? display.position : -1;

  if (operation.op === Op.REMOVE) {
    return {
      isLink: false,
      displayName: displayName,
    };
  }
  const groupPosition = getRateGroupPosition(tariff?.rates, position);

  return {
    isLink: groupPosition >= 0,
    link: `rates/#rateGroup-${groupPosition}`,
    displayName: displayName,
  };
};

export const linkDisplay = (
  operation: Operation,
  tariff: types.Tariff | undefined
): LinkDisplayInfo => {
  const display = getDisplayName(operation, tariff);
  const displayName = display?.displayName || operation.path;
  const property = operation.path.replace('/', '');
  const section = display?.targetPage?.toLowerCase() || null;
  return {
    isLink: !!(section && property) && operation.op !== Op.REMOVE,
    link: `${section}/#${property}`,
    displayName: displayName,
  };
};

export const tariffPropertyLinkDisplay = (
  operation: Operation,
  tariff: types.Tariff | undefined
): LinkDisplayInfo => {
  const display = getPropertyDisplayName(operation, tariff);
  const property = getPropertyKeyFromDisplayName(display?.displayName) || '';
  const hasNoTargetPage = UNKNOWN_PROPERTIES.includes(property);
  const displayName = display?.displayName || operation.path;
  let section = null;
  if (isPropertyTypesSection(operation, tariff, 'RATE_CRITERIA')) {
    section = 'criteria';
  } else if (display?.targetPage) {
    section = display?.targetPage?.toLowerCase();
  }
  return {
    isLink: !!(section && property && !hasNoTargetPage) && operation.op !== Op.REMOVE,
    link: `${section}/#${property}`,
    displayName: displayName,
  };
};

export const displayLinks = (tariff: types.Tariff): Record<string, string> => {
  const links: Record<string, string> = {};
  Object.keys(PATH_NAMES).forEach(keyPath => {
    const fieldName = keyPath.slice(1);
    const path = PATH_NAMES[keyPath];
    const section = path?.targetPage?.toLowerCase() || null;
    links[fieldName] = `${section}/#${fieldName}`;
  });
  tariff?.properties?.forEach(property => {
    const section = property.propertyTypes === 'RATE_CRITERIA' ? 'criteria' : 'eligibility';
    links[property.keyName] = `${section}/#${property.keyName}`;
  });
  return links;
};
