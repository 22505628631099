import { restClient, types } from '@genability/api';
import { GetTariffsRequest } from '@genability/api/dist/api';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GenApiClient } from '../../GenApiClient';
import { RootState } from '../rootReducer';
import { LoadingState } from '../reduxUtils';

export type TariffMeta = {
  count: number;
  pageCount: number;
  page: number;
};
export interface ITariffState {
  status: string;
  meta: TariffMeta;
  results: types.Tariff[] | [];
  errors: restClient.ResponseError[] | undefined;
  currentRequestId: string | undefined;
  loading: LoadingState;
}

export interface ITariffSuccess {
  status: string;
  meta: TariffMeta;
  results: types.Tariff[] | [];
  errors: restClient.ResponseError[] | undefined;
  currentRequestId: string | undefined;
}

export const initialState: ITariffState = {
  loading: LoadingState.IDLE,
  status: '',
  meta: {
    count: 0,
    page: 0,
    pageCount: 25,
  },
  results: [],
  errors: undefined,
  currentRequestId: undefined,
};

export const startLoading = (state: ITariffState) => {
  state.loading = LoadingState.PENDING;
};

export const loadingFailed = (
  state: ITariffState,
  action: PayloadAction<restClient.ResponseError[]>
) => {
  state.loading = LoadingState.FAILED;
  state.status = 'error';
  state.errors = action.payload;
  state.results = [];
};

export const tariffSlice = createSlice({
  name: 'tariffs',
  initialState,
  reducers: {
    getTariffsStart: startLoading,
    getTariffsSuccess(state, { payload }: PayloadAction<ITariffSuccess>) {
      const { errors, status, results, currentRequestId, meta } = payload;
      state.loading = LoadingState.SUCCEEDED;
      state.status = status;
      state.errors = errors;
      state.results = results || [];
      state.currentRequestId = currentRequestId;
      state.meta = meta;
    },
    getTariffsFailure: loadingFailed,
  },
});

export const { getTariffsStart, getTariffsSuccess, getTariffsFailure } = tariffSlice.actions;

export default tariffSlice.reducer;

export const createTariffsRequest = (
  params: Partial<GetTariffsRequest> | undefined
): GetTariffsRequest => {
  const tariffsParams: GetTariffsRequest = new GetTariffsRequest();
  if (params) {
    Object.keys(params).forEach((k: string) => {
      const prop = k as keyof GetTariffsRequest;
      if (prop === 'search') {
        if (params[prop]?.includes(':')) {
          const parts = params[prop]?.split(':');
          tariffsParams.search = parts != null ? parts[1].trim() : '';
          if (parts != null && parts[0] === 'tariffId') {
            tariffsParams.searchOn = ['tariffId', 'masterTariffId'];
          } else {
            tariffsParams.searchOn = parts != null ? [parts[0]] : [];
          }
          tariffsParams.startsWith = true;
        } else if (params[prop]?.length ?? 0 > 1) {
          tariffsParams.search = params[prop] as never;
          tariffsParams.searchOn = [
            'tariffId',
            'lseId',
            'tariffCode',
            'tariffName',
            'lseCode',
            'lseName',
          ];
        }
      } else {
        tariffsParams[prop] = params[prop] as never;
      }
    });
  }
  tariffsParams.tariffTypes = params?.tariffTypes || [
    types.TariffType.DEFAULT,
    types.TariffType.ALTERNATIVE,
  ];
  tariffsParams.pageCount = params?.pageCount || 25;
  tariffsParams.pageStart = params?.pageStart || 0;

  return tariffsParams;
};
export const fetchTariffs = createAsyncThunk(
  'tariffs/fetchTariffs',
  async (
    params: Partial<GetTariffsRequest> | undefined,
    { dispatch, getState, requestId }
  ): Promise<void> => {
    const {
      tariffs: { currentRequestId: currentReqId, loading },
    } = getState() as RootState;
    if (currentReqId === requestId) return;
    dispatch(getTariffsStart());
    const request = createTariffsRequest(params);
    const client = await GenApiClient();
    const response: restClient.PagedResponse<types.Tariff> = await client.tariffs.getTariffs(
      request
    );
    const { status, results, errors, count, pageCount, pageStart: page } = response;
    const tariffSuccess: ITariffSuccess = {
      status,
      meta: {
        count: count || 0,
        pageCount: pageCount || 25,
        page: page || 0,
      },
      results,
      errors,
      currentRequestId: requestId,
    };
    if (errors?.length) {
      dispatch(getTariffsFailure(errors));
    } else {
      dispatch(getTariffsSuccess(tariffSuccess));
    }
  }
);
