import React from 'react';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet';
import { Table } from 'react-bootstrap';
import styles from './UserProfile.module.scss';
import appConstants from '../../app-constants';
import { useSelector } from 'react-redux';
import { CurrentUser, selectCurrentUser } from '../../state/currentUser/currentUserSlice';

const UserProfile = (): React.ReactElement => {
  const currentUser: CurrentUser = useSelector(selectCurrentUser);

  return (
    <Container className={`${styles.wrapper} text-left`}>
      <Helmet>
        <title>User Settings</title>
      </Helmet>
      <br />
      <h1>User Profile</h1>
      <div className="w-50">
        <Container>
          <Table borderless>
            <tbody>
              <tr>
                <td>Username:</td>
                <td>{currentUser.username}</td>
              </tr>
              <tr>
                <td>Email:</td>
                <td>{currentUser.email}</td>
              </tr>
              <tr>
                <td>Genability Id:</td>
                <td>{currentUser.genability_user_id}</td>
              </tr>
            </tbody>
          </Table>
        </Container>
      </div>
      <h1>Groups</h1>
      <div>
        <Container>
          <Table bordered>
            <thead>
              <tr>
                <th>Group</th>
                <th>Description</th>
                <th>Member</th>
              </tr>
            </thead>
            <tbody>
              {appConstants.allGroups.map((group: string, index: number) => (
                <tr key={index}>
                  <td>{group}</td>
                  <td></td>
                  <td>{currentUser.groups.includes(group) ? 'Yes' : ''}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      </div>
    </Container>
  );
};

export default UserProfile;
