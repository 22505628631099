interface RetryableOptions {
  maxRetries: number;
  maxDelay: number;
}

interface RetryableResult {
  isRetryInProgress: boolean;
}

const retryableDispatchWithDelay = async (
  callbackFunction: () => Promise<any>,
  options: RetryableOptions
): Promise<RetryableResult> => {
  const { maxRetries, maxDelay } = options;
  let isRetryInProgress = true;

  for (let currentRetry = 0; currentRetry < maxRetries; currentRetry++) {
    try {
      const response = await callbackFunction();

      if (response?.payload?.status === 'success') {
        isRetryInProgress = false;
        break;
      }

      throw new Error('An error occurred during the operation; retrying...');
    } catch (error) {
      console.error(`Error in operation (Retry ${currentRetry + 1}):`, error);

      const delay = Math.min(2 ** currentRetry * 10000, maxDelay);
      await new Promise(resolve => setTimeout(resolve, delay));
    }

    if (currentRetry === maxRetries - 1) {
      console.error('Maximum retries reached without a successful operation.');
    }
  }

  return { isRetryInProgress };
};

export default retryableDispatchWithDelay;
