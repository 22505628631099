import React, { useRef } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { FieldError } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { NavHashLink as Link } from 'react-router-hash-link';
import { selectDQCheckOverrides } from '../../state/dqCheck/dqCheckSlice';
import DQContextMenu from '../DQOverride/DQContextMenu';

export interface FormErrorObject {
  [key: string | number]: FieldError | undefined;
}

export interface ApiFormErrorProps {
  apiErrors: FormErrorObject;
  errorLinks?: Record<string, string>;
}

export const addLinks = (
  message: string,
  errorLinks: Record<string, string>
): (JSX.Element | string)[] =>
  message.split(/(\s)/g).map(word =>
    word in errorLinks ? (
      <Link key={word} smooth to={errorLinks[word]}>
        {word}
      </Link>
    ) : (
      word
    )
  );

const ApiFormError: React.FC<ApiFormErrorProps> = ({
  apiErrors,
  errorLinks,
}: ApiFormErrorProps) => {
  const dqOverrides = useSelector(selectDQCheckOverrides);
  const rowsRef = useRef<HTMLDivElement[]>([]);

  const mappedErrors = Object.keys(apiErrors)
    .filter((key: string) => key.startsWith('noMatchedProperty') && apiErrors[`${key}`]?.message)
    .map((key: string, idx: number) => {
      const type = apiErrors[`${key}`]?.type;
      const message = apiErrors[`${key}`]?.message;
      const propertyName = key.replace(/noMatchedProperty([_.])*/, '');
      const isOverridden = !!dqOverrides?.find(
        override => override.message === message && override.propertyName === propertyName
      );
      return (
        <Row key={key} ref={(el: HTMLDivElement) => (rowsRef.current[idx] = el)}>
          <Col>
            {type === 'DQFailure' && !isOverridden && (
              <DQContextMenu propertyName={propertyName} errorType={type} errorMessage={message}>
                Error: {message} - Field: {propertyName}
              </DQContextMenu>
            )}
            {type !== 'DQFailure' && (
              <Alert variant="danger">
                {message && errorLinks ? addLinks(message, errorLinks) : message}
              </Alert>
            )}
          </Col>
        </Row>
      );
    });

  return Object.keys(apiErrors)?.length ? <>{mappedErrors}</> : null;
};

export default ApiFormError;
