import { configureStore, Action } from '@reduxjs/toolkit';
import rootReducer, { RootState } from './rootReducer';
import { ThunkAction } from 'redux-thunk';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const store = configureStore({
  reducer: rootReducer,
  enhancers: [sentryReduxEnhancer],
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppThunk = ThunkAction<void, RootState, undefined, Action<string>>; // Default thunkAction
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PromiseThunk = ThunkAction<Promise<any>, RootState, undefined, Action<string>>; // For thunks that should return a promise on dispatch
export type AppDispatch = typeof store.dispatch;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
