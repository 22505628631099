import React, { useState, useEffect, useMemo } from 'react';
import { Col, Form } from 'react-bootstrap';
import {
  useFormContext,
  useFieldArray,
  useWatch,
  UseFormRegisterReturn,
  RegisterOptions,
} from 'react-hook-form';
import styles from '../EditTariffRateForm.module.scss';
import {
  Button,
  CheckInput,
  IconButton,
  SelectInput,
  TextInput,
  CurrencyInput,
} from '@arcadiapower/gen-react-lib';
import PropertyKeyPicker from '../../PropertyKeyPicker/PropertyKeyPicker';
import { types } from '@genability/api';
import { ControlledPropertyKeyInput } from '../../PropertyKeyInput/PropertyKeyInput';
import {
  FORMULA_CONSUMPTION_UPPER_LIMIT,
  FORMULA_DEMAND_UPPER_LIMIT,
  FORMULA_PROPERTY_UPPER_LIMIT,
  tierLimitFieldMap,
  tierLimitLabelMap,
} from '../../../utils/rateFormConstants';
import { withFieldArray, withErrors } from '../../../utils/formUtils';
import { useSelector } from 'react-redux';
import { selectAllPropertyKeysByKeyName } from '../../../state/propertyKeys/propertyKeysSlice';
import UserHelpPopover from '../../UserHelpPopover/UserHelpPopover';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../state/rootReducer';
import {
  PropertyKeyPickerContextType,
  usePropertyKeyPickerContext,
} from '../../../context/propertyKeyPickerContext';

interface RowOrFragmentProps {
  newRow: boolean;
  children: React.ReactNode;
  className?: string;
}

const RowOrFragment = ({ newRow, children, className }: RowOrFragmentProps) => {
  if (newRow) {
    return (
      <Form.Row className={className}>
        <>{children}</>
      </Form.Row>
    );
  } else {
    return <>{children}</>;
  }
};

const createApplicabilityKeySelector = () => {
  return createSelector(
    selectAllPropertyKeysByKeyName,
    (_: unknown, keyName: string) => keyName,
    (propertyKeys, keyName) => {
      return propertyKeys[keyName] || null;
    }
  );
};

interface RateBandProps {
  lseId: number;
  handleRemoveRateBand: (rateSequenceNumber: number | undefined) => void;
  handleAddRateBand: (defaults: Partial<types.TariffRateBand>, rateSequenceNumber?: number) => void;
  tierLimitKeyInputs: Set<string>;
  currency: string | undefined;
  chargeType: types.ChargeType | undefined;
  addApplicability: (tariffProperty: types.GenPropertyKey) => void;
  changeApplicabilityValue: (oldValue: string, newValue: string) => types.TariffRateBand[];
  regroupBands: (rateBands: types.TariffRateBand[]) => void;
}

const RateBands = ({
  lseId,
  handleRemoveRateBand,
  handleAddRateBand,
  tierLimitKeyInputs,
  currency,
  chargeType,
  addApplicability,
  regroupBands,
  changeApplicabilityValue,
}: RateBandProps): React.ReactElement => {
  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  const { fields: formRateBands, replace } = useFieldArray({
    control: control,
    name: 'rateBands',
  });

  const [
    rateBands,
    hasCriteriaChecked,
    hasTieredChecked,
    hasFactorChecked,
    hasVariableChecked,
    applicabilityKey,
  ] = useWatch({
    control: control,
    name: [
      'rateBands',
      'hasCriteriaChecked',
      'hasTieredChecked',
      'hasFactorChecked',
      'hasVariableChecked',
      'applicabilityKey',
    ],
  });

  const renderVariableLimitKey = () => {
    return (
      <ControlledPropertyKeyInput
        control={control}
        name="variableLimitKey"
        label="Tier Limit Key:"
        emptyValue="Fixed Limit (no key)"
        clearable={true}
        renderPicker={props => {
          const title = 'Variable Limit Property Key Picker';
          if (picker?.title !== title) {
            setPicker({
              title: 'Variable Limit Property Key Picker',
              propertyDataTypes: [types.PropertyDataType.FORMULA],
              keySpaces: undefined,
              family: undefined,
              globalProperties: true,
              saved: false,
            });
          }
          return <PropertyKeyPicker lseId={lseId} {...props} />;
        }}
      />
    );
  };

  const renderApplicabilityKey = () => {
    return (
      <ControlledPropertyKeyInput
        label="Criteria Key:"
        name="applicabilityKey"
        control={control}
        required={true}
        clearable={false}
        onChange={tariffProperty => {
          if (!tariffProperty) {
            setValue('hasCriteriaChecked', false);
          } else {
            addApplicability(tariffProperty);
          }
        }}
        onClosePicker={tariffProperty => {
          if (!tariffProperty) setValue('hasCriteriaChecked', false);
        }}
        renderPicker={props => {
          const title = 'Rate Criteria Property Key Picker';
          if (picker?.title !== title) {
            setPicker({
              title,
              propertyDataTypes: [types.PropertyDataType.CHOICE, types.PropertyDataType.BOOLEAN],
              keySpaces: undefined, // TODO
              family: undefined, // TODO
              globalProperties: true,
              saved: false,
            });
          }
          return <PropertyKeyPicker lseId={lseId} {...props} />;
        }}
      />
    );
  };

  const applicabilityKeySelector = useMemo(createApplicabilityKeySelector, []);
  const selectedApplicabilityKey = useSelector((state: RootState) =>
    applicabilityKeySelector(state, applicabilityKey)
  );
  const [selectedApplicabilityChoices, setSelectedApplicabilityChoices] = useState<
    (string | undefined)[]
  >([]);
  const { picker, setPicker } = usePropertyKeyPickerContext() as PropertyKeyPickerContextType;
  useEffect(() => {
    if (rateBands) {
      const choices = [];

      for (const rateBand of rateBands) {
        if (rateBand.applicabilityValue && choices.indexOf(rateBand.applicabilityValue) === -1) {
          choices.push(rateBand.applicabilityValue);
        }
      }
      setSelectedApplicabilityChoices(choices);
    }
  }, [rateBands]);

  const renderCriteriaSelector = (
    rateBand: types.TariffRateBand,
    dataType: types.PropertyDataType,
    registerBand: (
      fieldName: string,
      options: Partial<RegisterOptions>
    ) => Partial<UseFormRegisterReturn>
  ) => {
    const { onChange, ...rest } = registerBand('applicabilityValue', {
      validate: (val: string) => {
        if (dataType == types.PropertyDataType.CHOICE) {
          return (
            selectedApplicabilityKey?.choices?.map(choice => choice.dataValue).includes(val) ||
            'Field is required'
          );
        }
        return true;
      },
    });
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(e);
      const newRateBands = changeApplicabilityValue(
        rateBand.applicabilityValue || '',
        e.target.value
      );
      replace(newRateBands);
    };
    return (
      <>
        {dataType === types.PropertyDataType.CHOICE && (
          <Col sm={4}>
            <SelectInput label="Criteria Value" {...rest} onChange={handleOnChange}>
              <option value="">Choose criteria</option>
              {selectedApplicabilityKey?.choices?.map(function (option) {
                return (
                  <option
                    disabled={
                      option.dataValue != rateBand.applicabilityValue &&
                      selectedApplicabilityChoices.includes(option.dataValue)
                    }
                    key={option.dataValue}
                    value={option.dataValue}
                  >
                    {option.displayValue}
                  </option>
                );
              })}
            </SelectInput>
          </Col>
        )}

        {dataType === types.PropertyDataType.BOOLEAN && (
          <Col sm={4}>
            <SelectInput
              label="Boolean Value"
              placeholder="Choose Value"
              {...rest}
              onChange={handleOnChange}
            >
              {['true', 'false'].map(function (option) {
                return (
                  <option
                    disabled={
                      option != rateBand.applicabilityValue &&
                      selectedApplicabilityChoices.includes(option)
                    }
                    key={option}
                    value={option}
                  >
                    {option}
                  </option>
                );
              })}
            </SelectInput>
          </Col>
        )}

        {dataType !== types.PropertyDataType.BOOLEAN &&
          dataType !== types.PropertyDataType.CHOICE && (
            <Col>
              <TextInput
                type="text"
                label={'Criteria Value'}
                placeholder={'Enter Criteria Value'}
                {...rest}
                onChange={handleOnChange}
              />
            </Col>
          )}
      </>
    );
  };

  const renderTierFields = (
    rateBand: types.TariffRateBand,
    registerBand: (
      fieldName: string,
      options: Partial<RegisterOptions>
    ) => Partial<UseFormRegisterReturn>,
    tierIndex: number
  ) => {
    const tierLimitValueMap: Map<string, number | undefined> = new Map<string, number | undefined>()
      .set(FORMULA_CONSUMPTION_UPPER_LIMIT, rateBand.consumptionUpperLimit)
      .set(FORMULA_DEMAND_UPPER_LIMIT, rateBand.demandUpperLimit)
      .set(FORMULA_PROPERTY_UPPER_LIMIT, rateBand.propertyUpperLimit);

    const tierLimitKeyInputsArray = Array.from(tierLimitKeyInputs);

    return (
      <>
        <Col xs="auto">
          <div className={styles.tieredtext}>{`Tier ${tierIndex + 1}:`}</div>
        </Col>
        {tierLimitKeyInputsArray.map((variableLimitKeyName: string, index: number) => {
          // Render an input field for each limit that appears in the formula
          if (Array.from(tierLimitValueMap.keys()).includes(variableLimitKeyName)) {
            return (
              <Col key={index}>
                <TextInput
                  type="number"
                  label={`${tierLimitLabelMap.get(variableLimitKeyName)}`}
                  {...registerBand(`${tierLimitFieldMap.get(variableLimitKeyName)}`, {
                    valueAsNumber: true,
                  })}
                />
              </Col>
            );
          }
        })}
      </>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderRateAmount = (
    rateBand: types.TariffRateBand,
    registerBand: any,
    placeHolder?: string
  ): JSX.Element => {
    return (
      <Col>
        {rateBand.rateUnit === types.RateUnit.PERCENTAGE ? (
          <TextInput
            type="number"
            controlId={`${rateBand.rateSequenceNumber}`}
            label={'Rate Amount'}
            placeholder={placeHolder || ''}
            inputHintRight={() => '%'}
            className="text-right"
            style={{ paddingRight: 'calc(1.5em + 23px)' }}
            {...registerBand('rateAmount', {
              valueAsNumber: true,
            })}
          />
        ) : (
          <CurrencyInput
            chargeType={chargeType || types.ChargeType.CONSUMPTION_BASED}
            currencyCode={currency}
            controlId={`${rateBand.rateSequenceNumber}`}
            label={'Rate Amount'}
            placeholder={placeHolder || ''}
            {...registerBand('rateAmount', {
              valueAsNumber: true,
            })}
          />
        )}
      </Col>
    );
  };

  const renderRateFactor = (
    registerBand: (
      fieldName: string,
      options: Partial<RegisterOptions>
    ) => Partial<UseFormRegisterReturn>
  ) => {
    return (
      <Col>
        <TextInput
          type="number"
          label={'Rate Factor'}
          {...registerBand('calculationFactor', {
            valueAsNumber: true,
            shouldUnregister: true,
          })}
        />
      </Col>
    );
  };

  const renderIsCredit = (registerBand: (fieldName: string) => Partial<UseFormRegisterReturn>) => {
    return (
      <Col xs="auto">
        <CheckInput
          label="Credit"
          className={styles.tierBandIsCredit}
          {...registerBand('isCredit')}
        />
      </Col>
    );
  };

  const renderDeleteButton = (rateBand: types.TariffRateBand) => {
    return (
      <Col className={styles.tierRemove} xs="auto">
        <IconButton
          icon="close"
          size="sm"
          disabled={rateBands?.length === 1}
          onClick={() => handleRemoveRateBand(rateBand.rateSequenceNumber)}
        />
      </Col>
    );
  };

  const renderReGroupBands = () => {
    return (
      <Form.Row>
        <Col>
          <Button variant="link" action={() => regroupBands(rateBands)}>
            Re-group Rates
          </Button>
          <UserHelpPopover id="regroup-rates-help">
            Use to re-group rates if applicability values are out of order.
          </UserHelpPopover>
        </Col>
      </Form.Row>
    );
  };

  const isRateBandList = (): boolean => {
    return rateBands.length > 1 || hasTieredChecked || hasCriteriaChecked;
  };

  const hasChoices = () => {
    return (
      selectedApplicabilityKey &&
      selectedApplicabilityKey?.dataType === types.PropertyDataType.CHOICE &&
      selectedApplicabilityKey?.choices
    );
  };

  const getRemainingChoices = () => {
    return selectedApplicabilityKey?.choices?.length
      ? selectedApplicabilityKey?.choices?.length -
          selectedApplicabilityChoices.filter(choice => choice !== '').length
      : 0;
  };

  const isBoolean = () => {
    return (
      selectedApplicabilityKey &&
      selectedApplicabilityKey.dataType === types.PropertyDataType.BOOLEAN
    );
  };

  const hasSameApplicabilityValue = (
    rateBand: types.TariffRateBand,
    index: number,
    currentApplicabilityValue: string | undefined
  ) => {
    // If this is not the first rateBand, and this rateBands's applicability value is
    // the same as the last one we saw, hide the selector. We'll use the selector
    // of the first rateBand with this value to set all of the rateBands with this value.
    return (
      index > 0 && hasTieredChecked && currentApplicabilityValue === rateBand.applicabilityValue
    );
  };

  const showAddTierButton = (rateBand: types.TariffRateBand, index: number) => {
    if (!hasTieredChecked) return false;
    return (
      index == rateBands.length - 1 || // This is the last tier,
      (hasCriteriaChecked && // Or criteria is checked and there are multiple lists,
        rateBands[index + 1].applicabilityValue !== currentApplicabilityValue) // and the next one has a different applicability value,
    );
  };

  let choicesRemaining = 0;
  let currentApplicabilityValue: string | undefined = undefined;
  let currentTierIndex = 0;

  if (hasChoices()) {
    choicesRemaining = getRemainingChoices();
  }

  if (isBoolean()) {
    choicesRemaining = 2 - (rateBands?.length || 0);
  }

  return (
    <>
      {hasTieredChecked && renderVariableLimitKey()}
      {hasCriteriaChecked && renderApplicabilityKey()}

      {formRateBands.map((rateBand: types.TariffRateBand & { id: string }, index: number) => {
        const name = `rateBands.${index}`;
        const registerBand = withFieldArray(withErrors(register, { errors }), {
          field: rateBand,
          name,
        });

        // Show the applicability selector by default if hasCriteria is checked
        let showApplicabilitySelector = hasCriteriaChecked;

        if (hasCriteriaChecked) {
          if (hasSameApplicabilityValue(rateBand, index, currentApplicabilityValue)) {
            showApplicabilitySelector = false;
            currentTierIndex += 1;
          } else {
            currentApplicabilityValue = rateBand.applicabilityValue;
            currentTierIndex = 0;
          }
        } else {
          currentTierIndex = index;
        }

        return (
          <React.Fragment key={rateBand.id}>
            <RowOrFragment newRow={isRateBandList()}>
              {showApplicabilitySelector &&
                renderCriteriaSelector(rateBand, selectedApplicabilityKey?.dataType, registerBand)}
              <RowOrFragment
                newRow={showApplicabilitySelector && hasTieredChecked}
                className="w-100 mx-0"
              >
                {hasTieredChecked && renderTierFields(rateBand, registerBand, currentTierIndex)}
                {!hasVariableChecked && renderRateAmount(rateBand, registerBand)}
                {hasFactorChecked && renderRateFactor(registerBand)}
                {!hasVariableChecked && renderIsCredit(registerBand)}
                {rateBands.length > 1 && renderDeleteButton(rateBand)}
              </RowOrFragment>
            </RowOrFragment>

            {showAddTierButton(rateBand, index) && (
              <Form.Row key={`addTier-${rateBand.id}`}>
                <Button
                  variant="link"
                  action={() =>
                    handleAddRateBand(
                      {
                        applicabilityValue: rateBand.applicabilityValue || '',
                      },
                      rateBand.rateSequenceNumber
                    )
                  }
                >
                  + Add Tier
                </Button>
              </Form.Row>
            )}
          </React.Fragment>
        );
      })}
      {choicesRemaining > 0 && (
        <Form.Row>
          <Button
            variant="link"
            action={() =>
              handleAddRateBand({
                applicabilityValue:
                  selectedApplicabilityKey.dataType == types.PropertyDataType.CHOICE
                    ? `${applicabilityKey}-${(formRateBands.length || 0) + 1}`
                    : '',
              })
            }
          >
            + Add Criteria Value ({choicesRemaining} remaining)
          </Button>
        </Form.Row>
      )}
      {hasCriteriaChecked && renderReGroupBands()}
    </>
  );
};

export default RateBands;
