// This is for Genability Beta APIs that are not public yet.

import { restClient } from '@genability/api';
import { BetaApi } from './calc-history';

export class GenabilityBetaApi {
  private static _instance: GenabilityBetaApi;

  private _credentials: restClient.RestApiCredentials;
  private _baseUrl: string;
  private _api: BetaApi | undefined;

  private constructor(baseUrl?: string, credentials?: restClient.RestApiCredentials) {
    this._baseUrl = baseUrl || 'https://api-dev.teamtariff.com';
    this._credentials = credentials || {};
  }

  public static configure(
    baseUrl?: string,
    credentials?: restClient.RestApiCredentials
  ): GenabilityBetaApi {
    return this._instance || (this._instance = new this(baseUrl, credentials));
  }

  public get calcHistory(): BetaApi {
    if (this._api === undefined) {
      this._api = new BetaApi(
        new restClient.GenabilityConfig({
          proxy: this._baseUrl,
          credentials: this._credentials,
        })
      );
    }
    return this._api;
  }
}
