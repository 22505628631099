import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTariff, selectTariff } from '../../state/tariff/tariffSlice';
import { fetchTariffHistory, selectTariffHistory } from '../../state/tariff/tariffHistorySlice';
import { fetchTariffDiff, selectTariffDiff } from '../../state/tariff/tariffDiffSlice';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import ResourceDiffBySection from '../ResourceDiffSection/ResourceDiffSection';
import { Container } from 'react-bootstrap';
import { Icon } from '@arcadiapower/gen-react-lib';
import { LoadingState } from '../../state/reduxUtils';

export interface TariffDiffParams {
  masterTariffId: string;
}

const { PENDING } = LoadingState;

const TariffDiff: React.FC<RouteComponentProps<TariffDiffParams>> = props => {
  const dispatch = useDispatch();
  const { tariff } = useSelector(selectTariff);
  const { tariffHistory } = useSelector(selectTariffHistory);
  const {
    result: tariffDiff,
    meta: { baseTariffId, comparisonTariffId },
    loading,
  } = useSelector(selectTariffDiff);

  useEffect(() => {
    const { masterTariffId } = props.match.params;
    const urlParams = new URLSearchParams(props.location.search);

    const baseTariffId = Number(urlParams.get('tariffIdA'));
    const comparisonTariffId = Number(urlParams.get('tariffIdB'));

    dispatch(fetchTariff(Number(masterTariffId)));
    dispatch(fetchTariffHistory(Number(masterTariffId)));
    if (baseTariffId !== undefined && comparisonTariffId !== undefined) {
      dispatch(fetchTariffDiff({ baseTariffId, comparisonTariffId }));
    }
  }, [dispatch, props]);

  const getTariffVersion = (tariffId: number | null) =>
    tariffHistory?.tariffVersions?.find(version => version.tariffId === tariffId);

  const isLoading = loading === PENDING;

  return (
    <Container className="pb-5">
      <div>
        <h2 className="display-4 my-3 mb-2 mt-5">
          {tariff?.tariffCode} {tariff?.tariffName}
        </h2>
        <div className="font-weight-bold">
          <span> Base date: </span>
          <span> {getTariffVersion(baseTariffId)?.effectiveDate} </span>
          <span> ({baseTariffId}) </span>
          <span className="mx-3">-</span>
          <span> Comparison date: </span>
          <span> {getTariffVersion(comparisonTariffId)?.effectiveDate} </span>
          <span> ({comparisonTariffId}) </span>
        </div>
        <div className="my-4">
          {isLoading && <Icon iconName="loader" spin fixedWidth />}
          {!isLoading && tariffDiff && <ResourceDiffBySection resourceDiff={tariffDiff} />}
        </div>
      </div>
    </Container>
  );
};

export default withRouter(TariffDiff);
