import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TabContainer from 'react-bootstrap/TabContainer';
import TabContent from 'react-bootstrap/TabContent';
import { DocumentViewer, NotificationLevel, TaskDocument } from '@arcadiapower/gen-react-lib';
import { types } from '@genability/api';
import styles from './Do03WorkBody.module.scss';
import StartTariffPanel from '../Panels/StartTariffPanel';
import EditTariffHeaderPanel from '../Panels/EditTariffHeaderPanel';
import AddTariffHeaderPanel from '../Panels/AddTariffHeaderPanel';
import EligibilityPanel from '../Panels/EligibilityPanel';
import RatesPanel from '../Panels/RatesPanel';
import RidersPanel from '../Panels/RidersPanel';
import RateCriteriaPanel from '../Panels/RateCriteriaPanel';
import CostsPanel from '../Panels/CostsPanel';
import {
  TaskAssignment,
  TaskAssignmentAnswerField,
  TaskTypeId,
} from '../../../task-api/types/task';
import SubmitTariffPanel from '../Panels/SubmitTariffPanel';
import { fetchTimeOfUseGroups } from '../../../state/timeOfUses/timeOfUseSlice';
import { fetchTerritories } from '../../../state/territories/territoriesSlice';
import {
  selectTaskAssignmentTariff,
  fetchTaskAssignmentTariff,
  updateTaskAssignmentTariff,
} from '../../../state/taskAssignmentTariffs/taskAssignmentTariffsSlice';
import { fetchAllRiderTariffs } from '../../../state/riderTariffs/riderTariffsSlice';
import {
  fetchTariffPropertyKeys,
  fetchPropertyKeys,
} from '../../../state/propertyKeys/propertyKeysSlice';
import { fetchDocument, selectDocumentBySectionId } from '../../../state/document/documentSlice';
import { Col, Container, Row } from 'react-bootstrap';
import appConstants from '../../../app-constants';
import { useHistory } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { fetchSeasons } from '../../../state/loadServingEntity/loadServingEntitySlice';
import { Button, Text, Tooltip } from '@arcadiapower/shrike';
import { addNotification } from '../../../state/notification/notificationSlice';
import { varianceCheckNotificationMessages } from '../../../utils/constants';
import { fetchTaskAssignmentAnswer } from '../../../state/taskAssignment/taskAssignmentSlice';
import { TaskTariff } from '../../../task-api/types/task';

export enum WorkPanel {
  START = 'start',
  HEADER = 'header',
  ELIGIBILITY = 'eligibility',
  RATES = 'rates',
  RIDERS = 'riders',
  RATE_CRITERIA = 'criteria',
  COSTS = 'costs',
  SUBMIT = 'submit',
}

interface DoWorkBodyParams {
  taskAssignment: TaskAssignment;
  requestedWorkPanel?: string;
}

function DoWorkBody({ taskAssignment, requestedWorkPanel }: DoWorkBodyParams): React.ReactElement {
  const [workPanel, setWorkPanel] = useState(requestedWorkPanel || WorkPanel.START);
  const [lseId, setLseId] = useState(taskAssignment?.task?.lseId);
  const [documentSectionId, setDocumentSectionId] = useState(0);

  const cleanTariff: TaskTariff | undefined = useSelector(selectTaskAssignmentTariff);
  const document: TaskDocument | null = useSelector(selectDocumentBySectionId(documentSectionId));

  const [showArchiveUrl, setShowArchiveUrl] = useState(true);

  const toggleArchiveUrl = () => {
    setShowArchiveUrl(prevShowArchive => !prevShowArchive);
  };

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (requestedWorkPanel) {
      setWorkPanel(requestedWorkPanel);
    }
  }, [requestedWorkPanel]);

  useEffect(() => {
    const varianceCheckResultTaskInput = taskAssignment?.task?.taskInputs?.find(
      taskInput => taskInput.inputKey === 'variance_check_result'
    );

    if (varianceCheckResultTaskInput) {
      const varianceCheckResultValue = varianceCheckResultTaskInput.inputValue;

      if (varianceCheckNotificationMessages.hasOwnProperty(varianceCheckResultValue)) {
        const notificationLevel =
          varianceCheckResultValue === 'PENDING' ? NotificationLevel.Info : NotificationLevel.Error;

        const notificationMessage = varianceCheckNotificationMessages[varianceCheckResultValue];

        dispatch(addNotification(notificationMessage, notificationLevel));
      }
    }
  }, [dispatch, taskAssignment]);

  useEffect(() => {
    if (taskAssignment && taskAssignment.taskAssignmentId) {
      dispatch(
        fetchTaskAssignmentTariff({
          taskAssignmentId: taskAssignment.taskAssignmentId,
        })
      );
      if (taskAssignment.task) {
        setLseId(taskAssignment.task.lseId);
        if (
          taskAssignment.taskTypeId === TaskTypeId['03_02'] ||
          taskAssignment.taskTypeId === TaskTypeId['03_03']
        ) {
          setDocumentSectionId(taskAssignment.task.entityId);
        }
      }
    }
  }, [taskAssignment]);

  useEffect(() => {
    if (cleanTariff) {
      dispatch(fetchTariffPropertyKeys(cleanTariff));
      if (cleanTariff.tariffType != 'RIDER') {
        dispatch(
          fetchAllRiderTariffs({
            lseId: cleanTariff.lseId,
            territoryId: cleanTariff.territoryId,
            populateProperties: false,
            populateRates: false,
          })
        );
      }
      if (taskAssignment.taskTypeId === TaskTypeId['03_04'] && cleanTariff.documents) {
        setDocumentSectionId(cleanTariff.documents[0].documentSectionId);
      }
    }
  }, [cleanTariff]);

  useEffect(() => {
    if (document == null && documentSectionId != 0) {
      dispatch(fetchDocument(documentSectionId, 'documentSectionId'));
    }
  }, [documentSectionId]);

  useEffect(() => {
    // Make sure that the cleanTariff points to the correct document when versioning
    if (
      taskAssignment.taskTypeId === TaskTypeId['03_03'] &&
      cleanTariff &&
      document &&
      documentSectionId
    ) {
      if (cleanTariff.documents?.find(doc => doc.documentId == document.documentId)) {
        // document already attached, no-op
        return;
      }

      const tariffDoc: types.TariffDocument = {
        tariffId: cleanTariff.tariffId,
        documentId: document.documentId,
        documentSectionId: documentSectionId,
        document: document,
      };

      const updatedTariff = cloneDeep(cleanTariff);
      updatedTariff.documents = [tariffDoc];

      // Write the new document
      dispatch(
        updateTaskAssignmentTariff({
          taskAssignmentId: taskAssignment.taskAssignmentId,
          tariff: updatedTariff,
        })
      );
    }
  }, [cleanTariff, document]);

  useEffect(() => {
    if (lseId) {
      dispatch(fetchTerritories({ lseId }));
      dispatch(fetchTimeOfUseGroups(lseId));
      dispatch(fetchSeasons(lseId));
    }
    const options = {
      searchTerm: '',
      startsWith: false,
      lseId,
      propertyDataType: types.PropertyDataType.DECIMAL,
      propertyKeySpace: null,
      propertyFamily: null,
      includeGlobalProperties: true,
      filterByLseId: true,
      pageNumber: 0,
    };
    dispatch(fetchPropertyKeys(options));
    dispatch(
      fetchPropertyKeys({
        ...options,
        propertyDataType: types.PropertyDataType.DEMAND,
      })
    );
    dispatch(
      fetchPropertyKeys({
        ...options,
        propertyDataType: types.PropertyDataType.LOOKUP,
      })
    );
    dispatch(
      fetchPropertyKeys({
        ...options,
        propertyDataType: types.PropertyDataType.CHOICE,
      })
    );
    dispatch(
      fetchPropertyKeys({
        ...options,
        propertyDataType: types.PropertyDataType.FORMULA,
      })
    );
    dispatch(
      fetchPropertyKeys({
        ...options,
        propertyDataType: types.PropertyDataType.BOOLEAN,
      })
    );
    dispatch(
      fetchPropertyKeys({
        ...options,
        propertyDataType: types.PropertyDataType.INTEGER,
      })
    );
  }, [lseId]);

  const handlePanelNavigation = (goto: WorkPanel | string | null) => {
    if (goto === WorkPanel.RATES) {
      dispatch(
        fetchTaskAssignmentAnswer({
          taskAssignmentId: taskAssignment.taskAssignmentId,
          answerField: TaskAssignmentAnswerField.AI_STAGED_ENTITY,
        })
      );
    }

    if (goto) {
      history.push(`${appConstants.routes.do}/${taskAssignment.taskAssignmentId}/${goto}`);
    }
  };

  const handleRouteNavigation = (goto: string | null) => {
    if (goto) {
      history.push(goto);
    }
  };

  const renderTabContent = (eventKey: string | null) => {
    let component = tabData[0].component;
    tabData.map(tab => {
      if (tab.eventKey == eventKey) {
        component = tab.component;
      }
    });
    return <div>{component}</div>;
  };

  const renderDocumentViewer = () => {
    const currentDocumentSection = document?.sections?.find(section => {
      return section.documentSectionId == documentSectionId;
    });
    if (document?.sourceContentType == 'application/pdf') {
      return (
        <>
          {document && currentDocumentSection && (
            <DocumentViewer document={document} documentSection={currentDocumentSection} />
          )}
        </>
      );
    } else {
      const sourceUrl = document?.sourceUrl;
      const archiveUrl = document?.archiveUrl;
      const iIframeContentUrl = showArchiveUrl ? archiveUrl : sourceUrl;
      return (
        <div className={styles.iframeContainer}>
          <Row className="mt-2">
            <Text
              color="primary"
              opacity="high"
              tag="h3"
              textStyle="heading700"
              className="ml-4 mt-2"
            >
              {currentDocumentSection?.sectionHeading}
            </Text>
            {['Source', 'Archive'].map(type => (
              <Tooltip
                key={`${type}UrlToolTip`}
                backgroundColor="primary"
                content={`Load content from ${type} Url. ${type} URL: ${
                  type === 'Source' ? sourceUrl : archiveUrl
                }`}
                label={`${type} Url`}
                tooltipId={`${type.toLowerCase()}UrlToolTip`}
                place="top"
              >
                <Button
                  className="ml-2"
                  backgroundColor="primaryInverse"
                  onClick={() => toggleArchiveUrl()}
                  size="small"
                  disabled={
                    (!showArchiveUrl && type === 'Source') || (showArchiveUrl && type === 'Archive')
                  }
                >
                  {`${type} Url`}
                </Button>
              </Tooltip>
            ))}
          </Row>
          {!iIframeContentUrl ? (
            <Row className="ml-3">
              <Text color="error" opacity="high" tag="h3" textStyle="heading700" className="mt-3">
                Content Url not available
              </Text>
            </Row>
          ) : (
            <>
              <Row className="ml-2 mt-3">
                <Text
                  color="primary"
                  opacity="high"
                  tag="h3"
                  textStyle="heading700"
                  className="ml-3 mt-2"
                >
                  {`Content from ${showArchiveUrl ? 'Archive' : 'Source'} Url`}
                </Text>
              </Row>
              <Container>
                <Row className="mt-1">
                  <iframe
                    className={`${styles.iframeElement} mt-2`}
                    src={iIframeContentUrl}
                    style={{ width: '100%', height: '100vh', border: 'none' }}
                  ></iframe>
                </Row>
              </Container>
            </>
          )}
        </div>
      );
    }
  };

  const TariffHeaderPanel =
    taskAssignment?.taskTypeId === TaskTypeId['03_02']
      ? AddTariffHeaderPanel
      : EditTariffHeaderPanel;
  const tabData = [
    {
      eventKey: WorkPanel.START,
      title: 'Start',
      component: (
        <StartTariffPanel
          taskAssignment={taskAssignment}
          onNext={() => handlePanelNavigation(WorkPanel.HEADER)}
        />
      ),
    },
  ];
  if (cleanTariff) {
    tabData.push(
      {
        eventKey: WorkPanel.HEADER,
        title: 'Header',
        component: (
          <TariffHeaderPanel
            taskAssignment={taskAssignment}
            onPrevious={() => handlePanelNavigation(WorkPanel.START)}
            onNext={() => handlePanelNavigation(WorkPanel.ELIGIBILITY)}
          />
        ),
      },
      {
        eventKey: WorkPanel.ELIGIBILITY,
        title: 'Eligibility',
        component: (
          <EligibilityPanel
            taskAssignment={taskAssignment}
            onPrevious={() => handlePanelNavigation(WorkPanel.HEADER)}
            onNext={() => handlePanelNavigation(WorkPanel.RATES)}
          />
        ),
      },
      {
        eventKey: WorkPanel.RATES,
        title: 'Rates',
        component: (
          <RatesPanel
            taskAssignment={taskAssignment}
            onPrevious={() => handlePanelNavigation(WorkPanel.ELIGIBILITY)}
            onNext={() => handlePanelNavigation(WorkPanel.RIDERS)}
          />
        ),
      },
      {
        eventKey: WorkPanel.RIDERS,
        title: 'Riders',
        component: (
          <RidersPanel
            taskAssignment={taskAssignment}
            onPrevious={() => handlePanelNavigation(WorkPanel.RATES)}
            onNext={() => handlePanelNavigation(WorkPanel.RATE_CRITERIA)}
          />
        ),
      },
      {
        eventKey: WorkPanel.RATE_CRITERIA,
        title: 'Rate Criteria',
        component: (
          <RateCriteriaPanel
            taskAssignment={taskAssignment}
            onPrevious={() => handlePanelNavigation(WorkPanel.RIDERS)}
            onNext={() => handlePanelNavigation(WorkPanel.COSTS)}
          />
        ),
      },
      {
        eventKey: WorkPanel.COSTS,
        title: 'Costs',
        component: (
          <CostsPanel
            taskAssignment={taskAssignment}
            onPrevious={() => handlePanelNavigation(WorkPanel.RATE_CRITERIA)}
            onNext={() => handlePanelNavigation(WorkPanel.SUBMIT)}
          />
        ),
      },
      {
        eventKey: WorkPanel.SUBMIT,
        title: 'Review',
        component: (
          <SubmitTariffPanel
            taskAssignment={taskAssignment}
            onPrevious={() => handlePanelNavigation(WorkPanel.COSTS)}
            onSubmit={() => handleRouteNavigation(appConstants.routes.do)}
          />
        ),
      }
    );
  }

  const isRider = cleanTariff?.tariffType === types.TariffType.RIDER;
  return (
    <React.Fragment>
      <div className={styles.tabContainer}>
        <TabContainer>
          <TabContent>
            <Tabs
              id="controlled-tab"
              className={styles.tabs}
              activeKey={workPanel}
              onSelect={(workPanel: string | null) => handlePanelNavigation(workPanel)}
            >
              {tabData.map(tab => {
                if (isRider && tab.eventKey === WorkPanel.RIDERS) {
                  return null;
                }
                return (
                  <Tab
                    key={tab.eventKey}
                    eventKey={tab.eventKey}
                    title={tab.title}
                    tabClassName={styles.tab}
                  ></Tab>
                );
              })}
            </Tabs>
          </TabContent>
        </TabContainer>
      </div>
      <Row className={styles.tabContent}>
        <Col xs={7} className={styles.formPanel}>
          {renderTabContent(workPanel)}
        </Col>
        <Col xs={5} className={styles.documentPanel}>
          {renderDocumentViewer()}
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default DoWorkBody;
