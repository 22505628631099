import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import TariffCalcSummary from '../../../../components/TariffCalcSummary/TariffCalcSummary';

const TariffCalcSummaryViewer: React.FC<RouteComponentProps> = props => {
  const [baseRequestId, setBaseRequestId] = useState<string>('');
  const [comparisonRequestId, setComparisonRequestId] = useState<string>('');

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    setBaseRequestId(params.get('baseRequestId') || '');
    setComparisonRequestId(params.get('comparisonRequestId') || '');
  }, [props]);

  return (
    <TariffCalcSummary baseRequestId={baseRequestId} comparisonRequestId={comparisonRequestId} />
  );
};

export default withRouter(TariffCalcSummaryViewer);
