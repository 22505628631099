export enum Op {
  ADD = 'add',
  REMOVE = 'remove',
  REPLACE = 'replace',
  MOVE = 'move',
  COPY = 'copy',
  TEST = 'test',
}

export interface Operation {
  op: Op;
  path: string;
  from?: string;
}

export interface ChangeOperation<T> extends Operation {
  value?: T;
  fromValue?: T;
}

export interface NestedOperation extends Operation {
  nestedPatch: Operation[];
}

export interface ResourceDiffSection {
  section: string;
  path: string;
  count: number;
  unchange?: number | null;
  change?: number | null;
  add?: number | null;
  remove?: number | null;
}

export interface ResourceDiff {
  diffMethod: string;
  summary: ResourceDiffSection[];
  patch: (Operation | ChangeOperation<unknown> | NestedOperation)[];
}

export function isOperation(arg: Operation): arg is Operation {
  return arg.op !== undefined && arg.path !== undefined;
}

export function isChangeOperation(arg: Operation): arg is Operation {
  return isOperation(arg) && ('value' in arg || 'fromValue' in arg);
}

export function isNestedOperation(arg: Operation): arg is NestedOperation {
  return isOperation(arg) && 'nestedPatch' in arg;
}

export function isResourceDiffSection(arg: ResourceDiffSection): arg is ResourceDiffSection {
  return arg.section !== undefined && arg.path !== undefined && arg.count !== undefined;
}

export function isResourceDiff(arg: ResourceDiff): arg is ResourceDiff {
  return arg.diffMethod !== undefined && arg.summary !== undefined && arg.patch !== undefined;
}

export function pathLastPosition(path: string): number | null {
  const parts = path.split('/');
  // loop backwards to find the last one
  for (let i = parts.length - 1; i >= 0; i--) {
    if (parts[i] === '') continue;
    if (!isNaN(+parts[i])) return Number(parts[i]);
  }
  return null;
}
