import { DateTime } from 'luxon';

/**
 * Converts a date string into valid Date or else null
 * @param dateString string date as yyyy-MM-dd
 * @returns Date without timeZone
 */
export const getValidDate = (dateString: string | null | undefined): Date | null => {
  const validDate = dateString ? new Date(dateString) : null;
  return validDate && !isNaN(validDate.getTime()) ? validDate : null;
};

const ago = (dateString: string | DateTime | null | undefined): string => {
  let totalSeconds = null;
  if (typeof dateString == 'string' && dateString) {
    totalSeconds = (new Date().getTime() - new Date(dateString).getTime()) / 1000;
  } else if (typeof dateString === 'object' && dateString) {
    totalSeconds = (new Date().getTime() - dateString.toMillis()) / 1000;
  }
  const relativeTime = convertToRelativeTime(totalSeconds);
  return relativeTime;
};

const convertToRelativeTime = (totalSeconds: number | null): string => {
  if (totalSeconds == null) {
    return '';
  }
  if (totalSeconds < 65) {
    return 'just now';
  }
  const totalMinutes = Math.floor(totalSeconds / 60);
  if (totalMinutes < 91) {
    return totalMinutes == 1 ? `${totalMinutes} minute ago` : `${totalMinutes} minutes ago`;
  }
  const totalHours = Math.floor(totalMinutes / 60);
  if (totalHours < 49) {
    return totalHours == 1 ? `${totalHours} hour ago` : `${totalHours} hours ago`;
  }
  const totalDays = Math.floor(totalHours / 24);
  if (totalDays < 99) {
    return `${totalDays} days ago`;
  }
  const totalMonths = Math.floor(totalDays / 30);
  return `${totalMonths} months ago`;
};

//Will accept valid ISO strings and DateTime else return empty string
//For valid formats refer https://moment.github.io/luxon/docs/manual/parsing.html#table-of-tokens
export const onAt = (dateString: string | DateTime | null | undefined): string => {
  const formatString = 'EEE DD hh:mm a';
  if (typeof dateString == 'string' && dateString) {
    const res = DateTime.fromJSDate(new Date(dateString)).toFormat(formatString);
    return res == 'Invalid Date' ? '' : res;
  } else if (typeof dateString === 'object' && dateString) {
    return dateString.toFormat(formatString);
  }
  return '';

  // if (typeof(dateString) == 'string' && dateString) {
  //   totalSeconds = (new Date().getTime() - new Date(dateString).getTime()) / 1000;
  // } else if (typeof(dateString) === 'object' && dateString) {
  //   totalSeconds = (new Date().getTime() - dateString.toMillis()) / 1000;
  // }
  // const relativeTime = convertToRelativeTime(totalSeconds);
  // return relativeTime;
};

//Will accept date in string format and return date like Aug 6, 2014
//For valid formats refer https://moment.github.io/luxon/docs/manual/parsing.html#table-of-tokens
export const on = (dateString: string | null): string => {
  if (dateString === null) return 'Invalid Date';
  return DateTime.fromJSDate(new Date(dateString)).toFormat('DD');
};

// Check if a given date is between a range but if one range's limit is null it means the range is opened.
export const isBetweenDates = (
  givenDate: Date | null,
  fromDate: Date | null,
  toDate: Date | null
): boolean => {
  return !!givenDate && (!fromDate || givenDate >= fromDate) && (!toDate || givenDate <= toDate);
};

//Format dateString YYYY-MM-DD to "Month Day" using UTC to avoid Timezone conversion.
//Because Date uses Browser's TimeZone, it's required to define UTC as option parameter to avoid wrong formatting
//Example: 2022-10-01 -> October 1
export const formatDateMD = (dateString: string | null): string => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    timeZone: 'UTC',
    month: 'long',
    day: 'numeric',
  };
  return dateString ? new Date(dateString).toLocaleString('en-US', dateOptions) : '';
};

export const applyTimeZoneOffSet = (date: Date | undefined) => {
  return date ? new Date(date.getTime() - date.getTimezoneOffset() * 60000) : date;
};

export const formatDateToLocaleStringWithTimeStamp = (dateString: string | undefined | null) => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  return dateString ? new Date(dateString).toLocaleString('en-US', dateOptions) : '';
};

export default ago;
