import React from 'react';
import { useAppDispatch } from '../../state/store';
import {
  selectPropertyKeysLoading,
  selectAllPropertyKeysList,
} from '../../state/propertyKeys/propertyKeysSlice';
import {
  IPropertySearchOption,
  PropertyKeyPicker as GenPropertyKeyPicker,
} from '@arcadiapower/gen-react-lib';
import { types } from '@genability/api';
import { useSelector } from 'react-redux';
import {
  PropertyKeyPickerContextType,
  usePropertyKeyPickerContext,
} from '../../context/propertyKeyPickerContext';

export interface PropertyKeyPickerProps {
  selected?: string;
  show: boolean;
  onSelected?: (selectedPropertyKey: types.GenPropertyKey) => void;
  onClose?: () => void;
  lseId?: number;
}

const PropertyKeyPicker: React.FC<PropertyKeyPickerProps> = ({
  selected,
  show,
  onSelected,
  onClose,
  lseId,
}: PropertyKeyPickerProps) => {
  const keysLoading = useSelector(selectPropertyKeysLoading);
  const allPropertyKeysByKeyName = useSelector(selectAllPropertyKeysList);
  const { picker: defaults, onPropertySearch } =
    usePropertyKeyPickerContext() as PropertyKeyPickerContextType;
  const dispatch = useAppDispatch();

  return defaults?.saved ? (
    <GenPropertyKeyPicker
      selected={selected}
      title={defaults.title}
      properties={allPropertyKeysByKeyName}
      show={show}
      includePropertyDataTypes={defaults.propertyDataTypes}
      includePropertyKeySpaces={defaults.keySpaces}
      includePropertyFamily={defaults.family}
      includeGlobalProperties={defaults.globalProperties}
      loading={keysLoading}
      onSelected={onSelected}
      onSearch={(options: IPropertySearchOption) =>
        onPropertySearch &&
        onPropertySearch(dispatch, {
          ...options,
          lseId,
        })
      }
      onClose={onClose}
    />
  ) : null;
};

export default PropertyKeyPicker;
