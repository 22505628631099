import { restClient } from '@genability/api';
import { TaskApiV2 } from './task-api-v2';
import { DocumentApi } from './document-api';
import { TariffApiV2 } from './tariff-api-v2';
import { TariffApi } from './tariff-api';

export class GenabilityApiV2 {
  private static _instance: GenabilityApiV2;

  private _credentials: restClient.RestApiCredentials;
  private _baseUrl: string;
  private _task: TaskApiV2 | undefined;
  private _tariff: TariffApiV2 | TariffApi | undefined;
  private _document: DocumentApi | undefined;

  private constructor(baseUrl?: string, credentials?: restClient.RestApiCredentials) {
    this._baseUrl = baseUrl || 'https://api-dev.teamtariff.com';
    this._credentials = credentials || {};
  }

  public static configure(
    baseUrl?: string,
    credentials?: restClient.RestApiCredentials
  ): GenabilityApiV2 {
    if (baseUrl && credentials) {
      return (this._instance = new this(baseUrl, credentials));
    }
    return this._instance;
  }

  public get taskV2(): TaskApiV2 {
    if (this._task === undefined) {
      this._task = new TaskApiV2(
        new restClient.GenabilityConfig({
          proxy: this._baseUrl,
          credentials: this._credentials,
        })
      );
    }
    return this._task;
  }

  public get document(): DocumentApi {
    if (this._document === undefined) {
      this._document = new DocumentApi(
        new restClient.GenabilityConfig({
          proxy: this._baseUrl,
          credentials: this._credentials,
        })
      );
    }
    return this._document;
  }

  public get tariffV2(): TariffApiV2 {
    if (this._tariff === undefined) {
      this._tariff = new TariffApiV2(
        new restClient.GenabilityConfig({
          proxy: this._baseUrl,
          credentials: this._credentials,
        })
      );
    }
    return this._tariff as TariffApiV2;
  }
  public get tariff(): TariffApi {
    if (this._tariff === undefined) {
      this._tariff = new TariffApi(
        new restClient.GenabilityConfig({
          proxy: this._baseUrl,
          credentials: this._credentials,
        })
      );
    }
    return this._tariff as TariffApi;
  }
}
