import { Modal, Select, TextArea } from '@arcadiapower/shrike';
import React, { useState } from 'react';

interface DynamicModalProps {
  title: string;
  dropdown?: {
    title: string;
    options: any[];
  };
  textArea?: {
    title: string;
    error: string;
  };
  onSubmit: (ModalData: ModalData) => void;
  show: boolean;
  testid: string;
  onHide: () => void;
  isLoading: boolean;
}

export interface ModalData {
  dropDownValue?: string;
  textAreaInput?: string;
}

const DynamicModal: React.FC<DynamicModalProps> = props => {
  const { title, dropdown, textArea, onSubmit, show, onHide, isLoading, testid } = props;
  const [modalData, setModalData] = useState<ModalData>({
    dropDownValue: dropdown?.options[0].value,
    textAreaInput: '',
  });

  return (
    <>
      <Modal aria-label="Modal" data-testid={testid} isOpen={show}>
        <Modal.Header title={title} onClose={onHide}></Modal.Header>
        <Modal.Content>
          {dropdown && (
            <Select
              value={modalData.dropDownValue}
              label={dropdown.title}
              loadingMessage="Loading..."
              minHeight="110px"
              name={dropdown.title}
              onChange={value => {
                setModalData({ ...modalData, dropDownValue: value });
              }}
              options={dropdown.options}
            />
          )}
          {textArea && (
            <TextArea
              id="textarea"
              label={textArea.title}
              errorText={!modalData.textAreaInput ? textArea.error : ''}
              minHeight="110px"
              name={textArea.title}
              onChange={value => {
                setModalData({ ...modalData, textAreaInput: value.toString() });
              }}
              value={modalData.textAreaInput ? modalData.textAreaInput : ''}
            />
          )}
        </Modal.Content>

        <Modal.Footer onSubmit={() => onSubmit(modalData)} loading={isLoading}></Modal.Footer>
      </Modal>
    </>
  );
};

export default DynamicModal;
