import React, { FC } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { TariffSelectionFooterProps } from './types';

const TariffActionFooter: FC<TariffSelectionFooterProps> = ({
  tariffDates,
  maxSelectedDates,
  masterTariffId,
}) => {
  const history = useHistory();

  const onRunCalculation = () => {
    const tariffIds = tariffDates.map(date => date.tariffId).join(',');
    history.push(`/directory/tariffs/${masterTariffId}/calculate?tariffIds=${tariffIds}`);
  };

  const getTariffId = (index: number) =>
    tariffDates.length > index ? tariffDates[index].tariffId : '';

  const showDiffLocation = {
    pathname: `/directory/tariffs/${masterTariffId}/diff`,
    search: `?tariffIdA=${getTariffId(0)}&tariffIdB=${getTariffId(1)}`,
  };

  return tariffDates?.length ? (
    <Container fluid className="p-2 bg-light position-fixed fixed-bottom">
      <Container>
        <Row>
          <Col xs={3}>
            <div>
              {tariffDates.length} date{tariffDates.length > 1 ? 's' : ''} selected
            </div>
            <div className="text-info">
              {maxSelectedDates && tariffDates.length + 1 === maxSelectedDates && 'Select 1 more'}
            </div>
          </Col>
          <Col xs={3}>With selected: </Col>
          <Col xs={3}>
            <Button onClick={onRunCalculation}>Run Calculation</Button>
          </Col>
          <Col xs={3}>
            <Link
              to={showDiffLocation}
              className={`btn btn-primary ${
                tariffDates.length != maxSelectedDates ? 'disabled' : ''
              }`}
            >
              Show Diff
            </Link>
          </Col>
        </Row>
      </Container>
    </Container>
  ) : null;
};

export default TariffActionFooter;
