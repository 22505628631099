import { types } from '@genability/api';
import { Button, IconButton } from '@arcadiapower/gen-react-lib';
import PropertyKeyPopover from '../PropertyKeyPopover/PropertyKeyPopover';
import React, { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectPropertyKey } from '../../state/propertyKeys/propertyKeysSlice';
import styles from './PropertyKeyInput.module.scss';
import { useController, UseControllerProps } from 'react-hook-form';
import DQContextMenu from '../DQOverride/DQContextMenu';

interface PartialPropertyKeyPickerProps {
  show: boolean;
  selected?: string;
  onSelected: (property: types.GenPropertyKey) => void;
  onClose: () => void;
}

interface PropertyKeyInputProps {
  label: string;
  tariffRatePropertyValue?: string | undefined;
  emptyValue?: string;
  required?: boolean;
  onSetSelectedKey?: (property: types.GenPropertyKey | undefined) => void;
  onChange: (property: types.GenPropertyKey | undefined) => void;
  onClosePicker?: (selectedProperty: types.GenPropertyKey | undefined) => void;
  clearable?: boolean;
  // TODO: Enforce the return value here somehow
  renderPicker: (props: PartialPropertyKeyPickerProps) => void;
  isInvalid?: boolean;
  feedback?: string;
  feedbackAs?: React.ElementType;
  errorProps?: {
    errorType: string;
    propertyName: string;
  };
}

export const PropertyKeyInput: React.FC<PropertyKeyInputProps> = ({
  label,
  tariffRatePropertyValue,
  emptyValue,
  onChange,
  onClosePicker = selectedProperty => selectedProperty,
  onSetSelectedKey,
  clearable = true,
  required = false,
  renderPicker,
  isInvalid = false,
  feedback,
  feedbackAs,
  errorProps,
}: PropertyKeyInputProps): React.ReactElement => {
  const defaultSelectedPropertyKey: types.GenPropertyKey | null = useSelector(
    selectPropertyKey(tariffRatePropertyValue)
  );
  let defaultSelectedProperty: types.GenPropertyKey | undefined;

  defaultSelectedPropertyKey ? (defaultSelectedProperty = defaultSelectedPropertyKey) : undefined;
  const [selectedKey, setSelectedKey] = useState<types.GenPropertyKey | undefined>(
    defaultSelectedProperty
  );
  const [showPicker, setShowPicker] = useState(required && !selectedKey ? true : false);

  useEffect(() => {
    onSetSelectedKey && onSetSelectedKey(defaultSelectedProperty);
  }, [selectedKey]);

  const openPicker = () => {
    setShowPicker(true);
  };

  const closePicker = (selectedProperty: types.GenPropertyKey | undefined) => {
    setShowPicker(false);
    onClosePicker(selectedProperty);
  };

  const onSelectedKey = (property: types.GenPropertyKey) => {
    setSelectedKey(property);
    setShowPicker(false);
    onChange(property);
  };

  const onRemoveKey = () => {
    setSelectedKey(undefined);
    setShowPicker(false);
    onChange(undefined);
  };

  const propertyName: string = tariffRatePropertyValue || emptyValue || 'None';

  return (
    <>
      <Form.Row className={isInvalid ? 'is-invalid' : ''}>
        <Col>
          <strong>
            <span>{label}</span>
          </strong>
          <br />
          <Button className={styles.propertyKeyLinkButton} variant="link" action={openPicker}>
            {propertyName}
          </Button>
          {propertyName != 'None' && selectedKey && (
            <>
              <PropertyKeyPopover propertyKey={selectedKey} />
              {clearable && <IconButton icon="close" size="sm" onClick={onRemoveKey} />}
            </>
          )}
        </Col>
      </Form.Row>

      {feedback && (
        <Form.Control.Feedback
          type={isInvalid ? 'invalid' : 'valid'}
          className={'form-control-feedback'}
          as={feedbackAs as any}
          {...errorProps}
        >
          {feedback}
        </Form.Control.Feedback>
      )}

      {propertyName != 'None' && selectedKey?.formulaDetail ? (
        <Form.Row>
          <Col>{selectedKey?.formulaDetail}</Col>
        </Form.Row>
      ) : null}

      {showPicker &&
        renderPicker({
          show: showPicker,
          selected: selectedKey && selectedKey.keyName,
          onSelected: (property: types.GenPropertyKey) => {
            onSelectedKey(property);
          },
          onClose: () => {
            closePicker(selectedKey);
          },
        })}
    </>
  );
};

// useController's type constraints prevent creating generic reusable components, so
// we need to override the type for 'control' here.
// Current official example: https://react-hook-form.com/api/usecontroller
// Related Github issue: https://github.com/react-hook-form/react-hook-form/issues/5055
type ControlledPropertyKeyInputProps = Omit<UseControllerProps, 'control'> &
  Omit<PropertyKeyInputProps, 'onChange'> & {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    control: any;
    onChange?: (property: types.GenPropertyKey | undefined) => void;
    errorProps?: {
      errorType: string;
      propertyName: string;
    };
  };

export const ControlledPropertyKeyInput: React.FC<ControlledPropertyKeyInputProps> = ({
  name: fieldName,
  control,
  onChange: parentOnChange,
  rules,
  errorProps,
  ...rest
}) => {
  const {
    field: { onChange, value },
    fieldState: { invalid, error },
  } = useController({
    name: fieldName,
    control,
    rules,
  });

  const handleOnChange = (property: types.GenPropertyKey | undefined) => {
    onChange(property ? property.keyName : '');
    parentOnChange && parentOnChange(property);
  };

  return (
    <PropertyKeyInput
      tariffRatePropertyValue={value}
      onChange={handleOnChange}
      isInvalid={invalid}
      feedback={error?.message}
      errorProps={errorProps}
      feedbackAs={errorProps?.errorType === 'DQFailure' ? DQContextMenu : undefined}
      {...rest}
    />
  );
};
