import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { restClient } from '@genability/api';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { TaskApiV2Client } from '../../GenApiClient';
import { addNotification } from '../notification/notificationSlice';
import { RootState } from '../rootReducer';
import { AppThunk } from '../store';
import { GetLookupValuesRequest } from '../../task-api/v2/api/task-api-v2';
import { LookupValueType } from '../../task-api/v2/types/LookupValueType';
import { LookupValueV2Transformed } from '../../task-api/v2/types/LookupValueV2Transformed';
import { LookupValueV2 } from '../../task-api/v2/types/LookupValueV2';
import { SortOrder } from '@genability/api/dist/rest-client';
import { applyTimeZoneOffSet } from '../../utils/dateUtils';

export interface GenLookupValuesByPropertyKeyState {
  actualLookupValues: LookupValueV2Transformed[];
  actualLookupCount: number;
  actualLookupLoading: boolean;
  actualLookupError: string | null;
  forecastedLookupValues: LookupValueV2Transformed[];
  forecastedLookupCount: number;
  forecastedLookupLoading: boolean;
  forecastedLookupError: string | null;
  actualLookupStartDate: string | undefined;
  actualLookupEndDate: string | undefined;
  foreCastedLookupStartDate: string | undefined;
  foreCastedLookupEndDate: string | undefined;
  defaultDatesLoading: boolean;
  defaultDatesError: string | null;
}

interface GenLookupValuesByPropertyKeySuccess {
  lookupValues: LookupValueV2Transformed[];
  count: number;
}

interface DefaultLookupDatesSuccess {
  actualLookupStartDate: string | undefined;
  actualLookupEndDate: string | undefined;
  foreCastedLookupStartDate: string | undefined;
  foreCastedLookupEndDate: string | undefined;
}

export const initialState: GenLookupValuesByPropertyKeyState = {
  actualLookupValues: [],
  actualLookupCount: 0,
  actualLookupLoading: false,
  actualLookupError: null,
  forecastedLookupValues: [],
  forecastedLookupCount: 0,
  forecastedLookupLoading: false,
  forecastedLookupError: null,
  actualLookupStartDate: undefined,
  actualLookupEndDate: undefined,
  foreCastedLookupStartDate: undefined,
  foreCastedLookupEndDate: undefined,
  defaultDatesLoading: false,
  defaultDatesError: null,
};

function actualLookupValuesStartLoading(state: GenLookupValuesByPropertyKeyState) {
  state.actualLookupLoading = true;
}

function actualLookupValuesLoadingFailed(
  state: GenLookupValuesByPropertyKeyState,
  action: PayloadAction<string>
) {
  state.actualLookupLoading = false;
  state.actualLookupError = action.payload;
}

function forecastedLookupValuesStartLoading(state: GenLookupValuesByPropertyKeyState) {
  state.forecastedLookupLoading = true;
}

function forecastedLookupValuesLoadingFailed(
  state: GenLookupValuesByPropertyKeyState,
  action: PayloadAction<string>
) {
  state.forecastedLookupLoading = false;
  state.forecastedLookupError = action.payload;
}

function defaultLookupDatesStartLoading(state: GenLookupValuesByPropertyKeyState) {
  state.defaultDatesLoading = true;
}

function defaultLookupDatesLoadingFailed(
  state: GenLookupValuesByPropertyKeyState,
  action: PayloadAction<string>
) {
  state.defaultDatesLoading = false;
  state.defaultDatesError = action.payload;
}

export const lookupValues = createSlice({
  name: 'lookupValuesByPropertyKey',
  initialState,
  reducers: {
    getActualLookupValuesStart: actualLookupValuesStartLoading,
    getActualLookupValuesSuccess(
      state,
      { payload }: PayloadAction<GenLookupValuesByPropertyKeySuccess>
    ) {
      const { lookupValues, count } = payload;
      state.actualLookupLoading = false;
      state.actualLookupError = null;
      state.actualLookupValues = lookupValues;
      state.actualLookupCount = count;
    },
    getActualLookupValuesFailure: actualLookupValuesLoadingFailed,
    getForecastedLookupValuesStart: forecastedLookupValuesStartLoading,
    getForecastedLookupValuesSuccess(
      state,
      { payload }: PayloadAction<GenLookupValuesByPropertyKeySuccess>
    ) {
      const { lookupValues, count } = payload;
      state.forecastedLookupLoading = false;
      state.forecastedLookupError = null;
      state.forecastedLookupValues = lookupValues;
      state.forecastedLookupCount = count;
    },
    getForecastedLookupValuesFailure: forecastedLookupValuesLoadingFailed,
    getDefaultLookupDatesStart: defaultLookupDatesStartLoading,
    getDefaultLookupDatesSuccess(state, { payload }: PayloadAction<DefaultLookupDatesSuccess>) {
      const {
        actualLookupStartDate,
        actualLookupEndDate,
        foreCastedLookupStartDate,
        foreCastedLookupEndDate,
      } = payload;
      state.defaultDatesLoading = false;
      state.defaultDatesError = null;
      state.actualLookupStartDate = actualLookupStartDate;
      state.actualLookupEndDate = actualLookupEndDate;
      state.foreCastedLookupStartDate = foreCastedLookupStartDate;
      state.foreCastedLookupEndDate = foreCastedLookupEndDate;
    },
    getDefaultLookupDatesFailure: defaultLookupDatesLoadingFailed,
  },
});

export const {
  getActualLookupValuesStart,
  getActualLookupValuesSuccess,
  getActualLookupValuesFailure,
  getForecastedLookupValuesStart,
  getForecastedLookupValuesSuccess,
  getForecastedLookupValuesFailure,
  getDefaultLookupDatesStart,
  getDefaultLookupDatesSuccess,
  getDefaultLookupDatesFailure,
} = lookupValues.actions;

export default lookupValues.reducer;

// It fetches the actual values for a particular property key
export const fetchActualLookupValues =
  (request: GetLookupValuesRequest, propertyKey: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(getActualLookupValuesStart());
      const client = await TaskApiV2Client();
      request.lookupValueType = LookupValueType.ACTUAL;
      const response: restClient.PagedResponse<LookupValueV2> = await client.taskV2.getLookupValues(
        propertyKey,
        request
      );
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
      const lookupValues: LookupValueV2Transformed[] = response.results.map(
        ({ propertyLookup, document }) => ({
          ...propertyLookup,
          document,
        })
      );
      const count: number = response.count;
      dispatch(getActualLookupValuesSuccess({ lookupValues, count }));
    } catch (err) {
      if (err) {
        const errorMessage = (err instanceof Error && err.message) || String(err);
        dispatch(getActualLookupValuesFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };

// It fetches the forecasted values for a particular property key
export const fetchForecastedLookupValues =
  (request: GetLookupValuesRequest, propertyKey: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(getForecastedLookupValuesStart());
      const client = await TaskApiV2Client();
      request.lookupValueType = LookupValueType.FORECASTED;
      const response: restClient.PagedResponse<LookupValueV2> = await client.taskV2.getLookupValues(
        propertyKey,
        request
      );
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
      const lookupValues: LookupValueV2Transformed[] = response.results.map(
        ({ propertyLookup, document }) => ({
          ...propertyLookup,
          document,
        })
      );
      const count: number = response.count;
      dispatch(getForecastedLookupValuesSuccess({ lookupValues, count }));
    } catch (err) {
      if (err) {
        const errorMessage = (err instanceof Error && err.message) || String(err);
        dispatch(getForecastedLookupValuesFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };

export const fetchDefaultLookupDates =
  (propertyKey: string, request: GetLookupValuesRequest): AppThunk =>
  async dispatch => {
    try {
      dispatch(getDefaultLookupDatesStart());
      const client = await TaskApiV2Client();
      request.sortOrder = [SortOrder.DESC];
      request.sortOn = ['fromDateTime'];
      request.lookupValueType = LookupValueType.ACTUAL;
      const lookupValuesDesc: restClient.PagedResponse<LookupValueV2> =
        await client.taskV2.getLookupValues(propertyKey, request);
      if (lookupValuesDesc.errors) {
        throw new Error(lookupValuesDesc.errors[0].message);
      }
      const startDate = new Date(lookupValuesDesc.results[0].propertyLookup.fromDateTime);
      const toDateTime = lookupValuesDesc.results[0].propertyLookup.toDateTime;
      const actualLookupEndDate = toDateTime ? new Date(toDateTime) : new Date(startDate);
      if (!toDateTime) {
        actualLookupEndDate.setSeconds(actualLookupEndDate.getSeconds() + 1);
      }
      const foreCastedLookupEndDate = new Date(actualLookupEndDate);
      foreCastedLookupEndDate.setFullYear(foreCastedLookupEndDate.getFullYear() + 2);

      dispatch(
        getDefaultLookupDatesSuccess({
          actualLookupStartDate: request.fromDateTime,
          actualLookupEndDate: applyTimeZoneOffSet(actualLookupEndDate)?.toISOString().slice(0, -5),
          foreCastedLookupStartDate: applyTimeZoneOffSet(actualLookupEndDate)
            ?.toISOString()
            .slice(0, -5),
          foreCastedLookupEndDate: applyTimeZoneOffSet(foreCastedLookupEndDate)
            ?.toISOString()
            .slice(0, -5),
        })
      );
    } catch (err) {
      if (err) {
        const errorMessage = (err instanceof Error && err.message) || String(err);
        dispatch(getDefaultLookupDatesFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };

export const selectLookupValues = (state: RootState): GenLookupValuesByPropertyKeyState => {
  return state.lookupValuesByPropertyKey;
};
