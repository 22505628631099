import { restClient } from '@genability/api';
import { OpsManagementApi } from './ops-api';

export class GenabilityOpsManagementApi {
  private static _instance: GenabilityOpsManagementApi;

  private _credentials: restClient.RestApiCredentials;
  private _baseUrl: string;
  private _opsData: OpsManagementApi | undefined;

  private constructor(baseUrl?: string, credentials?: restClient.RestApiCredentials) {
    this._baseUrl = baseUrl || 'https://api-dev.teamtariff.com';
    this._credentials = credentials || {};
  }

  public static configure(
    baseUrl?: string,
    credentials?: restClient.RestApiCredentials
  ): GenabilityOpsManagementApi {
    return this._instance || (this._instance = new this(baseUrl, credentials));
  }

  public get opsData(): OpsManagementApi {
    if (this._opsData === undefined) {
      this._opsData = new OpsManagementApi(
        new restClient.GenabilityConfig({
          proxy: this._baseUrl,
          credentials: this._credentials,
        })
      );
    }
    return this._opsData;
  }
}
