import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { restClient, types } from '@genability/api';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { TaskApiV2Client } from '../../GenApiClient';
import { addNotification } from '../notification/notificationSlice';
import { RootState } from '../rootReducer';
import { AppThunk } from '../store';
import { Task } from '../../task-api/v2/types/TaskV2';
import { GetTasksByPropertyKeyRequest } from '../../task-api/v2/api/task-api-v2';
import { getUnpublishedAnswers } from '../../utils/taskV2Utils';

export interface UnpublishedLookupTaskState {
  taskId: number | null;
  taskStatus: string | undefined;
  taskSource: string | null | undefined;
  workflowId: number | null;
  taskType: string | undefined;
  assignee: string | null;
  reviewer: string | null;
  unpublishedLookupValues: string | null;
  isLoading: boolean;
  error: string | null;
}

interface unpublishedLookupTaskSuccess {
  taskId: number | null;
  workflowId: number | null;
  taskStatus: string | undefined;
  taskType: string | undefined;
  taskSource: string | null | undefined;
  assignee: string | null;
  reviewer: string | null;
  unpublishedLookupValues: string | null;
}

export const initialState: UnpublishedLookupTaskState = {
  taskId: null,
  workflowId: null,
  taskType: '',
  taskSource: '',
  assignee: null,
  reviewer: null,
  unpublishedLookupValues: '',
  taskStatus: '',
  isLoading: false,
  error: '',
};

function unpublishedLookupTaskStartLoading(state: UnpublishedLookupTaskState) {
  state.isLoading = true;
}

function unpublishedLookupTaskLoadingFailed(
  state: UnpublishedLookupTaskState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

export const task = createSlice({
  name: 'unpublishedLookupTask',
  initialState,
  reducers: {
    unpublishedLookupTaskStart: unpublishedLookupTaskStartLoading,
    unpublishedLookupTaskSuccess(state, { payload }: PayloadAction<unpublishedLookupTaskSuccess>) {
      const {
        taskId,
        workflowId,
        unpublishedLookupValues,
        taskStatus,
        taskType,
        taskSource,
        assignee,
        reviewer,
      } = payload;
      state.isLoading = false;
      state.error = null;
      state.taskId = taskId;
      state.workflowId = workflowId;
      state.taskStatus = taskStatus;
      state.taskType = taskType;
      state.taskSource = taskSource;
      state.assignee = assignee;
      state.reviewer = reviewer;
      state.unpublishedLookupValues = unpublishedLookupValues;
    },
    unpublishedLookupTaskFailure: unpublishedLookupTaskLoadingFailed,
  },
});

export const {
  unpublishedLookupTaskStart,
  unpublishedLookupTaskSuccess,
  unpublishedLookupTaskFailure,
} = task.actions;

export default task.reducer;

export const fetchUnpublishedLookupTasks =
  (propertyKey: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(unpublishedLookupTaskStart());
      const client = await TaskApiV2Client();
      const request = new GetTasksByPropertyKeyRequest();
      request.unClosed = true;
      const { results, errors }: restClient.PagedResponse<Task> =
        await client.taskV2.getTasksForPropertyKey(propertyKey, request);

      if (errors) {
        throw new Error(errors[0].message);
      }

      const taskType = results[0] ? results[0].taskType : '';
      const taskId = results[0] ? results[0].taskId : null;
      const workflowId = results[0] ? results[0].workflowInstanceId : null;
      const unpublishedLookupValues = results[0] ? getUnpublishedAnswers(results[0]) : null;
      const taskStatus = results[0] ? results[0].taskStatus : '';
      const taskSource = results[0] ? results[0].taskSource : '';
      const assignee = results[0] ? results[0].assignee : null;
      const reviewer = results[0] ? results[0].reviewer : null;

      dispatch(
        unpublishedLookupTaskSuccess({
          taskId,
          workflowId,
          taskStatus,
          taskType,
          taskSource,
          assignee,
          reviewer,
          unpublishedLookupValues,
        })
      );
    } catch (err) {
      if (err) {
        const errorMessage = (err instanceof Error && err.message) || String(err);
        dispatch(unpublishedLookupTaskFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };

export const selectUnpublishedLookupTasks = (state: RootState): UnpublishedLookupTaskState => {
  return state.unpublishedLookupTasks;
};
