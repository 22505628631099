import React, { useEffect, useState } from 'react';
import Amplify from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignUp } from '@aws-amplify/ui-react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import awsconfig from './aws-exports';
import { useDispatch, useSelector } from 'react-redux';
import { addWorkerData, currentUserLoginSuccess } from './state/currentUser/currentUserSlice';
import {
  IPropertyPicker,
  PropertyKeyPickerContext,
  initialPropertyKeyPickerContext,
  onPropertySearchHandler,
} from './context/propertyKeyPickerContext';
import { AuthenticatedApp } from './AuthenticatedApp';
import {
  fetchWorkFlowConfig,
  selectedWorkFlowConfigDetails,
} from './state/workflowConfig/WorkFlowConfigSlice';
import { WorkflowConfigType } from './task-api/v2/types/WorkflowConfigType';
import { getReportingConfig } from './state/reportingConfig/reportingConfigSlice';

Amplify.configure(awsconfig);

const App: React.FC = (props: any) => {
  const [authState, setAuthState] = useState<AuthState | null>(null);
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const [user, setUser] = useState<any>(undefined);
  const [newPicker, setNewPicker] = useState<IPropertyPicker>(initialPropertyKeyPickerContext);
  const [picker, setPicker] = useState<IPropertyPicker>(initialPropertyKeyPickerContext);
  const dispatch = useDispatch();
  const { existingConfigDetails } = useSelector(selectedWorkFlowConfigDetails);

  const wfConfigs = [WorkflowConfigType.ADD_LOOKUP_AUTO, WorkflowConfigType.EDIT_LOOKUP_AUTO];

  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  // Adding API calls to fetch data for all the workflow configs and store it in the slice as a in memory cache which would be used in the dashboard and details screen
  useEffect(() => {
    if (user && user?.signInUserSession) {
      dispatch(currentUserLoginSuccess(user));
      wfConfigs.forEach(wfConfig => {
        if (!existingConfigDetails[wfConfig]) {
          dispatch(fetchWorkFlowConfig(wfConfig));
        }
      });
      dispatch(getReportingConfig({}));
      dispatch(addWorkerData(user.username));
    }
  }, [user?.username]);

  useEffect(() => {
    if (newPicker?.title !== picker?.title) {
      setPicker({ ...newPicker, saved: true });
    }
  }, [newPicker]);

  return authState === AuthState.SignedIn && user ? (
    <PropertyKeyPickerContext.Provider
      value={{
        picker,
        setPicker: setNewPicker,
        onPropertySearch: onPropertySearchHandler,
      }}
    >
      <Router {...props}>
        <AuthenticatedApp />
      </Router>
    </PropertyKeyPickerContext.Provider>
  ) : (
    <div className="App">
      <Helmet {...props}>
        <title>Team Tariff</title>
        <meta name="description" content="Team Tariff" />
      </Helmet>
      <header className="App-header">
        <AmplifyAuthenticator>
          <AmplifySignUp
            slot="sign-up"
            formFields={[
              { type: 'username' },
              { type: 'password' },
              { type: 'email' },
              {
                type: 'custom:genability_user_id',
                label: 'Genability Id',
                placeholder: 'genability_id',
              },
            ]}
          />
        </AmplifyAuthenticator>
      </header>
    </div>
  );
};

export default App;
