import React, { FC, useCallback } from 'react';
import { types } from '@genability/api';
import {
  AlphabetSelector,
  CountrySelect,
  MultipleChoiceInput,
  SearchFilter,
} from '@arcadiapower/gen-react-lib';
import { Col, Row } from 'react-bootstrap';
import { debounce } from 'lodash';
import { IUtilityFilter, useUtilityFilterContext } from '../../../context/utilityFilterContext';

const UtilitiesFilter: FC = () => {
  const { setFilter } = useUtilityFilterContext();

  const onFilterChange = (update: IUtilityFilter) => {
    setFilter(current => ({
      ...current,
      ...update,
      country: update.country || undefined,
    }));
  };

  const debounceSearch = useCallback(
    debounce((data: Partial<IUtilityFilter>) => {
      if (data) {
        onFilterChange(data);
      }
    }, 600),
    []
  );

  const getOptionsFromEnum = (data: Record<string, unknown>) => {
    const items = [{ name: 'All' }];
    for (const option in data) {
      items.push({ name: option });
    }
    return items;
  };

  return (
    <>
      <Row>
        <Col>
          <SearchFilter
            onChange={search => debounceSearch({ search })}
            placeholder="Search by utility name, short name. or ID"
          />
        </Col>
        <Col>
          <SearchFilter
            onChange={search => debounceSearch({ search })}
            label="ZIP CODE"
            placeholder="Search by utility ZIP"
          />
        </Col>
        <Col>
          <CountrySelect onChange={country => onFilterChange({ country })} />
        </Col>
      </Row>
      <Row data-testid="alphabet-selector-row">
        <AlphabetSelector onSelectAlphabet={search => onFilterChange({ search })} />
      </Row>
      <Row data-testid="multiple-choice-row">
        <Col>
          <MultipleChoiceInput
            label="Ownership"
            options={getOptionsFromEnum(types.Ownership)}
            onChange={ownerships => onFilterChange({ ownerships: ownerships as types.Ownership[] })}
          />
        </Col>
        <Col>
          <MultipleChoiceInput
            label="Services"
            options={getOptionsFromEnum(types.ServiceType)}
            onChange={services => onFilterChange({ serviceTypes: services as types.ServiceType[] })}
          />
        </Col>
        {/* TODO re-add them when the parameters are supported by the sdk
        <Col>
          <MultipleChoiceInput
            label="Residential Services"
            options={getOptionsFromEnum(types.ServiceType)}
            onChange={services =>
              onFilterChange({ residentialServiceTypes: services as types.ServiceType[] })
            }
          />
        </Col>
        <Col>
          <MultipleChoiceInput
            label="Commercial Services"
            options={getOptionsFromEnum(types.ServiceType)}
            onChange={services =>
              onFilterChange({ commercialServiceTypes: services as types.ServiceType[] })
            }
          />
        </Col>
        <Col>
          <MultipleChoiceInput
            label="Industrial Services"
            options={getOptionsFromEnum(types.ServiceType)}
            onChange={services =>
              onFilterChange({ transportationServiceTypes: services as types.ServiceType[] })
            }
          />
        </Col> */}
        <Col>
          <MultipleChoiceInput
            label="Transportation Services"
            options={getOptionsFromEnum(types.ServiceType)}
            onChange={services => onFilterChange({ serviceTypes: services as types.ServiceType[] })}
          />
        </Col>
      </Row>
    </>
  );
};

export default UtilitiesFilter;
