import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification, NotificationLevel } from '@arcadiapower/gen-react-lib';
import { RootState } from '../rootReducer';
import React from 'react';

const initialState: Notification[] = [];
let nextTodoId = 0;

export const NotificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification: {
      reducer(state, action: PayloadAction<Notification>) {
        state.push(action.payload);
      },
      prepare(message: React.ReactNode, level: NotificationLevel) {
        const notification: Notification = {
          id: '' + nextTodoId++,
          message,
          level,
          read: false,
        };
        return { payload: notification };
      },
    },
    toggleNotificationRead(state, action: PayloadAction<Notification>) {
      const notification = state.find(notification => notification.id === action.payload.id);
      if (notification) {
        notification.read = !notification.read;
      }
    },
  },
});

export const { addNotification, toggleNotificationRead } = NotificationSlice.actions;

export const selectUnreadNotifications = (): ((state: RootState) => Notification[]) => {
  return (state: RootState): Notification[] =>
    state.notifications.filter((notification: Notification) => !notification.read);
};

export default NotificationSlice.reducer;
