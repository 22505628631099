import React, { FC, useEffect, useState } from 'react';

import { Badge, Button, Card, Input, Modal, Text } from '@arcadiapower/shrike';
import { Tariff } from '../../task-api/v2/types/TariffDetails';

export interface MasterTariffDetailsComponentProps {
  tariffDetails: Tariff[];
  propertyKeyName?: string;
}

export const MasterTariffDetailsComponent: FC<MasterTariffDetailsComponentProps> = ({
  tariffDetails,
  propertyKeyName,
}) => {
  const [filteredMasterTariffID, setFilteredMasterTariffID] = useState<Tariff[]>([]);
  const [inputFiitleredTariffs, setInputFiitleredTariffs] = useState<Tariff[]>([]);
  const [showAllMasterTariff, setShowAllMasterTariff] = useState(false);
  const [filterSearchText, setFilterSearchText] = useState('');

  useEffect(() => {
    if (tariffDetails?.length > 20) {
      setFilteredMasterTariffID(tariffDetails.slice(0, 20));
    } else {
      setFilteredMasterTariffID(tariffDetails);
    }
    setInputFiitleredTariffs(tariffDetails);
  }, [tariffDetails]);

  const filterMasterTariffBssedOnUserInput = (inputValue: string) => {
    const filteredTariffs = tariffDetails.filter(
      tariff =>
        tariff.tariffId.toString().toLowerCase().includes(inputValue.toLowerCase()) || // Filter based on tariffId
        tariff.tariffName?.toLowerCase().includes(inputValue.toLowerCase()) // Filter based on tariffName
    );

    setInputFiitleredTariffs(filteredTariffs);
    setFilterSearchText(inputValue);
  };
  const closeModal = () => {
    setShowAllMasterTariff(false);
  };
  const openModal = () => {
    setShowAllMasterTariff(true);
  };
  return (
    <>
      <Card className="mb-3">
        <Card.Content className="p-3">
          <>
            {filteredMasterTariffID.length === 0 ? (
              <Text>No linked master tariff id for the property key {propertyKeyName}.</Text>
            ) : (
              filteredMasterTariffID.map(element => (
                <React.Fragment key={element.tariffId}>
                  <Badge backgroundless color="primary" margin="5px" size="medium">
                    {element.tariffId}
                  </Badge>
                </React.Fragment>
              ))
            )}

            <br />
            {tariffDetails?.length > 20 && (
              <Button
                backgroundColor="accent2"
                size="small"
                margin={{
                  top: '16px',
                }}
                onClick={openModal}
              >
                Show All Master Tariff
              </Button>
            )}
          </>
        </Card.Content>
      </Card>
      {showAllMasterTariff && (
        <Modal aria-label="My modal" onDismiss={closeModal} size="large" className="p-3">
          <Modal.Header
            onClose={closeModal}
            title={`Master tariff assoicated with ${propertyKeyName}`}
          />
          <Modal.Content>
            <Input
              onChange={value => filterMasterTariffBssedOnUserInput(value)}
              errorText=""
              helperText=""
              id="address"
              label="Filter based on Tariff ID or Tariff Name"
              name="address"
              value={filterSearchText}
              className="mb-3"
            />
            <Text color="primary" opacity="high" tag="h1" textStyle="heading500" className="mb-3">
              Master Tariff Ids:
            </Text>
            {inputFiitleredTariffs.length === 0 ? (
              <Text
                color="primary"
                opacity="high"
                tag="h1"
                textStyle="paragraph500"
                className="mb-3"
              >
                No tariff found for the given filter.
              </Text>
            ) : (
              inputFiitleredTariffs.map(element => (
                <React.Fragment key={element.tariffId}>
                  <Badge backgroundless color="primary" margin="5px" size="medium">
                    {element.tariffId}
                  </Badge>
                </React.Fragment>
              ))
            )}
          </Modal.Content>
          <Modal.Footer
            onSubmit={closeModal}
            primaryBackgroundColor="primaryInverse"
            primaryText="Close Popup"
          />
        </Modal>
      )}
    </>
  );
};
