import { restClient } from '@genability/api';
import { IWorker } from '../types/worker';

export class WorkerApi extends restClient.RestApiClient {
  public async getWorker(username: string): Promise<restClient.SingleResponse<IWorker>> {
    return this.getSingle(`/v1/workers/${username}`);
  }

  public async getWorkers(
    request?: restClient.DefaultPagedRequest
  ): Promise<restClient.PagedResponse<IWorker>> {
    return this.getPaged(`/v1/workers`, { params: request });
  }
}
