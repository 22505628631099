import { Checkbox, Button, IconButton, Text, Icon } from '@arcadiapower/shrike';
import { Loader, NotificationLevel, Pagination, Th } from '@arcadiapower/gen-react-lib';
import React, { ReactElement, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IWorker } from '../../../task-api/types/worker';
import WorkerInput from '../../WorkerInput/WorkerInput';
import { QualificationType } from '../../../task-api/v2/types/QualifictionType';
import { Row, Table } from 'react-bootstrap';
import BulkAssignConfig from '../BulkAssignConfig';
import DropdownSelect from '../../DropdownSelect/DropdownSelect';
import { FrequencyType } from '../../../ops-api/types/FrequencyType';
import { SortOrder } from '@genability/api/dist/rest-client';
import TextField from '../../TextField/TextField';
import { Link } from 'react-router-dom';
import { OpsManagementApiClient } from '../../../GenApiClient';
import { addNotification } from '../../../state/notification/notificationSlice';
import { doQuickSearch } from '../../../state/QuickSearch/QuickSearchSlice';
import QuickSearch from '../../QuickSearchComponent/QuickSearch';
import { OpsFilterProps, OpsGlobalFilter } from '../../../pages/OpsManagement/OpsManagementTabs';
import {
  selectLookupConfigData,
  LookupConfigDataProps,
  fetchLookupConfigData,
  startLookupLoading,
  updateLookupConfigData,
} from '../../../state/OpsManagement/Config/LookupConfigSlice';
import { SourceType } from '../../../ops-api/types/LookupSources';
import { fetchLookupConfigStats } from '../../../state/OpsManagement/Config/LookupConfigStatsSlice';
import { OpsManagementLocalFilterProps } from '../Status/LookupStatusTable';

export interface LookupDataRequestParams {
  showUnassignedLookups: boolean;
}
export interface LookupConfigData {
  userMappingId: number | undefined;
  monitoringInventoryId: number;
  primaryOwnerId: number | undefined;
  modellerId: number | undefined;
  reviewerId: number | undefined;
  propertyKey: string;
  lseId: number;
  lseName: string;
  frequency: FrequencyType | undefined;
  primaryOwnerUserName: string | undefined;
  modellerUserName: string | undefined;
  reviewerName: string | undefined;
  source: SourceType | undefined;
  utilityWebsite: string;
}

const LookupConfigTable = ({
  filter,
  setFilter,
  currentUserIsSME,
  userListLoading,
}: OpsFilterProps): ReactElement => {
  const dispatch = useDispatch();
  const {
    results: lookupValues,
    updatedEntityResults,
    fetchApiStatus,
    updateApiStatus,
    count,
  } = useSelector(selectLookupConfigData);
  const [editedRow, setEditedRow] = useState<LookupConfigData | undefined>(undefined);
  const [configEditMode, setConfigEditMode] = useState(0);
  const [params, setParams] = useState<LookupDataRequestParams>({
    showUnassignedLookups: false,
  });
  const [sort, setSort] = useState({ sortOn: 'propertykey', sortOrder: 'ASC' });
  const [page, setPage] = useState(1);
  const pageCount = 50;
  const totalPageCount = Math.ceil(count / pageCount);
  const [frequencyUpdated, setFrequencyUpdated] = useState<boolean>(false);
  const [sourceUpdated, setSourceUpdated] = useState<boolean>(false);
  const [exportLoad, setExportLoad] = useState<boolean>(false);
  const [lookupSelectedCount, setLookupSelectedCount] = useState(0);
  const [lookupSelectAll, setLookupSelectAll] = useState<boolean>(false);
  const [localFilter, setLocalFilter] = useState<Partial<OpsManagementLocalFilterProps>>({});
  const [lookupSelected, setLookupSelected] = useState<boolean[]>(
    Array(lookupValues ? lookupValues.length : 0).fill(false)
  );

  const lookupLevelHeaders = [
    {
      key: 'selectall',
      label: 'Select All',
      search: null,
    },
    {
      key: 'propertyKey',
      label: 'Property Key',
      search: (
        <QuickSearch
          popoverStyle={{ zIndex: 10 }}
          value={localFilter.propertyKey || filter.propertyKey || ''}
          onClick={() => {
            dispatch(
              doQuickSearch({ minChar: 3, key: 'propertyKey', value: localFilter.propertyKey })
            );
          }}
          onChange={value => {
            if (filter.propertyKey && value == '') {
              setFilter(prev => {
                return {
                  ...prev,
                  propertyKey: undefined,
                };
              });
            }
            dispatch(doQuickSearch({ minChar: 3, key: 'propertyKey', value: value }));
            setLocalFilter({
              propertyKey: value != '' ? value : undefined,
            });
          }}
          onSelect={value => {
            setLocalFilter({
              propertyKey: value,
            });
            setPage(1);
            setFilter(prev => {
              return {
                ...prev,
                propertyKey: value,
              };
            });
          }}
          placeholder="Search"
        />
      ),
    },
    {
      key: 'lseId',
      label: 'Lse Id',
      search: (
        <QuickSearch
          popoverStyle={{ zIndex: 10 }}
          value={localFilter.lseId || filter.lseId || ''}
          onClick={() => {
            dispatch(doQuickSearch({ minChar: 1, key: 'lseId', value: localFilter.lseId }));
          }}
          onChange={value => {
            if (filter.lseId && value == '') {
              setFilter(prev => {
                return {
                  ...prev,
                  lseId: undefined,
                };
              });
            }
            if (isNaN(Number(value))) {
              return;
            }
            dispatch(
              doQuickSearch({ minChar: 1, key: 'lseId', value: value != '' ? value : undefined })
            );
            setLocalFilter({
              lseId: value != '' ? value : undefined,
            });
          }}
          onSelect={value => {
            setLocalFilter({
              lseId: value,
            });
            setPage(1);
            setFilter(prev => {
              return {
                ...prev,
                lseId: Number(value),
              };
            });
          }}
          placeholder="Search"
        />
      ),
    },
    {
      key: 'lseName',
      label: 'Lse Name',
      search: (
        <QuickSearch
          popoverStyle={{ zIndex: 10 }}
          value={localFilter.lseName || filter.lseName || ''}
          onClick={() => {
            dispatch(doQuickSearch({ minChar: 3, key: 'lseName', value: localFilter.lseName }));
          }}
          onChange={value => {
            if (filter.lseName && value == '') {
              setFilter(prev => {
                return {
                  ...prev,
                  lseName: undefined,
                };
              });
            }
            dispatch(
              doQuickSearch({ minChar: 3, key: 'lseName', value: value != '' ? value : undefined })
            );
            setLocalFilter({
              lseName: value != '' ? value : undefined,
            });
          }}
          onSelect={value => {
            setLocalFilter({
              lseName: value,
            });
            setPage(1);
            setFilter(prev => {
              return {
                ...prev,
                lseName: value,
              };
            });
          }}
          placeholder="Search"
        />
      ),
    },
    {
      key: 'ownerUsername',
      label: 'Owner',
      search: (
        <WorkerInput
          popoverStyle={{ zIndex: 10 }}
          label={''}
          placeholder="Search"
          id=""
          qualification={QualificationType.LOOKUP_MODELLER}
          selected={filter.owner ? filter.owner : ({ userName: '' } as IWorker)}
          setSelected={(assignee: IWorker | undefined) => {
            if (!assignee && !filter.owner) {
              return;
            }
            setPage(1);
            setFilter(prev => {
              return {
                ...prev,
                owner: assignee,
              };
            });
          }}
        />
      ),
    },
    {
      key: 'modellerUsername',
      label: 'Modeller',
      search: (
        <WorkerInput
          popoverStyle={{ zIndex: 10 }}
          label={''}
          placeholder="Search"
          id=""
          qualification={QualificationType.LOOKUP_MODELLER}
          selected={filter.modeller ? filter.modeller : ({ userName: '' } as IWorker)}
          setSelected={(assignee: IWorker | undefined) => {
            if (!assignee && !filter.modeller) {
              return;
            }
            setPage(1);
            setFilter(prev => {
              return {
                ...prev,
                modeller: assignee,
              };
            });
          }}
        />
      ),
    },
    {
      key: 'reviewerUsername',
      label: 'Reviewer',
      search: (
        <WorkerInput
          popoverStyle={{ zIndex: 10 }}
          label={''}
          placeholder="Search"
          id=""
          qualification={QualificationType.LOOKUP_REVIEWER}
          selected={filter.reviewer ? filter.reviewer : ({ userName: '' } as IWorker)}
          setSelected={(assignee: IWorker | undefined) => {
            if (!assignee && !filter.reviewer) {
              return;
            }
            setPage(1);
            setFilter(prev => {
              return {
                ...prev,
                reviewer: assignee,
              };
            });
          }}
        />
      ),
    },
    {
      key: 'frequency',
      label: 'Frequency',
      search: (
        <DropdownSelect
          popoverStyle={{ zIndex: 10 }}
          selected={localFilter.frequency || filter.frequency || ''}
          onChange={value => {
            if (filter.frequency && value == '') {
              setFilter(prev => {
                return {
                  ...prev,
                  frequency: undefined,
                };
              });
            }
            setLocalFilter({ frequency: value });
          }}
          setSelected={value => {
            setLocalFilter({ frequency: value });
            setPage(1);
            setFilter(prev => {
              return { ...prev, frequency: value as FrequencyType };
            });
          }}
          options={Object.keys(FrequencyType).filter(
            key =>
              !localFilter.frequency?.length || key.includes(localFilter.frequency.toUpperCase())
          )}
          label=""
        />
      ),
    },
    {
      key: 'source',
      label: 'Source',
      search: (
        <DropdownSelect
          popoverStyle={{ zIndex: 10 }}
          selected={localFilter.source || filter.source || ''}
          onChange={value => {
            if (filter.source && value == '') {
              setFilter(prev => {
                return {
                  ...prev,
                  source: undefined,
                };
              });
            }
            setLocalFilter({ source: value });
          }}
          setSelected={value => {
            setLocalFilter({ source: value });
            setPage(1);
            setFilter(prev => {
              return { ...prev, source: value as SourceType };
            });
          }}
          options={Object.keys(SourceType).filter(
            key => !localFilter.source?.length || key.includes(localFilter.source.toUpperCase())
          )}
          label=""
        />
      ),
    },
    {
      key: 'edit',
      label: 'Edit',
      search: null,
    },
  ];

  const lookupCheckboxSelection = useCallback(
    (id: number) => {
      if (lookupSelected[id]) setLookupSelectedCount(prev => prev - 1);
      else setLookupSelectedCount(prev => prev + 1);
      setLookupSelected(prev => {
        const current = prev.slice();
        current[id] = !current[id];
        return current;
      });
    },
    [lookupSelected]
  );

  const handleSort = useCallback(
    (key: string) => {
      if (sort.sortOn != key || sort.sortOrder === 'DESC') {
        setSort(prev => {
          return { sortOn: key, sortOrder: 'ASC' };
        });
      } else {
        setSort(prev => {
          return { ...prev, sortOrder: 'DESC' };
        });
      }
    },
    [sort]
  );

  const lookupSelectAllSelection = useCallback(() => {
    if (!lookupValues) return;
    setLookupSelectAll(prev => {
      if (prev) setLookupSelectedCount(0);
      else setLookupSelectedCount(lookupValues.length);
      setLookupSelected(Array(lookupValues ? lookupValues.length : 0).fill(!prev));
      return !prev;
    });
  }, [lookupSelectAll, lookupValues]);

  const populateFilters = useCallback((): LookupConfigDataProps => {
    const request: LookupConfigDataProps = {
      paginationCriteria: { pageCount, pageStart: (page - 1) * pageCount },
      sortCriteria: { sortOn: [sort.sortOn], sortOrder: [sort.sortOrder] },
      lookupUserConfigFilterCriteria: {},
    };
    if (filter.modeller) {
      request.lookupUserConfigFilterCriteria.modellerId = [filter.modeller.workerId];
    }
    if (filter.owner) {
      request.lookupUserConfigFilterCriteria.primaryOwnerId = [filter.owner.workerId];
    }
    if (filter.reviewer) {
      request.lookupUserConfigFilterCriteria.reviewerId = [filter.reviewer.workerId];
    }
    if (filter.frequency) {
      request.lookupUserConfigFilterCriteria.frequency = [filter.frequency];
    }
    if (filter.source) {
      request.lookupUserConfigFilterCriteria.source = [filter.source];
    }
    if (filter.propertyKey) {
      request.lookupUserConfigFilterCriteria.propertyKey = [filter.propertyKey];
    }
    if (filter.lseName) {
      request.lookupUserConfigFilterCriteria.lseNames = [filter.lseName];
    }
    if (filter.lseId) {
      request.lookupUserConfigFilterCriteria.lseId = [filter.lseId];
    }
    return request;
  }, [filter, sort, page, pageCount]);
  const exportFunction = async () => {
    setExportLoad(true);
    const request: LookupConfigDataProps = populateFilters();
    request.paginationCriteria = undefined;
    const client = await OpsManagementApiClient();
    const response = await client.opsData.exportLookupConfigData(request, params);
    if (response.errors) {
      dispatch(addNotification('Export Failed !', NotificationLevel.Error));
    } else {
      dispatch(addNotification('Exported Successfully !!!!', NotificationLevel.Success));
    }
    setExportLoad(false);
  };

  useEffect(() => {
    setSourceUpdated(false);
    setFrequencyUpdated(false);
    setEditedRow(undefined);
    setLookupSelectedCount(0);
    setLookupSelected(Array(lookupValues ? lookupValues.length : 0).fill(false));
    setLookupSelectAll(false);
    setConfigEditMode(0);
  }, [lookupValues]);

  useEffect(() => {
    dispatch(fetchLookupConfigStats({}));
  }, [updatedEntityResults]);

  useEffect(() => {
    const request: LookupConfigDataProps = populateFilters();
    dispatch(fetchLookupConfigData({ request, params }));
    setLookupSelectedCount(0);
  }, [page, sort, updatedEntityResults, filter, params]);

  return (
    <Row key={'lookup'} id="lookup" className="d-flex justify-content-between">
      <span className="w-100 d-flex align-items-center" style={{ height: '50px' }}>
        <h3>Property Lookup Assignment Configuration</h3>
        {lookupSelectedCount > 1 && (
          <BulkAssignConfig
            params={params}
            populateFilters={populateFilters}
            sortedItems={lookupValues}
            selected={lookupSelected}
            type="lookup"
            currentUserIsSME={currentUserIsSME}
            loading={userListLoading}
          />
        )}
      </span>
      <span className="d-flex justify-content-between w-100 align-items-center">
        <span>
          <Checkbox
            checked={params.showUnassignedLookups}
            className="ml-1"
            onChange={() => {
              setPage(1);
              setParams(prev => {
                return { ...prev, showUnassignedLookups: !prev.showUnassignedLookups };
              });
            }}
            disabled={!currentUserIsSME || fetchApiStatus === 'pending'}
          >
            Show Unassigned Lookups Only
          </Checkbox>
        </span>
        <span className="d-flex justify-content-between align-items-center">
          <Text color="primary" opacity="high" tag="p" textStyle="paragraph400" className="mr-2">
            Showing {(page - 1) * pageCount + 1} - {Math.min(page * pageCount, count)} of
            {` ${count}`} entries
          </Text>
          <span className="pt-3">
            <Pagination
              activePage={page}
              totalPages={totalPageCount}
              onSelectPage={selectedPage => setPage(selectedPage)}
            />
          </span>
          <Button
            size="medium"
            className="ml-3"
            onClick={exportFunction}
            style={{ minWidth: '110px', height: '45px' }}
            loading={exportLoad}
          >
            <Icon icon="UIDownload" color="accent1" className="mr-1" />
            Export
          </Button>
        </span>
      </span>
      <span
        onClick={() => {
          setFilter({} as OpsGlobalFilter);
          setLocalFilter({});
          setParams(prev => {
            return { showUnassignedLookups: false };
          });
        }}
        className="ml-2"
        style={{ textDecoration: 'underLine', color: '#009933', cursor: 'pointer' }}
      >
        Clear all filters
      </span>
      <Table
        striped
        className="text-center mw-100 mt-4 mr-1"
        style={{ wordBreak: 'break-word' }}
        key="lookupConfig"
      >
        <thead>
          <tr>
            {lookupLevelHeaders.map(({ key, label, search }) => (
              <Th
                key={key}
                sortKey={key != 'edit' && key != 'selectall' ? key : ''}
                sortConfig={{ sortOn: [sort.sortOn], sortOrder: [sort.sortOrder as SortOrder] }}
                className="align-middle"
                style={
                  key == 'edit' && currentUserIsSME
                    ? { minWidth: '65px' }
                    : key == 'propertyKey'
                    ? { minWidth: '200px' }
                    : {}
                }
                onClick={() => (key != 'edit' && key != 'selectall' ? handleSort(key) : {})}
              >
                {key == 'selectall' ? (
                  <span className="d-flex">
                    <Checkbox
                      checked={lookupSelectAll || false}
                      className="ml-2"
                      disabled={
                        fetchApiStatus === 'pending' ||
                        updateApiStatus === 'pending' ||
                        userListLoading
                      }
                      onChange={lookupSelectAllSelection}
                    />
                  </span>
                ) : key == 'edit' && !currentUserIsSME ? (
                  ''
                ) : (
                  <span className="h5">{label}</span>
                )}
              </Th>
            ))}
          </tr>
        </thead>
        {fetchApiStatus === 'pending' || updateApiStatus === 'pending' ? (
          <tbody>
            <tr>
              {lookupLevelHeaders.map(({ key, search }) => (
                <td key={key} className="align-middle">
                  {search}
                </td>
              ))}
            </tr>
            <td colSpan={10} className="p-5">
              <Loader />
            </td>
          </tbody>
        ) : (
          <tbody>
            <tr>
              {lookupLevelHeaders.map(({ key, search }) => (
                <td key={key} className="align-middle">
                  {search}
                </td>
              ))}
            </tr>
            {lookupValues?.map((lookupRow: LookupConfigData, index) => (
              <tr key={lookupRow.propertyKey}>
                <td className="d-flex align-items-center justify-content-around">
                  <Checkbox
                    checked={lookupSelected[index]}
                    onChange={() => lookupCheckboxSelection(index)}
                  />
                </td>
                <td style={{ minWidth: '17vw' }}>
                  <Link
                    target="_blank"
                    to={{ pathname: `directory/property-keys/${lookupRow?.propertyKey}` }}
                  >
                    <TextField
                      textValue={lookupRow.propertyKey}
                      toolTipId={lookupRow.monitoringInventoryId + lookupRow.propertyKey}
                      maxSize={23}
                      textStyle="paragraph500"
                    />
                  </Link>
                </td>
                <td style={{ width: '8vw' }}>
                  <Link
                    target="_blank"
                    to={{ pathname: `${process.env.REACT_APP_MOTHER_URL}/lses/${lookupRow.lseId}` }}
                  >
                    <Text>{lookupRow.lseId || ''}</Text>
                  </Link>
                </td>
                <td style={{ minWidth: '13vw' }}>
                  <a
                    href={lookupRow.utilityWebsite || '#'}
                    target="_blank"
                    rel="noreferrer"
                    id="lookupUtility"
                  >
                    <TextField
                      textValue={lookupRow.lseName}
                      toolTipId={
                        lookupRow.monitoringInventoryId + (lookupRow.lseName?.toString() || '')
                      }
                      maxSize={15}
                      textStyle="paragraph500"
                    />
                  </a>
                </td>
                <td style={{ width: '11vw' }} key="primaryOwner">
                  {configEditMode != lookupRow.monitoringInventoryId ? (
                    <Link
                      target="_blank"
                      to={{
                        pathname: `${process.env.REACT_APP_MOTHER_URL}/admin/users/${lookupRow.primaryOwnerId}`,
                      }}
                    >
                      <TextField
                        textValue={lookupRow.primaryOwnerUserName?.split('@')[0] || ''}
                        toolTipId={lookupRow.monitoringInventoryId + lookupRow.propertyKey + 'PO'}
                        maxSize={11}
                        textStyle="paragraph500"
                      />
                    </Link>
                  ) : (
                    <WorkerInput
                      popoverStyle={{ zIndex: 10 }}
                      label={''}
                      placeholder="Search assignee"
                      id={'' + lookupRow.propertyKey}
                      selected={
                        {
                          userName: editedRow
                            ? editedRow.primaryOwnerUserName
                            : lookupRow.primaryOwnerUserName,
                        } as IWorker
                      }
                      qualification={QualificationType.LOOKUP_REVIEWER}
                      setSelected={(assignee: IWorker | undefined) => {
                        if (assignee?.userName === lookupRow.primaryOwnerUserName) {
                          return;
                        }
                        setEditedRow(prev => {
                          return {
                            ...prev,
                            primaryOwnerId: assignee?.workerId,
                            primaryOwnerUserName: assignee?.userName,
                          } as LookupConfigData;
                        });
                      }}
                    />
                  )}
                </td>
                <td style={{ width: '11vw' }} key="modeller">
                  {configEditMode != lookupRow.monitoringInventoryId ? (
                    <Link
                      target="_blank"
                      to={{
                        pathname: `${process.env.REACT_APP_MOTHER_URL}/admin/users/${lookupRow.modellerId}`,
                      }}
                    >
                      <TextField
                        textValue={lookupRow.modellerUserName?.split('@')[0] || ''}
                        toolTipId={lookupRow.monitoringInventoryId + lookupRow.propertyKey + 'MO'}
                        maxSize={11}
                        textStyle="paragraph500"
                      />
                    </Link>
                  ) : (
                    <WorkerInput
                      popoverStyle={{ zIndex: 10 }}
                      label={''}
                      placeholder="Search assignee"
                      id={'' + lookupRow.propertyKey}
                      qualification={QualificationType.LOOKUP_MODELLER}
                      selected={
                        {
                          userName: editedRow
                            ? editedRow.modellerUserName
                            : lookupRow.modellerUserName,
                        } as IWorker
                      }
                      setSelected={(assignee: IWorker | undefined) => {
                        if (assignee?.userName === lookupRow.modellerUserName) {
                          return;
                        }
                        setEditedRow(prev => {
                          return {
                            ...prev,
                            modellerId: assignee?.workerId,
                            modellerUserName: assignee?.userName,
                          } as LookupConfigData;
                        });
                      }}
                    />
                  )}
                </td>
                <td style={{ width: '11vw' }} key="reviewer">
                  {configEditMode != lookupRow.monitoringInventoryId ? (
                    <Link
                      target="_blank"
                      to={{
                        pathname: `${process.env.REACT_APP_MOTHER_URL}/admin/users/${lookupRow.reviewerId}`,
                      }}
                    >
                      <TextField
                        textValue={lookupRow.reviewerName?.split('@')[0] || ''}
                        toolTipId={lookupRow.monitoringInventoryId + lookupRow.propertyKey + 'RE'}
                        maxSize={11}
                        textStyle="paragraph500"
                      />
                    </Link>
                  ) : (
                    <WorkerInput
                      popoverStyle={{ zIndex: 10 }}
                      label={''}
                      placeholder="Search assignee"
                      id={'' + lookupRow.propertyKey}
                      qualification={QualificationType.LOOKUP_REVIEWER}
                      selected={
                        {
                          userName: editedRow ? editedRow.reviewerName : lookupRow.reviewerName,
                        } as IWorker
                      }
                      setSelected={(assignee: IWorker | undefined) => {
                        if (assignee?.userName === lookupRow.reviewerName) {
                          return;
                        }
                        setEditedRow(prev => {
                          return {
                            ...prev,
                            reviewerId: assignee?.workerId,
                            reviewerName: assignee?.userName,
                          } as LookupConfigData;
                        });
                      }}
                    />
                  )}
                </td>
                <td style={{ minWidth: '11vw' }}>
                  {configEditMode != lookupRow.monitoringInventoryId ? (
                    lookupRow.frequency
                  ) : (
                    <DropdownSelect
                      popoverStyle={{ zIndex: 10 }}
                      selected={(editedRow ? editedRow.frequency : lookupRow.frequency) as string}
                      onChange={value => {
                        setFrequencyUpdated(true);
                        setEditedRow(prev => {
                          return {
                            ...prev,
                            frequency: value,
                          } as LookupConfigData;
                        });
                      }}
                      setSelected={value => {
                        setEditedRow(prev => {
                          return {
                            ...prev,
                            frequency: value,
                          } as LookupConfigData;
                        });
                      }}
                      options={Object.keys(FrequencyType).filter(
                        key =>
                          !editedRow?.frequency || key.includes(editedRow.frequency.toUpperCase())
                      )}
                      label=""
                    />
                  )}
                </td>
                <td style={{ minWidth: '10vw' }}>
                  {configEditMode != lookupRow.monitoringInventoryId ? (
                    lookupRow.source
                  ) : (
                    <DropdownSelect
                      popoverStyle={{ zIndex: 10 }}
                      selected={(editedRow ? editedRow.source : lookupRow.source) as string}
                      onChange={value => {
                        setSourceUpdated(true);
                        setEditedRow(prev => {
                          return {
                            ...prev,
                            source: value,
                          } as LookupConfigData;
                        });
                      }}
                      setSelected={value => {
                        setEditedRow(prev => {
                          return {
                            ...prev,
                            source: value,
                          } as LookupConfigData;
                        });
                      }}
                      options={Object.keys(SourceType).filter(
                        key => !editedRow?.source || key.includes(editedRow.source.toUpperCase())
                      )}
                      label=""
                    />
                  )}
                </td>
                <td className="align-middle">
                  {currentUserIsSME && (
                    <>
                      {configEditMode != lookupRow.monitoringInventoryId ? (
                        <IconButton
                          aria-label="edit"
                          icon="Pencil"
                          onClick={() => {
                            setConfigEditMode(lookupRow.monitoringInventoryId);
                            setEditedRow(lookupRow);
                          }}
                        />
                      ) : (
                        <>
                          <IconButton
                            aria-label="save"
                            icon="Checkmark"
                            onClick={() => {
                              if (!editedRow) {
                                return;
                              }
                              dispatch(startLookupLoading());
                              dispatch(
                                updateLookupConfigData({
                                  postLookupUserConfigRequest: [
                                    {
                                      monitoringInventoryId: editedRow.monitoringInventoryId || 0,
                                      propertykey: editedRow.propertyKey || '',
                                      userAssignmentConfig: {
                                        userMappingId: editedRow.userMappingId,
                                        monitoringInventoryId: editedRow.monitoringInventoryId || 0,
                                        primaryOwnerId: editedRow.primaryOwnerId,
                                        modellerId: editedRow.modellerId,
                                        reviewerId: editedRow.reviewerId,
                                      },
                                      frequency:
                                        frequencyUpdated &&
                                        Object.keys(FrequencyType).includes(
                                          editedRow.frequency?.toUpperCase() || ''
                                        )
                                          ? (editedRow.frequency?.toUpperCase() as FrequencyType)
                                          : undefined,
                                      source:
                                        sourceUpdated &&
                                        Object.keys(SourceType).includes(
                                          editedRow.source?.toUpperCase() || ''
                                        )
                                          ? (editedRow.source?.toUpperCase() as SourceType)
                                          : undefined,
                                    },
                                  ],
                                  selectAllMatchingRecords: false,
                                })
                              );
                            }}
                          />
                          <IconButton
                            aria-label="cancel"
                            className="mt-1"
                            icon="UIClose"
                            onClick={() => {
                              setConfigEditMode(0);
                              setEditedRow(undefined);
                            }}
                          />
                        </>
                      )}
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
      <Row className="d-flex justify-content-center w-100 mt-1">
        <span className="d-flex justify-content-center w-100 mt-1">
          <Text
            color="primary"
            opacity="high"
            tag="p"
            textStyle="paragraph400"
            className="mt-2 mr-2"
          >
            Showing {(page - 1) * pageCount + 1} - {Math.min(page * pageCount, count)} of
            {` ${count}`} entries
          </Text>
          <Pagination
            activePage={page}
            totalPages={totalPageCount}
            onSelectPage={selectedPage => setPage(selectedPage)}
          />
        </span>
      </Row>
    </Row>
  );
};

export default LookupConfigTable;
