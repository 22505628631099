import React from 'react';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TaskAssignment,
  TaskAssignmentAnswer,
  TaskAssignmentAnswerField,
  TaskComment,
  TaskInput,
} from '../../task-api/types/task';
import { TaskCommentBody } from '../../task-api/api/task-api';
import { restClient } from '@genability/api';
import { TaskApiClient } from '../../GenApiClient';
import { FetchSingleApiResponse } from '../../utils/apiResponseTypes';
import { RootState } from '../rootReducer';
import { handleUnexpectedThunkException } from '../reduxUtils';
import {
  DQOverrideReason,
  GetTaskAssignmentsRequest,
  TaskAssignmentAnswerBody,
} from '../../task-api/api/task-assignment-api';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { addNotification } from '../notification/notificationSlice';
//import { findSystemError } from '../../utils/errorUtils'; TODO: Put this back once 400 errors display correctly

const { REACT_APP_MOTHER_SERVER = 'https://mother.genability.com' } = process.env;

export interface TaskAssignmentState {
  apiStatus: 'idle' | 'pending' | 'notfound' | 'resolved' | 'rejected' | 'submitted';
  lastUpdatedDate: string | undefined;
  result: TaskAssignment | undefined;
  submitCommentApiStatus: 'idle' | 'pending' | 'resolved' | 'rejected';
  commentsById: Record<number, TaskComment>;
  allCommentIds: number[];
  answersByField: Record<string, TaskAssignmentAnswer>;
  allAnswerFields: string[];
  errors: restClient.ResponseError[] | undefined;
}

const initialState: TaskAssignmentState = {
  apiStatus: 'idle',
  lastUpdatedDate: undefined,
  result: undefined,
  submitCommentApiStatus: 'idle',
  commentsById: {},
  allCommentIds: [],
  answersByField: {},
  allAnswerFields: [],
  errors: undefined,
};

interface FetchTaskAssignmentArgs {
  taskAssignmentId: number;
}

interface FetchTaskAssignmentAnswerArgs {
  taskAssignmentId: number;
  answerField: string;
}

interface DeleteTaskAssignmentAnswerArgs {
  taskAssignmentId: number;
  answerField: string;
}

interface submitTaskAssignmentCommentArgs {
  taskAssignment: TaskAssignment;
  username: string;
  comment: string;
}

export interface UpdateTaskAssignmentAnswerArgs {
  taskAssignmentId: number;
  taskAssignmentAnswer: TaskAssignmentAnswerBody;
}

interface SubmitTaskAssignmentArgs {
  taskAssignmentId: number;
  taskId: number;
  redirect: boolean;
  skipDQ: boolean;
  dqOverrides?: DQOverrideReason[];
}

function taskAssignmentSuccess(
  state: TaskAssignmentState,
  { payload }: PayloadAction<restClient.SingleResponse<TaskAssignment>>
) {
  state.result = payload.result ? payload.result : undefined;
  state.apiStatus = 'resolved';
  payload.result?.answers?.forEach(answer => {
    state.answersByField[answer.answerField] = answer;
  });
  state.allAnswerFields = payload.result?.answers
    ? payload.result.answers.map(n => n.answerField)
    : [];
}

export const fetchTaskAssignment = createAsyncThunk(
  'taskAssignment/fetchTaskAssignment',
  async (options: FetchTaskAssignmentArgs, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiClient();
      const request: GetTaskAssignmentsRequest = new GetTaskAssignmentsRequest();
      request.populateComments = true;
      request.populateAssignmentAnswers = true;
      const response: restClient.SingleResponse<TaskAssignment> =
        await client.taskAssignment.getTaskAssignment(options.taskAssignmentId, request);
      if (response.errors) {
        const errorMessage = response.errors[0].message;
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
      return { result: response.result, errors: response.errors };
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'TaskAssignment', dispatch));
    }
  }
);

export const fetchTaskAssignmentAnswer = createAsyncThunk(
  'taskAssignment/fetchTaskAssignmentAnswer',
  async (options: FetchTaskAssignmentAnswerArgs, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiClient();
      const { taskAssignmentId, answerField } = options;
      const request: GetTaskAssignmentsRequest = new GetTaskAssignmentsRequest();
      const response: restClient.SingleResponse<TaskAssignmentAnswer> =
        await client.taskAssignment.getTaskAssignmentAnswer(taskAssignmentId, answerField, request);
      if (response.errors) {
        // Don't show an error if the ai answer field is not found
        if (
          response.errors[0] &&
          response.errors[0].code == 'ObjectNotFound' &&
          [
            TaskAssignmentAnswerField.AI_STAGED_ENTITY,
            TaskAssignmentAnswerField.STAGED_TARIFF_REQUEST_ID,
            TaskAssignmentAnswerField.PUBLISHED_TARIFF_REQUEST_ID,
          ].includes(answerField as TaskAssignmentAnswerField)
        ) {
          return response;
        }
        const errorMessage = response.errors[0].message;
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
      return response;
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'TaskAssignmentAnswer', dispatch));
    }
  }
);

export const deleteTaskAssignmentAnswer = createAsyncThunk(
  'taskAssignment/deleteTaskAssignmentAnswer',
  async (options: DeleteTaskAssignmentAnswerArgs, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiClient();
      const { taskAssignmentId, answerField } = options;
      const request: GetTaskAssignmentsRequest = new GetTaskAssignmentsRequest();
      const response: restClient.SingleResponse<TaskAssignment> =
        await client.taskAssignment.deleteTaskAssignmentAnswer(
          taskAssignmentId,
          answerField,
          request
        );
      if (response.errors) {
        const errorMessage = response.errors[0].message;
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
      return response;
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'TaskAssignmentAnswer', dispatch));
    }
  }
);

export const submitTaskAssignment = createAsyncThunk<
  restClient.SingleResponse<TaskAssignment>,
  SubmitTaskAssignmentArgs,
  { rejectValue: restClient.ResponseError }
>(
  'taskAssignment/submitTaskAssignment',
  async (options: SubmitTaskAssignmentArgs, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiClient();
      const response: restClient.SingleResponse<TaskAssignment> =
        await client.taskAssignment.submitTaskAssignment(options.taskAssignmentId, {
          skipDQ: options.skipDQ,
          dqOverrides: options.dqOverrides,
        });
      if (response.errors) {
        // TODO: Put this back once 400 errors display correctly
        // Show notification only if the response is a SystemError
        // const systemError = findSystemError(response.errors);
        // if (systemError) {
        response.errors.forEach(error => {
          dispatch(addNotification(error.message, NotificationLevel.Error));
        });
        // }
      } else {
        const taskUrl = `${REACT_APP_MOTHER_SERVER}/mother/tasks/${options.taskId}`;
        if (options.redirect) {
          window.location.href = taskUrl;
        } else {
          const successMessage = (
            <>
              Assignment {options.taskAssignmentId} successfully submitted.{' '}
              <a href={taskUrl} target="_blank" rel="noreferrer">
                View task assignment in Mother
              </a>
            </>
          );
          dispatch(addNotification(successMessage, NotificationLevel.Success));
        }
      }
      return response;
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'TaskAssignment', dispatch));
    }
  }
);

export const submitTaskAssignmentComment = createAsyncThunk(
  'taskAssignment/submitTaskAssignmentComment',
  async (options: submitTaskAssignmentCommentArgs, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiClient();
      const taskCommentBody: TaskCommentBody = {
        taskId: options.taskAssignment?.taskId ? options.taskAssignment.taskId : undefined,
        taskAssignmentId: options.taskAssignment?.taskAssignmentId
          ? options.taskAssignment.taskAssignmentId
          : undefined,
        fromUser: options.username,
        comment: options.comment,
      };
      const response: restClient.PagedResponse<TaskComment> = await client.task.addTaskComment(
        taskCommentBody
      );
      if (response.errors) {
        const errorMessage = response.errors[0].message;
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
      return response;
    } catch (err) {
      return rejectWithValue(
        handleUnexpectedThunkException(err, 'TaskAssignmentComment', dispatch)
      );
    }
  }
);

export const updateTaskAssignmentAnswer = createAsyncThunk<
  restClient.SingleResponse<TaskAssignmentAnswer>,
  UpdateTaskAssignmentAnswerArgs,
  {
    rejectValue: restClient.ResponseError;
  }
>('taskAssignment/updateTaskAssignmentAnswer', async (options, { dispatch, rejectWithValue }) => {
  try {
    const client = await TaskApiClient();
    const response: restClient.SingleResponse<TaskAssignmentAnswer> =
      await client.taskAssignment.updateTaskAssignmentAnswer(
        options.taskAssignmentId,
        options.taskAssignmentAnswer
      );
    // TODO: Put this back once 400 errors display correctly
    // Show notification only if the response is a SystemError
    // const systemError = findSystemError(response.errors);
    // if (systemError) {
    if (response.errors) {
      response.errors.forEach(error => {
        dispatch(addNotification(error.message, NotificationLevel.Error));
      });
    }
    //}
    return response;
  } catch (err) {
    console.log(err);
    return rejectWithValue(handleUnexpectedThunkException(err, 'TaskAssignment', dispatch));
  }
});

export const TaskAssignmentSlice = createSlice({
  name: 'taskAssignment',
  initialState,
  reducers: {
    getTaskAssignmentSuccess: taskAssignmentSuccess,
  },
  extraReducers: builder => {
    builder.addCase(fetchTaskAssignment.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(
      fetchTaskAssignment.fulfilled,
      (state, action: PayloadAction<FetchSingleApiResponse<TaskAssignment>>) => {
        const { result, errors } = action.payload;
        if (errors) {
          if (errors[0] && errors[0].code == 'ObjectNotFound') {
            state.apiStatus = 'notfound';
          } else {
            state.apiStatus = 'rejected';
          }
          state.errors = errors;
        } else {
          state.apiStatus = 'resolved';
          state.errors = undefined;
          if (result) {
            const { lastUpdatedDate, comments, answers, ...remainder } = result;
            state.lastUpdatedDate = lastUpdatedDate;
            answers?.forEach(answer => {
              state.answersByField[answer.answerField] = answer;
            });
            state.allAnswerFields = answers ? answers.map(n => n.answerField) : [];
            comments?.forEach(comment => {
              state.commentsById[comment.taskCommentId] = comment;
            });
            state.allCommentIds = comments ? comments.map(c => c.taskCommentId) : [];
            state.result = remainder as TaskAssignment;
          }
        }
      }
    );
    builder.addCase(fetchTaskAssignment.rejected, state => {
      state.apiStatus = 'rejected';
    });
    builder.addCase(
      submitTaskAssignment.fulfilled,
      (state, action: PayloadAction<restClient.SingleResponse<TaskAssignment>>) => {
        const { result, errors } = action.payload;
        if (errors) {
          state.apiStatus = 'rejected';
          state.errors = errors;
        } else {
          state.apiStatus = 'submitted';
          state.errors = undefined;
          if (result) {
            const { lastUpdatedDate, comments, answers, ...remainder } = result;
            state.lastUpdatedDate = lastUpdatedDate;
            answers?.forEach(answer => {
              state.answersByField[answer.answerField] = answer;
            });
            comments?.forEach(comment => {
              state.commentsById[comment.taskCommentId] = comment;
            });
            state.allCommentIds = comments ? comments.map(c => c.taskCommentId) : [];
            state.allAnswerFields = answers ? answers.map(n => n.answerField) : [];
            state.result = remainder as TaskAssignment;
          }
        }
      }
    );
    builder.addCase(submitTaskAssignment.rejected, state => {
      state.apiStatus = 'rejected';
    });
    builder.addCase(
      updateTaskAssignmentAnswer.fulfilled,
      (state, action: PayloadAction<restClient.SingleResponse<TaskAssignmentAnswer>>) => {
        const { result, errors } = action.payload;
        if (!errors) {
          if (result) {
            state.allAnswerFields = state.allAnswerFields.includes(result.answerField)
              ? state.allAnswerFields
              : [...state.allAnswerFields, result.answerField];
            state.answersByField[result.answerField] = result;
          }
        }
      }
    );
    builder.addCase(
      fetchTaskAssignmentAnswer.fulfilled,
      (state, action: PayloadAction<restClient.SingleResponse<TaskAssignmentAnswer>>) => {
        const { result, errors } = action.payload;
        if (!errors) {
          if (result) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state.allAnswerFields = state.allAnswerFields.includes(result.answerField)
              ? state.allAnswerFields
              : [...state.allAnswerFields, result.answerField];
            state.answersByField[result.answerField] = result;
          }
        }
      }
    );
    builder.addCase(deleteTaskAssignmentAnswer.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(
      deleteTaskAssignmentAnswer.fulfilled,
      (state, action: PayloadAction<restClient.SingleResponse<TaskAssignment>>) => {
        const { result, errors } = action.payload;
        if (errors) {
          if (errors[0] && errors[0].code == 'ObjectNotFound') {
            state.apiStatus = 'notfound';
          } else {
            state.apiStatus = 'rejected';
          }
          state.errors = errors;
        } else {
          state.apiStatus = 'resolved';
          state.errors = undefined;
          if (result) {
            const { lastUpdatedDate, comments, answers, ...remainder } = result;
            state.lastUpdatedDate = lastUpdatedDate;
            answers?.forEach(answer => {
              state.answersByField[answer.answerField] = answer;
            });
            state.allAnswerFields = answers ? answers.map(n => n.answerField) : [];
            comments?.forEach(comment => {
              state.commentsById[comment.taskCommentId] = comment;
            });
            state.allCommentIds = comments ? comments.map(c => c.taskCommentId) : [];
            state.result = remainder as TaskAssignment;
          }
        }
      }
    );
    builder.addCase(deleteTaskAssignmentAnswer.rejected, state => {
      state.apiStatus = 'rejected';
    });
    builder.addCase(submitTaskAssignmentComment.pending, state => {
      state.submitCommentApiStatus = 'pending';
    });
    builder.addCase(submitTaskAssignmentComment.fulfilled, (state, action) => {
      const [result] = action.payload.results;
      if (result) {
        state.commentsById[result.taskCommentId] = result;
        state.allCommentIds.push(result.taskCommentId);
        state.submitCommentApiStatus = 'resolved';
      }
    });
    builder.addCase(submitTaskAssignmentComment.rejected, state => {
      state.submitCommentApiStatus = 'rejected';
    });
  },
});

export const selectTaskAssignmentApiStatus = (state: RootState): string => {
  return state.taskAssignment.apiStatus;
};

export const selectCommentApiStatus = (state: RootState): string => {
  return state.taskAssignment.submitCommentApiStatus;
};

export const selectTaskAssignment = (state: RootState): TaskAssignment | undefined => {
  return state.taskAssignment.result;
};

export const selectTaskInputs = (state: RootState): TaskInput[] => {
  return state.taskAssignment.result?.task?.taskInputs ?? [];
};

export const selectAllTaskAssignmentAnswerFields = (state: RootState): string[] => {
  return state.taskAssignment.allAnswerFields;
};

export const selectAllTaskAssignmentAnswers = (state: RootState): TaskAssignmentAnswer[] => {
  const allAnswers: TaskAssignmentAnswer[] = [];
  state.taskAssignment.allAnswerFields.forEach(answerField => {
    allAnswers.push(state.taskAssignment.answersByField[answerField]);
  });
  return allAnswers;
};

export const selectTaskAssignmentAnswerByField = (answerField: string) => {
  return (state: RootState): TaskAssignmentAnswer | null =>
    answerField ? state.taskAssignment.answersByField[answerField] : null;
};

export const selectTaskAssignmentComments = (state: RootState): TaskComment[] => {
  const allComment: TaskComment[] = [];
  state.taskAssignment.allCommentIds.forEach(commentId => {
    allComment.push(state.taskAssignment.commentsById[commentId]);
  });
  return allComment;
};

export const { getTaskAssignmentSuccess } = TaskAssignmentSlice.actions;

export default TaskAssignmentSlice.reducer;
