import { types } from '@genability/api';
const { GroupBy, DetailLevel } = types;

export const DETAIL_LEVELS = [
  {
    text: 'All',
    value: DetailLevel.ALL,
  },
  {
    text: 'Rate',
    value: DetailLevel.RATE,
  },
  {
    text: 'Charge Type',
    value: DetailLevel.CHARGE_TYPE,
  },
  {
    text: 'Charge Type And TOU',
    value: DetailLevel.CHARGE_TYPE_AND_TOU,
  },
  {
    text: 'Usage Type',
    value: DetailLevel.QUANTITY_KEY,
  },
  {
    text: 'Total',
    value: DetailLevel.TOTAL,
  },
];

export const GROUP_BYS = [
  { text: 'Effective Dates', value: GroupBy.ALL },
  { text: '15 Minutes', value: GroupBy.QTRHOUR },
  { text: 'Hour', value: GroupBy.HOUR },
  { text: 'Day', value: GroupBy.DAY },
  { text: 'Month', value: GroupBy.MONTH },
  { text: 'Year', value: GroupBy.YEAR },
];
