import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { restClient } from '@genability/api';
import { AppThunk } from '../store';
import { RootState } from '../rootReducer';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { addNotification } from '../notification/notificationSlice';
import { TaskV2DocumentSection } from '../../task-api/v2/types/TaskV2DocumentSection';
import { TaskApiV2Client } from '../../GenApiClient';

export interface DocumentSectionV2State {
  documentSection: TaskV2DocumentSection | null;
  isLoading: boolean;
  error: string | null;
}

interface UpdateDocumentSectionSuccess {
  documentSection: TaskV2DocumentSection;
}

export const initialState: DocumentSectionV2State = {
  documentSection: null,
  isLoading: false,
  error: null,
};

function startLoading(state: DocumentSectionV2State) {
  state.isLoading = true;
}

function loadingFailed(state: DocumentSectionV2State, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

export const documentSection = createSlice({
  name: 'documentSection',
  initialState,
  reducers: {
    updateDocumentSectionStart: startLoading,
    updateDocumentSectionSuccess(state, { payload }: PayloadAction<UpdateDocumentSectionSuccess>) {
      const { documentSection } = payload;
      state.documentSection = documentSection;
      state.isLoading = false;
      state.error = null;
    },
    updateDocumentSectionFailure: loadingFailed,
  },
});

export const {
  updateDocumentSectionStart,
  updateDocumentSectionSuccess,
  updateDocumentSectionFailure,
} = documentSection.actions;

export default documentSection.reducer;

export const updateDocumentSection =
  (
    documentId: number,
    sectionId: number,
    startPage: number | undefined,
    endPage: number | undefined
  ): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateDocumentSectionStart());
      const client = await TaskApiV2Client();
      const response: restClient.SingleResponse<TaskV2DocumentSection> =
        await client.document.updateDocumentSection(documentId, sectionId, { startPage, endPage });
      if (response.errors) {
        const errorMessage = response.errors[0].message;
        dispatch(updateDocumentSectionFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      } else if (response.result) {
        dispatch(updateDocumentSectionSuccess({ documentSection: response.results[0] }));
      }
    } catch (err: any) {
      if (err) {
        const errorMessage = err.message ? err.message : err.toString();
        dispatch(updateDocumentSectionFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };

export const selectDocumentSection = (state: RootState): DocumentSectionV2State => {
  return state.documentSectionV2;
};
