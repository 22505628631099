import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { restClient } from '@genability/api';
import { AppThunk } from '../store';
import { TaskApiClient } from '../../GenApiClient';
import { TaskComment } from '../../task-api/types/task';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { addNotification } from '../notification/notificationSlice';

export interface TaskCommentsState {
  commentsByTaskCommentId: Record<number, TaskComment | undefined>;
  allTaskCommentIds: number[];
  isLoading: boolean;
  error: string | null;
}

interface TaskCommentsSuccess {
  taskComments: TaskComment[];
}

export const initialState: TaskCommentsState = {
  commentsByTaskCommentId: {},
  allTaskCommentIds: [],
  isLoading: false,
  error: null,
};

function startLoading(state: TaskCommentsState) {
  state.isLoading = true;
}

function loadingFailed(state: TaskCommentsState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

export const taskComments = createSlice({
  name: 'taskComments',
  initialState,
  reducers: {
    getTaskCommentsStart: startLoading,
    getTaskCommentsSuccess(state, { payload }: PayloadAction<TaskCommentsSuccess>) {
      const { taskComments } = payload;
      state.isLoading = false;
      state.error = null;
      taskComments.forEach(taskComment => {
        state.commentsByTaskCommentId[taskComment.taskCommentId] = taskComment;
      });
      state.allTaskCommentIds = taskComments.map(taskComment => taskComment.taskCommentId);
    },
    getTaskCommentsFailure: loadingFailed,
  },
});

export const { getTaskCommentsStart, getTaskCommentsSuccess, getTaskCommentsFailure } =
  taskComments.actions;

export default taskComments.reducer;

export const fetchTaskComments =
  (taskId: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(getTaskCommentsStart());
      const client = await TaskApiClient();
      const response: restClient.PagedResponse<TaskComment> = await client.task.getTaskComments(
        taskId
      );
      const taskComments: TaskComment[] = response.results;
      dispatch(getTaskCommentsSuccess({ taskComments }));
    } catch (err: any) {
      const errorMessage = err.message ? err.message : err.toString();
      dispatch(getTaskCommentsFailure(errorMessage));
      dispatch(addNotification(errorMessage, NotificationLevel.Error));
    }
  };
