import React, { FC, useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { Button, Card, Text, TextArea } from '@arcadiapower/shrike';

import { TaskComment } from '../../../task-api/v2/types/TaskV2';
import { onAt } from '../../../utils/dateUtils';
import { addComment, selectTaskComment } from '../../../state/taskComment_V2/taskCommentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { CurrentUser, selectCurrentUser } from '../../../state/currentUser/currentUserSlice';
import { addNotification } from '../../../state/notification/notificationSlice';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';

export interface TaskCommentsParams {
  taskId: number;
  taskComments?: TaskComment[];
}

const TaskComments: FC<TaskCommentsParams> = ({ taskId, taskComments }) => {
  const dispatch = useDispatch();
  //Creating a local copy of the task comments so that the original prop is not modified
  const [copiedTaskComments, setCopiedTaskComments] = useState<TaskComment[]>([]);

  useEffect(() => {
    if (taskComments) {
      setCopiedTaskComments(JSON.parse(JSON.stringify(taskComments)));
    }
  }, [taskComments]);

  const currentUser: CurrentUser = useSelector(selectCurrentUser);

  const [newComment, setNewComment] = useState('');
  const { apiStatus } = useSelector(selectTaskComment);
  const updateNewCommentValue = useCallback((value: string) => {
    setNewComment(value);
  }, []);
  const addCommentToTask = () => {
    dispatch(addComment({ taskId: taskId, comment: newComment }));
  };
  useEffect(() => {
    if (apiStatus === 'resolved') {
      const newTaskComment: TaskComment = {
        taskId: taskId,
        comment: newComment,
        fromUser: currentUser.username,
        createdDate: DateTime.local().toString(),
      };
      dispatch(addNotification('Task Comments added successfully', NotificationLevel.Success));
      setCopiedTaskComments(prevState => [...prevState, newTaskComment]);

      setNewComment('');
    }
  }, [apiStatus]);
  return (
    <>
      <Row>
        <Text color="primary" opacity="high" tag="h3" textStyle="heading700" className="ml-4 mt-3">
          Task Comments
        </Text>
      </Row>
      <Row className="mt-3 ml-3">
        <Col xs={6}>
          {copiedTaskComments?.map((comment, index) => (
            <Card className="mt-3" mobileVariant="flat" key={index}>
              <Card.Content>
                <Text
                  color="primary"
                  opacity="high"
                  tag="p"
                  textStyle="paragraph600"
                  className="mt-3 ml-3"
                >
                  {`Commented by ${comment.fromUser} on ${onAt(String(comment?.createdDate))}`}
                </Text>
                <Text
                  color="primary"
                  opacity="high"
                  tag="p"
                  textStyle="paragraph500"
                  className="mt-3 ml-3"
                >
                  {comment.comment}
                </Text>
              </Card.Content>
            </Card>
          ))}
        </Col>
      </Row>
      <Row>
        <Col xs={11}>
          <TextArea
            id="addComment"
            label="Add New Comment to the task in the below section"
            margin="0"
            minHeight="110px"
            name="message"
            onChange={value => {
              updateNewCommentValue(value.toString());
            }}
            value={newComment}
            width="100%"
            className="m-5"
          />
        </Col>
      </Row>
      <Button
        loading={apiStatus === 'pending'}
        className="ml-5 mb-5"
        disabled={!newComment || newComment?.trim().length === 0}
        onClick={addCommentToTask}
      >
        Add Comment
      </Button>
    </>
  );
};

export default TaskComments;
