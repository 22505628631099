export enum MonitoringStatusType {
  DOC_TASK_CREATED = 'DOC_TASK_CREATED',
  TODO = 'TODO',
  NEW_VERSION_CREATED = 'NEW_VERSION_CREATED',
  PENDING_VERSIONING = 'PENDING_VERSIONING',
  NO_UPDATES = 'NO_UPDATES',
  NEW_RECORD_CREATED = 'NEW_RECORD_CREATED',
  RATE_UPDATED = 'RATE_UPDATED',
  RATE_EXTENDED = 'RATE_EXTENDED',
  RATE_NOT_RELEASED = 'RATE_NOT_RELEASED',
  QUERY = 'QUERY',
  DOCUMENT_NOT_AVAILABLE = 'DOCUMENT_NOT_AVAILABLE',
  UTILITY_OUTREACH = 'UTILITY_OUTREACH',
  TARIFF_NEWLY_ADDED = 'TARIFF_NEWLY_ADDED',
  YET_TO_REVISE = 'YET_TO_REVISE',
  NEW_EDIT_VERSION_CREATED = 'NEW_EDIT_VERSION_CREATED',
  EXISTING_LOOKUP_RECORD_UPDATED = 'EXISTING_LOOKUP_RECORD_UPDATED',
  TARIFF_OR_RIDER_EXPIRED = 'TARIFF_OR_RIDER_EXPIRED',
}
