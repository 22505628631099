import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { fetchTariff, selectTariff } from '../../../state/tariff/tariffSlice';
import { fetchTariffHistory, selectTariffHistory } from '../../../state/tariff/tariffHistorySlice';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { TextInput } from '@arcadiapower/gen-react-lib';
import { types } from '@genability/api';
import TariffDetails from '../../../components/TariffDetails';
import TariffActionFooter from '../../../components/TariffActionFooter/TariffActionFooter';
import TariffItemTypeFilter, {
  TariffItemTypes,
} from '../../../components/TariffDetails/TariffItemTypeFilter';
import { fetchSeasons } from '../../../state/loadServingEntity/loadServingEntitySlice';

export interface TariffDetailParams {
  masterTariffId: string;
}

type DateFilter = { from: string | undefined; to: string | undefined };

const findRatesWithSeasonByTariffId = (
  tariffId: number,
  tariff: types.Tariff | null
): types.TariffRate[] => {
  const foundRates = tariff?.rates?.filter(rate => {
    return rate.tariffId === tariffId && 'season' in rate;
  });
  return foundRates || [];
};

const filterSeasonGroupIds = (
  rates: types.TariffRate[]
): { lseId: number; seasonGroupId: number }[] => {
  const seasonGroupIds = rates.map(({ season }) => {
    if (season && season?.lseId && season?.seasonGroupId) {
      const { lseId, seasonGroupId } = season;
      return { lseId, seasonGroupId };
    }
  });
  // Remove duplicates
  return seasonGroupIds.filter(
    (obj, index) =>
      obj &&
      seasonGroupIds.findIndex(
        item => item?.lseId === obj.lseId && item?.seasonGroupId === obj.seasonGroupId
      ) === index
  ) as { lseId: number; seasonGroupId: number }[];
};

const MAX_EFFECTIVE_DATES = 2;

const TariffDetail: FC<RouteComponentProps<TariffDetailParams>> = props => {
  const [selectedTariffTypes, setSelectedTariffTypes] = useState<Set<string>>(new Set());
  const [dateFilter, setDateFilter] = useState<DateFilter>({ from: undefined, to: undefined });
  const [lookupValues, setLookupValues] = useState<types.LookupValue[]>([]);
  const [selectedDates, setSelectedDates] = useState<{ date: string; tariffId: number }[]>([]);

  const dispatch = useDispatch();
  const { tariff, loading: tariffLoading } = useSelector(selectTariff);
  const { tariffHistory, loading: tariffHistoryLoading } = useSelector(selectTariffHistory);

  const isLoading = tariffLoading !== 'idle' && tariffHistoryLoading !== 'idle';

  const addLookupValues = (lookupValues: types.LookupValue[]) => {
    // Remove duplicates
    setLookupValues(prevState => {
      const filtered = lookupValues.filter(
        lookupValue =>
          !prevState.some(
            value =>
              value.propertyKey === lookupValue.propertyKey &&
              value.fromDateTime === lookupValue.fromDateTime
          )
      );
      return [...prevState, ...filtered];
    });
  };

  useEffect(() => {
    const masterTariffId = Number(props.match.params.masterTariffId);
    dispatch(fetchTariff(masterTariffId));
    dispatch(fetchTariffHistory(masterTariffId));
  }, [dispatch, props.match.params.masterTariffId]);

  useEffect(() => {
    // Fetch seasons by LseID
    const seasonsByLseId = new Set<number>();
    if (tariffHistory?.tariffVersions) {
      tariffHistory.tariffVersions.forEach(tariffVersion => {
        const ratesWithSeason = findRatesWithSeasonByTariffId(tariffVersion.tariffId, tariff);
        ratesWithSeason.forEach(rate => {
          rate?.season?.lseId && seasonsByLseId.add(rate.season.lseId);
        });
      });
    }
    seasonsByLseId.forEach(lseId => dispatch(fetchSeasons(lseId)));
  }, [tariff, tariffHistory, isLoading]);

  const tariffHeader = tariff && (
    <div style={{ marginTop: '150px' }}>
      <h2 className="display-4 my-3 mb-2 mt-5">
        {tariff.tariffCode} {tariff.tariffName}
      </h2>
      <div className="font-weight-bold">
        <span> {tariff.lseCode} </span> <span className="mx-2">-</span>
        <span> MTID: </span> <span>{tariff.masterTariffId}</span> <span className="mx-2">-</span>
        <span> LastUpdate: </span> {tariff.effectiveDate} <span className="mx-2">-</span>
        <span>
          <Link
            target="_blank"
            to={{ pathname: `${process.env.REACT_APP_MOTHER_URL}/lses/${tariff.lseId}` }}
          >
            Open tariff in Mother
          </Link>
        </span>
        <span className="mx-2">-</span>
        <span>
          <Link
            target="_blank"
            to={{
              pathname: `${process.env.REACT_APP_DASH_URL}/explorer/tariffs/${tariff.masterTariffId}`,
            }}
          >
            Open tariff in Explorer
          </Link>
        </span>
        <span className="mx-2">-</span>
        <span>
          <Link
            target="_blank"
            to={{
              pathname: `${process.env.REACT_APP_DASH_URL}/explorer/tariffs/${tariff.masterTariffId}/documents`,
            }}
          >
            Open document in Explorer
          </Link>
        </span>
        <span className="mx-2">-</span>
        <span>
          {tariff?.documents?.[0]?.document && (
            <Link
              target="_blank"
              to={{
                pathname: tariff.documents[0].document.sourceUrl?.includes('.pdf')
                  ? tariff.documents[0].document?.sourceUrl
                  : tariff.documents[0].document?.archiveUrl,
              }}
            >
              {tariff.documents[0].document.sourceUrl?.includes('.pdf')
                ? 'Source doc'
                : 'Archive doc'}
            </Link>
          )}
        </span>
      </div>
    </div>
  );

  const filterByDateForm = (
    <form className="my-4">
      <Row>
        <Col className="form-group">
          <label className={`pt-2 pb-1 font-weight-bold`}>To</label>
          <TextInput
            type="date"
            label=""
            placeholder="Date"
            id="to-date"
            value={dateFilter.to}
            onChange={event => {
              setDateFilter(prevState => ({ ...prevState, to: event.target.value }));
            }}
          />
        </Col>
        <Col className="form-group">
          <label className={`pt-2 pb-1 font-weight-bold`}>From</label>
          <TextInput
            type="date"
            label=""
            placeholder="Date"
            id="from-date"
            value={dateFilter.from}
            onChange={event => {
              setDateFilter(prevState => ({ ...prevState, from: event.target.value }));
            }}
          />
        </Col>
      </Row>
    </form>
  );

  const onDateSelected = (ev: ChangeEvent<HTMLInputElement>, tariffId: number) => {
    if (!ev.target.checked) {
      const filtered = selectedDates.filter(se => se.date !== ev.target.value);
      setSelectedDates(filtered);
    } else if (selectedDates.length < MAX_EFFECTIVE_DATES) {
      setSelectedDates([...selectedDates, { date: ev.target.value, tariffId }]);
    }
  };

  const toggleTariffType = (tariffType: string) => {
    if (tariffType === TariffItemTypes.ALL) {
      setSelectedTariffTypes(new Set());
    } else {
      setSelectedTariffTypes(prevSelectedTypes => {
        const newSelectedTariffTypes = new Set([...prevSelectedTypes]);
        if (newSelectedTariffTypes.has(tariffType)) {
          newSelectedTariffTypes.delete(tariffType);
        } else {
          newSelectedTariffTypes.add(tariffType);
        }
        return newSelectedTariffTypes;
      });
    }
  };

  const tariffVersions = tariffHistory?.tariffVersions || [];

  const tariffDetailList = (
    <div>
      <div className="py-4">
        {tariff &&
          tariffVersions.map((version, index) => (
            <TariffDetails
              key={version.tariffId}
              tariff={tariff}
              version={version}
              seasonGroupIds={filterSeasonGroupIds(
                findRatesWithSeasonByTariffId(version.tariffId, tariff)
              )}
              dateFrom={dateFilter.from}
              dateUpTo={dateFilter.to}
              priorDate={index > 0 ? tariffVersions[index - 1].effectiveDate : ''}
              allLookupValues={lookupValues}
              addLookupValues={addLookupValues}
              selectedDates={selectedDates}
              onDateSelected={onDateSelected}
              maxSelectedDates={MAX_EFFECTIVE_DATES}
              selectedTariffTypes={selectedTariffTypes}
            />
          ))}
      </div>
    </div>
  );

  return (
    <>
      <Container className="pb-5 mt-5">
        {!isLoading && (
          <div className="mt-5">
            {tariffHeader}
            {filterByDateForm}
            <TariffItemTypeFilter
              selectedFilters={selectedTariffTypes}
              onToggleFilter={toggleTariffType}
            />
            {tariffDetailList}
          </div>
        )}
        {isLoading && (
          <div style={{ marginTop: '200px' }}>
            Tariff {props.match.params.masterTariffId} loading...
          </div>
        )}
      </Container>
      <TariffActionFooter
        tariffDates={selectedDates}
        maxSelectedDates={MAX_EFFECTIVE_DATES}
        masterTariffId={tariff?.masterTariffId}
      />
    </>
  );
};

export default withRouter(TariffDetail);
