import { Button, Checkbox, Modal } from '@arcadiapower/shrike';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WorkerInput from '../WorkerInput/WorkerInput';
import { IWorker } from '../../task-api/types/worker';
import { QualificationType } from '../../task-api/v2/types/QualifictionType';
import { addNotification } from '../../state/notification/notificationSlice';
import { TariffConfigData, TariffDataRequestParams } from './Config/TariffConfigTable';
import { LookupConfigData, LookupDataRequestParams } from './Config/LookupConfigTable';
import {
  LookupConfigDataProps,
  selectLookupConfigData,
  startLookupLoading,
  updateLookupConfigData,
  UpdateLookupConfigProps,
} from '../../state/OpsManagement/Config/LookupConfigSlice';
import {
  selectTariffConfigData,
  startTariffLoading,
  TariffConfigDataProps,
  updateTariffConfigData,
  UserAssignmentConfig,
} from '../../state/OpsManagement/Config/TariffConfigSlice';

interface bulkProps {
  sortedItems: TariffConfigData[] | LookupConfigData[];
  selected: boolean[];
  type: 'tariff' | 'lookup';
  currentUserIsSME: boolean;
  loading?: boolean;
  populateFilters: () => LookupConfigDataProps | TariffConfigDataProps;
  params: TariffDataRequestParams | LookupDataRequestParams;
}

const BulkAssignConfig = ({
  sortedItems,
  selected,
  type,
  currentUserIsSME,
  populateFilters,
  params,
  loading = false,
}: bulkProps) => {
  const dispatch = useDispatch();
  const { updateApiStatus: updateTariffApiStatus, count: tariffCount } =
    useSelector(selectTariffConfigData);
  const { updateApiStatus: updateLookupApiStatus, count: lookupCount } =
    useSelector(selectLookupConfigData);
  const [showAssignmentModal, setShowAssignmentModal] = useState<boolean>(false);
  const [selectAllMatchingRecords, setSelectAllMatchingRecords] = useState<boolean>(false);
  const [primaryOwner, setPrimaryOwner] = useState<IWorker | undefined>(undefined);
  const [modeller, setModeller] = useState<IWorker | undefined>(undefined);
  const [reviewer, setReviewer] = useState<IWorker | undefined>(undefined);

  return (
    <>
      <Button
        onClick={() => {
          if (!currentUserIsSME) {
            dispatch(
              addNotification(
                'Only SMEs can update assignment configurations',
                NotificationLevel.Error
              )
            );
            return;
          }
          setShowAssignmentModal(true);
        }}
        loading={false}
        size="small"
        className="ml-3 px-2 py-3"
        style={{ height: '80%' }}
      >
        Bulk assign
      </Button>
      <Modal aria-label="Modal" isOpen={showAssignmentModal} size="medium">
        <Modal.Header title="Bulk Assign"></Modal.Header>
        <Modal.Content>
          <div className="d-flex flex-column justify-content-between align-items-center">
            <div className="w-75 mb-2">
              <WorkerInput
                label="Choose Primary Owner"
                qualification={QualificationType.LOOKUP_REVIEWER}
                placeholder="Search assignee"
                id="assignTask"
                selected={primaryOwner}
                setSelected={assignee => setPrimaryOwner(assignee)}
              />
            </div>
            <div className="w-75 my-2 mt-4">
              <WorkerInput
                label="Choose Modeller"
                qualification={QualificationType.LOOKUP_MODELLER}
                placeholder="Search assignee"
                id="assignTask"
                selected={modeller}
                setSelected={setModeller}
              />
            </div>
            <div className="w-75 my-2 mt-4">
              <WorkerInput
                label="Choose Reviewer"
                qualification={QualificationType.LOOKUP_REVIEWER}
                placeholder="Search assignee"
                id="assignTask"
                selected={reviewer}
                setSelected={setReviewer}
              />
            </div>
            <div className="w-75 my-2 mt-4">
              <Checkbox
                checked={selectAllMatchingRecords}
                onChange={() => setSelectAllMatchingRecords(prev => !prev)}
              >
                {`Assign to all ${type == 'tariff' ? tariffCount : lookupCount} Matching Records`}
              </Checkbox>
            </div>
          </div>
        </Modal.Content>
        <Modal.Footer
          disabled={!reviewer && !modeller && !primaryOwner}
          onSubmit={() => {
            if (selectAllMatchingRecords) {
              sortedItems = [
                {
                  reviewerId: reviewer?.workerId,
                  modellerId: modeller?.workerId,
                  primaryOwnerId: primaryOwner?.workerId,
                  monitoringInventoryId: sortedItems[0].monitoringInventoryId,
                  userMappingId: sortedItems[0].userMappingId,
                },
              ] as TariffConfigData[] | LookupConfigData[];
            }
            if (type == 'tariff') {
              dispatch(startTariffLoading());
              dispatch(
                updateTariffConfigData({
                  userAssignmentConfig: (sortedItems as TariffConfigData[])
                    .filter((row, index: number) => selected[index])
                    .map(row => {
                      return {
                        userMappingId: row.userMappingId,
                        monitoringInventoryId: row.monitoringInventoryId,
                        primaryOwnerId: primaryOwner?.workerId || row.primaryOwnerId,
                        modellerId: modeller?.workerId || row.modellerId,
                        reviewerId: reviewer?.workerId || row.reviewerId,
                      };
                    }) as UserAssignmentConfig[],
                  selectAllMatchingRecords,
                  showInActiveTariff: (params as TariffDataRequestParams).showInActiveTariff,
                  showUnassignedTariff: (params as TariffDataRequestParams).showUnassignedTariff,
                  getTariffUserConfigRequest: {
                    ...populateFilters(),
                    paginationCriteria: { pageCount: tariffCount, pageStart: 0 },
                  } as TariffConfigDataProps,
                })
              );
            } else {
              dispatch(startLookupLoading());
              dispatch(
                updateLookupConfigData({
                  postLookupUserConfigRequest: (sortedItems as LookupConfigData[])
                    .filter((row, index: number) => selected[index])
                    .map(row => {
                      return {
                        monitoringInventoryId: row.monitoringInventoryId,
                        propertykey: row.propertyKey,
                        userAssignmentConfig: {
                          userMappingId: row.userMappingId,
                          monitoringInventoryId: row.monitoringInventoryId,
                          primaryOwnerId: primaryOwner?.workerId || row.primaryOwnerId,
                          modellerId: modeller?.workerId || row.modellerId,
                          reviewerId: reviewer?.workerId || row.reviewerId,
                        },
                      };
                    }) as UpdateLookupConfigProps[],
                  selectAllMatchingRecords,
                  showUnassignedLookups: (params as LookupDataRequestParams).showUnassignedLookups,
                  getLookupUserConfigRequest: {
                    ...populateFilters(),
                    paginationCriteria: { pageCount: lookupCount, pageStart: 0 },
                  } as LookupConfigDataProps,
                })
              );
            }
          }}
          loading={
            updateLookupApiStatus === 'pending' || updateTariffApiStatus === 'pending' || loading
          }
          onCancel={() =>
            setShowAssignmentModal(
              updateLookupApiStatus === 'pending' || updateTariffApiStatus === 'pending'
            )
          }
        ></Modal.Footer>
      </Modal>
    </>
  );
};

export default BulkAssignConfig;
