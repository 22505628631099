import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { restClient } from '@genability/api';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { OpsManagementApiClient } from '../../../GenApiClient';
import { LookupStatusData } from '../../../components/OpsManagement/Status/LookupStatusTable';
import { addNotification } from '../../notification/notificationSlice';
import { handleUnexpectedThunkException } from '../../reduxUtils';
import { RootState } from '../../rootReducer';

export interface LookupStatusDataResponse {
  results: LookupStatusData[];
  fetchApiStatus: 'idle' | 'pending' | 'resolved' | 'rejected';
  errors: restClient.ResponseError[] | undefined;
  count: number;
}
export interface LookupStatusProps {
  paginationCriteria?: { pageCount: number; pageStart: number };
  sortCriteria: { sortOn: string[]; sortOrder: string[] };
  lookupStatusFilterCriteria: LookupStatusFilterCriteria;
}
export interface LookupStatusFilterCriteria {
  lseNames?: string[];
  lseIds?: number[];
  propertyKeys?: string[];
  frequency?: string[];
  expired?: boolean;
  modellers?: string[];
  reviewers?: string[];
  dueInDays?: string;
  monitoringStatusList?: string[];
  monitoringNotes?: string[];
  sourceList?: string[];
  dateFilterCriteria?: { dateColumnName: string; fromDate: string; toDate: string }[];
}
export const initialState: LookupStatusDataResponse = {
  results: [],
  fetchApiStatus: 'idle',
  count: 0,
  errors: undefined,
};

export const fetchLookupStatusData = createAsyncThunk(
  'workflowManagement/status/lookup',
  async (request: LookupStatusProps, { dispatch, rejectWithValue }) => {
    try {
      const client = await OpsManagementApiClient();
      const response: restClient.PagedResponse<LookupStatusData> =
        await client.opsData.fetchLookupStatusData(request);
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
      return response;
    } catch (err) {
      dispatch(addNotification('Fetching LookupStatus Failed', NotificationLevel.Error));
      return rejectWithValue(handleUnexpectedThunkException(err, 'LookupStatus', dispatch));
    }
  }
);

export const LookupStatusDataSlice = createSlice({
  name: 'LookupStatusData',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchLookupStatusData.pending, state => {
      state.fetchApiStatus = 'pending';
    });
    builder.addCase(
      fetchLookupStatusData.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<LookupStatusData>>) => {
        const { count, results, errors } = action.payload;
        if (errors) {
          state.fetchApiStatus = 'rejected';
          state.errors = errors;
        } else {
          state.fetchApiStatus = 'resolved';
          state.count = count;
          state.results = results;
          state.errors = undefined;
        }
      }
    );
    builder.addCase(fetchLookupStatusData.rejected, state => {
      state.fetchApiStatus = 'rejected';
    });
  },
});

export default LookupStatusDataSlice.reducer;

export const selectLookupStatusData = (state: RootState): LookupStatusDataResponse => {
  return state.lookupStatusData;
};
