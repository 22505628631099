/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "teamtariffuiProxy",
            "endpoint": "https://7u0hkt8q54.execute-api.us-west-2.amazonaws.com/main",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:ee12cef6-82ca-46a1-8cdd-5bba68a76f68",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_By8s417Fl",
    "aws_user_pools_web_client_id": "1j6dv44u51ud3vivibmnnielmu",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "team-tariff-ui-master-hostingbucket-main",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "http://team-tariff-ui-master-hostingbucket-main.s3-website-us-west-2.amazonaws.com"
};


export default awsmobile;
