import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { restClient } from '@genability/api';
import { AppThunk } from '../store';
import { RootState } from '../rootReducer';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { addNotification } from '../notification/notificationSlice';
import { TaskV2Document } from '../../task-api/v2/types/TaskV2Document';
import { TaskApiV2Client } from '../../GenApiClient';

export interface DocumentV2State {
  document: TaskV2Document | null;
  isLoading: boolean;
  error: string | null;
}

interface DocumentSuccess {
  document: TaskV2Document;
}

export const initialState: DocumentV2State = {
  document: null,
  isLoading: false,
  error: null,
};

function startLoading(state: DocumentV2State) {
  state.isLoading = true;
}

function loadingFailed(state: DocumentV2State, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

export const document = createSlice({
  name: 'document',
  initialState,
  reducers: {
    getDocumentStart: startLoading,
    getDocumentSuccess(state, { payload }: PayloadAction<DocumentSuccess>) {
      const { document } = payload;
      state.document = document;
      state.isLoading = false;
      state.error = null;
    },
    getDocumentFailure: loadingFailed,
  },
});

export const { getDocumentStart, getDocumentSuccess, getDocumentFailure } = document.actions;

export default document.reducer;

export const fetchDocument =
  (documentId: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(getDocumentStart());
      const client = await TaskApiV2Client();
      const response: restClient.SingleResponse<TaskV2Document> = await client.document.getDocument(
        documentId
      );
      if (response.errors) {
        const errorMessage = response.errors[0].message;
        dispatch(getDocumentFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      } else if (response.result) {
        dispatch(getDocumentSuccess({ document: response.results[0] }));
      }
    } catch (err: any) {
      if (err) {
        const errorMessage = err.message ? err.message : err.toString();
        dispatch(getDocumentFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };

export const selectDocument = (state: RootState): DocumentV2State => {
  return state.documentV2;
};
