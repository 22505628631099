import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';
import { GenApiClient } from '../../GenApiClient';
import { LoadingState, handleUnexpectedThunkException } from '../reduxUtils';
import { GetTariffsRequest } from '@genability/api/dist/api';
import { ITariffState, ITariffSuccess, createTariffsRequest, initialState } from './tariffsSlice';

export const fetchTariffsByMtid = createAsyncThunk(
  'tariffsByMtid/fetchTariffsByMtid',
  async (params: Partial<GetTariffsRequest>, { dispatch, rejectWithValue }) => {
    try {
      const client = await GenApiClient();
      const request = createTariffsRequest(params);
      const response = await client.tariffs.getTariffs(request);
      const { status, results, errors, count, pageCount, pageStart: page } = response;
      const tariffSuccess: ITariffSuccess = {
        status,
        meta: {
          count: count || 0,
          pageCount: pageCount || 25,
          page: page || 0,
        },
        results,
        errors,
        currentRequestId: '',
      };
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
      return tariffSuccess;
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'Task', dispatch));
    }
  }
);

export const tariffsByMtidSlice = createSlice({
  name: 'tariffsByMtid',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchTariffsByMtid.pending, state => {
        state.loading = LoadingState.PENDING;
      })
      .addCase(fetchTariffsByMtid.fulfilled, (state, action) => {
        const { results, errors, meta } = action.payload;
        if (errors) {
          state.loading = LoadingState.FAILED;
          state.errors = errors;
          state.meta = meta;
        } else {
          state.loading = LoadingState.SUCCEEDED;
          state.results = results;
          state.meta = meta;
        }
      })
      .addCase(fetchTariffsByMtid.rejected, state => {
        state.loading = LoadingState.FAILED;
      });
  },
});

export const selectTariffByMtid = (state: RootState): ITariffState | undefined => {
  return state.tariffsByMtid;
};

export default tariffsByMtidSlice.reducer;
