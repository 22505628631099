import { Table, Th } from '@arcadiapower/gen-react-lib';
import React, { ReactElement } from 'react';
import styles from '../../../pages/OpsManagement/OpsManagement.module.scss';
import { useSelector } from 'react-redux';
import { selectTariffStatusStats } from '../../../state/OpsManagement/Status/TariffStatusStatsSlice';
import { Col } from 'react-bootstrap';

const TariffStatusStatsTable = (): ReactElement => {
  const stats = useSelector(selectTariffStatusStats);

  return (
    <>
      <Col>
        <span className="d-flex flex-column justify-content-around align-items-center">
          <h6 className="ml-3">Overall Summary</h6>
          <Table className="ml-3" striped bordered>
            <thead>
              <Th className={styles.statusHeader}>Total LSEs Assigned</Th>
              <Th className={styles.statusHeader}>Total Assigned Tariffs</Th>
              <Th className={styles.statusHeader}>Monitored</Th>
              <Th className={styles.statusHeader}>Pending Monitoring</Th>
              <Th className={styles.statusHeader}>New version Created</Th>
            </thead>
            <tbody className="text-center">
              {stats.apiStatus === 'pending' && <td colSpan={5}>Loading...</td>}
              {stats.apiStatus != 'pending' && stats.errors && (
                <td colSpan={5}>Loading Stats Failed</td>
              )}
              {stats.apiStatus != 'pending' && !stats.errors && stats.results.length > 0 && (
                <tr>
                  <td>{stats.results[0].globalStats.totalAssignedLSE}</td>
                  <td>{stats.results[0].globalStats.totalAssignedTariffs}</td>
                  <td>{stats.results[0].globalStats.monitoredTariffs}</td>
                  <td>{stats.results[0].globalStats.pendingMonitoringTariffs}</td>
                  <td>{stats.results[0].globalStats.newVersionCreatedTariffs}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </span>
      </Col>
      <Col>
        <span className="d-flex flex-column justify-content-around align-items-center">
          <h6 className="ml-3">Current User Summary</h6>
          <Table striped bordered className="">
            <thead>
              <Th className={styles.statusHeader}>Total LSEs Assigned</Th>
              <Th className={styles.statusHeader}>Total Assigned Tariffs</Th>
              <Th className={styles.statusHeader}>Monitored</Th>
              <Th className={styles.statusHeader}>Pending Monitoring</Th>
              <Th className={styles.statusHeader}>New version Created</Th>
            </thead>
            <tbody className="text-center">
              {stats.apiStatus === 'pending' && <td colSpan={5}>Loading...</td>}
              {stats.apiStatus != 'pending' && stats.errors && (
                <td colSpan={5}>Loading Stats Failed</td>
              )}
              {stats.apiStatus != 'pending' && !stats.errors && stats.results.length > 0 && (
                <tr>
                  <td>{stats.results[0].currentUserStats.totalAssignedLSE}</td>
                  <td>{stats.results[0].currentUserStats.totalAssignedTariffs}</td>
                  <td>{stats.results[0].currentUserStats.monitoredTariffs}</td>
                  <td>{stats.results[0].currentUserStats.pendingMonitoringTariffs}</td>
                  <td>{stats.results[0].currentUserStats.newVersionCreatedTariffs}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </span>
      </Col>
    </>
  );
};

export default TariffStatusStatsTable;
