import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DashboardFilterState,
  selectedDashboardFilter,
  FilterType,
  toggleFilter,
} from '../../state/dashboardFilter/dashboardFilterSlice';
import { Badge, Checkbox, Text, lightTheme } from '@arcadiapower/shrike';
import { Row } from 'react-bootstrap';

export interface FilterSectionParams {
  title: string;
  filterType: FilterType;
  filterValues: string[];
  bufferValues: string[];
  setBuffer: Dispatch<SetStateAction<string[]>>;
}

const FilterSection: React.FC<FilterSectionParams> = ({
  title,
  filterType,
  filterValues,
  bufferValues,
  setBuffer,
}) => {
  const dispatch = useDispatch();
  const activeTaskFilter: DashboardFilterState = useSelector(selectedDashboardFilter);

  const handleChange = useCallback(
    (filter: string) => {
      let buffer = bufferValues.slice();
      if (!activeTaskFilter[filterType][filter].isChecked) {
        buffer.push(filter);
      } else {
        buffer = buffer.filter(data => data != filter);
      }
      dispatch(toggleFilter({ filterType, filter }));
      setBuffer(buffer);
    },
    [bufferValues]
  );

  const toggleAllFilters = useCallback(
    (filterType: FilterType) => {
      let allFiltersChecked = true;
      let buffer = bufferValues.slice();
      filterValues.forEach(filter => {
        if (
          activeTaskFilter[filterType][filter].isEnabled &&
          !activeTaskFilter[filterType][filter].isChecked
        ) {
          dispatch(toggleFilter({ filterType, filter }));
          allFiltersChecked = false;
          buffer.push(filter);
        }
      });
      if (allFiltersChecked) {
        buffer = [];
        filterValues.forEach(filter => {
          if (
            activeTaskFilter[filterType][filter].isEnabled &&
            activeTaskFilter[filterType][filter].isChecked
          ) {
            dispatch(toggleFilter({ filterType, filter }));
          }
        });
      }
      setBuffer(buffer);
    },
    [bufferValues]
  );

  return (
    <>
      <Text color="primary" opacity="high" tag="h3" textStyle="eyebrow400" className="ml-1 mb-3">
        {title}
      </Text>
      {filterValues.map(filter => (
        <Checkbox
          key={filter}
          checked={activeTaskFilter[filterType][filter]?.isChecked || false}
          onChange={() => handleChange(filter)}
          id={`checkbox-${filter}`}
          name={`checkbox-${filter}`}
          disabled={!activeTaskFilter[filterType][filter]?.isEnabled || false}
        >
          {filter}
        </Checkbox>
      ))}
      <div
        className="mt-2 ml-2"
        onClick={() => {
          toggleAllFilters(filterType);
        }}
        style={{ cursor: 'pointer' }}
      >
        <Row>
          <Badge backgroundColor="primary" size="small">
            <span
              style={{
                textDecoration: 'underline',
                color: `${lightTheme.colors.content.success}`,
              }}
            >
              Select/Clear all filters
            </span>
          </Badge>
        </Row>
      </div>
    </>
  );
};

export default FilterSection;
