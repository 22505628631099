import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Task } from '../../task-api/types/task';
import { restClient, types } from '@genability/api';
import { TaskApiClient } from '../../GenApiClient';
import { FetchSingleApiResponse } from '../../utils/apiResponseTypes';
import { GetTasksRequest } from '../../task-api/api/task-api';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { addNotification } from '../notification/notificationSlice';
import { handleUnexpectedThunkException } from '../reduxUtils';
import { RootState } from '../rootReducer';

export interface TaskState {
  apiStatus: 'idle' | 'pending' | 'notfound' | 'resolved' | 'rejected';
  result: Task | undefined;
  source: types.Tariff | undefined;
  errors: restClient.ResponseError[] | undefined;
}

const initialState: TaskState = {
  apiStatus: 'idle',
  result: undefined,
  source: undefined,
  errors: undefined,
};

interface taskOptionsProps {
  taskId: number;
  request?: GetTasksRequest;
}

export const fetchTask = createAsyncThunk(
  'task/fetchTask',
  async (options: taskOptionsProps, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiClient();
      const response: restClient.SingleResponse<Task> = await client.task.getTask(
        options.taskId,
        options.request
      );
      if (response.errors) {
        const errorMessage = response.errors[0].message;
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
      return { result: response.result, errors: response.errors };
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'Task', dispatch));
    }
  }
);

export const fetchTaskSource = createAsyncThunk(
  'task/fetchTaskSource',
  async (options: taskOptionsProps, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiClient();
      const response: restClient.SingleResponse<types.Tariff> = await client.task.getTaskSource(
        options.taskId
      );
      if (response.errors) {
        const errorMessage = response.errors[0].message;
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
      return { result: response.result, errors: response.errors };
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'TaskSource', dispatch));
    }
  }
);

export const TaskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchTask.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(
      fetchTask.fulfilled,
      (state, action: PayloadAction<FetchSingleApiResponse<Task>>) => {
        const { result, errors } = action.payload;
        if (errors) {
          if (errors[0] && errors[0].code == 'ObjectNotFound') {
            state.apiStatus = 'notfound';
          } else {
            state.apiStatus = 'rejected';
          }
          state.errors = errors;
        } else {
          state.apiStatus = 'resolved';
          if (result) state.result = result;
          state.errors = undefined;
        }
      }
    );
    builder.addCase(fetchTask.rejected, state => {
      state.apiStatus = 'rejected';
    });
    builder.addCase(fetchTaskSource.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(
      fetchTaskSource.fulfilled,
      (state, action: PayloadAction<FetchSingleApiResponse<types.Tariff>>) => {
        const { result, errors } = action.payload;
        if (errors) {
          if (errors[0] && errors[0].code == 'ObjectNotFound') {
            state.apiStatus = 'notfound';
          } else {
            state.apiStatus = 'rejected';
          }
          state.errors = errors;
        } else {
          state.apiStatus = 'resolved';
          if (result) state.source = result;
          state.errors = undefined;
        }
      }
    );
    builder.addCase(fetchTaskSource.rejected, state => {
      state.apiStatus = 'rejected';
    });
  },
});

export const selectTaskApiStatus = (state: RootState): string => {
  return state.task.apiStatus;
};

export const selectTask = (state: RootState): Task | undefined => {
  return state.task.result;
};

export const selectTaskSource = (state: RootState): types.Tariff | undefined => {
  return state.task.source;
};
export default TaskSlice.reducer;
