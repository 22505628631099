import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import FileUploadArea from '../FileUploadArea/FileUploadArea';
import { DatePicker, Input, Modal, Select } from '@arcadiapower/shrike';
import { useSelector } from 'react-redux';
import { selectUnpublishedLookupTasks } from '../../state/tasksV2/UnpublishedLookupTaskSlice';
import { TaskType } from '../../task-api/v2/types/TaskType';
import { LookupValueV2Transformed } from '../../task-api/v2/types/LookupValueV2Transformed';
import { DocumentViewer } from '@arcadiapower/gen-react-lib';
import { TaskSource } from '../../task-api/v2/types/TaskSource';
import { isLookupAlreadyPresent } from '../../utils/lookupUtil';
import { applyTimeZoneOffSet } from '../../utils/dateUtils';
import { selectWorkflow } from '../../state/tasksV2/WorkflowStateSlice';
import { ActionType } from '../../task-api/v2/types/ActionType';
import { TaskV2Document } from '../../task-api/v2/types/TaskV2Document';
import styles from './LookupValueEditorModal.module.scss';

export interface LookupValueEditorModalProps {
  propertyKey: string;
  show: boolean;
  onHide: () => void;
  lookupValue?: LookupValueV2Transformed | null;
  document?: TaskV2Document | null;
  isGlobalPropertyKey?: boolean;
  editMode?: boolean;
  onSave: (
    modifiedLookupValues: ModifiedLookupValue,
    taskSource: string,
    startPage?: number,
    endPage?: number
  ) => void;
  isSaving: boolean;
  onUploadDocument?: (file: File, taskSource: string) => void;
  isUploadingDocument?: boolean;
  uploadDocumentFailure?: boolean;
}

export interface ModifiedLookupValue {
  fromDateTime?: Date;
  toDateTime?: Date | undefined;
  actualValue?: string;
  forecastValue?: string;
}

const LookupValueEditorModal: FC<LookupValueEditorModalProps> = ({
  show,
  onHide,
  lookupValue,
  isSaving,
  isUploadingDocument,
  onSave,
  isGlobalPropertyKey,
  editMode,
  onUploadDocument,
  document,
  propertyKey,
  uploadDocumentFailure,
}): ReactElement => {
  const [modifiedLookupValues, setModifiedLookupValues] = useState<ModifiedLookupValue>(
    lookupValue
      ? {
          fromDateTime: new Date(lookupValue?.fromDateTime),
          toDateTime: lookupValue?.toDateTime ? new Date(lookupValue?.toDateTime) : undefined,
          actualValue: lookupValue.actualValue ? lookupValue.actualValue.toString() : '',
          forecastValue: lookupValue.forecastValue ? lookupValue.forecastValue.toString() : '',
        }
      : {}
  );
  const { taskType, taskId, taskSource } = useSelector(selectUnpublishedLookupTasks);
  const { currentWorkflowState } = useSelector(selectWorkflow);

  const [validDocument, setValidDocument] = useState<TaskV2Document | null>(null);
  const [doesLookupAlreadyExist, setDoesLookupAlreadyExist] = useState<boolean>(false);
  const [doesLookupAlreadyExistLoading, setDoesLookupAlreadyExistLoading] =
    useState<boolean>(false);

  const [documentStartPage, setDocumentStartPage] = useState<number | undefined>(undefined);
  const [documentEndPage, setDocumentEndPage] = useState<number | undefined>(undefined);

  const [inputTaskSource, setInputTaskSource] = useState<string>(taskSource || TaskSource.UI);

  useEffect(() => {
    if (document) {
      const updatedDocument = { ...document };
      updatedDocument.sourceUrl = '';
      setValidDocument(updatedDocument);
      setDocumentStartPage(updatedDocument?.sections[0]?.startPage);
      setDocumentEndPage(updatedDocument?.sections[0]?.endPage);
    }
  }, [document]);

  const isValid = () => {
    if (editMode || isGlobalPropertyKey) {
      return (
        modifiedLookupValues.fromDateTime &&
        modifiedLookupValues.actualValue &&
        (!modifiedLookupValues.toDateTime ||
          modifiedLookupValues.fromDateTime <= modifiedLookupValues.toDateTime)
      );
    } else {
      return (
        modifiedLookupValues.fromDateTime &&
        modifiedLookupValues.actualValue &&
        (taskType == TaskType.ADD_LOOKUP || taskType == TaskType.EDIT_LOOKUP) &&
        (!modifiedLookupValues.toDateTime ||
          modifiedLookupValues.fromDateTime <= modifiedLookupValues.toDateTime)
      );
    }
  };

  const getErrorMessage = () => {
    if (
      modifiedLookupValues.fromDateTime &&
      modifiedLookupValues.toDateTime &&
      modifiedLookupValues.fromDateTime > modifiedLookupValues.toDateTime
    ) {
      return 'From Date cannot be greater than to Date';
    } else if (doesLookupAlreadyExist) {
      return 'Lookup already exists with this From Date';
    }
    return '';
  };

  const saveLookup = async () => {
    if (taskType === TaskType.ADD_LOOKUP) {
      setDoesLookupAlreadyExistLoading(true);
      const fromDateTime =
        applyTimeZoneOffSet(modifiedLookupValues.fromDateTime)?.toISOString().slice(0, -5) || '';
      //Adding a minute to toDateTime to avoid the case where  toDateTime are empty which helps us to find if we have any existing lookups
      const toDateTime = modifiedLookupValues.toDateTime
        ? modifiedLookupValues.toDateTime.toISOString().slice(0, -5)
        : applyTimeZoneOffSet(
            new Date((modifiedLookupValues?.fromDateTime?.getTime() ?? 0) + 1 * 60000)
          )
            ?.toISOString()
            ?.slice(0, -5);

      const value = await isLookupAlreadyPresent(propertyKey, fromDateTime, toDateTime);
      setDoesLookupAlreadyExist(value);
      setDoesLookupAlreadyExistLoading(false);
      if (value == false) {
        onSave(modifiedLookupValues, inputTaskSource, documentStartPage, documentEndPage);
      }
    } else {
      onSave(modifiedLookupValues, inputTaskSource, documentStartPage, documentEndPage);
    }
  };

  return (
    <Modal size="large" aria-label="Modal" isOpen={show} className={styles.lookupEditor}>
      <Modal.Header
        onClose={onHide}
        title={
          taskType
            ? taskType === TaskType.ADD_LOOKUP
              ? `Add Lookup Value- ${taskId}`
              : `Edit Lookup Value- ${taskId}`
            : editMode
            ? 'Edit Lookup Value'
            : 'Add Lookup Value'
        }
      ></Modal.Header>
      <Modal.Content>
        <Container fluid>
          <Row>
            {!editMode && !isGlobalPropertyKey && !validDocument && (
              <Col xs={9}>
                <Row>
                  <Select
                    value={inputTaskSource}
                    label="Task Source"
                    disabled={taskId != null || taskId != undefined}
                    minHeight="90px"
                    loadingMessage="Loading..."
                    name="Task Source"
                    onChange={value => {
                      setInputTaskSource(value);
                    }}
                    options={Object.keys(TaskSource)
                      .filter(
                        key => TaskSource[key as keyof typeof TaskSource] !== TaskSource.SCRAPER
                      )
                      .map(key => ({
                        text: TaskSource[key as keyof typeof TaskSource],
                        value: TaskSource[key as keyof typeof TaskSource],
                      }))}
                  />
                </Row>

                <Row>
                  <FileUploadArea
                    accept={['application/pdf']}
                    onUploadFile={(file: File) =>
                      onUploadDocument
                        ? onUploadDocument(file, inputTaskSource)
                        : console.log('uploading')
                    }
                    uploadFailure={uploadDocumentFailure}
                    isUploading={isUploadingDocument}
                  />
                </Row>
              </Col>
            )}
            {(editMode || isGlobalPropertyKey || validDocument) && (
              <Col xs={9}>
                {validDocument ? (
                  <div className="text-center" style={{ height: '500px', width: '100%' }}>
                    <DocumentViewer
                      className="h-100"
                      document={validDocument}
                      documentSection={validDocument.sections[0]}
                    />
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center border h-100">
                    {isGlobalPropertyKey
                      ? 'Document is not required for global lookup'
                      : 'No document found'}
                  </div>
                )}
              </Col>
            )}
            <Col xs={3} className="border-left">
              <Row>
                <Col>
                  {(editMode || isGlobalPropertyKey || taskId) && (
                    <Select
                      value={inputTaskSource}
                      label="Task Source"
                      disabled={
                        (taskId != null || taskId != undefined) &&
                        !currentWorkflowState?.allowedActions.includes(
                          ActionType.UPDATE_TASK_METADATA
                        )
                      }
                      loadingMessage="Loading..."
                      name="Task Source"
                      minHeight="90px"
                      onChange={value => {
                        setInputTaskSource(value);
                      }}
                      options={Object.keys(TaskSource)
                        .filter(
                          key => TaskSource[key as keyof typeof TaskSource] !== TaskSource.SCRAPER
                        )
                        .map(key => ({
                          text: TaskSource[key as keyof typeof TaskSource],
                          value: TaskSource[key as keyof typeof TaskSource],
                        }))}
                    />
                  )}
                </Col>
              </Row>
              {((!editMode && !isGlobalPropertyKey) || document) && (
                <>
                  <Row className="mb-2">
                    <Col>
                      <Input
                        label="Document Start Page"
                        name="documentStartPage"
                        type="number"
                        inputMode="numeric"
                        onChange={page => setDocumentStartPage(parseInt(page))}
                        value={documentStartPage?.toString()}
                        disabled={
                          (editMode && taskId == null) ||
                          (taskId != null &&
                            !currentWorkflowState?.allowedActions?.includes(
                              ActionType.UPDATE_DOCUMENT_SECTION
                            )) ||
                          taskType === TaskType.EDIT_LOOKUP
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <Input
                        label="Document End Page"
                        name="document End Page"
                        type="number"
                        inputMode="numeric"
                        onChange={page => setDocumentEndPage(parseInt(page))}
                        value={documentEndPage?.toString()}
                        disabled={
                          (editMode && taskId == null) ||
                          (taskId != null &&
                            !currentWorkflowState?.allowedActions?.includes(
                              ActionType.UPDATE_DOCUMENT_SECTION
                            ))
                        }
                      />
                    </Col>
                  </Row>
                </>
              )}
              {editMode && lookupValue?.lookupId && (
                <Row className="mb-2">
                  <Col>
                    <Input
                      label="Lookup ID"
                      name="lookupId"
                      type="number"
                      inputMode="numeric"
                      onChange={() => console.log('Cannot modify lookupID')}
                      value={lookupValue.lookupId ? lookupValue.lookupId.toString() : ''}
                      disabled
                    />
                  </Col>
                </Row>
              )}

              <Row className="mb-2">
                <Col>
                  <DatePicker
                    value={modifiedLookupValues.fromDateTime}
                    className="Fromdate-time-picker"
                    label="From Date"
                    handleChange={date =>
                      setModifiedLookupValues(previousState => ({
                        ...previousState,
                        fromDateTime: date ? date : undefined,
                      }))
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <DatePicker
                    value={modifiedLookupValues.toDateTime}
                    className="Todate-time-picker"
                    label="To Date"
                    reactDatePickerProps={{
                      disabled: (editMode && !taskType) || taskType === TaskType.EDIT_LOOKUP,
                    }}
                    handleChange={date =>
                      setModifiedLookupValues(previousState => ({
                        ...previousState,
                        toDateTime: date ? date : undefined,
                      }))
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <Input
                    label="Actual Value"
                    name="actualValue"
                    type="number"
                    inputMode="decimal"
                    onChange={value =>
                      setModifiedLookupValues(previousState => ({
                        ...previousState,
                        actualValue: value,
                      }))
                    }
                    value={modifiedLookupValues.actualValue}
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <Input
                    label="Forecast Value"
                    name="forecastValue"
                    type="number"
                    inputMode="decimal"
                    onChange={value =>
                      setModifiedLookupValues(previousState => ({
                        ...previousState,
                        forecastValue: value,
                      }))
                    }
                    value={modifiedLookupValues.forecastValue}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Modal.Content>
      <Modal.Footer
        onSubmit={() => saveLookup()}
        error={getErrorMessage()}
        loading={isSaving || doesLookupAlreadyExistLoading}
        primaryText="Save"
        disabled={!isValid()}
      ></Modal.Footer>
    </Modal>
  );
};

export default LookupValueEditorModal;
