import React, { useEffect, useState } from 'react';
import { Icon, Table } from '@arcadiapower/gen-react-lib';
import { Badge, Button as BSButton } from 'react-bootstrap';
import {
  Operation,
  ChangeOperation,
  NestedOperation,
  ResourceDiffSection,
} from '../../task-api/types/resource-diff';
import ResourceDiffPatches from '../ResourceDiffPatches/ResourceDiffPatches';
import styles from './ResourceDiffSummary.module.scss';

export interface ResourceDiffSummaryProps {
  summary: ResourceDiffSection;
  patch: (Operation | ChangeOperation<unknown> | NestedOperation)[];
  columnClassNames: string[];
  expanded?: boolean;
}

/**
 * ResourceDiffSummary is a collapsible that shows the summary and diffpatches.
 */
const ResourceDiffSummary: React.FC<ResourceDiffSummaryProps> = ({
  summary,
  patch,
  columnClassNames,
  expanded,
}: ResourceDiffSummaryProps): React.ReactElement => {
  // Initially all sections start collapsed
  const [isExpanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(expanded || false);
  }, [expanded]);

  return (
    <div>
      <Table className={styles.summaryHeaderTable}>
        <colgroup>
          {columnClassNames.map((columnClassName, index) => (
            <col key={index} className={columnClassName} />
          ))}
        </colgroup>
        <tbody>
          <tr>
            <td className={styles.sectionNameColumn}>
              <BSButton
                onClick={() => {
                  setExpanded(prevState => !prevState);
                }}
                variant="light"
                value={summary.section}
                className={styles.toggleSection}
              >
                <Icon
                  iconName={isExpanded ? 'chevronDown' : 'chevronRight'}
                  className={styles.chevronIcon}
                />
                {summary.section}
              </BSButton>
            </td>
            <td>{summary.count}</td>
            <td>{summary.unchange ? <Badge variant="warning">{summary.unchange}</Badge> : '-'}</td>
            <td>{summary.change ? <Badge variant="success">{summary.change}</Badge> : '-'}</td>
            <td>{summary.add ? <Badge variant="warning">{summary.add}</Badge> : '-'}</td>
            <td>{summary.remove ? <Badge variant="warning">{summary.remove}</Badge> : '-'}</td>
          </tr>
        </tbody>
      </Table>
      {isExpanded && (
        <ResourceDiffPatches
          title=""
          resourceDiff={{ summary: [summary], patch, diffMethod: '' }}
        />
      )}
    </div>
  );
};

export default ResourceDiffSummary;
