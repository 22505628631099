import React from 'react';
import { TaskAssignment } from '../../../task-api/types/task';
import { Table } from '@arcadiapower/gen-react-lib';

interface do02WorkBodyParams {
  taskAssignment: TaskAssignment | undefined;
}

function Do02WorkBody({ taskAssignment }: do02WorkBodyParams): React.ReactElement {
  return (
    <>
      <div>
        <div>
          Work Body for Task Type{' '}
          {taskAssignment && taskAssignment.task && taskAssignment.task.taskType.taskTypeId} for{' '}
          {taskAssignment && taskAssignment.task && taskAssignment.task?.lseName} has not been
          implemented yet
        </div>
        <div>Task Assignments</div>
        {taskAssignment && (
          <Table hover>
            <thead>
              <tr>
                <th>Task Assignment ID</th>
                <th>AssignmentStatus</th>
                <th>Created On</th>
                <th>Last Update</th>
              </tr>
            </thead>
            <tbody>
              {
                <tr key={taskAssignment.taskAssignmentId}>
                  <td>{taskAssignment.taskAssignmentId}</td>
                  <td>{taskAssignment.assignmentStatus}</td>
                  <td>{taskAssignment.createdDate}</td>
                  <td>{taskAssignment.lastUpdatedDate}</td>
                </tr>
              }
            </tbody>
          </Table>
        )}
      </div>
    </>
  );
}

export default Do02WorkBody;
