import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { types, TariffRateFactory, restClient } from '@genability/api';
import { ConfirmModal } from '@arcadiapower/gen-react-lib';
import xml2js from 'xml2js';
import useTariffAnswer, { TariffRateGroup } from '../../../utils/useTariffAnswer';
import { useAppDispatch } from '../../../state/store';
import {
  selectTaskAssignmentTariff,
  updateTaskAssignmentTariff,
} from '../../../state/taskAssignmentTariffs/taskAssignmentTariffsSlice';
import PanelNavigationButtons from '../PanelNavigationButtons/PanelNavigationButtons';
import RateGroupSection from '../../RateGroupSection/RateGroupSection';
import EditTariffRateForm from '../../EditTariffRateForm/EditTariffRateForm';
import RateCard from '../../RateCard/RateCard';
import { DoStepPanelProps } from '../work-body';
import styles from './RatesPanel.module.scss';
import {
  fetchDQCheck,
  isDQCheckOverride,
  resetDQCheckOverride,
  selectDQCheckErrors,
  selectDQCheckOverrides,
} from '../../../state/dqCheck/dqCheckSlice';
import { Crate, Text } from '@arcadiapower/shrike';
import { Col, Row } from 'react-bootstrap';
import {
  UpdateTaskAssignmentAnswerArgs,
  selectTaskAssignment,
  selectTaskAssignmentAnswerByField,
  updateTaskAssignmentAnswer,
  selectTaskInputs,
} from '../../../state/taskAssignment/taskAssignmentSlice';
import { TariffRate } from '@genability/api/dist/types';
import {
  TaskAssignmentAnswerField,
  TaskInputFeld,
  TaskAssignmentAnswer,
  TaskTypeId,
  TaskTariff,
} from '../../../task-api/types/task';
import { AiRateInfo } from '../../../task-api/v2/types/AiRateInfo';
import { AI_INPUT_MODE_VALUES } from '../../../utils/constants';
import { Prompt } from 'react-router';

export interface rateGroup {
  groupName: string;
  isNew?: boolean;
  rates: types.TariffRate[];
  offset?: number;
}

const RatesPanel: React.FC<DoStepPanelProps> = ({
  taskAssignment,
  onPrevious,
  onNext,
}: DoStepPanelProps) => {
  const cleanTariff: TaskTariff | undefined = useSelector(selectTaskAssignmentTariff);
  if (!cleanTariff) return null;
  const {
    tariffAnswer,
    groupsAnswer,
    addRate,
    moveRate,
    setRate,
    deleteRate,
    addRateGroup,
    updateRateGroupName,
    moveRateGroup,
    deleteRateGroup,
    resetAnswer,
    persistReorderedTariff,
    setPersistReorderedTariff,
  } = useTariffAnswer(cleanTariff);
  const [selectedTariffRateId, setSelectedTariffRateId] = useState<number | undefined>(undefined);
  const [isAddRate, setIsAddRate] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [showAiBanner, setShowAiBanner] = useState(false);
  const [saveAnswer, setSaveAnswer] = useState(false);
  const [nextSpin, setNextSpin] = useState(false);
  const [noChangesChecked, setNoChangesChecked] = useState(false);
  const [apiErrors, setApiErrors] = useState<restClient.ResponseError[]>([]);
  const dqCheckErrors = useSelector(selectDQCheckErrors);
  const dqOverrides = useSelector(selectDQCheckOverrides);
  const aiStagedEntity = useSelector(
    selectTaskAssignmentAnswerByField(TaskAssignmentAnswerField.AI_STAGED_ENTITY)
  );
  const aiSavedAppliedValues = useSelector(
    selectTaskAssignmentAnswerByField(TaskAssignmentAnswerField.APPLIED_AI_VALUES)
  );
  const taskInputs = useSelector(selectTaskInputs);
  const [aiAppliedValues, setAiAppliedValues] = useState<TaskAssignmentAnswer>();
  const dispatch = useAppDispatch();
  const [aiRatesAvailable, setAiRatesAvailable] = useState(false);
  const [aiRates, setAiRates] = useState<TariffRate[]>([]);
  const [appliedAiRates, setAppliedAiRates] = useState<AiRateInfo[]>([]);

  useEffect(() => {
    const aiModeObject = taskInputs.find(input => input.inputKey === TaskInputFeld.AI_MODE);

    if (
      aiRatesAvailable ||
      (aiModeObject &&
        (aiModeObject['inputValue'] == AI_INPUT_MODE_VALUES.COPILOT_MODE ||
          aiModeObject['inputValue'] == AI_INPUT_MODE_VALUES.AUTO_AUTHOR_MODE))
    ) {
      setShowAiBanner(true);
    } else {
      setShowAiBanner(false);
    }
  }, [taskInputs, aiRatesAvailable]);

  useEffect(() => {
    if (aiSavedAppliedValues) {
      setAiAppliedValues(aiSavedAppliedValues);
    }
  }, [aiSavedAppliedValues]);

  useEffect(() => {
    if (aiStagedEntity) {
      const aiRateInfos: AiRateInfo[] | undefined = aiAppliedValues?.answerValue
        ? JSON.parse(aiAppliedValues.answerValue)
        : undefined;

      if (aiRateInfos) {
        setAppliedAiRates(aiRateInfos);
      }

      const parser = new xml2js.Parser({ explicitArray: false });
      parser.parseString(aiStagedEntity.answerValue, (err, result) => {
        if (err) {
          console.error('Error parsing XML:', err);
          setAiRates([]);
          setAiRatesAvailable(false);
        } else {
          setAiRates(result?.tariff?.rates?.tariffRate);
          setAiRatesAvailable(true);
          analytics.track('ai_rates_available', {
            taskAssignmentId: taskAssignment.taskAssignmentId,
          });
        }
        if (taskAssignment.taskTypeId != TaskTypeId['03_03']) setAiRatesAvailable(false);
      });
    }
  }, [aiStagedEntity, aiAppliedValues]);

  useEffect(() => {
    if (cleanTariff) {
      resetAnswer(cleanTariff);
    }
  }, [cleanTariff]);

  useEffect(() => {
    if (saveAnswer) {
      save(false);
    }
  }, [saveAnswer]);

  useEffect(() => {
    if (persistReorderedTariff && tariffAnswer) {
      save(false, true);
      setPersistReorderedTariff(false);
    }
  }, [tariffAnswer]);

  useEffect(() => {
    dispatch(fetchDQCheck(taskAssignment.taskAssignmentId));
  }, []);

  const onAddRateGroup = (groupIndex: number) => {
    addRateGroup(groupIndex);
    setIsDirty(true);
  };

  const onUpdateRateGroupName = (groupIndex: number, name: string) => {
    updateRateGroupName(groupIndex, name);
    setIsDirty(true);
  };

  const onMoveRateGroupName = (fromGroupIndex: number, toGroupIndex: number) => {
    moveRateGroup(fromGroupIndex, toGroupIndex);
    setIsDirty(true);
  };

  const onDeleteRateGroup = async (rateGroupIndex: number) => {
    const result = await ConfirmModal.show({
      title: 'Delete Rate Group',
      question: 'Are you sure you want to delete this rate group and all its rates?',
    });
    if (result) {
      deleteRateGroup(rateGroupIndex);
      setIsDirty(true);
      return true;
    } else {
      return false;
    }
  };

  const onAddTariffRate = (rate: types.TariffRate, tariffSequenceNumber: number) => {
    const addedTariffRateId = addRate(rate, tariffSequenceNumber);
    setSelectedTariffRateId(addedTariffRateId);
    setIsAddRate(true);
    setIsDirty(true);
  };

  const onDeleteTariffRate = async (tariffRateId: number | null) => {
    if (!tariffRateId) {
      return;
    }
    const result = await ConfirmModal.show({
      title: 'Delete Rate',
      question: 'Are you sure you want to delete this rate?',
    });
    if (result) {
      deleteRate(tariffRateId);
      setIsDirty(true);
    }
  };

  const onCopyRate = (tariffRate: types.TariffRate, tariffSequenceNumber: number | undefined) => {
    const newRate: types.TariffRate | null = TariffRateFactory.copyRate(tariffRate);
    if (newRate) {
      const addedTariffRateId = addRate(newRate, tariffSequenceNumber);
      setSelectedTariffRateId(addedTariffRateId);
      setIsAddRate(true);
      setIsDirty(true);
    }
  };

  const onMoveRate = (group: TariffRateGroup, tariffRateId: number, position: string) => {
    position === 'topOfGroup' ? moveRate(tariffRateId, group.startSequenceNumber) : null;
    position === 'bottomOfGroup' ? moveRate(tariffRateId, group.endSequenceNumber) : null;
    if (['positionAbove', 'positionBelow'].includes(position)) {
      const rateToMove = tariffAnswer.rates?.find(item => {
        return item.tariffRateId === tariffRateId;
      });
      if (
        rateToMove &&
        rateToMove.tariffSequenceNumber &&
        group.startSequenceNumber != rateToMove.tariffSequenceNumber &&
        position === 'positionAbove'
      ) {
        moveRate(tariffRateId, rateToMove.tariffSequenceNumber - 1);
      }
      if (
        rateToMove &&
        rateToMove.tariffSequenceNumber &&
        group.endSequenceNumber != rateToMove.tariffSequenceNumber &&
        position === 'positionBelow'
      ) {
        moveRate(tariffRateId, rateToMove.tariffSequenceNumber + 1);
      }
    }
    if (position === 'groupAbove') {
      const groupAbove = groupsAnswer.find(gr => {
        if (gr.groupIndex === group.groupIndex - 1) {
          return gr;
        }
      });
      groupAbove
        ? moveRate(tariffRateId, groupAbove.endSequenceNumber, groupAbove.groupName)
        : null;
    }
    if (position === 'groupBelow') {
      const groupBelow = groupsAnswer.find(gr => {
        if (gr.groupIndex === group.groupIndex + 1) {
          return gr;
        }
      });
      groupBelow
        ? moveRate(tariffRateId, groupBelow.startSequenceNumber, groupBelow.groupName)
        : null;
    }
    setIsDirty(true);
  };

  const onSubmitEditRate = (newRate: types.TariffRate) => {
    setRate(newRate);
    setSaveAnswer(true);
  };
  const onApplyAiRate = (newRate: types.TariffRate, answerValue: string | null) => {
    setRate(newRate);
    setSaveAnswer(true);
    setIsDirty(true);
    if (!answerValue) {
      return;
    }
    if (aiAppliedValues) {
      const TAA = { ...aiAppliedValues };
      TAA.answerValue = answerValue;
      setAiAppliedValues(TAA);
    } else {
      const TAA: TaskAssignmentAnswer = {
        taskAssignmentId: taskAssignment.taskAssignmentId,
        answerField: TaskAssignmentAnswerField.APPLIED_AI_VALUES,
        answerValue: answerValue,
        taskAnswerId: -1,
        answerDiff: null,
        createdBy: null,
        createdDate: null,
        lastUpdatedBy: null,
        lastUpdatedDate: null,
      };
      setAiAppliedValues(TAA);
    }
  };

  const onCancelEditRate = (tariffRateId: number) => {
    if (isAddRate) {
      deleteRate(tariffRateId);
      setIsAddRate(false);
    }
    setSelectedTariffRateId(undefined);
    setApiErrors([]);
  };

  const resetChanges = () => {
    if (cleanTariff) {
      resetAnswer(cleanTariff);
    }
    setIsDirty(false);
    setIsAddRate(false);
    setSelectedTariffRateId(undefined);
    setApiErrors([]);
  };

  const saveAndNext = () => {
    save(true);
  };

  const handleNoChanges = (checked: boolean) => {
    setNoChangesChecked(checked);
  };

  /**
   * Filter dqCheck errors By rateIndex. If it's null, it checks any error related with Rates.
   */
  const filterErrorsByRateIndex = (
    dqCheckErrors: restClient.ResponseError[],
    rateIndex: number | null
  ) => {
    const { rates } = tariffAnswer;
    // Condition to skip riders at RatesPanel
    const isRate =
      rateIndex === null ||
      (rateIndex !== null &&
        rates &&
        rates.length > rateIndex &&
        rates[rateIndex] &&
        !rates[rateIndex].riderId);
    const rateCriteria = rateIndex === null ? 'rates.' : `rates.${rateIndex}.`;
    return dqCheckErrors
      .filter(
        error =>
          error.propertyName?.startsWith(rateCriteria) &&
          isRate &&
          !isDQCheckOverride(error, dqOverrides)
      )
      .map(error => ({
        ...error,
        propertyName: error.propertyName?.replace(rateCriteria, ''),
      }));
  };

  const save = async (andNext: boolean, forceSave = false) => {
    try {
      setNextSpin(true);
      setSaveAnswer(false);

      if (!andNext && !forceSave) {
        resetAnswer(tariffAnswer);
        setSelectedTariffRateId(undefined);
        setNextSpin(false);
        return;
      }

      if (aiAppliedValues) {
        const updatedTAA: UpdateTaskAssignmentAnswerArgs = {
          taskAssignmentId: taskAssignment.taskAssignmentId,
          taskAssignmentAnswer: {
            answerField: TaskAssignmentAnswerField.APPLIED_AI_VALUES,
            answerValue: aiAppliedValues.answerValue,
          },
        };
        dispatch(updateTaskAssignmentAnswer(updatedTAA));
      }

      const { result, errors } = await dispatch(
        updateTaskAssignmentTariff({
          taskAssignmentId: taskAssignment.taskAssignmentId,
          tariff: tariffAnswer,
        })
      ).unwrap();
      if (errors) {
        setApiErrors(errors);
      } else if (result) {
        const dqCheck = await dispatch(fetchDQCheck(taskAssignment.taskAssignmentId)).unwrap();

        const selectedIndex = tariffAnswer.rates?.findIndex(
          tariffRate => selectedTariffRateId === tariffRate.tariffRateId
        );
        const isErrorsInRatePanel =
          dqCheck.errors && filterErrorsByRateIndex(dqCheck.errors, null).length > 0;
        const isErrorsInSelectedRate =
          selectedIndex !== undefined &&
          dqCheck.errors &&
          filterErrorsByRateIndex(dqCheck.errors, selectedIndex).length > 0;

        if (!isErrorsInSelectedRate || !isErrorsInRatePanel) {
          resetAnswer(result);
          setIsAddRate(false);
          setSelectedTariffRateId(undefined);
          setApiErrors([]);
        }
      }

      if (andNext) {
        setIsDirty(false);
        onNext();
      }
    } catch (e: any) {
      console.error(`Error saving Tariff: message: ${e.message} - stack: ${e.stack}`);
      throw e;
    }
    setNextSpin(false);
  };

  useEffect(() => {
    const dqCheckRateErrors = dqCheckErrors.filter(error =>
      error.propertyName?.startsWith('rates.')
    );
    dqCheckRateErrors.forEach(({ propertyName }) => {
      const isOverrideDirty = dqOverrides.find(
        override => override.propertyName === propertyName && override.isDirty
      );
      if (isOverrideDirty) {
        setIsDirty(true);
        dispatch(resetDQCheckOverride(propertyName));
      }
    });
    setApiErrors(dqCheckRateErrors.filter(error => !isDQCheckOverride(error, dqOverrides)));
  }, [dqCheckErrors, dqOverrides]);

  return (
    <React.Fragment>
      <Row className="mb-4">
        <Col xs={2}>
          <h2 id="rates-header">Rates</h2>
        </Col>
        {showAiBanner && (
          <Col xs={9} className="ml-3">
            <Crate
              as="div"
              backgroundColor="accent7"
              borderRadius="8px"
              className="mt-1"
              display="block"
              height="36px"
              margin="0px"
              overflow="auto"
              position="relative"
              width="100%"
            >
              <Text
                color="errorBright"
                opacity="high"
                tag="h3"
                textStyle="heading500"
                className="mt-1 pt-1 d-flex justify-content-center"
              >
                AI generated value would be highlighted in green and current value in black
              </Text>
            </Crate>
          </Col>
        )}
      </Row>

      {groupsAnswer.map((group: TariffRateGroup, groupIndex: number) => {
        return (
          <RateGroupSection
            key={`${groupIndex}-${group.groupName}`}
            rateGroup={group}
            onUpdateGroupName={onUpdateRateGroupName}
            onDeleteGroup={onDeleteRateGroup}
            onAddRate={onAddTariffRate}
            onAddRateGroup={onAddRateGroup}
            disabled={selectedTariffRateId !== undefined ? true : false}
            onMoveGroupDown={
              groupIndex !== groupsAnswer.length - 1
                ? () => onMoveRateGroupName(group.groupIndex, group.groupIndex + 1)
                : undefined
            }
            onMoveGroupUp={
              groupIndex !== 0
                ? () => onMoveRateGroupName(group.groupIndex, group.groupIndex - 1)
                : undefined
            }
          >
            <div className={styles.cardList}>
              {group.hasRates &&
                tariffAnswer.rates?.map((tariffRate, index) => {
                  if (
                    (tariffRate.tariffSequenceNumber as number) >= group.startSequenceNumber &&
                    (tariffRate.tariffSequenceNumber as number) <= group.endSequenceNumber
                  ) {
                    if (
                      selectedTariffRateId !== undefined &&
                      selectedTariffRateId === tariffRate.tariffRateId
                    ) {
                      return (
                        <EditTariffRateForm
                          key={`${tariffRate.tariffRateId}-edit`}
                          taskAssignmentId={taskAssignment.taskAssignmentId}
                          tariffRate={tariffRate}
                          lseId={tariffAnswer.lseId}
                          onSubmit={onSubmitEditRate}
                          className={styles.cardItem}
                          onCancel={onCancelEditRate}
                          currency={tariffAnswer.currency}
                          apiErrors={filterErrorsByRateIndex(apiErrors, index)}
                          setIsRateDirty={setIsDirty}
                          rateIndex={index}
                        />
                      );
                    } else {
                      return (
                        <RateCard
                          taskAssignmentId={taskAssignment.taskAssignmentId}
                          aiRatesAvailable={aiRatesAvailable}
                          appliedAiRates={appliedAiRates}
                          aiTariffRates={aiRates}
                          key={tariffRate.tariffRateId}
                          tariffRateId={tariffRate.tariffRateId as number}
                          tariffRate={tariffRate}
                          currency={tariffAnswer.currency}
                          className={styles.cardItem}
                          onDeleteRate={onDeleteTariffRate}
                          onEditRate={setSelectedTariffRateId}
                          onCopyRate={onCopyRate}
                          onApplyAiRate={onApplyAiRate}
                          onMoveRate={(tariffRateId, position) =>
                            onMoveRate(group, tariffRateId, position)
                          }
                          fromRider={!!tariffRate.riderId}
                          disabled={selectedTariffRateId !== undefined}
                          apiErrors={filterErrorsByRateIndex(apiErrors, index)}
                          rateIndex={index}
                          appliedAiAnswerId={aiAppliedValues?.taskAnswerId}
                        />
                      );
                    }
                  }
                })}
            </div>
          </RateGroupSection>
        );
      })}
      <Prompt
        when={isDirty}
        message="You have unsaved changes. Are you sure you want to leave this page ?"
      />
      <PanelNavigationButtons
        onClickPrevious={onPrevious}
        onClickReset={resetChanges}
        onClickNext={saveAndNext}
        nextLabel={'Save & Next'}
        nextSpin={nextSpin}
        dirty={isDirty}
        disabled={selectedTariffRateId !== undefined}
        noChangesChecked={noChangesChecked}
        onClickNoChanges={handleNoChanges}
      />
    </React.Fragment>
  );
};

export default RatesPanel;
