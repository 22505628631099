export const updateRateEditAnalytics = (
  updateType: string,
  assignmentId?: number | null,
  rateBandId?: number | null,
  rateName?: string | null
) => {
  analytics.track(updateType, {
    taskAssignmentId: assignmentId,
    rateBandId: rateBandId,
    rateName: rateName,
  });
};
