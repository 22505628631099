import { ResourceDiff } from './resource-diff';
import { types } from '@genability/api';

export enum TaskTypeId {
  '02_01' = '02_01',
  '02_03' = '02_03',
  '03_02' = '03_02',
  '03_03' = '03_03',
  '03_04' = '03_04',
}

export enum TaskStatus {
  ASSIGNED = 'ASSIGNED',
  DRAFTING = 'DRAFTING',
  READY_TO_POST = 'READY_TO_POST',
  POSTED = 'POSTED',
  READY_TO_REVIEW = 'READY_TO_REVIEW',
  REVIEWING = 'REVIEWING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PUBLISHED = 'PUBLISHED',
  CLOSED = 'CLOSED',
}

export enum TaskAssignmentStatus {
  POSTED = 'POSTED',
  ASSIGNED = 'ASSIGNED',
  READY_TO_REVIEW = 'READY_TO_REVIEW',
  REVIEWING = 'REVIEWING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum TaskAssignmentAnswerField {
  PUBLISHED_TARIFF_REQUEST_ID = 'publishedTariffRequestId',
  STAGED_TARIFF_REQUEST_ID = 'stagedTariffRequestId',
  NOT_TARIFF_DOCUMENT = 'notATariffDocument',
  MISSING_RIDER = 'missingRider',
  APPLIED_AI_VALUES = 'appliedAiValues',
  AI_STAGED_ENTITY = 'aiStagedEntity',
}

export enum TaskInputFeld {
  AI_MODE = 'aiMode',
}

export interface Task {
  taskId: number;
  taskTitle: string;
  taskType: TaskType;
  previousTaskId: number | null;
  entityId: number;
  publishedEntityId: number | null;
  publishedEntityEditId: number | null;
  lseId: number;
  lseCode: string;
  lseName: string;
  taskStatus: TaskStatus;
  postedDate: string | null;
  reviewedDate: string | null;
  publishedDate: string | null;
  completedDate: string | null;
  taskInputs: TaskInput[] | null;
  requiredPlurality: number;
  comments: TaskComment | null;
  assignments: TaskAssignment[] | null;
  createdBy: number;
  createdDate: string;
  lastUpdatedBy: number;
  lastUpdatedDate: string;
}

export interface TaskType {
  taskTypeId: TaskTypeId | undefined;
  taskTypeName: string | null;
  description: string | null;
}

export interface TaskAssignment {
  taskAssignmentId: number;
  workerId: number | null;
  reviewerId?: number | null;
  assignmentStatus: TaskAssignmentStatus;
  answers?: TaskAssignmentAnswer[];
  taskId?: number;
  taskTypeId?: TaskTypeId;
  comments?: TaskComment[] | null;
  createdBy: number;
  createdDate: string;
  lastUpdatedBy: number;
  lastUpdatedDate: string | undefined;
  worker?: string;
  task?: Task;
}

export interface Author {
  orgId: string | null;
  name: string | null;
  refId: string | null;
  refType: string | null;
}

export interface TaskTariff extends types.Tariff {
  author: Author | null;
}

export interface TaskAssignmentAnswer {
  taskAssignmentId?: number | null;
  taskAnswerId: number;
  answerField: string;
  answerValue: string;
  answerDiff: ResourceDiff | null;
  createdBy: number | null;
  createdDate: string | null;
  lastUpdatedBy: number | null;
  lastUpdatedDate: string | null;
}

export interface TaskInput {
  taskInputId: number;
  inputSequence: number;
  inputKey: string;
  inputValue: string;
  createdBy: number;
  createdDate: string;
  lastUpdatedBy: number;
  lastUpdatedDate: string;
}

export interface TaskComment {
  taskCommentId: number;
  taskId: number;
  taskAssignmentId?: number;
  taskAnswerId?: number;
  fromUserId: number;
  fromUser: string;
  comment: string;
  createdBy: number;
  createdDate: string;
  lastUpdatedBy: number;
  lastUpdatedDate: string;
}

export interface DQCheck {
  rule: string;
  answerField: string;
  pass: boolean;
}

export function isTaskAssignmentAnswer(arg: TaskAssignmentAnswer): arg is TaskAssignmentAnswer {
  return (
    arg.answerField !== undefined && arg.taskAnswerId !== undefined && arg.answerValue !== undefined
  );
}

export function isTaskAssignment(arg: TaskAssignment): arg is TaskAssignment {
  return (
    arg.workerId !== undefined &&
    arg.assignmentStatus !== undefined &&
    arg.createdDate !== undefined &&
    arg.createdBy !== undefined &&
    arg.lastUpdatedBy !== undefined &&
    arg.lastUpdatedDate !== undefined
  );
}

export function isTask(arg: Task): arg is Task {
  return (
    arg.taskId !== undefined &&
    arg.taskTitle !== undefined &&
    arg.taskType !== undefined &&
    arg.previousTaskId !== undefined &&
    arg.entityId !== undefined &&
    arg.publishedEntityId !== undefined &&
    arg.publishedEntityEditId !== undefined &&
    arg.lseId !== undefined &&
    arg.taskStatus !== undefined &&
    arg.postedDate !== undefined &&
    arg.reviewedDate !== undefined &&
    arg.publishedDate !== undefined &&
    arg.completedDate !== undefined &&
    arg.taskInputs !== undefined &&
    arg.requiredPlurality !== undefined &&
    arg.comments !== undefined &&
    arg.assignments !== undefined &&
    arg.createdBy !== undefined &&
    arg.createdDate !== undefined &&
    arg.lastUpdatedBy !== undefined &&
    arg.lastUpdatedDate !== undefined
  );
}

export function isTaskComment(arg: TaskComment): arg is TaskComment {
  return (
    arg.taskCommentId !== undefined &&
    arg.taskId !== undefined &&
    arg.fromUserId !== undefined &&
    arg.fromUser !== undefined &&
    arg.comment !== undefined &&
    arg.createdDate !== undefined &&
    arg.createdBy !== undefined &&
    arg.lastUpdatedBy !== undefined &&
    arg.lastUpdatedDate !== undefined
  );
}

export const TIER1_LSE_IDS = [
  1228, 1071, 1535, 2252, 200, 913, 210, 2250, 2655, 3048, 2288, 2042, 3010, 734, 531, 2437, 2885,
  1578, 2241, 310, 918, 3111, 915, 919, 799, 2654,
];
