import { restClient } from '@genability/api';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TaskApiClient } from '../../GenApiClient';
import { RootState } from '../rootReducer';
import { DQCheck } from '../../task-api/types/task';

interface DQCheckOverride {
  message: string;
  objectName: string;
  propertyName: string;
  reason: string;
  isDirty?: boolean;
}

interface DQCheckOverrideSave {
  dqOverride: DQCheckOverride;
}

export interface DQCheckState {
  isLoading: boolean;
  status: string;
  errors: restClient.ResponseError[] | undefined;
  overrides: DQCheckOverride[];
}

interface DQCheckSuccess {
  status: string;
  results: DQCheck[];
  errors: restClient.ResponseError[] | undefined;
}

export const initialState: DQCheckState = {
  isLoading: false,
  status: '',
  errors: undefined,
  overrides: [],
};

function startLoading(state: DQCheckState) {
  state.isLoading = true;
}

function loadingFailed(state: DQCheckState) {
  state.isLoading = false;
  state.status = 'error';
}

export const dQCheckSlice = createSlice({
  name: 'dQCheck',
  initialState,
  reducers: {
    getDQCheckStart: startLoading,
    getDQCheckSuccess(state, { payload }: PayloadAction<DQCheckSuccess>) {
      const { errors, status } = payload;
      state.isLoading = false;
      state.status = status;
      state.errors = errors;
    },
    getDQCheckFailure: loadingFailed,
    addDQCheckOverride(state: DQCheckState, { payload }: PayloadAction<DQCheckOverrideSave>) {
      state.overrides = [...state.overrides, { ...payload.dqOverride, isDirty: true }];
    },
    clearDQCheckOverrides(state: DQCheckState) {
      state.overrides = [];
    },
    resetDQCheckOverride(state: DQCheckState, { payload }: PayloadAction<string | undefined>) {
      state.overrides = state.overrides.map(override => {
        if (override.propertyName === payload) {
          return { ...override, isDirty: false };
        }
        return override;
      });
    },
  },
});

export const {
  getDQCheckStart,
  getDQCheckSuccess,
  getDQCheckFailure,
  addDQCheckOverride,
  clearDQCheckOverrides,
  resetDQCheckOverride,
} = dQCheckSlice.actions;

export default dQCheckSlice.reducer;

const EXCLUDED_ERRORS = new Set(
  ['has_tou_rate should not be flagged'].map(message => message.toLowerCase())
);

export const fetchDQCheck = createAsyncThunk(
  'dqCheck/fetchDQCheck',
  async (taskAssignmentId: number, { dispatch }): Promise<restClient.PagedResponse<DQCheck>> => {
    dispatch(getDQCheckStart());

    const client = await TaskApiClient();
    const response: restClient.PagedResponse<DQCheck> = await client.taskAssignment.getDQCheck(
      taskAssignmentId
    );

    const errors = response.errors?.filter(
      error => !EXCLUDED_ERRORS.has(error.message?.toLowerCase())
    );
    const dqCheckSuccess: DQCheckSuccess = {
      status: response.status,
      results: response.results,
      errors,
    };
    dispatch(getDQCheckSuccess(dqCheckSuccess));

    return { ...response };
  }
);

export const storeDQOverride = createAsyncThunk(
  'dqCheck/storeDQOverride',
  async (dqOverride: DQCheckOverride, { dispatch }) => {
    dispatch(addDQCheckOverride({ dqOverride }));
  }
);

export const selectDQCheckErrors = (state: RootState): restClient.ResponseError[] => {
  return state.dQCheck.errors || [];
};

export const selectDQCheckOverrides = (state: RootState): DQCheckOverride[] => {
  return state.dQCheck.overrides || [];
};

export const isDQCheckOverride = (
  error: restClient.ResponseError,
  overrides: DQCheckOverride[]
): boolean =>
  overrides.some(
    override =>
      (error.propertyName !== 'propertyValue' && override.propertyName === error.propertyName) ||
      (error.propertyName === 'propertyValue' && override.message === error.message)
  );
