import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';
import { TaskStatusType } from '../../task-api/v2/types/TaskStatusType';
import { TaskType } from '../../task-api/v2/types/TaskType';
import { TaskSource } from '../../task-api/v2/types/TaskSource';

export type FilterType = 'statusFilter' | 'tariffTaskType' | 'taskSource';

export interface DashboardFilterState {
  statusFilter: FilterObject<TaskStatusType>;
  tariffTaskType: FilterObject<TaskType>;
  taskSource: FilterObject<TaskSource>;
  searchText: string;
  selectedStatusFilters: string[];
  selectedTaskTypes: string[];
  selectedTaskSource: string[];
  applyFilters: boolean;
  searchDate?: string[];
  appliedFilterState: ApplyFilterData;
}

export interface FilterObject<T> {
  [key: string]: {
    isEnabled: boolean;
    isChecked: boolean;
  };
}
type ApplyFilterData = {
  searchTextBuffer: string;
  taskStatusBuffer: string[];
  taskSourceBuffer: string[];
  taskTypeBuffer: string[];
  searchDate?: string[];
  applyFilter: boolean;
};
type ToggleType = {
  filter: string;
  filterType: FilterType;
};

const createInitialFilter = <T>(enumObj: Record<string, T>): FilterObject<T> => {
  const initialFilter: FilterObject<T> = {};
  Object.values(enumObj).forEach((value: T) => {
    const key = value as keyof typeof enumObj;
    initialFilter[key] = {
      isEnabled: key === 'CLOSED' ? false : true,
      isChecked: false,
    };
  });
  return initialFilter;
};

const defaultApplyFilterData: ApplyFilterData = {
  searchTextBuffer: '',
  applyFilter: false,
  taskSourceBuffer: [],
  taskStatusBuffer: [],
  taskTypeBuffer: [],
};

const initialState: DashboardFilterState = {
  statusFilter: createInitialFilter<TaskStatusType>(TaskStatusType),
  tariffTaskType: createInitialFilter<TaskType>(TaskType),
  taskSource: createInitialFilter<TaskSource>(TaskSource),
  searchText: '',
  selectedStatusFilters: [],
  selectedTaskTypes: [],
  selectedTaskSource: [],
  appliedFilterState: defaultApplyFilterData,
  applyFilters: false,
};

export const dashboardFilterSlice = createSlice({
  name: 'dashboardFilter',
  initialState,
  reducers: {
    toggleFilter: (state, action: PayloadAction<ToggleType>) => {
      state[action.payload.filterType][action.payload.filter].isChecked =
        !state[action.payload.filterType][action.payload.filter].isChecked;
    },
    setApplyFilters: (state, action: PayloadAction<ApplyFilterData>) => {
      state.searchText = action.payload.searchTextBuffer;
      state.selectedStatusFilters = action.payload.taskStatusBuffer.filter(
        filter => state.statusFilter[filter].isEnabled
      );
      state.selectedTaskSource = action.payload.taskSourceBuffer.filter(
        filter => state.taskSource[filter].isEnabled
      );
      state.selectedTaskTypes = action.payload.taskTypeBuffer.filter(
        filter => state.tariffTaskType[filter].isEnabled
      );
      state.searchDate = action.payload.searchDate;
      state.appliedFilterState = action.payload;
      state.applyFilters = action.payload.applyFilter;
    },
    enableStatusFilter: (state, action: PayloadAction<TaskStatusType[]>) => {
      const filterValues = action.payload;
      Object.keys(state.statusFilter).forEach(filter => {
        state.statusFilter[filter].isEnabled = filterValues.includes(filter as TaskStatusType);
      });
      state.selectedStatusFilters = state.appliedFilterState.taskStatusBuffer.filter(filter =>
        filterValues.includes(filter as TaskStatusType)
      );
    },
    enableTaskSourceFilter: (state, action: PayloadAction<TaskSource[]>) => {
      const filterValues = action.payload;
      Object.keys(state.taskSource).forEach(filter => {
        state.taskSource[filter].isEnabled = filterValues.includes(filter as TaskSource);
      });
      state.selectedTaskSource = state.appliedFilterState.taskSourceBuffer.filter(filter =>
        filterValues.includes(filter as TaskSource)
      );
    },
    enableTariffTaskFilter: (state, action: PayloadAction<TaskType[]>) => {
      const filterValues = action.payload;
      Object.keys(state.tariffTaskType).forEach(filter => {
        state.tariffTaskType[filter].isEnabled = filterValues.includes(filter as TaskType);
      });
      state.selectedTaskTypes = state.appliedFilterState.taskTypeBuffer.filter(tariffType =>
        filterValues.includes(tariffType as TaskType)
      );
    },
  },
});

export const selectedDashboardFilter = (state: RootState): DashboardFilterState => {
  return state.dashboardFilter;
};

export const {
  setApplyFilters,
  enableStatusFilter,
  enableTariffTaskFilter,
  enableTaskSourceFilter,
  toggleFilter,
} = dashboardFilterSlice.actions;

export default dashboardFilterSlice.reducer;
