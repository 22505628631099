import { restClient } from '@genability/api';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OpsManagementApiClient } from '../../GenApiClient';
import { handleUnexpectedThunkException } from '../reduxUtils';
import { RootState } from '../rootReducer';

export interface QuickSearchParams {
  minChar: number;
  key: string;
  value: any;
}
export interface QuickSearchResponse {
  results: string[];
  apiStatus: 'idle' | 'pending' | 'resolved' | 'rejected';
  errors: restClient.ResponseError[] | undefined;
  count: number;
}
const initialState: QuickSearchResponse = {
  results: [],
  apiStatus: 'idle',
  count: 0,
  errors: undefined,
};
let timeoutId: NodeJS.Timeout | undefined = undefined;

export const doQuickSearch = createAsyncThunk(
  'workflowManagement/quickSearch',
  async (
    { minChar, key, value }: QuickSearchParams,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (!value || value.length < minChar) {
      return {
        results: [],
        errors: undefined,
        count: 0,
        type: '',
        status: '',
      };
    }

    return new Promise<restClient.PagedResponse<string>>((resolve, reject) => {
      timeoutId = setTimeout(async () => {
        try {
          const client = await OpsManagementApiClient();
          const response: restClient.PagedResponse<string> = await client.opsData.quickSearch(
            key,
            value
          );
          if (response.errors) {
            throw new Error(response.errors[0].message);
          }
          resolve(response);
        } catch (err) {
          reject(rejectWithValue(handleUnexpectedThunkException(err, 'QuickSearch', dispatch)));
        }
      }, 700); // debounce time
    });
  }
);

export const QuickSearchSlice = createSlice({
  name: 'LookupStatusData',
  initialState,
  reducers: {
    startLookupStatusLoading: state => {
      state.apiStatus = 'pending';
    },
  },
  extraReducers: builder => {
    builder.addCase(doQuickSearch.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(
      doQuickSearch.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<string>>) => {
        const { count, results, errors } = action.payload;
        if (errors) {
          state.apiStatus = 'rejected';
          state.errors = errors;
        } else {
          state.apiStatus = 'resolved';
          state.count = count;
          state.results = results;
          state.errors = undefined;
        }
      }
    );
    builder.addCase(doQuickSearch.rejected, state => {
      state.apiStatus = 'rejected';
    });
  },
});

export default QuickSearchSlice.reducer;

export const selectQuickSearchData = (state: RootState): QuickSearchResponse => {
  return state.quickSearchData;
};
