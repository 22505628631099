import React, { useState, useEffect } from 'react';
import { GenBetaApiClient } from '../../GenApiClient';
import { DiffCalcResultList } from '@arcadiapower/gen-react-lib';
import { types } from '@genability/api';
import { Loading } from '@arcadiapower/shrike';
import { GetCalculatedCostRequest } from '@genability/api/dist/api';
import { CalculatedCost } from '@genability/api/dist/types';

export interface TariffCalculatorParams {
  baseRequestId: string;
  comparisonRequestId: string;
  onVarianceChange?: (variance: number) => void;
}

export interface TariffCalculatorReqRes {
  request: GetCalculatedCostRequest;
  response: CalculatedCost;
}

const TariffCalcSummary: React.FC<TariffCalculatorParams> = props => {
  const [baseRequestResponse, setBaseRequestResponse] = useState<TariffCalculatorReqRes>();
  const [comparisonRequestResponse, setComparisonRequestResponse] =
    useState<TariffCalculatorReqRes>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getRequests = async (requestId: string) => {
    setIsLoading(true);
    const client = await GenBetaApiClient();
    const request$ = client.calcHistory.getCalcHistoryRequest(requestId);
    const response$ = client.calcHistory.getCalcHistoryResponse(requestId);
    const [request, response] = await Promise.all([request$, response$]);
    setIsLoading(false);
    return {
      request: request.results[0],
      response: response.results[0],
    };
  };

  useEffect(() => {
    if (!props.baseRequestId || !props.comparisonRequestId) {
      return;
    }
    getRequests(props.baseRequestId).then(request => setBaseRequestResponse(request));
    getRequests(props.comparisonRequestId).then(request => setComparisonRequestResponse(request));
  }, [props.baseRequestId, props.comparisonRequestId]);

  useEffect(() => {
    if (!baseRequestResponse || !comparisonRequestResponse) {
      return;
    }
    const baseTotal = baseRequestResponse.response?.summary?.totalCost ?? NaN;
    const comparisonTotal = comparisonRequestResponse.response?.summary?.totalCost ?? NaN;
    const variance = (comparisonTotal - baseTotal) / baseTotal;
    if (props.onVarianceChange) {
      props.onVarianceChange(variance);
    }
  }, [baseRequestResponse, comparisonRequestResponse]);

  return (
    <>
      {isLoading && <Loading backgroundColor="primary" />}
      {!isLoading && (
        <DiffCalcResultList
          baseCalculatedCostItems={baseRequestResponse?.response?.items || []}
          comparisonCalculatedCostItems={comparisonRequestResponse?.response?.items || []}
          detailLevel={baseRequestResponse?.request?.detailLevel || types.DetailLevel.ALL}
          groupBy={baseRequestResponse?.request?.groupBy || types.GroupBy.ALL}
          showVariancePercentage={true}
        />
      )}
    </>
  );
};

export default TariffCalcSummary;
