import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { restClient, types } from '@genability/api';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { GenApiClient } from '../../GenApiClient';
import { addNotification } from '../notification/notificationSlice';
import { RootState } from '../rootReducer';
import { AppThunk } from '../store';

export interface GenLookupStatsState {
  lookupStats: types.LookupStats | null;
  lookupStatsLoading: boolean;
  lookupStatsError: string | null;
}

interface GenLookupStatsStateSuccess {
  lookupStats: types.LookupStats | null;
}

export const initialState: GenLookupStatsState = {
  lookupStats: null,
  lookupStatsLoading: false,
  lookupStatsError: '',
};

function lookupStatsStartLoading(state: GenLookupStatsState) {
  state.lookupStatsLoading = true;
}

function lookupStatsLoadingFailed(state: GenLookupStatsState, action: PayloadAction<string>) {
  state.lookupStatsLoading = false;
  state.lookupStatsError = action.payload;
}

export const lookupValues = createSlice({
  name: 'lookupStats',
  initialState,
  reducers: {
    getLookupStatsStart: lookupStatsStartLoading,
    getLookupStatsSuccess(state, { payload }: PayloadAction<GenLookupStatsStateSuccess>) {
      const { lookupStats } = payload;
      state.lookupStatsLoading = false;
      state.lookupStatsError = null;
      state.lookupStats = lookupStats;
    },
    getLookupStatsFailure: lookupStatsLoadingFailed,
  },
});

export const { getLookupStatsStart, getLookupStatsSuccess, getLookupStatsFailure } =
  lookupValues.actions;

export default lookupValues.reducer;

export const fetchLookupStats =
  (propertyKey: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(getLookupStatsStart());
      const client = await GenApiClient();
      const { results, errors }: restClient.SingleResponse<types.LookupStats> =
        await client.lookups.getPropertyLookupStats(propertyKey);
      if (errors) {
        throw new Error(errors[0].message);
      }
      const lookupStats: types.LookupStats | null = results[0];
      dispatch(getLookupStatsSuccess({ lookupStats }));
    } catch (err) {
      if (err) {
        const errorMessage = (err instanceof Error && err.message) || String(err);
        dispatch(getLookupStatsFailure(errorMessage));
      }
    }
  };

export const selectLookupStats = (state: RootState): GenLookupStatsState => {
  return state.lookupStats;
};
