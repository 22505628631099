import React, { useCallback, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { CurrentUser, selectCurrentUser } from '../../state/currentUser/currentUserSlice';
import TaskFilterPanel from '../../layout/TaskFilter/TaskFilterPanel';
import Stats from '../../layout/Stats/Stats';
import {
  TaskFilter,
  selectedTaskFilter,
  updateActiveFilter,
} from '../../state/taskFilter/taskFilterSlice';
import TaskContent from '../../layout/TaskContent/TaskContent';
import { Text, lightTheme } from '@arcadiapower/shrike';

import DashboardFilter from '../../components/DashboardFilter/DashboardFilter';
import { enableStatusFilter } from '../../state/dashboardFilter/dashboardFilterSlice';
import { TaskStatusType } from '../../task-api/v2/types/TaskStatusType';
import { allTaskStatus, openTaskStatus } from '../../utils/taskV2Utils';

const Dashboard: React.FC<RouteComponentProps> = props => {
  const currentUser: CurrentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const activeTaskFilter = useSelector(selectedTaskFilter).activeFilter;

  const updateActiveMenu = useCallback(
    (value: string) => {
      dispatch(updateActiveFilter(value as TaskFilter));
      let statusFilter: TaskStatusType[] = [];

      if (value == TaskFilter.ALL_TASKS) {
        statusFilter = allTaskStatus();
      } else if (value != TaskFilter.COMPLETED) {
        statusFilter = openTaskStatus();
      }
      dispatch(enableStatusFilter(statusFilter));
    },
    [dispatch]
  );

  return (
    <Container fluid>
      <Row>
        <Col
          xs={5}
          md={3}
          lg={2}
          className="mt-5 h-auto w-100"
          style={{
            minHeight: '100vh',
            borderRight: `2px solid ${lightTheme.colors.content.accent9}`,
          }}
        >
          <aside
            style={{
              position: 'sticky',
              top: '30px',
            }}
          >
            <TaskFilterPanel
              activeMenu={activeTaskFilter}
              filterItems={Object.values(TaskFilter)}
              updateActiveMenu={updateActiveMenu}
            />
          </aside>
        </Col>
        <Col xs={7} md={9} lg={10} className="mt-5 w-100">
          <main className={`mt-5`}>
            <Row className="m-2">
              <Text color="primary" opacity="medium" tag="h1" textStyle="heading900">
                Welcome, {currentUser?.username}
              </Text>
            </Row>
            <Stats />
            <DashboardFilter />
            <Row>
              <TaskContent />
            </Row>
          </main>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Dashboard);
