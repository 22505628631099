import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { ConfirmModal, Table, Button, NotificationLevel } from '@arcadiapower/gen-react-lib';
import PanelNavigationButtons from '../PanelNavigationButtons/PanelNavigationButtons';
import { DoSubmitPanelProps } from '../work-body';
import {
  TaskAssignmentAnswer,
  TaskAssignmentAnswerField,
  TaskTypeId,
} from '../../../task-api/types/task';
import {
  selectAllTaskAssignmentAnswers,
  deleteTaskAssignmentAnswer,
  submitTaskAssignment,
  selectTaskAssignmentAnswerByField,
  fetchTaskAssignmentAnswer,
} from '../../../state/taskAssignment/taskAssignmentSlice';
import { useAppDispatch } from '../../../state/store';
import ResourceDiffBySection from '../../ResourceDiffSection/ResourceDiffSection';
import ApiFormError, { FormErrorObject } from '../../ApiFormError/ApiFormError';
import {
  fetchDQCheck,
  isDQCheckOverride,
  selectDQCheckErrors,
  selectDQCheckOverrides,
} from '../../../state/dqCheck/dqCheckSlice';
import { ResponseError, SingleResponse } from '@genability/api/dist/rest-client';
import DQOverrideConfirmDialog from '../../DQOverride/DQOverrideConfirmationDialog/DQOverrideConfirmationDialog';
import TariffCalcSummary from '../../TariffCalcSummary/TariffCalcSummary';
import { TaskApiClient } from '../../../GenApiClient';
import { selectTotalCostBreachThreshold } from '../../../state/reportingConfig/reportingConfigSlice';
import { addNotification } from '../../../state/notification/notificationSlice';
import { debounce } from 'lodash';
import { PrivacyFlag, TariffType } from '@genability/api/dist/types';
import {
  fetchTaskAssignmentTariff,
  selectTaskAssignmentTariff,
} from '../../../state/taskAssignmentTariffs/taskAssignmentTariffsSlice';
import { formatDateToLocaleStringWithTimeStamp } from '../../../utils/dateUtils';
import { DateTime } from 'luxon';
import { Tooltip } from '@arcadiapower/shrike';
import retryDispatchWithDelay from '../../../utils/retryWithExponentialBackoff';
import { types } from '@genability/api';

const SubmitTariffPanel: React.FC<DoSubmitPanelProps> = ({
  taskAssignment,
  onPrevious,
  onSubmit,
}: DoSubmitPanelProps) => {
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [isSaveInprogress, setIsSaveInprogress] = useState(false);
  const [isAnswerDeleting, setAnswerDeleting] = useState(false);
  const [showOverrideConfirmation, setShowOverrideConfirmation] = useState(false);
  const cleanTariff: types.Tariff | undefined = useSelector(selectTaskAssignmentTariff);
  const [isDirty, setIsDirty] = useState(false);
  const tariff = useSelector(selectTaskAssignmentTariff);

  // Skip Calc for 03_02 Tasks and Incentive, Rider Tariffs
  const shouldSkipCalc =
    taskAssignment?.taskTypeId === TaskTypeId['03_02'] ||
    tariff?.tariffType === TariffType.INCENTIVE ||
    tariff?.tariffType === TariffType.RIDER ||
    tariff?.privacy === PrivacyFlag.PRIVATE;
  const [calcResultAcceptable, setCalcResultAcceptable] = useState(shouldSkipCalc);
  const [isCalcYetToRun, setIsCalcYetToRun] = useState(!shouldSkipCalc);
  const [numCalcsResulted, setNumCalcsResulted] = useState(0);
  const [apiErrors, setApiErrors] = useState<FormErrorObject | null>(null);
  const [inlineDQCheckErrors, setInlineDQCheckErrors] = useState<ResponseError[]>([]);
  const answers: TaskAssignmentAnswer[] = useSelector(selectAllTaskAssignmentAnswers);
  const stagedTariffRequestId = useSelector(
    selectTaskAssignmentAnswerByField(TaskAssignmentAnswerField.STAGED_TARIFF_REQUEST_ID)
  );
  const publishedTariffRequestId = useSelector(
    selectTaskAssignmentAnswerByField(TaskAssignmentAnswerField.PUBLISHED_TARIFF_REQUEST_ID)
  );
  const dqCheckErrors = useSelector(selectDQCheckErrors);
  const dqCheckOverrides = useSelector(selectDQCheckOverrides);
  const totalCostThreshold = useSelector(selectTotalCostBreachThreshold);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchDQCheck(taskAssignment.taskAssignmentId));
  }, []);

  useEffect(() => {
    // Reset if Calc is refreshing
    setCalcResultAcceptable(shouldSkipCalc);

    // Counted Changed Calcs
    setNumCalcsResulted(numCalcsResulted + 1);

    // Run Calc will set these values atleast twice.
    if (numCalcsResulted > 1) {
      setIsCalcYetToRun(false);
    }
  }, [stagedTariffRequestId, publishedTariffRequestId]);

  useEffect(() => {
    answers.some((taskAssignmentAnswer: TaskAssignmentAnswer) => {
      if (taskAssignmentAnswer.answerField == 'tariff') {
        if (taskAssignmentAnswer.answerDiff?.patch) {
          setIsDirty(true);
          return true;
        }
      }
    });

    const isOrgIdPresent = answers?.some((taskAssignmentAnswer: TaskAssignmentAnswer) => {
      const orgIdMatch = taskAssignmentAnswer.answerValue.match(/<orgId>(.*?)<\/orgId>/);
      if (orgIdMatch && orgIdMatch[1].trim()) {
        return true;
      }
      return false;
    });
    if (isOrgIdPresent) {
      setCalcResultAcceptable(isOrgIdPresent);
      setIsCalcYetToRun(!isOrgIdPresent);
    }
  }, [answers]);

  const onDeleteAnswer = async (taskAssignmentAnswer: TaskAssignmentAnswer) => {
    if (taskAssignment?.taskAssignmentId) {
      const result = await ConfirmModal.show({
        title: 'Danger Zone!',
        question:
          'This will completely remove the answer. Are you sure you want to start it again?',
      });
      if (result) {
        const taskAssignmentId = Number(taskAssignment?.taskAssignmentId);
        const answerField = taskAssignmentAnswer.answerField;
        setAnswerDeleting(true);
        return await dispatch(
          deleteTaskAssignmentAnswer({ taskAssignmentId, answerField })
        ).finally(() => {
          setAnswerDeleting(false);
        });
      }
    }
  };
  const onSubmitAssignment = async () => {
    if (taskAssignment?.taskAssignmentId) {
      const result = await ConfirmModal.show({
        title: 'Submit this Assignment',
        question: ' Are you sure this Assignment is ready to review?',
      });
      if (result) {
        const taskAssignmentId = Number(taskAssignment?.taskAssignmentId);
        const taskId = Number(taskAssignment?.taskId);
        setSubmitLoading(true);

        const skipDQ = dqCheckOverrides.length > 0;
        const dqOverrides = dqCheckOverrides.map(override => ({
          overrideReason: override.reason,
          dqField: override.propertyName,
          dqMessage: override.message,
        }));

        return await dispatch(
          submitTaskAssignment({
            taskAssignmentId,
            taskId,
            redirect: true,
            skipDQ,
            dqOverrides: skipDQ ? dqOverrides : undefined,
          })
        )
          .then(unwrapResult)
          .then(response => {
            if (response && response.result) {
              onSubmit();
            }
          })
          .finally(() => {
            setSubmitLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    const errors = inlineDQCheckErrors.concat(dqCheckErrors);
    const errorsObject: FormErrorObject = mappUnmatchedErrors(
      errors.filter(error => !isDQCheckOverride(error, dqCheckOverrides))
    );
    setApiErrors(errorsObject);
  }, [dqCheckErrors, dqCheckOverrides, inlineDQCheckErrors]);

  // Find if Inline DQ Check Errors are overriden
  useEffect(() => {
    if (inlineDQCheckErrors.length === 0) {
      return;
    }
    const unoveriddenErrors = inlineDQCheckErrors.filter(
      error => !isDQCheckOverride(error, dqCheckOverrides)
    );
    setCalcResultAcceptable(unoveriddenErrors.length === 0);
  }, [dqCheckOverrides, inlineDQCheckErrors]);

  const mappUnmatchedErrors = (errors: ResponseError[]): FormErrorObject => {
    // Map ResponseError to FormErrorObject
    const errorsObject: any = {};
    errors.forEach(error => {
      errorsObject[`noMatchedProperty.${error.propertyName}`] = {
        type: error.code,
        message: error.message,
      };
    });
    return errorsObject;
  };

  const onSubmitHandler = () => {
    if (isDirty && dqCheckOverrides?.length) {
      setShowOverrideConfirmation(true);
      return;
    }
    if (isDirty && (!apiErrors || Object.keys(apiErrors).length === 0)) {
      onSubmitAssignment();
    }
  };

  const onOverrideConfirmation = () => {
    setShowOverrideConfirmation(false);
    onSubmitAssignment();
  };

  const onVarianceChange = (variance: number) => {
    // Reset inline errors
    setCalcResultAcceptable(false);

    // If variance is NaN, then calculation failed
    // Unacceptable scenario
    if (isNaN(variance)) {
      const errorMessage = 'Calculation failed. Please validate DQ, Formulae, Lookups and Units.';
      setInlineDQCheckErrors([
        {
          code: 'DQFailure',
          message: errorMessage,
          objectName: 'Tariff',
          propertyName: 'tariffVariance',
        },
      ]);
      dispatch(addNotification(errorMessage, NotificationLevel.Error));
      return;
    }

    if (Math.abs(variance) < totalCostThreshold.value) {
      // Calc Result is from a recent run in this session
      setCalcResultAcceptable(true && !isCalcYetToRun);
      setInlineDQCheckErrors([]);
      return;
    }

    const variancePercentage = (Math.abs(variance) * 100).toFixed(2);
    const expectedVariancePercentage = (totalCostThreshold.value * 100).toFixed(2);
    const errorMessage = `Variance is ${variancePercentage}% should be less than ${expectedVariancePercentage}%`;
    setInlineDQCheckErrors([
      {
        code: 'DQFailure',
        message: errorMessage,
        objectName: 'Tariff',
        propertyName: 'tariffVariance',
      },
    ]);
    dispatch(addNotification(errorMessage, NotificationLevel.Error));
  };

  const runCalc = async () => {
    // If calculation has completed successfully
    // and results are acceptable or overridable

    if (calcResultAcceptable && !isCalcYetToRun) {
      onSubmitHandler();
      return;
    }

    analytics.track('run_calc_btn_clicked', {
      taskAssignmentId: taskAssignment.taskAssignmentId,
    });

    setIsSaveInprogress(true);
    const taskId = Number(taskAssignment?.taskId);
    const client = await TaskApiClient();
    const saveResponse = (await client.taskAssignment.persistTaskAssignment(
      taskAssignment.taskAssignmentId
    )) as SingleResponse<never>;

    if (saveResponse && saveResponse.errors) {
      dispatch(
        addNotification(
          'Unable to save Tariff, please validate DQs and try again.',
          NotificationLevel.Error
        )
      );
      setIsSaveInprogress(false);
      return;
    }
    await client.task.runTaskCalc(taskId);

    // Update only new TAAs for UX

    await fetchTaskAssignmentAnswerWithRetry(
      taskAssignment.taskAssignmentId,
      TaskAssignmentAnswerField.STAGED_TARIFF_REQUEST_ID
    );
    await fetchTaskAssignmentAnswerWithRetry(
      taskAssignment.taskAssignmentId,
      TaskAssignmentAnswerField.PUBLISHED_TARIFF_REQUEST_ID
    );

    dispatch(
      fetchTaskAssignmentTariff({
        taskAssignmentId: taskAssignment.taskAssignmentId,
      })
    ).finally(() => {
      setIsSaveInprogress(false);
    });
  };

  const fetchTaskAssignmentAnswerWithRetry = async (
    taskAssignmentId: number,
    answerField: string
  ) => {
    return retryDispatchWithDelay(
      () =>
        dispatch(
          fetchTaskAssignmentAnswer({
            taskAssignmentId,
            answerField,
          })
        ),
      { maxRetries: 3, maxDelay: 30000 }
    );
  };

  return (
    <React.Fragment>
      {apiErrors ? <ApiFormError apiErrors={apiErrors} /> : null}
      {showOverrideConfirmation ? (
        <DQOverrideConfirmDialog
          onContinue={onOverrideConfirmation}
          onCancel={() => setShowOverrideConfirmation(false)}
          isVisible={showOverrideConfirmation}
        />
      ) : null}
      <h2>Review and Submit</h2>
      <p>Before submitting please review the following answers:</p>
      <Table>
        <tbody>
          {answers
            ?.filter(
              taskAssignmentAnswer =>
                ['appliedAiValues', '_aiStagedEntity', 'aiStagedEntity', 'aiBackupEntity'].indexOf(
                  taskAssignmentAnswer.answerField
                ) == -1
            )
            .map((taskAssignmentAnswer: TaskAssignmentAnswer) => {
              return (
                <tr key={taskAssignmentAnswer.taskAnswerId}>
                  <td>{taskAssignmentAnswer.answerField}</td>
                  <td>
                    {taskAssignmentAnswer.answerField == 'tariff'
                      ? 'Tariff Object'
                      : taskAssignmentAnswer.answerValue}
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      spin={isAnswerDeleting}
                      disabled={isAnswerDeleting}
                      action={() => onDeleteAnswer(taskAssignmentAnswer)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>

      <div>
        {answers.map((taskAssignmentAnswer: TaskAssignmentAnswer) => {
          if (taskAssignmentAnswer.answerField == 'tariff') {
            return (
              <React.Fragment key={taskAssignmentAnswer.taskAnswerId}>
                <p>The Tariff Object has the following changes:</p>
                {taskAssignmentAnswer.answerDiff && (
                  <ResourceDiffBySection resourceDiff={taskAssignmentAnswer.answerDiff} />
                )}
              </React.Fragment>
            );
          }
        })}
        <div className="my-3">
          <h3>Calculation Summary</h3>
          {stagedTariffRequestId?.lastUpdatedDate && (
            <Tooltip
              label="Last Ran on"
              tooltipId="calcLastRunTimeStamp"
              content={formatDateToLocaleStringWithTimeStamp(
                stagedTariffRequestId?.lastUpdatedDate
              )}
              backgroundColor="primary"
            >
              <p>
                <i>
                  Last Ran:{' '}
                  {DateTime.fromISO(stagedTariffRequestId?.lastUpdatedDate, {
                    zone: 'utc',
                  }).toRelative()}
                </i>
              </p>
            </Tooltip>
          )}

          {stagedTariffRequestId?.answerValue && publishedTariffRequestId?.answerValue && (
            <TariffCalcSummary
              baseRequestId={publishedTariffRequestId?.answerValue}
              comparisonRequestId={stagedTariffRequestId?.answerValue}
              onVarianceChange={debounce(onVarianceChange, 500)}
            />
          )}
        </div>
        <div className="mt-3">
          <PanelNavigationButtons
            onClickPrevious={onPrevious}
            onClickNext={runCalc}
            nextLabel={
              calcResultAcceptable && !isCalcYetToRun ? 'Submit Assignment' : 'Run Calculation'
            }
            nextSpin={isSubmitLoading || isSaveInprogress}
            noChangesChecked={
              isDirty && (!calcResultAcceptable || Object.keys(apiErrors ?? {}).length === 0)
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SubmitTariffPanel;
