import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TaskApiV2Client } from '../../GenApiClient';
import { GetUserListsRequest } from '../../task-api/v2/api/task-api-v2';
import { handleUnexpectedThunkException } from '../reduxUtils';
import { RootState } from '../rootReducer';
import { UserListState } from '../../utils/taskV2Utils';
import { restClient } from '@genability/api';
import { User } from '../../task-api/v2/types/User';

const initialState: UserListState = {
  apiStatus: 'idle',
  count: 0,
  results: [],
  errors: undefined,
};

export const fetchUserList = createAsyncThunk(
  'v2/users/',
  async (request: GetUserListsRequest, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiV2Client();
      const response: restClient.PagedResponse<User> = await client.taskV2.getUserList(request);

      return response;
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'Task', dispatch));
    }
  }
);

export const userListSlice = createSlice({
  name: 'userList',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUserList.pending, state => {
        state.apiStatus = 'pending';
      })
      .addCase(
        fetchUserList.fulfilled,
        (state, action: PayloadAction<restClient.PagedResponse<User>>) => {
          const { count, results, errors } = action.payload;
          if (errors) {
            state.apiStatus = 'rejected';
            state.results = [];
          } else {
            state.apiStatus = 'resolved';
            state.count = count;
            state.results = results;
          }
        }
      );
  },
});

export const selectUserList = (state: RootState): UserListState => {
  return state.userList; // Fix the state key here
};

export default userListSlice.reducer;
