import { Select } from '@arcadiapower/shrike';
import { Family, KeySpace, KeySpaceFamilyMap } from '@arcadiapower/gen-react-lib';
import React, { FC, useEffect, useState } from 'react';

interface FamilySelectorProps {
  handleSelected: (family: Family) => void;
  value: Family;
  className?: string;
  keySpace: KeySpace;
}

const FamilySelector: React.FC<FamilySelectorProps> = props => {
  const { handleSelected, keySpace, value, className } = props;
  const [family, setFamily] = useState<Family>(value);
  const [familyOptions, setFamilyOptions] = useState<Family[]>([]);

  useEffect(() => {
    const familyDrop: Family[] = KeySpaceFamilyMap[keySpace] || [];
    setFamilyOptions(familyDrop);
  }, [keySpace]);
  return (
    <>
      <Select
        value={family}
        required
        label="Family"
        loadingMessage="Loading..."
        name="Family"
        minHeight="50px"
        className={className}
        options={familyOptions.map(key => ({
          text: key,
          value: key,
        }))}
        onChange={value => {
          setFamily(value as Family);
          handleSelected(value as Family);
        }}
      />
    </>
  );
};

export default FamilySelector;
