import { restClient, types } from '@genability/api';
import { Icon, IconButton, NotificationLevel } from '@arcadiapower/gen-react-lib';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { RouteComponentProps, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { PropertyKeyDetail } from '../../../components/PropertyKeyDetail/PropertyKeyDetail';
import { useAppDispatch } from '../../../state/store';
import LookupDetail from '../../../components/LookupDetails/LookupDetail';
import { PropertyKeyV2 } from '../../../task-api/v2/types/PropertyKeyV2';
import { TaskApiV2Client } from '../../../GenApiClient';
import { addNotification } from '../../../state/notification/notificationSlice';

export type PageParams = {
  propertyKeyName: string;
};

const Divider: FC = () => <hr className="my-3 w-100" />;

export const PropertyKey: FC<RouteComponentProps<PageParams>> = ({ match }): ReactElement => {
  const [property, setProperty] = useState<PropertyKeyV2 | null>(null);
  const { propertyKeyName } = useParams<PageParams>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchPropertyKey = async () => {
      const keyName = match?.params?.propertyKeyName;
      if (keyName) {
        const client = await TaskApiV2Client();
        const { result, errors }: restClient.SingleResponse<PropertyKeyV2> =
          await client.taskV2.getPropertyKey(keyName);
        if (result) {
          setProperty(result);
        }
        if (errors) {
          dispatch(addNotification(errors, NotificationLevel.Error));
          setProperty(null);
        }
      }
    };

    fetchPropertyKey();
  }, [match?.params?.propertyKeyName]);

  return (
    <Container fluid className="mt-5">
      <Row className="p-3">
        <Col sm={12} className="mt-3">
          <Link to={'/directory/property-keys'} className="text-muted">
            <Icon iconName="back" /> {'Return to Property Key Directory'}
          </Link>
        </Col>
      </Row>
      <Row className="p-3">
        <Col sm={6} className="font-weight-bold">
          <h2>
            {property?.displayName || 'No data available for property key: ' + propertyKeyName}
          </h2>
        </Col>
      </Row>
      <Row className="p-3">
        <Col sm={6}>
          <h6>{property?.description}</h6>
        </Col>
      </Row>
      <Divider />

      <Row>
        <Col xs={12} md={6}>
          {property && <PropertyKeyDetail propertyKey={property} />}
        </Col>
        {property?.keyName && property?.dataType === types.PropertyDataType.LOOKUP && (
          <LookupDetail
            propertyKey={property.keyName}
            isGlobalPropertyKey={
              property.entityId == null ||
              property.entityId === 0 ||
              Number.isNaN(property.entityId)
            }
          />
        )}
      </Row>
    </Container>
  );
};
