/* eslint-disable react/display-name */
import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from '@arcadiapower/gen-react-lib';
import Dropdown from 'react-bootstrap/Dropdown';
import { TaskTypeId } from '../../task-api/types/task';
import appConstants from '../../app-constants';
import styles from './TasktitleBar.module.scss';
import { motherLink } from '../../utils/externalLinks';

interface TitleProp {
  back: string;
  taskTypeId: TaskTypeId | undefined;
  taskTypeTitle: string | null;
  title: string;
  taskId: number | undefined;
  lseId: number | undefined;
  lseName?: string;
  onCommentClick: () => void;
  onHistoryClick: () => void;
  onMenuClick: (selectedMenu: string | null) => void;
  taskAssignmentId?: number;
}

const CustomToggle = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: any, ref: any) => <IconButton icon="menu" ref={ref} onClick={props.onClick}></IconButton>
);

function TaskTitleBar(props: TitleProp): React.ReactElement {
  const handleSelectMenu = (selectedMenu: string | null) => {
    props.onMenuClick(selectedMenu);
  };
  return (
    <React.Fragment>
      <div className={`${styles.wrapper} `}>
        <div className={styles.leftWrapper}>
          <div className={styles.back}>
            <Link to={props.back}>
              <IconButton
                icon="back"
                onClick={() => {
                  console.log('Do nothing');
                }}
              />
            </Link>
          </div>
          <div className={styles.title}>
            {props.taskTypeId}
            <div className={styles.subTitle}>{props.taskTypeTitle}</div>
          </div>
          <div className={styles.title}>
            {props.title}
            <div className={styles.subTitle}>
              <div className={styles.subTitleSection}>{props.lseName}</div>
              <div className={styles.subTitleSection}>{`Task ID: ${props.taskId}`}</div>
              {props.taskAssignmentId && (
                <div className={styles.subTitleSection}>
                  {`Task Assignment ID: ${props.taskAssignmentId}`}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.rightWrapper}>
          <div>
            <IconButton icon="history" onClick={props.onHistoryClick} />
          </div>
          <div>
            <IconButton icon="comment" onClick={props.onCommentClick} />
          </div>
          <Dropdown onSelect={handleSelectMenu} drop={'down'} alignRight={true}>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              Custom toggle
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey={appConstants.menuDropdown.viewJson}>
                {appConstants.menuDropdown.viewJson}
              </Dropdown.Item>
              {props.taskTypeId == TaskTypeId['03_02'] && (
                <Dropdown.Item eventKey={appConstants.menuDropdown.copyTariff}>
                  {appConstants.menuDropdown.copyTariff}
                </Dropdown.Item>
              )}
              <Dropdown.Divider />
              <Dropdown.Header>{appConstants.menuDropdown.motherLinksHeader}</Dropdown.Header>
              <Dropdown.Item
                disabled={props.taskId ? false : true}
                onClick={() =>
                  window.open(props.taskId ? motherLink('tasks', props.taskId) : '', '_blank')
                }
                eventKey={appConstants.motherLinks.task}
              >
                {appConstants.motherLinks.task}
              </Dropdown.Item>
              <Dropdown.Item
                disabled={props.taskAssignmentId ? false : true}
                onClick={() =>
                  window.open(
                    props.taskAssignmentId ? motherLink('assignments', props.taskAssignmentId) : '',
                    '_blank'
                  )
                }
                eventKey={appConstants.motherLinks.taskAssignment}
              >
                {appConstants.motherLinks.taskAssignment}
              </Dropdown.Item>
              <Dropdown.Item
                disabled={props.lseId ? false : true}
                onClick={() =>
                  window.open(props.lseId ? motherLink('lses', props.lseId) : '', '_blank')
                }
                eventKey={appConstants.motherLinks.lse}
              >
                {appConstants.motherLinks.lse}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TaskTitleBar;
