export enum WorkflowStateType {
  DOC_OPEN = 'DOC_OPEN',
  DOC_IN_PROGRESS = 'DOC_IN_PROGRESS',
  DOC_PUBLISHED = 'DOC_PUBLISHED',
  DOC_CLOSED = 'DOC_CLOSED',
  DE_INIT = 'DE_INIT',
  DE_OPEN = 'DE_OPEN',
  DE_IN_PROGRESS = 'DE_IN_PROGRESS',
  DE_READY_FOR_REVIEW = 'DE_READY_FOR_REVIEW',
  DE_REVIEW_IN_PROGRESS = 'DE_REVIEW_IN_PROGRESS',
  DE_APPROVED = 'DE_APPROVED',
  DE_REGRESSION_INPROGRESS = 'DE_REGRESSION_INPROGRESS',
  DE_READY_FOR_PUBLISH = 'DE_READY_FOR_PUBLISH',
  DE_PUBLISHED = 'DE_PUBLISHED',
  DE_CLOSED = 'DE_CLOSED',
}
