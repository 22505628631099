import React from 'react';
import { TaskComment } from '../../task-api/types/task';
import ago from '../../utils/dateUtils';
import styles from './TaskComment.module.scss';

interface TaskCommentProps {
  taskComment: TaskComment | undefined;
}

const TaskCommentCard: React.FC<TaskCommentProps> = ({ taskComment }: TaskCommentProps) => {
  return (
    <React.Fragment>
      <div className={styles.flex}>
        <div className={styles.avatar}></div>&nbsp;&nbsp;
        <div>
          <div>{taskComment?.fromUser}</div>
          <div className={styles.ago}>{ago(taskComment?.lastUpdatedDate)}</div>
          <div>{taskComment?.comment}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TaskCommentCard;
