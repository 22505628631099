import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Alert, Button, Modal, Text } from '@arcadiapower/shrike';
import UnpublishedLookupValuesList from '../LookupValuesList/UnpublishedLookupValues';
import DynamicModal, { ModalData } from '../DynamicModal/DynamicModal';
import { ClosureCodesType } from '../../task-api/v2/types/ClosureCodesType';
import { useDispatch, useSelector } from 'react-redux';
import { selectIntermediateSavedData } from '../../state/tasksV2/intermediateSaveTaskSlice';
import {
  fetchUnpublishedLookupTasks,
  selectUnpublishedLookupTasks,
} from '../../state/tasksV2/UnpublishedLookupTaskSlice';
import { TaskType } from '../../task-api/v2/types/TaskType';
import {
  fetchWorkFlowConfig,
  fetchWorkFlowStates,
  selectedWorkFlowConfigDetails,
} from '../../state/workflowConfig/WorkFlowConfigSlice';
import { WorkflowConfigType } from '../../task-api/v2/types/WorkflowConfigType';
import {
  approveTask,
  cancelTask,
  publishTask,
  sendForRework,
  selectTaskWorkflowEntityPostAction,
  submitTask,
  proceedToWork,
  proceedToReview,
} from '../../state/tasksV2/TasksActionSlice';
import {
  resetTaskState,
  selectTaskWorkflowEntity,
} from '../../state/tasksV2/CreateLookupTaskSlice';
import ProgressTracker from '../ProgressTracker/ProgressTracker';
import { CurrentUser, selectCurrentUser } from '../../state/currentUser/currentUserSlice';
import { fetchWorkflowByWorkflowId, selectWorkflow } from '../../state/tasksV2/WorkflowStateSlice';
import { ActionType } from '../../task-api/v2/types/ActionType';
import { WorkflowStateType } from '../../task-api/v2/types/WorkflowStatesTypes';
import AssignTask from '../AssignTask/AssignTask';
import UserInput from '../UserNameInput/UserNameInput';
import { User } from '../../task-api/v2/types/User';
import { assignTask, taskAssignment } from '../../state/taskAssignment_V2/taskAssignmentV2Slice';
import { TaskAssignmentRequest } from '../../task-api/v2/api/task-api-v2';
import { selectUpdatedTaskAssignmentAnswer } from '../../state/tasksV2/LookupsGapFillingSlice';
import WorkerInput from '../WorkerInput/WorkerInput';
import { IWorker } from '../../task-api/types/worker';
import { QualificationType } from '../../task-api/v2/types/QualifictionType';

export interface UnpublishedLookupTaskProps {
  propertyKey: string;
  isGlobalPropertyKey: boolean;
}

const UnpublishedLookupTasks: FC<UnpublishedLookupTaskProps> = ({
  propertyKey,
  isGlobalPropertyKey,
}): ReactElement => {
  const currentUser: CurrentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const { taskAssignmentAnswer } = useSelector(selectIntermediateSavedData);
  const { deTaskId } = useSelector(selectTaskWorkflowEntity);
  const {
    taskWorkflowEntityPostAction,
    submitError,
    submitLoading,
    cancelLoading,
    approveLoading,
    publishLoading,
    sendForReworkLoading,
    proceedToWorkLoading,
    proceedToReviewLoading,
  } = useSelector(selectTaskWorkflowEntityPostAction);
  const { existingConfigDetails, wfStatesData } = useSelector(selectedWorkFlowConfigDetails);
  const {
    taskId,
    workflowId,
    taskType,
    error,
    assignee,
    reviewer,
    taskStatus,
    isLoading: unPublishedLookupLoading,
  } = useSelector(selectUnpublishedLookupTasks);
  const { currentWorkflowState } = useSelector(selectWorkflow);
  const { taskAssignmentAnswer: updatedTaskAssignmentAnswer, isLoading: lookupGapFillingLoading } =
    useSelector(selectUpdatedTaskAssignmentAnswer);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [showAssignmentModal, setShowAssignmentModal] = useState<boolean>(false);
  const [commentError, setCommentError] = useState<string>('');
  const [worker, setWorker] = useState<IWorker | undefined>(undefined);
  const [toggleRefreshUnpublishedTasks, setToggleRefreshUnpublishedTasks] =
    useState<boolean>(false);
  const [workflowConfig, setWorkflowConfig] = useState<string>('');
  const [taskSubmit, setTasksubmit] = useState<boolean>(false);
  const { apiState: taskAssignmentAPIState } = useSelector(taskAssignment);

  useEffect(() => {
    if (taskType === TaskType.ADD_LOOKUP || taskType === TaskType.ADD_DOCUMENT) {
      setWorkflowConfig(WorkflowConfigType.ADD_LOOKUP_AUTO);
      dispatch(fetchWorkFlowConfig(WorkflowConfigType.ADD_LOOKUP_AUTO));
    } else if (taskType === TaskType.EDIT_LOOKUP) {
      setWorkflowConfig(WorkflowConfigType.EDIT_LOOKUP_AUTO);
      dispatch(fetchWorkFlowConfig(WorkflowConfigType.EDIT_LOOKUP_AUTO));
    }
  }, [taskType]);

  useEffect(() => {
    dispatch(fetchUnpublishedLookupTasks(propertyKey));
  }, [
    taskAssignmentAnswer,
    taskWorkflowEntityPostAction,
    deTaskId,
    toggleRefreshUnpublishedTasks,
    updatedTaskAssignmentAnswer,
  ]);

  useEffect(() => {
    if (workflowId != null) {
      dispatch(fetchWorkflowByWorkflowId(workflowId));
      dispatch(fetchWorkFlowStates(workflowId));
    }
  }, [workflowId, taskWorkflowEntityPostAction, toggleRefreshUnpublishedTasks]);

  useEffect(() => {
    if (taskAssignmentAPIState === 'resolved') {
      setShowAssignmentModal(false);
      setWorker(undefined);
      setToggleRefreshUnpublishedTasks(!toggleRefreshUnpublishedTasks);
    }
  }, [taskAssignmentAPIState]);

  useEffect(() => {
    if (taskWorkflowEntityPostAction && !submitError && worker && showAssignmentModal) {
      const request = new TaskAssignmentRequest();
      request.taskId = taskId?.toString();
      request.workflowId = workflowId?.toString();
      request.username = worker.userName;
      dispatch(assignTask(request));
    } else if (taskWorkflowEntityPostAction && !submitError && showAssignmentModal) {
      setShowAssignmentModal(false);
    }
  }, [taskSubmit]);

  const closeTask = async (data: ModalData) => {
    if (data.dropDownValue == ClosureCodesType.Other && !data.textAreaInput) {
      setCommentError('Please add Comment');
    } else {
      setCommentError('');
      await dispatch(
        cancelTask(taskId, workflowId, taskType, data.dropDownValue, data.textAreaInput)
      );
      dispatch(resetTaskState());
      setShowCancelModal(false);
    }
  };

  const submitLookupTask = async () => {
    await dispatch(submitTask(taskId, workflowId, taskType));
    setTasksubmit(!taskSubmit);
  };

  const approveLookupTask = () => {
    dispatch(approveTask(taskId, workflowId, taskType));
  };

  const sendLookupTaskForRework = () => {
    dispatch(sendForRework(taskId, workflowId, taskType));
  };

  const publishLookupTask = () => {
    dispatch(publishTask(taskId, workflowId, taskType));
    dispatch(resetTaskState());
  };

  const proceedToWorkOnLookupTask = () => {
    dispatch(proceedToWork(taskId, workflowId, taskType));
  };

  const proceedToReviewLookupTask = () => {
    dispatch(proceedToReview(taskId, workflowId, taskType));
  };

  return (
    <>
      <Row>
        <Col>
          <Text color="primary" opacity="high" tag="h3" textStyle="heading600">
            Unpublished Tasks ({taskId ? 1 : 0})
          </Text>
        </Col>
      </Row>
      {taskId && (
        <>
          <Row>
            <ProgressTracker
              workflowStates={wfStatesData}
              workflowConfig={existingConfigDetails[workflowConfig] || null}
              currentTaskState={taskStatus}
            />
          </Row>
          <Row className="mt-4">
            <Col className="d-flex">
              <Text
                color="primary"
                opacity="high"
                tag="p"
                textStyle="paragraph500"
                className="mr-2"
              >
                <b>Task Id: </b>
                {taskId ? taskId : ''} |
              </Text>

              <Text
                color="primary"
                opacity="high"
                tag="p"
                textStyle="paragraph500"
                className="mr-2"
              >
                <b>Task Type: </b>
                {taskType ? taskType : ''} |
              </Text>
              <Text
                color="primary"
                opacity="high"
                tag="p"
                textStyle="paragraph500"
                className="mr-2"
              >
                <b>Modeller: </b>
                {assignee ? assignee : 'Not assigned'} |
              </Text>
              <Text
                color="primary"
                opacity="high"
                tag="p"
                textStyle="paragraph500"
                className="mr-2"
              >
                <b>Reviewer: </b>
                {reviewer ? reviewer : 'Not assigned'}
              </Text>

              {currentWorkflowState?.allowedActions.includes(ActionType.ASSIGNTOREVIEWER) && (
                <AssignTask
                  workflowId={workflowId?.toString()}
                  taskId={taskId.toString()}
                  taskType={taskType as TaskType}
                  reviewer
                  text={reviewer ? 'Re-assign' : 'Assign'}
                />
              )}
            </Col>
          </Row>
          <Row>
            <UnpublishedLookupValuesList
              propertyKey={propertyKey}
              isGlobalPropertyKey={isGlobalPropertyKey}
            />
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              {currentUser.username === assignee &&
                currentWorkflowState?.allowedActions.includes(ActionType.SUBMIT) && (
                  <Button
                    backgroundColor="primaryInverse"
                    data-testid="submit-button"
                    loading={
                      reviewer
                        ? submitLoading
                        : false || unPublishedLookupLoading || lookupGapFillingLoading
                    }
                    onClick={() => (reviewer ? submitLookupTask() : setShowAssignmentModal(true))}
                    className="mr-4"
                  >
                    Submit
                  </Button>
                )}
              <Modal aria-label="Modal" data-testid="assign-model" isOpen={showAssignmentModal}>
                <Modal.Header
                  title="Assign Task"
                  onClose={() => setShowAssignmentModal(false)}
                ></Modal.Header>
                <Modal.Content>
                  <WorkerInput
                    qualification={QualificationType.PROPERTY_KEY_REVIEWER}
                    label={'Reviewer'}
                    placeholder="Search"
                    id="assignTask"
                    selected={worker}
                    setSelected={setWorker}
                  />
                </Modal.Content>
                <Modal.Footer
                  onSubmit={() => submitLookupTask()}
                  loading={submitLoading || taskAssignmentAPIState === 'pending'}
                ></Modal.Footer>
              </Modal>
              {currentUser.username === assignee &&
                currentWorkflowState?.name === WorkflowStateType.DE_OPEN && (
                  <Button
                    backgroundColor="primaryInverse"
                    loading={
                      proceedToWorkLoading || unPublishedLookupLoading || lookupGapFillingLoading
                    }
                    onClick={() => proceedToWorkOnLookupTask()}
                    className="mr-4"
                  >
                    Proceed to Task
                  </Button>
                )}

              {currentUser.username === reviewer &&
                currentWorkflowState?.name === WorkflowStateType.DE_READY_FOR_REVIEW && (
                  <Button
                    backgroundColor="primaryInverse"
                    loading={
                      proceedToReviewLoading || unPublishedLookupLoading || lookupGapFillingLoading
                    }
                    onClick={() => proceedToReviewLookupTask()}
                    className="mr-4"
                    data-testid="proceed-button"
                  >
                    Proceed to Task
                  </Button>
                )}

              {currentUser.username === reviewer &&
                currentWorkflowState?.allowedActions.includes(ActionType.APPROVE) && (
                  <Button
                    backgroundColor="primaryInverse"
                    loading={approveLoading || unPublishedLookupLoading || lookupGapFillingLoading}
                    onClick={() => approveLookupTask()}
                    className="mr-4"
                    data-testid="approve-button"
                  >
                    Approve
                  </Button>
                )}
              {currentUser.username === reviewer &&
                currentWorkflowState?.allowedActions.includes(ActionType.REWORK) && (
                  <Button
                    backgroundColor="tertiary"
                    loading={
                      sendForReworkLoading || unPublishedLookupLoading || lookupGapFillingLoading
                    }
                    onClick={() => sendLookupTaskForRework()}
                    className="mr-4"
                  >
                    Send For Rework
                  </Button>
                )}

              {currentUser.username === reviewer &&
                currentWorkflowState?.allowedActions.includes(ActionType.PUBLISH) && (
                  <Button
                    backgroundColor="primaryInverse"
                    className="mr-4"
                    data-testid="publish-button"
                    loading={publishLoading || unPublishedLookupLoading || lookupGapFillingLoading}
                    onClick={() => publishLookupTask()}
                  >
                    Publish
                  </Button>
                )}

              {(currentUser.username === assignee || currentUser.username === reviewer) &&
                currentWorkflowState?.allowedActions.includes(ActionType.CANCEL) && (
                  <Button
                    backgroundColor="tertiary"
                    className="mr-4"
                    data-testid="close-button"
                    onClick={() => setShowCancelModal(true)}
                  >
                    Cancel Task
                  </Button>
                )}
            </Col>
          </Row>
        </>
      )}
      {error && <Alert variant="error">{error}</Alert>}
      {showCancelModal && (
        <DynamicModal
          title="Cancel Task"
          show={showCancelModal}
          testid="cancel-task-modal"
          onHide={() => setShowCancelModal(false)}
          onSubmit={closeTask}
          dropdown={{
            title: 'Closure code',
            options: Object.keys(ClosureCodesType)
              .filter(
                key =>
                  ClosureCodesType[key as keyof typeof ClosureCodesType] !==
                  ClosureCodesType.Completed
              )
              .map(key => ({
                text: key,
                value: ClosureCodesType[key as keyof typeof ClosureCodesType],
              })),
          }}
          isLoading={cancelLoading}
          textArea={{
            title: 'Comments',
            error: commentError,
          }}
        />
      )}
    </>
  );
};

export default UnpublishedLookupTasks;
