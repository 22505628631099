import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { restClient, types, restApis } from '@genability/api';
import { GenApiClient } from '../../GenApiClient';
import { RootState } from '../rootReducer';
import { handleUnexpectedThunkException } from '../reduxUtils';
import { FetchPagedApiResponse } from '../../utils/apiResponseTypes';
export interface TerritoryState {
  apiStatus: 'idle' | 'pending' | 'resolved' | 'rejected';
  territoriesByLse: Record<number, Array<types.Territory>>;
  errors: restClient.ResponseError[] | undefined;
}

interface FetchTerritoriesArgs {
  lseId: number;
}

export const initialState: TerritoryState = {
  apiStatus: 'idle',
  territoriesByLse: {},
  errors: undefined,
};

interface TerritoryResponseSuccess {
  lseId: number;
  response: FetchPagedApiResponse<types.Territory>;
}

export const fetchTerritories = createAsyncThunk(
  'territories/fetchTerritories',
  async (arg: FetchTerritoriesArgs, { dispatch, rejectWithValue }) => {
    try {
      const client = await GenApiClient();
      const territoryParams = new restApis.GetTerritoriesRequest();
      territoryParams.lseId = arg.lseId;
      territoryParams.populateItems = false;
      territoryParams.populateLses = false;
      const response: restClient.PagedResponse<types.Territory> =
        await client.territories.getTerritories(territoryParams);
      return { lseId: arg.lseId, response: { results: response.results } };
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'Territory', dispatch));
    }
  }
);

export const TerritoriesSlice = createSlice({
  name: 'territories',
  initialState,
  reducers: {
    getTerritoriesSuccess(state, { payload }: PayloadAction<TerritoryResponseSuccess>) {
      const { lseId, response } = payload;
      state.territoriesByLse[lseId] = response.results;
      state.apiStatus = 'resolved';
      state.errors = undefined;
    },
    getTerritoriesFailure(state: TerritoryState, action: PayloadAction<TerritoryResponseSuccess>) {
      state.apiStatus = 'rejected';
      state.errors = action.payload.response.errors;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchTerritories.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(
      fetchTerritories.fulfilled,
      (state, action: PayloadAction<TerritoryResponseSuccess>) => {
        const { lseId, response } = action.payload;
        if (response.errors) {
          state.apiStatus = 'rejected';
          state.errors = response.errors;
        } else {
          state.apiStatus = 'resolved';
          state.territoriesByLse[lseId] = response.results;
          state.errors = undefined;
        }
      }
    );
    builder.addCase(fetchTerritories.rejected, state => {
      state.apiStatus = 'rejected';
    });
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectServiceTerritories = (lseId: number | undefined): any => {
  const territories = (state: RootState) => {
    if (lseId && Array.isArray(state.territories.territoriesByLse[lseId])) {
      return state.territories.territoriesByLse[lseId].filter(
        (territory: types.Territory) => territory.usageType === types.UsageType.SERVICE
      );
    }
    return [];
  };
  return territories;
};

export const selectTariffTerritories = (
  state: RootState,
  lseId: number
): Array<types.Territory> => {
  if (Array.isArray(state.territories.territoriesByLse[lseId])) {
    return state.territories.territoriesByLse[lseId].filter(
      (territory: types.Territory) => territory.usageType === types.UsageType.TARIFF
    );
  }
  return [];
};

export const { getTerritoriesSuccess, getTerritoriesFailure } = TerritoriesSlice.actions;

export default TerritoriesSlice.reducer;
