import { types } from '@genability/api';

function getChargePeriodUnit(tariffRate: types.TariffRate): string {
  let unit = '';
  switch (tariffRate.chargePeriod) {
    case types.ChargePeriod.ONE_TIME:
      unit = 'onetime';
      break;

    case types.ChargePeriod.HOURLY:
      unit = '/hour';
      break;

    case types.ChargePeriod.DAILY:
      unit = '/day';
      break;

    case types.ChargePeriod.MONTHLY:
      unit = '/month';
      break;

    case types.ChargePeriod.QUARTERLY:
      unit = '/quarter';
      break;

    case types.ChargePeriod.ANNUALLY:
      unit = '/year';
      break;

    default:
      break;
  }
  return unit;
}

export function getRateUnit(tariffRate: types.TariffRate): string {
  let unit = '';
  const rateBand = tariffRate.rateBands && tariffRate.rateBands.find(band => band.rateUnit != null);
  if (!rateBand) {
    return unit;
  }
  if (rateBand.rateUnit === 'COST_PER_UNIT') {
    switch (tariffRate.chargeType) {
      case 'CONSUMPTION_BASED':
        unit = '/kWh';
        break;

      case 'DEMAND_BASED':
        unit = '/kW';
        break;

      case 'FIXED_PRICE':
      case 'MINIMUM':
        unit = getChargePeriodUnit(tariffRate);
        break;

      case 'QUANTITY':
        unit = '/qty';
        break;

      default:
        break;
    }
  } else if (rateBand.rateUnit === 'PERCENTAGE') {
    unit = ' %';
  }
  return unit;
}

const getMajorSymbol = (currency: string | undefined) => {
  const symbol = new Intl.NumberFormat('en', {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol', // This gets the correct short form for currencies with no symbol
  })
    .formatToParts(1)
    .find(x => x.type === 'currency');
  return symbol && symbol.value;
};

export function formatRateAmount(
  rateAmount: number | undefined,
  currencyCode: string | undefined,
  chargeType: types.ChargeType,
  rateUnit: types.RateUnit | undefined
): string {
  let symbol = '¢';
  const amount = rateAmount === undefined ? '' : rateAmount;
  const currency = currencyCode || 'USD';
  if (rateUnit === types.RateUnit.PERCENTAGE) {
    return `${amount}`;
  }
  if (chargeType == types.ChargeType.CONSUMPTION_BASED) {
    symbol = '¢';
    return `${amount}${symbol}`;
  } else {
    symbol = getMajorSymbol(currency) || '$';
    return `${symbol}${amount}`;
  }
}
