import { Text } from '@arcadiapower/shrike';
import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Table, Th, Tr, Td } from '@arcadiapower/gen-react-lib';
import appConstants from '../../../app-constants';
import OverlayLink from '../../OverlayLink/OverlayLink';

export interface TaskHistoryParams {
  previousDocTask?: number | null;
  previousDeTask?: number | null;
  currentDocTask?: number | null;
  currentDeTask?: number | null;
}

const TaskHistory: FC<TaskHistoryParams> = ({
  previousDocTask,
  previousDeTask,
  currentDocTask,
  currentDeTask,
}) => {
  return (
    <>
      <Row>
        <Text color="primary" opacity="high" tag="h3" textStyle="heading700" className="ml-4 mt-3">
          Task References
        </Text>
      </Row>
      <Row className="mt-3 ml-2">
        <Col xs={9} className="text-center">
          <Table bordered hover responsive>
            <thead>
              <tr>
                <Th key="taskDetails_previousTask" colSpan={2}>
                  Previous Task
                </Th>
                <Th key="taskDetails_currentTask" colSpan={2}>
                  Current Task
                </Th>
              </tr>
            </thead>
            <tbody>
              <Tr className="text-center">
                <td>Document Task</td>
                <td>Data Entry Task</td>
                <td>Document Task</td>
                <td>Data Entry Task</td>
              </Tr>
              <Tr className="text-center">
                {[previousDocTask, previousDeTask, currentDocTask, currentDeTask].map(
                  (task, index) => (
                    <td key={index}>
                      {task && (
                        <OverlayLink
                          id={task}
                          to={true}
                          url={`${appConstants.routes.taskDetails}/${task}`}
                          displayText={`${task}`}
                        />
                      )}
                    </td>
                  )
                )}
              </Tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default TaskHistory;
