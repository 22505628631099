export enum ActionType {
  ASSIGN = 'canAssignTask',
  SUBMIT = 'canSubmitTask',
  CANCEL = 'canCancelTask',
  ASSIGNTOREVIEWER = 'canAssignTaskToReviewer',
  REWORK = 'canSendTaskForRework',
  SAVE = 'saveTaskAnswer',
  APPROVE = 'canApproveTask',
  PUBLISH = 'canPublishTask',
  UPDATE_TASK_METADATA = 'canUpdateTaskMetaData',
  UPDATE_DOCUMENT_SECTION = 'canUpdateDocumentSection',
}
