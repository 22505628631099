import React, { FC, ReactElement, useEffect, useState, useCallback } from 'react';
import { Pagination, Table, Td, Th, Tr, useSortableData } from '@arcadiapower/gen-react-lib';
import { Button, DatePicker, Icon, IconButton, Loading, Text } from '@arcadiapower/shrike';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import styles from './LookupValuesList.module.scss';
import {
  fetchForecastedLookupValues,
  selectLookupValues,
} from '../../state/lookupValues/lookupValuesByPropertyKey';
import { useDispatch, useSelector } from 'react-redux';
import { GetLookupValuesRequest } from '../../task-api/v2/api/task-api-v2';
import { LookupValueV2Transformed } from '../../task-api/v2/types/LookupValueV2Transformed';
import { applyTimeZoneOffSet } from '../../utils/dateUtils';
import { restClient } from '@genability/api';
import { selectTaskWorkflowEntityPostAction } from '../../state/tasksV2/TasksActionSlice';
import { DateTime } from 'luxon';

export interface ForeCastedLookupValuesProps {
  propertyKey: string;
}

type CustomLookupValue = LookupValueV2Transformed & {
  index: number;
};

const HEADERS = [
  {
    property: 'index',
    value: '#',
  },
  {
    property: 'lookupId',
    value: 'Lookup Id',
  },
  {
    property: 'fromDateTime',
    value: 'From Date',
  },
  {
    property: 'toDateTime',
    value: 'To Date',
  },
  {
    property: 'actualValue',
    value: 'Actual Value',
  },
  {
    property: 'forecastValue',
    value: 'Forecast Value',
  },
  // { UTD-1215 Hiding the Forcase method from UI
  //   property: 'forecastMethod',
  //   value: 'Forecast Method',
  // },
  {
    property: 'lseForecastValue',
    value: 'LSE Forecast Value',
  },
  {
    property: 'createdDate',
    value: 'Created Date',
  },
  {
    property: 'createdUsername',
    value: 'Created By',
  },
  {
    property: 'lastUpdatedDate',
    value: 'Last Updated Date',
  },
  {
    property: 'lastUpdatedUsername',
    value: 'Last Updated By',
  },
];

const ForeCastedLookupValuesList: FC<ForeCastedLookupValuesProps> = ({
  propertyKey,
}): ReactElement => {
  const dispatch = useDispatch();
  const {
    forecastedLookupValues,
    forecastedLookupCount,
    forecastedLookupLoading,
    forecastedLookupError,
    foreCastedLookupStartDate,
    foreCastedLookupEndDate,
  } = useSelector(selectLookupValues);
  const { published } = useSelector(selectTaskWorkflowEntityPostAction);
  const [fromDate, setFromDate] = useState<string | undefined>(foreCastedLookupStartDate);
  const [toDate, setToDate] = useState<string | undefined>(foreCastedLookupEndDate);
  const [page, setPage] = useState(1);
  const [customLookupValues, setCustomLookupValues] = useState<CustomLookupValue[] | undefined>(
    undefined
  );
  const [searchButtonClicked, setSearchButtonClicked] = useState<boolean>(false);
  const [pageClicked, setPageClicked] = useState<boolean>(false);
  const [toggleRefresh, setToggleRefresh] = useState<boolean>(false);
  const pageCount = 30;
  const totalPageCount = Math.ceil(forecastedLookupCount / pageCount);

  const { sortedItems, sortConfig, handleSort } = useSortableData(forecastedLookupValues, {
    sortOn: ['fromDateTime'],
    sortOrder: [restClient.SortOrder.ASC],
  });

  const handleSelectPage = useCallback(selectedPage => {
    setPage(selectedPage);
    setPageClicked(prevState => !prevState);
  }, []);

  const handleSubmitButtonClick = useCallback(() => {
    setPage(1);
    setSearchButtonClicked(prevState => !prevState);
  }, []);

  const handleRefreshButtonClick = useCallback(async () => {
    setPage(1);
    setFromDate(foreCastedLookupStartDate);
    setToDate(foreCastedLookupEndDate);
    setToggleRefresh(toggleRefresh => !toggleRefresh);
  }, []);

  useEffect(() => {
    if (published) {
      setPage(1);
      setToggleRefresh(toggleRefresh => !toggleRefresh);
    }
  }, [published]);

  useEffect(() => {
    setPage(1);
    setFromDate(foreCastedLookupStartDate);
    setToDate(foreCastedLookupEndDate);
    setToggleRefresh(toggleRefresh => !toggleRefresh);
  }, [foreCastedLookupStartDate, foreCastedLookupEndDate]);

  useEffect(() => {
    (async () => {
      await getLookupValues();
    })();
  }, [searchButtonClicked, pageClicked, sortConfig, toggleRefresh]);

  useEffect(() => {
    const lookupValues: CustomLookupValue[] | undefined = sortedItems
      ? sortedItems.map((value, index) => ({
          ...value,
          index: (page - 1) * pageCount + index + 1,
        }))
      : undefined;
    setCustomLookupValues(lookupValues);
  }, [sortedItems]);

  const getLookupValues = async () => {
    const request = new GetLookupValuesRequest();
    request.fromDateTime = fromDate ? fromDate : foreCastedLookupStartDate;
    request.toDateTime = toDate ? toDate : foreCastedLookupEndDate;
    request.sortOn = sortConfig.sortOn;
    request.sortOrder = sortConfig.sortOrder;
    request.pageCount = pageCount;
    request.pageStart = (page - 1) * pageCount;
    dispatch(fetchForecastedLookupValues(request, propertyKey));
  };

  const mappedHeaders = () => {
    const mapped = HEADERS.map(({ property, value }) => (
      <Th
        className="text-center"
        key={property}
        sortConfig={sortConfig}
        sortKey={property}
        onClick={property ? () => handleSort(property) : undefined}
      >
        {value}
      </Th>
    ));
    return mapped;
  };

  const mappedForeCastedValuesRows = () => {
    return customLookupValues?.length
      ? customLookupValues.map(lookupValue => (
          <Tr
            className={`${styles.row} border-top-3 border-info`}
            key={`forecasted-${lookupValue?.lookupId}`}
          >
            <Td className="text-center">{lookupValue?.index}</Td>
            <Td className="text-center">{lookupValue?.lookupId}</Td>
            <Td className="text-center">
              {lookupValue?.fromDateTime
                ? new Date(lookupValue.fromDateTime).toLocaleDateString()
                : ''}
            </Td>
            <Td className="text-center">
              {lookupValue?.toDateTime ? new Date(lookupValue.toDateTime).toLocaleDateString() : ''}
            </Td>
            <Td className="text-center">{lookupValue?.actualValue}</Td>
            <Td className="text-center">{lookupValue?.forecastValue}</Td>
            {/* <Td className="text-center">{lookupValue?.forecastMethod}</Td> */}
            <Td className="text-center">{lookupValue?.lseForecastValue}</Td>
            <Td className="text-center">
              {lookupValue?.createdDateTime
                ? DateTime.fromMillis(lookupValue?.createdDateTime, { zone: 'utc' }).toFormat(
                    "yyyy-MM-dd'T'HH:mm:ss"
                  )
                : ''}
            </Td>
            <Td className="text-center">{lookupValue?.createdUsername}</Td>
            <Td className="text-center">
              {lookupValue?.lastUpdatedDateTime
                ? DateTime.fromMillis(lookupValue?.lastUpdatedDateTime, { zone: 'utc' }).toFormat(
                    "yyyy-MM-dd'T'HH:mm:ss"
                  )
                : ''}
            </Td>
            <Td className="text-center">{lookupValue?.lastUpdatedUsername}</Td>
          </Tr>
        ))
      : null;
  };

  const renderRows = () => {
    if (!customLookupValues?.length) {
      return (
        <Tr>
          <Td className="text-center" colSpan={HEADERS.length}>
            No data available...
          </Td>
        </Tr>
      );
    }
    return <>{mappedForeCastedValuesRows()}</>;
  };

  return (
    <Container fluid className="p-4">
      <Row>
        <Col className="mb-4" md={2}>
          <DatePicker
            label="From Date"
            value={fromDate ? new Date(fromDate) : undefined}
            handleChange={updatedDate => {
              if (updatedDate)
                setFromDate(applyTimeZoneOffSet(updatedDate)?.toISOString().slice(0, -5));
            }}
          />
        </Col>
        <Col className="mb-4" md={2}>
          <DatePicker
            label="To Date"
            value={toDate ? new Date(toDate) : undefined}
            handleChange={updatedDate => {
              if (updatedDate)
                setToDate(applyTimeZoneOffSet(updatedDate)?.toISOString().slice(0, -5));
            }}
          />
        </Col>
        <Col className="mb-4" md={2}>
          <Button className="mt-4" onClick={handleSubmitButtonClick}>
            <Icon className="mr-2" icon="UISearch" color="primaryInverse" /> Search
          </Button>
        </Col>
        <Col className="d-flex  align-items-center justify-content-end">
          {customLookupValues && customLookupValues.length > 0 && (
            <>
              <Text
                color="primary"
                opacity="high"
                tag="p"
                textStyle="paragraph400"
                className="mr-2 mb-3"
              >
                Showing {(page - 1) * pageCount + 1} -{' '}
                {Math.min(page * pageCount, forecastedLookupCount)} of
                {` ${forecastedLookupCount}`} entries
              </Text>

              <Pagination
                activePage={page}
                totalPages={totalPageCount}
                onSelectPage={handleSelectPage}
              />
              <IconButton
                className="ml-2 mb-3"
                aria-label="refresh"
                icon="UIRefresh"
                onClick={handleRefreshButtonClick}
              />
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Table bordered striped hover>
          <thead>
            <Tr>{mappedHeaders()}</Tr>
          </thead>
          <tbody>
            {forecastedLookupLoading ? (
              <Tr className={`${styles.row}`}>
                <Td colSpan={HEADERS.length} className="text-center">
                  <Loading />
                </Td>
              </Tr>
            ) : (
              renderRows()
            )}
            {forecastedLookupLoading ? (
              <Tr>
                <Td colSpan={HEADERS.length}>
                  <Alert variant="error">{forecastedLookupError}</Alert>
                </Td>
              </Tr>
            ) : null}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
};

export default ForeCastedLookupValuesList;
