import React, { FC, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';
import UtilitiesFilter from '../../../components/utilities/UtilitiesFilter/UtilitesFilter';
import UtilitiesList from '../../../components/utilities/UtilitiesList/UtilitiesList';
import {
  initialUtilityFilterContext,
  IUtilityFilter,
  onUtilitiesFilterChange,
  UtilityFilterContext,
} from '../../../context/utilityFilterContext';

const Utilites: FC<RouteComponentProps> = () => {
  const [newFilter, setNewFilter] = useState<IUtilityFilter>(initialUtilityFilterContext.filter);
  return (
    <UtilityFilterContext.Provider
      value={{
        filter: newFilter,
        setFilter: setNewFilter,
        onFilterChange: onUtilitiesFilterChange,
      }}
    >
      <Container fluid className="py-3">
        <UtilitiesFilter />
        <Row>
          <UtilitiesList />
        </Row>
      </Container>
    </UtilityFilterContext.Provider>
  );
};

export default Utilites;
