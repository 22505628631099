export const motherLink = (
  resourceName: 'tasks' | 'assignments' | 'lses' | '',
  id: number | undefined
): string => {
  const serverName = `${process.env.REACT_APP_MOTHER_SERVER}`;
  if (resourceName && id) {
    return `${serverName}/${resourceName}/${id}`;
  }
  return '';
};
