import { restClient } from '@genability/api';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OpsManagementApiClient } from '../../../GenApiClient';
import { TariffDataRequestParams } from '../../../components/OpsManagement/Config/TariffConfigTable';
import { TariffStatusData } from '../../../components/OpsManagement/Status/TariffStatusTable';
import { addNotification } from '../../notification/notificationSlice';
import { handleUnexpectedThunkException } from '../../reduxUtils';
import { RootState } from '../../rootReducer';

export interface TariffStatusDataResponse {
  results: TariffStatusData[];
  apiStatus: 'idle' | 'pending' | 'resolved' | 'rejected';
  errors: restClient.ResponseError[] | undefined;
  count: number;
}
export interface TariffStatusFilterCriteria {
  lseIds?: any[];
  lseNames?: string[];
  tariffNames?: string[];
  tariffCodes?: string[];
  priorityList?: string[];
  tariffTier?: string[];
  customerTier?: string[];
  masterTariffIds?: number[];
  modellers?: string[];
  reviewers?: string[];
  sentinelConfigured?: boolean;
  sentinelStatus?: string[];
  dueInDays?: string;
  customerCount?: string;
  apiCount?: string;
  tariffTypes?: string[];
  monitoringStatusList?: string[];
  monitoringNotes?: string[];
  dateFilterCriteria?: { dateColumnName: string; fromDate: string; toDate: string }[];
}
export interface TariffStatusProps {
  paginationCriteria?: { pageCount: number; pageStart: number };
  sortCriteria: { sortOn: string[]; sortOrder: string[] };
  tariffStatusFilterCriteria: TariffStatusFilterCriteria;
}
export interface tariffStatusRequestProps {
  request: TariffStatusProps;
  params: TariffDataRequestParams;
}
export const initialState: TariffStatusDataResponse = {
  results: [],
  apiStatus: 'idle',
  count: 0,
  errors: undefined,
};

export const fetchTariffStatusData = createAsyncThunk(
  'workflowManagement/status/tariff',
  async ({ request, params }: tariffStatusRequestProps, { dispatch, rejectWithValue }) => {
    try {
      const client = await OpsManagementApiClient();
      const response: restClient.PagedResponse<TariffStatusData> =
        await client.opsData.fetchTariffStatusData(request, params);
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
      return response;
    } catch (err) {
      dispatch(addNotification('Fetching TariffStatus Failed', NotificationLevel.Error));
      return rejectWithValue(handleUnexpectedThunkException(err, 'TariffStatus', dispatch));
    }
  }
);

export const TariffStatusDataSlice = createSlice({
  name: 'TariffStatusData',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchTariffStatusData.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(
      fetchTariffStatusData.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<TariffStatusData>>) => {
        const { count, results, errors } = action.payload;
        if (errors) {
          state.apiStatus = 'rejected';
          state.errors = errors;
        } else {
          state.apiStatus = 'resolved';
          state.count = count;
          state.results = results;
          state.errors = undefined;
        }
      }
    );
    builder.addCase(fetchTariffStatusData.rejected, state => {
      state.apiStatus = 'rejected';
    });
  },
});

export default TariffStatusDataSlice.reducer;

export const selectTariffStatusData = (state: RootState): TariffStatusDataResponse => {
  return state.tariffStatusData;
};
