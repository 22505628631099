export enum FrequencyType {
  YEARLY = 'YEARLY',
  HOURLY = 'HOURLY',
  UNKNOWN = 'UNKNOWN',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  QUARTERLY = 'QUARTERLY',
  HALFYEARLY = 'HALFYEARLY',
  MONTHLY = 'MONTHLY',
}
