import { restClient } from '@genability/api';
import { TariffAdminApi } from './tariff-admin-api';
import { TaskApi } from './task-api';
import { TaskAssignmentApi } from './task-assignment-api';
import { WorkerApi } from './worker-api';
import { ReportingConfigApi } from './reporting-config';

export class GenabilityTaskApi {
  private static _instance: GenabilityTaskApi;

  private _credentials: restClient.RestApiCredentials;
  private _task: TaskApi | undefined;
  private _taskAssignment: TaskAssignmentApi | undefined;
  private _baseUrl: string;
  private _worker: WorkerApi | undefined;
  private _tariffAdmin: TariffAdminApi | undefined;
  private _reportingConfig: ReportingConfigApi | undefined;

  private constructor(baseUrl?: string, credentials?: restClient.RestApiCredentials) {
    this._baseUrl = baseUrl || 'https://api-dev.teamtariff.com';
    this._credentials = credentials || {};
  }

  public static configure(
    baseUrl?: string,
    credentials?: restClient.RestApiCredentials
  ): GenabilityTaskApi {
    return this._instance || (this._instance = new this(baseUrl, credentials));
  }

  public get task(): TaskApi {
    if (this._task === undefined) {
      this._task = new TaskApi(
        new restClient.GenabilityConfig({
          proxy: this._baseUrl,
          credentials: this._credentials,
        })
      );
    }
    return this._task;
  }

  public get taskAssignment(): TaskAssignmentApi {
    if (this._taskAssignment === undefined) {
      this._taskAssignment = new TaskAssignmentApi(
        new restClient.GenabilityConfig({
          proxy: this._baseUrl,
          credentials: this._credentials,
        })
      );
    }
    return this._taskAssignment;
  }

  public get worker(): WorkerApi {
    if (this._worker === undefined) {
      this._worker = new WorkerApi(
        new restClient.GenabilityConfig({
          proxy: this._baseUrl,
          credentials: this._credentials,
        })
      );
    }
    return this._worker;
  }

  public get tariffAdmin(): TariffAdminApi {
    if (this._tariffAdmin === undefined) {
      this._tariffAdmin = new TariffAdminApi(
        new restClient.GenabilityConfig({
          proxy: this._baseUrl,
          credentials: this._credentials,
        })
      );
    }
    return this._tariffAdmin;
  }

  public get reportingConfig(): ReportingConfigApi {
    if (this._reportingConfig === undefined) {
      this._reportingConfig = new ReportingConfigApi(
        new restClient.GenabilityConfig({
          proxy: this._baseUrl,
          credentials: this._credentials,
        })
      );
    }
    return this._reportingConfig;
  }
}
