import React, { FC, ReactNode, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Loading, Text } from '@arcadiapower/shrike';

import {
  fetchMasterTariffByPropertyKey,
  selectTariffDetails,
} from '../../state/tariffDetails/tariffDetailsSlice';
import { MasterTariffDetailsComponent } from '../MasterTariff/MasterTariffDetailsComponent';
import { PropertyKeyV2 } from '../../task-api/v2/types/PropertyKeyV2';
import { Table, Td, Tr } from '@arcadiapower/gen-react-lib';
import { GenPropertyChoice } from '@genability/api/dist/types';
export interface PropertyKeyDetailProps {
  propertyKey: PropertyKeyV2 | null;
}

type StyledProps = {
  children: ReactNode;
};
const Key: FC<StyledProps> = ({ children }) => (
  <div className="p-2 font-weight-bold">{children}</div>
);
const Value: FC<StyledProps> = ({ children }) => (
  <div className="p-2 text-muted text-break">{children}</div>
);

export const PropertyKeyDetail: FC<PropertyKeyDetailProps> = ({ propertyKey }) => {
  const { results, apiStatus } = useSelector(selectTariffDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    if (propertyKey?.keyName) dispatch(fetchMasterTariffByPropertyKey(propertyKey.keyName));
    console.log(propertyKey?.choices?.length);
  }, [propertyKey?.keyName]);

  return (
    <Container fluid>
      <Row className="justify-content-md-center">
        <Col xs={6}>
          <Key>Key Space:</Key>
          <Value>{propertyKey?.keyspace || '--'}</Value>
        </Col>
        <Col xs={6}>
          <Key>Key Attribute:</Key>
          <Value>{propertyKey?.formulaDetail || '--'}</Value>
        </Col>
        <Col xs={6}>
          <Key>Family:</Key>
          <Value>{propertyKey?.family || '--'}</Value>
        </Col>
        <Col xs={6}>
          <Key>Interval Quantity:</Key>
          <Value>{propertyKey?.lookbackIntervalQuantity || '--'}</Value>
        </Col>
        <Col xs={6}>
          <Key>Key Name:</Key>
          <Value>{propertyKey?.keyName || '--'}</Value>
        </Col>
        <Col xs={6}>
          <Key>Run Forecast:</Key>
          <Value>{String(propertyKey?.runForecast)}</Value>
        </Col>
        <Col xs={6}>
          <Key>LookBack Months:</Key>
          <Value>{propertyKey?.lookbackQuantity || '--'}</Value>
        </Col>
        <Col xs={6}>
          <Key>Quantity Unit:</Key>
          <Value>{propertyKey?.quantityUnit || '--'}</Value>
        </Col>
        <Col xs={6}>
          <Key>Lookback Period:</Key>
          <Value>{propertyKey?.lookbackPeriod || '--'}</Value>
        </Col>
        <Col xs={6}>
          <Key>Entity ID:</Key>
          <Value>{propertyKey?.entityId || '--'}</Value>
        </Col>
        <Col xs={6}>
          <Key>Entity Type:</Key>
          <Value>{propertyKey?.entityType || '--'}</Value>
        </Col>
        <Col xs={6}>
          <Key>Entity Name:</Key>
          <Value>{propertyKey?.entityName || '--'}</Value>
        </Col>
        <Col xs={6}>
          <Key>Season ID:</Key>
          <Value>{propertyKey?.lookbackSeasonId || '--'}</Value>
        </Col>
        <Col xs={6}>
          <Key>Property Data Type:</Key>
          <Value>{propertyKey?.dataType || '--'}</Value>
        </Col>
        <Col xs={12}>
          <Key>Time Of Use ID:</Key>
          <Value>{propertyKey?.lookbackTimeOfUseId || '--'}</Value>
        </Col>
        <Col xs={12}>
          <Key>Master Tariff Id:</Key>
          {apiStatus === 'idle' || apiStatus === 'pending' ? (
            <Loading backgroundColor="primary" />
          ) : (
            <MasterTariffDetailsComponent
              tariffDetails={results}
              propertyKeyName={propertyKey?.keyName}
            />
          )}
        </Col>
        {propertyKey?.choices && (
          <Col xs={12}>
            <Key>Property Choices:</Key>
            <Table bordered striped hover>
              <thead>
                <Td className="font-weight-bold">Choice Name</Td>
                <Td className="font-weight-bold">Choice Value</Td>
              </thead>
              <tbody>
                {propertyKey?.choices.map((choice: GenPropertyChoice) => (
                  <Tr key={choice.dataValue}>
                    <Td>{choice.displayValue}</Td>
                    <Td>{choice.dataValue}</Td>
                  </Tr>
                ))}
              </tbody>
            </Table>
          </Col>
        )}
      </Row>
    </Container>
  );
};
