import { restClient, types } from '@genability/api';

export function isRateCriteriaDefaultExpected(tariffProperty: types.TariffProperty): boolean {
  if (tariffProperty.keyName === 'consumption' || tariffProperty.keyName === 'territoryId') {
    return false;
  }
  return true;
}

export function generateTariffPropertyAlternateKey(prop: types.TariffProperty): string {
  const { keyName, period, quantityKey, propertyTypes } = prop;
  return (
    keyName +
    (period ? '_' + period : '') +
    (quantityKey ? '_' + quantityKey : '') +
    (propertyTypes ? '_' + propertyTypes : '')
  );
}

export function isSameTariffProperty(
  prop1: types.TariffProperty,
  prop2: types.TariffProperty
): boolean {
  return generateTariffPropertyAlternateKey(prop1) === generateTariffPropertyAlternateKey(prop2);
}

export function compareTariffPropertyArrays(
  props1: types.TariffProperty[],
  props2: types.TariffProperty[]
): boolean {
  // If our arrays are not the same length they're not
  // equal, save ourselves some work
  if (props1.length !== props2.length) return false;

  const [propsHash1, propsHash2] = [props1, props2].map(props => {
    return props
      .map((prop: types.TariffProperty) => generateTariffPropertyAlternateKey(prop))
      .sort()
      .join(',');
  });

  return propsHash1 === propsHash2;
}
