import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { restClient } from '@genability/api';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { TaskApiV2Client } from '../../GenApiClient';
import { addNotification } from '../notification/notificationSlice';
import { RootState } from '../rootReducer';
import { AppThunk } from '../store';
import { TaskWorkflowEntity } from '../../task-api/v2/types/TaskWorkflowEntity';
import { WorkflowConfigType } from '../../task-api/v2/types/WorkflowConfigType';
import { TaskType } from '../../task-api/v2/types/TaskType';
import { EntityType } from '../../task-api/v2/types/EntityType';
import { saveIntermediateAnswer } from './intermediateSaveTaskSlice';
import { TaskV2Document } from '../../task-api/v2/types/TaskV2Document';
import axios from 'axios';

export interface TaskWorkflowState {
  deTaskId: number | null | undefined;
  workflowId: number | null;
  document: TaskV2Document | null;
  isLoading: boolean;
  error: string | null;
}

interface createTaskSuccess {
  deTaskId: number | null | undefined;
  workflowId: number | null;
  document?: TaskV2Document | null;
}

export const initialState: TaskWorkflowState = {
  deTaskId: null,
  workflowId: null,
  isLoading: false,
  error: '',
  document: null,
};

function createTaskStartLoading(state: TaskWorkflowState) {
  state.isLoading = true;
}

function createTaskLoadingFailed(state: TaskWorkflowState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

function resetTaskState_(state: TaskWorkflowState) {
  state.deTaskId = null;
  state.workflowId = null;
  state.isLoading = false;
  state.error = '';
  state.document = null;
}

export const task = createSlice({
  name: 'createTask',
  initialState,
  reducers: {
    createTaskStart: createTaskStartLoading,
    createTaskSuccess(state, { payload }: PayloadAction<createTaskSuccess>) {
      const { deTaskId, workflowId, document } = payload;
      state.isLoading = false;
      state.error = null;
      state.deTaskId = deTaskId;
      state.workflowId = workflowId;
      if (document) {
        state.document = document;
      }
    },
    createTaskFailure: createTaskLoadingFailed,
    resetTaskState: resetTaskState_,
  },
});

export const { createTaskStart, createTaskSuccess, createTaskFailure, resetTaskState } =
  task.actions;

export default task.reducer;

export const createAddLookupTask =
  (
    propertyKey: string,
    taskSource: string,
    isGlobalPropertyKey: boolean,
    data?: FormData,
    answerJSON?: string
  ): AppThunk =>
  async dispatch => {
    try {
      dispatch(createTaskStart());
      const client = await TaskApiV2Client();
      const {
        results: createTaskResult,
        errors: createTaskErrors,
      }: restClient.SingleResponse<TaskWorkflowEntity> = await client.taskV2.createTask(
        {
          workflowConfigId: WorkflowConfigType.ADD_LOOKUP_AUTO,
          taskType: TaskType.ADD_LOOKUP,
          entityType: EntityType.PROPERTY,
          sourceEntityId: propertyKey,
        },
        taskSource
      );
      if (createTaskErrors) {
        throw new Error(createTaskErrors[0].message);
      }

      const workflowId = createTaskResult[0].tasks[0].workflowInstanceId;
      if (isGlobalPropertyKey) {
        const deTaskId = createTaskResult[0].tasks[0].taskId;
        await dispatch(createTaskSuccess({ deTaskId, workflowId }));
        if (answerJSON) dispatch(saveIntermediateAnswer(deTaskId, workflowId, answerJSON));
      } else {
        if (!data) {
          throw new Error('Document is not present');
        }
        const docTaskId = createTaskResult[0].tasks[0].taskId;

        const {
          results: presignedURLResult,
          errors: presignedURLError,
        }: restClient.SingleResponse<string> = await client.taskV2.getPresignedUrl(
          workflowId,
          docTaskId
        );

        if (presignedURLError) {
          throw new Error(presignedURLError[0].message);
        }

        const uploadResponse = await axios.put(presignedURLResult[0], data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (uploadResponse.status !== 200) {
          throw new Error('Failed to upload document');
        }

        const {
          results: uploadDocumentResult,
          errors: uploadDocumentErrors,
        }: restClient.SingleResponse<TaskWorkflowEntity> = await client.taskV2.uploadDocument(
          { presignedurl: presignedURLResult[0] },
          workflowId,
          docTaskId
        );

        if (uploadDocumentErrors) {
          throw new Error(uploadDocumentErrors[0].message);
        }

        const docTask = uploadDocumentResult[0].tasks.find(
          task => task.taskType === TaskType.ADD_DOCUMENT
        );

        let document: TaskV2Document | null = null;
        if (docTask?.taskAssignmentAnswers?.length) {
          document = docTask?.taskAssignmentAnswers[0].answerValue
            ? JSON.parse(docTask?.taskAssignmentAnswers[0].answerValue)
            : null;
        }

        const deTaskId = uploadDocumentResult[0].tasks.find(
          task => task.taskType === TaskType.ADD_LOOKUP
        )?.taskId;

        dispatch(createTaskSuccess({ deTaskId, workflowId, document }));
      }
    } catch (err) {
      if (err) {
        const errorMessage = (err instanceof Error && err.message) || String(err);
        dispatch(createTaskFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };

export const createEditLookupTask =
  (data: string, propertyKey: string, taskSource: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(createTaskStart());
      const client = await TaskApiV2Client();
      const {
        results: createTaskResult,
        errors: createTaskErrors,
      }: restClient.SingleResponse<TaskWorkflowEntity> = await client.taskV2.createTask(
        {
          workflowConfigId: WorkflowConfigType.EDIT_LOOKUP_AUTO,
          taskType: TaskType.EDIT_LOOKUP,
          entityType: EntityType.PROPERTY,
          sourceEntityId: propertyKey,
        },
        taskSource
      );
      if (createTaskErrors) {
        throw new Error(createTaskErrors[0].message);
      }

      const workflowId = createTaskResult[0].tasks[0].workflowInstanceId;
      const deTaskId = createTaskResult[0].tasks[0].taskId;
      await dispatch(createTaskSuccess({ deTaskId, workflowId }));
      dispatch(saveIntermediateAnswer(deTaskId, workflowId, data));
    } catch (err) {
      if (err) {
        const errorMessage = (err instanceof Error && err.message) || String(err);
        dispatch(createTaskFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };

export const selectTaskWorkflowEntity = (state: RootState): TaskWorkflowState => {
  return state.taskWorkflowEntity;
};
