import { Loading, Modal, Text } from '@arcadiapower/shrike';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Alert, Col, Container, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchMonitoringHistory,
  selectMonitoringHistory,
} from '../../../state/OpsManagement/Status/MonitoringHistorySlice';
import { Pagination, Td, Th, Tr, useSortableData } from '@arcadiapower/gen-react-lib';
import { GetMonitoringHistoryRequest } from '../../../ops-api/api/ops-api';
import { EntityType } from '../../../task-api/v2/types/EntityType';
import { restClient } from '@genability/api';
import styles from './MonitoringHistoryModal.module.scss';
import { applyTimeZoneOffSet } from '../../../utils/dateUtils';
export interface MonitoringHistoryModalProps {
  monitoringInventoryId?: number;
  show: boolean;
  onHide: () => void;
  entityType: EntityType;
  entityID?: string;
}

const MonitoringHistoryModal: FC<MonitoringHistoryModalProps> = ({
  show,
  onHide,
  monitoringInventoryId,
  entityType,
  entityID,
}): ReactElement => {
  const dispatch = useDispatch();
  const { results, fetchApiStatus, count, errors } = useSelector(selectMonitoringHistory);
  const [page, setPage] = useState(1);
  const pageCount = 10;
  const totalPageCount = Math.ceil(count / pageCount);

  const { sortedItems, sortConfig, handleSort } = useSortableData(results, {
    sortOn: ['monitoredDate'],
    sortOrder: [restClient.SortOrder.DESC],
  });

  useEffect(() => {
    const request = new GetMonitoringHistoryRequest();
    request.monitoringInventoryId = monitoringInventoryId;
    request.sortOn = sortConfig.sortOn;
    request.sortOrder = sortConfig.sortOrder;
    request.pageCount = pageCount;
    request.pageStart = (page - 1) * pageCount;
    dispatch(fetchMonitoringHistory(request));
  }, [page, sortConfig]);

  const TARIFF_HISTORY_HEADERS = [
    {
      property: 'monitoredDate',
      value: 'Monitored Date',
    },
    {
      property: 'createdByUserName',
      value: 'Monitored By',
    },
    {
      property: 'monitoredEntityId',
      value: 'Tariff ID',
    },
    {
      property: 'monitoredEntityEditId',
      value: 'Edit ID',
    },
    {
      property: 'monitoringStatus',
      value: 'Monitoring Status',
    },
    {
      property: 'monitoringNotes',
      value: 'Monitoring Notes',
    },
  ];

  const LOOKUP_HISTORY_HEADERS = [
    {
      property: 'monitoredDate',
      value: 'Monitored Date',
    },
    {
      property: 'createdByUserName',
      value: 'Created By',
    },
    {
      property: 'monitoredEntityId',
      value: 'Monitored Lookup ID',
    },
    {
      property: 'monitoringStatus',
      value: 'Monitoring Status',
    },
    {
      property: 'monitoringNotes',
      value: 'Monitoring Notes',
    },
  ];

  const mappedTariffHistory = () => {
    return sortedItems?.length
      ? sortedItems.map(history => (
          <Tr key={`history-${history.monitoringHistoryId}`}>
            <Td className="text-center">
              {new Date(history?.monitoredDate)?.toLocaleString('sv-SE')}
            </Td>
            <Td className="text-center">{history?.createdByUserName.split('@')[0]}</Td>
            <Td className="text-center">{history?.monitoredEntityId}</Td>
            <Td className="text-center">{history?.monitoredEntityEditId}</Td>
            <Td className="text-center">{history?.monitoringStatus}</Td>
            <Td className="text-center">{history?.monitoringNotes}</Td>
          </Tr>
        ))
      : null;
  };

  const mappedTariffHistoryHeaders = () => {
    const mapped = TARIFF_HISTORY_HEADERS.map(({ property, value }) => (
      <Th
        className="text-center"
        key={property}
        sortConfig={sortConfig}
        sortKey={property}
        onClick={property ? () => handleSort(property) : undefined}
      >
        {value}
      </Th>
    ));
    return mapped;
  };

  const renderTariffHistoryRows = () => {
    if (!errors && !sortedItems?.length) {
      return (
        <Tr>
          <Td className="text-center" colSpan={TARIFF_HISTORY_HEADERS.length}>
            History Not found.
          </Td>
        </Tr>
      );
    }
    return <>{mappedTariffHistory()}</>;
  };

  const mappedLookupHistory = () => {
    return sortedItems.length
      ? sortedItems.map(history => (
          <Tr key={`history-${history.monitoringHistoryId}`}>
            <Td className="text-center">
              {new Date(history?.monitoredDate)?.toLocaleString('sv-SE')}
            </Td>
            <Td className="text-center">{history?.createdByUserName.split('@')[0]}</Td>
            <Td className="text-center">{history?.monitoredEntityId}</Td>
            <Td className="text-center">{history?.monitoringStatus}</Td>
            <Td className="text-center">{history?.monitoringNotes}</Td>
          </Tr>
        ))
      : null;
  };

  const mappedLookupHistoryHeaders = () => {
    const mapped = LOOKUP_HISTORY_HEADERS.map(({ property, value }) => (
      <Th
        className="text-center"
        key={property}
        sortConfig={sortConfig}
        sortKey={property}
        onClick={property ? () => handleSort(property) : undefined}
      >
        {value}
      </Th>
    ));
    return mapped;
  };

  const renderLookupHistoryRows = () => {
    if (!errors && !sortedItems?.length) {
      return (
        <Tr>
          <Td className="text-center" colSpan={LOOKUP_HISTORY_HEADERS.length}>
            History Not found.
          </Td>
        </Tr>
      );
    }
    return <>{mappedLookupHistory()}</>;
  };
  return (
    <Modal size="large" aria-label="Modal" isOpen={show} className={styles.monitoringHistory}>
      <Modal.Header
        onClose={onHide}
        title={
          'Monitoring History | ' +
          (entityType === EntityType.TARIFF ? 'MTID: ' + entityID : 'PropertyKey: ' + entityID)
        }
      ></Modal.Header>
      <Modal.Content>
        <Row>
          <Col className="d-flex align-items-center justify-content-end">
            <Text
              color="primary"
              opacity="high"
              tag="p"
              textStyle="paragraph400"
              className="mr-2 mb-3"
            >
              Showing {(page - 1) * pageCount + 1} - {Math.min(page * pageCount, count)} of
              {` ${count}`} entries
            </Text>

            <Pagination
              activePage={page}
              totalPages={totalPageCount}
              onSelectPage={selectedPage => setPage(selectedPage)}
            />
          </Col>
        </Row>
        <Container fluid className={styles.monitoringHistoryContainer}>
          <Row>
            <Table bordered striped hover>
              <thead>
                <Tr>
                  {entityType === EntityType.TARIFF
                    ? mappedTariffHistoryHeaders()
                    : mappedLookupHistoryHeaders()}
                </Tr>
              </thead>
              <tbody>
                {fetchApiStatus == 'pending' || fetchApiStatus == 'idle' ? (
                  <Tr>
                    <Td
                      colSpan={
                        entityType === EntityType.TARIFF
                          ? TARIFF_HISTORY_HEADERS.length
                          : LOOKUP_HISTORY_HEADERS.length
                      }
                      className="text-center w-100"
                    >
                      <Loading />
                    </Td>
                  </Tr>
                ) : entityType === EntityType.TARIFF ? (
                  renderTariffHistoryRows()
                ) : (
                  renderLookupHistoryRows()
                )}
                {errors ? (
                  <Tr>
                    <Td
                      className="text-center"
                      colSpan={
                        entityType === EntityType.TARIFF
                          ? TARIFF_HISTORY_HEADERS.length
                          : LOOKUP_HISTORY_HEADERS.length
                      }
                    >
                      <Alert variant="error">{errors}</Alert>
                    </Td>
                  </Tr>
                ) : null}
              </tbody>
            </Table>
          </Row>
        </Container>
      </Modal.Content>
    </Modal>
  );
};

export default MonitoringHistoryModal;
