import React, { useCallback, useEffect, useState } from 'react';
import {
  IconButton,
  Pagination,
  Table,
  Td,
  Th,
  Tr,
  useSortableData,
  NotificationLevel,
} from '@arcadiapower/gen-react-lib';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTariffTasks, selectTasks } from '../../state/tariffTasks/tariffTasksSlice';
import { GetTasksRequestV2 } from '../../task-api/v2/api/task-api-v2';
import { Loading, Checkbox, Modal, Badge, Text, Button, Icon } from '@arcadiapower/shrike';
import { CurrentUser, selectCurrentUser } from '../../state/currentUser/currentUserSlice';
import { selectedTaskFilter, TaskFilter } from '../../state/taskFilter/taskFilterSlice';
import { format, parseISO } from 'date-fns';
import { restClient } from '@genability/api';
import { Task } from '../../task-api/v2/types/TaskV2';
import {
  selectedDashboardFilter,
  enableTariffTaskFilter,
  enableTaskSourceFilter,
} from '../../state/dashboardFilter/dashboardFilterSlice';
import { TASK_FILTER_MAPPINGS } from '../../utils/taskV2Utils';
import OverlayLink from '../OverlayLink/OverlayLink';
import { TaskStatus } from '../../task-api/types/task';
import AssignTask from '../AssignTask/AssignTask';
import { taskAssignment } from '../../state/taskAssignment_V2/taskAssignmentV2Slice';
import { TaskType } from '../../task-api/v2/types/TaskType';
import { TaskSource } from '../../task-api/v2/types/TaskSource';
import BulkAssignTaskModal from '../BulkAssignment/BulkAssignTaskModal';
import { EntityType } from '../../task-api/v2/types/EntityType';
import TextField from '../TextField/TextField';
import { addNotification } from '../../state/notification/notificationSlice';
import { TaskApiV2Client } from '../../GenApiClient';

const TARIFF_TASKS_COLUMNS = [
  {
    label: '',
    key: 'selection',
  },
  {
    label: 'Task ID',
    key: 'taskId',
  },
  {
    label: 'Task Type',
    key: 'taskType',
  },
  {
    label: 'Created On',
    key: 'createdDate',
  },
  {
    label: 'Lse Name',
    key: 'lseName',
  },
  {
    label: 'MTID',
    key: 'masterTariffId',
  },
  {
    label: 'Task Status',
    key: 'taskStatus',
  },
  {
    label: 'Modeller',
    key: 'modeller',
  },
  {
    label: 'Reviewer',
    key: 'reviewer',
  },
];

const TariffTasks = () => {
  const dispatch = useDispatch();

  const currentUser: CurrentUser = useSelector(selectCurrentUser);
  const { results, apiStatus, count } = useSelector(selectTasks);
  const { activeFilter, activeTaskType } = useSelector(selectedTaskFilter);
  const {
    searchText,
    selectedStatusFilters,
    selectedTaskTypes,
    selectedTaskSource,
    applyFilters,
    searchDate,
  } = useSelector(selectedDashboardFilter);
  const { apiState: taskAssignmentAPIState } = useSelector(taskAssignment);

  const tasks: Task[] = results || [];

  const [showSpinner, setShowSpinner] = useState(false);
  const [exportLoad, setExportLoad] = useState<boolean>(false);
  const [toggleRefresh, setToggleRefresh] = useState(true);
  const [page, setPage] = useState(1);
  const pageCount = 10;
  const totalPageCount = Math.ceil(count / pageCount);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<number>(0);
  const [showAllMtid, setShowAllMtid] = useState<string | undefined>();
  const [lseIdForShowAll, setLseIdForShowAll] = useState<string | undefined>();
  const [selected, setSelected] = useState<boolean[]>(
    Array(results ? results.length : pageCount).fill(false)
  );
  const { sortedItems, sortConfig, handleSort } = useSortableData(tasks, {
    sortOn: ['createdDate'],
    sortOrder: [restClient.SortOrder.DESC],
  });

  const checkboxSelection = useCallback(
    (id: number) => {
      setSelected(prev => {
        const current = [...prev];
        if (selectAll && current[id]) {
          setSelectAll(false);
        }
        current[id] = !current[id];
        current[id] ? setSelectedCount(prev => prev + 1) : setSelectedCount(prev => prev - 1);
        return current;
      });
    },
    [selectedCount, selected]
  );

  const selectAllSelection = useCallback(() => {
    if (!sortedItems) return;
    let count = 0;
    setSelectAll(prev => {
      const selectedBuffer = Array(sortedItems ? sortedItems.length : pageCount).fill(false);
      if (prev) {
        setSelectedCount(0);
      } else {
        sortedItems.forEach((val, index) => {
          if (
            ((val?.taskType === TaskType.ADD_TARIFF ||
              val?.taskType === TaskType.EDIT_TARIFF ||
              val?.taskType === TaskType.REVISE_TARIFF) &&
              [TaskStatus.DRAFTING, TaskStatus.READY_TO_POST, TaskStatus.POSTED].includes(
                val?.taskStatus
              )) ||
            ((val?.taskStatus === TaskStatus.REJECTED ||
              val?.taskStatus === TaskStatus.READY_TO_REVIEW ||
              val?.taskStatus === TaskStatus.REVIEWING) &&
              ((val?.assignee && val?.assignee === currentUser.username) ||
                (val?.reviewer && val?.reviewer === currentUser.username)))
          ) {
            count += 1;
            selectedBuffer[index] = true;
          }
        });
        setSelectedCount(count);
      }
      setSelected(selectedBuffer);
      return !prev;
    });
  }, [selectAll, sortedItems, currentUser]);

  const handleSelectPage = useCallback(selectedPage => {
    setPage(selectedPage);
  }, []);

  useEffect(() => {
    if (results && results.length > 0) setSelected(Array(results.length).fill(false));
    setSelectAll(false);
    setSelectedCount(0);
  }, [results]);

  useEffect(() => {
    if (taskAssignmentAPIState == 'resolved') {
      refreshTabDetails();
    }
  }, [taskAssignmentAPIState]);

  //To be invoked once while the component gets mounted
  useEffect(() => {
    const enabledTaskType: TaskType[] = [];
    enabledTaskType.push(
      TaskType.ADD_TARIFF,
      TaskType.EDIT_TARIFF,
      TaskType.REVISE_TARIFF,
      TaskType.ADD_DOCUMENT,
      TaskType.REVISE_DOCUMENT
    );
    const enabledTaskSource: TaskSource[] = [TaskSource.UI, TaskSource.SCRAPER];
    dispatch(enableTariffTaskFilter(enabledTaskType));
    dispatch(enableTaskSourceFilter(enabledTaskSource));
    setEnabled(prev => prev + 1);
  }, []);

  useEffect(() => {
    setShowSpinner(apiStatus === 'idle' || apiStatus === 'pending');
  }, [apiStatus]);

  useEffect(() => {
    setPage(1);
    setEnabled(prev => prev + 1);
  }, [applyFilters]);

  useEffect(() => {
    if (enabled == 0) return;
    const request = populateFilters();
    dispatch(fetchTariffTasks(request));
  }, [dispatch, pageCount, page, activeFilter, toggleRefresh, sortConfig, enabled]);

  const populateFilters = useCallback(() => {
    const request = new GetTasksRequestV2();

    request.pageCount = pageCount;
    request.pageStart = (page - 1) * pageCount;
    request.sortOn = sortConfig.sortOn;
    request.sortOrder = sortConfig.sortOrder;
    request.searchText = searchText;
    request.creationDate = searchDate;
    request.taskType =
      selectedTaskTypes && selectedTaskTypes.length > 0
        ? selectedTaskTypes
        : [
            TaskType.ADD_TARIFF,
            TaskType.EDIT_TARIFF,
            TaskType.REVISE_TARIFF,
            TaskType.ADD_DOCUMENT,
            TaskType.REVISE_DOCUMENT,
          ];

    request.taskStatus =
      selectedStatusFilters && selectedStatusFilters.length > 0
        ? selectedStatusFilters
        : TASK_FILTER_MAPPINGS[activeFilter];

    if (activeFilter === TaskFilter.ALL_TASKS) {
      request.retrieveAllAssignedTasks = true;
    } else if (activeFilter === TaskFilter.UNASSIGNED) {
      request.assignee = ' ';
    } else {
      request.assignee = currentUser.username;
    }
    if (selectedTaskSource?.length) request.taskSource = selectedTaskSource;
    request.searchText = searchText;
    request.creationDate = searchDate;
    return request;
  }, [pageCount, page, activeFilter, toggleRefresh, sortConfig, enabled]);

  const exportFunction = async () => {
    setExportLoad(true);
    const request = populateFilters();
    request.assignee = currentUser.username;
    const client = await TaskApiV2Client();
    const response = await client.taskV2.exportTariffTasksFromV2Endpoint(request);
    if (response.errors) {
      dispatch(addNotification('Export Failed !', NotificationLevel.Error));
    } else {
      dispatch(
        addNotification(
          'Tariff tasks report will be dispatched to your email. please check after few minutes!!',
          NotificationLevel.Success
        )
      );
    }
    setExportLoad(false);
  };

  const dateFormatter = useCallback((date: string) => format(parseISO(date), 'dd-MMM-yyyy'), []);

  const refreshTabDetails = useCallback(() => {
    setToggleRefresh(!toggleRefresh);
  }, [toggleRefresh]);

  const mappedTariffValuesRows = useCallback(() => {
    return sortedItems?.length
      ? sortedItems.map((tariffTask, index) => (
          <Tr key={tariffTask.taskId}>
            <Td>
              <div className="d-flex justify-content-around">
                <Checkbox
                  checked={selected[index]}
                  onChange={() => checkboxSelection(index)}
                  disabled={
                    !(
                      [TaskStatus.DRAFTING, TaskStatus.READY_TO_POST, TaskStatus.POSTED].includes(
                        tariffTask?.taskStatus
                      ) &&
                      (tariffTask?.taskType === TaskType.ADD_TARIFF ||
                        tariffTask?.taskType === TaskType.EDIT_TARIFF ||
                        tariffTask?.taskType === TaskType.REVISE_TARIFF)
                    ) &&
                    !(
                      (tariffTask?.taskStatus === TaskStatus.REJECTED ||
                        tariffTask?.taskStatus === TaskStatus.APPROVED ||
                        tariffTask?.taskStatus === TaskStatus.READY_TO_REVIEW ||
                        tariffTask?.taskStatus === TaskStatus.REVIEWING) &&
                      (tariffTask?.taskType === TaskType.ADD_TARIFF ||
                        tariffTask?.taskType === TaskType.EDIT_TARIFF ||
                        tariffTask?.taskType === TaskType.REVISE_TARIFF) &&
                      ((tariffTask?.assignee && tariffTask?.assignee === currentUser.username) ||
                        (tariffTask?.reviewer && tariffTask?.reviewer === currentUser.username))
                    )
                  }
                />
              </div>
            </Td>
            <Td className="text-center  ">
              <OverlayLink
                id={tariffTask.taskId}
                openInNewTab
                url={
                  tariffTask?.taskType === TaskType.REVISE_DOCUMENT &&
                  tariffTask?.taskAssignmentId &&
                  tariffTask?.entityId &&
                  tariffTask?.assignee
                    ? `${process.env.REACT_APP_MOTHER_URL}/documents/${tariffTask?.entityId}/sections/review?taskId=${tariffTask?.taskId}&assignmentId=${tariffTask?.taskAssignmentId}`
                    : `${process.env.REACT_APP_MOTHER_URL}/tasks/${tariffTask.taskId}`
                }
                displayText={`${tariffTask.taskId}`}
              />
            </Td>
            <Td className="text-center  ">{tariffTask?.taskType}</Td>
            <Td className="text-center  ">{dateFormatter(String(tariffTask?.createdDate))}</Td>
            <Td className="text-center  ">
              <a
                target="_blank"
                rel="noreferrer"
                href={`${process.env.REACT_APP_MOTHER_URL}/lses/${tariffTask?.lseId}`}
                style={{ textDecoration: 'underline', textDecorationColor: 'rgb(10,28,25)' }}
              >
                <TextField
                  textValue={tariffTask?.lseName}
                  toolTipId={tariffTask?.taskId + 'lse'}
                  maxSize={11}
                  textStyle="heading500"
                />
              </a>
            </Td>
            <Td
              className="text-center  "
              onClick={() => {
                if (
                  tariffTask?.masterTariffId &&
                  tariffTask?.masterTariffId.split(',').length > 2
                ) {
                  setShowAllMtid(tariffTask.masterTariffId);
                  setLseIdForShowAll(tariffTask.lseId);
                }
              }}
              style={{ fontFamily: 'DM Sans Bold' }}
            >
              {tariffTask?.masterTariffId && tariffTask?.masterTariffId.split(',').length > 2 ? (
                tariffTask?.masterTariffId.split(',')[0] +
                ', ' +
                tariffTask?.masterTariffId.split(',')[1] +
                ', more'
              ) : tariffTask?.masterTariffId ? (
                tariffTask?.masterTariffId.split(',').length == 2 ? (
                  <>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${process.env.REACT_APP_MOTHER_URL}/lses/${
                        tariffTask?.lseId
                      }/tariffs/${tariffTask?.masterTariffId.split(',')[0]}`}
                      style={{ textDecoration: 'underline', color: 'rgb(10,28,25)' }}
                    >
                      {tariffTask?.masterTariffId.split(',')[0]}
                    </a>
                    ,
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${process.env.REACT_APP_MOTHER_URL}/lses/${
                        tariffTask?.lseId
                      }/tariffs/${tariffTask?.masterTariffId.split(',')[1]}`}
                      style={{ textDecoration: 'underline', color: 'rgb(10,28,25)' }}
                    >
                      {tariffTask?.masterTariffId.split(',')[1]}
                    </a>
                  </>
                ) : (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${process.env.REACT_APP_MOTHER_URL}/lses/${tariffTask?.lseId}/tariffs/${
                      tariffTask?.masterTariffId.split(',')[0]
                    }`}
                    style={{ textDecoration: 'underline', color: 'rgb(10,28,25)' }}
                  >
                    {tariffTask?.masterTariffId.split(',')[0]}
                  </a>
                )
              ) : (
                <></>
              )}
            </Td>
            <Td className="text-center  ">{tariffTask?.taskStatus}</Td>
            <Td className="text-center text-wrap ">
              <div className=" d-flex flex-column align-items-center">
                {tariffTask?.assignee && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${process.env.REACT_APP_MOTHER_URL}/mother/admin/users/${tariffTask?.assigneeId}`}
                    style={{ textDecoration: 'underline', textDecorationColor: 'rgb(10,28,25)' }}
                  >
                    <TextField
                      textValue={tariffTask?.assignee?.split('@')[0]}
                      toolTipId={tariffTask?.taskId + 'modeller'}
                      toolTipValue={tariffTask?.assignee}
                      maxSize={11}
                      textStyle="heading500"
                    />
                  </a>
                )}
                {[TaskStatus.DRAFTING, TaskStatus.READY_TO_POST, TaskStatus.POSTED].includes(
                  tariffTask?.taskStatus
                ) &&
                  (tariffTask?.taskType === TaskType.ADD_TARIFF ||
                    tariffTask?.taskType === TaskType.EDIT_TARIFF ||
                    tariffTask?.taskType === TaskType.REVISE_TARIFF) && (
                    <AssignTask
                      taskId={tariffTask.taskId}
                      taskType={tariffTask.taskType}
                      className="m-1"
                      workflowId={tariffTask?.workflowInstanceId}
                      text={tariffTask?.assignee ? 'Re-Assign' : 'Assign'}
                    />
                  )}
              </div>
            </Td>
            <Td className="text-center text-wrap">
              <div className=" d-flex flex-column align-items-center">
                {tariffTask?.reviewer && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${process.env.REACT_APP_MOTHER_URL}/mother/admin/users/${tariffTask?.reviewerId}`}
                    style={{ textDecoration: 'underline', textDecorationColor: 'rgb(10,28,25)' }}
                  >
                    <TextField
                      textValue={tariffTask?.reviewer?.split('@')[0]}
                      toolTipId={tariffTask?.taskId + 'reviewer'}
                      toolTipValue={tariffTask?.reviewer}
                      maxSize={11}
                      textStyle="heading500"
                    />
                  </a>
                )}
                {(tariffTask?.taskStatus === TaskStatus.REJECTED ||
                  tariffTask?.taskStatus === TaskStatus.APPROVED ||
                  tariffTask?.taskStatus === TaskStatus.READY_TO_REVIEW ||
                  tariffTask?.taskStatus === TaskStatus.REVIEWING) &&
                  (tariffTask?.taskType === TaskType.ADD_TARIFF ||
                    tariffTask?.taskType === TaskType.EDIT_TARIFF ||
                    tariffTask?.taskType === TaskType.REVISE_TARIFF) &&
                  ((tariffTask?.assignee && tariffTask?.assignee === currentUser.username) ||
                    (tariffTask?.reviewer && tariffTask?.reviewer === currentUser.username)) && (
                    <AssignTask
                      taskId={tariffTask.taskId}
                      taskType={tariffTask.taskType}
                      reviewer
                      className="m-1"
                      text={tariffTask?.reviewer ? 'Re-Assign' : 'Assign'}
                      workflowId={tariffTask?.workflowInstanceId}
                    />
                  )}
              </div>
            </Td>
          </Tr>
        ))
      : null;
  }, [sortedItems, dateFormatter, selected]);

  return (
    <>
      {apiStatus === 'idle' || apiStatus === 'pending' ? (
        <Loading backgroundColor="primary" />
      ) : (
        <>
          <Row>
            <Col md={3}>
              {activeFilter == TaskFilter.ALL_TASKS && activeTaskType == 'tariff' && (
                <Text className="mt-1 font-italic" textStyle="paragraph600">
                  Only tasks created in last 12 months are displayed
                </Text>
              )}
            </Col>
            <Col md={9} className="d-flex justify-content-end">
              {results && results.length > 0 && (
                <>
                  {selectedCount > 1 && (
                    <BulkAssignTaskModal
                      entityType={EntityType.TARIFF}
                      selected={selected}
                      sortedItems={sortedItems}
                      refreshTabDetails={refreshTabDetails}
                    />
                  )}
                  <Text
                    color="primary"
                    opacity="high"
                    tag="p"
                    textStyle="paragraph400"
                    className="mt-2 mr-2"
                  >
                    Showing {(page - 1) * pageCount + 1} - {Math.min(page * pageCount, count)} of
                    {` ${count}`} entries
                  </Text>

                  <Pagination
                    activePage={page}
                    totalPages={totalPageCount}
                    onSelectPage={handleSelectPage}
                  />
                </>
              )}
              <IconButton
                className="ml-1"
                style={{ height: '40px', width: '40px' }}
                icon="refresh"
                spin={showSpinner}
                onClick={() => setToggleRefresh(!toggleRefresh)}
              />
              {activeFilter == TaskFilter.ALL_TASKS && activeTaskType == 'tariff' && (
                <Button
                  size="medium"
                  className="ml-3"
                  onClick={exportFunction}
                  style={{ minWidth: '110px', height: '45px' }}
                  loading={exportLoad}
                >
                  <Icon icon="UIDownload" color="accent1" className="mr-1" />
                  Export
                </Button>
              )}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Table bordered striped hover responsive>
                <thead>
                  <tr>
                    {TARIFF_TASKS_COLUMNS.map(({ label, key }) => (
                      <Th
                        key={label}
                        sortConfig={sortConfig}
                        sortKey={key != 'selection' ? key : ''}
                        className={
                          key == 'selection' ? 'd-flex align-middle' : 'align-middle text-center'
                        }
                        style={key == 'taskId' ? { minWidth: '100px' } : {}}
                        onClick={key && key != 'selection' ? () => handleSort(key) : undefined}
                      >
                        <span className="my-auto">{label}</span>
                        <span className="my-auto">
                          {key == 'selection' && (
                            <Checkbox
                              checked={selectAll}
                              onChange={selectAllSelection}
                              className="ml-3"
                            />
                          )}
                        </span>
                      </Th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {!sortedItems || sortedItems.length === 0 ? (
                    <Tr className="text-center">
                      <td colSpan={TARIFF_TASKS_COLUMNS.length}>No results found.</td>
                    </Tr>
                  ) : (
                    mappedTariffValuesRows()
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      )}
      {showAllMtid && (
        <Modal
          aria-label="My modal"
          onDismiss={() => setShowAllMtid(undefined)}
          size="large"
          className="p-3"
        >
          <Modal.Header onClose={() => setShowAllMtid(undefined)} title={`All Master tariff Ids`} />
          <Modal.Content>
            <Text color="primary" opacity="high" tag="h1" textStyle="heading500" className="mb-3">
              Master Tariff Ids:
            </Text>
            {showAllMtid.split(',').map((value: string) => (
              <React.Fragment key={value}>
                <Badge backgroundless color="primary" margin="5px" size="medium">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${process.env.REACT_APP_MOTHER_URL}/lses/${lseIdForShowAll}/tariffs/${value}`}
                    style={{ textDecoration: 'underline', color: 'rgb(10,28,25)' }}
                  >
                    {value}
                  </a>
                </Badge>
              </React.Fragment>
            ))}
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};

export default TariffTasks;
