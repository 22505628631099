import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import { CheckInput, ConfirmModal } from '@arcadiapower/gen-react-lib';
import CheckInputWithConfirmation from '../../CheckInputWithConfirmation/CheckInputWithConfirmation';
import { types } from '@genability/api';

interface StructureRowProps {
  chargeType: types.ChargeType | undefined;
}

const StructureRow: React.FC<StructureRowProps> = ({ chargeType }) => {
  const { register, control, watch } = useFormContext();
  const [rateBands, hasTieredChecked, hasVariableChecked, hasCriteriaChecked, variableLimitKey] =
    watch([
      'rateBands',
      'hasTieredChecked',
      'hasVariableChecked',
      'hasCriteriaChecked',
      'variableLimitKey',
    ]);

  const confirmUnsetCriteria = async () => {
    if (hasCriteriaChecked && rateBands && rateBands.length !== 0) {
      let question = '';

      if (rateBands.length > 1 && !hasTieredChecked) {
        question =
          'This will clear all rate criteria selections and keep only the first rate band. Continue?';
      } else {
        question = 'This will clear all rate criteria selections. Continue?';
      }

      const confirmation = ConfirmModal.show({
        title: 'Remove Criteria',
        question: question,
      });

      return confirmation;
    } else {
      return true;
    }
  };

  const confirmUnsetTiers = async () => {
    if (hasTieredChecked == true && ((rateBands && rateBands.length !== 0) || variableLimitKey)) {
      // need to put variableLimitKey into form state
      let question = '';

      if (rateBands && rateBands.length > 1) {
        question =
          'This will clear all tiered rate data and keep only the first rate band. Continue?';
      } else {
        question = 'This will clear all tiered rate data. Continue?';
      }

      const confirmation = await ConfirmModal.show({
        title: 'Remove Tiers',
        question: question,
      });

      return confirmation;
    } else {
      return true;
    }
  };

  return (
    <Row>
      <Col>
        <span>Structure: </span>
        <CheckInput
          id="id-variable"
          label="Variable"
          {...register('hasVariableChecked')}
          disabled={hasTieredChecked}
          inline
        />
        <CheckInputWithConfirmation
          name="hasTieredChecked"
          control={control}
          confirm={confirmUnsetTiers}
          label="Tiered"
          id="id-tiered"
          disabled={hasVariableChecked || chargeType === types.ChargeType.FIXED_PRICE}
          inline
        />
        <CheckInputWithConfirmation
          name="hasCriteriaChecked"
          control={control}
          confirm={confirmUnsetCriteria}
          label="Has Criteria"
          id="id-hasCriteria"
          inline
        />
        <CheckInput id="id-hasFactor" label="Has Factor" {...register('hasFactorChecked')} inline />
        <CheckInput
          id="Predominance"
          label="Predominance"
          {...register('hasEdgePredominance')}
          inline
        />
      </Col>
    </Row>
  );
};

export default StructureRow;
