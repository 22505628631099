import { Text } from '@arcadiapower/shrike';
import { Table, Th, Tr } from '@arcadiapower/gen-react-lib';
import React, { FC, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import OverlayLink from '../../OverlayLink/OverlayLink';
import { format, parseISO } from 'date-fns';
const { REACT_APP_MOTHER_SERVER = 'https://mother.genability.com' } = process.env;

export interface TaskAttributesParams {
  taskId: string;
  lseId?: number;
  lseName?: string | null;
  oldEntityId?: string | null;
  oldTaskId?: number | null;
  newEntityID?: string | null;
  taskSource?: string | null;
  workflowId?: string | null;
  workFlowStatus?: string | null;
  closureCode?: string | null;
  srcEntityType?: string | null;
  targetEntityType?: string | null;
  srcEntityId?: string | null;
  targetEntityId?: string | null;
  taskChannel?: string | null;
  assigneeId?: string | null;
  assignee?: string | null;
  reviewerId?: string | null;
  reviewer?: string | null;
  createdDate?: string | null;
}

const TaskAttributes: FC<TaskAttributesParams> = ({
  taskId,
  lseId,
  lseName,
  oldEntityId,
  newEntityID,
  taskSource,
  workflowId,
  workFlowStatus,
  closureCode,
  srcEntityType,
  targetEntityType,
  srcEntityId,
  targetEntityId,
  taskChannel,
  createdDate,
}) => {
  const dateFormatter = useCallback((date: string) => format(parseISO(date), 'dd-MMM-yyyy'), []);
  return (
    <>
      <Row className="mt-5 ml-2">
        <Col xs={9} className="text-center">
          <Table bordered hover>
            <thead>
              <tr>
                <Th key="tskAtrb_lseId">LSE Id</Th>
                <Th key="tskAtrb_lseName">LSE Name</Th>
                <Th key="tskAtrb_tskSrc">Task Source</Th>
                <Th key="tskAtrb_workFlowId">Workflow Id</Th>
                <Th key="tskAtrb_workFlowStatus">Workflow Status</Th>
                <Th key="tskAtrb_closureCode">Closure Code</Th>
                <Th key="tskAtrb_taskChannel">Task Channel</Th>
                <Th key="tskAtrb_createdOn">Created on</Th>
              </tr>
            </thead>
            <tbody>
              <Tr>
                <td>{lseId}</td>
                <td>
                  {lseId != -1 ? (
                    <OverlayLink
                      id={taskId}
                      openInNewTab
                      url={`${REACT_APP_MOTHER_SERVER}/mother/lses/${lseId}`}
                      displayText={`${lseName}`}
                    />
                  ) : null}
                </td>
                <td>{taskSource}</td>
                <td>{workflowId}</td>
                <td>{workFlowStatus}</td>
                <td>{closureCode}</td>
                <td>{taskChannel}</td>
                <td>{dateFormatter(String(createdDate))}</td>
              </Tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Text color="primary" opacity="high" tag="h3" textStyle="heading700" className="ml-4 mt-3">
          Entity References
        </Text>
      </Row>
      <Row className="mt-2 ml-2">
        <Col xs={9} className="text-center">
          <Table bordered hover>
            <thead>
              <tr>
                {/* <Th key="tskAtrb_oldEntityId">Old Entity Id</Th>
                <Th key="tskAtrb_newEntityId">New Entity Id</Th> */}
                <Th key="tskAtrb_srcEntyId">Source Entity Id</Th>
                <Th key="tskAtrb_srcEntyTyp">Source Entity Type</Th>
                <Th key="tskAtrb_trgtEntyId">Target Entity Id</Th>
                <Th key="tskAtrb_trgtEntyTyp">Target Entity Type</Th>
              </tr>
            </thead>
            <tbody>
              <Tr>
                {/* <td>{oldEntityId}</td>
                <td>{newEntityID}</td> */}
                <td>{srcEntityId}</td>
                <td>{srcEntityType}</td>
                <td>{targetEntityId}</td>
                <td>{targetEntityType}</td>
              </Tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default TaskAttributes;
