import { restClient } from '@genability/api';
import { UploadApi } from './upload-api';

export class GenabilityUploadApi {
  private static _instance: GenabilityUploadApi;

  private _credentials: restClient.RestApiCredentials;
  private _upload: UploadApi | undefined;
  private _baseUrl: string;

  private constructor(baseUrl?: string, credentials?: restClient.RestApiCredentials) {
    this._baseUrl = baseUrl || 'https://api-dev.teamtariff.com';
    this._credentials = credentials || {};
  }

  public static configure(
    baseUrl?: string,
    credentials?: restClient.RestApiCredentials
  ): GenabilityUploadApi {
    return this._instance || (this._instance = new this(baseUrl, credentials));
  }

  public get upload(): UploadApi {
    if (this._upload === undefined) {
      this._upload = new UploadApi(
        new restClient.GenabilityConfig({
          proxy: this._baseUrl,
          credentials: this._credentials,
        })
      );
    }
    return this._upload;
  }
}
