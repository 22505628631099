import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Modal from 'react-bootstrap/Modal';
import { Button, CheckInput } from '@arcadiapower/gen-react-lib';

export interface DQOverrideDialogProps {
  onContinue?: () => void;
  onCancel?: () => void;
  isVisible: boolean;
  hideModal: () => void;
  title?: string;
}

const DQOverrideDialog: FC<DQOverrideDialogProps> = ({
  isVisible,
  hideModal,
  onContinue,
  onCancel,
  title = 'Override DQ Check',
  children,
}) => {
  const [confirmOverride, setConfirmOverride] = useState(false);

  const handleCancel = () => {
    onCancel && onCancel();
    hideModal();
  };

  const handleContinue = () => {
    onContinue && onContinue();
    hideModal();
  };

  useEffect(() => {
    if (isVisible) {
      setConfirmOverride(false);
    }
  }, [isVisible]);

  return createPortal(
    <Modal show={isVisible} onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>{children}</>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ flexGrow: '2' }}>
          <CheckInput
            controlId="confirm-dqCheck-override"
            label="I understand"
            feedback="You must confirm to override DQ Check"
            checked={confirmOverride}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setConfirmOverride(event.target.checked)
            }
            required
          />
        </div>
        <Button variant="primary" onClick={handleContinue} disabled={!confirmOverride}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>,
    document.body
  );
};

export default DQOverrideDialog;
