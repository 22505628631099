import { restClient } from '@genability/api';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OpsManagementApiClient } from '../../../GenApiClient';
import { handleUnexpectedThunkException } from '../../reduxUtils';
import { RootState } from '../../rootReducer';

export interface LookupStatusStatsType {
  currentUserStats: {
    totalAssignedLSE: number;
    totalAssignedLookups: number;
    monitoredLookups: number;
    pendingMonitoringLookups: number;
    newRecordCreatedLookups: number;
  };
  globalStats: {
    totalAssignedLSE: number;
    totalAssignedLookups: number;
    monitoredLookups: number;
    pendingMonitoringLookups: number;
    newRecordCreatedLookups: number;
  };
}
export interface LookupStatusStatsResponse {
  results: LookupStatusStatsType[];
  apiStatus: 'idle' | 'pending' | 'resolved' | 'rejected';
  errors: restClient.ResponseError[] | undefined;
  count: number;
}
const initialState: LookupStatusStatsResponse = {
  results: [],
  apiStatus: 'pending',
  count: 0,
  errors: undefined,
};

export const fetchLookupStatusStats = createAsyncThunk(
  'opsManagement/status/stats/lookup',
  async (request: any, { dispatch, rejectWithValue }) => {
    try {
      const client = await OpsManagementApiClient();
      const response: restClient.PagedResponse<LookupStatusStatsType> =
        await client.opsData.lookupStatusStats();
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
      return response;
    } catch (err) {
      return rejectWithValue(
        handleUnexpectedThunkException(err, 'LookupStatusStatsSlice', dispatch)
      );
    }
  }
);

export const LookupStatusStatsSlice = createSlice({
  name: 'LookupStatusStatsSlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchLookupStatusStats.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(
      fetchLookupStatusStats.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<LookupStatusStatsType>>) => {
        const { count, results, errors } = action.payload;
        if (errors) {
          state.apiStatus = 'rejected';
          state.errors = errors;
        } else {
          state.apiStatus = 'resolved';
          state.count = count;
          state.results = results;
          state.errors = undefined;
        }
      }
    );
    builder.addCase(fetchLookupStatusStats.rejected, state => {
      state.apiStatus = 'rejected';
    });
  },
});

export default LookupStatusStatsSlice.reducer;

export const selectLookupStatusStats = (state: RootState): LookupStatusStatsResponse => {
  return state.lookupStatusStatsData;
};
