import { restClient, types } from '@genability/api';
import { Task, TaskStatus, TaskComment } from '../types/task';
import { ResourceDiff } from '../types/resource-diff';

export class GetTasksRequest extends restClient.BasePagedRequest {
  public worker?: string;
  public taskStatuses?: TaskStatus[];
  public populateTaskInputs?: boolean;
  public populateTaskAssignments?: boolean;

  addParams(addParam: restClient.AddParamCallback): void {
    addParam('worker', this.worker);
    addParam('taskStatuses', this.taskStatuses);
    addParam('populateTaskInputs', this.populateTaskInputs);
    addParam('populateTaskAssignments', this.populateTaskAssignments);
  }
}

export class GetTaskCommentsRequest extends restClient.BasePagedRequest {
  public userName?: string;
  public taskCommentId?: number;

  addParams(addParam: restClient.AddParamCallback): void {
    addParam('userName', this.userName);
    addParam('taskCommentId', this.taskCommentId);
  }
}

export interface TaskCommentBody {
  comment: string;
  taskCommentId?: number;
  taskId?: number;
  taskAssignmentId?: number;
  taskAnswerId?: number;
  fromUserId?: number;
  fromUser?: string;
  createdBy?: number;
  createdDate?: string;
  lastUpdatedBy?: number;
  lastUpdatedDate?: string;
}

export class TaskApi extends restClient.RestApiClient {
  public async getTasks(request?: GetTasksRequest): Promise<restClient.PagedResponse<Task>> {
    return this.getPaged(`/v1/tasks`, { params: request });
  }

  public async getTask(
    taskId: number,
    request?: GetTasksRequest
  ): Promise<restClient.SingleResponse<Task>> {
    return this.getSingle(`/v1/tasks/${taskId}`, { params: request });
  }

  public async getTaskComments(
    taskId: number,
    request?: GetTaskCommentsRequest
  ): Promise<restClient.PagedResponse<TaskComment>> {
    return this.getPaged(`/v1/tasks/${taskId}/comments`, { params: request });
  }

  public async addTaskComment(
    body: TaskCommentBody
  ): Promise<restClient.SingleResponse<TaskComment>> {
    return this.post(`/v1/tasks/${body.taskId}/comments`, body);
  }

  public async updateTaskComment(
    body: TaskCommentBody
  ): Promise<restClient.SingleResponse<TaskComment>> {
    return this.put(`/v1/tasks/${body.taskId}/comments`, body);
  }

  public async getTaskSource(taskId: number): Promise<restClient.SingleResponse<types.Tariff>> {
    return this.getSingle(`/v1/tasks/${taskId}/source`);
  }

  public async getTaskDiff(
    taskId: number,
    request?: restClient.BasePagedRequest
  ): Promise<restClient.SingleResponse<ResourceDiff>> {
    return this.getSingle(`/v1/tasks/${taskId}/diff`, { params: request });
  }

  public async runTaskCalc(taskId: number): Promise<restClient.SingleResponse<Task>> {
    return this.post(`/v1/tasks/${taskId}/runCalculation`);
  }
}
