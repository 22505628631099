import { CheckInput } from '@arcadiapower/gen-react-lib';
import React, { useState, useEffect } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { FormCheckProps } from 'react-bootstrap/FormCheck';
import { FormGroupProps } from 'react-bootstrap/FormGroup';

// TODO: Export this from gen-react-lib the same way react-bootstrap does.
export interface CheckInputProps extends Omit<FormCheckProps, 'size'>, FormGroupProps {
  // TODO: Using Omit here because there's an error in hoe gen-react-lib uses "size" that should be fixed upstream.
  id?: string;
  name?: string;
  checked?: boolean;
}

// useController's type constraints prevent creating generic reusable components, so
// we need to override the type for 'control' here.
// Current official example: https://react-hook-form.com/api/usecontroller
// Related Github issue: https://github.com/react-hook-form/react-hook-form/issues/5055
type CheckInputWithConfirmationProps = Omit<UseControllerProps, 'control'> &
  CheckInputProps & {
    confirm: () => Promise<boolean>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: any;
  };

const CheckInputWithConfirmation: React.FC<CheckInputWithConfirmationProps> = ({
  name: fieldName,
  control,
  confirm,
  rules,
  ...rest
}) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name: fieldName,
    control,
    rules,
  });

  const [checked, setChecked] = useState(value);

  useEffect(() => {
    onChange(checked);
  }, [checked]);

  const handleOnChange = async (event: React.SyntheticEvent<EventTarget>) => {
    event.preventDefault();
    const targetChecked = (event.target as HTMLInputElement).checked;
    const confirmation = await confirm();
    setChecked(confirmation ? targetChecked : !targetChecked);
  };

  return (
    <CheckInput
      onChange={handleOnChange}
      checked={checked}
      isInvalid={!!error}
      feedback={error?.message}
      {...rest}
    />
  );
};

export default CheckInputWithConfirmation;
