import React, { useEffect } from 'react';
import {
  IconButton,
  ChargeTypeIcon,
  Icon,
  TransactionTypeIcon,
  ChargePeriodIcon,
  Table,
  Th,
  Tr,
  TimeOfUseIcon,
  SeasonsIcon,
  RateApplicabilityIcon,
  TieredRatesIcon,
  ChargeClassIcon,
  TerritoryRatesIcon,
} from '@arcadiapower/gen-react-lib';
import { Icon as ShrikeIcon, Text, lightTheme } from '@arcadiapower/shrike';
import Badge from 'react-bootstrap/Badge';
import PropertyKeyPopover from '../PropertyKeyPopover/PropertyKeyPopover';
import { types } from '@genability/api';
import { useAppDispatch } from '../../state/store';
import { useSelector } from 'react-redux';
import { Col, Row, Tooltip, OverlayTrigger, Dropdown } from 'react-bootstrap';
import { fetchPropertyKeys, selectPropertyKey } from '../../state/propertyKeys/propertyKeysSlice';
import { getRateUnit, formatRateAmount } from '../../utils/tariffUtils';
import styles from './RateCard.module.scss';
import { ChargeType, TariffRate, TariffRateBand } from '@genability/api/dist/types';
import { restClient } from '@genability/api';
import ApiFormError from '../ApiFormError/ApiFormError';
import { transformToFormError } from '../../utils/errorUtils';
import { AiRateInfo } from '../../task-api/v2/types/AiRateInfo';

import ApplyAiValue from '../AiComponents/ApplyAiValue';
import RevertAiValue from '../AiComponents/RevertAiValue';

interface RateCardProps {
  taskAssignmentId?: number;
  tariffRateId: number;
  tariffRate: types.TariffRate;
  className?: string;
  onDeleteRate?: (tariffRateId: number) => void;
  onCopyRate?: (tariffRate: types.TariffRate, tariffSequenceNumber: number | undefined) => void;
  onEditRate?: (tariffRateId: number) => void;
  onMoveRate?: (tariffRateId: number, position: string) => void;
  onApplyAiRate?: (modifyRate: types.TariffRate, answerValue: string | null) => void;
  fromRider?: boolean;
  disabled?: boolean;
  currency?: string;
  apiErrors?: restClient.ResponseError[];
  rateIndex?: number;
  aiTariffRates?: TariffRate[];
  aiRatesAvailable?: boolean;
  appliedAiRates?: AiRateInfo[];
  appliedAiAnswerId?: number;
}

interface AiRateBandEntry {
  tariffRateBand: TariffRateBand;
}

const CustomToggle = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: any, ref: any) => <IconButton icon="menu" ref={ref} onClick={props.onClick}></IconButton>
);

CustomToggle.displayName = 'CustomToggle';

const RateCard: React.FC<RateCardProps> = ({
  taskAssignmentId,
  tariffRateId,
  tariffRate,
  className,
  onDeleteRate,
  onCopyRate,
  onEditRate,
  onMoveRate,
  fromRider,
  disabled,
  currency,
  apiErrors,
  rateIndex,
  aiTariffRates,
  aiRatesAvailable,
  appliedAiRates,
  onApplyAiRate,
  appliedAiAnswerId,
}: RateCardProps): React.ReactElement => {
  const propertyKey: types.GenPropertyKey | null = useSelector(
    selectPropertyKey(tariffRate.variableRateKey)
  );
  const applicabilityKey: types.GenPropertyKey | null = useSelector(
    selectPropertyKey(tariffRate.applicabilityKey)
  );

  const applicabilityDisplay = applicabilityKey
    ? applicabilityKey.choices?.reduce((map: { [key: string]: string }, choice) => {
        map[choice.dataValue] = choice.displayValue;
        return map;
      }, {})
    : null;

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (tariffRate.variableRateKey) {
      dispatch(fetchPropertyKeys({ searchTerm: tariffRate.variableRateKey }));
    }
  }, []);

  const getApplicabilityDisplayValue = (applicabilityDataValue: string | undefined | null) => {
    if (!applicabilityDataValue || !applicabilityDisplay) {
      return applicabilityDataValue || '';
    }
    return applicabilityDisplay[applicabilityDataValue];
  };

  const deleteRate = () => {
    onDeleteRate ? onDeleteRate(tariffRateId) : null;
  };

  const editRate = () => {
    onEditRate ? onEditRate(tariffRateId) : null;
  };

  const copyRate = () => {
    onCopyRate
      ? onCopyRate(
          tariffRate,
          tariffRate.tariffSequenceNumber ? tariffRate.tariffSequenceNumber + 1 : undefined
        )
      : null;
  };

  const handleSelectMenu = (selectedMenu: string | null) => {
    if (selectedMenu === 'delete') {
      deleteRate();
    } else if (selectedMenu === 'copy') {
      copyRate();
    } else if (
      selectedMenu &&
      [
        'topOfGroup',
        'bottomOfGroup',
        'positionAbove',
        'positionBelow',
        'groupAbove',
        'groupBelow',
      ].includes(selectedMenu)
    ) {
      onMoveRate ? onMoveRate(tariffRateId, selectedMenu) : null;
    }
  };

  const renderRateAmount = (aiRatesAvailable?: boolean, aiTariffRates?: TariffRate[]) => {
    const hasCriteria = tariffRate.applicabilityKey && tariffRate.applicabilityKey !== '';
    const chargeType = tariffRate.chargeType || types.ChargeType.CONSUMPTION_BASED;
    const columns = [
      ...(hasCriteria ? ['Applicability Value'] : []),
      ...(tariffRate?.rateBands?.some(rateBand => rateBand.hasConsumptionLimit)
        ? ['Consumption Limit']
        : []),
      ...(tariffRate?.rateBands?.some(rateBand => rateBand.hasDemandLimit) ? ['Demand Limit'] : []),
      ...(tariffRate?.rateBands?.some(rateBand => rateBand.hasPropertyLimit)
        ? ['Property Limit']
        : []),
      aiRatesAvailable ? 'Current Rate Amount' : 'Rate Amount',
      ...(aiRatesAvailable ? ['Ai Rate Amount'] : []),
      ...(tariffRate?.rateBands?.some(rateBand => rateBand.calculationFactor)
        ? ['Rate Factor']
        : []),
    ];

    let aiRate;

    if (Array.isArray(aiTariffRates)) {
      aiRate = aiTariffRates?.find(
        rate => rate.rateName == tariffRate.rateName || rate.tariffRateId == tariffRate.tariffRateId
      );
    } else {
      aiRate = aiTariffRates;
    }

    const aiRateBand = aiRate?.rateBands ? JSON.parse(JSON.stringify(aiRate?.rateBands)) : null;
    const aiRateBandArray = aiRateBand?.tariffRateBand
      ? Array.isArray(aiRateBand?.tariffRateBand)
        ? aiRateBand.tariffRateBand
        : [aiRateBand.tariffRateBand]
      : null;
    const appliedAiValueRate = appliedAiRates?.find(
      appliedRate =>
        appliedRate.isAiValueApplied &&
        appliedRate.rateGroupName == tariffRate.rateGroupName &&
        appliedRate.rateName == tariffRate.rateName
    );
    const revertedAiValue = appliedAiRates?.find(
      appliedRate =>
        appliedRate.rateGroupName == tariffRate.rateGroupName &&
        appliedRate.rateName == tariffRate.rateName
    );
    return (
      <Table size="sm">
        <thead>
          {tariffRate.applicabilityKey && (
            <tr>
              <Th colSpan={2} className={styles.applicabilityKey}>
                {applicabilityKey?.displayName}
              </Th>
            </tr>
          )}
          <tr className="align-items end">
            {columns.map((column, idx) => (
              <>
                <Th colSpan={column === 'Ai Rate Amount' ? 2 : 1} key={idx}>
                  {column}
                </Th>
              </>
            ))}
          </tr>
        </thead>
        <tbody>
          {tariffRate.rateBands &&
            tariffRate.rateBands.map((rateBand: types.TariffRateBand, idx: number) => {
              const filteredAiRateBand = aiRateBandArray?.[idx];
              return (
                <Tr key={rateBand.tariffRateBandId || idx}>
                  {hasCriteria && (
                    <td>{getApplicabilityDisplayValue(rateBand.applicabilityValue)}</td>
                  )}
                  {rateBand.hasConsumptionLimit && <td>{rateBand.consumptionUpperLimit}</td>}
                  {rateBand.hasDemandLimit && <td>{rateBand.demandUpperLimit}</td>}
                  {rateBand.hasPropertyLimit && <td>{rateBand.propertyUpperLimit}</td>}
                  <td>
                    <div className={`${styles.flexBadgeSpacing}`}>
                      {`${formatRateAmount(
                        rateBand.rateAmount,
                        currency,
                        chargeType,
                        rateBand.rateUnit
                      )} ${getRateUnit(tariffRate)}`}
                      {rateBand.isCredit && (
                        <Badge className={`gen-badge ${styles.badgeAlignment}`}>CR</Badge>
                      )}
                    </div>
                  </td>

                  {aiRatesAvailable && (
                    <>
                      <td colSpan={2}>
                        <Row>
                          <Col xs={6} md={5} lg={5}>
                            <div
                              className={`${styles.flexBadgeSpacing} d-flex ${
                                filteredAiRateBand?.rateAmount != rateBand?.rateAmount
                                  ? 'justify-content-end'
                                  : 'justify-content-centre'
                              }`}
                              style={{
                                paddingRight: '5px',
                                borderRight:
                                  filteredAiRateBand?.rateAmount != rateBand?.rateAmount
                                    ? `5px solid ${lightTheme.colors.background.successBright}`
                                    : 'none',
                                color:
                                  filteredAiRateBand?.rateAmount != rateBand?.rateAmount
                                    ? `${lightTheme.colors.background.successBright}`
                                    : 'inherit',
                              }}
                            >
                              {`${formatRateAmount(
                                filteredAiRateBand?.rateAmount,
                                currency,
                                chargeType,
                                filteredAiRateBand?.rateUnit
                              )} ${getRateUnit(tariffRate)}`}
                            </div>
                          </Col>
                          <Col>
                            <div>
                              {filteredAiRateBand?.rateAmount == null ||
                              filteredAiRateBand?.rateAmount == rateBand.rateAmount ? (
                                <></>
                              ) : (
                                <ApplyAiValue
                                  taskAssignmentId={taskAssignmentId}
                                  tariffRateId={tariffRateId}
                                  tariffRate={tariffRate}
                                  rateBandIndex={idx}
                                  onApplyAiRate={onApplyAiRate}
                                  filteredAiRateBand={filteredAiRateBand}
                                  appliedAiRates={appliedAiRates}
                                  revertedAiValue={revertedAiValue}
                                  appliedAiValueRate={appliedAiValueRate}
                                />
                              )}
                              {appliedAiValueRate &&
                              filteredAiRateBand?.rateAmount == rateBand.rateAmount ? (
                                <RevertAiValue
                                  taskAssignmentId={taskAssignmentId}
                                  tariffRate={tariffRate}
                                  rateBandIndex={idx}
                                  onRevertAiRate={onApplyAiRate}
                                  appliedAiRates={appliedAiRates}
                                  revertedAiValue={revertedAiValue}
                                  appliedAiValueRate={appliedAiValueRate}
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </td>
                    </>
                  )}
                  {rateBand.calculationFactor && <td>{rateBand.calculationFactor}</td>}
                </Tr>
              );
            })}
        </tbody>
      </Table>
    );
  };

  const hasTieredOrCriteria =
    types.hasTiers(tariffRate) ||
    (tariffRate.applicabilityKey && tariffRate.applicabilityKey !== '');

  return (
    <div className={`${styles.container} ${className}`}>
      {apiErrors && (
        <ApiFormError
          apiErrors={transformToFormError(
            apiErrors,
            rateIndex !== undefined ? `rates.${rateIndex}` : ''
          )}
        />
      )}
      <Row
        className={fromRider || disabled ? `${styles.riderRate}` : `${styles.tariffRate}`}
        // onClick={() => {
        // if (!fromRider && !disabled) editRate();
        // }} Disabling this row edit as we have edit icon on all eligable rows
      >
        <Col xs={1}>
          {fromRider ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="riderTooltip" placement="top">
                  This Rate is from a Rider.
                </Tooltip>
              }
            >
              <Icon iconName="ticket" />
            </OverlayTrigger>
          ) : (
            <div>
              {tariffRate.chargeType ? (
                <ChargeTypeIcon
                  chargeType={tariffRate.chargeType}
                  tooltipText={
                    tariffRate.chargeType == ChargeType.DEMAND_BASED
                      ? tariffRate.quantityKey
                      : undefined
                  }
                />
              ) : (
                <div />
              )}
            </div>
          )}
        </Col>
        <Col xs={aiRatesAvailable ? 2 : 4} className={styles.rateName}>
          <div className={styles.name}>{tariffRate.rateName}</div>
        </Col>
        <Col xs={1} className={styles.noPadding}>
          {!fromRider ? (
            <div className={styles.editRateName}>
              <IconButton icon="edit" size="sm" disabled={disabled} onClick={() => editRate()} />
            </div>
          ) : (
            <div />
          )}
        </Col>
        <Col xs={2} className={`${styles.noPadding}`}>
          {tariffRate.timeOfUse && <TimeOfUseIcon id={`tou`} timeOfUse={tariffRate.timeOfUse} />}
          {tariffRate.season && <SeasonsIcon id={`season`} season={tariffRate.season} />}
          {tariffRate.transactionType && (
            <TransactionTypeIcon transactionType={tariffRate.transactionType} />
          )}
          {tariffRate.chargePeriod && <ChargePeriodIcon chargePeriod={tariffRate.chargePeriod} />}
          {tariffRate.applicabilityKey && (
            <RateApplicabilityIcon id={`rc`} keyName={tariffRate.applicabilityKey} />
          )}
          {types.hasTiers(tariffRate) && <TieredRatesIcon id={`tiers`} />}
          {tariffRate.chargeClass && (
            <ChargeClassIcon id={`chargeClass`} chargeClass={tariffRate.chargeClass} />
          )}
          {tariffRate.territory && (
            <TerritoryRatesIcon id={`territory`} territory={tariffRate.territory} />
          )}
        </Col>
        <Col xs={aiRatesAvailable ? 5 : 3} className={styles.noPadding}>
          {tariffRate.variableRateKey ? (
            <div className={styles.flexAlignRight}>
              <OverlayTrigger
                overlay={
                  <Tooltip id="riderTooltip" placement="top">
                    {tariffRate.variableRateKey}
                  </Tooltip>
                }
              >
                <div className={styles.overflowEllipsis}>{tariffRate.variableRateKey}</div>
              </OverlayTrigger>
              {propertyKey && <PropertyKeyPopover propertyKey={propertyKey} placement="bottom" />}
            </div>
          ) : hasTieredOrCriteria ? null : (
            tariffRate.rateBands &&
            tariffRate.rateBands.map((rateBand: types.TariffRateBand, rateBandIndex) => {
              const chargeType = tariffRate.chargeType || types.ChargeType.CONSUMPTION_BASED;
              // Adding these parsers as we are converting xml to json. This is a work around as we are extracting the json from xml
              let aiRate;

              if (Array.isArray(aiTariffRates)) {
                aiRate = aiTariffRates?.find(
                  rate =>
                    rate.rateName == tariffRate.rateName ||
                    rate.tariffRateId == tariffRate.tariffRateId
                );
              } else {
                aiRate = aiTariffRates;
              }
              const aiRateBand = aiRate?.rateBands
                ? JSON.parse(JSON.stringify(aiRate?.rateBands))
                : null;
              const aiRateBandArray = aiRateBand?.tariffRateBand
                ? Array.isArray(aiRateBand?.tariffRateBand)
                  ? aiRateBand.tariffRateBand
                  : [aiRateBand.tariffRateBand]
                : null;

              const filteredAiRateBand = aiRateBandArray?.[rateBandIndex];

              const appliedAiValueRate = appliedAiRates?.find(
                appliedRate =>
                  appliedRate.isAiValueApplied &&
                  appliedRate.rateGroupName == tariffRate.rateGroupName &&
                  appliedRate.rateName == tariffRate.rateName
              );
              const revertedAiValue = appliedAiRates?.find(
                appliedRate =>
                  appliedRate.rateGroupName == tariffRate.rateGroupName &&
                  appliedRate.rateName == tariffRate.rateName
              );

              return (
                <Row key="rates">
                  <Col xs={aiRatesAvailable ? 5 : 11} className="ml-1">
                    {aiRatesAvailable && <div>Current Value</div>}
                    <div key={rateBand.tariffRateBandId}>
                      {`${formatRateAmount(
                        rateBand.rateAmount,
                        currency,
                        chargeType,
                        rateBand.rateUnit
                      )} ${getRateUnit(tariffRate)}`}
                      {rateBand.isCredit && (
                        <Badge className={`gen-badge ${styles.badgeAlignment}`}>CR</Badge>
                      )}
                    </div>
                  </Col>
                  {aiRatesAvailable && filteredAiRateBand?.rateAmount && (
                    <Col xs={4} className="px-0">
                      <div className={`d-flex justify-content-end `}>AI Value</div>
                      <div
                        className={(className = 'd-flex justify-content-end')}
                        key={filteredAiRateBand?.tariffRateBandId}
                        style={{
                          paddingRight: '4px',
                          borderRight:
                            filteredAiRateBand?.rateAmount != rateBand.rateAmount
                              ? `5px solid ${lightTheme.colors.background.successBright}`
                              : 'none',
                          color:
                            filteredAiRateBand?.rateAmount != rateBand.rateAmount
                              ? `${lightTheme.colors.background.successBright}`
                              : 'inherit',
                        }}
                      >
                        {`${formatRateAmount(
                          filteredAiRateBand?.rateAmount,
                          currency,
                          chargeType,
                          filteredAiRateBand?.rateUnit
                        )} ${getRateUnit(tariffRate)}`}
                      </div>
                    </Col>
                  )}
                  {filteredAiRateBand?.rateAmount == null ||
                  filteredAiRateBand?.rateAmount == rateBand.rateAmount ? (
                    <></>
                  ) : (
                    <ApplyAiValue
                      className="pt-4"
                      taskAssignmentId={taskAssignmentId}
                      tariffRateId={tariffRateId}
                      tariffRate={tariffRate}
                      rateBandIndex={rateBandIndex}
                      onApplyAiRate={onApplyAiRate}
                      filteredAiRateBand={filteredAiRateBand}
                      appliedAiRates={appliedAiRates}
                      revertedAiValue={revertedAiValue}
                      appliedAiValueRate={appliedAiValueRate}
                    />
                  )}
                  {appliedAiValueRate && filteredAiRateBand?.rateAmount == rateBand.rateAmount ? (
                    <RevertAiValue
                      className="mt-3"
                      taskAssignmentId={taskAssignmentId}
                      tariffRate={tariffRate}
                      rateBandIndex={rateBandIndex}
                      onRevertAiRate={onApplyAiRate}
                      appliedAiRates={appliedAiRates}
                      revertedAiValue={revertedAiValue}
                      appliedAiValueRate={appliedAiValueRate}
                    />
                  ) : (
                    <></>
                  )}
                </Row>
              );
            })
          )}
        </Col>
        <Col xs={1} className={aiRatesAvailable ? 'mt-3' : ''}>
          <div className={`${styles.delContainer}`}>
            <Dropdown
              onSelect={handleSelectMenu}
              onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
            >
              <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components"></Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="copy" disabled={disabled && !onCopyRate}>
                  Copy
                </Dropdown.Item>
                <Dropdown.Item eventKey="delete" disabled={disabled && !onDeleteRate}>
                  Delete
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Header>Send To</Dropdown.Header>
                <Dropdown.Item eventKey="topOfGroup">Top of Group</Dropdown.Item>
                <Dropdown.Item eventKey="bottomOfGroup">Bottom of Group</Dropdown.Item>
                <Dropdown.Item eventKey="positionAbove">Position Above</Dropdown.Item>
                <Dropdown.Item eventKey="positionBelow">Position Below</Dropdown.Item>
                <Dropdown.Item eventKey="groupAbove">Group Above</Dropdown.Item>
                <Dropdown.Item eventKey="groupBelow">Group Below</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
      </Row>
      {hasTieredOrCriteria && renderRateAmount(aiRatesAvailable, aiTariffRates)}
    </div>
  );
};

export default RateCard;
