import React, { SetStateAction, Dispatch, useState } from 'react';
import DatePicker from 'react-datepicker';
import styles from '../../pages/OpsManagement/OpsManagement.module.scss';

export interface DateRangePickerProps {
  date: [Date, Date] | null;
  setDate: Dispatch<SetStateAction<[Date, Date] | null>>;
  placeholder?: string;
  minDate?: Date;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  date,
  setDate,
  placeholder,
  minDate,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleDateChange = (dates: [Date, Date]) => {
    if (dates) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      if (end) {
        setDate(dates);
      } else {
        setDate(null);
      }
    }
  };

  return (
    <DatePicker
      selectsRange
      popperClassName={styles.reactDatepickerPopper}
      startDate={startDate}
      endDate={endDate}
      minDate={minDate}
      placeholderText={placeholder || 'Select Range'}
      onChange={handleDateChange}
      className={styles.dateRangePicker}
    />
  );
};

export default DateRangePicker;
