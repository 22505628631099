import { restClient, restApis } from '@genability/api';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GenApiClient } from '../../GenApiClient';
import { Fields } from '@genability/api/dist/rest-client';
import { LoadServingEntity } from '@genability/api/dist/types';
import { LoadingState } from '../reduxUtils';
import { RootState } from '../rootReducer';

export interface LoadServingEntityByIdState {
  currentlseId?: number;
  lse?: LoadServingEntity;
  errorMessage: string | null;
  currentRequestId: string | undefined;
  loading: LoadingState;
}

export const initialState: LoadServingEntityByIdState = {
  currentlseId: undefined,
  lse: undefined,
  errorMessage: null,
  currentRequestId: undefined,
  loading: LoadingState.IDLE,
};

interface LoadingServingByIdSuccess {
  result: LoadServingEntity;
  currentlseId: number;
  currentRequestId: string;
}

export const loadServingEntityByIdSlice = createSlice({
  name: 'loadServingEntityById',
  initialState,
  reducers: {
    loadServingEntityByIdStart: (state: LoadServingEntityByIdState) => {
      state.loading = LoadingState.PENDING;
    },
    loadServingEntityByIdSuccess(state, { payload }: PayloadAction<LoadingServingByIdSuccess>) {
      const { result, currentRequestId, currentlseId } = payload;
      state.loading = LoadingState.SUCCEEDED;
      state.lse = result;
      state.currentlseId = currentlseId;
      state.currentRequestId = currentRequestId;
    },
    loadServingEntityByIdFailure: (
      state: LoadServingEntityByIdState,
      action: PayloadAction<restClient.ResponseError>
    ) => {
      state.loading = LoadingState.FAILED;
      state.errorMessage = action.payload?.message;
    },
  },
});

export const {
  loadServingEntityByIdStart,
  loadServingEntityByIdSuccess,
  loadServingEntityByIdFailure,
} = loadServingEntityByIdSlice.actions;
export default loadServingEntityByIdSlice.reducer;

export const fetchLoadServingEntityById = createAsyncThunk<
  restClient.SingleResponse<LoadServingEntity> | void,
  number,
  { state: { loadServingEntityById: { loading: string; currentRequestId: string } } }
>(
  'loadServingEntity/fetchLoadServingEntityById',
  async (
    lseId: number,
    { dispatch, getState, requestId }
  ): Promise<restClient.SingleResponse<LoadServingEntity> | void> => {
    const { currentRequestId, loading } = getState().loadServingEntityById;
    if (loading === LoadingState.PENDING || requestId === currentRequestId) {
      return;
    }
    dispatch(loadServingEntityByIdStart());

    const request = new restApis.GetLoadServingEntitiesRequest();
    request.fields = Fields.EXTENDED;

    const client = await GenApiClient();
    const response = (await client.lses.getSingle(`/rest/public/lses/${lseId}`, {
      params: request,
    })) as restClient.SingleResponse<LoadServingEntity>;

    if (response?.errors?.length) {
      dispatch(loadServingEntityByIdFailure(response?.errors[0]));
    } else {
      dispatch(
        loadServingEntityByIdSuccess({
          result: response.results[0],
          currentlseId: lseId,
          currentRequestId: requestId,
        })
      );
    }
  }
);

export const selectLoadServingEntity = (state: RootState) => state.loadServingEntityById;
