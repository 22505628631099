import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Row, Col, Container, ListGroup, ListGroupItem, Popover, Overlay } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectedDashboardFilter,
  setApplyFilters,
} from '../../state/dashboardFilter/dashboardFilterSlice';
import { Text, Button, FilteredSearchInput, Input } from '@arcadiapower/shrike';
import { TaskStatusType } from '../../task-api/v2/types/TaskStatusType';
import { TaskType } from '../../task-api/v2/types/TaskType';
import { TaskSource } from '../../task-api/v2/types/TaskSource';
import FilterSection from '../FilterSection/FilterSection';
import styles from '../TariffsFilter/TariffsFilter.module.scss';
import DateRangePicker from '../DateRangePicker/DateRangePicker';
import { selectWorkers, fetchWorkers } from '../../state/workers-v2/workersSlice';
import { IWorker } from '../../task-api/types/worker';
import { GetWorkersRequest } from '../../task-api/v2/api/task-api-v2';
import { QualificationType } from '../../task-api/v2/types/QualifictionType';
import {
  fetchLoadServingEntity,
  selectLoadServingEntities,
} from '../../state/loadServingEntity/loadServingEntitySlice';
import { debounce } from 'lodash';
import {
  fetchPropertyKeys,
  selectFilteredPropertyKeys,
  selectPropertyKeysLoading,
} from '../../state/propertyKeys/propertyKeysSlice';
import { restClient } from '@genability/api';
import { LoadingState } from '../../state/reduxUtils';
import { ResponseError } from '@genability/api/dist/rest-client';
import { selectedTaskFilter, TaskFilter } from '../../state/taskFilter/taskFilterSlice';

export interface PopoverContentType {
  apiStatus: 'idle' | 'pending' | 'notfound' | 'resolved' | 'rejected';
  type: 'lse' | 'worker' | 'propertyKey';
  results: any[];
  errors: restClient.ResponseError[] | undefined;
}

const DashboardFilter: React.FC<RouteComponentProps> = props => {
  const dispatch = useDispatch();
  const activeTaskFilter = useSelector(selectedDashboardFilter);
  const { activeFilter, activeTaskType } = useSelector(selectedTaskFilter);
  const [searchTextBuffer, setSearchTextBuffer] = useState<string>('');
  const [taskStatusBuffer, setTaskStatusBuffer] = useState<string[]>([]);
  const [taskSourceBuffer, setTaskSourceBuffer] = useState<string[]>([]);
  const [taskTypeBuffer, setTaskTypeBuffer] = useState<string[]>([]);
  const [searchDate, setSearchDate] = useState<[Date, Date] | null>(null);
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [filteredWorkers, setFilteredWorkers] = useState<IWorker[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { results: workers, errors, apiStatus } = useSelector(selectWorkers);
  const { lses, loading, errorMessage } = useSelector(selectLoadServingEntities);
  const keysLoading = useSelector(selectPropertyKeysLoading);
  const filteredPropertyKeys = useSelector(selectFilteredPropertyKeys);
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 1);

  const applyFilter = useCallback(() => {
    const applyFilter = !activeTaskFilter.applyFilters;
    dispatch(
      setApplyFilters({
        searchTextBuffer,
        taskStatusBuffer,
        taskSourceBuffer,
        taskTypeBuffer,
        searchDate: searchDate
          ? [
              searchDate[0].toISOString().slice(0, -5).split('T')[0],
              searchDate[1].toISOString().slice(0, -5).split('T')[0],
            ]
          : [],
        applyFilter,
      })
    );
  }, [
    dispatch,
    activeTaskFilter,
    searchTextBuffer,
    taskSourceBuffer,
    taskStatusBuffer,
    searchDate,
    taskTypeBuffer,
  ]);

  const debounceSearch = useCallback(
    debounce((searchTerm: string) => {
      dispatch(fetchLoadServingEntity(searchTerm));
    }, 500),
    []
  );

  useEffect(() => {
    const request = new GetWorkersRequest();
    request.qualification = QualificationType.TARIFF_MODELLER;
    dispatch(fetchWorkers(request));
  }, []);

  const popoverContent = ({ apiStatus, errors, results, type }: PopoverContentType) => (
    <>
      {apiStatus === 'pending' ? (
        <ListGroupItem style={{ minHeight: '50px' }}> Loading Options....</ListGroupItem>
      ) : (
        <fieldset>
          {errors && (
            <ListGroupItem style={{ minHeight: '50px' }}>
              <b>Error:</b> {errors}
            </ListGroupItem>
          )}
          {!errors && results?.length == 0 && (
            <ListGroupItem style={{ minHeight: '50px' }}>No results for given term</ListGroupItem>
          )}
          {results?.length > 0 && (
            <ListGroup style={{ maxHeight: '300px', overflowY: 'auto' }}>
              {type === 'worker' &&
                results?.map((worker, index) => (
                  <ListGroupItem
                    key={index}
                    data-testid={`item-${worker.userName}`}
                    style={{
                      maxHeight: '50px',
                    }}
                    onMouseUp={() => {
                      setSearchTextBuffer('assignee: ' + worker.userName);
                      setFilteredWorkers([worker]);
                      setShowPopover(false);
                    }}
                  >
                    {worker.userName}
                  </ListGroupItem>
                ))}
              {type === 'lse' &&
                lses?.map((item, index) => (
                  <ListGroupItem
                    key={index}
                    onMouseUp={() => {
                      setSearchTextBuffer('lseName: ' + item.name);
                      debounceSearch(item.name);
                      setShowPopover(false);
                    }}
                    style={{
                      minHeight: '55px',
                    }}
                  >
                    {item.name}
                  </ListGroupItem>
                ))}
              {type === 'propertyKey' &&
                filteredPropertyKeys?.map((propertyKey, index) => (
                  <ListGroupItem
                    key={index}
                    data-testid={`item-${propertyKey.keyName}`}
                    style={{
                      maxHeight: '50px',
                    }}
                    onMouseUp={() => {
                      setSearchTextBuffer('propertyKey: ' + propertyKey.keyName);
                      setShowPopover(false);
                    }}
                  >
                    {propertyKey.keyName}
                  </ListGroupItem>
                ))}
            </ListGroup>
          )}
        </fieldset>
      )}
    </>
  );

  return (
    <Container className="ml-4">
      <Row>
        <Text color="primary" opacity="high" tag="h3" textStyle="eyebrow400" className="mb-1">
          Filter By:
        </Text>
      </Row>
      <Row>
        <Col md={2} className="m-3">
          <FilterSection
            title="Task Status"
            filterValues={Object.values(TaskStatusType)}
            filterType="statusFilter"
            bufferValues={taskStatusBuffer}
            setBuffer={setTaskStatusBuffer}
          />
        </Col>
        <Col md={2} className="m-3">
          <FilterSection
            title="Task Type"
            filterValues={Object.values(TaskType)}
            filterType="tariffTaskType"
            bufferValues={taskTypeBuffer}
            setBuffer={setTaskTypeBuffer}
          />
        </Col>
        <Col md={2} className="m-3">
          <FilterSection
            title="Task Source"
            filterValues={Object.values(TaskSource)}
            filterType="taskSource"
            bufferValues={taskSourceBuffer}
            setBuffer={setTaskSourceBuffer}
          />
        </Col>
        <Col md={4} className="m-3">
          <Text
            color="primary"
            opacity="high"
            tag="h3"
            textStyle="eyebrow400"
            className="ml-1 mb-3"
          >
            {'SEARCH BY'}
          </Text>
          <FilteredSearchInput
            className={`${styles.tariffDirectorySearch}`}
            label=""
            name="search"
            onClick={() =>
              ['assignee', 'lseName', 'propertyKey'].includes(searchTextBuffer.split(': ')[0]) &&
              setShowPopover(true)
            }
            onChange={value => {
              if (
                value == '' ||
                [
                  'masterTariffId',
                  'propertyKey',
                  'assignee',
                  'lseName',
                  'taskId',
                  'lseId',
                ].includes(value.split(': ')[0])
              ) {
                setSearchTextBuffer(value);
                if (value.split(': ')[0] == 'assignee') {
                  setShowPopover(true);
                  if (value.split(': ').length > 1) {
                    setFilteredWorkers(
                      workers.filter(worker => worker.userName.startsWith(value.split(': ')[1]))
                    );
                  }
                } else if (value.split(': ')[0] == 'lseName') {
                  setShowPopover(true);
                  if (value.split(': ').length > 1) {
                    debounceSearch(value.split(': ')[1]);
                  }
                } else if (value.split(': ')[0] == 'propertyKey') {
                  setShowPopover(true);
                  dispatch(fetchPropertyKeys({ searchTerm: value.split(': ')[1] }));
                }
              }
            }}
            helperTextColor="secondary"
            value={searchTextBuffer}
            filterOptions={[
              {
                description: 'TASK ID',
                tag: 'taskId',
              },
              {
                description: 'MTID',
                tag: 'masterTariffId',
              },
              {
                description: 'PROPERTYKEY',
                tag: 'propertyKey',
              },
              {
                description: 'ASSIGNEE',
                tag: 'assignee',
              },
              {
                description: 'LSE ID',
                tag: 'lseId',
              },
              {
                description: 'LSE NAME',
                tag: 'lseName',
              },
            ]}
            onEnter={() => {
              return;
            }}
          />
          <Input
            ref={inputRef}
            className="invisible"
            label=""
            name=""
            onChange={() => null}
            style={{ height: 0 }}
          />
          <Overlay
            show={showPopover}
            target={inputRef.current?.parentElement || inputRef.current}
            placement="bottom-start"
            transition={false}
            rootClose
            onHide={() => setShowPopover(false)}
          >
            <Popover
              style={{
                minWidth: inputRef.current?.parentElement?.clientWidth,
                zIndex: 19,
              }}
              id="assigneePop"
            >
              {searchTextBuffer.split(': ')[0] == 'assignee' &&
                popoverContent({ apiStatus, errors, results: filteredWorkers, type: 'worker' })}
              {searchTextBuffer.split(': ')[0] == 'lseName' &&
                popoverContent({
                  apiStatus: loading === LoadingState.PENDING ? 'pending' : 'idle',
                  errors: errorMessage ? ([errorMessage] as unknown as ResponseError[]) : undefined,
                  results: lses,
                  type: 'lse',
                })}
              {searchTextBuffer.split(': ')[0] == 'propertyKey' &&
                popoverContent({
                  apiStatus: keysLoading ? 'pending' : 'idle',
                  errors: undefined,
                  results: filteredPropertyKeys,
                  type: 'propertyKey',
                })}
            </Popover>
          </Overlay>

          <Text color="primary" opacity="high" tag="h3" textStyle="eyebrow400" className="m-1 mt-2">
            {'DATE RANGE'}
          </Text>
          <DateRangePicker
            date={searchDate}
            setDate={setSearchDate}
            placeholder="Search by Creation Date Range..."
            minDate={
              activeFilter == TaskFilter.ALL_TASKS && activeTaskType == 'tariff'
                ? minDate
                : undefined
            }
          />
          <Button backgroundColor="primaryInverse" className="mt-4 ml-1" onClick={applyFilter}>
            Apply Filters
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(DashboardFilter);
