import { restClient } from '@genability/api';
import { IReportingConfig } from '../types/reporting-config';

export class ReportingConfigApi extends restClient.RestApiClient {
  public async getReportingConfig(
    name?: string
  ): Promise<restClient.PagedResponse<IReportingConfig>> {
    return this.getSingle<IReportingConfig>('/v2/reporting/config', { params: { name } });
  }
}
