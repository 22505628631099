import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Overlay from 'react-bootstrap/Overlay';
import { Input } from '@arcadiapower/shrike';
import { GetWorkersRequest } from '../../task-api/v2/api/task-api-v2';
import { ListGroup, ListGroupItem, Popover, Row } from 'react-bootstrap';
import { QualificationType } from '../../task-api/v2/types/QualifictionType';
import { IWorker } from '../../task-api/types/worker';
import { fetchWorkers, selectWorkers } from '../../state/workers-v2/workersSlice';

export interface WorkerInputProps {
  label: string;
  placeholder?: string;
  qualification?: QualificationType;
  id: string;
  popoverStyle?: React.CSSProperties;
  name?: string;
  selected?: IWorker;
  setSelected?: (worker: IWorker | undefined) => void;
}

const WorkerInput: React.FC<WorkerInputProps> = props => {
  const { label, placeholder, id, name, selected, popoverStyle, setSelected, qualification } =
    props;
  const [searchTerm, setSearchTerm] = useState('');
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);

  const [show, setShow] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const dispatch = useDispatch();
  const { results: workers, errors, apiStatus } = useSelector(selectWorkers);
  const [filteredWorkers, setFilteredWorkers] = useState<IWorker[]>([]);

  useEffect(() => {
    if (searchTerm == '') {
      setFilteredWorkers(workers);
    } else {
      setFilteredWorkers(workers.filter(worker => worker.userName.startsWith(searchTerm)));
    }
  }, [workers, searchTerm]);

  useEffect(() => {
    if (qualification) {
      const request = new GetWorkersRequest();
      request.qualification = qualification;
      dispatch(fetchWorkers(request));
    }
  }, [qualification]);

  useEffect(() => {
    if (selected) {
      setSearchTerm(selected.userName);
    }
  }, [selected]);

  const handleMouseOver = (index: number) => {
    setHoveredItem(index);
  };

  const handleMouseOut = () => {
    setHoveredItem(null);
  };

  const handleChange = (value: string) => {
    setSearchTerm(value);
    if (value) setShow(value ? true : false);
    if (!value && setSelected) setSelected(undefined);
  };

  const handleSelection = (worker: IWorker) => {
    setSearchTerm(worker.userName || '');
    if (setSelected) {
      setSelected(worker);
    }
    setShow(false);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.autocomplete = 'off';
    }
  }, [inputRef]);

  const popoverContent = (
    <>
      {apiStatus === 'pending' ? (
        <ListGroupItem style={{ minHeight: '50px' }}> Loading Options....</ListGroupItem>
      ) : (
        <fieldset>
          {errors && (
            <ListGroupItem style={{ minHeight: '50px' }}>
              <b>Error:</b> {errors}
            </ListGroupItem>
          )}
          {!errors && filteredWorkers?.length == 0 && (
            <ListGroupItem style={{ minHeight: '50px' }}>
              No results for term <b>{searchTerm}</b>
            </ListGroupItem>
          )}
          {filteredWorkers?.length > 0 && (
            <ListGroup style={{ maxHeight: '300px', overflowY: 'auto' }}>
              {filteredWorkers?.map((worker, index) => (
                <ListGroupItem
                  key={index}
                  data-testid={`item-${worker.userName}`}
                  onMouseOver={() => handleMouseOver(index)}
                  onMouseOut={() => handleMouseOut()}
                  style={{
                    backgroundColor: hoveredItem === index ? '#e5f2fd' : '',
                    maxHeight: '50px',
                  }}
                  onMouseUp={() => handleSelection(worker)}
                >
                  {worker.userName}
                </ListGroupItem>
              ))}
            </ListGroup>
          )}
        </fieldset>
      )}
    </>
  );

  return (
    <div>
      <Input
        label={label}
        placeholder={placeholder || 'Search'}
        id={id}
        name={name || ''}
        ref={inputRef}
        value={searchTerm}
        icon={!searchTerm ? 'UISearch' : undefined}
        type="text"
        onClick={() => {
          if (qualification) {
            const request = new GetWorkersRequest();
            request.qualification = qualification;
            dispatch(fetchWorkers(request));
          }
          setShow(prev => !prev);
        }}
        onChange={value => handleChange(value)}
      />

      <Overlay
        show={show}
        target={inputRef.current?.parentElement || inputRef.current}
        placement="bottom-start"
        transition={false}
        rootClose
        onHide={() => setShow(false)}
      >
        <Popover
          style={{
            minWidth: inputRef.current?.parentElement?.clientWidth,
            ...popoverStyle,
          }}
          id={`${id}_users`}
        >
          {popoverContent}
        </Popover>
      </Overlay>
    </div>
  );
};

export default WorkerInput;
