import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { TaskApiV2Client } from '../../GenApiClient';
import { addNotification } from '../notification/notificationSlice';
import { RootState } from '../rootReducer';
import { AppThunk } from '../store';
import { LookupValueV2Transformed } from '../../task-api/v2/types/LookupValueV2Transformed';
import { GetLookupValuesRequest } from '../../task-api/v2/api/task-api-v2';
import { LookupValueType } from '../../task-api/v2/types/LookupValueType';
import { SortOrder } from '@genability/api/dist/rest-client';
import { TaskType } from '../../task-api/v2/types/TaskType';
import { TaskAssignmentAnswerV2 } from '../../task-api/v2/types/TaskAssignmentAnswerV2';

const ERROR_MESSAGE = 'There was an error while filling Lookup gaps, Please Try Saving Agian.';

export interface LookupGapFillingState {
  taskAssignmentAnswer: TaskAssignmentAnswerV2 | null;
  isLoading: boolean;
  error: string | null;
}

export const initialState: LookupGapFillingState = {
  taskAssignmentAnswer: null,
  isLoading: false,
  error: '',
};

interface GapFillingSuccess {
  taskAssignmentAnswer: TaskAssignmentAnswerV2 | null;
}

function gapFillingStartLoading(state: LookupGapFillingState) {
  state.isLoading = true;
}

function gapFillingLoadingFailed(state: LookupGapFillingState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

export const task = createSlice({
  name: 'gapFilling',
  initialState,
  reducers: {
    gapFillingStart: gapFillingStartLoading,
    gapFillingSuccess(state, { payload }: PayloadAction<GapFillingSuccess>) {
      const { taskAssignmentAnswer } = payload;
      state.taskAssignmentAnswer = taskAssignmentAnswer;
      state.isLoading = false;
      state.error = null;
    },
    gapFillingFailure: gapFillingLoadingFailed,
  },
});

export const { gapFillingStart, gapFillingFailure, gapFillingSuccess } = task.actions;

export default task.reducer;

export const fillGaps =
  (
    propertyKey: string,
    taskId: number | null,
    workflowId: number | null,
    taskType: string | undefined,
    intermediateSavedLookups: LookupValueV2Transformed[],
    actualLookupStartDate: string | undefined,
    actualLookupEndDate: string | undefined
  ): AppThunk =>
  async dispatch => {
    if (taskId == null || workflowId == null) {
      throw new Error('Invalid input: taskId or workflowId null');
    }
    try {
      dispatch(gapFillingStart());
      intermediateSavedLookups = intermediateSavedLookups
        .filter(lookupValue => !lookupValue.isAutoGenerated)
        .sort((a, b) => (new Date(a.fromDateTime) < new Date(b.fromDateTime) ? 1 : -1));
      const client = await TaskApiV2Client();
      const autoGeneratedLookups: LookupValueV2Transformed[] = [];
      for (const intermediateSavedLookup of intermediateSavedLookups) {
        if (!intermediateSavedLookup.isAutoGenerated) {
          if (taskType === TaskType.ADD_LOOKUP) {
            await updateCurrentLookupToDate(
              propertyKey,
              intermediateSavedLookup,
              actualLookupEndDate
            );
          }
          await updatePreviousLookups(
            propertyKey,
            intermediateSavedLookup,
            actualLookupStartDate,
            actualLookupEndDate,
            intermediateSavedLookups,
            autoGeneratedLookups,
            taskType
          );
        }
      }
      intermediateSavedLookups.push(...autoGeneratedLookups);
      const answerJSON = JSON.stringify(intermediateSavedLookups);
      const response = await client.taskV2.saveIntermediateAnswer(workflowId, taskId, {
        answerJSON,
      });
      if (response.errors) {
        throw new Error(ERROR_MESSAGE);
      }
      const taskAssignmentAnswer = response.results[0];
      dispatch(gapFillingSuccess({ taskAssignmentAnswer }));
    } catch (err) {
      if (err) {
        const errorMessage = (err instanceof Error && err.message) || String(err);
        dispatch(gapFillingFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };

const updateCurrentLookupToDate = async (
  propertyKey: string,
  currentLookup: LookupValueV2Transformed,
  actualLookupEndDate: string | undefined
) => {
  const nextLookup = await getNextLookup(propertyKey, currentLookup, actualLookupEndDate);
  currentLookup.toDateTime = nextLookup?.fromDateTime || currentLookup.toDateTime;
};

const updatePreviousLookups = async (
  propertyKey: string,
  currentLookup: LookupValueV2Transformed,
  actualLookupStartDate: string | undefined,
  actualLookupEndDate: string | undefined,
  intermediateSavedLookups: LookupValueV2Transformed[],
  autoGeneratedLookups: LookupValueV2Transformed[],
  taskType: string | undefined
) => {
  const previousLookup = await getPreviousLookup(propertyKey, currentLookup, actualLookupStartDate);
  if (previousLookup && previousLookup.toDateTime !== currentLookup.fromDateTime) {
    const lookupIndex = intermediateSavedLookups.findIndex(
      lookup => lookup.lookupId === previousLookup.lookupId
    );
    if (lookupIndex != -1) {
      intermediateSavedLookups[lookupIndex] = {
        ...intermediateSavedLookups[lookupIndex],
        fromDateTime: previousLookup.fromDateTime,
        toDateTime: currentLookup.fromDateTime,
        parentLookupID: currentLookup.lookupId,
        isAutoGenerated: true,
      };
    } else {
      autoGeneratedLookups.push({
        ...previousLookup,
        toDateTime: currentLookup.fromDateTime,
        parentLookupID: currentLookup.lookupId,
        isAutoGenerated: true,
      });
    }
  }
  if (taskType === TaskType.EDIT_LOOKUP) {
    await removeOverLaps(
      propertyKey,
      currentLookup,
      actualLookupEndDate,
      previousLookup,
      intermediateSavedLookups,
      autoGeneratedLookups
    );
  }
};

const removeOverLaps = async (
  propertyKey: string,
  currentLookup: LookupValueV2Transformed,
  actualLookupEndDate: string | undefined,
  previousLookup: LookupValueV2Transformed | undefined,
  intermediateSavedLookups: LookupValueV2Transformed[],
  autoGeneratedLookups: LookupValueV2Transformed[]
) => {
  const client = await TaskApiV2Client();
  const request = new GetLookupValuesRequest();
  request.lookupValueType = LookupValueType.ACTUAL;
  request.fromDateTime = currentLookup.fromDateTime;
  request.toDateTime = currentLookup.toDateTime || actualLookupEndDate;
  const response = await client.taskV2.getLookupValues(propertyKey, request);
  if (response.errors) {
    throw new Error(ERROR_MESSAGE);
  }
  const lookupValues: LookupValueV2Transformed[] = response.results
    .map(({ propertyLookup, document }) => ({
      ...propertyLookup,
      document,
    }))
    .filter(
      lookupValue =>
        lookupValue.lookupId != currentLookup.lookupId &&
        lookupValue.lookupId != previousLookup?.lookupId
    );
  lookupValues.forEach(lookupValue => {
    const lookupIndex = intermediateSavedLookups.findIndex(
      lookup => lookup.lookupId === lookupValue.lookupId
    );
    if (lookupIndex != -1) {
      intermediateSavedLookups[lookupIndex] = {
        ...intermediateSavedLookups[lookupIndex],
        fromDateTime: lookupValue.fromDateTime,
        toDateTime: lookupValue.toDateTime,
        isAutoGenerated: true,
        toBeDeleted: true,
        parentLookupID: currentLookup.lookupId,
      };
    } else {
      autoGeneratedLookups.push({
        ...lookupValue,
        toDateTime: lookupValue.fromDateTime,
        isAutoGenerated: true,
        toBeDeleted: true,
        parentLookupID: currentLookup.lookupId,
      });
    }
  });
};

const getPreviousLookup = async (
  propertyKey: string,
  lookup: LookupValueV2Transformed,
  actualLookupStartDate: string | undefined
) => {
  if (!actualLookupStartDate) {
    return;
  }
  const client = await TaskApiV2Client();
  const request = new GetLookupValuesRequest();
  request.lookupValueType = LookupValueType.ACTUAL;
  request.fromDateTime = actualLookupStartDate;
  request.toDateTime = lookup.fromDateTime;
  request.sortOn = ['fromDateTime'];
  request.sortOrder = [SortOrder.DESC];
  const response = await client.taskV2.getLookupValues(propertyKey, request);
  if (response.errors) {
    throw new Error(ERROR_MESSAGE);
  }
  const lookupValues: LookupValueV2Transformed[] = response.results.map(
    ({ propertyLookup, document }) => ({
      ...propertyLookup,
      document,
    })
  );
  const previousLookup: LookupValueV2Transformed | undefined = lookupValues.find(
    lookupvalue =>
      new Date(lookupvalue.fromDateTime) < new Date(lookup.fromDateTime) &&
      lookupvalue.lookupId != lookup.lookupId
  );
  return previousLookup;
};

const getNextLookup = async (
  propertyKey: string,
  lookup: LookupValueV2Transformed,
  actualLookupEndDate: string | undefined
) => {
  if (!actualLookupEndDate) {
    return;
  }
  const client = await TaskApiV2Client();
  const request = new GetLookupValuesRequest();
  request.lookupValueType = LookupValueType.ACTUAL;
  request.fromDateTime = lookup.fromDateTime;
  request.toDateTime = actualLookupEndDate;
  request.sortOn = ['fromDateTime'];
  request.sortOrder = [SortOrder.ASC];
  const response = await client.taskV2.getLookupValues(propertyKey, request);
  if (response.errors) {
    throw new Error(ERROR_MESSAGE);
  }
  const lookupValues: LookupValueV2Transformed[] = response.results.map(
    ({ propertyLookup, document }) => ({
      ...propertyLookup,
      document,
    })
  );
  return lookupValues.find(
    lookupvalue =>
      new Date(lookupvalue.fromDateTime) > new Date(lookup.fromDateTime) &&
      lookupvalue.lookupId != lookup.lookupId
  );
};

export const selectUpdatedTaskAssignmentAnswer = (state: RootState): LookupGapFillingState => {
  return state.updatedTaskAssignmentAnswer;
};
