import React, { ChangeEvent, FC, useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import { Button, TextInput } from '@arcadiapower/gen-react-lib';

export interface DQOverrideReasonProps {
  message: string;
  reason?: string; // To set the previous saved reason
  onSave?: (reason: string) => void;
  onCancel?: () => void;
  isVisible: boolean;
  hideModal: () => void;
}

const DQOverrideReason: FC<DQOverrideReasonProps> = ({
  message,
  reason: prevReason,
  onSave,
  onCancel,
  isVisible,
  hideModal,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [reason, setReason] = useState('');

  useEffect(() => {
    if (isVisible) {
      setReason(prevReason || '');
      textAreaRef?.current?.focus();
    }
  }, [isVisible, textAreaRef]);

  const handleCancel = () => {
    onCancel && onCancel();
    hideModal();
  };

  const handleSave = () => {
    onSave && onSave(reason);
    hideModal();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };

  return createPortal(
    <Modal show={isVisible} onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Override DQ Check</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>You are attempting to override the following DQ check:</p>
        <div>
          <Alert variant="danger">{message}</Alert>
        </div>
        <p>Please explain your reason for overriding this DQ check:</p>
        <div>
          <TextInput
            label="Reason"
            controlId={'dq-override-reason'}
            name="overrideReason"
            feedback=""
            required
            as="textarea"
            value={reason}
            type="text"
            onChange={handleChange}
            ref={textAreaRef}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={!reason}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>,
    document.body
  );
};

export default DQOverrideReason;
