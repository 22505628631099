import {
  Input,
  FilteredSearchInput,
  Button,
  Text,
  IconButton,
  Checkbox,
  Icon,
} from '@arcadiapower/shrike';
import { Loader, NotificationLevel, Pagination, Th } from '@arcadiapower/gen-react-lib';
import React, { ReactElement, useState, useCallback, useEffect } from 'react';
import { Row, Table } from 'react-bootstrap';
import TextField from '../../TextField/TextField';
import DropdownSelect from '../../DropdownSelect/DropdownSelect';
import { MonitoringStatusType } from '../../../ops-api/types/MonitoringStatusTypes';
import { useDispatch, useSelector } from 'react-redux';
import {
  TariffStatusProps,
  fetchTariffStatusData,
  selectTariffStatusData,
} from '../../../state/OpsManagement/Status/TariffStatusSlice';
import { SortOrder } from '@genability/api/dist/rest-client';
import { Link } from 'react-router-dom';
import { SentinelStatusType } from '../../../ops-api/types/SentinelStatusTypes';
import { OpsManagementApiClient } from '../../../GenApiClient';
import { addNotification } from '../../../state/notification/notificationSlice';
import { doQuickSearch } from '../../../state/QuickSearch/QuickSearchSlice';
import QuickSearch from '../../QuickSearchComponent/QuickSearch';
import { OpsFilterProps, OpsGlobalFilter } from '../../../pages/OpsManagement/OpsManagementTabs';
import {
  customerTiers,
  TariffDataRequestParams,
  tariffPriorities,
  tariffTiers,
} from '../Config/TariffConfigTable';
import { applyTimeZoneOffSet } from '../../../utils/dateUtils';
import {
  selectUpdatedStatusData,
  startUpdateMonitoringStatusLoading,
  updateMonitoringStatus,
} from '../../../state/OpsManagement/Status/UpdateStatusSlice';
import { fetchTariffStatusStats } from '../../../state/OpsManagement/Status/TariffStatusStatsSlice';
import appConstants from '../../../app-constants';
import { MultiSelect, Option } from 'react-multi-select-component';
import { fetchWorkers, selectWorkers } from '../../../state/workers-v2/workersSlice';
import { QualificationType } from '../../../task-api/v2/types/QualifictionType';
import { GetWorkersRequest } from '../../../task-api/v2/api/task-api-v2';
import DateRangePicker from '../../DateRangePicker/DateRangePicker';
import { selectCurrentUser } from '../../../state/currentUser/currentUserSlice';
import { MonitoringHistory } from '../../../ops-api/types/MonitoringHistory';
import MonitoringHistoryModal from './MonitoringHistoryModal';
import { EntityType } from '../../../task-api/v2/types/EntityType';
import { OpsManagementLocalFilterProps } from './LookupStatusTable';
import { UserMonitoringStatusType } from '../../../ops-api/types/UserMonitoringStatusType';
import {
  fetchMonitoringStatusSentinelDates,
  selectMonitoringStatusSentinelDates,
} from '../../../state/OpsManagement/Status/SentinelDatesSlice';

export interface SentinelDetails {
  sentinelStatus: SentinelStatusType;
  sentinelDiffUrl: string;
  rateUrl: string;
  syncDate: string;
  batchRunDate: string;
  latestScheduledBatchRunDate: string;
}

export interface TariffStatusData {
  monitoringInventoryId: number;
  lseId: number;
  lseName: string;
  tariffName: string;
  tariffCode: string;
  priority: string;
  tariffTier: string;
  customerTier: string;
  customerCount: number;
  getTariffCount: number;
  onDemandCount: number;
  savingsAnalysisCount: number;
  masterTariffId: number;
  latestTariffId: number;
  modeller: string;
  reviewer: string;
  sentinelConfigured: boolean;
  effectiveStartDate: string;
  lastUpdatedDate: string;
  nextExpectedPostDate: string;
  dueInDays: number;
  tariffType: string;
  utilityWebsite: string;
  monitoringStatus: MonitoringHistory;
  sentinelDetails: SentinelDetails;
}
const TariffStatusTable = ({ filter, setFilter }: OpsFilterProps): ReactElement => {
  const dispatch = useDispatch();
  const { results, apiStatus: fetchApiStatus, count } = useSelector(selectTariffStatusData);
  const { results: sentinelResults, apiStatus: sentinelApiStatus } = useSelector(
    selectMonitoringStatusSentinelDates
  );
  const { results: workers } = useSelector(selectWorkers);
  const currentUser = useSelector(selectCurrentUser);
  const { results: updatedStatusResults, apiStatus: updateApiStatus } =
    useSelector(selectUpdatedStatusData);
  const [sort, setSort] = useState({ sortOn: 'masterTariffId', sortOrder: 'ASC' });
  const [params, setParams] = useState<TariffDataRequestParams>({
    showUnassignedTariff: false,
    showInActiveTariff: false,
  });
  const [page, setPage] = useState(1);
  const pageCount = 50;
  const totalPageCount = Math.ceil(count / pageCount);
  const [exportLoad, setExportLoad] = useState<boolean>(false);
  const [modeller, setModeller] = useState<Option[]>([
    { value: currentUser.username, label: currentUser.username },
  ]);
  const [statusEditMode, setStatusEditMode] = useState(0);
  const [localFilter, setLocalFilter] = useState<Partial<OpsManagementLocalFilterProps>>({});
  const [startDate, setStartDate] = useState<[Date, Date] | null>(null);
  const [monitoredDate, setMonitoredDate] = useState<[Date, Date] | null>(null);
  const [editedRow, setEditedRow] = useState<
    { notes: string | undefined; status: string | undefined } | undefined
  >(undefined);
  const [showMonitoringHistory, setShowMonitoringHistory] = useState<boolean>(false);
  const [monitoringInventoryId, setMonitoringInventoryId] = useState<number>();
  const [entityID, setEntityId] = useState<string>();
  const [sentinalRefreshLoading, setSentinelRefreshLoading] = useState<boolean>(false);

  const tariffStatusHeaders = [
    {
      key: 'lseName',
      label: (
        <span className="d-flex flex-column">
          <span>Lse</span>
          <span>Name</span>
        </span>
      ),
      search: (
        <QuickSearch
          popoverStyle={{ zIndex: 10 }}
          value={localFilter.lse || filter.lse || ''}
          onClick={() => {
            dispatch(
              doQuickSearch({ minChar: 1, key: 'lse', value: localFilter.lse?.split('-')[0] })
            );
          }}
          onChange={value => {
            setLocalFilter({
              lse: value,
            });
            if (filter.lse && value == '') {
              setFilter(prev => {
                return {
                  ...prev,
                  lse: undefined,
                };
              });
            }
            dispatch(doQuickSearch({ minChar: 1, key: 'lse', value }));
          }}
          onSelect={value => {
            setLocalFilter({
              lse: value,
            });
            setFilter(prev => {
              return {
                ...prev,
                lse: value || undefined,
              };
            });
            setPage(1);
          }}
          placeholder="Search"
        />
      ),
    },
    {
      key: 'tariffName',
      label: (
        <span className="d-flex flex-column">
          <span>Tariff</span>
          <span>Name</span>
        </span>
      ),
      search: (
        <QuickSearch
          popoverStyle={{ zIndex: 10 }}
          value={localFilter.tariffName || filter.tariffName || ''}
          onClick={() => {
            dispatch(
              doQuickSearch({ minChar: 3, key: 'tariffName', value: localFilter.tariffName })
            );
          }}
          onChange={value => {
            if (filter.tariffName && value == '') {
              setFilter(prev => {
                return {
                  ...prev,
                  tariffName: undefined,
                };
              });
            }
            dispatch(
              doQuickSearch({
                minChar: 3,
                key: 'tariffName',
                value: value != '' ? value : undefined,
              })
            );
            setLocalFilter({
              tariffName: value != '' ? value : undefined,
            });
          }}
          onSelect={value => {
            setLocalFilter({
              tariffName: value,
            });
            setPage(1);
            setFilter(prev => {
              return {
                ...prev,
                tariffName: value,
              };
            });
          }}
          placeholder="Search"
        />
      ),
    },
    {
      key: 'masterTariffId',
      label: 'Master Tariff Id',
      search: (
        <QuickSearch
          popoverStyle={{ zIndex: 10 }}
          value={localFilter.masterTariffId || filter.masterTariffId || ''}
          onClick={() => {
            dispatch(
              doQuickSearch({
                minChar: 1,
                key: 'masterTariffId',
                value: localFilter.masterTariffId,
              })
            );
          }}
          onChange={value => {
            if (filter.masterTariffId && value == '') {
              setFilter(prev => {
                return {
                  ...prev,
                  masterTariffId: undefined,
                };
              });
            }
            if (isNaN(Number(value))) {
              return;
            }
            dispatch(
              doQuickSearch({
                minChar: 1,
                key: 'masterTariffId',
                value: value != '' ? value : undefined,
              })
            );
            setLocalFilter({
              masterTariffId: value != '' ? value : undefined,
            });
          }}
          onSelect={value => {
            setLocalFilter({
              masterTariffId: value,
            });
            setPage(1);
            setFilter(prev => {
              return {
                ...prev,
                masterTariffId: Number(value),
              };
            });
          }}
          placeholder="Search"
        />
      ),
    },
    {
      key: 'priorityLevel',
      label: 'Tariff Priority',
      search: (
        <DropdownSelect
          popoverStyle={{ zIndex: 10 }}
          style={{ maxWidth: '7vw' }}
          selected={filter.priority}
          setSelected={value => {
            if (![...tariffPriorities, ''].includes(value || '')) {
              return;
            }
            setPage(1);
            setFilter(prev => {
              return { ...prev, priority: value };
            });
          }}
          options={tariffPriorities}
          label=""
          onChange={value =>
            value == '' &&
            setFilter(prev => {
              return { ...prev, priority: value };
            })
          }
        />
      ),
    },
    {
      key: 'tariffTier',
      label: 'Tariffs Tier',
      search: (
        <DropdownSelect
          popoverStyle={{ zIndex: 10 }}
          style={{ maxWidth: '7vw' }}
          selected={filter.tariffTier}
          setSelected={value => {
            if (![...tariffTiers, ''].includes(value || '')) {
              return;
            }
            setPage(1);
            setFilter(prev => {
              return { ...prev, tariffTier: value };
            });
          }}
          options={tariffTiers}
          label=""
          onChange={value =>
            value == '' &&
            setFilter(prev => {
              return { ...prev, tariffTier: value };
            })
          }
        />
      ),
    },
    {
      key: 'customerTier',
      label: 'Customer Tier',
      search: (
        <DropdownSelect
          popoverStyle={{ zIndex: 10 }}
          style={{ maxWidth: '7vw' }}
          selected={filter.customerTier}
          setSelected={value => {
            if (![...customerTiers, ''].includes(value || '')) {
              return;
            }
            setPage(1);
            setFilter(prev => {
              return { ...prev, customerTier: value };
            });
          }}
          options={customerTiers}
          label=""
          onChange={value =>
            value == '' &&
            setFilter(prev => {
              return { ...prev, customerTier: value };
            })
          }
        />
      ),
    },
    {
      key: 'sentinelStatus',
      label: 'Sentinel Status',
      search: (
        <DropdownSelect
          popoverStyle={{ zIndex: 10 }}
          selected={localFilter.sentinelStatus || filter.sentinelStatus || ''}
          onChange={value => {
            if (filter.sentinelStatus && value == '') {
              setFilter(prev => {
                return {
                  ...prev,
                  sentinelStatus: undefined,
                };
              });
            }
            setLocalFilter({ sentinelStatus: value });
          }}
          setSelected={value => {
            setLocalFilter({ sentinelStatus: value });
            setPage(1);
            setFilter(prev => {
              return { ...prev, sentinelStatus: value as SentinelStatusType };
            });
          }}
          options={Object.keys(SentinelStatusType).filter(
            key =>
              !localFilter.sentinelStatus?.length ||
              key.includes(localFilter.sentinelStatus.toUpperCase())
          )}
          label=""
        />
      ),
    },
    {
      key: 'monitoringStatus',
      label: 'Monitoring Status',
      search: (
        <DropdownSelect
          popoverStyle={{ zIndex: 10 }}
          selected={localFilter.monitoringStatus || filter.monitoringStatus || ''}
          onChange={value => {
            if (filter.monitoringStatus && value == '') {
              setFilter(prev => {
                return {
                  ...prev,
                  monitoringStatus: undefined,
                };
              });
            }
            setLocalFilter({ monitoringStatus: value });
          }}
          setSelected={value => {
            setLocalFilter({ monitoringStatus: value });
            setPage(1);
            setFilter(prev => {
              return { ...prev, monitoringStatus: value as MonitoringStatusType };
            });
          }}
          options={Object.keys(MonitoringStatusType).filter(
            key =>
              !localFilter.monitoringStatus?.length ||
              key.includes(localFilter.monitoringStatus.toUpperCase())
          )}
          label=""
        />
      ),
    },
    {
      key: 'monitoringNotes',
      label: 'Monitoring Notes',
      search: (
        <Input
          name="notes"
          value={localFilter.monitoringNotesBuffer}
          onChange={value => {
            setLocalFilter(prev => {
              return { ...prev, monitoringNotesBuffer: value };
            });
            if (value === '') {
              setFilter(prev => {
                return { ...prev, monitoringNotes: undefined };
              });
            }
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              setFilter(prev => {
                return { ...prev, monitoringNotes: localFilter.monitoringNotesBuffer };
              });
            }
          }}
          label=""
          placeholder="Enter..."
        />
      ),
    },
    {
      key: 'lastMonitoredDate',
      label: 'Monitored date',
      search: <DateRangePicker date={monitoredDate} setDate={setMonitoredDate} />,
    },
    {
      key: 'effectiveStartDate',
      label: 'Effective start date',
      search: <DateRangePicker date={startDate} setDate={setStartDate} />,
    },
    // {
    //   key: 'nextExpectedPostDate',
    //   label: 'Expected Post Date',
    //   search: (
    //     <Input
    //       name="nepd"
    //       value={filter.nextExpectedPostDate?.toString().split('T')[0] || ''}
    //       onChange={value => {
    //         setPage(1);
    //         setFilter(prev => {
    //           return { ...prev, nextExpectedPostDate: value };
    //         });
    //       }}
    //       label=""
    //       placeholder="Search"
    //     />
    //   ),
    // },
    {
      key: 'dueInDays',
      label: 'Due in days',
      search: (
        <Input
          name="due"
          value={localFilter.dueInDaysBuffer}
          onChange={value => {
            setLocalFilter(prev => {
              return { ...prev, dueInDaysBuffer: value };
            });
            if (filter.dueInDays && value === '') {
              setFilter(prev => {
                return { ...prev, dueInDays: undefined };
              });
            }
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              setFilter(prev => {
                return { ...prev, dueInDays: localFilter.dueInDaysBuffer };
              });
            }
          }}
          label=""
          placeholder="Enter..."
        />
      ),
    },
    {
      key: 'customerCount',
      label: 'Customer count',
      search: (
        <Input
          name="customer count"
          value={localFilter.customerCountBuffer}
          onChange={value => {
            setLocalFilter(prev => {
              return { ...prev, customerCountBuffer: value };
            });
            if (filter.customerCount && value === '') {
              setFilter(prev => {
                return { ...prev, customerCount: undefined };
              });
            }
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              setFilter(prev => {
                return { ...prev, customerCount: localFilter.customerCountBuffer };
              });
            }
          }}
          label=""
          placeholder="Enter..."
        />
      ),
    },
    {
      key: 'apiCount',
      label: 'Api count',
      search: (
        <Input
          name="api count"
          value={localFilter.apiCountBuffer}
          onChange={value => {
            setLocalFilter(prev => {
              return { ...prev, apiCountBuffer: value };
            });
            if (filter.apiCount && value === '') {
              setFilter(prev => {
                return { ...prev, apiCount: undefined };
              });
            }
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              setFilter(prev => {
                return { ...prev, apiCount: localFilter.apiCountBuffer };
              });
            }
          }}
          label=""
          placeholder="Enter..."
        />
      ),
    },
    {
      key: 'edit',
      label: 'Edit ',
      search: null,
    },
    { key: 'history', label: 'History ', search: null },
  ];

  const handleSort = useCallback(
    (key: string) => {
      if (sort.sortOn != key || sort.sortOrder === 'DESC') {
        setSort(prev => {
          return { sortOn: key, sortOrder: 'ASC' };
        });
      } else {
        setSort(prev => {
          return { ...prev, sortOrder: 'DESC' };
        });
      }
    },
    [sort]
  );

  const populateFilters = useCallback((): TariffStatusProps => {
    const request: TariffStatusProps = {
      paginationCriteria: { pageCount, pageStart: (page - 1) * pageCount },
      sortCriteria: { sortOn: [sort.sortOn], sortOrder: [sort.sortOrder] },
      tariffStatusFilterCriteria: {},
    };
    if (modeller) {
      request.tariffStatusFilterCriteria.modellers = modeller.map(worker => worker.label);
    }
    if (filter.sentinelConfigured) {
      request.tariffStatusFilterCriteria.sentinelConfigured = filter.sentinelConfigured;
    }
    if (filter.lse) {
      request.tariffStatusFilterCriteria.lseIds = [filter.lse?.split('-')[0]];
    }
    if (filter.priority) {
      request.tariffStatusFilterCriteria.priorityList = [filter.priority];
    }
    if (filter.tariffTier) {
      request.tariffStatusFilterCriteria.tariffTier = [filter.tariffTier];
    }
    if (filter.customerTier) {
      request.tariffStatusFilterCriteria.customerTier = [filter.customerTier];
    }
    if (filter.masterTariffId) {
      request.tariffStatusFilterCriteria.masterTariffIds = [filter.masterTariffId];
    }
    if (filter.tariffName) {
      request.tariffStatusFilterCriteria.tariffNames = [filter.tariffName];
    }
    if (filter.sentinelStatus) {
      request.tariffStatusFilterCriteria.sentinelStatus = [filter.sentinelStatus];
    }
    if (filter.dueInDays) {
      request.tariffStatusFilterCriteria.dueInDays = filter.dueInDays;
    }
    if (filter.customerCount) {
      request.tariffStatusFilterCriteria.customerCount = filter.customerCount;
    }
    if (filter.apiCount) {
      request.tariffStatusFilterCriteria.apiCount = filter.apiCount;
    }
    if (filter.tariffType) {
      request.tariffStatusFilterCriteria.tariffTypes = [filter.tariffType];
    }
    if (filter.monitoringStatus) {
      request.tariffStatusFilterCriteria.monitoringStatusList = [filter.monitoringStatus];
    }
    if (filter.monitoringNotes) {
      request.tariffStatusFilterCriteria.monitoringNotes = filter.monitoringNotes;
    }
    if (startDate) {
      if (!request.tariffStatusFilterCriteria.dateFilterCriteria) {
        request.tariffStatusFilterCriteria.dateFilterCriteria = [];
      }
      const fromDate = applyTimeZoneOffSet(startDate[0]);
      const toDate = applyTimeZoneOffSet(startDate[1]);
      if (fromDate && toDate) {
        request.tariffStatusFilterCriteria.dateFilterCriteria.push({
          dateColumnName: 'effectiveStartDate',
          fromDate: fromDate?.toISOString().slice(0, -5).split('T')[0],
          toDate: toDate?.toISOString().slice(0, -5).split('T')[0],
        });
      }
    }
    if (monitoredDate) {
      if (!request.tariffStatusFilterCriteria.dateFilterCriteria) {
        request.tariffStatusFilterCriteria.dateFilterCriteria = [];
      }
      const fromDate = applyTimeZoneOffSet(monitoredDate[0]);
      const toDate = applyTimeZoneOffSet(monitoredDate[1]);
      if (fromDate && toDate) {
        request.tariffStatusFilterCriteria.dateFilterCriteria.push({
          dateColumnName: 'lastMonitoredDate',
          fromDate: fromDate?.toISOString().slice(0, -5).split('T')[0],
          toDate: toDate?.toISOString().slice(0, -5).split('T')[0],
        });
      }
    }

    return request;
  }, [page, pageCount, sort, filter, modeller, startDate, monitoredDate]);

  const exportFunction = async () => {
    setExportLoad(true);
    const request: TariffStatusProps = populateFilters();
    const client = await OpsManagementApiClient();
    const response = await client.opsData.exportTariffStatusData(request, params);
    if (response.errors) {
      dispatch(addNotification('Export Failed !', NotificationLevel.Error));
    } else {
      dispatch(addNotification('Exported Successfully !!!!', NotificationLevel.Success));
    }
    setExportLoad(false);
  };

  useEffect(() => {
    dispatch(fetchTariffStatusStats({}));
  }, [updatedStatusResults]);

  useEffect(() => {
    const workerRequest = new GetWorkersRequest();
    workerRequest.qualification = QualificationType.LOOKUP_MODELLER;
    dispatch(fetchWorkers(workerRequest));
    const request: TariffStatusProps = {
      paginationCriteria: { pageCount: 1, pageStart: 0 },
      sortCriteria: { sortOn: [sort.sortOn], sortOrder: [sort.sortOrder] },
      tariffStatusFilterCriteria: { sentinelConfigured: true },
    };
    dispatch(fetchMonitoringStatusSentinelDates({ request, params }));
  }, []);

  useEffect(() => {
    const request: TariffStatusProps = populateFilters();
    dispatch(fetchTariffStatusData({ request, params }));
  }, [
    page,
    pageCount,
    sort,
    filter,
    updatedStatusResults,
    params,
    modeller,
    startDate,
    monitoredDate,
  ]);

  useEffect(() => {
    setModeller([{ value: currentUser.username, label: currentUser.username }]);
  }, [currentUser]);

  return (
    <Row key={'tariff'} id="tariff" className="d-flex justify-content-between text-center">
      <span className="w-100 text-left ml-1 mb-3 mt-1 d-flex justify-content-between">
        <span className="d-flex justify-content-start w-75 align-items-center">
          <h3>Tariff Monitoring Status</h3>
          <Button
            size="medium"
            className="mx-4"
            style={{ minWidth: '180px' }}
            onClick={async () => {
              const client = await OpsManagementApiClient();
              setSentinelRefreshLoading(true);
              const response = await client.opsData.refreshSentinelStatus();
              if (!response.errors) {
                dispatch(
                  addNotification(
                    'Sentinel Refresh Process initiated. You will be notified in slack once refresh is completed.',
                    NotificationLevel.Success
                  )
                );
              } else {
                dispatch(
                  addNotification(
                    'Refreshing Sentinel Details Failed, Please try again.',
                    NotificationLevel.Error
                  )
                );
              }
              setSentinelRefreshLoading(false);
            }}
            loading={sentinalRefreshLoading}
          >
            <Icon icon="UIRenew" color="accent1" className="mr-2" />
            Refresh Sentinel
          </Button>
          <span>
            <Text textStyle="heading600">{`Last synced on ${
              sentinelApiStatus === 'pending'
                ? '...'
                : sentinelResults.length
                ? applyTimeZoneOffSet(
                    new Date(sentinelResults[0].sentinelDetails?.syncDate)
                  )?.toLocaleString('sv-SE')
                : '(NA)'
            }`}</Text>
            <Text className="mt-2" textStyle="heading600">{`Batch run date ${
              sentinelApiStatus === 'pending'
                ? '...'
                : sentinelResults.length
                ? applyTimeZoneOffSet(
                    new Date(sentinelResults[0].sentinelDetails?.latestScheduledBatchRunDate)
                  )?.toLocaleString('sv-SE')
                : '(NA)'
            }`}</Text>
          </span>
        </span>
        <span
          className="d-flex justify-content-around h6 align-items-center mr-3"
          style={{ width: '40%' }}
        >
          <h5>Filter by Modeller :</h5>
          <span style={{ zIndex: 4, width: '60%' }}>
            <MultiSelect
              options={workers.map(worker => {
                return { label: worker.userName, value: worker };
              })}
              className="w-100 border border-dark rounded ml-3"
              value={modeller}
              labelledBy="Search Modeller"
              hasSelectAll={false}
              disabled={fetchApiStatus === 'pending'}
              onChange={setModeller}
            />
          </span>
        </span>
      </span>
      <span className="d-flex justify-content-between w-100 align-items-center">
        <span className="d-flex justify-content-between align-items-center">
          <Checkbox
            checked={params.showInActiveTariff}
            className="ml-2"
            onChange={() =>
              setParams(prev => {
                return { ...prev, showInActiveTariff: !prev.showInActiveTariff };
              })
            }
            disabled={fetchApiStatus === 'pending'}
          >
            Include InActive Tariffs
          </Checkbox>
          <Checkbox
            checked={filter.sentinelConfigured ? true : false}
            className="ml-5"
            onChange={() => {
              setPage(1);
              setFilter(prev => {
                return { ...prev, sentinelConfigured: !prev.sentinelConfigured };
              });
            }}
            disabled={fetchApiStatus === 'pending'}
          >
            Show Sentinel Configured Only
          </Checkbox>
        </span>
        <span className="d-flex justify-content-between align-items-center">
          <Text color="primary" opacity="high" tag="p" textStyle="paragraph400" className="mr-2">
            Showing {(page - 1) * pageCount + 1} - {Math.min(page * pageCount, count)} of
            {` ${count}`} entries
          </Text>
          <span className="pt-3">
            <Pagination
              activePage={page}
              totalPages={totalPageCount}
              onSelectPage={selectedPage => setPage(selectedPage)}
            />
          </span>
          <Button
            size="medium"
            className="ml-3"
            onClick={exportFunction}
            style={{ minWidth: '110px', height: '45px' }}
            loading={exportLoad}
          >
            <Icon icon="UIDownload" color="accent1" className="mr-1" />
            Export
          </Button>
        </span>
      </span>
      <span
        onClick={() => {
          setFilter({} as OpsGlobalFilter);
          setStartDate(null);
          setMonitoredDate(null);
          setLocalFilter({});
          setParams(prev => {
            return { ...prev, showInActiveTariff: false };
          });
        }}
        className="ml-2"
        style={{ textDecoration: 'underLine', color: '#009933', cursor: 'pointer' }}
      >
        Clear all filters
      </span>
      <span style={{ overflowX: 'scroll' }}>
        <Table
          striped
          className="text-center mw-100 mt-4 mr-1"
          style={{ wordBreak: 'break-word' }}
          key="tariffs"
        >
          <thead>
            <tr className="d-flex justify-content-around">
              {tariffStatusHeaders.map(({ key, label, search }) => (
                <Th
                  key={key}
                  sortKey={key != 'edit' ? key : ''}
                  sortConfig={{ sortOn: [sort.sortOn], sortOrder: [sort.sortOrder as SortOrder] }}
                  className="d-flex align-items-center justify-content-center"
                  style={
                    ['lseName', 'tariffName'].includes(key)
                      ? { width: '11vw' }
                      : key == 'edit'
                      ? { width: '60px' }
                      : ['dueInDays', 'tariffTier', 'customerTier', 'priorityLevel'].includes(key)
                      ? { width: '7vw' }
                      : { width: '9vw' }
                  }
                  onClick={() => (key != 'edit' ? handleSort(key) : {})}
                >
                  <span style={{ fontSize: '1.1rem' }}>{label}</span>
                </Th>
              ))}
            </tr>
          </thead>
          {fetchApiStatus === 'pending' || updateApiStatus === 'pending' ? (
            <tbody className="d-flex flex-column">
              <tr>
                {tariffStatusHeaders.map(({ key, search }) => (
                  <td
                    style={
                      ['lseName', 'tariffName'].includes(key)
                        ? { width: '11vw' }
                        : key == 'edit'
                        ? { width: '60px' }
                        : ['dueInDays', 'tariffTier', 'customerTier', 'priorityLevel'].includes(key)
                        ? { width: '7vw' }
                        : { width: '9vw' }
                    }
                    key={key}
                    className="align-middle"
                  >
                    {search}
                  </td>
                ))}
              </tr>
              <tr
                style={{ height: '20vh' }}
                className="d-flex justify-content-around align-items-center"
              >
                <td style={{ position: 'absolute', left: '50vw' }}>
                  <Loader />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody className="d-flex flex-column">
              <tr>
                {tariffStatusHeaders.map(({ key, search }) => (
                  <td
                    style={
                      ['lseName', 'tariffName'].includes(key)
                        ? { width: '11vw' }
                        : key == 'edit'
                        ? { width: '60px' }
                        : ['dueInDays', 'tariffTier', 'customerTier', 'priorityLevel'].includes(key)
                        ? { width: '7vw' }
                        : { width: '9vw' }
                    }
                    key={key}
                    className="align-middle"
                  >
                    {search}
                  </td>
                ))}
              </tr>
              {results.map(tariffRow => (
                <tr
                  key={tariffRow.masterTariffId}
                  style={tariffRow.sentinelConfigured ? { backgroundColor: '#fffae6' } : {}}
                >
                  <td style={{ width: '11vw' }}>
                    <a href={tariffRow.utilityWebsite} target="_blank" rel="noreferrer">
                      <TextField
                        textValue={
                          tariffRow.lseId + (tariffRow.lseName ? '-' + tariffRow.lseName : '')
                        }
                        toolTipId={tariffRow.monitoringInventoryId + 'lse'}
                        maxSize={11}
                      />
                    </a>
                  </td>
                  <td style={{ width: '11vw' }}>
                    {tariffRow.sentinelDetails && tariffRow.sentinelDetails.rateUrl ? (
                      <a target="_blank" rel="noreferrer" href={tariffRow.sentinelDetails.rateUrl}>
                        <TextField
                          textValue={tariffRow.tariffName}
                          toolTipValue={
                            <>
                              {tariffRow.tariffName}
                              <br />
                              {`TariffCode : ${tariffRow.tariffCode}`}
                            </>
                          }
                          toolTipId={tariffRow.monitoringInventoryId + tariffRow.tariffName}
                          maxSize={14}
                          textStyle="paragraph500"
                        />
                      </a>
                    ) : (
                      <TextField
                        textValue={tariffRow.tariffName}
                        toolTipValue={
                          <>
                            {tariffRow.tariffName}
                            <br />
                            {`TariffCode : ${tariffRow.tariffCode}`}
                          </>
                        }
                        toolTipId={tariffRow.monitoringInventoryId + tariffRow.tariffName}
                        maxSize={14}
                        textStyle="paragraph500"
                      />
                    )}
                  </td>
                  <td style={{ width: '9vw' }}>
                    <Link
                      target="_blank"
                      to={{
                        pathname: `${process.env.REACT_APP_MOTHER_URL}/lses/${tariffRow.lseId}/tariffs/${tariffRow.latestTariffId}`,
                      }}
                    >
                      <Text>{tariffRow.masterTariffId || ''}</Text>
                    </Link>
                  </td>
                  <td style={{ width: '7vw' }}>{tariffRow.priority}</td>
                  <td style={{ width: '7vw' }}>{tariffRow.tariffTier}</td>
                  <td style={{ width: '7vw' }}>{tariffRow.customerTier}</td>
                  <td style={{ width: '9vw' }}>
                    {tariffRow.sentinelDetails && tariffRow.sentinelDetails.sentinelDiffUrl ? (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={tariffRow.sentinelDetails.sentinelDiffUrl}
                      >
                        <TextField
                          textValue={tariffRow.sentinelDetails?.sentinelStatus || ''}
                          toolTipValue={
                            <>
                              {tariffRow.sentinelDetails?.sentinelStatus}
                              <br />
                              {`Batch Run Date: ${
                                applyTimeZoneOffSet(
                                  new Date(tariffRow.sentinelDetails?.batchRunDate)
                                )?.toLocaleString('sv-SE') || 'NA'
                              }`}
                            </>
                          }
                          toolTipId={tariffRow.monitoringInventoryId + 'ss'}
                          maxSize={8}
                          textStyle="paragraph500"
                        />
                      </a>
                    ) : (
                      <TextField
                        textValue={tariffRow.sentinelDetails?.sentinelStatus || ''}
                        toolTipValue={
                          <>
                            {tariffRow.sentinelDetails?.sentinelStatus}
                            <br />
                            {`Batch Run Date: ${
                              applyTimeZoneOffSet(
                                new Date(tariffRow.sentinelDetails?.batchRunDate)
                              )?.toLocaleString('sv-SE') || 'NA'
                            }`}
                          </>
                        }
                        toolTipId={tariffRow.monitoringInventoryId + 'ss'}
                        maxSize={8}
                        textStyle="paragraph500"
                      />
                    )}
                  </td>
                  <td style={{ width: '9vw' }}>
                    {tariffRow.monitoringInventoryId != statusEditMode ? (
                      <TextField
                        textValue={tariffRow.monitoringStatus.monitoringStatus}
                        toolTipId={tariffRow.monitoringInventoryId + 'status'}
                        maxSize={9}
                        textStyle="paragraph500"
                      />
                    ) : (
                      <DropdownSelect
                        popoverStyle={{ zIndex: 10 }}
                        selected={editedRow?.status || ''}
                        onChange={value => {
                          setEditedRow({
                            status: value,
                            notes: editedRow?.notes || tariffRow.monitoringStatus.monitoringNotes,
                          });
                        }}
                        setSelected={value => {
                          setEditedRow({
                            status: value as UserMonitoringStatusType,
                            notes: editedRow?.notes || tariffRow.monitoringStatus.monitoringNotes,
                          });
                        }}
                        options={
                          Object.keys(UserMonitoringStatusType).filter(
                            key =>
                              !editedRow?.status?.length ||
                              key.includes(editedRow.status.toUpperCase())
                          ).length > 0
                            ? Object.keys(UserMonitoringStatusType).filter(
                                key =>
                                  !editedRow?.status?.length ||
                                  key.includes(editedRow.status.toUpperCase())
                              )
                            : Object.keys(UserMonitoringStatusType)
                        }
                        label=""
                      />
                    )}
                  </td>
                  <td style={{ width: '9vw' }}>
                    {tariffRow.monitoringInventoryId != statusEditMode ? (
                      <TextField
                        textValue={tariffRow.monitoringStatus.monitoringNotes}
                        toolTipId={tariffRow.monitoringInventoryId + 'moniNotes'}
                        maxSize={10}
                        textStyle="paragraph500"
                      />
                    ) : (
                      <Input
                        name="notes"
                        value={editedRow?.notes}
                        errorText={!editedRow?.notes ? 'Please enter Monitoring Notes' : ''}
                        onChange={value => {
                          setEditedRow({
                            notes: value,
                            status:
                              editedRow?.status || tariffRow.monitoringStatus.monitoringStatus,
                          });
                          return;
                        }}
                        label=""
                        placeholder="Search"
                      />
                    )}
                  </td>
                  <td style={{ width: '9vw' }}>
                    <TextField
                      textValue={
                        new Date(tariffRow.monitoringStatus.monitoredDate)
                          ?.toLocaleString('sv-SE')
                          .split(' ')[0] || ''
                      }
                      toolTipId={tariffRow.monitoringInventoryId + 'moniDate'}
                      toolTipValue={new Date(
                        tariffRow.monitoringStatus.monitoredDate
                      )?.toLocaleString('sv-SE')}
                      maxSize={12}
                      textStyle="paragraph500"
                    />
                  </td>
                  <td style={{ width: '9vw' }}>
                    <TextField
                      textValue={tariffRow.effectiveStartDate?.split('T')[0] || ''}
                      toolTipId={tariffRow.monitoringInventoryId + 'esd'}
                      maxSize={12}
                      textStyle="paragraph500"
                    />
                  </td>
                  <td style={{ width: '7vw' }}>
                    <span className="d-flex justify-content-center">
                      <TextField
                        textValue={tariffRow.dueInDays.toString()}
                        color={
                          isNaN(Number(appConstants.statusScreen.dueInDays))
                            ? 'accent1'
                            : tariffRow.dueInDays >= Number(appConstants.statusScreen.dueInDays)
                            ? 'successBright'
                            : tariffRow.dueInDays < -Number(appConstants.statusScreen.dueInDays)
                            ? 'errorBright'
                            : 'warningBright'
                        }
                        toolTipValue={'Nepd : ' + tariffRow.nextExpectedPostDate?.split('T')[0]}
                        toolTipId={tariffRow.monitoringInventoryId + 'due'}
                        maxSize={9}
                        textStyle="heading600"
                      />
                    </span>
                  </td>
                  <td style={{ width: '9vw' }}>{tariffRow.customerCount}</td>
                  <td style={{ width: '9vw' }}>
                    <TextField
                      textValue={(
                        (tariffRow.getTariffCount || 0) +
                        (tariffRow.onDemandCount || 0) +
                        (tariffRow.savingsAnalysisCount || 0)
                      ).toString()}
                      toolTipValue={
                        'GT : ' +
                        tariffRow.getTariffCount +
                        ' | OD : ' +
                        tariffRow.onDemandCount +
                        ' | SA : ' +
                        tariffRow.savingsAnalysisCount
                      }
                      toolTipId={tariffRow.monitoringInventoryId + 'apicount'}
                      maxSize={12}
                      textStyle="paragraph500"
                    />
                  </td>
                  <td className="pl-3">
                    {statusEditMode != tariffRow.monitoringInventoryId ? (
                      <IconButton
                        aria-label="edit"
                        icon="Pencil"
                        onClick={() => {
                          setStatusEditMode(tariffRow.monitoringInventoryId);
                          setEditedRow(prev => {
                            return {
                              status: tariffRow.monitoringStatus.monitoringStatus,
                              notes: tariffRow.monitoringStatus.monitoringNotes,
                            };
                          });
                        }}
                      />
                    ) : (
                      <>
                        <IconButton
                          aria-label="save"
                          icon="Checkmark"
                          disabled={!editedRow?.notes}
                          onClick={() => {
                            if (!editedRow) {
                              return;
                            }
                            setStatusEditMode(0);
                            dispatch(startUpdateMonitoringStatusLoading());
                            dispatch(
                              updateMonitoringStatus([
                                {
                                  monitoringInventoryId: tariffRow.monitoringInventoryId,
                                  monitoringNotes: editedRow.notes || '',
                                  monitoringStatus: editedRow.status as MonitoringStatusType,
                                },
                              ])
                            );
                          }}
                        />
                        <IconButton
                          aria-label="cancel"
                          icon="UIClose"
                          className="mt-1"
                          onClick={() => {
                            setStatusEditMode(0);
                            setEditedRow(undefined);
                          }}
                        />
                      </>
                    )}
                  </td>
                  {/* <td style={{ width: '11vw' }} key="modeller">
                    <TextField
                      textValue={tariffRow.modeller?.split('@')[0] || ''}
                      toolTipId={tariffRow.monitoringInventoryId + tariffRow.tariffName + 'MO'}
                      maxSize={12}
                      textStyle="paragraph500"
                    />
                  </td> */}
                  <td style={{ width: '9vw' }} key="history">
                    <Button
                      color="primary"
                      size="small"
                      onClick={() => {
                        setShowMonitoringHistory(true);
                        setMonitoringInventoryId(tariffRow?.monitoringInventoryId);
                        setEntityId(tariffRow?.masterTariffId.toString());
                      }}
                    >
                      View
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      </span>
      <Row className="d-flex justify-content-center w-100 mt-4 pb-0">
        <span className="d-flex justify-content-center w-100 mb-0 pb-0">
          <Text
            color="primary"
            opacity="high"
            tag="p"
            textStyle="paragraph400"
            className="mt-2 mr-2"
          >
            Showing {(page - 1) * pageCount + 1} - {Math.min(page * pageCount, count)} of
            {` ${count}`} entries
          </Text>
          <Pagination
            activePage={page}
            totalPages={totalPageCount}
            onSelectPage={selectedPage => setPage(selectedPage)}
          />
        </span>
      </Row>
      {showMonitoringHistory && (
        <MonitoringHistoryModal
          show={showMonitoringHistory}
          entityType={EntityType.TARIFF}
          entityID={entityID}
          onHide={() => setShowMonitoringHistory(false)}
          monitoringInventoryId={monitoringInventoryId}
        />
      )}
    </Row>
  );
};

export default TariffStatusTable;
