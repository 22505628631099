import { restClient } from '@genability/api';
import { Tariff } from '@genability/api/dist/types';

export class TariffApiV2 extends restClient.RestApiClient {
  public async unpublishAll(masterTariffId: number): Promise<restClient.SingleResponse<void>> {
    return this.delete(`/tariff/${masterTariffId}/all`, { params: {} });
  }
  public async unpublishLatest(masterTariffId: number): Promise<restClient.SingleResponse<Tariff>> {
    return this.delete(`/tariff/${masterTariffId}/latest`, { params: {} });
  }
}
