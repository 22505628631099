import React, { useState, useEffect } from 'react';
import { Col, Row, Fade } from 'react-bootstrap';
import {
  CheckInput,
  Form,
  FormGroup,
  Loader,
  ConfirmModal,
  Icon,
} from '@arcadiapower/gen-react-lib';
import { restClient, types, TariffRateFactory } from '@genability/api';
import styles from './RidersPanel.module.scss';
import { TaskAssignmentAnswerField, TaskTariff } from '../../../task-api/types/task';
import RiderCard, { RiderCardProps } from '../../RiderCard/RiderCard';
import { Tariff } from '@genability/api/dist/types';
import {
  selectTaskAssignmentAnswerByField,
  updateTaskAssignmentAnswer,
} from '../../../state/taskAssignment/taskAssignmentSlice';
import {
  selectTaskAssignmentTariff,
  updateTaskAssignmentTariff,
} from '../../../state/taskAssignmentTariffs/taskAssignmentTariffsSlice';
import useTariffAnswer from '../../../utils/useTariffAnswer';
import { DoStepPanelProps } from '../work-body';
import { selectRiderTariffs } from '../../../state/riderTariffs/riderTariffsSlice';
import PanelNavigationButtons from '../PanelNavigationButtons/PanelNavigationButtons';
import { useAppDispatch } from '../../../state/store';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/rootReducer';
import {
  fetchDQCheck,
  isDQCheckOverride,
  selectDQCheckErrors,
  selectDQCheckOverrides,
} from '../../../state/dqCheck/dqCheckSlice';
import ApiFormError from '../../ApiFormError/ApiFormError';
import { mapToFormErrorObject } from '../../../utils/formUtils';

const getTariffRiderIds = (tariff: types.Tariff | undefined): number[] => {
  // all riderId present on tariff rates
  const tariffRiderIds: number[] = [];
  tariff?.rates?.forEach(rate => {
    if (rate.riderId) {
      tariffRiderIds.push(rate.riderId);
    }
  });
  return tariffRiderIds;
};

const getOtherAvailableRiders = (
  riderTariffs: types.Tariff[] | [],
  tariffRiderIds: number[]
): types.Tariff[] => {
  // all tariffs from riderTariffs which have at least one riderId present on tariff's rates
  return riderTariffs.filter((rider: Tariff) => {
    return tariffRiderIds.indexOf(rider.masterTariffId) == -1;
  });
};

interface RiderCardWithFadeProps extends RiderCardProps {
  fadeInOnMount?: boolean;
}

const RiderCardWithFade: React.FC<RiderCardWithFadeProps> = ({
  onAddRider,
  onDeleteRider,
  ...rest
}: RiderCardWithFadeProps) => {
  const [fade, setFade] = useState(true);
  const deleteRider = async () => {
    const result = await ConfirmModal.show({
      title: 'Remove Rider',
      question: 'Are you sure you want to remove this rider from this tariff?',
    });
    if (result) {
      setFade(false);
    }
  };
  return (
    <Fade
      in={fade}
      unmountOnExit={true}
      onExited={() => {
        onAddRider && onAddRider();
        onDeleteRider && onDeleteRider();
      }}
    >
      <RiderCard
        onAddRider={
          onAddRider
            ? () => {
                setFade(false);
              }
            : undefined
        }
        onDeleteRider={onDeleteRider ? deleteRider : undefined}
        {...rest}
      />
    </Fade>
  );
};

const RidersPanel: React.FC<DoStepPanelProps> = ({
  taskAssignment,
  onPrevious,
  onNext,
}: DoStepPanelProps) => {
  const cleanTariff: TaskTariff | undefined = useSelector(selectTaskAssignmentTariff);
  if (!cleanTariff) return null;
  const { tariffAnswer, addRate, deleteRate, resetAnswer } = useTariffAnswer(cleanTariff);
  const tariffRiderIds = getTariffRiderIds(tariffAnswer);
  const riderTariffs = useSelector((state: RootState) => {
    return selectRiderTariffs(state, {
      customerClass: tariffAnswer?.customerClass,
      serviceType: tariffAnswer?.serviceType,
    });
  });

  const otherAvailableRiders = getOtherAvailableRiders(riderTariffs, tariffRiderIds);
  const ridersLoading = useSelector((state: RootState) => {
    return state.riderTariffs.isLoading;
  });
  const missingRidersAnswer = useSelector(
    selectTaskAssignmentAnswerByField(TaskAssignmentAnswerField.MISSING_RIDER)
  );
  const [isMissingRidersChecked, setMissingRidersChecked] = useState(false);
  const [isMissingRiderAnswerUpdating, setMissingRiderAnswerUpdating] = useState(false);

  const [noChangesChecked, setNoChangesChecked] = useState(false);
  const [nextSpin, setNextSpin] = useState(false);

  const [isDirty, setIsDirty] = useState(false);
  const dispatch = useAppDispatch();
  const [responseErrors, setResponseErrors] = useState<restClient.ResponseError[]>([]);
  const dqCheckErrors = useSelector(selectDQCheckErrors);
  const dqOverrides = useSelector(selectDQCheckOverrides);

  useEffect(() => {
    if (cleanTariff) {
      resetAnswer(cleanTariff);
    }
  }, [cleanTariff]);

  useEffect(() => {
    if (missingRidersAnswer && missingRidersAnswer.answerValue === 'true') {
      setMissingRidersChecked(true);
    } else {
      setMissingRidersChecked(false);
    }
  }, [missingRidersAnswer]);

  useEffect(() => {
    dispatch(fetchDQCheck(taskAssignment.taskAssignmentId));
  }, []);

  const deleteExistingRider = async (tariffRateId: number | null) => {
    if (tariffAnswer?.rates && tariffRateId) {
      deleteRate(tariffRateId);
      setIsDirty(true);
    }
  };

  const addOtherRider = (newRider: Tariff) => {
    const newRate = TariffRateFactory.createRiderRate(undefined, newRider);
    addRate(newRate);
    setIsDirty(true);
  };

  /**
   * Filter dqCheck errors By rateIndex and check if it's a rider.
   */
  const filterErrorsByRider = (dqCheckErrors: restClient.ResponseError[] | undefined) => {
    const filteredErrors =
      dqCheckErrors &&
      dqCheckErrors.filter(error => {
        // Check if error is a Rate
        const splittedProperty = error.propertyName?.split(/\./);
        if (splittedProperty?.length !== 3 || splittedProperty[0] !== 'rates') {
          return false;
        }
        // Check if it has riderId
        const rateIndex = parseInt(splittedProperty[1]);
        if (tariffAnswer.rates && tariffAnswer.rates.length > rateIndex) {
          return !!tariffAnswer.rates[rateIndex].riderId && !isDQCheckOverride(error, dqOverrides);
        }
        return false;
      });
    return filteredErrors;
  };

  const saveAnswerAndNext = async () => {
    setNextSpin(true);
    setResponseErrors([]);
    const { result, errors } = await dispatch(
      updateTaskAssignmentTariff({
        taskAssignmentId: taskAssignment.taskAssignmentId,
        tariff: tariffAnswer,
      })
    ).unwrap();
    if (errors) {
      setResponseErrors(errors);
    } else if (result) {
      const dqCheck = await dispatch(fetchDQCheck(taskAssignment.taskAssignmentId)).unwrap();
      const filteredErrors = dqCheck.errors ? filterErrorsByRider(dqCheck.errors) : [];
      if (filteredErrors?.length === 0) {
        resetAnswer(result);
        setIsDirty(false);
        onNext();
      }
    }
    setNextSpin(false);
  };

  const resetChanges = () => {
    if (cleanTariff) {
      resetAnswer(cleanTariff);
    }
    setResponseErrors([]);
    setIsDirty(false);
  };

  const toggleMissingRiders = () => {
    const answerValue = isMissingRidersChecked ? 'false' : 'true'; // toggle
    const updateTaskAssignmentAnswerProps = {
      taskAssignmentId: Number(taskAssignment.taskAssignmentId),
      taskAssignmentAnswer: {
        answerField: TaskAssignmentAnswerField.MISSING_RIDER,
        answerValue,
      },
    };
    setMissingRiderAnswerUpdating(true);
    setNextSpin(true);
    dispatch(updateTaskAssignmentAnswer(updateTaskAssignmentAnswerProps)).finally(() => {
      setMissingRiderAnswerUpdating(false);
      setNextSpin(false);
    });
  };

  const handleNoChanges = (checked: boolean) => {
    setNoChangesChecked(checked);
  };

  useEffect(() => {
    if (dqCheckErrors) {
      setResponseErrors(filterErrorsByRider(dqCheckErrors) || []);
    }
  }, [dqCheckErrors]);

  return (
    <React.Fragment>
      <h2>Riders</h2>
      <ApiFormError apiErrors={mapToFormErrorObject(responseErrors)} />

      <Row>
        <Col>
          <h4>Existing Attached Riders</h4>
        </Col>
      </Row>
      <div className="list-group">
        {tariffRiderIds.length != 0 ? (
          tariffAnswer?.rates?.map(rate => {
            if (rate.riderId) {
              return (
                <RiderCardWithFade
                  key={rate.riderId}
                  className={'list-group-item d-flex'}
                  rate={rate}
                  onDeleteRider={() => deleteExistingRider(rate.tariffRateId)}
                />
              );
            } else {
              return null;
            }
          })
        ) : (
          <Row className="list-group-item">
            <Col className={styles.noContent}>None attached</Col>
          </Row>
        )}
      </div>
      <Row>
        <Col>
          <h4 className="mt-3">Other Available Riders</h4>
        </Col>
      </Row>
      {otherAvailableRiders.length != 0 ? (
        <div className="list-group">
          {otherAvailableRiders.map((rider: types.Tariff, index) => {
            return (
              <RiderCardWithFade
                key={rider.tariffId}
                className={
                  'list-group-item d-flex ' +
                  (index == otherAvailableRiders.length - 1 ? styles.noBorderBottom : '')
                }
                tariff={rider}
                onAddRider={() => addOtherRider(rider)}
              />
            );
          })}
        </div>
      ) : ridersLoading ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <Row>
          <Col className={styles.noContent}>None available to attach</Col>
        </Row>
      )}

      <Row>
        <Col>
          <Form className={styles.missingRiderForm}>
            <FormGroup controlId="oneOrMoreRidersCheckbox" className="mt-3">
              <CheckInput
                controlId="control-id-toggleMissingRiders"
                label="This Tariff has 1 or more Riders not listed above"
                onChange={toggleMissingRiders}
                checked={isMissingRidersChecked}
              />
              {isMissingRiderAnswerUpdating && <Icon iconName="spinner" spin />}
            </FormGroup>
          </Form>
        </Col>
      </Row>

      <Row>
        <Col>
          <PanelNavigationButtons
            onClickPrevious={onPrevious}
            onClickReset={resetChanges}
            onClickNext={isDirty ? saveAnswerAndNext : onNext}
            nextLabel={isDirty ? 'Save & Next' : 'Next'}
            nextSpin={nextSpin}
            dirty={isDirty}
            noChangesChecked={noChangesChecked}
            onClickNoChanges={handleNoChanges}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default RidersPanel;
