import { restClient } from '@genability/api';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OpsManagementApiClient } from '../../../GenApiClient';
import { MonitoringStatusType } from '../../../ops-api/types/MonitoringStatusTypes';
import { addNotification } from '../../notification/notificationSlice';
import { handleUnexpectedThunkException } from '../../reduxUtils';
import { RootState } from '../../rootReducer';
import { MonitoringHistory } from '../../../ops-api/types/MonitoringHistory';

export interface UpdateMonitoringStatusRequest {
  monitoringInventoryId: number;
  monitoringStatus: MonitoringStatusType;
  monitoringNotes: string;
}
export interface UpdatedMonitoringStatusData {
  errors: restClient.ResponseError[] | undefined;
  monitoringStatus: MonitoringHistory;
  status: string;
}
export interface UpdatedMonitoringStatusResponse {
  results: UpdatedMonitoringStatusData[];
  apiStatus: 'idle' | 'pending' | 'resolved' | 'rejected';
  errors: restClient.ResponseError[] | undefined;
  count: number;
}
const initialState: UpdatedMonitoringStatusResponse = {
  results: [],
  apiStatus: 'idle',
  count: 0,
  errors: undefined,
};

export const updateMonitoringStatus = createAsyncThunk(
  'opsManagement/status/updateStatus',
  async (request: UpdateMonitoringStatusRequest[], { dispatch, rejectWithValue }) => {
    try {
      const client = await OpsManagementApiClient();
      const response: restClient.PagedResponse<UpdatedMonitoringStatusData> =
        await client.opsData.updateStatusData(request);
      if (response.results[0].errors) {
        throw new Error(response.results[0].errors[0].message);
      }
      dispatch(addNotification('Status Updated Successfully !!!!', NotificationLevel.Success));
      return response;
    } catch (err) {
      dispatch(addNotification('Updating Tariff Status Failed', NotificationLevel.Error));
      return rejectWithValue(handleUnexpectedThunkException(err, 'UpdateTariffStatus', dispatch));
    }
  }
);

export const UpdateMonitoringStatusSlice = createSlice({
  name: 'TariffStatusData',
  initialState,
  reducers: {
    startUpdateMonitoringStatusLoading: state => {
      state.apiStatus = 'pending';
    },
  },
  extraReducers: builder => {
    builder.addCase(updateMonitoringStatus.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(
      updateMonitoringStatus.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<UpdatedMonitoringStatusData>>) => {
        const { count, results, errors } = action.payload;
        if (results[0].errors) {
          state.apiStatus = 'rejected';
          state.errors = errors;
        } else {
          state.apiStatus = 'resolved';
          state.count = count;
          state.results = results;
          state.errors = undefined;
        }
      }
    );
    builder.addCase(updateMonitoringStatus.rejected, state => {
      state.apiStatus = 'rejected';
      state.apiStatus = 'rejected';
    });
  },
});

export default UpdateMonitoringStatusSlice.reducer;

export const { startUpdateMonitoringStatusLoading } = UpdateMonitoringStatusSlice.actions;

export const selectUpdatedStatusData = (state: RootState): UpdatedMonitoringStatusResponse => {
  return state.updatedMonitoringStatusData;
};
