/* eslint-disable @typescript-eslint/no-unused-vars */
import { Formula, types } from '@genability/api';
import { useState } from 'react';
import { cloneDeep, uniq } from 'lodash';
import { TariffRateBand } from '@genability/api/dist/types';

import {
  FORMULA_CONSUMPTION_UPPER_LIMIT,
  FORMULA_DEMAND_UPPER_LIMIT,
  FORMULA_PROPERTY_UPPER_LIMIT,
  tierLimitChargeTypeMap,
} from './rateFormConstants';

interface UseTariffRateAnswerReturned {
  tariffRateAnswer: types.TariffRate;
  resetAnswer: (originalTariffRate: types.TariffRate) => void;
  regroupBands: (rateBands?: types.TariffRateBand[]) => types.TariffRateBand[] | undefined;
  setRateField: (
    fieldName: string,
    fieldValue: string | types.ChargeClass[] | types.ProrationRule[] | types.Territory | undefined
  ) => void;
  setQuantityKey: (propertyKey: types.GenPropertyKey | undefined) => void;
  setSeason: (selectedSeason: types.Season) => void;
  resetSeason: () => void;
  setTou: (selectedTou: types.TimeOfUse) => void;
  resetTou: () => void;
  removeSeasonAndTou: () => void;
  addRateBand: (
    rateBand: Partial<types.TariffRateBand>,
    rateSequenceNumber: number | undefined,
    rateBands?: types.TariffRateBand[]
  ) => void;
  deleteRateBand: (rateSequenceNumber: number) => void;
  setRateBandIsCredit: (rateSequenceNumber: number, fieldValue: boolean | undefined) => void;
  setRateBandNumericalField: (
    rateSequenceNumber: number,
    fieldName:
      | 'rateAmount'
      | 'calculationFactor'
      | 'consumptionUpperLimit'
      | 'demandUpperLimit'
      | 'propertyUpperLimit',
    fieldValue: number | undefined
  ) => void;
  hasTiers: (rateBands: types.TariffRateBand[]) => boolean;
  removeTiers: () => Partial<types.TariffRate>;
  setVariableLimitKey: (variableLimitKey: types.GenPropertyKey | undefined) => void;
  addTiers: () => Partial<types.TariffRate>;
  hasApplicability: (rateBands: types.TariffRateBand[]) => boolean;
  addApplicability: (propertyKey: types.GenPropertyKey) => Partial<types.TariffRate>;
  removeApplicability: (preserveTiers: boolean) => Partial<types.TariffRate>;
  changeApplicabilityValue: (
    fromApplicabilityValue: string | undefined,
    toApplicabilityValue: string
  ) => TariffRateBand[];
}

const useTariffAnswer = (initialValue: types.TariffRate): UseTariffRateAnswerReturned => {
  const init = (
    toInitialize: types.TariffRate
  ): {
    initTariffRate: types.TariffRate;
  } => {
    const initTariffRate = cloneDeep(toInitialize);
    if (initTariffRate.rateBands === undefined || initTariffRate.rateBands.length == 0) {
      initTariffRate.rateBands = [];
    } else if (initTariffRate.rateBands.length == 1) {
      initTariffRate.rateBands[0].rateSequenceNumber = 1;
    } else {
      initTariffRate.rateBands.sort(compareRateSequenceNumber);
      initTariffRate.rateBands.forEach((rateBand: types.TariffRateBand, position) => {
        rateBand.rateSequenceNumber = position + 1;
      });
    }
    return { initTariffRate };
  };

  const compareApplicabilityValue = (applicabilitySortOrder: { [key: string]: number }) => {
    return (a: types.TariffRateBand, b: types.TariffRateBand): number => {
      if (a.applicabilityValue === undefined || a.applicabilityValue === null) {
        if (b.applicabilityValue === undefined || b.applicabilityValue === null) {
          return 0;
        }
        return -1;
      } else if (b.applicabilityValue === undefined || b.applicabilityValue === null) {
        return 1;
      }
      if (
        applicabilitySortOrder[a.applicabilityValue] < applicabilitySortOrder[b.applicabilityValue]
      ) {
        return -1;
      }
      if (
        applicabilitySortOrder[a.applicabilityValue] > applicabilitySortOrder[b.applicabilityValue]
      ) {
        return 1;
      }
      return 0;
    };
  };

  const applicabilitySortOrder = (tariffRate: types.TariffRate) => {
    if (!tariffRate?.rateBands) return {};
    const uniqueValues = uniq(tariffRate.rateBands.map(band => band.applicabilityValue));
    return uniqueValues.reduce(
      (prev: { [key: string]: number }, value: string | undefined, idx: number) => {
        if (value) prev[value] = idx;
        return prev;
      },
      {}
    );
  };

  const compareRateSequenceNumber = (a: types.TariffRateBand, b: types.TariffRateBand): number => {
    if (a.rateSequenceNumber === undefined || a.rateSequenceNumber === null) {
      if (b.rateSequenceNumber === undefined || b.rateSequenceNumber === null) {
        return 0;
      }
      return -1;
    } else if (b.rateSequenceNumber === undefined || b.rateSequenceNumber === null) {
      return 1;
    }
    if (a.rateSequenceNumber < b.rateSequenceNumber) {
      return -1;
    }
    if (a.rateSequenceNumber > b.rateSequenceNumber) {
      return 1;
    }
    return 0;
  };

  const compareBands = (tariffRate: types.TariffRate) => {
    const applicabilityCompareFunction = compareApplicabilityValue(
      applicabilitySortOrder(tariffRate)
    );
    return (a: types.TariffRateBand, b: types.TariffRateBand): number => {
      const compare = applicabilityCompareFunction(a, b);
      if (compare === 0) {
        return compareRateSequenceNumber(a, b);
      }
      return compare;
    };
  };

  const getLimitProperties = (
    variableLimitKey: types.GenPropertyKey | undefined,
    chargeType: types.ChargeType | undefined
  ) => {
    let limitProperties: Set<string> = Formula.getProperties(variableLimitKey?.formulaDetail || '');

    if (limitProperties.size === 0) {
      limitProperties = new Set<string>().add(tierLimitChargeTypeMap.get(chargeType) || '');
    }

    return limitProperties;
  };

  const { initTariffRate } = init(initialValue);
  const [tariffRate, setTariffRate] = useState(initTariffRate);

  /**
   * Use to revert pending changes or when server has been updated.
   * @param cleanTariffRate to reset back to
   */
  const resetAnswer = (cleanTariffRate: types.TariffRate) => {
    const { initTariffRate } = init(cleanTariffRate);
    setTariffRate(initTariffRate);
  };

  /**
   * Use to re-group rate bands by applicability value and rate sequence number,
   * and then re-assign rate sequence number. Use when dealing with rate bands
   * whose existing rate sequence numbers cause broken-up applicability value groups.
   **/
  const regroupBands = (rateBands = tariffRate.rateBands): types.TariffRateBand[] | undefined => {
    if (!rateBands) return rateBands;
    rateBands.sort(compareBands(tariffRate));
    rateBands.forEach((rateBand: types.TariffRateBand, position) => {
      rateBand.rateSequenceNumber = position + 1;
    });
    setTariffRate({ ...tariffRate, rateBands: rateBands });
    return rateBands;
  };

  const setRateField = (
    fieldName: string,
    fieldValue: string | types.ChargeClass[] | types.ProrationRule[] | types.Territory | undefined
  ) => {
    if (fieldName == 'variableFactorKey' && fieldValue == undefined) {
      tariffRate.rateBands?.forEach((rateBand: types.TariffRateBand) => {
        rateBand.calculationFactor = undefined;
      });
    }
    setTariffRate({
      ...tariffRate,
      [fieldName]: fieldValue,
    });
  };

  const setQuantityKey = (propertyKey: types.GenPropertyKey | undefined) => {
    setTariffRate({
      ...tariffRate,
      quantityKey: propertyKey?.keyName || undefined,
    });
  };

  const setSeason = (selectedSeason: types.Season) => {
    setTariffRate({
      ...tariffRate,
      season: selectedSeason,
    });
  };

  const resetSeason = () => {
    setTariffRate({
      ...tariffRate,
      season: undefined,
    });
  };

  const setTou = (selectedTou: types.TimeOfUse) => {
    setTariffRate({
      ...tariffRate,
      timeOfUse: selectedTou,
    });
  };

  const resetTou = () => {
    setTariffRate({
      ...tariffRate,
      timeOfUse: undefined,
    });
  };
  const removeSeasonAndTou = () => {
    setTariffRate({
      ...tariffRate,
      season: undefined,
      timeOfUse: undefined,
    });
  };

  const addRateBand = (
    rateBand: Partial<types.TariffRateBand>,
    rateSequenceNumber: number | undefined,
    rateBands = tariffRate.rateBands
  ) => {
    const newRateBands = cloneDeep(rateBands) as types.TariffRateBand[];
    const rateUnit =
      (rateBand && rateBand.rateUnit) ||
      (tariffRate.rateBands && tariffRate.rateBands[0] && tariffRate.rateBands[0].rateUnit);
    if (rateSequenceNumber === undefined) {
      newRateBands.push(
        cloneDeep({
          ...rateBand,
          rateUnit: rateUnit,
          rateSequenceNumber: newRateBands.length + 1,
        })
      );
    } else {
      const index = newRateBands.findIndex(item => item.rateSequenceNumber === rateSequenceNumber);
      if (index === -1 || !newRateBands.length || newRateBands.length < rateSequenceNumber) {
        newRateBands.push(
          cloneDeep({
            ...rateBand,
            rateUnit: rateUnit,
            rateSequenceNumber: newRateBands.length + 1,
          })
        );
      } else {
        const ratesToMove = newRateBands.splice(index, newRateBands.length);
        newRateBands.splice(
          index,
          0,
          cloneDeep({
            ...rateBand,
            rateUnit: rateUnit,
            rateSequenceNumber: rateSequenceNumber,
          }),
          ...ratesToMove
        );
      }
    }
    newRateBands.forEach((item: types.TariffRateBand, position) => {
      item.rateSequenceNumber = position + 1;
    });
    setTariffRate({
      ...tariffRate,
      rateBands: newRateBands,
    });
  };

  const deleteRateBand = (rateSequenceNumber: number) => {
    if (tariffRate.rateBands) {
      const index = tariffRate.rateBands.findIndex(
        item => item.rateSequenceNumber === rateSequenceNumber
      );
      if (index !== -1) {
        const newRateBands = cloneDeep(tariffRate.rateBands);
        newRateBands.splice(index, 1);
        newRateBands.forEach((rateBand: types.TariffRateBand, position) => {
          rateBand.rateSequenceNumber = position + 1;
        });
        setTariffRate({
          ...tariffRate,
          rateBands: newRateBands,
        });
      }
    }
  };

  const setRateBandIsCredit = (rateSequenceNumber: number, fieldValue: boolean | undefined) => {
    if (tariffRate.rateBands) {
      const index = tariffRate.rateBands.findIndex(
        item => item.rateSequenceNumber === rateSequenceNumber
      );
      if (index !== -1) {
        const newRateBands = cloneDeep(tariffRate.rateBands);
        newRateBands[index].isCredit = fieldValue;
        setTariffRate({
          ...tariffRate,
          rateBands: newRateBands,
        });
      }
    }
  };

  const setRateBandNumericalField = (
    rateSequenceNumber: number,
    fieldName:
      | 'rateAmount'
      | 'calculationFactor'
      | 'consumptionUpperLimit'
      | 'demandUpperLimit'
      | 'propertyUpperLimit',
    fieldValue: number | undefined
  ) => {
    if (tariffRate.rateBands) {
      const index = tariffRate.rateBands.findIndex(
        item => item.rateSequenceNumber === rateSequenceNumber
      );
      if (index !== -1) {
        const newRateBands = cloneDeep(tariffRate.rateBands);
        newRateBands[index] = Object.assign(newRateBands[index], {
          [fieldName]: fieldValue,
        });
        setTariffRate({
          ...tariffRate,
          rateBands: newRateBands,
        });
      }
    }
  };

  const hasTiers = (rateBands: types.TariffRateBand[]) => {
    return rateBands.some((rateBand: types.TariffRateBand) => {
      return (
        !!rateBand.hasConsumptionLimit ||
        !!rateBand.consumptionUpperLimit ||
        !!rateBand.hasDemandLimit ||
        !!rateBand.demandUpperLimit ||
        !!rateBand.hasPropertyLimit ||
        !!rateBand.propertyUpperLimit
      );
    });
  };

  const removeTiers = () => {
    // If there are rate bands,
    const tariffRateUpdate: Partial<types.TariffRate> = {
      variableLimitKey: undefined,
    };
    if (tariffRate.rateBands && tariffRate.rateBands.length > 0) {
      // Make a copy of the first rate band, but remove tier fields
      const {
        hasConsumptionLimit,
        consumptionUpperLimit,
        hasDemandLimit,
        demandUpperLimit,
        hasPropertyLimit,
        propertyUpperLimit,
        ...firstRateBand
      } = tariffRate.rateBands[0];
      const newRateBands = [{ ...firstRateBand, rateSequenceNumber: 1 }];
      tariffRateUpdate.rateBands = newRateBands;
    }

    setTariffRate({
      ...tariffRate,
      ...tariffRateUpdate,
    });

    return tariffRateUpdate;
  };

  const addTiers = () => {
    const tariffRateUpdate: Partial<types.TariffRate> = {};
    if (tariffRate.rateBands) {
      const newRateBands = cloneDeep(tariffRate.rateBands);
      const limitProperties = getLimitProperties(undefined, tariffRate.chargeType);
      if (newRateBands && limitProperties && limitProperties.size > 0) {
        newRateBands.forEach(setTierLimits(limitProperties));
      }
      tariffRateUpdate.rateBands = newRateBands;
    }
    setTariffRate({
      ...tariffRate,
      ...tariffRateUpdate,
    });
    return tariffRateUpdate;
  };

  const setTierLimits = (limitProperties: Set<string>) => {
    return (rateBand: types.TariffRateBand) => {
      rateBand.hasConsumptionLimit = limitProperties.has(FORMULA_CONSUMPTION_UPPER_LIMIT);
      rateBand.hasDemandLimit = limitProperties.has(FORMULA_DEMAND_UPPER_LIMIT);
      rateBand.hasPropertyLimit = limitProperties.has(FORMULA_PROPERTY_UPPER_LIMIT);
      rateBand.consumptionUpperLimit = rateBand.hasConsumptionLimit
        ? rateBand.consumptionUpperLimit
        : undefined;
      rateBand.demandUpperLimit = rateBand.hasDemandLimit ? rateBand.demandUpperLimit : undefined;
      rateBand.propertyUpperLimit = rateBand.hasPropertyLimit
        ? rateBand.propertyUpperLimit
        : undefined;
      return rateBand;
    };
  };

  const setVariableLimitKey = (variableLimitKey: types.GenPropertyKey | undefined) => {
    // Set tier limit fields based on either variableLimitKey or chargeType
    const formulaProperties = getLimitProperties(variableLimitKey, tariffRate.chargeType);

    const newRateBands = cloneDeep(tariffRate.rateBands);
    if (newRateBands && formulaProperties && formulaProperties.size > 0) {
      newRateBands.forEach(setTierLimits(formulaProperties));
    }

    setTariffRate({
      ...tariffRate,
      variableLimitKey: variableLimitKey?.keyName,
      rateBands: newRateBands,
    });
  };

  const hasApplicability = (rateBands: types.TariffRateBand[]) => {
    return rateBands.some((rateBand: types.TariffRateBand) => {
      return rateBand.applicabilityValue !== undefined;
    });
  };

  const addApplicability = (propertyKey: types.GenPropertyKey) => {
    // If there are applicability values, keep them — they'll be caught in validation.
    // If a rate band has no applicability value, insert one as propertyKey.keyName + rateSequenceNumber.
    const newRateBands = tariffRate.rateBands?.map(rateBand => {
      if (!rateBand.applicabilityValue) {
        rateBand.applicabilityValue = `${propertyKey.keyName}-${rateBand.rateSequenceNumber}`;
      }
      return rateBand;
    });
    const tariffRateUpdate = {
      applicabilityKey: propertyKey?.keyName,
      rateBands: newRateBands ? [...newRateBands] : tariffRate.rateBands,
    };
    setTariffRate({
      ...tariffRate,
      ...tariffRateUpdate,
    });
    return tariffRateUpdate;
  };

  const removeApplicability = (preserveTiers = false) => {
    const tariffRateUpdate: Partial<types.TariffRate> = {
      applicabilityKey: undefined,
    };
    if (tariffRate.rateBands && tariffRate.rateBands.length > 0) {
      let newRateBands = [];

      if (!preserveTiers) {
        const { applicabilityValue, ...firstRateBand } = tariffRate.rateBands[0];
        newRateBands = [{ ...firstRateBand, rateSequenceNumber: 1 }];
      } else {
        newRateBands = cloneDeep(tariffRate.rateBands);
        newRateBands.forEach(rateBand => {
          rateBand.applicabilityValue = undefined;
        });
      }

      tariffRateUpdate.rateBands = newRateBands;
    }

    setTariffRate({
      ...tariffRate,
      ...tariffRateUpdate,
    });

    return tariffRateUpdate;
  };

  const changeApplicabilityValue = (
    fromApplicabilityValue: string | undefined,
    toApplicabilityValue: string
  ) => {
    const newRateBands =
      tariffRate.rateBands?.map(rateBand => {
        if (rateBand.applicabilityValue === fromApplicabilityValue) {
          rateBand.applicabilityValue = toApplicabilityValue;
        }
        return rateBand;
      }) || [];
    setTariffRate({
      ...tariffRate,
      rateBands: newRateBands,
    });
    return newRateBands;
  };

  return {
    tariffRateAnswer: tariffRate,
    resetAnswer,
    regroupBands,
    setRateField,
    setQuantityKey,
    setSeason,
    resetSeason,
    setTou,
    resetTou,
    removeSeasonAndTou,
    addRateBand,
    deleteRateBand,
    setRateBandIsCredit,
    setRateBandNumericalField,
    hasTiers,
    removeTiers,
    setVariableLimitKey,
    addTiers,
    hasApplicability,
    addApplicability,
    removeApplicability,
    changeApplicabilityValue,
  };
};

export default useTariffAnswer;
