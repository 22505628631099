import React, { useCallback, useEffect, useState, useRef } from 'react';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Overlay from 'react-bootstrap/Overlay';
import { TextInput } from '@arcadiapower/gen-react-lib';
import {
  fetchLoadServingEntity,
  LoadServingEntity,
  selectLoadServingEntities,
} from '../../state/loadServingEntity/loadServingEntitySlice';
import { ListGroup, ListGroupItem, Popover } from 'react-bootstrap';

export interface LseInputProps {
  label: string;
  placeholder?: string;
  id: string;
  name?: string;
  feedback?: string;
  required?: boolean;
  style?: Partial<CSSStyleDeclaration>;
  popoverStyle?: React.CSSProperties;
  selected?: LoadServingEntity;
  setSelected?: (lse: LoadServingEntity | undefined) => void;
}

const LseInput: React.FC<LseInputProps> = props => {
  const {
    label,
    placeholder,
    id,
    name,
    feedback,
    required,
    popoverStyle,
    style,
    selected,
    setSelected,
  } = props;
  const [selectedId, setSelectedId] = useState<number>();
  const [searchTerm, setSearchTerm] = useState('');
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);

  const [show, setShow] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatch();
  const { search, lses, loading, errorMessage } = useSelector(selectLoadServingEntities);

  useEffect(() => {
    if (selected) {
      setSelectedId(selected.lseId);
      setSearchTerm(selected.name);
    }
  }, [selected]);

  const findLseById = (lseId: number): LoadServingEntity | undefined => {
    return lses?.find(item => item.lseId === lseId);
  };

  const handleMouseOver = (index: number) => {
    setHoveredItem(index);
  };

  const handleMouseOut = () => {
    setHoveredItem(null);
  };

  const debounceSearch = useCallback(
    debounce((searchTerm: string) => {
      if (searchTerm) {
        // if at least one letter show popover
        dispatch(fetchLoadServingEntity(searchTerm));
        setShow(true);
      }
    }, 500),
    []
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    debounceSearch(value);
    setSearchTerm(value);
    if (!value && setSelected) setSelected(undefined);
  };

  const handleSelection = (lseId: number) => {
    const lse = findLseById(lseId);
    setSearchTerm(lse?.name || '');
    setSelectedId(lse?.lseId);
    if (setSelected) {
      setSelected(lse);
    }
    setShow(false);
  };

  const handleKeyForInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const isEsc = event.key === 'Escape';
    const isDown = event.key === 'ArrowDown';

    if (isDown && inputRef.current === event.target) {
      setShow(true);
      setTimeout(() => {
        const items = containerRef?.current?.querySelectorAll(`input`);
        if (items?.length) {
          const item = [...items].find(item => item.value === `${selectedId}`);
          (item || items[0]).focus();
        }
      }, 0);
    } else if (isEsc) {
      setShow(false);
    }
  };

  const handleKeyForOption = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const isEnter = event.key === 'Enter';
    const isSpace = event.key === ' ';
    const isEsc = event.key === 'Escape';
    if (isEnter || isSpace) {
      handleSelection(parseInt((event.target as HTMLInputElement).value));
    } else if (isEsc) {
      setShow(false);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.autocomplete = 'off';
    }
  }, [inputRef]);

  const popoverContent = (
    <div>
      {loading === 'pending' && <ListGroupItem> Loading Options....</ListGroupItem>}
      {loading !== 'pending' && (
        <fieldset>
          {errorMessage && (
            <ListGroupItem>
              <b>Error:</b> {errorMessage}
            </ListGroupItem>
          )}
          {!errorMessage && lses?.length == 0 && (
            <ListGroupItem>
              No results for term <b>{search}</b>
            </ListGroupItem>
          )}
          {lses?.length > 0 && (
            <>
              <ListGroup style={{ maxHeight: '300px', overflowY: 'auto' }}>
                {lses?.map((item, index) => (
                  <ListGroupItem
                    key={index}
                    onMouseOver={() => handleMouseOver(index)}
                    onMouseOut={() => handleMouseOut()}
                    onMouseUp={() => handleSelection(item.lseId)}
                    style={{
                      backgroundColor: hoveredItem === index ? '#e5f2fd' : '',
                      minHeight: '60px',
                    }}
                  >
                    <label key={item.lseId}>
                      <input
                        name={`_${id}`}
                        value={item.lseId}
                        type="radio"
                        onKeyUp={handleKeyForOption}
                        className="sr-only"
                      />
                      {item.name}
                    </label>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </>
          )}
        </fieldset>
      )}
    </div>
  );

  return (
    <>
      <div onKeyUp={handleKeyForInput}>
        <TextInput
          label={label}
          placeholder={placeholder}
          id={id}
          name={name}
          feedback={feedback}
          required={required}
          style={style}
          ref={inputRef}
          value={searchTerm}
          type="text"
          onChange={handleChange}
        />
        <Overlay
          show={show}
          target={inputRef.current}
          placement="bottom-start"
          rootClose
          onHide={() => setShow(false)}
        >
          <Popover
            style={{
              minWidth: inputRef.current?.clientWidth,
              ...popoverStyle,
            }}
            id={`${id}_lse`}
          >
            {popoverContent}
          </Popover>
        </Overlay>
      </div>
    </>
  );
};

export default LseInput;
