import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { restClient, types } from '@genability/api';
import { AppThunk } from '../store';
import { GenApiClient } from '../../GenApiClient';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { addNotification } from '../notification/notificationSlice';
import { RootState } from '../rootReducer';

export interface TimeOfUseState {
  timeOfUseGroupsByLse: Record<number, Array<types.TimeOfUseGroup>>;
  timeOfUseGroupByGroupId: Record<number, types.TimeOfUseGroup>;
  isLoading: boolean;
  error: string | null;
}

interface TimeOfUseGroupSuccess {
  lseId: number;
  timeOfUseGroups: Array<types.TimeOfUseGroup>;
}

export const initialState: TimeOfUseState = {
  timeOfUseGroupsByLse: {},
  timeOfUseGroupByGroupId: {},
  isLoading: false,
  error: null,
};

function startLoading(state: TimeOfUseState) {
  state.isLoading = true;
}

function loadingFailed(state: TimeOfUseState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

export const timeOfUses = createSlice({
  name: 'timeOfUses',
  initialState,
  reducers: {
    getTimeOfUseGroupsStart: startLoading,
    getTimeOfUseGroupsSuccess(state, { payload }: PayloadAction<TimeOfUseGroupSuccess>) {
      const { lseId, timeOfUseGroups } = payload;
      state.timeOfUseGroupsByLse[lseId] = timeOfUseGroups;
      timeOfUseGroups.forEach((touGroup: types.TimeOfUseGroup) => {
        state.timeOfUseGroupByGroupId[touGroup.touGroupId] = touGroup;
      });
      state.isLoading = false;
      state.error = null;
    },
    getTimeOfUseGroupsFailure: loadingFailed,
  },
});

export const { getTimeOfUseGroupsStart, getTimeOfUseGroupsSuccess, getTimeOfUseGroupsFailure } =
  timeOfUses.actions;

export default timeOfUses.reducer;

export const fetchTimeOfUseGroups =
  (lseId: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(getTimeOfUseGroupsStart());
      const client = await GenApiClient();
      const results: types.TimeOfUseGroup[] = [];

      let count;
      let pageStart = 0;
      const pageCount = 100;
      let loop = true;

      while (loop) {
        const request = new restClient.DefaultPagedRequest({
          pageStart,
          pageCount,
        });
        const response: restClient.PagedResponse<types.TimeOfUseGroup> =
          await client.timeofuses.getTimeOfUseGroups(lseId, request);
        if (!count) {
          count = response.count;
        }
        results.push(...response.results);

        pageStart += pageCount;

        if (count < pageStart) {
          loop = false;
        }
      }

      dispatch(getTimeOfUseGroupsSuccess({ lseId, timeOfUseGroups: results }));
    } catch (err: any) {
      if (err) {
        const errorMessage = err.message ? err.message : err.toString();
        dispatch(getTimeOfUseGroupsFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };

export const selectTimeOfUseGroupsByLseId = (
  lseId: number
): ((state: RootState) => Array<types.TimeOfUseGroup>) => {
  return (state: RootState): Array<types.TimeOfUseGroup> =>
    state.timeOfUses.timeOfUseGroupsByLse[lseId] || [];
};

export const selectTimeOfUseGroupByGroupId = (
  groupId: number
): ((state: RootState) => types.TimeOfUseGroup | null) => {
  return (state: RootState): types.TimeOfUseGroup | null =>
    state.timeOfUses.timeOfUseGroupByGroupId[groupId] || null;
};
