import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps, Redirect, withRouter } from 'react-router';
import TaskTitleBar from '../../components/TaskTitleBar/TaskTitleBar';
import { Task } from '../../task-api/types/task';
import { Loader } from '@arcadiapower/gen-react-lib';
import appConstants from '../../app-constants';
import styles from './DraftDetail.module.scss';
import { useDispatch } from 'react-redux';
import { fetchTask, selectTask, selectTaskApiStatus } from '../../state/task/taskSlice';
import { useSelector } from 'react-redux';

interface MatchParams {
  taskId: string;
}

function DraftDetail(props: RouteComponentProps<MatchParams>): React.ReactElement {
  const apiStatus: string = useSelector(selectTaskApiStatus);
  const task: Task | undefined = useSelector(selectTask);
  const dispatch = useDispatch();

  useEffect(() => {
    const taskId = Number(props.match.params.taskId);
    dispatch(fetchTask({ taskId }));
  }, [dispatch, props.match.params.taskId]);

  function onCommentClick() {
    console.log('onCommentClick');
  }
  function onHistoryClick() {
    console.log('onHistoryClick');
  }
  function onMenuClick() {
    console.log('onMenuClick');
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>Draft Task</title>
      </Helmet>
      <div className={styles.wrapper}>
        <TaskTitleBar
          back={appConstants.routes.draft}
          taskId={props.match.params.taskId ? Number(props.match.params.taskId) : undefined}
          title={task?.taskTitle || ''}
          taskTypeId={task?.taskType?.taskTypeId}
          taskTypeTitle={task?.taskType.taskTypeName || ''}
          lseId={task?.lseId}
          lseName={task?.lseName}
          onCommentClick={onCommentClick}
          onHistoryClick={onHistoryClick}
          onMenuClick={onMenuClick}
        />
        <div className={styles.body}>Draft Detail Page</div>
      </div>
      {apiStatus === 'idle' || apiStatus === 'pending' ? (
        <div className="text-center" style={{ marginTop: '200px' }}>
          <Loader />
        </div>
      ) : (
        [apiStatus === 'notfound' ? <Redirect to={appConstants.routes.draft} /> : <div>Loaded</div>]
      )}
    </React.Fragment>
  );
}

export default withRouter(DraftDetail);
