import { restClient } from '@genability/api';

export interface UploadRequest {
  filename: string;
  contentType: string;
}

export interface MultipartResponse {
  UploadId: string;
}

export interface MultipartUrlRequest {
  uploadId: string;
  filename: string;
  partNumber?: number;
}

export interface CompleteMultipartRequest {
  uploadId: string;
  filename: string;
  parts: Array<{ PartNumber: number; ETag: string }>;
}

export interface MultipartCompletedResponse {
  Key: string;
  Location: string;
}

export class UploadApi extends restClient.RestApiClient {
  // Returns a pre-signed URL for uploading to an S3 bucket
  public async getUploadUrl(request: UploadRequest): Promise<restClient.SingleResponse<string>> {
    const { filename, contentType } = request;
    return this.getSingle(
      `/url?filename=${filename}&contentType=${contentType}`,
      undefined,
      response => {
        // Reformat AxiosResponse inside results
        response.data = { results: [response.data] };
      }
    );
  }

  // Starts a multipart upload and returns an UploadId for the upload chunks
  public async createMultipartUpload(
    request: UploadRequest
  ): Promise<restClient.SingleResponse<MultipartResponse>> {
    return this.post(`/multipart/create`, request, undefined, response => {
      response.data = { results: [{ ...response.data }] };
    });
  }

  // Returns a pre-signed URL for the specified file chunk
  public async getMultipartUrl(
    request: MultipartUrlRequest
  ): Promise<restClient.SingleResponse<string>> {
    const { uploadId, filename, partNumber } = request;
    return this.getSingle(
      `/multipart/${uploadId}/url?filename=${filename}&partNumber=${partNumber}`,
      undefined,
      response => {
        response.data = { results: [response.data] };
      }
    );
  }

  // Finishes a multipart upload and returns info about the uploaded file
  public async completeMultipart(
    request: CompleteMultipartRequest
  ): Promise<restClient.SingleResponse<MultipartCompletedResponse>> {
    const { uploadId, ...postCompleteBody } = request;
    return this.post(`/multipart/${uploadId}/complete`, postCompleteBody, undefined, response => {
      response.data = { results: [response.data] };
    });
  }
}
