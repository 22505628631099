import React from 'react';
import { DoStepPanelProps } from '../work-body';
import PanelNavigationButtons from '../PanelNavigationButtons/PanelNavigationButtons';

const CostsPanel: React.FC<DoStepPanelProps> = ({ onPrevious, onNext }: DoStepPanelProps) => {
  // TODO: this page shows nothing and so doesn't need a No Changes checkbox

  const onReset = () => {
    //Todo later
  };

  return (
    <React.Fragment>
      <h2>Costs</h2>
      <p>Review the tariff documentation and make edits to any values that have changed.</p>
      <PanelNavigationButtons
        onClickReset={onReset}
        onClickPrevious={onPrevious}
        onClickNext={onNext}
        nextLabel="Next"
        noChangesChecked={true}
      />
    </React.Fragment>
  );
};

export default CostsPanel;
