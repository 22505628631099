import React from 'react';
import { useSelector } from 'react-redux';
import { SelectInput, JsonViewer } from '@arcadiapower/gen-react-lib';
import { TaskAssignment } from '../../task-api/types/task';
import appConstants from '../../app-constants';
import styles from './ViewJsonDrawer.module.scss';
import { selectTaskAssignmentTariff } from '../../state/taskAssignmentTariffs/taskAssignmentTariffsSlice';
import { RootState } from '../../state/rootReducer';
import { types } from '@genability/api';

interface ViewJsonDrawerProps {
  visibleJson: string;
  setVisibleJson: (_: string) => void;
  taskAssignment?: TaskAssignment;
  tariffSource?: types.Tariff;
}

const ViewJsonDrawer = ({
  visibleJson,
  setVisibleJson,
  taskAssignment,
  tariffSource,
}: ViewJsonDrawerProps): React.ReactElement => {
  const handleSelectMenuItem = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVisibleJson(e.target.value);
  };
  const document = useSelector((state: RootState) => state.document);
  const tariff = useSelector(selectTaskAssignmentTariff);
  const riders = useSelector((state: RootState) => state.riderTariffs.tariffs);
  const territories = useSelector((state: RootState) => state.territories.territoriesByLse);
  const seasonGroups = useSelector((state: RootState) => state.loadServingEntity);
  const touGroups = useSelector((state: RootState) => state.timeOfUses);
  let visibleData: unknown = {};
  if (visibleJson === appConstants.viewJsonDropdown.taskAssignment && taskAssignment) {
    visibleData = taskAssignment;
  } else if (visibleJson === appConstants.viewJsonDropdown.document && document) {
    visibleData = document;
  } else if (visibleJson === appConstants.viewJsonDropdown.tariffAnswer && tariff) {
    visibleData = tariff;
  } else if (visibleJson === appConstants.viewJsonDropdown.tariffSource && tariffSource) {
    visibleData = tariffSource;
  } else if (visibleJson === appConstants.viewJsonDropdown.riders && riders) {
    visibleData = riders;
  } else if (visibleJson === appConstants.viewJsonDropdown.territories && territories) {
    visibleData = territories;
  } else if (visibleJson === appConstants.viewJsonDropdown.seasons && seasonGroups) {
    visibleData = seasonGroups;
  } else if (visibleJson === appConstants.viewJsonDropdown.timeOfUse && touGroups) {
    visibleData = touGroups;
  }

  return (
    <>
      <SelectInput label="View JSON" onChange={handleSelectMenuItem}>
        <option
          key={appConstants.viewJsonDropdown.taskAssignment}
          value={appConstants.viewJsonDropdown.taskAssignment}
        >
          {appConstants.viewJsonDropdown.taskAssignment}
        </option>
        <option
          key={appConstants.viewJsonDropdown.document}
          value={appConstants.viewJsonDropdown.document}
        >
          {appConstants.viewJsonDropdown.document}
        </option>
        <option
          key={appConstants.viewJsonDropdown.tariffAnswer}
          value={appConstants.viewJsonDropdown.tariffAnswer}
        >
          {appConstants.viewJsonDropdown.tariffAnswer}
        </option>
        <option
          key={appConstants.viewJsonDropdown.tariffSource}
          value={appConstants.viewJsonDropdown.tariffSource}
        >
          {appConstants.viewJsonDropdown.tariffSource}
        </option>
        <option
          key={appConstants.viewJsonDropdown.riders}
          value={appConstants.viewJsonDropdown.riders}
        >
          {appConstants.viewJsonDropdown.riders}
        </option>
        <option
          key={appConstants.viewJsonDropdown.territories}
          value={appConstants.viewJsonDropdown.territories}
        >
          {appConstants.viewJsonDropdown.territories}
        </option>
        <option
          key={appConstants.viewJsonDropdown.seasons}
          value={appConstants.viewJsonDropdown.seasons}
        >
          {appConstants.viewJsonDropdown.seasons}
        </option>
        <option
          key={appConstants.viewJsonDropdown.timeOfUse}
          value={appConstants.viewJsonDropdown.timeOfUse}
        >
          {appConstants.viewJsonDropdown.timeOfUse}
        </option>
      </SelectInput>

      {visibleJson && (
        <div className={styles.jsonViewerContainer}>
          <JsonViewer data={visibleData} collapsed={false} fontSize="sm" />
        </div>
      )}
    </>
  );
};

export default ViewJsonDrawer;
