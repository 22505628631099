import React from 'react';
import { selectPropertyKey } from '../../state/propertyKeys/propertyKeysSlice';
import { PropertyKeyPopover as GenPropertyKeyPopover } from '@arcadiapower/gen-react-lib';
import { types } from '@genability/api';
import { useSelector } from 'react-redux';

interface PropertyKeyPopoverProps {
  propertyKey: types.GenPropertyKey;
  //default place is right
  placement?:
    | 'auto-start'
    | 'auto'
    | 'auto-end'
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-end'
    | 'bottom'
    | 'bottom-start'
    | 'left-end'
    | 'left'
    | 'left-start';
}

const PropertyKeyPopover: React.FC<PropertyKeyPopoverProps> = ({
  propertyKey,
  ...rest
}: PropertyKeyPopoverProps) => {
  // The GenPropertyKey passed into the popover
  // may or may not be fully populated. Grab it out of
  // the Redux state to ensure it always has complete
  // information.

  const completePropertyKey = useSelector(selectPropertyKey(propertyKey.keyName));

  return (
    <>
      {completePropertyKey && <GenPropertyKeyPopover propertyKey={completePropertyKey} {...rest} />}
    </>
  );
};

export default PropertyKeyPopover;
