import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';
import { StatsFilterTableHeader } from '../../pages/Dashboard/dashboard.types';

export const TARIFF = 'Tariff';
export const LOOKUPS = 'Lookups';
export const ZIPCODES = 'Zipcodes';
export const LSE = 'LSE';
export const PROPERTY_KEYS = 'Property_key';

export enum TaskFilter {
  MY_TASKS = 'My Tasks',
  UNASSIGNED = 'Unassigned',
  COMPLETED = 'Completed',
  ALL_TASKS = 'All Tasks',
}

export interface TaskFilterState {
  activeFilter: TaskFilter;
  activeTaskType: string;
  statsTable: StatsFilterTableHeader;
}
const completedTableConfig = {
  'Last Month': 'lastMonthCount',
  'This Month': 'thisMonthCount',
  'Last Week': 'lastWeekCount',
  'This Week': 'thisWeekCount',
};
const lookupTableConfig = {
  'Add Lookup': 'addLookUpTasks',
  'Edit LookUp': 'editLookupTasks',
  'Upsert Lookup': 'upsertLookupTasks',
  'Add Document': 'addDocumentTasks',
};

const propertyKeyConfig = {
  'Add Property': 'addPropertyTasks',
  'Edit Property': 'editPropertyTasks',
};

const tariffConfig = {
  'Add Tariff': 'addTariffTasks',
  'Edit Tariff': 'editTariffTasks',
  'Revise Tariff': 'reviseTariffTasks',
  'Add Document': 'addDocumentTasks',
  'Revise Document': 'reviseDocumentTasks',
};
const initialState: TaskFilterState = {
  activeFilter: TaskFilter.MY_TASKS,
  activeTaskType: LOOKUPS.toLowerCase(),
  statsTable: {},
};

export const taskFilterSlice = createSlice({
  name: 'taskFilter',
  initialState,
  reducers: {
    updateActiveFilter: (state, action: PayloadAction<TaskFilter>) => {
      state.activeFilter = action.payload;
    },
    updateActiveSelectedTaskType: (state, action: PayloadAction<string>) => {
      const dashboardMenu = action.payload;
      let unassignedStatsHeader = null;
      switch (dashboardMenu) {
        case LOOKUPS.toLowerCase(): {
          unassignedStatsHeader = lookupTableConfig;
          break;
        }
        case PROPERTY_KEYS.toLowerCase(): {
          unassignedStatsHeader = propertyKeyConfig;
          break;
        }
        default: {
          unassignedStatsHeader = tariffConfig;
        }
      }
      state.activeTaskType = dashboardMenu;
      state.statsTable = {
        [TaskFilter.MY_TASKS]: {
          title: 'Your Tasks by status stats :',
          tableConfig: {
            Drafting: 'draftingTasks',
            'Ready To Post': 'readyToPostTasks',
            Posted: 'postedTasks',
            'Ready to Review': 'readyToReviewTasks',
            'Under Review': 'reviewingTasks',
            Approved: 'approvedTasks',
            Rejected: 'rejectedTasks',
            Published: 'publishedTasks',
          },
        },
        [TaskFilter.UNASSIGNED]: {
          title: 'Open Tasks by Task stats :',
          tableConfig: unassignedStatsHeader,
        },
        [TaskFilter.COMPLETED]: {
          title: 'Your Tasks Completion stats :',
          tableConfig: completedTableConfig,
        },
        [TaskFilter.ALL_TASKS]: {
          title: 'All Tasks Completion stats :',
          tableConfig: completedTableConfig,
        },
      };
    },
  },
});

export const selectedTaskFilter = (state: RootState): TaskFilterState => {
  return state.taskFilter;
};

export const { updateActiveFilter, updateActiveSelectedTaskType } = taskFilterSlice.actions;
export default taskFilterSlice.reducer;
