import React, { ReactNode, useEffect, useState } from 'react';

import { Text, TextProps, Tooltip } from '@arcadiapower/shrike';
export interface TextFieldProps {
  color?: TextProps['color'];
  opacity?: TextProps['opacity'];
  tag?: TextProps['tag'];
  textStyle?: TextProps['textStyle'];
  textValue: string;
  toolTipId: string;
  maxSize?: number;
  className?: string;
  toolTipValue?: ReactNode;
}
const TextField: React.FC<TextFieldProps> = ({
  color,
  opacity,
  tag,
  textStyle,
  textValue,
  toolTipId,
  toolTipValue,
  className,
  maxSize = 15,
}) => {
  const [displayText, setDisplayText] = useState<string>(textValue);

  useEffect(() => {
    if (textValue != null && textValue.length > 0 && textValue.length > maxSize) {
      setDisplayText(`${textValue.slice(0, maxSize)}...`);
    }
  }, [textValue]);
  return (
    <>
      <Text
        color={color || 'primary'}
        opacity={opacity || 'high'}
        tag={tag || 'p'}
        className={className || 'd-flex justify-content-around'}
        textStyle={textStyle || 'paragraph600'}
      >
        <Tooltip
          backgroundColor="primaryInverse"
          content={toolTipValue || textValue}
          label={`tooltip-${toolTipId}`}
          tooltipId={`tooltip-${toolTipId}`}
        >
          {displayText}
        </Tooltip>
      </Text>
    </>
  );
};
export default TextField;
