import { Table, Th } from '@arcadiapower/gen-react-lib';
import React, { ReactElement } from 'react';
import styles from '../../../pages/OpsManagement/OpsManagement.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectLookupConfigStats } from '../../../state/OpsManagement/Config/LookupConfigStatsSlice';

const LookupConfigStatsTable = (): ReactElement => {
  const dispatch = useDispatch();
  const stats = useSelector(selectLookupConfigStats);

  return (
    <>
      <span className="d-flex flex-column justify-content-around align-items-center">
        <h6 className="ml-3">Overall Summary</h6>
        <Table className="ml-3" striped bordered>
          <thead>
            <Th className={styles.header}>Total LSEs</Th>
            <Th className={styles.header}>Total Lookups</Th>
            <Th className={styles.header}>Assigned Lookups</Th>
            <Th className={styles.header}>UnAssigned Lookups</Th>
          </thead>
          <tbody className="text-center">
            {stats.apiStatus === 'pending' && <td colSpan={4}>Loading...</td>}
            {stats.apiStatus != 'pending' && stats.errors && (
              <td colSpan={4}>Loading Stats Failed</td>
            )}
            {stats.apiStatus != 'pending' && !stats.errors && stats.results.length > 0 && (
              <tr>
                <td>{stats.results[0].totalLse}</td>
                <td>{stats.results[0].totalLookups}</td>
                <td>{stats.results[0].assigendLookups}</td>
                <td>{stats.results[0].unAssigendLookups}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </span>
      <span className="d-flex flex-column justify-content-around align-items-center">
        <h6 className="ml-3">Current User Summary</h6>
        <Table striped bordered className="ml-3">
          <thead>
            <Th className={styles.header}>Total LSEs Assigned</Th>
            <Th className={styles.header}>Total Lookups Assigned</Th>
          </thead>
          <tbody className="text-center">
            {stats.apiStatus === 'pending' && <td colSpan={2}>Loading...</td>}
            {stats.apiStatus != 'pending' && stats.errors && (
              <td colSpan={2}>Loading Stats Failed</td>
            )}
            {stats.apiStatus != 'pending' && !stats.errors && stats.results.length > 0 && (
              <tr>
                <td>{stats.results[0].totalLseAssignedToCurrentUser}</td>
                <td>{stats.results[0].totalLookupsAssignedToCurrentUser}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </span>
    </>
  );
};

export default LookupConfigStatsTable;
