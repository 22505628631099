import React, { FC, ReactElement, useEffect, useState } from 'react';
import {
  EllipsisPagination,
  InitialsBadge,
  Loader,
  Table,
  Td,
  Th,
  Tr,
} from '@arcadiapower/gen-react-lib';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state/rootReducer';
import { LoadingState } from '../../state/reduxUtils';
import { useTariffFilterContext } from '../../context/tariffFilterContext';
import { Link } from 'react-router-dom';
import { Icon } from '@arcadiapower/shrike';
import { TariffType } from '@genability/api/dist/types';
import TariffListModal from '../TariffListModal/TariffListModal';
import { fetchTariffs } from '../../state/tariffs/tariffsSlice';

const headers = [
  {
    property: 'tariffCode',
    value: 'Tariff Code',
    hidden: false,
  },
  {
    property: 'name',
    value: 'Tariff Name',
    hidden: false,
  },
  {
    property: 'masterTariffId',
    value: 'Master Tariff Id',
    hidden: false,
  },
  {
    property: 'TariffID',
    value: 'Tariff ID',
    hidden: false,
  },
  {
    property: 'rateCriteria',
    value: 'Rate Criteria',
    hidden: false,
  },
  {
    property: 'customerClass',
    value: 'Customer Class',
    hidden: false,
  },
  {
    property: 'effectiveDate',
    value: 'Effective Date',
    hidden: false,
  },
  {
    property: 'lseName',
    value: 'Utility Name',
    hidden: false,
  },
  {
    property: 'minMonthlyConsumption',
    value: 'KWH Range',
    hidden: false,
  },
  {
    property: 'minMonthlyDemand',
    value: 'KW Range',
    hidden: false,
  },
  {
    property: 'customerCount',
    value: 'Customer Count',
    hidden: false,
  },
  {
    property: 'tariffLinks',
    value: 'Links',
    hidden: false,
  },
  {
    property: 'actions',
    value: 'Actions',
    hidden: false,
  },
];

const TariffsList: FC = (): ReactElement => {
  const [currentMeta, setCurrentMeta] = useState<any>({
    currentPage: 1,
    itemsPerPage: 25,
  });
  const [showTariffListModal, setShowTariffListModal] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>('Unpublish All');
  const [masterTariffId, setMasterTariffId] = useState<number>(0);
  const {
    results: tariffs,
    errors,
    loading,
    meta,
  } = useSelector((state: RootState) => state.tariffs);
  const { filter } = useTariffFilterContext();
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentMeta.currentPage < 1) {
      return;
    }
    dispatch(
      fetchTariffs({
        ...filter,
        pageCount: currentMeta.pageCount,
        pageStart: currentMeta.currentPage - 1,
      })
    );
  }, [currentMeta]);

  const refreshTab = () => {
    dispatch(
      fetchTariffs({
        ...filter,
        pageCount: currentMeta.pageCount,
        pageStart: currentMeta.page,
      })
    );
  };
  const mappedHeaders = () => {
    const mapped = headers.map(head => <Th key={head.property}>{head.value}</Th>);
    return mapped;
  };

  const handleIconChange = (buttonText: string, masterTariffId: number) => {
    setButtonText(buttonText);
    setShowTariffListModal(true);
    setMasterTariffId(masterTariffId);
  };

  const mappedTariffRows = () => {
    return tariffs?.length
      ? tariffs.map(tariff => (
          <Tr className={`border-top-3 border-info`} key={tariff?.tariffId}>
            <Td className="align-middle">{tariff.tariffCode}</Td>
            <Td className="align-middle">{tariff.tariffName}</Td>
            <Td className="align-middle">{tariff.masterTariffId}</Td>
            <Td className="align-middle">{tariff.tariffId}</Td>
            <Td className="align-middle">
              {tariff.hasRateApplicability ? (
                <InitialsBadge value="Applicability" variant="dark" />
              ) : (
                ''
              )}
              {tariff.hasContractedRates ? <InitialsBadge value="Contracted" variant="dark" /> : ''}
              {tariff.hasNetMetering ? <InitialsBadge value="Net Metering" variant="dark" /> : ''}
              {tariff.hasTieredRates ? <InitialsBadge value="Tiered" variant="dark" /> : ''}
              {tariff.hasTimeOfUseRates ? <InitialsBadge value="Time Of Use" variant="dark" /> : ''}
            </Td>
            <Td className="align-middle">{tariff.customerClass}</Td>
            <Td className="align-middle">{tariff.effectiveDate}</Td>
            <Td className="align-middle">{tariff.lseName}</Td>
            <Td className="align-middle">
              {tariff.minMonthlyConsumption} - {tariff.maxMonthlyConsumption}
            </Td>
            <Td className="align-middle">
              {tariff.minMonthlyDemand} - {tariff.maxMonthlyDemand}
            </Td>
            <Td className="align-middle">{tariff.customerCount?.toLocaleString()}</Td>
            <Td className="align-middle">
              {tariff.tariffType &&
                tariff.tariffType != TariffType.RIDER &&
                tariff.tariffType != TariffType.INCENTIVE && (
                  <Row>
                    <Link target="_blank" to={`tariffs/${tariff.masterTariffId}`}>
                      Detail view
                    </Link>
                  </Row>
                )}
              <Row>
                <Link
                  target="_blank"
                  to={{
                    pathname: `${process.env.REACT_APP_DASH_URL}/explorer/tariffs/${tariff.masterTariffId}`,
                  }}
                >
                  Dash view
                </Link>
              </Row>
              <Row>
                <Link
                  target="_blank"
                  to={{
                    pathname: `${process.env.REACT_APP_MOTHER_URL}/lses/${tariff.lseId}/tariffs/${tariff.tariffId}`,
                  }}
                >
                  Mother view
                </Link>
              </Row>
            </Td>
            <Td className="align-middle" id="unpublish">
              <Icon
                icon={'UICloseCircleInverted'}
                title="Unpublish Latest Version"
                className="ml-3"
                scale={3.5}
                onClick={() => handleIconChange('Unpublish Latest Version', tariff.masterTariffId)}
              />
              <Icon
                icon={'UIDelete'}
                className="mt-3 ml-3"
                title="Unpublish All"
                scale={3.5}
                onClick={() => handleIconChange('Unpublish All', tariff.masterTariffId)}
              />
            </Td>
          </Tr>
        ))
      : null;
  };

  const renderRows = () => {
    if (!tariffs?.length) {
      return (
        <Tr>
          <Td colSpan={headers.length}>No data available...</Td>
        </Tr>
      );
    }
    return <>{mappedTariffRows()}</>;
  };

  return (
    <Container fluid className="p-4">
      {showTariffListModal && (
        <TariffListModal
          buttonText={buttonText}
          setShowTariffListModal={setShowTariffListModal}
          showTariffListModal={showTariffListModal}
          masterTariffId={masterTariffId}
          refreshTab={refreshTab}
        />
      )}
      <Row className="my-2">
        <Col xs={6} sm={8} md={10} className="d-flex align-items-end">
          <h5 className="font-weight-bold">Search Results:</h5>
        </Col>
      </Row>
      <Row>
        <Table striped borderless hover>
          <thead>
            <Tr>{mappedHeaders()}</Tr>
          </thead>
          <tbody>
            {loading === LoadingState.PENDING ? (
              <Tr>
                <Td colSpan={headers.length} className="text-center w-100">
                  <Loader />
                  Loading...
                </Td>
              </Tr>
            ) : (
              renderRows()
            )}
            {errors?.length
              ? errors.map(error => (
                  <Tr key={error.code}>
                    <Td>
                      <Alert variant="error">{error.message}</Alert>
                    </Td>
                  </Tr>
                ))
              : null}
          </tbody>
        </Table>
      </Row>
      <Row className="justify-content-center">
        <EllipsisPagination
          count={meta.count}
          page={meta.page}
          pageCount={meta.pageCount}
          setCurrentMeta={setCurrentMeta}
          showItemsPerPageSelector
        />
      </Row>
    </Container>
  );
};

export default TariffsList;
