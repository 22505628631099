import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Task, TaskStatus } from '../../task-api/types/task';
import { restClient } from '@genability/api';
import { TaskApiClient } from '../../GenApiClient';
import { GetTasksRequest } from '../../task-api/api/task-api';
import { handleUnexpectedThunkException } from '../reduxUtils';
import { RootState } from '../rootReducer';

export interface TasksState {
  apiStatus: 'idle' | 'pending' | 'resolved' | 'rejected';
  count: number;
  results: Task[];
  errors: restClient.ResponseError[] | undefined;
}

const initialState: TasksState = {
  apiStatus: 'idle',
  count: 0,
  results: [],
  errors: undefined,
};

export const fetchTasks = createAsyncThunk(
  'tasks/fetchTasks',
  async (request: GetTasksRequest, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiClient();
      const response: restClient.PagedResponse<Task> = await client.task.getTasks(request);
      return response;
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'Task', dispatch));
    }
  }
);

export const fetchDraftTasks = createAsyncThunk(
  'tasks/fetchDraftTasks',
  async (request: GetTasksRequest, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiClient();
      request.taskStatuses = [TaskStatus.DRAFTING];
      const response: restClient.PagedResponse<Task> = await client.task.getTasks(request);
      return response;
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'Task', dispatch));
    }
  }
);

export const fetchReviewTasks = createAsyncThunk(
  'tasks/fetchReviewTasks',
  async (request: GetTasksRequest, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiClient();
      request.taskStatuses = [TaskStatus.REVIEWING];
      const response: restClient.PagedResponse<Task> = await client.task.getTasks(request);
      return response;
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'Task', dispatch));
    }
  }
);

export const fetchCompletedTasks = createAsyncThunk(
  'tasks/fetchCompletedTasks',
  async (request: GetTasksRequest, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiClient();
      if (!request.taskStatuses) request.taskStatuses = [TaskStatus.PUBLISHED, TaskStatus.CLOSED];
      const response: restClient.PagedResponse<Task> = await client.task.getTasks(request);
      return response;
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'Task', dispatch));
    }
  }
);
export const TaskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchTasks.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(
      fetchTasks.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<Task>>) => {
        const { results, errors } = action.payload;
        if (errors) {
          state.apiStatus = 'rejected';
          state.errors = errors;
        } else {
          state.apiStatus = 'resolved';
          state.results = results;
          state.errors = undefined;
        }
      }
    );
    builder.addCase(fetchTasks.rejected, state => {
      state.apiStatus = 'rejected';
    });
    builder.addCase(fetchDraftTasks.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(
      fetchDraftTasks.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<Task>>) => {
        const { results, errors } = action.payload;
        if (errors) {
          state.apiStatus = 'rejected';
          state.errors = errors;
        } else {
          state.apiStatus = 'resolved';
          state.results = results;
          state.errors = undefined;
        }
      }
    );
    builder.addCase(fetchDraftTasks.rejected, state => {
      state.apiStatus = 'rejected';
    });
    builder.addCase(fetchReviewTasks.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(
      fetchReviewTasks.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<Task>>) => {
        const { results, errors } = action.payload;
        if (errors) {
          state.apiStatus = 'rejected';
          state.errors = errors;
        } else {
          state.apiStatus = 'resolved';
          state.results = results;
          state.errors = undefined;
        }
      }
    );
    builder.addCase(fetchReviewTasks.rejected, state => {
      state.apiStatus = 'rejected';
    });
    builder.addCase(fetchCompletedTasks.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(
      fetchCompletedTasks.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<Task>>) => {
        const { results, errors } = action.payload;
        if (errors) {
          state.apiStatus = 'rejected';
          state.errors = errors;
        } else {
          state.apiStatus = 'resolved';
          state.results = results;
          state.errors = undefined;
        }
      }
    );
    builder.addCase(fetchCompletedTasks.rejected, state => {
      state.apiStatus = 'rejected';
    });
  },
});

export const selectTasks = (state: RootState): TasksState => {
  return state.tasks;
};

export default TaskSlice.reducer;
