import { Link } from '@arcadiapower/shrike';
import React from 'react';

interface OverlayLinkProps {
  id?: string | number;
  url: string;
  displayText: string;
  to?: boolean | false;
  fontSize?: 500 | 600 | undefined;
  openInNewTab?: boolean | true;
}

const OverlayLink: React.FC<OverlayLinkProps> = ({
  id,
  url,
  displayText,
  to,
  openInNewTab,
  fontSize,
}) => {
  return (
    <Link
      id={`${id}`}
      backgroundColor="primaryInverse"
      color="primary"
      fontSize={fontSize || 500}
      href={!to ? url : undefined}
      to={to ? url : undefined}
      openInNewTab={openInNewTab}
      size="small"
      type="standalone"
      hideIcon
      textAlign="string"
      className="text-center"
      style={{
        wordBreak: 'break-all',
      }}
    >
      {displayText}
    </Link>
  );
};

export default OverlayLink;
