import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { restClient, types } from '@genability/api';
import { TaskApiClient } from '../../GenApiClient';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { addNotification } from '../notification/notificationSlice';
import { FetchSingleApiResponse } from '../../utils/apiResponseTypes';
import { RootState } from '../rootReducer';
import { fetchTaskAssignmentAnswer } from '../taskAssignment/taskAssignmentSlice';
import { handleUnexpectedThunkException } from '../reduxUtils';
import { GetTaskAssignmentsRequest } from '../../task-api/api/task-assignment-api';
import { addLinks } from '../../components/ApiFormError/ApiFormError';
import { TaskTariff } from '../../task-api/types/task';

export interface TaskAssignmentTariffState {
  taskAssignmentId: number | null;
  tariff: TaskTariff | undefined;
  isLoading: boolean;
  error: string | null;
  apiStatus: 'idle' | 'pending' | 'notfound' | 'success' | 'error'; // rejected=error, success=resolved
  errors: restClient.ResponseError[] | undefined;
}

export const initialState: TaskAssignmentTariffState = {
  taskAssignmentId: null,
  tariff: undefined,
  isLoading: false,
  error: null,
  apiStatus: 'idle',
  errors: undefined,
};

interface FetchTaskAssignmentTariffArgs {
  taskAssignmentId: number;
}

interface TaskAssignmentTariffSuccess {
  taskAssignmentId: number;
  tariff: TaskTariff;
}

interface UpdateTaskAssignmentTariffArgs {
  taskAssignmentId: number;
  tariff: TaskTariff;
  errorLinks?: Record<string, string>;
}

function loadingFailed(state: TaskAssignmentTariffState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
  state.apiStatus = 'error';
}

function tariffSuccess(
  state: TaskAssignmentTariffState,
  { payload }: PayloadAction<TaskAssignmentTariffSuccess>
) {
  const { taskAssignmentId, tariff } = payload;
  state.taskAssignmentId = taskAssignmentId;
  state.tariff = tariff;
  state.isLoading = false;
  state.error = null;
  state.apiStatus = 'success';
}

export const fetchTaskAssignmentTariff = createAsyncThunk(
  'taskAssignmentTariffs/fetchTaskAssignmentTariff',
  async (options: FetchTaskAssignmentTariffArgs, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiClient();
      const request: GetTaskAssignmentsRequest = new GetTaskAssignmentsRequest();
      request.fields = restClient.Fields.EXTENDED;
      const response: restClient.SingleResponse<TaskTariff> =
        await client.taskAssignment.getTaskAssignmentTariff(options.taskAssignmentId, request);
      if (response.errors) {
        const errorMessage = response.errors[0].message;
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
      return { result: response.result, errors: response.errors };
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'Tariff', dispatch));
    }
  }
);

export const updateTaskAssignmentTariff = createAsyncThunk<
  restClient.SingleResponse<TaskTariff>,
  UpdateTaskAssignmentTariffArgs,
  {
    rejectValue: restClient.ResponseError;
  }
>(
  'taskAssignmentTariffs/updateTaskAssignmentTariff',
  async (options, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiClient();
      const response: restClient.SingleResponse<TaskTariff> =
        await client.taskAssignment.updateTaskAssignmentTariff(
          options.taskAssignmentId,
          options.tariff
        );
      // TODO: Put this back once 400 errors display correctly
      // Show notification only if the response is a SystemError
      // const systemError = findSystemError(response.errors);
      if (response.errors) {
        response.errors.forEach(error => {
          const messageWithLinks = options.errorLinks
            ? addLinks(error.message, options.errorLinks)
            : error.message;
          dispatch(addNotification(messageWithLinks, NotificationLevel.Error));
        });
      }
      if (response.result) {
        dispatch(
          fetchTaskAssignmentAnswer({
            taskAssignmentId: options.taskAssignmentId,
            answerField: 'tariff',
          })
        );
      }
      return response;
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'Tariff', dispatch));
    }
  }
);

export const taskAssignmentTariffs = createSlice({
  name: 'taskAssignmentTariffs',
  initialState,
  reducers: {
    getTaskAssignmentTariffSuccess: tariffSuccess,
    getTaskAssignmentTariffFailure: loadingFailed,
  },
  extraReducers: builder => {
    builder.addCase(fetchTaskAssignmentTariff.pending, state => {
      state.apiStatus = 'pending';
      state.isLoading = true;
    });
    builder.addCase(
      fetchTaskAssignmentTariff.fulfilled,
      (state, action: PayloadAction<FetchSingleApiResponse<TaskTariff>>) => {
        const { result, errors } = action.payload;
        if (errors) {
          if (errors[0] && errors[0].code == 'ObjectNotFound') {
            state.apiStatus = 'notfound';
          } else {
            state.apiStatus = 'error';
          }
          state.errors = errors;
        } else {
          state.apiStatus = 'success';
          state.tariff = result ? result : undefined;
          state.errors = undefined;
        }
        state.isLoading = false;
      }
    );
    builder.addCase(fetchTaskAssignmentTariff.rejected, state => {
      state.apiStatus = 'error';
      state.isLoading = false;
    });
    builder.addCase(updateTaskAssignmentTariff.pending, state => {
      state.apiStatus = 'pending';
      state.isLoading = true;
    });
    builder.addCase(
      updateTaskAssignmentTariff.fulfilled,
      (state, action: PayloadAction<restClient.SingleResponse<TaskTariff>>) => {
        const { result, errors } = action.payload;
        if (errors) {
          state.apiStatus = 'error';
          state.errors = errors;
        } else {
          state.apiStatus = 'success';
          state.tariff = result ? result : undefined;
          state.errors = undefined;
        }
        state.isLoading = false;
      }
    );
    builder.addCase(updateTaskAssignmentTariff.rejected, state => {
      state.apiStatus = 'error';
      state.isLoading = false;
    });
  },
});

export const { getTaskAssignmentTariffSuccess, getTaskAssignmentTariffFailure } =
  taskAssignmentTariffs.actions;

export const selectIsLoading = (state: RootState): boolean => {
  return state.taskAssignmentTariffs.isLoading;
};

export const selectApiStatus = (state: RootState): string => {
  return state.taskAssignmentTariffs.apiStatus;
};

export const selectTaskAssignmentTariff = (state: RootState): TaskTariff | undefined => {
  return state.taskAssignmentTariffs.tariff;
};

export default taskAssignmentTariffs.reducer;
