import { restApis } from '@genability/api';
import { ChargeType, CustomerClass, ServiceType, TariffType } from '@genability/api/dist/types';
import React, { createContext, useContext } from 'react';
import { AppDispatch } from '../state/store';
import { fetchTariffs } from '../state/tariffs/tariffsSlice';
import { IFilterContext } from './types';

export type TariffFilterContext = `tariffContext`;
export type TariffFilter = Omit<
  restApis.GetTariffsRequest,
  | 'addParams'
  | 'queryStringify'
  | 'addPaginationParams'
  | 'addSearchParams'
  | 'addSortParams'
  | 'addFieldsParam'
>;

export interface ITariffFilter {
  country: string | undefined;
  chargeTypes: ChargeType[] | undefined;
  tariffTypes: TariffType[] | undefined;
  customerClasses: CustomerClass[] | undefined;
  serviceTypes: ServiceType[] | undefined;
  search: string | undefined;
  effectiveOn: string | undefined;
  activeDuring: string | undefined;
  zipCode: string | undefined;
  demand: number | undefined;
  consumption: number | undefined;
  hasNetMetering: boolean | undefined;
  hasTimeOfUseRates: boolean | undefined;
  hasTieredRates: boolean | undefined;
  hasContractedRates: boolean | undefined;
  pageCount?: number;
  pageStart?: number;
}
export type TariffFilterContextType = {
  filter: TariffFilter;
  setFilter: React.Dispatch<React.SetStateAction<TariffFilter>>;
  onFilterChange: (dispatch: AppDispatch, options: TariffFilter) => void;
};

export const initialTariffFilterContext: IFilterContext<TariffFilter> = {
  filter: {
    country: undefined,
    chargeTypes: undefined,
    customerClasses: undefined,
    serviceTypes: undefined,
    tariffTypes: [TariffType.DEFAULT, TariffType.ALTERNATIVE],
    search: undefined,
    hasContractedRates: undefined,
    hasNetMetering: undefined,
    hasTieredRates: undefined,
    hasTimeOfUseRates: undefined,
    demand: undefined,
    consumption: undefined,
    effectiveOn: undefined,
    zipCode: undefined,
    isActive: true,
    pageCount: 25,
    pageStart: 0,
  },
  setFilter: () => ({}),
  onFilterChange: (dispatch: AppDispatch, options: TariffFilter) => {
    return;
  },
};

export const TariffFilterContext = createContext<IFilterContext<TariffFilter>>(
  initialTariffFilterContext
);

export const useTariffFilterContext = (): IFilterContext<TariffFilter> =>
  useContext(TariffFilterContext);
