import { Input, Button, Text, IconButton, Icon, Checkbox } from '@arcadiapower/shrike';
import { Loader, NotificationLevel, Pagination, Th } from '@arcadiapower/gen-react-lib';
import React, { ReactElement, useState, useCallback, useEffect } from 'react';
import { Row, Table } from 'react-bootstrap';
import TextField from '../../TextField/TextField';
import { FrequencyType } from '../../../ops-api/types/FrequencyType';
import DropdownSelect from '../../DropdownSelect/DropdownSelect';
import { useDispatch, useSelector } from 'react-redux';
import {
  LookupStatusProps,
  fetchLookupStatusData,
  selectLookupStatusData,
} from '../../../state/OpsManagement/Status/LookupStatusSlice';
import { SortOrder } from '@genability/api/dist/rest-client';
import { MonitoringStatusType } from '../../../ops-api/types/MonitoringStatusTypes';
import { OpsFilterProps, OpsGlobalFilter } from '../../../pages/OpsManagement/OpsManagementTabs';
import { Link } from 'react-router-dom';
import { OpsManagementApiClient } from '../../../GenApiClient';
import { addNotification } from '../../../state/notification/notificationSlice';
import { doQuickSearch } from '../../../state/QuickSearch/QuickSearchSlice';
import QuickSearch, { QuickSearchProps } from '../../QuickSearchComponent/QuickSearch';
import {
  selectUpdatedStatusData,
  startUpdateMonitoringStatusLoading,
  updateMonitoringStatus,
} from '../../../state/OpsManagement/Status/UpdateStatusSlice';
import { SourceType } from '../../../ops-api/types/LookupSources';
import { fetchLookupStatusStats } from '../../../state/OpsManagement/Status/LookupStatusStatsSlice';
import appConstants from '../../../app-constants';
import { MultiSelect, Option } from 'react-multi-select-component';
import { selectWorkers, fetchWorkers } from '../../../state/workers-v2/workersSlice';
import { GetWorkersRequest } from '../../../task-api/v2/api/task-api-v2';
import { QualificationType } from '../../../task-api/v2/types/QualifictionType';
import DateRangePicker from '../../DateRangePicker/DateRangePicker';
import { selectCurrentUser } from '../../../state/currentUser/currentUserSlice';
import { MonitoringHistory } from '../../../ops-api/types/MonitoringHistory';
import MonitoringHistoryModal from './MonitoringHistoryModal';
import { EntityType } from '../../../task-api/v2/types/EntityType';
import { UserMonitoringStatusType } from '../../../ops-api/types/UserMonitoringStatusType';
import { applyTimeZoneOffSet } from '../../../utils/dateUtils';

export interface LookupStatusData {
  propertyKey: string;
  monitoringInventoryId: number;
  lseId: number;
  lseName: string;
  frequency: FrequencyType;
  source: SourceType;
  modeller: string;
  reviewer: string;
  effectiveStartDate: string;
  lastUpdatedDate: string;
  dueInDays: number;
  effectiveEndDate: string;
  expired: boolean;
  nextExpectedPostDate: string;
  monitoringStatus: MonitoringHistory;
}
export interface OpsManagementLocalFilterProps extends QuickSearchProps {
  dueInDaysBuffer: string;
  customerCountBuffer: string;
  apiCountBuffer: string;
  monitoringNotesBuffer: string;
  source: string;
  frequency: string;
  monitoringStatus: string;
  sentinelStatus: string;
}

const LookupStatusTable = ({ filter, setFilter }: OpsFilterProps): ReactElement => {
  const dispatch = useDispatch();
  const { results, fetchApiStatus, count } = useSelector(selectLookupStatusData);
  const { results: workers } = useSelector(selectWorkers);
  const currentUser = useSelector(selectCurrentUser);
  const { results: updatedStatusResults, apiStatus: updateApiStatus } =
    useSelector(selectUpdatedStatusData);
  const [sort, setSort] = useState({ sortOn: 'propertyKey', sortOrder: 'ASC' });
  const [page, setPage] = useState(1);
  const pageCount = 50;
  const totalPageCount = Math.ceil(count / pageCount);
  const [statusEditMode, setStatusEditMode] = useState(0);
  const [exportLoad, setExportLoad] = useState<boolean>(false);
  const [modeller, setModeller] = useState<Option[]>([
    { value: currentUser.username, label: currentUser.username },
  ]);
  const [localFilter, setLocalFilter] = useState<Partial<OpsManagementLocalFilterProps>>({});
  const [startDate, setStartDate] = useState<[Date, Date] | null>(null);
  const [lastUpdatedDate, setLastUpdatedDate] = useState<[Date, Date] | null>(null);
  const [monitoredDate, setMonitoredDate] = useState<[Date, Date] | null>(null);
  const [editedRow, setEditedRow] = useState<
    { notes: string | undefined; status: string | undefined } | undefined
  >(undefined);
  const [showMonitoringHistory, setShowMonitoringHistory] = useState<boolean>(false);
  const [monitoringInventoryId, setMonitoringInventoryId] = useState<number>();
  const [entityID, setEntityId] = useState<string>();

  const lookupStatusHeaders = [
    {
      key: 'lseName',
      label: (
        <span className="d-flex flex-column">
          <span>Lse</span>
          <span>Name</span>
        </span>
      ),
      search: (
        <QuickSearch
          popoverStyle={{ zIndex: 10 }}
          value={localFilter.lse || filter.lse || ''}
          onClick={() => {
            dispatch(
              doQuickSearch({ minChar: 1, key: 'lse', value: localFilter.lse?.split('-')[0] })
            );
          }}
          onChange={value => {
            setLocalFilter({
              lse: value,
            });
            if (filter.lse && value == '') {
              setFilter(prev => {
                return {
                  ...prev,
                  lse: undefined,
                };
              });
            }
            dispatch(doQuickSearch({ minChar: 1, key: 'lse', value }));
          }}
          onSelect={value => {
            setLocalFilter({
              lse: value,
            });
            setFilter(prev => {
              return {
                ...prev,
                lse: value || undefined,
              };
            });
            setPage(1);
          }}
          placeholder="Search"
        />
      ),
    },
    {
      key: 'propertyKey',
      label: `Property Key Name`,
      search: (
        <QuickSearch
          popoverStyle={{ zIndex: 10 }}
          value={localFilter.propertyKey || filter.propertyKey || ''}
          onClick={() => {
            dispatch(
              doQuickSearch({ minChar: 3, key: 'propertyKey', value: localFilter.propertyKey })
            );
          }}
          onChange={value => {
            if (filter.propertyKey && value == '') {
              setFilter(prev => {
                return {
                  ...prev,
                  propertyKey: undefined,
                };
              });
            }
            dispatch(doQuickSearch({ minChar: 3, key: 'propertyKey', value: value }));
            setLocalFilter({
              propertyKey: value != '' ? value : undefined,
            });
          }}
          onSelect={value => {
            setLocalFilter({
              propertyKey: value,
            });
            setPage(1);
            setFilter(prev => {
              return {
                ...prev,
                propertyKey: value,
              };
            });
          }}
          placeholder="Search"
        />
      ),
    },
    {
      key: 'frequency',
      label: 'Lookup Frequency',
      search: (
        <DropdownSelect
          popoverStyle={{ zIndex: 10 }}
          selected={localFilter.frequency || filter.frequency || ''}
          onChange={value => {
            if (filter.frequency && value == '') {
              setFilter(prev => {
                return {
                  ...prev,
                  frequency: undefined,
                };
              });
            }
            setLocalFilter({ frequency: value });
          }}
          setSelected={value => {
            setLocalFilter({ frequency: value });
            setPage(1);
            setFilter(prev => {
              return { ...prev, frequency: value as FrequencyType };
            });
          }}
          options={Object.keys(FrequencyType).filter(
            key =>
              !localFilter.frequency?.length || key.includes(localFilter.frequency.toUpperCase())
          )}
          label=""
        />
      ),
    },
    {
      key: 'source',
      label: 'Lookup Source',
      search: (
        <DropdownSelect
          popoverStyle={{ zIndex: 10 }}
          selected={localFilter.source || filter.source || ''}
          onChange={value => {
            if (filter.source && value == '') {
              setFilter(prev => {
                return {
                  ...prev,
                  source: undefined,
                };
              });
            }
            setLocalFilter({ source: value });
          }}
          setSelected={value => {
            setLocalFilter({ source: value });
            setPage(1);
            setFilter(prev => {
              return { ...prev, source: value as SourceType };
            });
          }}
          options={Object.keys(SourceType).filter(
            key => !localFilter.source?.length || key.includes(localFilter.source.toUpperCase())
          )}
          label=""
        />
      ),
    },
    {
      key: 'effectiveStartDate',
      label: 'Effective start date',
      search: <DateRangePicker date={startDate} setDate={setStartDate} />,
    },
    {
      key: 'lastUpdatedDate',
      label: 'Last updated date',
      search: <DateRangePicker date={lastUpdatedDate} setDate={setLastUpdatedDate} />,
    },
    // {
    //   key: 'nextExpectedPostDate',
    //   label: 'Expected Post Date',
    //   search: (
    //     <Input
    //       name="nepd"
    //       value={filter.nepd || ''}
    //       onChange={value => {
    //         // setPage(1);
    //         // setSearchValues({ las: value });
    //         return;
    //       }}
    //       label=""
    //       placeholder="Search"
    //     />
    //   ),
    // },
    {
      key: 'dueInDays',
      label: 'Due in days',
      search: (
        <Input
          name="due"
          value={localFilter.dueInDaysBuffer}
          onChange={value => {
            setLocalFilter(prev => {
              return { ...prev, dueInDaysBuffer: value };
            });
            if (filter.dueInDays && value === '') {
              setFilter(prev => {
                return { ...prev, dueInDays: undefined };
              });
            }
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              setFilter(prev => {
                return { ...prev, dueInDays: localFilter.dueInDaysBuffer };
              });
            }
          }}
          label=""
          placeholder="Enter..."
        />
      ),
    },
    {
      key: 'monitoringStatus',
      label: 'Monitoring Status',
      search: (
        <DropdownSelect
          popoverStyle={{ zIndex: 10 }}
          selected={localFilter.monitoringStatus || filter.monitoringStatus || ''}
          onChange={value => {
            if (filter.monitoringStatus && value == '') {
              setFilter(prev => {
                return {
                  ...prev,
                  monitoringStatus: undefined,
                };
              });
            }
            setLocalFilter({ monitoringStatus: value });
          }}
          setSelected={value => {
            setLocalFilter({ monitoringStatus: value });
            setPage(1);
            setFilter(prev => {
              return { ...prev, monitoringStatus: value as MonitoringStatusType };
            });
          }}
          options={Object.keys(MonitoringStatusType).filter(
            key =>
              !localFilter.monitoringStatus?.length ||
              key.includes(localFilter.monitoringStatus.toUpperCase())
          )}
          label=""
        />
      ),
    },
    {
      key: 'monitoringNotes',
      label: 'Monitoring notes',
      search: (
        <Input
          name="notes"
          value={localFilter.monitoringNotesBuffer}
          onChange={value => {
            setLocalFilter(prev => {
              return { ...prev, monitoringNotesBuffer: value };
            });
            if (value === '') {
              setFilter(prev => {
                return { ...prev, monitoringNotes: undefined };
              });
            }
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              setFilter(prev => {
                return { ...prev, monitoringNotes: localFilter.monitoringNotesBuffer };
              });
            }
          }}
          label=""
          placeholder="Enter..."
        />
      ),
    },
    {
      key: 'lastMonitoredDate',
      label: 'Monitored date',
      search: <DateRangePicker date={monitoredDate} setDate={setMonitoredDate} />,
    },
    {
      key: 'edit',
      label: 'Edit',
      search: null,
    },
    // {
    //   key: 'modeller',
    //   label: 'Modeller',
    //   search: (
    //     <WorkerInput popoverStyle={{zIndex:10}}
    //       label={''}
    //       placeholder="Search"
    //       id=""
    //       qualification={QualificationType.LOOKUP_MODELLER}
    //       selected={filter.modeller}
    //       setSelected={(assignee: IWorker | undefined) => {
    //         if (!assignee && !filter.modeller) {
    //           return;
    //         }
    //         setFilter(prev => {
    //           return { ...prev, modeller: assignee };
    //         });
    //       }}
    //     />
    //   ),
    // },
    { key: 'history', label: 'History ', search: null },
  ];

  const handleSort = useCallback(
    (key: string) => {
      if (sort.sortOn != key || sort.sortOrder === 'DESC') {
        setSort(prev => {
          return { sortOn: key, sortOrder: 'ASC' };
        });
      } else {
        setSort(prev => {
          return { ...prev, sortOrder: 'DESC' };
        });
      }
    },
    [sort]
  );

  const populateFilters = useCallback((): LookupStatusProps => {
    const request: LookupStatusProps = {
      paginationCriteria: { pageCount, pageStart: (page - 1) * pageCount },
      sortCriteria: { sortOn: [sort.sortOn], sortOrder: [sort.sortOrder] },
      lookupStatusFilterCriteria: {},
    };
    if (filter.propertyKey) {
      request.lookupStatusFilterCriteria.propertyKeys = [filter.propertyKey];
    }
    if (modeller) {
      request.lookupStatusFilterCriteria.modellers = modeller.map(worker => worker.label);
    }
    if (filter.lse) {
      request.lookupStatusFilterCriteria.lseIds = [filter.lse?.split('-')[0]];
    }
    if (filter.frequency) {
      request.lookupStatusFilterCriteria.frequency = [filter.frequency];
    }
    if (filter.expiredFlag) {
      request.lookupStatusFilterCriteria.expired = filter.expiredFlag;
    }
    if (filter.dueInDays) {
      request.lookupStatusFilterCriteria.dueInDays = filter.dueInDays;
    }
    if (filter.monitoringStatus) {
      request.lookupStatusFilterCriteria.monitoringStatusList = [filter.monitoringStatus];
    }
    if (filter.monitoringNotes) {
      request.lookupStatusFilterCriteria.monitoringNotes = filter.monitoringNotes;
    }
    if (filter.source) {
      request.lookupStatusFilterCriteria.sourceList = [filter.source];
    }

    if (startDate) {
      if (!request.lookupStatusFilterCriteria.dateFilterCriteria) {
        request.lookupStatusFilterCriteria.dateFilterCriteria = [];
      }
      const fromDate = applyTimeZoneOffSet(startDate[0]);
      const toDate = applyTimeZoneOffSet(startDate[1]);
      if (fromDate && toDate) {
        request.lookupStatusFilterCriteria.dateFilterCriteria.push({
          dateColumnName: 'effectiveStartDate',
          fromDate: fromDate?.toISOString().slice(0, -5).split('T')[0],
          toDate: toDate?.toISOString().slice(0, -5).split('T')[0],
        });
      }
    }
    if (lastUpdatedDate) {
      if (!request.lookupStatusFilterCriteria.dateFilterCriteria) {
        request.lookupStatusFilterCriteria.dateFilterCriteria = [];
      }
      const fromDate = applyTimeZoneOffSet(lastUpdatedDate[0]);
      const toDate = applyTimeZoneOffSet(lastUpdatedDate[1]);
      if (fromDate && toDate) {
        request.lookupStatusFilterCriteria.dateFilterCriteria.push({
          dateColumnName: 'lastUpdatedDate',
          fromDate: fromDate?.toISOString().slice(0, -5).split('T')[0],
          toDate: toDate?.toISOString().slice(0, -5).split('T')[0],
        });
      }
    }
    if (monitoredDate) {
      if (!request.lookupStatusFilterCriteria.dateFilterCriteria) {
        request.lookupStatusFilterCriteria.dateFilterCriteria = [];
      }
      const fromDate = applyTimeZoneOffSet(monitoredDate[0]);
      const toDate = applyTimeZoneOffSet(monitoredDate[1]);
      if (fromDate && toDate) {
        request.lookupStatusFilterCriteria.dateFilterCriteria.push({
          dateColumnName: 'lastMonitoredDate',
          fromDate: fromDate?.toISOString().slice(0, -5).split('T')[0],
          toDate: toDate?.toISOString().slice(0, -5).split('T')[0],
        });
      }
    }
    return request;
  }, [page, pageCount, sort, filter, modeller, startDate, lastUpdatedDate, monitoredDate]);

  const exportFunction = async () => {
    setExportLoad(true);
    const request: LookupStatusProps = populateFilters();
    const client = await OpsManagementApiClient();
    const response = await client.opsData.exportLookupStatusData(request);
    if (response.errors) {
      dispatch(addNotification('Export Failed !', NotificationLevel.Error));
    } else {
      dispatch(addNotification('Exported Successfully !!!!', NotificationLevel.Success));
    }
    setExportLoad(false);
  };

  useEffect(() => {
    dispatch(fetchLookupStatusStats({}));
  }, [updatedStatusResults]);

  useEffect(() => {
    const request = new GetWorkersRequest();
    request.qualification = QualificationType.LOOKUP_MODELLER;
    dispatch(fetchWorkers(request));
  }, []);

  useEffect(() => {
    const request: LookupStatusProps = populateFilters();
    dispatch(fetchLookupStatusData(request));
  }, [
    page,
    pageCount,
    sort,
    filter,
    updatedStatusResults,
    modeller,
    startDate,
    lastUpdatedDate,
    monitoredDate,
  ]);

  useEffect(() => {
    setModeller([{ value: currentUser.username, label: currentUser.username }]);
  }, [currentUser]);

  return (
    <Row key={'lookup'} id="lookup" className="d-flex justify-content-between">
      <span className="w-100 text-left ml-1 mt-1 mb-3 d-flex justify-content-between">
        <h3>Lookup Monitoring Status</h3>
        <span
          className="d-flex justify-content-around h6 align-items-center mr-3"
          style={{ width: '34.3%' }}
        >
          <h5>Filter by Modeller :</h5>
          <span style={{ zIndex: 4, width: '60%' }}>
            <MultiSelect
              options={workers.map(worker => {
                return { label: worker.userName, value: worker };
              })}
              className="w-100 border border-dark rounded ml-3"
              value={modeller}
              labelledBy="Search Modeller"
              hasSelectAll={false}
              disabled={fetchApiStatus === 'pending'}
              onChange={setModeller}
            />
          </span>
        </span>
      </span>
      <span className="d-flex justify-content-between w-100 align-items-center">
        <span>
          <Checkbox
            checked={filter.expiredFlag ? true : false}
            className="ml-2"
            onChange={() => {
              setFilter(prev => {
                return { ...prev, expiredFlag: !prev.expiredFlag };
              });
              setPage(1);
            }}
            disabled={fetchApiStatus === 'pending'}
          >
            Show Expired Lookups Only
          </Checkbox>
        </span>
        <span className="d-flex justify-content-between align-items-center">
          <Text color="primary" opacity="high" tag="p" textStyle="paragraph400" className="mr-2">
            Showing {(page - 1) * pageCount + 1} - {Math.min(page * pageCount, count)} of
            {` ${count}`} entries
          </Text>
          <span className="pt-3">
            <Pagination
              activePage={page}
              totalPages={totalPageCount}
              onSelectPage={selectedPage => setPage(selectedPage)}
            />
          </span>
          <Button
            size="medium"
            className="ml-3"
            onClick={exportFunction}
            style={{ minWidth: '110px', height: '45px' }}
            loading={exportLoad}
          >
            <Icon icon="UIDownload" color="accent1" className="mr-1" />
            Export
          </Button>
        </span>
      </span>
      <span
        onClick={() => {
          setFilter({} as OpsGlobalFilter);
          setStartDate(null);
          setLastUpdatedDate(null);
          setMonitoredDate(null);
          setLocalFilter({});
        }}
        className="ml-2"
        style={{ textDecoration: 'underLine', color: '#009933', cursor: 'pointer' }}
      >
        Clear all filters
      </span>
      <Table
        striped
        className="text-center mw-100 mt-4 mr-1"
        style={{ wordBreak: 'break-word' }}
        key="lookupConfig"
      >
        <thead>
          <tr className="d-flex justify-content-around">
            {lookupStatusHeaders.map(({ key, label, search }) => (
              <Th
                key={key}
                sortKey={key != 'edit' ? key : ''}
                sortConfig={{ sortOn: [sort.sortOn], sortOrder: [sort.sortOrder as SortOrder] }}
                className="d-flex align-items-center justify-content-center"
                style={
                  ['lseName', 'propertyKey'].includes(key)
                    ? { width: '10vw' }
                    : key == 'edit'
                    ? { width: '65px' }
                    : key == 'dueInDays'
                    ? { width: '7vw' }
                    : { width: '9vw' }
                }
                onClick={() => (key != 'edit' ? handleSort(key) : {})}
              >
                <span style={{ fontSize: '1.1rem' }}>{label}</span>
              </Th>
            ))}
          </tr>
        </thead>
        {fetchApiStatus === 'pending' || updateApiStatus === 'pending' ? (
          <tbody className="d-flex flex-column">
            <tr>
              {lookupStatusHeaders.map(({ key, search }) => (
                <td
                  style={
                    ['lseName', 'propertyKey'].includes(key)
                      ? { width: '10vw' }
                      : key == 'edit'
                      ? { width: '55px' }
                      : key == 'dueInDays'
                      ? { width: '7vw' }
                      : { width: '9vw' }
                  }
                  key={key}
                  className="align-middle"
                >
                  {search}
                </td>
              ))}
            </tr>
            <tr
              style={{ height: '20vh' }}
              className="d-flex justify-content-around align-items-center"
            >
              <td style={{ position: 'absolute', left: '50vw' }}>
                <Loader />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody className="d-flex flex-column">
            <tr>
              {lookupStatusHeaders.map(({ key, search }) => (
                <td
                  style={
                    ['lseName', 'propertyKey'].includes(key)
                      ? { width: '10vw' }
                      : key == 'edit'
                      ? { width: '55px' }
                      : key == 'dueInDays'
                      ? { width: '7vw' }
                      : { width: '9vw' }
                  }
                  key={key}
                  className="align-middle"
                >
                  {search}
                </td>
              ))}
            </tr>
            {results.map(lookupRow => (
              <tr key={lookupRow.monitoringInventoryId}>
                <td style={{ width: '10.5vw' }}>
                  <Link
                    target="_blank"
                    to={{
                      pathname: `${process.env.REACT_APP_MOTHER_URL}/lses/${lookupRow.lseId}`,
                    }}
                  >
                    <TextField
                      textValue={
                        lookupRow.lseId + (lookupRow.lseName ? '-' + lookupRow.lseName : '')
                      }
                      toolTipId={lookupRow.monitoringInventoryId + 'lse'}
                      maxSize={11}
                    />
                  </Link>
                </td>
                <td style={{ width: '10vw' }}>
                  <Link
                    target="_blank"
                    to={{ pathname: `directory/property-keys/${lookupRow?.propertyKey}` }}
                  >
                    <TextField
                      textValue={lookupRow.propertyKey}
                      toolTipId={lookupRow.monitoringInventoryId + lookupRow.propertyKey}
                      maxSize={12}
                      textStyle="paragraph500"
                    />
                  </Link>
                </td>
                <td style={{ width: '9vw' }}>{lookupRow.frequency || ''}</td>
                <td style={{ width: '9vw' }}>{lookupRow.source || ''}</td>
                <td style={{ width: '9vw' }}>
                  <TextField
                    textValue={lookupRow.effectiveStartDate?.split('T')[0] || ''}
                    toolTipId={lookupRow.monitoringInventoryId + 'lud'}
                    maxSize={12}
                    textStyle="paragraph500"
                  />
                </td>
                <td style={{ width: '9vw' }}>
                  <TextField
                    textValue={lookupRow.lastUpdatedDate?.split('T')[0] || ''}
                    toolTipId={lookupRow.monitoringInventoryId + 'esd'}
                    maxSize={12}
                    textStyle="paragraph500"
                  />
                </td>
                {/* <td style={{ width: '9vw' }}>
                    <TextField
                      textValue={lookupRow.nextExpectedPostDate?.split('T')[0] || ''}
                      toolTipValue={
                        (lookupRow.nextExpectedPostDate?.split('T')[0] || '') +
                        ' / ' +
                        (lookupRow.nextExpectedPostDate?.split('T')[1] || '')
                      }
                      toolTipId={lookupRow.monitoringInventoryId + 'nepd'}
                      maxSize={12}
                      textStyle="paragraph500"
                    />
                  </td> */}
                <td style={{ width: '7vw' }}>
                  <span className="d-flex justify-content-center">
                    <TextField
                      textValue={lookupRow.dueInDays.toString()}
                      color={
                        isNaN(Number(appConstants.statusScreen.dueInDays))
                          ? 'accent1'
                          : lookupRow.dueInDays >= Number(appConstants.statusScreen.dueInDays)
                          ? 'successBright'
                          : lookupRow.dueInDays < -Number(appConstants.statusScreen.dueInDays)
                          ? 'errorBright'
                          : 'warningBright'
                      }
                      toolTipValue={'Nepd : ' + lookupRow.nextExpectedPostDate?.split('T')[0]}
                      toolTipId={lookupRow.monitoringInventoryId + 'due'}
                      maxSize={9}
                      textStyle="heading600"
                    />
                  </span>
                </td>
                <td style={{ width: '9vw' }}>
                  {lookupRow.monitoringInventoryId != statusEditMode ? (
                    <TextField
                      textValue={lookupRow.monitoringStatus.monitoringStatus || ''}
                      toolTipId={lookupRow.monitoringInventoryId + 'status'}
                      maxSize={9}
                      textStyle="paragraph500"
                    />
                  ) : (
                    <DropdownSelect
                      popoverStyle={{ zIndex: 10 }}
                      selected={editedRow?.status || ''}
                      onChange={value => {
                        setEditedRow({
                          status: value,
                          notes: editedRow?.notes || lookupRow.monitoringStatus.monitoringNotes,
                        });
                      }}
                      setSelected={value => {
                        setEditedRow({
                          status: value as UserMonitoringStatusType,
                          notes: editedRow?.notes || lookupRow.monitoringStatus.monitoringNotes,
                        });
                      }}
                      options={
                        Object.keys(UserMonitoringStatusType).filter(
                          key =>
                            !editedRow?.status?.length ||
                            key.includes(editedRow.status.toUpperCase())
                        ).length > 0
                          ? Object.keys(UserMonitoringStatusType).filter(
                              key =>
                                !editedRow?.status?.length ||
                                key.includes(editedRow.status.toUpperCase())
                            )
                          : Object.keys(UserMonitoringStatusType)
                      }
                      label=""
                    />
                  )}
                </td>
                <td style={{ width: '9vw' }}>
                  {lookupRow.monitoringInventoryId != statusEditMode ? (
                    <TextField
                      textValue={lookupRow.monitoringStatus.monitoringNotes || ''}
                      toolTipId={lookupRow.monitoringInventoryId + 'moniNotes'}
                      maxSize={10}
                      textStyle="paragraph500"
                    />
                  ) : (
                    <Input
                      name="notes"
                      value={editedRow?.notes}
                      errorText={!editedRow?.notes ? 'Please enter Monitoring Notes' : ''}
                      onChange={value => {
                        setEditedRow({
                          notes: value,
                          status: editedRow?.status || lookupRow.monitoringStatus.monitoringStatus,
                        });
                        return;
                      }}
                      label=""
                      placeholder="Search"
                    />
                  )}
                </td>
                <td style={{ width: '9vw' }}>
                  <TextField
                    textValue={
                      new Date(lookupRow.monitoringStatus.monitoredDate)
                        ?.toLocaleString('sv-SE')
                        .split(' ')[0] || ''
                    }
                    toolTipId={lookupRow.monitoringInventoryId + 'moniDate'}
                    toolTipValue={new Date(
                      lookupRow.monitoringStatus.monitoredDate
                    )?.toLocaleString('sv-SE')}
                    maxSize={12}
                    textStyle="paragraph500"
                  />
                </td>
                <td className="pl-4">
                  {statusEditMode != lookupRow.monitoringInventoryId ? (
                    <IconButton
                      aria-label="edit"
                      icon="Pencil"
                      onClick={() => {
                        setStatusEditMode(lookupRow.monitoringInventoryId);
                        setEditedRow(prev => {
                          return {
                            status: lookupRow.monitoringStatus.monitoringStatus,
                            notes: lookupRow.monitoringStatus.monitoringNotes,
                          };
                        });
                      }}
                    />
                  ) : (
                    <>
                      <IconButton
                        aria-label="save"
                        icon="Checkmark"
                        disabled={!editedRow?.notes}
                        onClick={() => {
                          if (!editedRow) {
                            return;
                          }
                          setStatusEditMode(0);
                          dispatch(startUpdateMonitoringStatusLoading());
                          dispatch(
                            updateMonitoringStatus([
                              {
                                monitoringInventoryId: lookupRow.monitoringInventoryId,
                                monitoringNotes: editedRow.notes || '',
                                monitoringStatus: editedRow.status as MonitoringStatusType,
                              },
                            ])
                          );
                        }}
                      />
                      <IconButton
                        aria-label="cancel"
                        className="mt-1"
                        icon="UIClose"
                        onClick={() => {
                          setStatusEditMode(0);
                          setEditedRow(undefined);
                        }}
                      />
                    </>
                  )}
                </td>
                {/* <td style={{ width: '10vw' }} key="modeller">
                    <TextField
                      textValue={lookupRow.modeller?.split('@')[0] || ''}
                      toolTipId={lookupRow.monitoringInventoryId + lookupRow.propertyKey + 'MO'}
                      maxSize={12}
                      textStyle="paragraph500"
                    />
                  </td> */}
                <td style={{ width: '9vw' }} key="history">
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => {
                      setShowMonitoringHistory(true);
                      setMonitoringInventoryId(lookupRow?.monitoringInventoryId);
                      setEntityId(lookupRow?.propertyKey);
                    }}
                  >
                    View
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
      <Row className="d-flex justify-content-center w-100 mt-1">
        <span className="d-flex justify-content-center w-100 mt-1">
          <Text
            color="primary"
            opacity="high"
            tag="p"
            textStyle="paragraph400"
            className="mt-2 mr-2"
          >
            Showing {(page - 1) * pageCount + 1} - {Math.min(page * pageCount, count)} of
            {` ${count}`} entries
          </Text>
          <Pagination
            activePage={page}
            totalPages={totalPageCount}
            onSelectPage={selectedPage => setPage(selectedPage)}
          />
        </span>
      </Row>
      {showMonitoringHistory && (
        <MonitoringHistoryModal
          show={showMonitoringHistory}
          onHide={() => setShowMonitoringHistory(false)}
          monitoringInventoryId={monitoringInventoryId}
          entityType={EntityType.LOOKUP}
          entityID={entityID}
        />
      )}
    </Row>
  );
};

export default LookupStatusTable;
