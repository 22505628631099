import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { restClient } from '@genability/api';

import { RootState } from '../rootReducer';
import { TaskApiClient } from '../../GenApiClient';
import { handleUnexpectedThunkException } from '../reduxUtils';
import { GetConfigRequest, IReportingConfig } from '../../task-api/types/reporting-config';

export interface ReportingConfigState {
  apiStatus: 'idle' | 'pending' | 'notfound' | 'resolved' | 'rejected';
  reportingConfigMap: Record<string, IReportingConfig>;
  errors: restClient.ResponseError[] | undefined;
}

const initialState: ReportingConfigState = {
  apiStatus: 'idle',
  reportingConfigMap: {
    total_cost_breach_threshold_annualcalc_wf: {
      name: 'total_cost_breach_threshold_annualcalc_wf',
      type: 'number',
      value: 0.05,
    },
  },
  errors: undefined,
};

export const getReportingConfig = createAsyncThunk(
  'reportingConfig/getReportingConfig',
  async (request: GetConfigRequest, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiClient();
      const response = await client.reportingConfig.getReportingConfig(request.name);
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
      return response;
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'Task', dispatch));
    }
  }
);

export const reportingConfigSlice = createSlice({
  name: 'reportingConfig',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getReportingConfig.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(getReportingConfig.fulfilled, (state, action) => {
      const { results, errors } = action.payload;
      if (errors) {
        state.apiStatus = 'rejected';
        state.errors = errors;
      } else {
        state.apiStatus = 'resolved';
        state.reportingConfigMap = results.reduce((acc, curr) => {
          acc[curr.name] = curr;
          return acc;
        }, {} as Record<string, IReportingConfig>);
      }
    });
    builder.addCase(getReportingConfig.rejected, state => {
      state.apiStatus = 'rejected';
    });
  },
});

export const selectTotalCostBreachThreshold = (state: RootState): IReportingConfig => {
  return state.reportingConfig.reportingConfigMap['total_cost_breach_threshold_annualcalc_wf'];
};

export default reportingConfigSlice.reducer;
