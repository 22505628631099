import React from 'react';
import { Icon as ShrikeIcon, lightTheme } from '@arcadiapower/shrike';
import { types } from '@genability/api';
import { AiRateInfo } from '../../task-api/v2/types/AiRateInfo';
import { Col } from 'react-bootstrap';
import { updateRateEditAnalytics } from '../../utils/analyticsUtil';

interface RevertAiValueProps {
  taskAssignmentId?: number;
  tariffRate: types.TariffRate;
  rateBandIndex: number;
  onRevertAiRate?: (modifyRate: types.TariffRate, answerValue: string | null) => void;
  appliedAiRates?: AiRateInfo[];
  revertedAiValue?: AiRateInfo;
  appliedAiValueRate: AiRateInfo;
  appliedAiAnswerId?: number;
  className?: string;
}
const RevertAiValue: React.FC<RevertAiValueProps> = ({
  tariffRate,
  rateBandIndex,
  appliedAiValueRate,
  appliedAiRates,
  taskAssignmentId,
  onRevertAiRate,
  appliedAiAnswerId,
  className,
}) => {
  const handleClick = () => {
    const updatedRateBands = tariffRate?.rateBands?.map((originalRateBand, index) => {
      if (index === rateBandIndex) {
        const rateAmount = appliedAiValueRate.previousRateValue;
        return {
          ...originalRateBand,
          rateAmount: rateAmount,
        };
      }
      return originalRateBand;
    });
    const updatedTariffRate = { ...tariffRate, rateBands: updatedRateBands };

    const updatedAppliedAiRates = appliedAiRates?.map(rate => {
      if (rate === appliedAiValueRate) {
        return {
          ...rate,
          isAiValueApplied: false,
        };
      }
      return rate;
    });
    const updatedRecord = updatedRateBands?.find((rateBand, index) => index === rateBandIndex);
    if (taskAssignmentId !== undefined && taskAssignmentId !== null) {
      updateRateEditAnalytics(
        'ai_value_roll_back',
        taskAssignmentId,
        updatedRecord?.tariffRateBandId,
        updatedTariffRate.rateName
      );
      if (onRevertAiRate) onRevertAiRate(updatedTariffRate, JSON.stringify(updatedAppliedAiRates));
    }
  };

  return (
    <Col xs={1} className={`mr-2 ${className}`} onClick={handleClick} style={{ cursor: 'pointer' }}>
      <ShrikeIcon
        icon="UIRefresh"
        opacity="high"
        color="error"
        scale={3}
        style={{
          transform: 'scaleX(-1)',
        }}
        title="Revert back applied Ai Value"
      />
    </Col>
  );
};

export default RevertAiValue;
