import { ActionType } from '../task-api/v2/types/ActionType';
import { WorkFlowConfigResponse } from '../task-api/v2/types/WorkflowConfigResponse';
import { AssignmentTypes } from './constants';

export const allowAssignment = (
  wfConfigState?: string | null,
  wfConfig?: string,
  assignmentType?: string,
  currentUser?: string | null,
  assignedUser?: string | null,
  existingConfigDetails?: { [key: string]: WorkFlowConfigResponse }
): boolean => {
  const configState = wfConfigState || '';
  const config = wfConfig || '';
  const allowedActions = existingConfigDetails?.[config]?.states[configState]?.allowedActions;
  if (allowedActions) {
    if (assignmentType == AssignmentTypes.MODELLER) {
      return (
        (!assignedUser || assignedUser === currentUser) &&
        allowedActions?.includes(ActionType.ASSIGN)
      );
    } else {
      return allowedActions?.includes(ActionType.ASSIGNTOREVIEWER);
    }
  } else {
    return false;
  }
};
