import { restClient } from '@genability/api';
import { ResourceDiff } from '../types/resource-diff';

export class TariffAdminApi extends restClient.RestApiClient {
  public async getTariffDiff(
    baseTariffId: number,
    comparisonTariffId: number
  ): Promise<restClient.SingleResponse<ResourceDiff>> {
    return this.getSingle(`/v1/tariffs/${baseTariffId}/diff/${comparisonTariffId}?diffById=true`);
  }
}
