import GlobalNavBar from './layout/GlobalNavBar';
import Completed from './pages/Completed';
import React from 'react';
import Do from './pages/Do';
import Draft from './pages/Draft';
import Review from './pages/Review';
import UserProfile from './pages/UserProfile/UserProfile';
import Dashboard from './pages/Dashboard/Dashboard';
import { Switch, Route, Redirect } from 'react-router-dom';
import appConstants from './app-constants';
import OpsManagement from './pages/OpsManagement/OpsManagementTabs';

const MasterRoute: React.FC = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to={appConstants.routes.dashboard} />;
          }}
        />
        <Route path={appConstants.routes.dashboard}>
          <Dashboard />
        </Route>
        <Route path={appConstants.routes.completed}>
          <Completed />
        </Route>
        <Route path={appConstants.routes.do}>
          <Do />
        </Route>
        <Route path={appConstants.routes.opsManagement}>
          <OpsManagement />
        </Route>
        <Route exact path={appConstants.routes.draft}>
          <Draft />
        </Route>
        <Route path={appConstants.routes.review}>
          <Review />
        </Route>
        <Route path={appConstants.routes.settings}>
          <UserProfile />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default MasterRoute;
