export enum TaskStatusType {
  DRAFTING = 'DRAFTING',
  READY_TO_POST = 'READY_TO_POST',
  POSTED = 'POSTED',
  READY_TO_REVIEW = 'READY_TO_REVIEW',
  REVIEWING = 'REVIEWING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PUBLISHED = 'PUBLISHED',
  CLOSED = 'CLOSED',
}
