import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { restClient } from '@genability/api';
import { OpsManagementApiClient } from '../../../GenApiClient';
import {
  LookupDataRequestParams,
  LookupConfigData,
} from '../../../components/OpsManagement/Config/LookupConfigTable';
import { addNotification } from '../../notification/notificationSlice';
import { handleUnexpectedThunkException } from '../../reduxUtils';
import { RootState } from '../../rootReducer';
import { UserAssignmentConfig } from './TariffConfigSlice';

export interface LookupRequestProps {
  request: LookupConfigDataProps;
  params: LookupDataRequestParams;
}
export interface UpdatedLookupConfigDataResults {
  data: LookupConfigData;
  status: string;
  errors?: string;
}
export interface UpdateLookupConfigRequest {
  selectAllMatchingRecords: boolean;
  showUnassignedLookups?: boolean;
  getLookupUserConfigRequest?: LookupConfigDataProps;
  postLookupUserConfigRequest: UpdateLookupConfigProps[];
}
export interface UpdateLookupConfigProps {
  monitoringInventoryId: number;
  propertykey: string;
  userAssignmentConfig?: UserAssignmentConfig;
  frequency?: string;
  source?: string;
}
export interface LookupConfigDataProps {
  paginationCriteria?: { pageCount: number; pageStart: number };
  sortCriteria: { sortOn: string[]; sortOrder: string[] };
  lookupUserConfigFilterCriteria: {
    frequency?: string[];
    propertyKey?: string[];
    primaryOwnerId?: number[];
    modellerId?: number[];
    reviewerId?: number[];
    source?: string[];
    lseNames?: string[];
    lseId?: number[];
  };
}
export interface LookupConfigDataResponse {
  results: LookupConfigData[];
  updatedEntityResults: UpdatedLookupConfigDataResults[];
  fetchApiStatus: 'idle' | 'pending' | 'resolved' | 'rejected';
  updateApiStatus: 'idle' | 'pending' | 'resolved' | 'rejected';
  count: number;
  errors: restClient.ResponseError[] | undefined;
}
export const initialState: LookupConfigDataResponse = {
  results: [],
  updatedEntityResults: [],
  fetchApiStatus: 'idle',
  updateApiStatus: 'idle',
  count: 0,
  errors: undefined,
};

export const updateLookupConfigData = createAsyncThunk(
  'workflowManagement/config/updateLookupConfig',
  async (request: UpdateLookupConfigRequest, { dispatch, rejectWithValue }) => {
    try {
      const client = await OpsManagementApiClient();
      let response: restClient.PagedResponse<UpdatedLookupConfigDataResults>;
      if (request.selectAllMatchingRecords) {
        response = await client.opsData.updateLookupConfigDataForAllMatches(request);
      } else {
        response = await client.opsData.updateLookupConfigData(request.postLookupUserConfigRequest);
      }
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
      let successCount = 0;
      response.results.forEach(row => {
        if (!row.errors) {
          successCount += 1;
        }
      });
      dispatch(
        addNotification(
          successCount +
            ' of ' +
            response.results.length +
            ' Assignments Updated Successfully !!!!',
          NotificationLevel.Success
        )
      );
      return response;
    } catch (err) {
      dispatch(addNotification('Assignment Failed', NotificationLevel.Error));
      return rejectWithValue(handleUnexpectedThunkException(err, 'UpdateLookupConfig', dispatch));
    }
  }
);

export const fetchLookupConfigData = createAsyncThunk(
  'workflowManagement/config/lookupConfig',
  async ({ request, params }: LookupRequestProps, { dispatch, rejectWithValue }) => {
    try {
      const client = await OpsManagementApiClient();
      const response: restClient.PagedResponse<LookupConfigData> =
        await client.opsData.fetchLookupConfigData(request, params);
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
      return response;
    } catch (err) {
      dispatch(addNotification('Fetching LookupConfigData Failed', NotificationLevel.Error));
      return rejectWithValue(handleUnexpectedThunkException(err, 'LookupConfig', dispatch));
    }
  }
);

export const LookpuConfigAssignmentSlice = createSlice({
  name: 'LookupConfigAssignment',
  initialState,
  reducers: {
    startLookupLoading: state => {
      state.updateApiStatus = 'pending';
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchLookupConfigData.pending, state => {
      state.fetchApiStatus = 'pending';
    });
    builder.addCase(
      fetchLookupConfigData.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<LookupConfigData>>) => {
        const { count, results, errors } = action.payload;
        if (errors) {
          state.fetchApiStatus = 'rejected';
        } else {
          state.fetchApiStatus = 'resolved';
          state.count = count;
          state.results = results;
        }
      }
    );
    builder.addCase(
      updateLookupConfigData.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<UpdatedLookupConfigDataResults>>) => {
        const { count, results, errors } = action.payload;
        if (errors) {
          state.updateApiStatus = 'rejected';
          state.errors = errors;
        } else {
          state.fetchApiStatus = 'pending';
          state.updateApiStatus = 'resolved';
          state.count = count;
          state.updatedEntityResults = results;
          state.errors = undefined;
        }
      }
    );
    builder.addCase(fetchLookupConfigData.rejected || updateLookupConfigData.rejected, state => {
      state.fetchApiStatus = 'rejected';
      state.updateApiStatus = 'rejected';
    });
  },
});

export default LookpuConfigAssignmentSlice.reducer;

export const { startLookupLoading } = LookpuConfigAssignmentSlice.actions;

export const selectLookupConfigData = (state: RootState): LookupConfigDataResponse => {
  return state.lookupConfigData;
};
