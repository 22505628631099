import { Table } from '@arcadiapower/gen-react-lib';
import React from 'react';
import styles from './StartTariffPanel.module.scss';
import { TaskTypeId } from '../../../task-api/types/task';
import { types } from '@genability/api';
import { useSelector } from 'react-redux';
import { selectTaskAssignmentTariff } from '../../../state/taskAssignmentTariffs/taskAssignmentTariffsSlice';
import { DoStartPanelProps } from '../work-body';
import PanelNavigationButtons from '../PanelNavigationButtons/PanelNavigationButtons';

const StartTariffPanel: React.FC<DoStartPanelProps> = ({
  taskAssignment,
  onNext,
}: DoStartPanelProps) => {
  const tariff: types.Tariff | undefined = useSelector(selectTaskAssignmentTariff);
  if (!tariff) return null;
  const tariffOrRider = tariff?.tariffType === types.TariffType.RIDER ? 'Rider' : 'Tariff';
  let panelTitle = `Version a ${tariffOrRider}`;
  let entityIdFieldContains = 'document section';
  let assignmentTariffPreamble = `The ${tariffOrRider} to be versioned is:`;
  if (taskAssignment?.taskTypeId === TaskTypeId['03_02']) {
    panelTitle = `Add a ${tariffOrRider}`;
    assignmentTariffPreamble = `The ${tariffOrRider} to be added is:`;
  } else if (taskAssignment?.taskTypeId === TaskTypeId['03_04']) {
    panelTitle = `Edit a ${tariffOrRider}`;
    entityIdFieldContains = 'Tariff ID';
    assignmentTariffPreamble = `The ${tariffOrRider} to be edited is:`;
  }

  return (
    <React.Fragment>
      <h2>{panelTitle}</h2>
      <div className={styles.section}>
        <p>
          Based on {entityIdFieldContains} {taskAssignment?.task?.entityId} and task input values:
        </p>

        <Table>
          <tbody>
            {taskAssignment?.task?.taskInputs?.map(taskInput => {
              return (
                <tr key={taskInput.taskInputId}>
                  <td>{taskInput.inputKey}</td>
                  <td>{taskInput.inputValue}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      <div className={styles.section}>
        <p>{assignmentTariffPreamble}</p>
        <Table>
          <tbody>
            <tr>
              <td>MTID</td>
              <td>{tariff?.masterTariffId ?? 'n/a'}</td>
            </tr>
            <tr>
              <td>Tariff Name</td>
              <td>{tariff?.tariffName}</td>
            </tr>
            <tr>
              <td>Tariff Type</td>
              <td>{tariff?.tariffType}</td>
            </tr>
            <tr>
              <td>LSE ID</td>
              <td>{tariff?.lseId}</td>
            </tr>
            <tr>
              <td>LSE Name</td>
              <td>{tariff?.lseName}</td>
            </tr>
          </tbody>
        </Table>
      </div>

      <div className={styles.section}>
        {taskAssignment?.task?.previousTaskId ? (
          <p>This task follows on from Task ID {taskAssignment?.task?.previousTaskId}.</p>
        ) : (
          <p>This task does not have a previous Task ID.</p>
        )}
      </div>

      <div className="mt-3">
        <PanelNavigationButtons
          onClickNext={onNext}
          nextLabel={'Next'}
          noChangesChecked={true}
          disabled={!tariff}
        />
      </div>
    </React.Fragment>
  );
};

export default StartTariffPanel;
