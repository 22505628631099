import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, Switch, Route } from 'react-router';
import MasterRoute from './MasterRoute';
import appConstants from './app-constants';
import NotificationQueue from './components/NotificationQueue/NotificationQueue';
import PropertyKeyDirectory from './components/PropertyKeyDirectory/PropertyKeyDirectory';
import TariffDiff from './components/TariffDiff';
import CompletedDetail from './pages/Completed/CompletedDetail';
import DoDetail from './pages/Do/DoDetail';
import ReviewDetail from './pages/Review/ReviewDetail';
import DraftDetail from './pages/Draft/DraftDetail';
import TaskDetail from './pages/TaskDetails/TaskDetail';
import { PropertyKey } from './pages/directory/PropertyKey/PropertyKey';
import TariffDetail from './pages/directory/TariffDetail';
import LoadServingEntity from './pages/directory/LoadServingEntity/LoadServingEntity';
import TariffCalculatorParameterParser from './pages/directory/Tariffs/TariffCalculatorParameterParser/TariffCalculatorParameterParser';
import Tariffs from './pages/directory/Tariffs/Tariffs';
import Utilites from './pages/directory/Utilities/Utilities';
import TariffCalcSummaryViewer from './pages/directory/Tariffs/TariffCalcSummaryViewer/TariffCalcSummaryViewer';
import GlobalNavBar from './layout/GlobalNavBar';
import ConfigTab from './pages/OpsManagement/ConfigTab';
import StatusMonitoringTab from './pages/OpsManagement/StatusMonitoringTab';
import ReportingTab from './pages/OpsManagement/ReportingTab';
import OpsManagement from './pages/OpsManagement/OpsManagementTabs';

export const AuthenticatedApp: React.FC = (props: any) => {
  const location = useLocation();

  // Segment Analytics send page event
  useEffect(() => {
    if (!location) return;
    analytics.page(location.pathname);
  }, [location]);

  return (
    <div className="App">
      <Helmet {...props}>
        <title>Team Tariff</title>
        <meta name="description" content="Team Tariff" />
      </Helmet>
      <NotificationQueue maxAlerts={3} />
      <div id="navBar" style={{ marginBottom: '20px' }}>
        <GlobalNavBar />
      </div>

      <div id="mainContent">
        <Switch>
          <Route
            exact
            path={`${appConstants.routes.draft}/:taskId`}
            render={props => <DraftDetail key={props.match.params.taskId} {...props} />}
          />
          <Route
            exact
            path={`${appConstants.routes.do}/:taskAssignmentId/:workPanel?`}
            render={props => <DoDetail key={props.match.params.taskAssignmentId} {...props} />}
          />
          <Route
            exact
            path={`${appConstants.routes.review}/:taskId`}
            render={props => <ReviewDetail key={props.match.params.taskId} {...props} />}
          />
          <Route
            exact
            path={`${appConstants.routes.completed}/:taskId`}
            render={props => <CompletedDetail key={props.match.params.taskId} {...props} />}
          />
          <Route
            exact
            path={`${appConstants.routes.directory}/property-keys`}
            render={props => <PropertyKeyDirectory key="propertyKeysDirectory" {...props} />}
          />
          <Route
            exact
            path={`${appConstants.routes.directory}/property-keys/:propertyKeyName`}
            render={props => <PropertyKey {...props} />}
          />
          <Route
            exact
            path={`${appConstants.routes.directory}/tariffs`}
            render={props => <Tariffs key="tariffsDirectory" {...props} />}
          />
          <Route
            path={`${appConstants.routes.directory}/tariffs/calcSummary`}
            render={props => <TariffCalcSummaryViewer key="tariffCalcSummary" {...props} />}
          />
          <Route
            exact
            path={`${appConstants.routes.directory}/tariffs/:masterTariffId/:detailComponent?`}
            render={props => {
              const { detailComponent } = props.match.params;
              const isDiff = detailComponent?.startsWith('diff');
              const isCalculate = detailComponent?.startsWith('calculate');
              if (isDiff) {
                return <TariffDiff />;
              } else if (isCalculate) {
                return <TariffCalculatorParameterParser key="calculatePage" />;
              }
              return <TariffDetail {...props} />;
            }}
          />
          <Route
            exact
            path={`${appConstants.routes.directory}/utilities`}
            render={props => <Utilites {...props} />}
          />
          <Route
            exact
            path={`${appConstants.routes.directory}/lse`}
            render={props => <LoadServingEntity {...props} />}
          />
          <Route
            exact
            path={`${appConstants.routes.directory}/lses/:lseId`}
            render={props => <LoadServingEntity />}
          />
          <Route
            exact
            path={`${appConstants.routes.taskDetails}/:taskId`}
            render={props => <TaskDetail {...props} />}
          />
          <Route path="/" component={MasterRoute} />
        </Switch>
      </div>
    </div>
  );
};
