import { Checkbox, Button, IconButton, Text, Icon, Input } from '@arcadiapower/shrike';
import { Loader, NotificationLevel, Pagination, Th } from '@arcadiapower/gen-react-lib';
import React, { ReactElement, useState, useCallback, useEffect } from 'react';
import { IWorker } from '../../../task-api/types/worker';
import WorkerInput from '../../WorkerInput/WorkerInput';
import { QualificationType } from '../../../task-api/v2/types/QualifictionType';
import { Row, Table } from 'react-bootstrap';
import BulkAssignConfig from '../BulkAssignConfig';
import { SortOrder } from '@genability/api/dist/rest-client';
import TextField from '../../TextField/TextField';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { OpsFilterProps, OpsGlobalFilter } from '../../../pages/OpsManagement/OpsManagementTabs';
import { OpsManagementApiClient } from '../../../GenApiClient';
import { addNotification } from '../../../state/notification/notificationSlice';
import { doQuickSearch } from '../../../state/QuickSearch/QuickSearchSlice';
import QuickSearch, { QuickSearchProps } from '../../QuickSearchComponent/QuickSearch';
import {
  selectTariffConfigData,
  TariffConfigDataProps,
  fetchTariffConfigData,
  startTariffLoading,
  updateTariffConfigData,
} from '../../../state/OpsManagement/Config/TariffConfigSlice';
import { fetchTariffConfigStats } from '../../../state/OpsManagement/Config/TariffConfigStatsSlice';
import DropdownSelect from '../../DropdownSelect/DropdownSelect';
import { OpsManagementLocalFilterProps } from '../Status/LookupStatusTable';

export interface TariffDataRequestParams {
  showInActiveTariff: boolean;
  showUnassignedTariff: boolean;
}
export interface TariffConfigData {
  userMappingId: number | undefined | undefined;
  monitoringInventoryId: number;
  primaryOwnerId: number | undefined;
  modellerId: number | undefined;
  reviewerId: number | undefined;
  lseId: number;
  lseName: string;
  tariffName: string;
  tariffCode: string;
  tariffPriority: string;
  tariffTier: string;
  customerTier: string;
  masterTariffId: number;
  primaryOwnerUserName: string | undefined;
  modellerUserName: string | undefined;
  reviewerName: string | undefined;
  utilityWebsite: string;
  getTariffCount: number;
  onDemandCount: number;
  savingsAnalysisCount: number;
}

export const tariffTiers = [
  'Tier-0',
  'Tier-1',
  'Tier-2',
  'Tier-3',
  'Tier-4',
  'Tier-5',
  'Tier-6',
  'Tier-7',
];
export const customerTiers = ['C0', 'A0', 'C1', 'C2', 'C3', 'C4', 'Legacy TP'];
export const tariffPriorities = [
  'C0',
  'P1',
  'P2',
  'P3',
  'P4',
  'P5',
  'P6',
  'P7',
  'P8',
  'P9',
  'P10',
  'P11',
  'P12',
  'P13',
  'P14',
  'P15',
];

const TariffConfigTable = ({
  filter,
  setFilter,
  currentUserIsSME,
  userListLoading,
}: OpsFilterProps): ReactElement => {
  const dispatch = useDispatch();
  const {
    results: tariffValues,
    updatedEntityResults,
    fetchApiStatus,
    updateApiStatus,
    count,
  } = useSelector(selectTariffConfigData);
  const [params, setParams] = useState<TariffDataRequestParams>({
    showUnassignedTariff: false,
    showInActiveTariff: false,
  });
  const [editedRow, setEditedRow] = useState<TariffConfigData | undefined>(undefined);
  const [localFilter, setLocalFilter] = useState<Partial<OpsManagementLocalFilterProps>>({});
  const [configEditMode, setConfigEditMode] = useState(0);
  const [sort, setSort] = useState({ sortOn: 'masterTariffId', sortOrder: 'ASC' });
  const [page, setPage] = useState(1);
  const pageCount = 50;
  const totalPageCount = Math.ceil(count / pageCount);
  const [exportLoad, setExportLoad] = useState<boolean>(false);
  const [tariffSelectedCount, setTariffSelectedCount] = useState(0);
  const [tariffSelectAll, setTariffSelectAll] = useState<boolean>(false);
  const [tariffSelected, setTariffSelected] = useState<boolean[]>(
    Array(tariffValues ? tariffValues.length : 0).fill(false)
  );

  const tariffLevelHeaders = [
    {
      key: 'selectall',
      label: 'Select All',
      search: null,
    },
    {
      key: 'lseId',
      label: 'Lse Id',
      search: (
        <QuickSearch
          popoverStyle={{ zIndex: 10 }}
          style={{ minWidth: '4vw' }}
          value={localFilter.lseId || filter.lseId || ''}
          onClick={() => {
            dispatch(doQuickSearch({ minChar: 1, key: 'lseId', value: localFilter.lseId }));
          }}
          onChange={value => {
            if (filter.lseId && value == '') {
              setFilter(prev => {
                return {
                  ...prev,
                  lseId: undefined,
                };
              });
            }
            if (isNaN(Number(value))) {
              return;
            }
            dispatch(
              doQuickSearch({ minChar: 1, key: 'lseId', value: value != '' ? value : undefined })
            );
            setLocalFilter({
              lseId: value != '' ? value : undefined,
            });
          }}
          onSelect={value => {
            setLocalFilter({
              lseId: value,
            });
            setPage(1);
            setFilter(prev => {
              return {
                ...prev,
                lseId: Number(value),
              };
            });
          }}
          placeholder="Search"
        />
      ),
    },
    {
      key: 'lseName',
      label: 'Lse Name',
      search: (
        <QuickSearch
          popoverStyle={{ zIndex: 10 }}
          value={localFilter.lseName || filter.lseName || ''}
          onClick={() => {
            dispatch(doQuickSearch({ minChar: 3, key: 'lseName', value: localFilter.lseName }));
          }}
          onChange={value => {
            if (filter.lseName && value == '') {
              setFilter(prev => {
                return {
                  ...prev,
                  lseName: undefined,
                };
              });
            }
            dispatch(
              doQuickSearch({ minChar: 3, key: 'lseName', value: value != '' ? value : undefined })
            );
            setLocalFilter({
              lseName: value != '' ? value : undefined,
            });
          }}
          onSelect={value => {
            setLocalFilter({
              lseName: value,
            });
            setPage(1);
            setFilter(prev => {
              return {
                ...prev,
                lseName: value,
              };
            });
          }}
          placeholder="Search"
        />
      ),
    },
    {
      key: 'tariffName',
      label: 'Tariff Name',
      search: (
        <QuickSearch
          popoverStyle={{ zIndex: 10 }}
          style={{ minWidth: '6vw' }}
          value={localFilter.tariffName || filter.tariffName || ''}
          onClick={() => {
            dispatch(
              doQuickSearch({ minChar: 3, key: 'tariffName', value: localFilter.tariffName })
            );
          }}
          onChange={value => {
            if (filter.tariffName && value == '') {
              setFilter(prev => {
                return {
                  ...prev,
                  tariffName: undefined,
                };
              });
            }
            dispatch(
              doQuickSearch({
                minChar: 3,
                key: 'tariffName',
                value: value != '' ? value : undefined,
              })
            );
            setLocalFilter({
              tariffName: value != '' ? value : undefined,
            });
          }}
          onSelect={value => {
            setLocalFilter({
              tariffName: value,
            });
            setPage(1);
            setFilter(prev => {
              return {
                ...prev,
                tariffName: value,
              };
            });
          }}
          placeholder="Search"
        />
      ),
    },
    {
      key: 'priorityLevel',
      label: 'Priority',
      search: (
        <DropdownSelect
          popoverStyle={{ zIndex: 10 }}
          style={{ minWidth: '4vw' }}
          selected={filter.priority}
          setSelected={value => {
            if (![...tariffPriorities, ''].includes(value || '')) {
              return;
            }
            setPage(1);
            setFilter(prev => {
              return { ...prev, priority: value };
            });
          }}
          options={tariffPriorities}
          label=""
          onChange={value =>
            value == '' &&
            setFilter(prev => {
              return { ...prev, priority: value };
            })
          }
        />
      ),
    },
    {
      key: 'tariffTier',
      label: 'T-Tier',
      search: (
        <DropdownSelect
          popoverStyle={{ zIndex: 10 }}
          style={{ minWidth: '4vw' }}
          selected={filter.tariffTier}
          setSelected={value => {
            if (![...tariffTiers, ''].includes(value || '')) {
              return;
            }
            setPage(1);
            setFilter(prev => {
              return { ...prev, tariffTier: value };
            });
          }}
          options={tariffTiers}
          label=""
          onChange={value =>
            value == '' &&
            setFilter(prev => {
              return { ...prev, tariffTier: value };
            })
          }
        />
      ),
    },
    {
      key: 'customerTier',
      label: 'C-Tier',
      search: (
        <DropdownSelect
          popoverStyle={{ zIndex: 10 }}
          style={{ minWidth: '4vw' }}
          selected={filter.customerTier}
          setSelected={value => {
            if (![...customerTiers, ''].includes(value || '')) {
              return;
            }
            setPage(1);
            setFilter(prev => {
              return { ...prev, customerTier: value };
            });
          }}
          options={customerTiers}
          label=""
          onChange={value =>
            value == '' &&
            setFilter(prev => {
              return { ...prev, customerTier: value };
            })
          }
        />
      ),
    },
    {
      key: 'masterTariffId',
      label: 'MTID',
      search: (
        <QuickSearch
          popoverStyle={{ zIndex: 10 }}
          value={localFilter.masterTariffId || filter.masterTariffId || ''}
          onClick={() => {
            dispatch(
              doQuickSearch({
                minChar: 1,
                key: 'masterTariffId',
                value: localFilter.masterTariffId,
              })
            );
          }}
          onChange={value => {
            if (filter.masterTariffId && value == '') {
              setFilter(prev => {
                return {
                  ...prev,
                  masterTariffId: undefined,
                };
              });
            }
            if (isNaN(Number(value))) {
              return;
            }
            dispatch(
              doQuickSearch({
                minChar: 1,
                key: 'masterTariffId',
                value: value != '' ? value : undefined,
              })
            );
            setLocalFilter({
              masterTariffId: value != '' ? value : undefined,
            });
          }}
          onSelect={value => {
            setLocalFilter({
              masterTariffId: value,
            });
            setPage(1);
            setFilter(prev => {
              return {
                ...prev,
                masterTariffId: Number(value),
              };
            });
          }}
          placeholder="Search"
        />
      ),
    },
    {
      key: 'primaryOwnerUserName',
      label: 'Owner',
      search: (
        <WorkerInput
          popoverStyle={{ zIndex: 10 }}
          label={''}
          placeholder="Search"
          id=""
          qualification={QualificationType.LOOKUP_MODELLER}
          selected={filter.owner ? filter.owner : ({ userName: '' } as IWorker)}
          setSelected={(assignee: IWorker | undefined) => {
            if (!assignee && !filter.owner) {
              return;
            }
            setPage(1);
            setFilter(prev => {
              return {
                ...prev,
                owner: assignee,
              };
            });
          }}
        />
      ),
    },
    {
      key: 'modellerUsername',
      label: 'Modeller',
      search: (
        <WorkerInput
          popoverStyle={{ zIndex: 10 }}
          label={''}
          placeholder="Search"
          id=""
          qualification={QualificationType.LOOKUP_MODELLER}
          selected={filter.modeller ? filter.modeller : ({ userName: '' } as IWorker)}
          setSelected={(assignee: IWorker | undefined) => {
            if (!assignee && !filter.modeller) {
              return;
            }
            setPage(1);
            setFilter(prev => {
              return {
                ...prev,
                modeller: assignee,
              };
            });
          }}
        />
      ),
    },
    {
      key: 'reviewerUsername',
      label: 'Reviewer',
      search: (
        <WorkerInput
          popoverStyle={{ zIndex: 10 }}
          label={''}
          placeholder="Search"
          id=""
          qualification={QualificationType.LOOKUP_REVIEWER}
          selected={filter.reviewer ? filter.reviewer : ({ userName: '' } as IWorker)}
          setSelected={(assignee: IWorker | undefined) => {
            setPage(1);
            setFilter(prev => {
              return { ...prev, reviewer: assignee };
            });
          }}
        />
      ),
    },
    {
      key: 'apiCount',
      label: 'Api Count',
      search: (
        <Input
          name="api count"
          style={{ minWidth: '4vw' }}
          value={localFilter.apiCountBuffer}
          onChange={value => {
            setLocalFilter(prev => {
              return { ...prev, apiCountBuffer: value };
            });
            if (filter.apiCount && value === '') {
              setFilter(prev => {
                return { ...prev, apiCount: undefined };
              });
            }
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              setFilter(prev => {
                return { ...prev, apiCount: localFilter.apiCountBuffer };
              });
            }
          }}
          label=""
          placeholder="Enter..."
        />
      ),
    },
    {
      key: 'edit',
      label: 'Edit',
      search: null,
    },
  ];

  const populateFilters = useCallback((): TariffConfigDataProps => {
    const request: TariffConfigDataProps = {
      paginationCriteria: { pageCount, pageStart: (page - 1) * pageCount },
      sortCriteria: { sortOn: [sort.sortOn], sortOrder: [sort.sortOrder] },
      tariffUserConfigFilterCriteria: {},
    };
    if (filter.modeller) {
      request.tariffUserConfigFilterCriteria.modellerId = [filter.modeller.workerId];
    }
    if (filter.owner) {
      request.tariffUserConfigFilterCriteria.primaryOwnerId = [filter.owner.workerId];
    }
    if (filter.reviewer) {
      request.tariffUserConfigFilterCriteria.reviewerId = [filter.reviewer.workerId];
    }
    if (filter.lseName) {
      request.tariffUserConfigFilterCriteria.lseNames = [filter.lseName];
    }
    if (filter.tariffCode) {
      request.tariffUserConfigFilterCriteria.tariffCode = [filter.tariffCode];
    }
    if (filter.priority) {
      request.tariffUserConfigFilterCriteria.tariffPriority = [filter.priority];
    }
    if (filter.tariffTier) {
      request.tariffUserConfigFilterCriteria.tariffTier = [filter.tariffTier];
    }
    if (filter.customerTier) {
      request.tariffUserConfigFilterCriteria.customerTier = [filter.customerTier];
    }
    if (filter.masterTariffId) {
      request.tariffUserConfigFilterCriteria.mtid = [filter.masterTariffId];
    }
    if (filter.tariffName) {
      request.tariffUserConfigFilterCriteria.tariffName = [filter.tariffName];
    }
    if (filter.apiCount) {
      request.tariffUserConfigFilterCriteria.apiCount = filter.apiCount;
    }
    if (filter.lseId) {
      request.tariffUserConfigFilterCriteria.lseId = [filter.lseId];
    }
    return request;
  }, [filter, sort, page, pageCount]);

  const exportFunction = async () => {
    setExportLoad(true);
    const request: TariffConfigDataProps = populateFilters();
    request.paginationCriteria = undefined;
    const client = await OpsManagementApiClient();
    const response = await client.opsData.exportTariffConfigData(request, params);
    if (response.errors) {
      dispatch(addNotification('Export Failed !', NotificationLevel.Error));
    } else {
      dispatch(addNotification('Exported Successfully !!!!', NotificationLevel.Success));
    }
    setExportLoad(false);
  };

  const tariffCheckboxSelection = useCallback(
    (id: number) => {
      if (tariffSelected[id]) setTariffSelectedCount(prev => prev - 1);
      else setTariffSelectedCount(prev => prev + 1);
      setTariffSelected(prev => {
        const current = prev.slice();
        current[id] = !current[id];
        return current;
      });
    },
    [tariffSelected]
  );

  const tariffSelectAllSelection = useCallback(() => {
    if (!tariffValues) return;
    setTariffSelectAll(prev => {
      if (prev) setTariffSelectedCount(0);
      else setTariffSelectedCount(tariffValues.length);
      setTariffSelected(Array(tariffValues ? tariffValues.length : 0).fill(!prev));
      return !prev;
    });
  }, [tariffSelectAll, tariffValues]);

  const handleSort = useCallback(
    (key: string) => {
      if (sort.sortOn != key || sort.sortOrder === 'DESC') {
        setSort(prev => {
          return { sortOn: key, sortOrder: 'ASC' };
        });
      } else {
        setSort(prev => {
          return { ...prev, sortOrder: 'DESC' };
        });
      }
    },
    [sort]
  );

  useEffect(() => {
    setEditedRow(undefined);
    setTariffSelectedCount(0);
    setTariffSelected(Array(tariffValues ? tariffValues.length : 0).fill(false));
    setTariffSelectAll(false);
    setConfigEditMode(0);
  }, [tariffValues]);

  useEffect(() => {
    dispatch(fetchTariffConfigStats({}));
  }, [updatedEntityResults]);

  useEffect(() => {
    const request: TariffConfigDataProps = populateFilters();
    dispatch(fetchTariffConfigData({ request, params }));
    setTariffSelectedCount(0);
  }, [page, sort, updatedEntityResults, filter, params]);

  return (
    <Row key={'tariff'} id="tariff" className="d-flex justify-content-between">
      <span className="w-100 d-flex align-items-center" style={{ height: '50px' }}>
        <h3>Tariff Assignment Configuration</h3>
        {tariffSelectedCount > 1 && (
          <BulkAssignConfig
            params={params}
            populateFilters={populateFilters}
            sortedItems={tariffValues}
            selected={tariffSelected}
            type="tariff"
            currentUserIsSME={currentUserIsSME}
            loading={userListLoading}
          />
        )}
      </span>
      <span className="d-flex justify-content-between w-100 align-items-center">
        <span className="d-flex justify-content-between align-items-center">
          <Checkbox
            checked={params.showInActiveTariff}
            className="ml-1"
            onChange={() =>
              setParams(prev => {
                return { ...prev, showInActiveTariff: !prev.showInActiveTariff };
              })
            }
            disabled={fetchApiStatus === 'pending'}
          >
            Include InActive Tariffs
          </Checkbox>
          <Checkbox
            checked={params.showUnassignedTariff}
            className="ml-5"
            onChange={() => {
              setPage(1);
              setParams(prev => {
                return { ...prev, showUnassignedTariff: !prev.showUnassignedTariff };
              });
            }}
            disabled={!currentUserIsSME || fetchApiStatus === 'pending'}
          >
            Show Unassigned Tariffs Only
          </Checkbox>
        </span>
        <span className="d-flex justify-content-between align-items-center">
          <Text color="primary" opacity="high" tag="p" textStyle="paragraph400" className="mr-2">
            Showing {(page - 1) * pageCount + 1} - {Math.min(page * pageCount, count)} of
            {` ${count}`} entries
          </Text>
          <span className="pt-3">
            <Pagination
              activePage={page}
              totalPages={totalPageCount}
              onSelectPage={selectedPage => setPage(selectedPage)}
            />
          </span>
          <Button
            size="medium"
            className="ml-3"
            onClick={exportFunction}
            style={{ minWidth: '110px', height: '45px' }}
            loading={exportLoad}
          >
            <Icon icon="UIDownload" color="accent1" className="mr-1" />
            Export
          </Button>
        </span>
      </span>
      <span
        onClick={() => {
          setFilter({} as OpsGlobalFilter);
          setLocalFilter({});
          setParams(prev => {
            return { showUnassignedTariff: false, showInActiveTariff: false };
          });
        }}
        className="ml-2"
        style={{ textDecoration: 'underLine', color: '#009933', cursor: 'pointer' }}
      >
        Clear all filters
      </span>
      <span style={{ overflowX: 'scroll' }}>
        <Table
          striped
          className="text-center mw-100 mt-4 mr-1"
          style={{ wordBreak: 'break-word' }}
          key="tariffs"
        >
          <thead>
            <tr>
              {tariffLevelHeaders.map(({ key, label, search }) => (
                <Th
                  key={key}
                  sortKey={key != 'edit' && key != 'selectall' ? key : ''}
                  sortConfig={{ sortOn: [sort.sortOn], sortOrder: [sort.sortOrder as SortOrder] }}
                  className="align-middle"
                  style={key == 'edit' && currentUserIsSME ? { minWidth: '65px' } : {}}
                  onClick={() => (key != 'edit' && key != 'selectall' ? handleSort(key) : {})}
                >
                  {key == 'selectall' ? (
                    <span className="d-flex">
                      <Checkbox
                        checked={tariffSelectAll || false}
                        className="ml-2"
                        disabled={
                          fetchApiStatus === 'pending' ||
                          updateApiStatus === 'pending' ||
                          userListLoading
                        }
                        onChange={tariffSelectAllSelection}
                      />
                    </span>
                  ) : key == 'edit' && !currentUserIsSME ? (
                    ''
                  ) : (
                    <span className="h5">{label}</span>
                  )}
                </Th>
              ))}
            </tr>
          </thead>
          {fetchApiStatus === 'pending' || updateApiStatus === 'pending' ? (
            <tbody>
              <tr>
                {tariffLevelHeaders.map(({ key, search }) => (
                  <td key={key} className="align-middle">
                    {search}
                  </td>
                ))}
              </tr>
              <td colSpan={12} className="p-5">
                <Loader />
              </td>
            </tbody>
          ) : (
            <tbody>
              <tr>
                {tariffLevelHeaders.map(({ key, search }) => (
                  <td key={key} className="align-middle">
                    {search}
                  </td>
                ))}
              </tr>
              {tariffValues?.map((tariffRow: TariffConfigData, index: number) => (
                <tr key={tariffRow.masterTariffId}>
                  <td className="d-flex align-items-center justify-content-around">
                    <Checkbox
                      checked={tariffSelected[index]}
                      onChange={() => tariffCheckboxSelection(index)}
                    />
                  </td>
                  <td style={{ minWidth: '4vw' }}>
                    <Link
                      target="_blank"
                      to={{
                        pathname: `${process.env.REACT_APP_MOTHER_URL}/lses/${tariffRow.lseId}`,
                      }}
                    >
                      <Text>{tariffRow.lseId || ''}</Text>
                    </Link>
                  </td>
                  <td style={{ minWidth: '10vw' }}>
                    <a
                      href={tariffRow.utilityWebsite || '#'}
                      target="_blank"
                      rel="noreferrer"
                      id="tariffUtility"
                    >
                      <TextField
                        textValue={tariffRow.lseName}
                        toolTipId={tariffRow.monitoringInventoryId + 'lseName'}
                        maxSize={11}
                      />
                    </a>
                  </td>
                  <td style={{ minWidth: '12vw' }}>
                    <Link
                      target="_blank"
                      to={{
                        pathname: `${process.env.REACT_APP_MOTHER_URL}/lses/${tariffRow.lseId}/tariffs/${tariffRow.masterTariffId}`,
                      }}
                    >
                      <TextField
                        textValue={tariffRow.tariffName || ''}
                        toolTipId={tariffRow.monitoringInventoryId + tariffRow.tariffName}
                        maxSize={18}
                        textStyle="paragraph500"
                      />
                    </Link>
                  </td>
                  <td style={{ minWidth: '4vw' }}>{tariffRow.tariffPriority}</td>
                  <td style={{ minWidth: '4vw' }}>{tariffRow.tariffTier}</td>
                  <td style={{ minWidth: '4vw' }}>{tariffRow.customerTier}</td>
                  <td style={{ minWidth: '8vw' }}>
                    <Link
                      target="_blank"
                      to={{
                        pathname: `${process.env.REACT_APP_MOTHER_URL}/lses/${tariffRow.lseId}/tariffs/${tariffRow.masterTariffId}`,
                      }}
                    >
                      <Text>{tariffRow.masterTariffId || ''}</Text>
                    </Link>
                  </td>
                  <td style={{ minWidth: '8vw' }} key="primaryOwnerUserName">
                    {configEditMode != tariffRow.monitoringInventoryId ? (
                      <Link
                        target="_blank"
                        to={{
                          pathname: `${process.env.REACT_APP_MOTHER_URL}/admin/users/${tariffRow.primaryOwnerId}`,
                        }}
                      >
                        <TextField
                          textValue={tariffRow.primaryOwnerUserName?.split('@')[0] || ''}
                          toolTipId={tariffRow.monitoringInventoryId + tariffRow.tariffName + 'PO'}
                          maxSize={10}
                          textStyle="paragraph500"
                        />
                      </Link>
                    ) : (
                      <WorkerInput
                        popoverStyle={{ zIndex: 10 }}
                        label={''}
                        placeholder="Search assignee"
                        id={'' + tariffRow.masterTariffId}
                        selected={
                          {
                            userName: editedRow
                              ? editedRow.primaryOwnerUserName
                              : tariffRow.primaryOwnerUserName,
                          } as IWorker
                        }
                        qualification={QualificationType.LOOKUP_REVIEWER}
                        setSelected={(assignee: IWorker | undefined) => {
                          if (tariffRow.primaryOwnerUserName === assignee?.userName) {
                            return;
                          }
                          setEditedRow(prev => {
                            return {
                              ...prev,
                              primaryOwnerId: assignee?.workerId,
                              primaryOwnerUserName: assignee?.userName,
                            } as TariffConfigData;
                          });
                        }}
                      />
                    )}
                  </td>
                  <td style={{ minWidth: '8vw' }} key="modeller">
                    {configEditMode != tariffRow.monitoringInventoryId ? (
                      <Link
                        target="_blank"
                        to={{
                          pathname: `${process.env.REACT_APP_MOTHER_URL}/admin/users/${tariffRow.modellerId}`,
                        }}
                      >
                        <TextField
                          textValue={tariffRow.modellerUserName?.split('@')[0] || ''}
                          toolTipId={tariffRow.monitoringInventoryId + tariffRow.tariffName + 'MO'}
                          maxSize={10}
                          textStyle="paragraph500"
                        />
                      </Link>
                    ) : (
                      <WorkerInput
                        popoverStyle={{ zIndex: 10 }}
                        label={''}
                        placeholder="Search assignee"
                        id={'' + tariffRow.masterTariffId}
                        qualification={QualificationType.LOOKUP_MODELLER}
                        selected={
                          {
                            userName: editedRow
                              ? editedRow.modellerUserName
                              : tariffRow.modellerUserName,
                          } as IWorker
                        }
                        setSelected={(assignee: IWorker | undefined) => {
                          if (tariffRow.modellerUserName === assignee?.userName) {
                            return;
                          }
                          setEditedRow(prev => {
                            return {
                              ...prev,
                              modellerId: assignee?.workerId,
                              modellerUserName: assignee?.userName,
                            } as TariffConfigData;
                          });
                        }}
                      />
                    )}
                  </td>
                  <td style={{ minWidth: '8vw' }} key="reviewer">
                    {configEditMode != tariffRow.monitoringInventoryId ? (
                      <Link
                        target="_blank"
                        to={{
                          pathname: `${process.env.REACT_APP_MOTHER_URL}/admin/users/${tariffRow.reviewerId}`,
                        }}
                      >
                        <TextField
                          textValue={tariffRow.reviewerName?.split('@')[0] || ''}
                          toolTipId={tariffRow.monitoringInventoryId + tariffRow.tariffName + 'RE'}
                          maxSize={10}
                          textStyle="paragraph500"
                        />
                      </Link>
                    ) : (
                      <WorkerInput
                        popoverStyle={{ zIndex: 10 }}
                        label={''}
                        placeholder="Search assignee"
                        id={'' + tariffRow.masterTariffId}
                        qualification={QualificationType.LOOKUP_REVIEWER}
                        selected={
                          {
                            userName: editedRow ? editedRow.reviewerName : tariffRow.reviewerName,
                          } as IWorker
                        }
                        setSelected={(assignee: IWorker | undefined) => {
                          const updatedRow = { ...tariffRow };
                          if (updatedRow.reviewerName === assignee?.userName) {
                            return;
                          }
                          setEditedRow(prev => {
                            return {
                              ...prev,
                              reviewerId: assignee?.workerId,
                              reviewerName: assignee?.userName,
                            } as TariffConfigData;
                          });
                        }}
                      />
                    )}
                  </td>
                  <td style={{ minWidth: '4vw' }}>
                    <TextField
                      textValue={(
                        (tariffRow.getTariffCount || 0) +
                        (tariffRow.onDemandCount || 0) +
                        (tariffRow.savingsAnalysisCount || 0)
                      ).toString()}
                      toolTipValue={
                        'GT : ' +
                        tariffRow.getTariffCount +
                        ' , OD : ' +
                        tariffRow.onDemandCount +
                        ' , SA : ' +
                        tariffRow.savingsAnalysisCount
                      }
                      toolTipId={tariffRow.monitoringInventoryId + 'apicount'}
                      maxSize={12}
                      textStyle="paragraph500"
                    />
                  </td>
                  <td className="pt-3">
                    {currentUserIsSME && (
                      <>
                        {configEditMode != tariffRow.monitoringInventoryId ? (
                          <IconButton
                            aria-label="edit"
                            icon="Pencil"
                            onClick={() => {
                              setConfigEditMode(tariffRow.monitoringInventoryId);
                              setEditedRow(prev => tariffRow);
                            }}
                          />
                        ) : (
                          <>
                            <IconButton
                              aria-label="save"
                              icon="Checkmark"
                              onClick={() => {
                                if (!editedRow) {
                                  return;
                                }
                                dispatch(startTariffLoading());
                                dispatch(
                                  updateTariffConfigData({
                                    userAssignmentConfig: [
                                      {
                                        userMappingId: editedRow.userMappingId,
                                        monitoringInventoryId: editedRow.monitoringInventoryId,
                                        primaryOwnerId: editedRow.primaryOwnerId,
                                        modellerId: editedRow.modellerId,
                                        reviewerId: editedRow.reviewerId,
                                      },
                                    ],
                                    selectAllMatchingRecords: false,
                                  })
                                );
                              }}
                            />
                            <IconButton
                              aria-label="cancel"
                              className="mt-1"
                              icon="UIClose"
                              onClick={() => {
                                setConfigEditMode(0);
                                setEditedRow(undefined);
                              }}
                            />
                          </>
                        )}
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      </span>
      <Row className="d-flex justify-content-center w-100 mt-1">
        <span className="d-flex justify-content-center w-100 mt-1">
          <Text
            color="primary"
            opacity="high"
            tag="p"
            textStyle="paragraph400"
            className="mt-2 mr-2"
          >
            Showing {(page - 1) * pageCount + 1} - {Math.min(page * pageCount, count)} of
            {` ${count}`} entries
          </Text>
          <Pagination
            activePage={page}
            totalPages={totalPageCount}
            onSelectPage={selectedPage => setPage(selectedPage)}
          />
        </span>
      </Row>
    </Row>
  );
};

export default TariffConfigTable;
