import React, { useCallback, useEffect, useState } from 'react';
import {
  IconButton,
  Pagination,
  Table,
  Td,
  Th,
  Tr,
  useSortableData,
} from '@arcadiapower/gen-react-lib';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { GetTasksRequestV2 } from '../../task-api/v2/api/task-api-v2';
import { Loading } from '@arcadiapower/shrike';
import { Text, Checkbox } from '@arcadiapower/shrike';
import { CurrentUser, selectCurrentUser } from '../../state/currentUser/currentUserSlice';
import { selectedTaskFilter, TaskFilter } from '../../state/taskFilter/taskFilterSlice';
import { format, parseISO } from 'date-fns';
import { restClient } from '@genability/api';
import { Task } from '../../task-api/v2/types/TaskV2';
import {
  enableTariffTaskFilter,
  enableTaskSourceFilter,
  selectedDashboardFilter,
} from '../../state/dashboardFilter/dashboardFilterSlice';
import { fetchLookUpTasks, selectLookupTasks } from '../../state/lookup/lookupSlice';
import { TASK_FILTER_MAPPINGS } from '../../utils/taskV2Utils';
import OverlayLink from '../OverlayLink/OverlayLink';
import AssignTask from '../AssignTask/AssignTask';
import appConstants from '../../app-constants';
import { taskAssignment } from '../../state/taskAssignment_V2/taskAssignmentV2Slice';
import { selectedWorkFlowConfigDetails } from '../../state/workflowConfig/WorkFlowConfigSlice';
import { AssignmentTypes, WorkFlow_Config_Mapping } from '../../utils/constants';
import { allowAssignment } from '../../utils/utilityFunction';
import { TaskType } from '../../task-api/v2/types/TaskType';
import { TaskSource } from '../../task-api/v2/types/TaskSource';
import BulkAssignTaskModal from '../BulkAssignment/BulkAssignTaskModal';
import { EntityType } from '../../task-api/v2/types/EntityType';
import TextField from '../TextField/TextField';
import { Link } from 'react-router-dom';

const LOOKUP_TASKS_COLUMNS = [
  {
    label: 'Select All',
    key: 'selection',
  },
  {
    label: 'Task ID',
    key: 'taskId',
  },
  {
    label: 'Task Type',
    key: 'taskType',
  },
  {
    label: 'Created On',
    key: 'createdDate',
  },
  {
    label: 'Lse Name',
    key: 'lseName',
  },
  {
    label: 'Property Key',
    key: 'propertyKey',
  },
  {
    label: 'Task Status',
    key: 'taskStatus',
  },
  {
    label: 'Modeller',
    key: 'modeller',
  },
  {
    label: 'Reviewer',
    key: 'reviewer',
  },
  {
    label: 'Closure Code',
    key: 'closureCode',
  },
];

const LookUpTasks = () => {
  const currentUser: CurrentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const { results, apiStatus, count } = useSelector(selectLookupTasks);
  const { apiState: taskAssignmentAPIState } = useSelector(taskAssignment);
  const {
    searchText,
    selectedTaskTypes,
    selectedStatusFilters,
    selectedTaskSource,
    applyFilters,
    searchDate,
  } = useSelector(selectedDashboardFilter);
  const tasks: Task[] = results || [];
  const { activeFilter } = useSelector(selectedTaskFilter);
  const [showSpinner, setShowSpinner] = useState(false);
  const [toggleRefresh, setToggleRefresh] = useState(true);
  const [page, setPage] = useState(1);
  const pageCount = 10;
  const totalPageCount = Math.ceil(count / pageCount);
  const { existingConfigDetails, wfConfigApiStatus } = useSelector(selectedWorkFlowConfigDetails);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<number>(0);
  const [selected, setSelected] = useState<boolean[]>(
    Array(results ? results.length : pageCount).fill(false)
  );
  const { sortedItems, sortConfig, handleSort } = useSortableData(tasks, {
    sortOn: ['taskId'],
    sortOrder: [restClient.SortOrder.DESC],
  });
  const handleSelectPage = useCallback(selectedPage => {
    setPage(selectedPage);
  }, []);

  const checkboxSelection = useCallback(
    (id: number) => {
      setSelected(prev => {
        const current = [...prev];
        if (selectAll && current[id]) {
          setSelectAll(false);
        }
        current[id] = !current[id];
        current[id] ? setSelectedCount(prev => prev + 1) : setSelectedCount(prev => prev - 1);
        return current;
      });
    },
    [selected]
  );

  const selectAllSelection = useCallback(() => {
    if (!results) return;
    setSelectAll(prev => {
      if (prev) setSelectedCount(0);
      else setSelectedCount(results.length);
      //If toggled to true the selected count is set as length of selected array
      //If toggled to false the selected count is set as 0
      //Then the selected array is also changed in the same manner
      setSelected(Array(results ? results.length : pageCount).fill(!prev));
      return !prev;
      //Finally returns the logical not value of prev state value.
    });
  }, [selectAll, results]);

  useEffect(() => {
    if (taskAssignmentAPIState == 'resolved') {
      refreshTabDetails();
    }
  }, [taskAssignmentAPIState]);

  //We want the effect to run once when the component gets mounted
  useEffect(() => {
    const enabledTaskTypes: TaskType[] = [
      TaskType.ADD_LOOKUP,
      TaskType.EDIT_LOOKUP,
      TaskType.ADD_DOCUMENT,
      // TaskType.UPSERT_LOOKUP,
    ];
    const enableTaskSource: TaskSource[] = [TaskSource.UI, TaskSource.SCRAPER, TaskSource.ZENDESK];
    dispatch(enableTariffTaskFilter(enabledTaskTypes));
    dispatch(enableTaskSourceFilter(enableTaskSource));
    setEnabled(prev => prev + 1);
  }, []);

  useEffect(() => {
    setShowSpinner(apiStatus === 'idle' || apiStatus === 'pending');
  }, [apiStatus]);

  useEffect(() => {
    if (results && results.length > 0) setSelected(Array(results.length).fill(false));
    setSelectAll(false);
    setSelectedCount(0);
  }, [results]);

  useEffect(() => {
    setPage(1);
    setEnabled(prev => prev + 1);
  }, [applyFilters]);

  useEffect(() => {
    if (enabled == 0) return;
    const request = new GetTasksRequestV2();
    request.pageCount = pageCount;
    request.pageStart = (page - 1) * pageCount;
    request.sortOn = sortConfig.sortOn;
    request.sortOrder = sortConfig.sortOrder;
    request.taskStatus = [];
    request.taskType = [];
    request.searchText = searchText;
    request.creationDate = searchDate;

    request.taskType =
      selectedTaskTypes && selectedTaskTypes.length > 0
        ? selectedTaskTypes
        : [
            TaskType.ADD_LOOKUP,
            TaskType.EDIT_LOOKUP,
            TaskType.ADD_DOCUMENT,
            // TaskType.UPSERT_LOOKUP,
          ];

    request.taskStatus =
      selectedStatusFilters && selectedStatusFilters.length > 0
        ? selectedStatusFilters
        : TASK_FILTER_MAPPINGS[activeFilter];
    if (activeFilter == TaskFilter.ALL_TASKS) {
      request.retrieveAllAssignedTasks = true;
    } else {
      if (activeFilter == TaskFilter.UNASSIGNED) {
        request.assignee = ' ';
      } else {
        request.assignee = currentUser.username;
      }
    }
    if (selectedTaskSource && selectedTaskSource.length > 0) {
      request.taskSource = selectedTaskSource;
    }
    dispatch(fetchLookUpTasks(request));
  }, [dispatch, pageCount, page, activeFilter, toggleRefresh, sortConfig, applyFilters, enabled]);

  const dateFormatter = useCallback((date: string) => format(parseISO(date), 'dd-MMM-yyyy'), []);

  const refreshTabDetails = useCallback(() => {
    setToggleRefresh(!toggleRefresh);
  }, [toggleRefresh]);

  const mappedLookUpValuesRows = useCallback(() => {
    return sortedItems?.length
      ? sortedItems.map((task, index) => (
          <Tr key={task.taskId}>
            <Td className="align-middle">
              <div className="d-flex align-items-center justify-content-around">
                <Checkbox checked={selected[index]} onChange={() => checkboxSelection(index)} />
              </div>
            </Td>
            <Td className="text-center">
              <OverlayLink
                id={task.taskId}
                to={true}
                url={`${appConstants.routes.taskDetails}/${task.taskId}`}
                displayText={`${task.taskId}`}
              />
            </Td>
            <Td className="text-center">{task?.taskType}</Td>
            <Td className="text-center">{dateFormatter(String(task?.createdDate))}</Td>
            <Td className="text-center  ">
              <a
                target="_blank"
                rel="noreferrer"
                href={`${process.env.REACT_APP_MOTHER_URL}/lses/${task?.lseId}`}
                style={{ textDecoration: 'underline', textDecorationColor: 'rgb(10,28,25)' }}
              >
                <TextField
                  textValue={task?.lseName}
                  toolTipId={task?.taskId + 'lse'}
                  maxSize={10}
                  textStyle="heading500"
                />
              </a>
            </Td>
            <Td className="text-center">
              {task?.propertyKey && (
                <Link
                  to={`directory/property-keys/${task?.propertyKey}`}
                  style={{ textDecoration: 'underline', textDecorationColor: 'rgb(10,28,25)' }}
                >
                  <TextField
                    textValue={task?.propertyKey}
                    toolTipId={task?.taskId + 'propkey'}
                    maxSize={11}
                    textStyle="heading500"
                  />
                </Link>
              )}
            </Td>
            <Td className="text-center">{task?.taskStatus}</Td>
            <Td className="text-center">
              <div className=" d-flex flex-column align-items-center">
                {task?.assignee && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${process.env.REACT_APP_MOTHER_URL}/mother/admin/users/${task?.assigneeId}`}
                    style={{ textDecoration: 'underline', textDecorationColor: 'rgb(10,28,25)' }}
                  >
                    <TextField
                      textValue={task?.assignee?.split('@')[0]}
                      toolTipId={task?.taskId + 'modeller'}
                      toolTipValue={task?.assignee}
                      maxSize={10}
                      textStyle="heading500"
                    />
                  </a>
                )}
                {allowAssignment(
                  task.currentWorkFlowStatus,
                  WorkFlow_Config_Mapping[task.workflowConfigId],
                  AssignmentTypes.MODELLER,
                  currentUser.username,
                  task.assignee,
                  existingConfigDetails
                ) && (
                  <AssignTask
                    taskType={task.taskType}
                    taskId={task.taskId}
                    className="m-1"
                    workflowId={task.workflowInstanceId}
                    username={task.assignee ? undefined : currentUser.username}
                    text={task.assignee ? 'Re-Assign' : 'Assign'}
                  />
                )}
              </div>
            </Td>
            <Td className="text-center">
              <div className=" d-flex flex-column align-items-center">
                {task?.reviewer && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${process.env.REACT_APP_MOTHER_URL}/mother/admin/users/${task?.reviewerId}`}
                    style={{ textDecoration: 'underline', textDecorationColor: 'rgb(10,28,25)' }}
                  >
                    <TextField
                      textValue={task?.reviewer?.split('@')[0]}
                      toolTipId={task?.taskId + 'reviewer'}
                      toolTipValue={task?.reviewer}
                      maxSize={10}
                      textStyle="heading500"
                    />
                  </a>
                )}
                {allowAssignment(
                  task.currentWorkFlowStatus,
                  WorkFlow_Config_Mapping[task.workflowConfigId],
                  AssignmentTypes.REVIEWER,
                  currentUser.username,
                  task.assignee,
                  existingConfigDetails
                ) && (
                  <AssignTask
                    taskId={task.taskId}
                    taskType={task.taskType}
                    className="m-1"
                    workflowId={task.workflowInstanceId}
                    reviewer
                    text={task.assignee ? 'Re-Assign' : 'Assign'}
                  />
                )}
              </div>
            </Td>
            <Td className="text-center">{task?.closureCode}</Td>
          </Tr>
        ))
      : null;
  }, [sortedItems, dateFormatter, selected]);

  return (
    <>
      {apiStatus === 'idle' || apiStatus === 'pending' || wfConfigApiStatus === 'pending' ? (
        <Loading backgroundColor="primary" />
      ) : (
        <>
          <Row>
            <Col className="d-flex justify-content-end">
              {results && results.length > 0 && (
                <>
                  {selectedCount > 1 && (
                    <BulkAssignTaskModal
                      entityType={EntityType.LOOKUP}
                      selected={selected}
                      sortedItems={sortedItems}
                      refreshTabDetails={refreshTabDetails}
                    />
                  )}
                  <Text
                    color="primary"
                    opacity="high"
                    tag="p"
                    textStyle="paragraph400"
                    className="mt-2 mr-2"
                  >
                    Showing {(page - 1) * pageCount + 1} - {Math.min(page * pageCount, count)} of
                    {` ${count}`} entries
                  </Text>

                  <Pagination
                    activePage={page}
                    totalPages={totalPageCount}
                    onSelectPage={handleSelectPage}
                  />
                </>
              )}
              <IconButton
                className="ml-1"
                style={{ height: '40px', width: '40px' }}
                icon="refresh"
                spin={showSpinner}
                onClick={() => setToggleRefresh(!toggleRefresh)}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Table bordered striped hover responsive>
                <thead>
                  <tr>
                    {LOOKUP_TASKS_COLUMNS.map(({ label, key }) => {
                      return (
                        <Th
                          key={label}
                          sortConfig={sortConfig}
                          sortKey={key != 'selection' ? key : ''}
                          className={
                            key == 'selection' ? 'd-flex align-middle' : 'align-middle text-center'
                          }
                          style={key == 'taskId' ? { minWidth: '100px' } : {}}
                          onClick={key && key != 'selection' ? () => handleSort(key) : undefined}
                        >
                          <span className="my-auto">
                            {key == 'selection'
                              ? selectAll
                                ? 'DESELECT ALL'
                                : 'SELECT ALL'
                              : label}
                          </span>
                          <span className="my-auto'">
                            {key == 'selection' && (
                              <Checkbox
                                checked={selectAll || false}
                                className="ml-2"
                                onChange={selectAllSelection}
                              />
                            )}
                          </span>
                        </Th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {!sortedItems || sortedItems.length === 0 ? (
                    <Tr className="text-center">
                      <td colSpan={LOOKUP_TASKS_COLUMNS.length}>No results found.</td>
                    </Tr>
                  ) : (
                    mappedLookUpValuesRows()
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default LookUpTasks;
