import React from 'react';
import { Icon as ShrikeIcon } from '@arcadiapower/shrike';
import { types } from '@genability/api';
import { TariffRateBand } from '@genability/api/dist/types/tariff';
import { AiRateInfo } from '../../task-api/v2/types/AiRateInfo';
import { updateRateEditAnalytics } from '../../utils/analyticsUtil';
import { Col } from 'react-bootstrap';

interface ApplyAiValueProps {
  taskAssignmentId?: number;
  tariffRateId: number;
  tariffRate: types.TariffRate;
  rateBandIndex: number;
  onApplyAiRate?: (modifyRate: types.TariffRate, answerValue: string | null) => void;
  filteredAiRateBand: TariffRateBand;
  appliedAiRates?: AiRateInfo[];
  revertedAiValue?: AiRateInfo;
  appliedAiValueRate?: AiRateInfo;
  className?: string;
}
const ApplyAiValue: React.FC<ApplyAiValueProps> = ({
  tariffRate,
  rateBandIndex,
  filteredAiRateBand,
  revertedAiValue,
  appliedAiValueRate,
  appliedAiRates,
  taskAssignmentId,
  onApplyAiRate,
  className,
}) => {
  const handleClick = () => {
    let previousValue: number | undefined;
    let answerValue;
    const updatedRateBands = tariffRate?.rateBands?.map((originalRateBand, index) => {
      if (index === rateBandIndex) {
        previousValue = originalRateBand.rateAmount; // Store the previous value
        return {
          ...originalRateBand,
          rateAmount: filteredAiRateBand.rateAmount,
        };
      }
      return originalRateBand;
    });
    const updatedTariffRate = { ...tariffRate, rateBands: updatedRateBands };
    const updatedRecord = updatedRateBands?.find((rateBand, index) => index === rateBandIndex);

    if (revertedAiValue) {
      const updatedAppliedAiRates = appliedAiRates?.map(rate => {
        if (rate === revertedAiValue) {
          return {
            ...rate,
            isAiValueApplied: true,
            acceptedAiValue: updatedRecord?.rateAmount,
            previousRateValue: previousValue,
          };
        }
        // Keep other objects unchanged
        return rate;
      });
      answerValue = JSON.stringify(updatedAppliedAiRates);
    } else {
      const updatedAiRateInfo = {
        acceptedAiValue: updatedRecord?.rateAmount,
        previousRateValue: previousValue,
        rateGroupName: tariffRate.rateGroupName,
        rateName: tariffRate.rateName,
        isAiValueApplied: true,
        ...(updatedRecord?.tariffRateId && { tariffRateId: updatedRecord?.tariffRateId }),
        ...(updatedRecord?.tariffRateBandId && {
          tariffRateBandId: updatedRecord?.tariffRateBandId,
        }),
      };
      appliedAiRates?.push(updatedAiRateInfo);
      answerValue = JSON.stringify(appliedAiRates);
    }

    if (taskAssignmentId !== undefined && taskAssignmentId !== null) {
      updateRateEditAnalytics(
        'ai_value_accepted',
        taskAssignmentId,
        updatedRecord?.tariffRateBandId,
        updatedTariffRate.rateName
      );

      if (onApplyAiRate) onApplyAiRate(updatedTariffRate, answerValue);
    }
  };

  return (
    <Col
      xs={'auto'}
      className={`${className}`}
      onClick={handleClick}
      style={{ cursor: 'pointer', flexGrow: 1 }}
    >
      <ShrikeIcon
        color="success"
        icon="CheckmarkCircleInverted"
        opacity="high"
        scale={3}
        title="Apply Ai Value"
      />
    </Col>
  );
};

export default ApplyAiValue;
