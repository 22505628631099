/**
 * known dq errors with no index
 */
export const knownDqErrors = {
  elegibility: [
    {
      propertyName: 'tariffProperty',
      message: 'Tariff applicability missing',
    },
    {
      propertyName: 'propertyValue',
      message: 'Mismatched tariff properties (between versions)',
    },
  ],
  rateCriteria: [
    {
      propertyName: 'propertyValue',
      message: 'Mismatched defaults between last 2 versions',
    },
  ],
};
