/**

Created a separate request folder and file named 'v2' 
in order to handle the specific requirements and functionalities of the v2 endpoints,

This approach allows for clear separation and organization of code related to the v2 version of the API.
By having a dedicated request file, it simplifies maintenance and future enhancements specific to the v2 endpoints
without impacting the existing codebase.

It provides a clean and focused implementation for working with the v2 API features and ensures better 
compatibility and flexibility for any future updates or changes in the API specifications.

**/
import { restClient, types } from '@genability/api';
import { TaskType } from '../types/TaskType';
import { EntityType } from '../types/EntityType';
import { Task as TaskV2 } from '../types/TaskV2';
import { TaskWorkflowEntity } from '../types/TaskWorkflowEntity';
import { TaskAssignmentAnswerV2 } from '../types/TaskAssignmentAnswerV2';
import { User } from '../types/User';
import { SingleResponse } from '@genability/api/dist/rest-client';
import { TaskDetails } from '../types/TaskDetails';
import { WorkFlowConfigResponse } from '../types/WorkflowConfigResponse';
import { LookupValueV2 } from '../types/LookupValueV2';
import { TaskStats } from '../types/TaskStats';
import { WorkflowStateResponse } from '../types/WorkflowStateResponse';
import { Tariff } from '../types/TariffDetails';
import { PropertyKeyV2 } from '../types/PropertyKeyV2';
import { bulkAssignmentResponseType } from '../../../components/BulkAssignment/BulkAssignTaskModal';
import { QualificationType } from '../types/QualifictionType';
import { IWorker } from '../../types/worker';

export interface bulkAssignmentType {
  workflowId: number;
  taskId: number;
  taskStatus: string | undefined;
}

export class GetTasksRequestV2 extends restClient.BasePagedRequest {
  public lse?: string;
  public taskStatus?: string[];
  public taskType?: string[];
  public taskSource?: string[];
  public assignee?: string;
  public searchText?: string;
  public negateAssignee?: boolean;
  public creationDate?: string[];
  public retrieveAllAssignedTasks?: boolean;

  addParams(addParam: restClient.AddParamCallback): void {
    addParam('lse', this.lse);
    addParam('taskStatus', this.taskStatus);
    addParam('taskType', this.taskType);
    addParam('assignee', this.assignee);
    addParam('taskSource', this.taskSource);
    addParam('creationDate', this.creationDate);
    addParam('searchText', this.searchText);
    addParam('negateAssignee', this.negateAssignee);
    addParam('retrieveAllAssignedTasks', this.retrieveAllAssignedTasks);
  }
}

export class GetTasksByPropertyKeyRequest extends restClient.BasePagedRequest {
  public unClosed?: boolean;
  public taskStatus?: string;
  public taskType?: string;

  addParams(addParam: restClient.AddParamCallback): void {
    addParam('unClosed', this.unClosed);
    addParam('taskStatus', this.taskStatus);
    addParam('taskType', this.taskType);
  }
}

export class GetPropertyKeysRequest extends restClient.BasePagedRequest {
  public excludeGlobal?: boolean;
  public keySpace?: string;
  public scope?: string;
  public family?: string;
  public entityId?: number;
  public entityType?: string;
  public dataType?: types.PropertyDataType;
  addParams(addParam: restClient.AddParamCallback): void {
    addParam('excludeGlobal', this.excludeGlobal);
    addParam('keySpace', this.keySpace);
    addParam('scope', this.scope);
    addParam('family', this.family);
    addParam('entityId', this.entityId);
    addParam('entityType', this.entityType);
    addParam('dataType', this.dataType);
  }
}

export class GetWorkersRequest extends restClient.BasePagedRequest {
  public qualification?: QualificationType;
  addParams(addParam: restClient.AddParamCallback): void {
    addParam('qualification', this.qualification);
  }
}

export class TaskAssignmentRequest {
  public workflowId?: string;
  public taskId?: string;
  public username?: string;
  public taskType?: 'Tariff';
  public isReviewer?: boolean;

  addParams(addParam: restClient.AddParamCallback): void {
    addParam('workflowId', this.workflowId);
    addParam('taskId', this.taskId);
    addParam('username', this.username);
  }
}

export class GetUserListsRequest extends restClient.BasePagedRequest {
  public haveRole?: string;
  public orgId?: string;
  public loadRoles?: boolean;

  addParams(addParam: restClient.AddParamCallback): void {
    addParam('haveRole', this.haveRole);
    addParam('orgId', this.orgId);
    addParam('loadRoles', this.loadRoles?.toString()); // Convert to string
  }
}

export class GetLookupValuesRequest extends restClient.BasePagedRequest {
  public lookupValueType?: string;
  public fromDateTime?: string;
  public toDateTime?: string;
  addParams(addParam: restClient.AddParamCallback): void {
    addParam('lookupValueType', this.lookupValueType);
    addParam('fromDateTime', this.fromDateTime);
    addParam('toDateTime', this.toDateTime);
  }
}
export class TaskStatsRequest extends restClient.BasePagedRequest {
  public assignee: string | undefined;
  public taskTypeMenu: string | undefined;
  public dashboardMenu: string | undefined;
  addParams(addParam: restClient.AddParamCallback): void {
    addParam('assignee', this.assignee);
    addParam('taskTypeMenu', this.taskTypeMenu);
    addParam('dashboardMenu', this.dashboardMenu);
  }
}

export interface CreateTaskBody {
  workflowConfigId?: string;
  taskType?: TaskType;
  entityType?: EntityType;
  sourceEntityId?: string;
  sourceEntityEditId?: number;
  sourceEntityGlobalId?: number;
  taskAssignmentAnswer?: string;
}

export interface UpdateWorkflowTaskMetaData {
  taskSource?: string;
  taskChannel?: string;
}

export interface intermediateSaveBody {
  answerJSON: string;
}

export interface uploadDocumentBody {
  presignedurl: string;
}

export class TaskApiV2 extends restClient.RestApiClient {
  public async getTariffTasksFromV2Endpoint(
    request?: GetTasksRequestV2
  ): Promise<restClient.PagedResponse<TaskV2>> {
    return this.getPaged(`/tasks/tariff`, { params: request });
  }

  public async exportTariffTasksFromV2Endpoint(
    request?: GetTasksRequestV2
  ): Promise<restClient.SingleResponse<TaskV2>> {
    return this.getSingle(`/tasks/tariff/export`, { params: request });
  }

  public async getLookupTasksFromV2Endpoint(
    request?: GetTasksRequestV2
  ): Promise<restClient.PagedResponse<TaskV2>> {
    return this.getPaged(`/tasks/lookup`, { params: request });
  }

  public async getPropertyKeyTasks(
    request?: GetTasksRequestV2
  ): Promise<restClient.PagedResponse<TaskV2>> {
    return this.getPaged(`/tasks/propertyKeyTasks`, { params: request });
  }

  public async getTasksForPropertyKey(
    propertyKey?: string,
    request?: GetTasksByPropertyKeyRequest
  ): Promise<restClient.PagedResponse<TaskV2>> {
    return this.getPaged(`/tasks/lookup/property/${propertyKey}`, { params: request });
  }

  public async createTask(
    body?: CreateTaskBody,
    taskSource?: string
  ): Promise<restClient.SingleResponse<TaskWorkflowEntity>> {
    return this.post(`/task/create?source=${taskSource}`, body);
  }

  public async assignTaskToUser(
    workflowId: string,
    taskId: string,
    username: string
  ): Promise<SingleResponse<void>> {
    return this.post(`/workflow/${workflowId}/tasks/${taskId}/assign/${username}`);
  }

  public async assignTariffTaskToUser(
    taskId: string[],
    taskStatus: string[],
    username: string,
    isReviewer: boolean
  ): Promise<SingleResponse<bulkAssignmentResponseType>> {
    return this.post(`/tasks/tariffs/update`, { taskId, taskStatus, username, isReviewer });
  }

  public async bulkAssignTask(
    bulkRows: bulkAssignmentType[],
    username: string,
    isReviewer: boolean
  ): Promise<SingleResponse<bulkAssignmentResponseType>> {
    return this.post(`/workflow/bulkassign/${username}/${isReviewer}`, bulkRows);
  }

  public async getPresignedUrl(
    workflowId: number,
    taskId: number
  ): Promise<restClient.SingleResponse<string>> {
    return this.getSingle(`/workflow/${workflowId}/tasks/${taskId}/presignedurl`);
  }

  public async uploadDocument(
    body: uploadDocumentBody,
    workflowId: number,
    taskId: number
  ): Promise<restClient.SingleResponse<TaskWorkflowEntity>> {
    return this.post(`/workflow/${workflowId}/tasks/${taskId}/uploadDocument`, body);
  }

  public async saveIntermediateAnswer(
    workflowId: number,
    taskId: number,
    body: intermediateSaveBody
  ): Promise<restClient.SingleResponse<TaskAssignmentAnswerV2>> {
    return this.put(`/workflow/${workflowId}/tasks/${taskId}/save`, body);
  }

  public async workflowTransition(
    workflowId: number,
    taskId: number,
    taskType: string,
    toState: string,
    body?: any
  ): Promise<restClient.SingleResponse<TaskWorkflowEntity>> {
    return this.post(`/workflow/${workflowId}/tasks/${taskId}/${taskType}/${toState}`, body ?? {});
  }

  public async addComment(taskId: number, body?: any): Promise<restClient.SingleResponse<void>> {
    return this.post(`/tasks/${taskId}/comment/`, body ?? {});
  }

  public async getUserList(request?: GetUserListsRequest): Promise<restClient.PagedResponse<User>> {
    return this.getPaged(`/users`, { params: request });
  }

  public async getPropertyKeys(
    request?: GetPropertyKeysRequest
  ): Promise<restClient.PagedResponse<PropertyKeyV2>> {
    return this.getPaged(`/propertyKeys`, { params: request });
  }

  public async getTaskDetails(taskId?: string): Promise<restClient.PagedResponse<TaskDetails>> {
    return this.getPaged(`/tasks/${taskId}/details`);
  }

  public async getWorkflowConfig(
    configId?: string
  ): Promise<restClient.PagedResponse<WorkFlowConfigResponse>> {
    return this.getPaged(`/workflow/${configId}`);
  }
  public async getWorkFlowStatesByWorkflowId(
    workflowId: number
  ): Promise<restClient.PagedResponse<WorkflowStateResponse>> {
    return this.getSingle(`/workflow/${workflowId}/workflow-states`);
  }
  public async getWorkFlowByWorkflowId(
    workflowId: number
  ): Promise<restClient.SingleResponse<TaskWorkflowEntity>> {
    return this.getSingle(`/workflow/${workflowId}/instance`);
  }

  public async updateWorkflowTaskMetaData(
    workflowId: number,
    body: UpdateWorkflowTaskMetaData
  ): Promise<restClient.PagedResponse<TaskV2>> {
    return this.post(`/workflow/${workflowId}/updateTaskMetaData`, body ?? {});
  }

  public async getLookupValues(
    propertyKey: string,
    request: GetLookupValuesRequest
  ): Promise<restClient.PagedResponse<LookupValueV2>> {
    return this.getPaged(`/tasks/property/${propertyKey}/lookups`, { params: request });
  }

  public async getTaskStats(
    request?: TaskStatsRequest
  ): Promise<restClient.PagedResponse<TaskStats>> {
    return this.getPaged(`/tasks/stats`, { params: request });
  }

  public async getMasterTariffByPropertyKey(
    propertyKey: string
  ): Promise<restClient.PagedResponse<Tariff>> {
    return this.getPaged(`tariff/${propertyKey}/masterTariff`, {});
  }

  public async getTariffByTariffId(tariffId: number): Promise<restClient.SingleResponse<Tariff>> {
    return this.getSingle(`tariff/${tariffId}`, {});
  }

  public async getPropertyKey(keyName?: string): Promise<restClient.SingleResponse<PropertyKeyV2>> {
    return this.getSingle(`/propertyKeys/${keyName}`);
  }
  public async getWorkers(request?: GetWorkersRequest): Promise<restClient.PagedResponse<IWorker>> {
    return this.getPaged(`/workers`, { params: request });
  }
}
