import React, { useState, useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { types } from '@genability/api';
import PanelNavigationButtons from '../PanelNavigationButtons/PanelNavigationButtons';
import {
  ConfirmModal,
  Form,
  TextInput,
  SelectInput,
  CheckInput,
} from '@arcadiapower/gen-react-lib';
import { useAppDispatch } from '../../../state/store';
import { fetchPropertyKey, selectPropertyKey } from '../../../state/propertyKeys/propertyKeysSlice';
import { selectServiceTerritories } from '../../../state/territories/territoriesSlice';
import {
  selectTaskAssignmentTariff,
  updateTaskAssignmentTariff,
} from '../../../state/taskAssignmentTariffs/taskAssignmentTariffsSlice';
import { DoStepPanelProps } from '../work-body';
import useTariffAnswer from '../../../utils/useTariffAnswer';
import { mapApiErrors, withErrors } from '../../../utils/formUtils';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import ApiFormError, { FormErrorObject } from '../../ApiFormError/ApiFormError';
import {
  fetchDQCheck,
  selectDQCheckErrors,
  selectDQCheckOverrides,
  isDQCheckOverride,
  resetDQCheckOverride,
} from '../../../state/dqCheck/dqCheckSlice';
import DQContextMenu from '../../DQOverride/DQContextMenu';
import { isErrorMapToField } from '../../../utils/errorUtils';
import { displayLinks } from '../../../utils/diffUtils';
import { TaskTariff } from '../../../task-api/types/task';
import QuickSearch from '../../QuickSearchComponent/QuickSearch';
import { doQuickSearch } from '../../../state/QuickSearch/QuickSearchSlice';

const AddTariffHeaderPanel: React.FC<DoStepPanelProps> = ({
  onPrevious,
  onNext,
  taskAssignment,
}: DoStepPanelProps) => {
  const serviceTerritories: Array<types.Territory> = useSelector(
    selectServiceTerritories(taskAssignment?.task?.lseId)
  );
  const tariff: TaskTariff | undefined = useSelector(selectTaskAssignmentTariff);
  const dqCheckErrors = useSelector(selectDQCheckErrors);
  const dqOverrides = useSelector(selectDQCheckOverrides);

  if (!tariff) {
    return null;
  }

  const tariffHeaderProperties = [
    'tariffName',
    'tariffBookName',
    'tariffCode',
    'tariffType',
    'customerClass',
    'billingPeriod',
    'serviceType',
    'timeZone',
    'currency',
    'territoryId',
    'effectiveDate',
    'endDate',
    'closedDate',
    'customerCount',
    'customerCountSource',
    'author.orgId',
    'author.name',
  ] as (keyof TaskTariff)[];

  const { tariffAnswer, getTariffFields, setTariffFieldsFromForm, tariffFields } = useTariffAnswer(
    tariff,
    tariffHeaderProperties
  );

  // TODO: Find a way to fix the return type of useTariffAnswer so we can guarantee that
  // if the second function parameter is supplied, tariffFields is guaranteed to be defined

  if (!tariffFields) {
    return null;
  }

  const initialBookNameCheck = Boolean(
    tariff &&
      tariff.tariffName &&
      tariff.tariffBookName &&
      tariff.tariffName != tariff.tariffBookName
  );
  const [nextSpin, setNextSpin] = useState(false);
  const [noChangesChecked, setNoChangesChecked] = useState(false);
  const [isRider, setIsRider] = useState(tariffAnswer?.tariffType === types.TariffType.RIDER);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchDQCheck(taskAssignment.taskAssignmentId));
  }, []);

  useEffect(() => {
    dispatch(fetchPropertyKey('timeZone'));
    dispatch(fetchPropertyKey('currency'));
  }, [tariff]);

  const handleNoChanges = async (checked: boolean) => {
    if (checked) {
      const results = await trigger(); // validate form
      if (results) {
        setNoChangesChecked(checked);
      }
    } else {
      setNoChangesChecked(checked);
    }
  };

  const timeZoneProperty: types.GenPropertyKey | null = useSelector(selectPropertyKey('timeZone'));

  const currencyProperty: types.GenPropertyKey | null = useSelector(selectPropertyKey('currency'));

  interface HeaderFields extends Pick<TaskTariff, (typeof tariffFields)[number]> {
    hasBookName: boolean;
    hasClosedDate: boolean;
    dqOverridesSaved: boolean;
  }

  const saveAndNext = async (data: HeaderFields) => {
    const updatedTariff = setTariffFieldsFromForm(data);
    const [id, orgName] = combinedValue?.split('==') || [];
    if (combinedValue?.length > 8) {
      updatedTariff.author = {
        orgId: id,
        name: orgName,
        refId: null,
        refType: null,
      };
    } else {
      updatedTariff.author = {
        orgId: null,
        name: null,
        refId: null,
        refType: null,
      };
    }

    if (taskAssignment) {
      // temp until this isn't undefined
      setNextSpin(true);

      const { result } = await dispatch(
        updateTaskAssignmentTariff({
          taskAssignmentId: taskAssignment.taskAssignmentId,
          tariff: updatedTariff,
          errorLinks: displayLinks(updatedTariff),
        })
      ).unwrap();

      if (result) {
        const dqCheck = await dispatch(fetchDQCheck(taskAssignment.taskAssignmentId)).unwrap();
        const isErrorsInForm = dqCheck.errors?.some(
          error => isErrorMapToField(getValues(), error) && !isDQCheckOverride(error, dqOverrides)
        );
        if (!isErrorsInForm) {
          onNext();
        }
      }
    }
    setNextSpin(false);
  };

  const {
    register: useFormRegister,
    handleSubmit,
    trigger,
    formState,
    watch,
    reset,
    setValue,
    setError,
    getValues,
    clearErrors,
  } = useForm<HeaderFields>({
    mode: 'onChange',

    defaultValues: useMemo(() => {
      return {
        ...getTariffFields(),
        customerCountSource: tariffAnswer.customerCountSource || 'Genability Estimate',
        // Non-tariff fields
        hasBookName: initialBookNameCheck,
        hasClosedDate: !!(tariffAnswer && tariffAnswer.closedDate),
        dqOverridesSaved: false,
      };
    }, [tariffAnswer, timeZoneProperty, currencyProperty]),
  });
  const { isDirty, errors } = formState;

  const register = withErrors(useFormRegister, { errors });
  const hasClosedDate = watch('hasClosedDate');
  const hasBookName = watch('hasBookName');
  const tariffType = watch('tariffType');

  const [combinedValue, setCombinedValue] = useState('');
  const authorOrgId = watch('author.orgId');
  const authorName = watch('author.name');

  useEffect(() => {
    if (authorOrgId && authorName) {
      setCombinedValue(`${authorOrgId} == ${authorName}`);
    }
  }, [authorName]);

  useEffect(() => {
    if (!hasBookName) {
      setValue('tariffBookName', undefined);
    }
  }, [hasBookName]);

  useEffect(() => {
    if (!hasClosedDate) {
      setValue('closedDate', null);
    }
  }, [hasClosedDate]);

  useEffect(() => {
    setIsRider(tariffType === 'RIDER');
  }, [tariffType]);

  useEffect(() => {
    dqCheckErrors.forEach(error => {
      const fieldName = error.propertyName;
      if (isDQCheckOverride(error, dqOverrides)) {
        clearErrors(fieldName as keyof HeaderFields);
      }
    });
    const dqCheckErrorsInHeader = dqCheckErrors.filter(error =>
      isErrorMapToField(getValues(), error)
    );
    if (dqCheckErrorsInHeader.length > 0) {
      dqCheckErrorsInHeader.forEach(({ propertyName }) => {
        const isOverrideDirty = dqOverrides.find(
          override => override.propertyName === propertyName && override.isDirty
        );
        if (isOverrideDirty) {
          setValue('dqOverridesSaved', true, { shouldDirty: true });
          dispatch(resetDQCheckOverride(propertyName));
        }
      });
    }
    mapApiErrors<HeaderFields>(
      dqCheckErrorsInHeader.filter(error => !isDQCheckOverride(error, dqOverrides)),
      setError,
      getTariffFields()
    );
  }, [dqCheckErrors, dqOverrides]);

  return (
    <>
      <h2>{isRider ? 'Rider Header' : 'Tariff Header'}</h2>
      <p>Review the tariff documentation and set all fields for this new tariff.</p>
      <Form
        onSubmit={handleSubmit(saveAndNext)}
        onReset={e => {
          e.preventDefault();
          reset();
        }}
      >
        <ApiFormError apiErrors={errors as FormErrorObject} errorLinks={{}} />
        <Row>
          <Col>
            <TextInput
              type="text"
              label="Tariff Name"
              placeholder="Residential Service"
              {...register('tariffName', { required: 'Field is required' })}
            />
            <CheckInput label="Use different Tariff Book name" {...register('hasBookName')} />
            {hasBookName && (
              <TextInput
                type="text"
                label="Tariff Book Name"
                placeholder="Tariff Book Name"
                {...register('tariffBookName')}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              type="text"
              label="Tariff Code"
              placeholder="Tariff Code"
              {...register('tariffCode', { required: true })}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectInput label="Service Type" {...register('serviceType', { required: true })}>
              <option value="">select service type</option>
              {Object.values(types.ServiceType).map(option => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                );
              })}
            </SelectInput>
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectInput label="Tariff Type" {...register('tariffType', { required: true })}>
              <option value="">select tariff type</option>
              {Object.values(types.TariffType).map(option => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                );
              })}
            </SelectInput>
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectInput
              label="Customer Class"
              {...register('customerClass', {
                required: isRider ? false : 'Field is required',
                setValueAs: value => {
                  if (value === '' && isRider) return null;
                  return value;
                },
              })}
              id="customerClass"
            >
              {isRider ? (
                <option value="">ALL</option>
              ) : (
                <option value="" disabled>
                  select customer class
                </option>
              )}
              {Object.values(types.CustomerClass).map(option => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                );
              })}
            </SelectInput>
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectInput label="Billing Period" {...register('billingPeriod', { required: true })}>
              <option value="">select billing period</option>
              {Object.values(types.ChargePeriod).map(option => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                );
              })}
            </SelectInput>
          </Col>
        </Row>
        <Row>
          <Col>
            {timeZoneProperty?.choices && ( // Don't render until options are available
              <SelectInput label="Time Zone" {...register('timeZone', { required: true })}>
                <option value="">select time zone</option>
                {timeZoneProperty?.choices?.map(option => {
                  return (
                    <option key={option.dataValue} value={option.dataValue}>
                      {option.displayValue}
                    </option>
                  );
                })}
              </SelectInput>
            )}
          </Col>
          <Col>
            {currencyProperty?.choices && ( // Don't render until options are available
              <SelectInput label="Currency" {...register('currency', { required: true })}>
                <option value="">select currency</option>
                {currencyProperty?.choices?.map(option => {
                  return (
                    <option key={option.dataValue} value={option.dataValue}>
                      {option.displayValue}
                    </option>
                  );
                })}
              </SelectInput>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectInput label="Service Area Territory" {...register('territoryId')}>
              {serviceTerritories &&
                serviceTerritories.map(serviceTerritory => (
                  <option key={serviceTerritory.territoryId} value={serviceTerritory.territoryId}>
                    {serviceTerritory.territoryName}
                  </option>
                ))}
            </SelectInput>
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              type="date"
              label="Effective Date"
              {...register('effectiveDate', { required: true })}
            />
          </Col>
          <Col>
            <TextInput type="date" label="End Date" {...register('endDate')} />
          </Col>
        </Row>
        <Row>
          <Col>
            <QuickSearch
              popoverStyle={{ zIndex: 15 }}
              value={combinedValue}
              label="Author Org ID"
              id="author"
              onChange={value => {
                setCombinedValue(value || '');
                dispatch(doQuickSearch({ minChar: 2, key: 'orgId', value }));
              }}
              onSelect={async value => {
                const result = await ConfirmModal.show({
                  title: 'Warning',
                  question: 'Are you sure you want to edit this Org Id and Org Name?' + value,
                  confirm: 'Yes',
                  cancel: 'No',
                });
                if (result) {
                  const id = value?.slice(0, 36);
                  const name = value?.slice(37, value?.length);
                  setCombinedValue(id + '==' + name || '');
                }
              }}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <CheckInput label="Closed to new customers" {...register('hasClosedDate')} />
            {!hasClosedDate && errors.closedDate?.message && (
              <DQContextMenu propertyName="closedDate" errorType="DQFailure">
                {errors.closedDate?.message}
              </DQContextMenu>
            )}
            {hasClosedDate && (
              <TextInput
                type="date"
                label="Closed Date"
                placeholder="Closed Date"
                {...register('closedDate')}
              />
            )}
          </Col>
        </Row>
        {!isRider && (
          <Row>
            <Col xs={5}>
              <TextInput
                type="number"
                label="Customer Count"
                placeholder="Customer Count"
                {...register('customerCount', {
                  valueAsNumber: true,
                  required: 'Field is required',
                })}
              />
            </Col>
            <Col>
              <SelectInput
                label="Count Source"
                {...register('customerCountSource', { required: true })}
              >
                <option value="FERC Form 1">FERC Form 1</option>
                <option value="Genability Estimate">Genability Estimate</option>
              </SelectInput>
            </Col>
          </Row>
        )}
        <PanelNavigationButtons
          onClickPrevious={onPrevious}
          onClickReset={() => true}
          onClickNext={isDirty ? () => true : onNext}
          nextLabel={isDirty ? 'Save & Next' : 'Next'}
          nextSpin={nextSpin}
          dirty={isDirty}
          noChangesChecked={noChangesChecked}
          onClickNoChanges={handleNoChanges}
        />
      </Form>
    </>
  );
};

export default AddTariffHeaderPanel;
