import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { addNotification } from './notification/notificationSlice';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { restClient } from '@genability/api';

// eslint-disable-next-line
export function handleUnexpectedThunkException(
  err: any,
  objectName: string,
  dispatch?: ThunkDispatch<unknown, unknown, AnyAction>
): restClient.ResponseError {
  const message = err.message ? err.message : err.toString();
  if (dispatch) {
    dispatch(addNotification(message, NotificationLevel.Error));
  }
  return {
    code: 'SystemError',
    message,
    objectName,
  };
}

export enum LoadingState {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}
