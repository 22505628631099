import { restClient } from '@genability/api';
import { CopyTariffSourceType } from '../types/CopyTariffSourceType';
import { TaskAssignment } from '../types/TaskV2';

export interface CopyTariffBody {
  targetTaskAssignmentId: number;
  sourceType: CopyTariffSourceType;
  sourceId: number;
}

export class TariffApi extends restClient.RestApiClient {
  public async copyTariff(
    body: CopyTariffBody
  ): Promise<restClient.SingleResponse<TaskAssignment>> {
    return this.post(`tariff/copy`, body);
  }
}
