import React, { useState, useEffect } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { withRouter, useHistory, useLocation, RouteComponentProps } from 'react-router-dom';
import appConstants from '../../app-constants';
import { Task, TaskTypeId } from '../../task-api/types/task';
import { GetTasksRequest } from '../../task-api/api/task-api';
import {
  Table,
  Th,
  FilterChipGroup,
  Pagination,
  useSortableData,
  IconButton,
} from '@arcadiapower/gen-react-lib';
import styles from './Completed.module.scss';
import { useDispatch } from 'react-redux';
import { fetchCompletedTasks, selectTasks } from '../../state/tasks/tasksSlice';
import { useSelector } from 'react-redux';
import { TaskStatus } from '../../task-api/types/task';
import { on } from '../../utils/dateUtils';
import { restClient } from '@genability/api';

const Completed: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const searchParams = new URLSearchParams(props.location.search);
  const [selectedTaskTypeId, setSselectedTaskTypeId] = useState<string | null>('All types');
  const [toggleRefresh, setToggleRefresh] = useState(true);
  const [selectedTaskStatuses, setSelectedTaskStatuses] = useState([
    TaskStatus.PUBLISHED,
    TaskStatus.CLOSED,
  ]);
  const { results, apiStatus, count } = useSelector(selectTasks);
  const tasks: Task[] = results || [];
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const initialPage = Number(searchParams.get('page')) || 1;
  const [page, setPage] = useState(initialPage);
  const totalTasks = Number(count);
  const remainder = totalTasks % 100;
  const extraOnePage = remainder === 0 ? 0 : 1;
  const totalPageCount = Math.trunc(totalTasks / 100) + extraOnePage;

  const dropDownOptions: string[] = [];
  tasks.forEach(task => {
    if (!dropDownOptions.includes(`${task.taskType.taskTypeId}`)) {
      dropDownOptions.push(`${task.taskType.taskTypeId}`);
    }
  });
  dropDownOptions.unshift('All types');

  const filterBy = (list: Task[], taskType: string | null) => {
    if (taskType !== 'All types' && taskType !== null) {
      list = list.filter(
        (item: { taskType: { taskTypeId: TaskTypeId | undefined } }) =>
          item.taskType.taskTypeId === taskType
      );
    }
    return list;
  };

  const taskStatusOptions = Object.fromEntries(
    Object.entries(TaskStatus).filter(([status]) => {
      return [
        TaskStatus.APPROVED,
        TaskStatus.REJECTED,
        TaskStatus.PUBLISHED,
        TaskStatus.CLOSED,
      ].includes(TaskStatus[status as TaskStatus]);
    })
  );

  const { sortedItems, sortConfig, handleSort } = useSortableData(tasks, {
    sortOn: ['completedDate'],
    sortOrder: [restClient.SortOrder.DESC],
  });
  const insertUrlParam = (key: string, value: string): void => {
    const pathname = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value);
    history.push(`${pathname}?${searchParams.toString()}`);
  };

  useEffect(() => {
    const request = new GetTasksRequest();
    request.populateTaskAssignments = true;
    request.taskStatuses = selectedTaskStatuses;
    request.fields = restClient.Fields.EXTENDED;
    request.sortOn = sortConfig.sortOn;
    request.sortOrder = sortConfig.sortOrder;
    request.pageCount = 100;
    request.pageStart = page * request.pageCount - request.pageCount;
    dispatch(fetchCompletedTasks(request));
  }, [dispatch, selectedTaskStatuses, sortConfig, toggleRefresh]);

  const handleSelect = (e: string | null) => {
    setSselectedTaskTypeId(e);
  };

  const handleTaskStatusSelect = (selectedChps: string[]) => {
    setSelectedTaskStatuses(selectedChps as Array<TaskStatus>);
  };

  const handleSelectPage = (page: number) => {
    insertUrlParam('page', String(page));
    setPage(page);
  };

  const columns = [
    {
      label: 'Task ID',
      key: 'taskId',
    },
    {
      label: 'Task Type',
      //"key": "taskType.taskTypeName"
    },
    {
      label: 'Details',
      key: 'taskTitle',
    },
    {
      label: 'LSE',
      key: 'lseId',
    },
    {
      label: 'Status',
      key: 'taskStatus',
    },
    {
      label: 'Posted On',
      key: 'postedDate',
    },
    {
      label: 'Plurality',
      key: 'requiredPlurality',
    },
    {
      label: 'Reviewed On',
      key: 'reviewedDate',
    },
    {
      label: 'Reviewer',
      //"key": "assignments[0].reviewerId"
    },
    {
      label: 'Published On',
      key: 'publishedDate',
    },
    {
      label: 'Completed On',
      key: 'completedDate',
    },
  ];

  return (
    <div className={styles.wrapper}>
      {
        <>
          <div className="row">
            <div className="col d-flex">
              <h1>Completed Tasks </h1>
            </div>
            <div className={'col d-flex'}>
              <div className={styles.taskTypeFilter}>
                <DropdownButton
                  alignRight
                  variant="default"
                  title="Task Type"
                  id="dropdown-menu-align-right"
                  onSelect={handleSelect}
                >
                  {dropDownOptions.map(option => (
                    <Dropdown.Item
                      key={option}
                      eventKey={option}
                      active={option === selectedTaskTypeId}
                    >
                      {option}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>
              <div className={`${styles.taskStatusFilter} w-100`}>
                <FilterChipGroup
                  options={taskStatusOptions}
                  placeholder="Select Task Status"
                  onChange={(selectedChps: string[]) => handleTaskStatusSelect(selectedChps)}
                  selected={selectedTaskStatuses}
                  size={'sm'}
                />
              </div>
              <div>
                <IconButton
                  icon="refresh"
                  spin={apiStatus === 'idle' || apiStatus === 'pending'}
                  onClick={() => setToggleRefresh(!toggleRefresh)}
                />
              </div>
            </div>
          </div>
          <div>
            <Table hover>
              <thead>
                <tr>
                  {columns.map((column, idx) => (
                    <Th
                      key={idx}
                      sortConfig={sortConfig}
                      sortKey={column.key}
                      onClick={column.key ? () => handleSort(column.key) : undefined}
                    >
                      {column.label}
                    </Th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {sortedItems &&
                  filterBy(sortedItems, selectedTaskTypeId).map((task: Task) => (
                    <tr
                      className="gen-has-link"
                      key={task.taskId}
                      onClick={() =>
                        history.push(`${appConstants.routes.completed}/${task.taskId}`)
                      }
                    >
                      <td>{task.taskId}</td>
                      <td>{task.taskType.taskTypeName}</td>
                      <td>{task.taskTitle}</td>
                      <td>{task.lseId}</td>
                      <td>{task.taskStatus}</td>
                      <td>{on(task.postedDate)}</td>
                      <td>{task.requiredPlurality}</td>
                      <td>{on(task.reviewedDate)}</td>
                      <td>
                        {task.assignments && task.assignments[0] && task.assignments[0].reviewerId}
                      </td>
                      <td>{on(task.publishedDate)}</td>
                      <td>{on(task.completedDate)}</td>
                    </tr>
                  ))}
                {(!tasks || tasks.length === 0) && (
                  <tr>
                    <td className="no-results" colSpan={12}>
                      No results
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Pagination
              activePage={page}
              totalPages={totalPageCount}
              onSelectPage={handleSelectPage}
            />
          </div>
        </>
      }
    </div>
  );
};
export default withRouter(Completed);
