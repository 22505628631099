import React, { FC, useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import TariffCalculator from '../../../../components/TariffCalculator/TariffCalculator';

export interface TariffCalculatorParams {
  masterTariffId: string;
}

const deserializeTariffIds = (search: string) => {
  const tariffIdsParam = new URLSearchParams(search).get('tariffIds');
  if (!tariffIdsParam) {
    return [];
  }
  const tariffIds = tariffIdsParam
    .split(',')
    .map(tariffId => Number(tariffId))
    .filter(tariffId => tariffId > 0);

  return tariffIds;
};

const TariffCalculatorParameterParser: FC<RouteComponentProps<TariffCalculatorParams>> = props => {
  const { masterTariffId } = props.match.params;
  const [tariffIds, setTariffIds] = useState<number[]>([]);

  useEffect(() => {
    setTariffIds(deserializeTariffIds(props.location.search));
  }, [props]);

  return <TariffCalculator masterTariffId={Number(masterTariffId)} tariffIds={tariffIds} />;
};

export default withRouter(TariffCalculatorParameterParser);
