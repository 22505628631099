import { restClient } from '@genability/api';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { OpsManagementApiClient } from '../../../GenApiClient';
import { handleUnexpectedThunkException } from '../../reduxUtils';
import { RootState } from '../../rootReducer';

export interface TariffStatusStatsType {
  currentUserStats: {
    totalAssignedLSE: number;
    totalAssignedTariffs: number;
    monitoredTariffs: number;
    pendingMonitoringTariffs: number;
    newVersionCreatedTariffs: number;
  };
  globalStats: {
    totalAssignedLSE: number;
    totalAssignedTariffs: number;
    monitoredTariffs: number;
    pendingMonitoringTariffs: number;
    newVersionCreatedTariffs: number;
  };
}
export interface TariffStatusStatsResponse {
  results: TariffStatusStatsType[];
  apiStatus: 'idle' | 'pending' | 'resolved' | 'rejected';
  errors: restClient.ResponseError[] | undefined;
  count: number;
}
const initialState: TariffStatusStatsResponse = {
  results: [],
  apiStatus: 'pending',
  count: 0,
  errors: undefined,
};

export const fetchTariffStatusStats = createAsyncThunk(
  'opsManagement/status/stats/tariff',
  async (request: any, { dispatch, rejectWithValue }) => {
    try {
      const client = await OpsManagementApiClient();
      const response: restClient.PagedResponse<TariffStatusStatsType> =
        await client.opsData.tariffStatusStats();
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
      return response;
    } catch (err) {
      return rejectWithValue(
        handleUnexpectedThunkException(err, 'TariffStatusStatsSlice', dispatch)
      );
    }
  }
);

export const TariffStatusStatsSlice = createSlice({
  name: 'TariffStatusStatsSlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchTariffStatusStats.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(
      fetchTariffStatusStats.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<TariffStatusStatsType>>) => {
        const { count, results, errors } = action.payload;
        if (errors) {
          state.apiStatus = 'rejected';
          state.errors = errors;
        } else {
          state.apiStatus = 'resolved';
          state.count = count;
          state.results = results;
          state.errors = undefined;
        }
      }
    );
    builder.addCase(fetchTariffStatusStats.rejected, state => {
      state.apiStatus = 'rejected';
    });
  },
});

export default TariffStatusStatsSlice.reducer;

export const selectTariffStatusStats = (state: RootState): TariffStatusStatsResponse => {
  return state.tariffStatusStatsData;
};
