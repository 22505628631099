import { restClient } from '@genability/api';
import { GetCalculatedCostRequest } from '@genability/api/dist/api';
import { CalculatedCost } from '@genability/api/dist/types';

export class BetaApi extends restClient.RestApiClient {
  public async getCalcHistoryRequest(
    requestId: string
  ): Promise<restClient.PagedResponse<GetCalculatedCostRequest>> {
    return this.getPaged<GetCalculatedCostRequest>('/calculate/history/requests/' + requestId);
  }

  public async getCalcHistoryResponse(
    requestId: string
  ): Promise<restClient.PagedResponse<CalculatedCost>> {
    return this.getPaged<CalculatedCost>('/calculate/history/responses/' + requestId);
  }
}
