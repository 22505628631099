import { Button, Modal, Radio } from '@arcadiapower/shrike';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Task } from '../../task-api/v2/types/TaskV2';
import { bulkAssignmentType } from '../../task-api/v2/api/task-api-v2';
import { TaskApiV2Client } from '../../GenApiClient';
import { ASSIGNMENT_SUCCESS } from '../../utils/taskV2Utils';
import { SingleResponse } from '@genability/api/dist/rest-client';
import { addNotification } from '../../state/notification/notificationSlice';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import WorkerInput from '../WorkerInput/WorkerInput';
import { IWorker } from '../../task-api/types/worker';
import { EntityType } from '../../task-api/v2/types/EntityType';
import { QualificationType } from '../../task-api/v2/types/QualifictionType';

interface bulkProps {
  sortedItems: Task[];
  selected: boolean[];
  entityType: EntityType;
  refreshTabDetails: () => void;
}

export interface bulkAssignmentResponseType {
  taskId: number;
  taskStatus: string;
  assignmentStatus: string;
  assignmentSuccessful: boolean;
}

const BulkAssignTaskModal = (props: bulkProps) => {
  const dispatch = useDispatch();
  const [showAssignmentModal, setShowAssignmentModal] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [successCount, setSuccessCount] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [worker, setWorker] = useState<IWorker | undefined>(undefined);
  const [apiState, setApiState] = useState<string>('idle');
  const [response, setResponse] = useState<bulkAssignmentResponseType[]>();
  const [assigneeType, setAssigneeType] = useState<string>('Reviewer');
  const [qualification, setQualification] = useState<QualificationType>();

  useEffect(() => {
    if (apiState == 'resolved' && showAssignmentModal == false) props.refreshTabDetails();
  }, [apiState, showAssignmentModal]);

  useEffect(() => {
    if (props.entityType === EntityType.LOOKUP) {
      setQualification(
        assigneeType == 'Reviewer'
          ? QualificationType.LOOKUP_REVIEWER
          : QualificationType.LOOKUP_MODELLER
      );
    } else if (props.entityType === EntityType.PROPERTY) {
      setQualification(
        assigneeType == 'Reviewer'
          ? QualificationType.PROPERTY_KEY_REVIEWER
          : QualificationType.PROPERTY_KEY_MODELLER
      );
    } else if (props.entityType === EntityType.TARIFF) {
      setQualification(
        assigneeType == 'Reviewer'
          ? QualificationType.TARIFF_REVIEWER
          : QualificationType.TARIFF_MODELLER
      );
    }
  }, [props.entityType, assigneeType]);

  const assignTasktoUser = async (username: string | undefined) => {
    if (username) {
      setApiState('pending');
      const tariffTaskIds: string[] = [],
        tariffTaskStatuses: string[] = [];
      const bulkRows: bulkAssignmentType[] = props.sortedItems
        .map((val: Task) => {
          return {
            workflowId: val.workflowInstanceId,
            taskId: val.taskId,
            taskStatus: val.taskStatus,
          };
        })
        .filter((val: bulkAssignmentType, index: number) => {
          if (props.selected[index]) {
            tariffTaskIds.push(val.taskId + '');
            tariffTaskStatuses.push(val.taskStatus || '');
          }
          return props.selected[index];
        });

      try {
        const client = await TaskApiV2Client();
        let response: SingleResponse<bulkAssignmentResponseType>;
        if (props.entityType === EntityType.TARIFF) {
          response = await client.taskV2.assignTariffTaskToUser(
            tariffTaskIds,
            tariffTaskStatuses,
            username,
            assigneeType == 'Reviewer'
          );
        } else {
          response = await client.taskV2.bulkAssignTask(
            bulkRows,
            username,
            assigneeType == 'Reviewer'
          );
        }
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }
        const result = response.results.filter(
          result => result.assignmentStatus != 'Assigned Successfully'
        );
        setSuccessCount(response.results.length - result.length);
        setTotalCount(response.results.length);
        setResponse(result);

        if (result.length == 0) {
          dispatch(addNotification(ASSIGNMENT_SUCCESS, NotificationLevel.Success));
        } else {
          dispatch(addNotification('Assignments Updated With Errors', NotificationLevel.Error));
        }
      } catch (err: any) {
        dispatch(addNotification(err.toString(), NotificationLevel.Error));
        setShowAssignmentModal(false);
      }
      setApiState('resolved');
      setDisabled(true);
    }
  };

  const handleChange = (val: string) => {
    setAssigneeType(val);
  };

  return (
    <>
      <Button
        onClick={() => {
          setShowAssignmentModal(true);
        }}
        loading={false}
        size={'small'}
        className="mr-3 h-75"
      >
        Bulk assign
      </Button>
      <Modal aria-label="Modal" isOpen={showAssignmentModal} size="large">
        <Modal.Header title="Bulk Assign Task"></Modal.Header>
        <Modal.Content>
          <div className="d-flex">
            <div className="d-flex w-50 mt-3">
              <label className="h6 mr-4">Select target assignee type: </label>
              <Radio
                name="selection"
                value="Reviewer"
                onChange={val => handleChange(val)}
                checked={assigneeType == 'Reviewer'}
              >
                Reviewer
              </Radio>
              <Radio
                name="selection"
                value="Modeller"
                onChange={val => handleChange(val)}
                checked={assigneeType == 'Modeller'}
                className="ml-4 mr-4"
              >
                Modeller
              </Radio>
            </div>
            <div className="w-50 my-auto">
              <WorkerInput
                label={''}
                qualification={qualification}
                placeholder="Search assignee"
                id="assignTask"
                selected={worker}
                setSelected={setWorker}
              />
            </div>
          </div>
          {response && <p className="h5 mt-2 mb-4">Results</p>}
          {response && (
            <p className="h5 mb-1">{successCount + '/' + totalCount} reassignments succeeded</p>
          )}
          <div className="mt-3 container">
            {response?.map((e, index) => (
              <div className="row h6" key={index}>
                <span className="mr-1 my-auto">
                  {'Task ID <'}
                  {e.taskId}
                  {'>'}
                </span>
                <span className="mr-1 my-auto">
                  {'with workflow status <'}
                  {e.taskStatus}
                  {'>'}
                </span>
                <span className="mX-1 my-auto text-danger">
                  {"reassignment has failed with error msg '"}
                  {e.assignmentStatus}
                  {"'"}
                </span>
              </div>
            ))}
          </div>
        </Modal.Content>
        <Modal.Footer
          onSubmit={() => assignTasktoUser(worker?.userName)}
          loading={apiState === 'pending'}
          disabled={disabled}
          onCancel={() => setShowAssignmentModal(apiState == 'pending' || false)}
        ></Modal.Footer>
      </Modal>
    </>
  );
};

export default BulkAssignTaskModal;
