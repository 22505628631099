import React from 'react';
import { types } from '@genability/api';
import styles from './TariffPropertyForm.module.scss';
import { Container, Col, Row } from 'react-bootstrap';
import { TextInput, SelectInput, RadioInput, IconButton } from '@arcadiapower/gen-react-lib';
import PropertyKeyPopover from '../PropertyKeyPopover/PropertyKeyPopover';
import { withErrors, withFieldArray } from '../../utils/formUtils';
import { useFormContext } from 'react-hook-form';

interface TariffPropertyHookFormProps {
  className?: string;
  tariffProperty: types.TariffProperty | undefined;
  readOnly?: boolean;
  onDelete?: () => void;
  name: string;
  operatorRequired?: boolean;
  bands?: string[];
}

const TariffPropertyForm: React.FC<TariffPropertyHookFormProps> = ({
  tariffProperty,
  className,
  readOnly,
  onDelete,
  name,
  operatorRequired = false,
  bands,
}: TariffPropertyHookFormProps) => {
  const { register: parentRegister, formState, watch } = useFormContext();
  const { errors } = formState;

  const register = withFieldArray(withErrors(parentRegister, { errors }), {
    field: tariffProperty,
    name,
  });

  const operator = watch(`${name}.operator`);

  const renderOperatorField = (readOnly: boolean) => {
    const operators = ['', '=', '<', '<=', '>', '>=', 'between'];

    return (
      <Col sm={4} lg={3}>
        {readOnly ? (
          <TextInput
            type="text"
            readOnly={readOnly}
            plaintext
            label="Operator"
            placeholder="Operator"
            {...register('operator', {
              required: operatorRequired && 'Field is required',
            })}
          />
        ) : (
          <SelectInput
            label="Operator"
            readOnly={readOnly}
            {...register('operator', {
              required: operatorRequired && 'Field is required',
            })}
          >
            {operators.map(function (option, index) {
              return (
                <option key={index} value={option}>
                  {option}
                </option>
              );
            })}
          </SelectInput>
        )}
      </Col>
    );
  };

  const renderControls = (dataType: string | undefined) => {
    if (dataType === types.PropertyDataType.BOOLEAN) {
      return (
        <Row>
          {renderOperatorField(true)}
          <Col>
            <div className={styles.flex}>
              {['true', 'false'].map(function (option, index) {
                return (
                  <RadioInput
                    id={`${name}_${index}`}
                    disabled={readOnly}
                    value={option}
                    label={option}
                    key={index}
                    inline
                    {...register('propertyValue', {
                      check: option,
                      shouldUnregister: true,
                      required: 'Field is required',
                    })}
                  />
                );
              })}
            </div>
          </Col>
        </Row>
      );
    } else if (dataType === types.PropertyDataType.CHOICE) {
      // Sort choices so that in a long list of choices, the choices actually specified by the rate(s) containing
      // the property key come first, followed by the remaining choices.

      // This is only necessary because some rate criteria can be set to a "none" option by default, meaning the
      // rates where those criteria are set do not apply by default, and the value of "none" is different for each
      // property key.

      const choices = [...(tariffProperty?.choices || [])];
      const selectedChoices = bands?.reduce(
        (options: types.GenPropertyChoice[], option: string): types.GenPropertyChoice[] => {
          const index = choices.findIndex(choice => choice.dataValue === option);
          if (index === -1) return options;
          options.push(choices.splice(index, 1)[0]);
          return options;
        },
        []
      );
      return (
        <Row>
          {renderOperatorField(true)}
          <Col>
            <SelectInput
              data-testid="choiceSelect"
              label="Property Value"
              readOnly={readOnly}
              {...register('propertyValue', {
                shouldUnregister: true,
                required: 'Field is required',
              })}
            >
              <option value="">No choices selected</option>
              {selectedChoices?.length ? (
                <>
                  <optgroup label="Selected Choices">
                    {selectedChoices?.map((selectedChoice, index) => (
                      <option key={index} value={selectedChoice?.dataValue}>
                        {selectedChoice?.displayValue}
                      </option>
                    ))}
                  </optgroup>
                  <optgroup label="Aditional Choices">
                    {tariffProperty?.choices?.map((option, index) => (
                      <option key={index} value={option.dataValue}>
                        {option.displayValue}
                      </option>
                    ))}
                  </optgroup>
                </>
              ) : (
                tariffProperty?.choices?.map((option, index) => (
                  <option key={index} value={option.dataValue}>
                    {option.displayValue}
                  </option>
                ))
              )}
            </SelectInput>
          </Col>
        </Row>
      );
    } else if (
      dataType === types.PropertyDataType.DATE ||
      dataType === types.PropertyDataType.DECIMAL ||
      dataType === types.PropertyDataType.INTEGER ||
      dataType === types.PropertyDataType.DEMAND
    ) {
      const inputType = dataType === types.PropertyDataType.DATE ? 'date' : 'number';
      return (
        <Row>
          {renderOperatorField(false)}
          <Col>
            {operator === 'between' ? (
              <Row>
                <Col>
                  <TextInput
                    type={inputType}
                    readOnly={readOnly}
                    label="Min"
                    placeholder="Property Value"
                    {...register('minValue', {
                      shouldUnregister: true,
                      required: 'Field is required',
                    })}
                  />
                </Col>
                <Col>
                  <TextInput
                    type={inputType}
                    readOnly={readOnly}
                    label="Max"
                    placeholder="Property Value"
                    {...register('maxValue', {
                      shouldUnregister: true,
                      required: 'Field is required',
                    })}
                  />
                </Col>
              </Row>
            ) : (
              <TextInput
                type={inputType}
                readOnly={readOnly}
                label="Property Value"
                placeholder={tariffProperty?.keyName}
                {...register('propertyValue', {
                  shouldUnregister: true,
                  required: 'Field is required',
                })}
              />
            )}
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          {renderOperatorField(true)}
          <Col>
            <TextInput
              type="text"
              readOnly={readOnly}
              label="Property Value"
              placeholder={tariffProperty?.keyName}
              {...register('propertyValue', { required: 'Field is required' })}
            />
          </Col>
        </Row>
      );
    }
  };

  return (
    <React.Fragment>
      <div className={`${styles.container} ${className}`}>
        <Container>
          <Row>
            <Col xs={12}>
              <b>{tariffProperty?.keyName}</b>
              {tariffProperty && <PropertyKeyPopover propertyKey={tariffProperty} />}
              {tariffProperty?.period}
            </Col>
          </Row>
          <Row>
            <Col xs={11}>{renderControls(tariffProperty?.dataType)}</Col>
            {onDelete && (
              <Col xs={1}>
                <IconButton icon="delete" onClick={onDelete} />
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TariffPropertyForm;
