import React, { useRef, useCallback, useImperativeHandle } from 'react';
import { types } from '@genability/api';
import { SelectInput } from '@arcadiapower/gen-react-lib';
import { FormGroupProps } from 'react-bootstrap/FormGroup';
import { FormControlProps } from 'react-bootstrap/FormControl';

export interface SeasonDropdownProps extends FormGroupProps, FormControlProps {
  seasonGroups: types.SeasonGroup[];
  size?: 'sm' | 'lg';
  variant?: 'default' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger';
  feedback?: string;
  label?: string;
}

const SeasonDropdown = React.forwardRef(
  ({ seasonGroups, size, feedback, label, ...rest }: SeasonDropdownProps, forwardRef) => {
    const inputRef = useRef<HTMLDivElement | null>(null);
    useImperativeHandle(forwardRef, () => inputRef.current);
    const setRef = useCallback(
      input => {
        inputRef.current = input;
      },
      [inputRef]
    );

    const renderHeader = (seasonGroup: types.SeasonGroup) => {
      return `Season Group ${seasonGroup.seasonGroupId}`;
    };

    const renderItems = (season: types.Season) => {
      return (
        <option key={season.seasonId} value={season.seasonId}>
          {`${season.seasonName} ${season.seasonFromMonth}/${season.seasonFromDay} - ${season.seasonToMonth}/${season.seasonToDay}`}
        </option>
      );
    };

    return (
      <div>
        <SelectInput
          placeholder="Select season..."
          size={size}
          ref={setRef}
          feedback={feedback}
          label={label}
          {...rest}
        >
          {seasonGroups.map((seasonGroup: types.SeasonGroup) => {
            return (
              <optgroup key={seasonGroup.seasonGroupId} label={renderHeader(seasonGroup)}>
                {seasonGroup.seasons?.map(season => {
                  return renderItems(season);
                })}
              </optgroup>
            );
          })}
        </SelectInput>
      </div>
    );
  }
);

SeasonDropdown.displayName = 'SeasonDropdown';

export default SeasonDropdown;
