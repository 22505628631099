import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { restClient, types } from '@genability/api';
import { RootState } from '../rootReducer';
import { GenApiClient } from '../../GenApiClient';

export type TariffVersion = {
  effectiveDate: string;
  lastUpdatedDate: string;
  lookupVariableRates: Record<string, any>[];
  riderVersions: Record<string, any>[];
  tariffId: number;
};

type TariffHistory = types.Tariff & {
  tariffVersions?: TariffVersion[] | undefined;
};

export interface TariffHistoryState {
  tariffHistory: TariffHistory | null;
  masterTariffId: number | null;
  error: string | null;
  currentRequestId: string | undefined;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

export const initialState: TariffHistoryState = {
  tariffHistory: null,
  masterTariffId: null,
  error: null,
  currentRequestId: undefined,
  loading: 'idle',
};

export const fetchTariffHistory = createAsyncThunk<
  restClient.SingleResponse<types.Tariff> | void,
  number,
  { state: { tariffHistory: TariffHistoryState } }
>(
  'tariffHistorySlice/fetchTariffHistory',
  async (
    masterTariffId: number,
    { getState, requestId }
  ): Promise<restClient.SingleResponse<types.Tariff> | void> => {
    const { currentRequestId, loading } = getState().tariffHistory;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return;
    }

    const client = await GenApiClient();
    const history = await client.tariffs.getTariffHistory(masterTariffId);

    return { ...history } as restClient.SingleResponse<types.Tariff>;
  }
);

export const tariffHistorySlice = createSlice({
  name: 'tariff',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchTariffHistory.pending, (state, action) => {
        state.loading = 'pending';
        state.currentRequestId = action.meta.requestId;
        state.error = null;
        state.masterTariffId = action.meta.arg;
      })
      .addCase(fetchTariffHistory.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.currentRequestId !== requestId) {
          return; // ignore stale
        }
        state.loading = 'idle';
        if (action.payload && action.payload.count) {
          state.tariffHistory = action.payload.results[0];
        }
        state.currentRequestId = undefined;
        state.error = null;
      })
      .addCase(fetchTariffHistory.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.currentRequestId != requestId) {
          return; // ignore state
        }
        state.loading = 'idle';
        state.tariffHistory = null;
        state.currentRequestId = undefined;
        state.error = action.error?.message || String(action.error);
      });
  },
});

export default tariffHistorySlice.reducer;

export const selectTariffHistory = (state: RootState): TariffHistoryState => state.tariffHistory;
