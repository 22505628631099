import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TaskAssignment, TaskAssignmentStatus } from '../../task-api/types/task';
import { restClient } from '@genability/api';
import { TaskApiClient } from '../../GenApiClient';
import { RootState } from '../rootReducer';
import { handleUnexpectedThunkException } from '../reduxUtils';
import { GetTaskAssignmentsRequest } from '../../task-api/api/task-assignment-api';

export interface TaskAssignmentsState {
  apiStatus: 'idle' | 'pending' | 'notfound' | 'resolved' | 'rejected' | 'submitted';
  count: number;
  results: TaskAssignment[];
  errors: restClient.ResponseError[] | undefined;
}

const initialState: TaskAssignmentsState = {
  apiStatus: 'idle',
  count: 0,
  results: [],
  errors: undefined,
};

interface FetchTaskAssignmentsArgs {
  worker: string;
}

export const fetchTaskAssignments = createAsyncThunk(
  'taskAssignments/fetchTaskAssignments',
  async (arg: FetchTaskAssignmentsArgs, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiClient();
      const taskAssignmentRequest = new GetTaskAssignmentsRequest();
      taskAssignmentRequest.worker = arg.worker;
      // Temporarily adding READY_TO_REVIEW and REVIEWING to support editing after initial work is submitted.
      taskAssignmentRequest.assignmentStatuses = [
        TaskAssignmentStatus.POSTED,
        TaskAssignmentStatus.ASSIGNED,
        TaskAssignmentStatus.READY_TO_REVIEW,
        TaskAssignmentStatus.REVIEWING,
      ];
      taskAssignmentRequest.populateTask = true;
      taskAssignmentRequest.populateAssignmentAnswers = true;
      const assignments = await client.taskAssignment.getTaskAssignments(taskAssignmentRequest);

      // use spread to avoid non serializable error
      return { ...assignments };
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'TaskAssignment', dispatch));
    }
  }
);

export const TaskAssignmentsSlice = createSlice({
  name: 'taskAssignments',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchTaskAssignments.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(
      fetchTaskAssignments.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<TaskAssignment>>) => {
        const { results, errors } = action.payload;
        if (errors) {
          state.apiStatus = 'rejected';
          state.errors = errors;
        } else {
          // Don't show 02_x tasks in the Do panel until they're implemented in Team Tariff
          const filtered02_xTasks = results.filter(task => !task.taskTypeId?.startsWith('02_'));
          state.apiStatus = 'resolved';
          state.results = filtered02_xTasks;
          state.errors = undefined;
        }
      }
    );
    builder.addCase(fetchTaskAssignments.rejected, state => {
      state.apiStatus = 'rejected';
    });
  },
});

export const selectApiStatus = (state: RootState): string => {
  return state.taskAssignments.apiStatus;
};

export const selectCurrentTaskAssignments = (state: RootState): TaskAssignment[] => {
  return state.taskAssignments.results;
};

export default TaskAssignmentsSlice.reducer;
