import { restApis } from '@genability/api';
import { createContext, useContext } from 'react';
import { AppDispatch } from '../state/store';
import { getUtilities } from '../state/utilities/utilitiesSlice';
import { IFilterContext } from './types';

export type UtilityFilterContext = `UtilityFilterContext`;

export type IUtilityFilter = Omit<
  restApis.GetLoadServingEntitiesRequest,
  | 'addParams'
  | 'queryStringify'
  | 'addPaginationParams'
  | 'addSearchParams'
  | 'addSortParams'
  | 'addFieldsParam'
>;

export const onUtilitiesFilterChange = (dispatch: AppDispatch, options: IUtilityFilter): void => {
  dispatch(
    getUtilities({
      ...options,
    })
  );
};

export const initialUtilityFilterContext: IFilterContext<IUtilityFilter> = {
  filter: {
    postCode: undefined,
    zipCode: undefined,
    country: undefined,
    serviceTypes: [],
    ownerships: [],
    pageCount: 25,
    pageStart: 0,
  },
  setFilter: () => ({}),
  onFilterChange: onUtilitiesFilterChange,
};

export const UtilityFilterContext = createContext<IFilterContext<IUtilityFilter>>(
  initialUtilityFilterContext
);

export const useUtilityFilterContext = (): IFilterContext<IUtilityFilter> =>
  useContext(UtilityFilterContext);
