import React, { useState } from 'react';
import { Button, TextInput } from '@arcadiapower/gen-react-lib';
import styles from './TaskCommentBox.module.scss';

interface TaskCommentBoxProps {
  submitComment: (comment: string) => void;
}

const TaskCommentBox: React.FC<TaskCommentBoxProps> = ({ submitComment }: TaskCommentBoxProps) => {
  const [showCommentSave, setShowCommentSave] = useState(false);
  const [comment, setComment] = useState('');

  const handleCommentBox = (event: React.SyntheticEvent<EventTarget>) => {
    const commentValue = (event.target as HTMLInputElement).value;
    if (commentValue.trim()) {
      setComment(commentValue);
      setShowCommentSave(true);
    } else {
      setComment(commentValue);
      setShowCommentSave(false);
    }
  };

  const saveComment = () => {
    submitComment(comment);
    setComment('');
    setShowCommentSave(false);
  };

  const onCancel = () => {
    setComment('');
    setShowCommentSave(false);
  };

  return (
    <React.Fragment>
      <TextInput
        rows={3}
        label=""
        type="text"
        as="textarea"
        id="comment-box-id"
        value={comment}
        placeholder="Add comment..."
        onChange={handleCommentBox}
      />
      <div className={styles.buttonContainer}>
        <Button variant="link" action={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" disabled={!showCommentSave} action={saveComment}>
          Save
        </Button>
      </div>
    </React.Fragment>
  );
};

export default TaskCommentBox;
