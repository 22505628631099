import React, { useEffect, useState } from 'react';
import { TaskAssignment } from '../../task-api/types/task';
import { useHistory } from 'react-router-dom';
import { Table, Th, Tr, useSortableData, IconButton } from '@arcadiapower/gen-react-lib';
import appConstants from '../../app-constants';
import styles from './Do.module.scss';
import { selectCurrentUser } from '../../state/currentUser/currentUserSlice';

import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTaskAssignments,
  selectCurrentTaskAssignments,
  selectApiStatus,
} from '../../state/taskAssignments/taskAssignmentsSlice';
import ago, { onAt } from '../../utils/dateUtils';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const { REACT_APP_MOTHER_SERVER = 'https://mother.genability.com' } = process.env;

const Do = (): React.ReactElement => {
  const history = useHistory();
  const username: string = useSelector(selectCurrentUser).username;
  const apiStatus: string = useSelector(selectApiStatus);
  const userTaskAssignments: TaskAssignment[] = useSelector(selectCurrentTaskAssignments);
  const [toggleRefresh, setToggleRefresh] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTaskAssignments({ worker: username }));
  }, [dispatch, username, toggleRefresh]);

  const { sortedItems, sortConfig, handleSort } = useSortableData(userTaskAssignments, {
    sortOn: ['lastUpdatedDate'],
    sortOrder: ['DESC'],
  });

  const columns = [
    {
      key: 'taskAssignmentId',
      label: 'Task Assignment ID',
    },
    {
      key: 'lseId',
      label: 'LSE',
      accessor: (taskAssignment: TaskAssignment) => taskAssignment.task?.lseId,
    },
    {
      key: 'taskId',
      label: 'Task ID',
      accessor: (taskAssignment: TaskAssignment) => taskAssignment.task?.taskId,
    },
    {
      key: 'taskTypeId',
      label: 'Task Type',
      accessor: (taskAssignment: TaskAssignment) => taskAssignment.task?.taskType.taskTypeId,
    },
    {
      key: 'taskTitle',
      label: 'Summary',
      accessor: (taskAssignment: TaskAssignment) => taskAssignment.task?.taskTitle,
    },
    {
      key: 'assignmentStatus',
      label: 'Status',
    },
    {
      key: 'postedDate',
      label: 'Posted On',
      accessor: (taskAssignment: TaskAssignment) => taskAssignment.task?.postedDate,
    },
    { key: 'lastUpdatedDate', label: 'Last Update' },
  ];

  return (
    <div className={styles.wrapper}>
      <>
        <div className="row">
          <div className="col d-flex">
            <h1>My Assignments </h1>
          </div>
          <div className={'col d-flex justify-content-end'}>
            <div>
              <IconButton
                icon="refresh"
                spin={apiStatus === 'idle' || apiStatus === 'pending'}
                onClick={() => setToggleRefresh(!toggleRefresh)}
              />
            </div>
          </div>
        </div>
        <Table hover>
          <thead>
            <tr>
              {columns.map((column, idx) => (
                <Th
                  key={idx}
                  sortConfig={sortConfig}
                  sortKey={column.key}
                  onClick={column.key ? () => handleSort(column.key, column.accessor) : undefined}
                >
                  {column.label}
                </Th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedItems &&
              sortedItems.map((taskAssignment: TaskAssignment) => (
                <Tr
                  key={taskAssignment.taskAssignmentId}
                  onClick={() =>
                    history.push(`${appConstants.routes.do}/${taskAssignment.taskAssignmentId}`)
                  }
                >
                  <td>
                    <OverlayTrigger
                      key="openInMother"
                      placement="top"
                      overlay={<Tooltip id={`tooltip-openInMother`}>Open task in Mother</Tooltip>}
                    >
                      <a
                        onClick={e => e.stopPropagation()}
                        href={`${REACT_APP_MOTHER_SERVER}/mother/assignments/${taskAssignment.taskAssignmentId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {taskAssignment.taskAssignmentId}
                      </a>
                    </OverlayTrigger>
                  </td>
                  <td>{taskAssignment.task?.lseName}</td>
                  <td>{taskAssignment.task?.taskId}</td>
                  <td>{taskAssignment.task?.taskType.taskTypeId}</td>
                  <td>{taskAssignment.task?.taskTitle}</td>
                  <td>{taskAssignment.assignmentStatus}</td>
                  <td>{taskAssignment.task ? ago(taskAssignment.task.postedDate) : ''}</td>
                  <td>{onAt(taskAssignment.lastUpdatedDate)}</td>
                </Tr>
              ))}
            {(!userTaskAssignments || userTaskAssignments.length === 0) && (
              <tr>
                <td className="no-results" colSpan={12}>
                  No results
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </>
    </div>
  );
};

export default Do;
