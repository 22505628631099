import React, { useState } from 'react';
import { types, TariffRateFactory } from '@genability/api';
import styles from './RateGroupSection.module.scss';
import { Button, IconButton, TextInput } from '@arcadiapower/gen-react-lib';
import { TariffRateGroup } from '../../utils/useTariffAnswer';

interface RateGroupSectionProps {
  rateGroup: TariffRateGroup;
  onUpdateGroupName: (groupIndex: number, name: string) => void;
  onDeleteGroup: (groupIndex: number) => Promise<boolean>;
  onAddRate: (rate: types.TariffRate, tariffSequenceNumber: number) => void;
  onAddRateGroup: (groupIndex: number) => void;
  onMoveGroupUp?: () => void;
  onMoveGroupDown?: () => void;
  children?: React.ReactNode;
  disabled?: boolean | false;
}

const RateGroupSection: React.FC<RateGroupSectionProps> = ({
  rateGroup,
  onUpdateGroupName,
  onDeleteGroup,
  onAddRate,
  onAddRateGroup,
  onMoveGroupUp,
  onMoveGroupDown,
  children,
  disabled,
}: RateGroupSectionProps): React.ReactElement => {
  const [editRateGroupName, setEditRateGroupName] = useState(false);
  const [rateGroupName, setRateGroupName] = useState(rateGroup.groupName);

  const addRateGroup = (isBefore: boolean) => {
    if (!isBefore) {
      onAddRateGroup(rateGroup.groupIndex + 1);
    } else {
      onAddRateGroup(rateGroup.groupIndex);
    }
  };

  const addTariffRate = (rateGroupName: string, rateGenerate: () => types.TariffRate) => {
    const newRate: types.TariffRate = rateGenerate();
    newRate.rateGroupName = rateGroupName;
    onAddRate(newRate, rateGroup.endSequenceNumber + 1);
  };

  const onSaveRename = () => {
    onUpdateGroupName(rateGroup.groupIndex, rateGroupName);
    setEditRateGroupName(false);
  };

  const onCancelRename = () => {
    setRateGroupName(rateGroup.groupName);
    setEditRateGroupName(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setRateGroupName(value);
  };
  const addRateOptions = [
    {
      label: 'Consumption',
      factoryMethod: TariffRateFactory.createConsumptionRate,
    },
    {
      label: 'Demand',
      factoryMethod: TariffRateFactory.createDemandRate,
    },
    {
      label: 'Fixed',
      factoryMethod: TariffRateFactory.createFixedRate,
    },
    {
      label: 'Min',
      factoryMethod: TariffRateFactory.createMinimumRate,
    },
    {
      label: 'Max',
      factoryMethod: TariffRateFactory.createMaximumRate,
    },
    {
      label: 'Quantity',
      factoryMethod: TariffRateFactory.createQuantityRate,
    },
    {
      label: 'Percentage',
      factoryMethod: TariffRateFactory.createPercentageRate,
    },
    {
      label: 'Tax',
      factoryMethod: TariffRateFactory.createTaxRate,
    },
    {
      label: 'Net Excess Generation',
      factoryMethod: TariffRateFactory.createNetExcessGenerationRate,
    },
  ];

  return (
    <section
      key={rateGroup.groupIndex}
      className={styles.container}
      id={`rateGroup-${rateGroup.groupIndex}`}
      tabIndex={rateGroup.groupIndex}
    >
      {editRateGroupName && !disabled ? (
        <div className={styles.headerSection}>
          <div className="d-flex">
            <TextInput
              type="text"
              name="tariffRate-rateGroupName"
              label={'Rate Group Name'}
              placeholder={'Rate Group Name'}
              clear={false}
              onChange={handleInputChange}
              value={rateGroupName}
            />
            <div>
              <IconButton
                className={styles.iconBtn}
                icon="check"
                size="sm"
                onClick={onSaveRename}
              />
              <IconButton
                className={styles.iconBtn}
                icon="close"
                size="sm"
                onClick={onCancelRename}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.headerSection}>
          <div className={`d-flex w-75`}>
            <h4 className={styles.rateGroupName}>{rateGroupName}</h4>
            <div className={styles.editRateGroupPencil}>
              <IconButton
                className={styles.iconBtn}
                icon="edit"
                size="sm"
                disabled={disabled}
                onClick={() => {
                  setEditRateGroupName(true);
                }}
              />
            </div>
          </div>
          <div className={`d-flex w-25 justify-content-end`}>
            <IconButton
              className={styles.iconBtn}
              icon="up"
              size="sm"
              onClick={onMoveGroupUp}
              disabled={disabled ? true : onMoveGroupUp ? false : true}
              data-testid={`moveUpBtn-${rateGroup.groupIndex}`}
            />
            <IconButton
              className={styles.iconBtn}
              icon="down"
              size="sm"
              onClick={onMoveGroupDown}
              disabled={disabled ? true : onMoveGroupDown ? false : true}
              data-testid={`moveDownBtn-${rateGroup.groupIndex}`}
            />
            <IconButton
              className={styles.iconBtn}
              icon="delete"
              size="sm"
              onClick={async () => {
                onDeleteGroup(rateGroup.groupIndex);
              }}
              disabled={disabled}
            />
          </div>
        </div>
      )}
      <div className={styles.ratesSection}>{children}</div>
      <div className={styles.addRateLinksSection}>
        <span className={styles.linkPreambleSm}>+ Add New Rate</span>
        {addRateOptions.map(addRateOption => {
          return (
            <Button
              key={addRateOption.label}
              size="sm"
              variant="link"
              disabled={disabled}
              action={() => {
                addTariffRate(rateGroup.groupName, addRateOption.factoryMethod);
              }}
            >
              {addRateOption.label}
            </Button>
          );
        })}
      </div>
      <div className={styles.addNewGroupLinksSection}>
        <span className={styles.linkPreambleSm}>+ Add New Group</span>
        <Button size="sm" variant="link" action={() => addRateGroup(true)} disabled={disabled}>
          Before
        </Button>
        <Button size="sm" variant="link" action={() => addRateGroup(false)} disabled={disabled}>
          After
        </Button>
      </div>
    </section>
  );
};

export default RateGroupSection;
