import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { restClient } from '@genability/api';
import { RootState } from '../rootReducer';
import { TaskApiClient } from '../../GenApiClient';
import { ResourceDiff } from '../../task-api/types/resource-diff';
import { LoadingState } from '../reduxUtils';

export type TariffDiffMeta = {
  baseTariffId: number | null;
  comparisonTariffId: number | null;
};

export interface TariffDiffState {
  meta: TariffDiffMeta;
  result: ResourceDiff | undefined;
  errors: restClient.ResponseError[] | undefined;
  currentRequestId: string | undefined;
  loading: LoadingState;
  status: string;
}

export interface ITariffDiffSuccess {
  status: string;
  meta: TariffDiffMeta;
  result: ResourceDiff | undefined;
  errors: restClient.ResponseError[] | undefined;
  currentRequestId: string | undefined;
}

export const initialState: TariffDiffState = {
  loading: LoadingState.IDLE,
  status: '',
  meta: {
    baseTariffId: null,
    comparisonTariffId: null,
  },
  result: undefined,
  errors: undefined,
  currentRequestId: undefined,
};

const startLoading = (state: TariffDiffState) => {
  state.loading = LoadingState.PENDING;
};

const loadingFailed = (
  state: TariffDiffState,
  action: PayloadAction<restClient.ResponseError[]>
) => {
  state.loading = LoadingState.FAILED;
  state.status = 'error';
  state.errors = action.payload;
};

export type GetTariffDiffRequest = {
  baseTariffId: number;
  comparisonTariffId: number;
};

export const tariffDiffSlice = createSlice({
  name: 'tariffDiff',
  initialState,
  reducers: {
    getTariffDiffStart: startLoading,
    getTariffDiffSuccess(state, { payload }: PayloadAction<ITariffDiffSuccess>) {
      const { errors, status, result, currentRequestId, meta } = payload;
      state.loading = LoadingState.SUCCEEDED;
      state.status = status;
      state.errors = errors;
      state.result = result;
      state.currentRequestId = currentRequestId;
      state.meta = meta;
    },
    getTariffDiffFailure: loadingFailed,
  },
});

export const { getTariffDiffStart, getTariffDiffSuccess, getTariffDiffFailure } =
  tariffDiffSlice.actions;

export default tariffDiffSlice.reducer;

export const fetchTariffDiff = createAsyncThunk(
  'tariffs/fetchTariffDiff',
  async (params: GetTariffDiffRequest, { dispatch, getState, requestId }): Promise<void> => {
    const {
      tariffs: { currentRequestId: currentReqId, loading },
    } = getState() as RootState;
    if (loading === LoadingState.PENDING || currentReqId === requestId) return;
    dispatch(getTariffDiffStart());

    const client = await TaskApiClient();
    const response: restClient.SingleResponse<ResourceDiff> =
      await client.tariffAdmin.getTariffDiff(params.baseTariffId, params.comparisonTariffId);
    const { status, results, errors } = response;
    const tariffSuccess: ITariffDiffSuccess = {
      status,
      meta: {
        baseTariffId: params.baseTariffId,
        comparisonTariffId: params.comparisonTariffId,
      },
      result: results[0],
      errors,
      currentRequestId: requestId,
    };

    if (errors?.length) {
      dispatch(getTariffDiffFailure(errors));
    } else {
      dispatch(getTariffDiffSuccess(tariffSuccess));
    }
  }
);

export const selectTariffDiff = (state: RootState): TariffDiffState => state.tariffDiff;
