import React from 'react';
import { Icon } from '@arcadiapower/gen-react-lib';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { OverlayTriggerProps } from 'react-bootstrap/OverlayTrigger';
import styles from './UserHelpPopover.module.scss';

interface UserHelpPopoverProps extends Pick<OverlayTriggerProps, 'placement'> {
  id: string;
  children: React.ReactNode;
}

const UserHelpPopover: React.FC<UserHelpPopoverProps> = ({
  id,
  placement = 'top',
  children,
}: UserHelpPopoverProps) => {
  return (
    <OverlayTrigger
      trigger="hover"
      placement={placement}
      overlay={
        <Popover id={id}>
          <Popover.Content>
            <>{children}</>
          </Popover.Content>
        </Popover>
      }
    >
      <Icon iconName="question" className={styles.infoPopoverTrigger} />
    </OverlayTrigger>
  );
};

export default UserHelpPopover;
