import React from 'react';
import { IconButton } from '@arcadiapower/gen-react-lib';
import { types } from '@genability/api';
import { Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from './RiderCard.module.scss';

export interface RiderCardProps {
  className?: string;
  tariff?: types.Tariff;
  rate?: types.TariffRate;
  onAddRider?: () => void;
  onDeleteRider?: () => void;
}

const RiderCard: React.FC<RiderCardProps> = ({
  className,
  tariff,
  rate,
  onAddRider,
  onDeleteRider,
}: RiderCardProps): React.ReactElement => {
  let masterTariffId, tariffName, masterTariffRateId;

  if (tariff) {
    masterTariffId = tariff.masterTariffId;
    tariffName = `${tariff.tariffCode} : ${tariff.tariffName}`;
    masterTariffRateId = '';
  } else if (rate) {
    masterTariffId = rate.riderId;
    tariffName = rate.rateName;
    masterTariffRateId = rate?.masterTariffRateId ? rate.masterTariffRateId : 'None';
  }
  return (
    <Row className={`${styles.riderCardSection} ${className}`}>
      <Col xs={2}>
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="top"
          overlay={<Tooltip id="mtid">Master Tariff ID</Tooltip>}
        >
          <div className={styles.tooltipContent}>{masterTariffId}</div>
        </OverlayTrigger>
      </Col>
      <Col xs={2}>
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="top"
          overlay={<Tooltip id="mtrid">Master Tariff Rate ID</Tooltip>}
        >
          <div className={styles.tooltipContent}>{masterTariffRateId}</div>
        </OverlayTrigger>
      </Col>
      <Col xs={6}>{tariffName}</Col>
      <Col xs={2}>
        <div className={styles.addIcon}>
          {onAddRider && (
            <IconButton
              size="sm"
              icon="add"
              onClick={() => {
                onAddRider();
              }}
            />
          )}
          {onDeleteRider && (
            <IconButton
              size="sm"
              icon="delete"
              onClick={() => {
                onDeleteRider();
              }}
            />
          )}
        </div>
      </Col>
    </Row>
  );
};

export default RiderCard;
