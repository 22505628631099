import { Icon, lightTheme, Text } from '@arcadiapower/shrike';
import React, { FC, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { format, parseISO } from 'date-fns';
import OverlayLink from '../../OverlayLink/OverlayLink';

export interface TaskDetailHeaderParams {
  taskId: string;
  lseName?: string | null;
  taskType?: string;
  taskStatus?: string;
  propertyKey?: string | null;
  lastUpdatedDate?: string | null;

  refreshTaskDetails?: () => void;
}

const TaskHeader: FC<TaskDetailHeaderParams> = ({
  taskId,
  taskType,
  taskStatus,
  refreshTaskDetails,
  lastUpdatedDate,
  propertyKey,
  lseName,
}) => {
  const dateFormatter = useCallback((date: string) => format(parseISO(date), 'dd-MMM-yyyy'), []);
  return (
    <Row
      style={{
        borderBottom: `2px solid ${lightTheme.colors.content.accent9}`,
        marginTop: '100px',
      }}
      className="d-flex justify-content-between"
    >
      <Col xs={9} className="mb-3 ml-3">
        <Text color="primary" opacity="high" tag="p" textStyle="heading700">
          {(lseName != null ? lseName : '') +
            (propertyKey && propertyKey.trim() != '' ? ' | ' : '')}
          {propertyKey && propertyKey.trim() != '' && (
            <OverlayLink
              id={propertyKey}
              fontSize={600}
              openInNewTab={true}
              url={`/directory/property-keys/${propertyKey}`}
              displayText={`${propertyKey}`}
            />
          )}
        </Text>
        <Text color="primary" opacity="medium" tag="h1" textStyle="heading500" className="mt-2">
          Task Id: {taskId} | Task Status: {taskStatus} | Last Updated On:
          {dateFormatter(String(lastUpdatedDate))} | TaskType: {taskType}
        </Text>
      </Col>
      <Col xs={2} className="d-flex justify-content-end mb-2">
        <Icon
          className="justify-content-end mb-2"
          color="primary"
          icon="UIRefresh"
          margin="5px"
          opacity="high"
          scale={4}
          title="Refresh task details"
          onClick={refreshTaskDetails}
        />
      </Col>
    </Row>
  );
};

export default TaskHeader;
