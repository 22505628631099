import { restClient } from '@genability/api';
import {
  TariffDataRequestParams,
  TariffConfigData,
} from '../../components/OpsManagement/Config/TariffConfigTable';
import {
  LookupDataRequestParams,
  LookupConfigData,
} from '../../components/OpsManagement/Config/LookupConfigTable';
import { TariffStatusData } from '../../components/OpsManagement/Status/TariffStatusTable';
import { LookupStatusData } from '../../components/OpsManagement/Status/LookupStatusTable';
import {
  LookupConfigDataProps,
  UpdatedLookupConfigDataResults,
  UpdateLookupConfigProps,
  UpdateLookupConfigRequest,
} from '../../state/OpsManagement/Config/LookupConfigSlice';
import {
  TariffConfigDataProps,
  UserAssignmentConfig,
  UpdatedTariffConfigDataResults,
  UpdateTariffConfigRequest,
} from '../../state/OpsManagement/Config/TariffConfigSlice';
import { LookupStatusProps } from '../../state/OpsManagement/Status/LookupStatusSlice';
import { TariffStatusProps } from '../../state/OpsManagement/Status/TariffStatusSlice';
import { LookupConfigStatsType } from '../../state/OpsManagement/Config/LookupConfigStatsSlice';
import { TariffConfigStatsType } from '../../state/OpsManagement/Config/TariffConfigStatsSlice';
import {
  UpdateMonitoringStatusRequest,
  UpdatedMonitoringStatusData,
} from '../../state/OpsManagement/Status/UpdateStatusSlice';
import { MonitoringHistory } from '../types/MonitoringHistory';
import { LookupStatusStatsType } from '../../state/OpsManagement/Status/LookupStatusStatsSlice';
import { TariffStatusStatsType } from '../../state/OpsManagement/Status/TariffStatusStatsSlice';

export class GetMonitoringHistoryRequest extends restClient.BasePagedRequest {
  public monitoringInventoryId?: number;

  addParams(addParam: restClient.AddParamCallback): void {
    addParam('monitoringInventoryId', this.monitoringInventoryId);
  }
}
export class OpsManagementApi extends restClient.RestApiClient {
  public async fetchTariffConfigData(
    reqBody: TariffConfigDataProps,
    params: TariffDataRequestParams
  ): Promise<restClient.PagedResponse<TariffConfigData>> {
    return this.post(
      `/ops-management/configuration/tariffs?showInActive=${params.showInActiveTariff}&showUnassigned=${params.showUnassignedTariff}`,
      reqBody
    );
  }

  public async fetchLookupConfigData(
    reqBody: LookupConfigDataProps,
    params: LookupDataRequestParams
  ): Promise<restClient.PagedResponse<LookupConfigData>> {
    return this.post(
      `/ops-management/configuration/lookups?showUnassigned=${params.showUnassignedLookups}`,
      reqBody
    );
  }

  public async updateTariffConfigData(
    reqBody: UserAssignmentConfig[]
  ): Promise<restClient.PagedResponse<UpdatedTariffConfigDataResults>> {
    return this.post(`/ops-management/configuration/tariffs/upsert`, reqBody);
  }

  public async updateTariffConfigDataForAllMatches(
    reqBody: UpdateTariffConfigRequest
  ): Promise<restClient.PagedResponse<UpdatedTariffConfigDataResults>> {
    return this.post(`/ops-management/configuration/tariffs/upsertAll`, reqBody);
  }

  public async updateLookupConfigData(
    reqBody: UpdateLookupConfigProps[]
  ): Promise<restClient.PagedResponse<UpdatedLookupConfigDataResults>> {
    return this.post(`/ops-management/configuration/lookups/upsert`, reqBody);
  }

  public async updateLookupConfigDataForAllMatches(
    reqBody: UpdateLookupConfigRequest
  ): Promise<restClient.PagedResponse<UpdatedLookupConfigDataResults>> {
    return this.post(`/ops-management/configuration/lookups/upsertAll`, reqBody);
  }

  public async exportTariffConfigData(
    reqBody: TariffConfigDataProps,
    params: TariffDataRequestParams
  ): Promise<restClient.SingleResponse<void>> {
    const response: restClient.SingleResponse<any> = await this.post(
      `/ops-management/configuration/tariffs/export?showInActive=${params.showInActiveTariff}&showUnassigned=${params.showUnassignedTariff}`,
      reqBody
    );

    window.open(response.results[0], '_blank');
    return response;
  }

  public async exportLookupConfigData(
    reqBody: LookupConfigDataProps,
    params: LookupDataRequestParams
  ): Promise<restClient.SingleResponse<void>> {
    const response: restClient.SingleResponse<any> = await this.post(
      `/ops-management/configuration/lookups/export?showUnassigned=${params.showUnassignedLookups}`,
      reqBody
    );

    window.open(response.results[0], '_blank');
    return response;
  }

  public async fetchTariffStatusData(
    reqBody: TariffStatusProps,
    params: TariffDataRequestParams
  ): Promise<restClient.PagedResponse<TariffStatusData>> {
    return this.post(
      `/ops-management/status/tariffs?showInactive=${params.showInActiveTariff}`,
      reqBody
    );
  }

  public async fetchLookupStatusData(
    reqBody: LookupStatusProps
  ): Promise<restClient.PagedResponse<LookupStatusData>> {
    return this.post('/ops-management/status/lookups', reqBody);
  }

  public async updateStatusData(
    reqBody: UpdateMonitoringStatusRequest[]
  ): Promise<restClient.PagedResponse<UpdatedMonitoringStatusData>> {
    return this.post(`/ops-management/status/save`, reqBody);
  }

  public async fetchMonitoringHistory(
    request?: GetMonitoringHistoryRequest
  ): Promise<restClient.PagedResponse<MonitoringHistory>> {
    return this.getPaged(`/ops-management/status/history`, { params: request });
  }

  public async exportTariffStatusData(
    reqBody: TariffStatusProps,
    params: TariffDataRequestParams
  ): Promise<restClient.SingleResponse<void>> {
    const response: restClient.SingleResponse<any> = await this.post(
      `/ops-management/status/tariffs/export?showInactive=${params.showInActiveTariff}`,
      reqBody
    );
    window.open(response.results[0], '_blank');
    return response;
  }

  public async exportLookupStatusData(
    reqBody: LookupStatusProps
  ): Promise<restClient.SingleResponse<void>> {
    const response: restClient.SingleResponse<any> = await this.post(
      `/ops-management/status/lookups/export`,
      reqBody
    );
    window.open(response.results[0], '_blank');
    return response;
  }

  public async quickSearch(key: string, value: string): Promise<restClient.PagedResponse<string>> {
    return this.getPaged(`/quick-search?searchField=${key}&query=${encodeURIComponent(value)}`);
  }

  public async lookupConfigStats(): Promise<restClient.PagedResponse<LookupConfigStatsType>> {
    return this.getPaged(`/ops-management/configuration/lookups/stats`);
  }

  public async tariffConfigStats(): Promise<restClient.PagedResponse<TariffConfigStatsType>> {
    return this.getPaged(`/ops-management/configuration/tariffs/stats`);
  }

  public async lookupStatusStats(): Promise<restClient.PagedResponse<LookupStatusStatsType>> {
    return this.getPaged(`/ops-management/status/lookups/stats`);
  }

  public async tariffStatusStats(): Promise<restClient.PagedResponse<TariffStatusStatsType>> {
    return this.getPaged(`/ops-management/status/tariffs/stats`);
  }

  public async refreshSentinelStatus() {
    return this.post(`/ops-management/sentinel/refresh`);
  }
}
