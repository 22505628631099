import React from 'react';
import { Badge } from 'react-bootstrap';
import { TaskAssignment } from '../../task-api/types/task';

const variantColor = (status: string) => {
  if (status === 'POSTED' || status === 'ASSIGNED') {
    return 'secondary';
  } else if (status === 'READY_TO_REVIEW' || status === 'REVIEWING') {
    return 'primary';
  } else if (status === 'APPROVED') {
    return 'success';
  } else if (status === 'REJECTED') {
    return 'danger';
  }
  return 'dark';
};

function TaskAssignmentBadge(props: { assignments: TaskAssignment[] | null }): React.ReactElement {
  return (
    <React.Fragment>
      <ul className="list-inline">
        {props.assignments &&
          props.assignments.map((assignment: TaskAssignment, index: number) => (
            <Badge
              className="list-inline-item"
              key={index}
              variant={variantColor(assignment.assignmentStatus)}
            >
              <li>
                {assignment.assignmentStatus} {assignment.workerId} {assignment.lastUpdatedDate}
              </li>
            </Badge>
          ))}
      </ul>
    </React.Fragment>
  );
}

export default TaskAssignmentBadge;
