import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import App from './App';
import store from './state/store';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import { lightTheme } from '@arcadiapower/shrike';
import { ThemeProvider } from 'styled-components';

// Report sentry events only if it is not a development environment
// Add REACT_APP_ENABLE_SENTRY=1 to .env file to enable for production
// or if need to debug something in Sentry
const sentryReportingEnabled = parseInt(process.env.REACT_APP_ENABLE_SENTRY || '') === 1;

if (sentryReportingEnabled) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,

    // For now: disabling Sentry Transactions (Sentry Performance Monitoring).
    // Not used by TT team, and other Arcadia teams want the transactions quota to experiment with sentry
    // performance monitoring (with basic plan, we blow through transactions quota in first day of month).
    // This does NOT affect Sentry error reporting.
    // If want to experiment with transaction monitoring on TT, contact Tyler Drake to coordinate.
    //integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    //tracesSampleRate: 1.0,
    // reusing NODE_ENV as the environment for Sentry
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'development',
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={lightTheme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
