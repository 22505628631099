import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps, Redirect, withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, Drawer } from '@arcadiapower/gen-react-lib';
import TaskTitleBar from '../../components/TaskTitleBar/TaskTitleBar';
import { TaskAssignment, TaskComment, TaskTypeId } from '../../task-api/types/task';
import appConstants from '../../app-constants';
import styles from './DoDetail.module.scss';
import {
  fetchTaskAssignment,
  selectTaskAssignment,
  submitTaskAssignmentComment,
  selectTaskAssignmentComments,
  selectTaskAssignmentApiStatus,
  selectCommentApiStatus,
  selectTaskInputs,
  selectTaskAssignmentAnswerByField,
} from '../../state/taskAssignment/taskAssignmentSlice';
import { fetchTaskSource, selectTaskSource } from '../../state/task/taskSlice';
import { selectCurrentUser } from '../../state/currentUser/currentUserSlice';
import ViewJsonDrawer from '../../components/ViewJsonDrawer/ViewJsonDrawer';
import Do03WorkBody from '../../components/WorkBody/03/Do03WorkBody';
import Do02WorkBody from '../../components/WorkBody/02/Do02WorkBody';
import TaskCommentCard from '../../components/TaskComment/TaskComment';
import TaskCommentBox from '../../components/TaskCommentBox/TaskCommentBox';
import { types } from '@genability/api';
import useTimeout from '../../hooks/useTimeout';
import CopyTariffModal from '../../components/CopyTariffModal/CopyTariffModal';

interface MatchParams {
  taskAssignmentId: string;
  workPanel?: string;
}

function DoDetail(props: RouteComponentProps<MatchParams>): React.ReactElement {
  const [openCommentDrawer, setOpenCommentDrawer] = useState(false);
  const [openHistoryDrawer, setOpenHistoryDrawer] = useState(false);
  const [openViewJsonDrawer, setOpenViewJsonDrawer] = useState(false);
  const [openCopyTariffModal, setOpenCopyTariffModal] = useState(false);
  const [waitForAI, setWaitForAI] = useState(true);
  const [bailOnAI, setBailOnAI] = useState(false);
  const [visibleJson, setVisibleJson] = useState<string>(
    appConstants.viewJsonDropdown.taskAssignment
  );

  const username: string = useSelector(selectCurrentUser).username;
  const apiStatus: string = useSelector(selectTaskAssignmentApiStatus);
  const taskAssignment: TaskAssignment | undefined = useSelector(selectTaskAssignment);
  const tariffSource: types.Tariff | undefined = useSelector(selectTaskSource);
  const taskComments: TaskComment[] | [] = useSelector(selectTaskAssignmentComments);
  const commentApiStatus: string = useSelector(selectCommentApiStatus);
  const taskInputs = useSelector(selectTaskInputs);
  const aiStagedEntity = useSelector(selectTaskAssignmentAnswerByField('aiStagedEntity'));
  const [tariffCopied, setTariffCopied] = useState<boolean>(false);
  const dispatch = useDispatch();

  // Don't wait on AI if copilot_mode is false or if the AI staged entity is set
  useEffect(() => {
    const isCopilotEnabled = taskInputs.some(
      taskInput => taskInput.inputKey === 'copilot_mode' && taskInput.inputValue === 'true'
    );

    if (!isCopilotEnabled || aiStagedEntity) {
      setWaitForAI(false);
    } else {
      setWaitForAI(!bailOnAI);
    }
  }, [taskInputs, bailOnAI, aiStagedEntity]);

  // Bail on AI after 30 seconds, failsafe
  useTimeout(() => {
    setBailOnAI(true);
  }, 30000);

  useEffect(() => {
    const taskAssignmentId = Number(props.match.params.taskAssignmentId);
    dispatch(fetchTaskAssignment({ taskAssignmentId }));
  }, [dispatch, props.match.params.taskAssignmentId, tariffCopied]);

  useEffect(() => {
    if (taskAssignment && taskAssignment.taskId) {
      dispatch(fetchTaskSource({ taskId: taskAssignment.taskId }));
    }
  }, [taskAssignment]);

  const showCommentDrawer = () => {
    setOpenCommentDrawer(true);
  };

  const showHistoryDrawer = () => {
    setOpenHistoryDrawer(true);
  };

  const showViewJsonDrawer = () => {
    setOpenViewJsonDrawer(true);
  };

  const showCopyTariffModal = () => {
    setOpenCopyTariffModal(true);
  };

  const closeCommentDrawer = () => {
    setOpenCommentDrawer(false);
  };

  const closeHistoryDrawer = () => {
    setOpenHistoryDrawer(false);
  };

  const closeViewJsonDrawer = () => {
    setOpenViewJsonDrawer(false);
    if (visibleJson) {
      setVisibleJson(appConstants.viewJsonDropdown.taskAssignment);
    }
  };

  function onCommentClick() {
    Promise.resolve().then(() => {
      closeHistoryDrawer();
      closeViewJsonDrawer();
      showCommentDrawer();
    });
  }

  function onHistoryClick() {
    Promise.resolve().then(() => {
      closeCommentDrawer();
      closeViewJsonDrawer();
      showHistoryDrawer();
    });
  }

  function onMenuClick(selectedMenu: string | null) {
    Promise.resolve().then(() => {
      closeHistoryDrawer();
      closeCommentDrawer();
      if (selectedMenu === appConstants.menuDropdown.viewJson) {
        showViewJsonDrawer();
      } else if (selectedMenu === appConstants.menuDropdown.copyTariff) {
        showCopyTariffModal();
      }
    });
  }

  const submitComment = (comment: string) => {
    if (taskAssignment) {
      dispatch(submitTaskAssignmentComment({ taskAssignment, username, comment }));
    }
  };

  const sortedTaskAssignmentComments = (): TaskComment[] | [] => {
    if (taskComments.length > 1) {
      const comments = taskComments.sort(function (first, second) {
        return (
          new Date(first.lastUpdatedDate).getTime() - new Date(second.lastUpdatedDate).getTime()
        );
      });
      return comments;
    }
    if (taskAssignment && taskAssignment.comments) {
      return taskAssignment.comments;
    }
    return [];
  };

  const renderSortedComments = () => {
    const allComments = (sortedTaskAssignmentComments() as Array<TaskComment>).map(function (
      comment: TaskComment
    ) {
      return (
        <div key={comment.taskCommentId}>
          <hr className={styles.whiteBackground} />
          <TaskCommentCard taskComment={comment} />
        </div>
      );
    });
    if (allComments.length === 0) {
      return <div>No Comments</div>;
    }
    return allComments;
  };

  const renderDoWorkBody = (assignment: TaskAssignment) => {
    if (assignment && assignment.task && assignment.task.taskType.taskTypeId) {
      if (
        [TaskTypeId['03_02'], TaskTypeId['03_03'], TaskTypeId['03_04']].includes(
          assignment.task.taskType.taskTypeId
        )
      ) {
        return (
          <Do03WorkBody
            taskAssignment={assignment}
            requestedWorkPanel={props.match.params.workPanel}
          />
        );
      } else if (
        [TaskTypeId['02_01'], TaskTypeId['02_03']].includes(assignment.task.taskType.taskTypeId)
      ) {
        return <Do02WorkBody taskAssignment={assignment} />;
      }
    }
    return (
      <div>
        Unknown Task Type {assignment && assignment.task && assignment.task.taskType.taskTypeId}
      </div>
    );
  };

  const renderPageBody = (): React.ReactElement => {
    return (
      <>
        <TaskTitleBar
          back={appConstants.routes.do}
          taskId={taskAssignment && taskAssignment.task?.taskId}
          title={`${taskAssignment && taskAssignment.task?.taskTitle}`}
          taskTypeId={taskAssignment && taskAssignment.task?.taskType.taskTypeId}
          taskTypeTitle={`${taskAssignment && taskAssignment.task?.taskType.taskTypeName}`}
          lseId={taskAssignment && taskAssignment.task?.lseId}
          lseName={taskAssignment && taskAssignment.task?.lseName}
          onCommentClick={onCommentClick}
          onHistoryClick={onHistoryClick}
          taskAssignmentId={taskAssignment && taskAssignment.taskAssignmentId}
          onMenuClick={onMenuClick}
        />
        <div className={styles.body}>{taskAssignment && renderDoWorkBody(taskAssignment)}</div>
      </>
    );
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Do Task</title>
      </Helmet>
      <Drawer
        open={openHistoryDrawer}
        onOpen={() => console.log('History drawer opened')}
        direction="right"
        header="Assignment History"
        subheader="Updates made to this assignment"
        onClose={() => setOpenHistoryDrawer(false)}
      >
        <div>History will go here</div>
      </Drawer>
      <Drawer
        open={openCommentDrawer}
        direction="right"
        header="Task Comments"
        onClose={() => setOpenCommentDrawer(false)}
      >
        <div className={styles.commentsBody}>
          {renderSortedComments()}
          <br />
          {commentApiStatus == 'pending' ? (
            <div className="mt-5">
              <div className="text-center mt-5">
                <Loader />
              </div>
              <br />
            </div>
          ) : (
            ''
          )}
          <TaskCommentBox submitComment={submitComment} />
        </div>
      </Drawer>
      <Drawer
        open={openViewJsonDrawer}
        onOpen={() => console.log('Menu drawer opened')}
        direction="right"
        header="View JSON"
        className="view-json-drawer"
        onClose={closeViewJsonDrawer}
      >
        <ViewJsonDrawer
          visibleJson={visibleJson}
          setVisibleJson={setVisibleJson}
          taskAssignment={taskAssignment}
          tariffSource={tariffSource}
        />
      </Drawer>
      {apiStatus === 'idle' || apiStatus === 'pending' || waitForAI ? (
        <div className="text-center" style={{ marginTop: '200px' }}>
          <Loader />
        </div>
      ) : (
        [
          apiStatus === 'notfound' || apiStatus === 'submitted' ? (
            <Redirect to={appConstants.routes.do} />
          ) : (
            <div key="1">{renderPageBody()}</div>
          ),
        ]
      )}
      <CopyTariffModal
        onTariffCopied={() => {
          setOpenCopyTariffModal(false);
          setTariffCopied(true);
        }}
        onHide={() => setOpenCopyTariffModal(false)}
        show={openCopyTariffModal}
        taskAssignmentId={parseInt(props.match.params.taskAssignmentId)}
      />
    </React.Fragment>
  );
}

export default withRouter(DoDetail);
