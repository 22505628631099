import { Input, Modal, Select, TextArea, Text, Button } from '@arcadiapower/shrike';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { restClient, types } from '@genability/api';
import PropertyDataTypeSelector from '../PropertyDataTypeSelector/PropertyDataTypeSelector';
import KeySpaceSelector from '../KeySpaceSelector/KeySpaceSelector';
import { Family, IconButton, KeySpace } from '@arcadiapower/gen-react-lib';
import styles from './PropertyKeyEditorModal.module.scss';
import FamilySelector from '../FamilySelector/FamilySelector';
import { PropertyKeyV2 } from '../../task-api/v2/types/PropertyKeyV2';
import { GenPropertyChoice, LoadServingEntity, PropertyDataType } from '@genability/api/dist/types';
import { selectSeasons, fetchSeasons } from '../../state/loadServingEntity/loadServingEntitySlice';
import {
  selectTimeOfUseGroupsByLseId,
  fetchTimeOfUseGroups,
} from '../../state/timeOfUses/timeOfUseSlice';
import { useDispatch, useSelector } from 'react-redux';
import { GenApiClient, TaskApiV2Client } from '../../GenApiClient';
import { EntityType } from '../../task-api/v2/types/EntityType';
import { TaskType } from '../../task-api/v2/types/TaskType';
import OverlayLink from '../OverlayLink/OverlayLink';
import appConstants from '../../app-constants';

export interface PropertyKeyEditorModalProps {
  taskId?: number;
  taskType?: TaskType;
  propertyKey?: PropertyKeyV2;
  reviewer?: boolean;
  show: boolean;
  submitLoading: boolean;
  editMode?: boolean;
  onHide: () => void;
  onSubmit: (modifiedPropertyKey: PropertyKeyV2) => void;
}

export const initPropertyKey: PropertyKeyV2 = {
  keyName: '',
  displayName: '',
  keyspace: KeySpace.TARIFF,
  family: Family.INCENTIVE_SPECIFICATIONS,
  description: '',
  choices: [],
  dataType: '' as PropertyDataType,
  entityType: undefined,
};

const PropertyKeyEditorModal: FC<PropertyKeyEditorModalProps> = ({
  show,
  onHide,
  onSubmit,
  reviewer,
  taskType,
  taskId,
  submitLoading,
  editMode,
  propertyKey,
}): ReactElement => {
  const dispatch = useDispatch();
  const [modifiedPropertyKey, setModifiedPropertyKey] = useState<PropertyKeyV2>(
    propertyKey || initPropertyKey
  );
  const [keyNameValid, setKeyNameValid] = useState<boolean>(true);
  const [lseIdValid, setLseIdValid] = useState<boolean>(true);
  const seasonGroups: types.SeasonGroup[] = useSelector(
    selectSeasons(modifiedPropertyKey.entityId ?? -1)
  );
  const touGroups: types.TimeOfUseGroup[] = useSelector(
    selectTimeOfUseGroupsByLseId(modifiedPropertyKey.entityId ?? -1)
  );

  const [showPropertyChoice, setShowPropertyChoice] = useState<boolean>(false);
  const [showKeyAttribute, setShowKeyAttribute] = useState<boolean>(false);
  const [showSeasonAndTouDropDown, setShowSeasonAndTouDropDown] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [keyNameError, setKeyNameError] = useState<string>('');

  const validatePropertyKey = async (propertyKeyName: string) => {
    if (propertyKeyName) {
      const client = await TaskApiV2Client();
      const { errors }: restClient.SingleResponse<PropertyKeyV2> =
        await client.taskV2.getPropertyKey(propertyKeyName);
      const alreadyExists = !errors;
      const isCamelCased = /^[a-z][a-zA-Z0-9]*$/.test(propertyKeyName);
      setKeyNameValid(!alreadyExists && isCamelCased);
      setKeyNameError(
        alreadyExists
          ? 'Property key with same name already exists'
          : !isCamelCased
          ? 'Property key name is not camel Cased'
          : ''
      );
    }
  };

  useEffect(() => {
    if (modifiedPropertyKey.entityId) {
      dispatch(fetchTimeOfUseGroups(modifiedPropertyKey.entityId));
      dispatch(fetchSeasons(modifiedPropertyKey.entityId));
    }
  }, []);

  const searchLSEId = async (lseId?: number) => {
    if (lseId) {
      const client = await GenApiClient();
      const { errors }: restClient.SingleResponse<LoadServingEntity> =
        await client.lses.getLoadServingEntity(lseId);
      setLseIdValid(errors ? false : true);
    }
  };
  useEffect(() => {
    let choices: GenPropertyChoice[] = [];
    let showPropertyChoice = false;
    let showKeyAttribute = false;
    let showSeasonAndTouDropDown = false;

    switch (modifiedPropertyKey.dataType) {
      case PropertyDataType.CHOICE:
        choices =
          modifiedPropertyKey.choices && modifiedPropertyKey.choices.length > 0
            ? [...modifiedPropertyKey.choices]
            : [{ displayValue: '', dataValue: '' }];
        showPropertyChoice = true;
        showSeasonAndTouDropDown = true;
        break;
      case PropertyDataType.LOOKUP:
        break;
      case PropertyDataType.FORMULA:
        showKeyAttribute = true;
        showSeasonAndTouDropDown = true;
        break;
      case PropertyDataType.DEMAND:
        showKeyAttribute = true;
        showSeasonAndTouDropDown = true;
        break;
      default:
        showSeasonAndTouDropDown = true;
        break;
    }
    setModifiedPropertyKey(previousState => ({
      ...previousState,
      formulaDetail: modifiedPropertyKey.formulaDetail,
      choices: choices,
    }));
    setShowPropertyChoice(showPropertyChoice);
    setShowKeyAttribute(showKeyAttribute);
    setShowSeasonAndTouDropDown(showSeasonAndTouDropDown);
  }, [modifiedPropertyKey.dataType]);

  const handleKeySpaceSelected = async (keySpace: KeySpace) => {
    setModifiedPropertyKey(previousState => ({
      ...previousState,
      keyspace: keySpace,
    }));
    setIsDirty(true);
  };

  const handleFamilySelected = async (family: Family) => {
    setModifiedPropertyKey(previousState => ({
      ...previousState,
      family: family,
    }));
    setIsDirty(true);
  };

  const handlePropertyDataTypeSelected = async (dataType: PropertyDataType) => {
    setModifiedPropertyKey(previousState => ({
      ...previousState,
      dataType: dataType,
    }));
    setIsDirty(true);
  };

  const updateChoice = (index: number, name?: string, value?: string) => {
    setModifiedPropertyKey(previousState => ({
      ...previousState,
      choices: previousState.choices?.map((choice, i) =>
        i === index
          ? {
              ...choice,
              displayValue: name ?? choice.displayValue,
              dataValue: value ?? choice.dataValue,
            }
          : choice
      ),
    }));
    setIsDirty(true);
  };
  const addChoice = () => {
    setModifiedPropertyKey(previousState => ({
      ...previousState,
      choices: [...(previousState.choices || []), { displayValue: '', dataValue: '' }],
    }));
  };

  const removeChoice = (index: number) => {
    setModifiedPropertyKey(previousState => ({
      ...previousState,
      choices: previousState.choices?.filter((_, i) => i !== index),
    }));
    setIsDirty(true);
  };

  const getTaskTitle = () => {
    if (taskType == TaskType.ADD_PROPERTY) {
      return 'Add a Property Key';
    } else {
      return 'Edit Property Key';
    }
  };

  const shouldSubmitBeEnabled = () => {
    if (
      (!reviewer && !isDirty) ||
      !modifiedPropertyKey.keyName ||
      !keyNameValid ||
      !lseIdValid ||
      !modifiedPropertyKey.dataType ||
      !modifiedPropertyKey.displayName ||
      !modifiedPropertyKey.description ||
      !modifiedPropertyKey.keyspace ||
      !modifiedPropertyKey.family ||
      (modifiedPropertyKey.entityType == EntityType.LSE && !modifiedPropertyKey.entityId)
    ) {
      return false;
    }
    switch (modifiedPropertyKey.dataType) {
      case PropertyDataType.CHOICE:
        return (
          modifiedPropertyKey.choices &&
          modifiedPropertyKey.choices.length > 0 &&
          modifiedPropertyKey.choices.every(choice => choice.displayValue && choice.dataValue)
        );
      case PropertyDataType.FORMULA:
        return (
          modifiedPropertyKey.formulaDetail !== undefined &&
          modifiedPropertyKey.formulaDetail.trim() !== ''
        );
    }
    return true;
  };

  return (
    <Modal size="large" aria-label="Modal" isOpen={show} className={styles.propertyKeyEditor}>
      <Modal.Header
        onClose={onHide}
        title={getTaskTitle() + (taskId ? ` | Task ID -${taskId}` : ``)}
      ></Modal.Header>
      <Modal.Content>
        <Container fluid>
          {!taskId || reviewer ? (
            <>
              <Row className="mb-2">
                <Col xs={6}>
                  <PropertyDataTypeSelector
                    className={styles.dropDown}
                    disabled={editMode}
                    value={modifiedPropertyKey.dataType}
                    handleSelected={handlePropertyDataTypeSelected}
                  />
                </Col>
              </Row>
              {modifiedPropertyKey.dataType && (
                <>
                  <Row className="mb-2">
                    <Col xs={6}>
                      <Input
                        label="Key Name"
                        name="keyname"
                        type="text"
                        required
                        disabled={editMode}
                        errorText={keyNameError}
                        value={modifiedPropertyKey.keyName}
                        onChange={value => {
                          setModifiedPropertyKey(previousState => ({
                            ...previousState,
                            keyName: value,
                          }));
                          setIsDirty(true);
                        }}
                        onBlur={() => validatePropertyKey(modifiedPropertyKey.keyName)}
                      />
                    </Col>
                    <Col xs={6}>
                      <Input
                        label="Display Name"
                        name="displayname"
                        type="text"
                        required
                        value={modifiedPropertyKey.displayName}
                        onChange={value => {
                          setModifiedPropertyKey(previousState => ({
                            ...previousState,
                            displayName: value,
                          }));
                          setIsDirty(true);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={6}>
                      <Select
                        className={styles.dropDown}
                        value={modifiedPropertyKey.entityType}
                        label="Entity Type"
                        loadingMessage="Loading..."
                        disabled={editMode}
                        required
                        minHeight="50px"
                        name="Entity type"
                        options={[
                          {
                            text: 'NONE',
                            value: 'NONE',
                          },
                          {
                            text: EntityType.LSE,
                            value: EntityType.LSE,
                          },
                        ]}
                        onChange={value => {
                          setModifiedPropertyKey(previousState => ({
                            ...previousState,
                            entityType: value != EntityType.LSE ? undefined : value,
                            entityId: undefined,
                          }));
                        }}
                      />
                    </Col>
                    {modifiedPropertyKey.entityType == EntityType.LSE && (
                      <Col xs={6}>
                        <Input
                          label="Entity ID"
                          name="entityID"
                          type="number"
                          disabled={editMode}
                          inputMode="numeric"
                          required
                          errorText={!lseIdValid ? 'LSE ID is invalid' : ''}
                          value={modifiedPropertyKey.entityId?.toString()}
                          onChange={value => {
                            setModifiedPropertyKey(previousState => ({
                              ...previousState,
                              entityId: Number.parseInt(value),
                              lookbackTimeOfUseId: undefined,
                              lookbackSeasonId: undefined,
                            }));
                          }}
                          onBlur={async () => {
                            await searchLSEId(modifiedPropertyKey.entityId);
                            if (modifiedPropertyKey.entityId && lseIdValid) {
                              dispatch(fetchTimeOfUseGroups(modifiedPropertyKey.entityId));
                              dispatch(fetchSeasons(modifiedPropertyKey.entityId));
                            }
                          }}
                        />
                      </Col>
                    )}
                  </Row>

                  <Row className="mb-2">
                    <Col xs={6}>
                      <KeySpaceSelector
                        className={styles.dropDown}
                        value={modifiedPropertyKey.keyspace as KeySpace}
                        handleSelected={handleKeySpaceSelected}
                      />
                    </Col>
                    <Col xs={6}>
                      <FamilySelector
                        className={styles.dropDown}
                        handleSelected={handleFamilySelected}
                        value={modifiedPropertyKey.family as Family}
                        keySpace={modifiedPropertyKey.keyspace as KeySpace}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={12}>
                      <TextArea
                        id="description"
                        style={{ maxWidth: '100%' }}
                        label="Description"
                        name="description"
                        required
                        value={modifiedPropertyKey.description}
                        onChange={value => {
                          setModifiedPropertyKey(previousState => ({
                            ...previousState,
                            description: value.toString(),
                          }));
                          setIsDirty(true);
                        }}
                      />
                    </Col>
                  </Row>
                  {showSeasonAndTouDropDown && modifiedPropertyKey.entityId && (
                    <Row className="mb-2">
                      <Col xs={6}>
                        <Select
                          label="Time of Use ID"
                          name="Time of Use ID"
                          className={styles.dropDown}
                          withEmptyPlaceholder
                          value={modifiedPropertyKey.lookbackTimeOfUseId}
                          minHeight="50px"
                          loadingMessage="Loading..."
                          options={touGroups?.flatMap(
                            group =>
                              group.timeOfUses?.map(tou => ({
                                text: `${tou.touId} - ${tou.touName}`,
                                value: tou.touId,
                              })) ?? []
                          )}
                          onChange={value => {
                            setModifiedPropertyKey(previousState => ({
                              ...previousState,
                              lookbackTimeOfUseId: Number.parseInt(value),
                            }));
                            setIsDirty(true);
                          }}
                        />
                      </Col>
                      <Col xs={6}>
                        <Select
                          label="Season ID"
                          name="Season ID"
                          className={styles.dropDown}
                          value={modifiedPropertyKey.lookbackSeasonId}
                          withEmptyPlaceholder
                          minHeight="50px"
                          loadingMessage="Loading..."
                          options={seasonGroups?.flatMap(
                            group =>
                              group.seasons?.map(season => ({
                                text: `${season.seasonId} - ${season.seasonName}`,
                                value: season.seasonId,
                              })) ?? []
                          )}
                          onChange={value => {
                            setModifiedPropertyKey(previousState => ({
                              ...previousState,
                              lookbackSeasonId: Number.parseInt(value),
                            }));
                            setIsDirty(true);
                          }}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row className="mb-2">
                    <Col xs={6}>
                      <Input
                        label="Quantity Unit"
                        name="quantity Unit"
                        type="text"
                        value={modifiedPropertyKey.quantityUnit}
                        onChange={value => {
                          setModifiedPropertyKey(previousState => ({
                            ...previousState,
                            quantityUnit: value,
                          }));
                          setIsDirty(true);
                        }}
                      />
                    </Col>
                    <Col xs={6}>
                      <Select
                        label="Interval Quantity"
                        name="Interval Quantity"
                        className={styles.dropDown}
                        value={modifiedPropertyKey.lookbackIntervalQuantity}
                        loadingMessage="Loading..."
                        minHeight="50px"
                        withEmptyPlaceholder
                        options={[
                          {
                            text: '15',
                            value: 15,
                          },
                          {
                            text: '30',
                            value: 30,
                          },
                          {
                            text: '60',
                            value: 60,
                          },
                        ]}
                        onChange={value => {
                          setModifiedPropertyKey(previousState => ({
                            ...previousState,
                            lookbackIntervalQuantity: Number.parseInt(value),
                          }));
                          setIsDirty(true);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={6}>
                      <Select
                        label="Lookback Month"
                        name="Lookback Month"
                        className={styles.dropDown}
                        value={modifiedPropertyKey.lookbackQuantity}
                        minHeight="50px"
                        withEmptyPlaceholder
                        loadingMessage="Loading..."
                        options={[
                          {
                            text: '1',
                            value: 1,
                          },
                          {
                            text: '12',
                            value: 12,
                          },
                        ]}
                        onChange={value => {
                          setModifiedPropertyKey(previousState => ({
                            ...previousState,
                            lookbackQuantity: Number.parseInt(value),
                          }));
                          setIsDirty(true);
                        }}
                      />
                    </Col>
                    <Col xs={6}>
                      <Select
                        label="Lookback Period"
                        name="Lookback Period"
                        className={styles.dropDown}
                        withEmptyPlaceholder
                        value={modifiedPropertyKey.lookbackPeriod}
                        minHeight="50px"
                        loadingMessage="Loading..."
                        options={[
                          {
                            text: 'BILLING_PERIOD',
                            value: 'BILLING_PERIOD',
                          },
                          {
                            text: 'MONTH',
                            value: 'MONTH',
                          },
                          {
                            text: 'YEAR',
                            value: 'YEAR',
                          },
                        ]}
                        onChange={value => {
                          setModifiedPropertyKey(previousState => ({
                            ...previousState,
                            lookbackPeriod: value,
                          }));
                          setIsDirty(true);
                        }}
                      />
                    </Col>
                  </Row>
                  {showPropertyChoice && (
                    <>
                      <Row className="mb-2">
                        <Col>
                          <Text textStyle="heading500">Property Choices</Text>
                        </Col>
                      </Row>

                      {modifiedPropertyKey.choices?.map((choice, index) => (
                        <Row className="mb-2" key={index}>
                          <Col xs={5}>
                            <Input
                              label="Choice Name"
                              name="choicename"
                              type="text"
                              required
                              value={choice.displayValue}
                              onChange={value =>
                                updateChoice(
                                  index,
                                  value,
                                  modifiedPropertyKey.choices
                                    ? modifiedPropertyKey.choices[index].dataValue
                                    : undefined
                                )
                              }
                            />
                          </Col>
                          <Col xs={5}>
                            <Input
                              label="Choice Value"
                              name="choicevalue"
                              type="text"
                              required
                              value={choice.dataValue}
                              onChange={value =>
                                updateChoice(
                                  index,
                                  modifiedPropertyKey.choices
                                    ? modifiedPropertyKey.choices[index].displayValue
                                    : undefined,
                                  value
                                )
                              }
                            />
                          </Col>
                          <Col xs={1} className="mt-4">
                            <IconButton
                              icon="close"
                              className="align-middle"
                              disabled={
                                modifiedPropertyKey.choices &&
                                modifiedPropertyKey.choices?.length == 1
                              }
                              onClick={() => removeChoice(index)}
                            />
                          </Col>
                          {modifiedPropertyKey.choices &&
                            index == modifiedPropertyKey.choices.length - 1 && (
                              <Col xs={1} className="mt-4">
                                <IconButton
                                  icon="add"
                                  className="align-middle"
                                  onClick={addChoice}
                                />
                              </Col>
                            )}
                        </Row>
                      ))}
                    </>
                  )}
                  {showKeyAttribute && (
                    <Row className="mb-2">
                      <Col xs={12}>
                        <Input
                          label="Key Attribute"
                          name="keyAttribute"
                          type="text"
                          required={modifiedPropertyKey.dataType === PropertyDataType.FORMULA}
                          value={modifiedPropertyKey.formulaDetail}
                          onChange={value => {
                            setModifiedPropertyKey(previousState => ({
                              ...previousState,
                              formulaDetail: value,
                            }));
                            setIsDirty(true);
                          }}
                        />
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              Your task has been Submitted successfully.
              <OverlayLink
                id={taskId}
                to={true}
                url={`${appConstants.routes.taskDetails}/${taskId}`}
                displayText="Click here to view your task"
              />
            </div>
          )}
        </Container>
      </Modal.Content>
      {(!taskId || reviewer) && (
        <Modal.Footer
          onSubmit={() => onSubmit(modifiedPropertyKey)}
          primaryText={reviewer ? 'Publish' : 'Submit'}
          loading={submitLoading}
          disabled={!shouldSubmitBeEnabled()}
        ></Modal.Footer>
      )}
    </Modal>
  );
};

export default PropertyKeyEditorModal;
