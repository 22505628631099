import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Icon, IconButton } from '@arcadiapower/gen-react-lib';
import styles from './FileUploadArea.module.scss';

interface FileUploadAreaProps {
  accept: string[];
  onUploadFile: (file: File) => void;
  resetUpload?: () => void;
  isUploading?: boolean;
  uploadFailure?: boolean;
}

const FileUploadArea: React.FC<FileUploadAreaProps> = props => {
  const { accept, onUploadFile, resetUpload, isUploading, uploadFailure } = props;
  const [file, setFile] = useState<File | null>(null);
  const [isDragActive, setDragActive] = useState(false);
  const [isFileAccepted, setFileAccepted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (uploadFailure) {
      setFile(null);
    }
  }, [uploadFailure]);

  const validateFileType = (fileType: string) => accept.includes(fileType);

  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    let fileAccepted = false;
    const { items } = event.dataTransfer;
    if (!isUploading && items?.length === 1) {
      fileAccepted = validateFileType(items[0].type);
    }
    event.dataTransfer.dropEffect = fileAccepted ? 'copy' : 'none';
  };

  const handleDragIn = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const { items } = event.dataTransfer;
    if (!isUploading && items?.length === 1) {
      setDragActive(true);
      setFileAccepted(validateFileType(items[0].type));
    }
    setErrorMessage('');
  };

  const handleDragOut = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragActive(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);

    const { files } = event.dataTransfer;
    if (!isUploading && files.length === 1) {
      validateAndUploadFiles(files[0]);
    }
  };

  const validateAndUploadFiles = (newfile: File) => {
    setErrorMessage('');
    if (validateFileType(newfile.type)) {
      setFile(newfile);
      onUploadFile(newfile);
    } else {
      setErrorMessage('File type not valid. Try PDF document');
    }
  };

  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files?.length === 1) {
      validateAndUploadFiles(files[0]);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    if (resetUpload) {
      resetUpload();
    }
  };

  return (
    <div className={styles.fileUpload}>
      <div
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
        className={`${styles.fileDropZone} ${isDragActive && styles.dragActive} ${
          styles.alignCenter
        } border rounded`}
      >
        {!isDragActive && file && (
          <div className={`${styles.fileUploadIcon} ${styles.alignCenter} mt-2`}>
            {isUploading && <Icon iconName="spinner" spin />}
            <div>
              {file.name}
              {!isUploading && resetUpload && (
                <IconButton icon="close" onClick={handleRemoveFile} />
              )}
            </div>
          </div>
        )}
        {!isDragActive && !file && (
          <div className={`${styles.fileUploadIcon} ${styles.alignCenter} pt-4`}>
            <Icon iconName="upload" variant="dark" />
            <div className="mt-2">Drag or drop file here</div>
            <label htmlFor="image_uploads" className="border rounded py-2 px-3 mt-3">
              {' '}
              Select file{' '}
            </label>
            <input
              id="image_uploads"
              type="file"
              accept={accept.join(',')}
              style={{ opacity: '0' }}
              onChange={handleChangeFile}
            />
          </div>
        )}
      </div>
      {isDragActive && (
        <div
          className={`${styles.fileDrag} ${
            isFileAccepted ? styles.fileAccepted : styles.fileRejected
          } ${styles.alignCenter}`}
        />
      )}
      {!isDragActive && errorMessage && (
        <Alert variant="danger" className="mt-2">
          {errorMessage}
        </Alert>
      )}
    </div>
  );
};

export default FileUploadArea;
