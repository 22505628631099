import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Table, Td, Th, Tr } from '@arcadiapower/gen-react-lib';
import { Container, Row } from 'react-bootstrap';
import styles from './LookupValuesList.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { TaskType } from '../../task-api/v2/types/TaskType';
import { selectUnpublishedLookupTasks } from '../../state/tasksV2/UnpublishedLookupTaskSlice';
import {
  saveIntermediateAnswer,
  selectIntermediateSavedData,
} from '../../state/tasksV2/intermediateSaveTaskSlice';
import { Alert, IconButton, Loading } from '@arcadiapower/shrike';
import LookupValueEditorModal, {
  ModifiedLookupValue,
} from '../LookupValueEditorModal/LookupValueEditorModal';
import {
  selectTaskWorkflowEntityPostAction,
  uploadDocument,
} from '../../state/tasksV2/TasksActionSlice';
import { applyTimeZoneOffSet } from '../../utils/dateUtils';
import { selectWorkflow } from '../../state/tasksV2/WorkflowStateSlice';
import { ActionType } from '../../task-api/v2/types/ActionType';
import { Document } from '@genability/api/dist/types';
import { getUnpublishedAnswers } from '../../utils/taskV2Utils';
import { LookupValueV2Transformed } from '../../task-api/v2/types/LookupValueV2Transformed';
import { CurrentUser, selectCurrentUser } from '../../state/currentUser/currentUserSlice';
import { selectUpdatedTaskAssignmentAnswer } from '../../state/tasksV2/LookupsGapFillingSlice';
import { updateTaskSource } from '../../utils/lookupUtil';
import { fetchDocument, selectDocument } from '../../state/document-v2/documentSlice';
import {
  selectDocumentSection,
  updateDocumentSection,
} from '../../state/document-v2/documentSectionSlice';

export interface UnpublishedLookupValuesProps {
  propertyKey: string;
  isGlobalPropertyKey: boolean;
}

type CustomLookupValue = LookupValueV2Transformed & {
  index: number;
};

const HEADERS = [
  {
    property: 'index',
    value: '#',
  },
  {
    property: 'lookupId',
    value: 'Lookup Id',
  },
  {
    property: 'fromDateTime',
    value: 'From Date',
  },
  {
    property: 'toDateTime',
    value: 'To Date',
  },
  {
    property: 'actualValue',
    value: 'Actual Value',
  },
  {
    property: 'forecastValue',
    value: 'Forecast Value',
  },
  // { UTD-1215 Hiding the Forcase method from UI
  //   property: 'forecastMethod',
  //   value: 'Forecast Method',
  // },
  {
    property: 'lseForecastValue',
    value: 'LSE Forecast Value',
  },
  {
    property: 'document',
    value: 'Document',
  },
];

const UnpublishedLookupValuesList: FC<UnpublishedLookupValuesProps> = ({
  propertyKey,
  isGlobalPropertyKey,
}): ReactElement => {
  const currentUser: CurrentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [currentLookupValue, setCurrentLookupValue] = useState<LookupValueV2Transformed | null>(
    null
  );
  const [currentLookupIndex, setCurrentLookupIndex] = useState<number | null>(null);
  const {
    taskId,
    taskType,
    error,
    workflowId,
    unpublishedLookupValues,
    isLoading,
    reviewer,
    assignee,
  } = useSelector(selectUnpublishedLookupTasks);
  const { isLoading: lookupGapFillingLoading } = useSelector(selectUpdatedTaskAssignmentAnswer);
  const { documentSection, isLoading: updateDocumentSectionLoading } =
    useSelector(selectDocumentSection);
  const { tasks } = useSelector(selectWorkflow);
  const { document } = useSelector(selectDocument);
  const { currentWorkflowState } = useSelector(selectWorkflow);
  const { uploadLoading, uploadError } = useSelector(selectTaskWorkflowEntityPostAction);
  const { isLoading: intermediateSaveLoading } = useSelector(selectIntermediateSavedData);
  const [customLookupValues, setCustomLookupValues] = useState<CustomLookupValue[]>([]);
  const [taskMetaDataUpdateLoading, setTaskMetaDataUpdateLoading] = useState<boolean>(false);

  useEffect(() => {
    const parsedValues = unpublishedLookupValues ? JSON.parse(unpublishedLookupValues) : [];
    const lookUpValues: LookupValueV2Transformed[] = Array.isArray(parsedValues)
      ? parsedValues
      : [parsedValues];
    const customLookupValues: CustomLookupValue[] = lookUpValues.map((value, index) => ({
      ...value,
      index: index + 1,
    }));
    setCustomLookupValues(customLookupValues);
  }, [unpublishedLookupValues]);

  useEffect(() => {
    const documentTask = tasks?.find(task => task.taskType === TaskType.ADD_DOCUMENT);
    if (documentTask) {
      const unpublishedDocument = getUnpublishedAnswers(documentTask);
      const document: Document | null = unpublishedDocument
        ? JSON.parse(unpublishedDocument)
        : null;
      if (document?.documentId) {
        dispatch(fetchDocument(document.documentId));
      }
    }
  }, [tasks, documentSection]);

  const mappedHeaders = () => {
    const mapped = HEADERS.map(head => (
      <Th className="text-center" key={head.property}>
        {head.value}
      </Th>
    ));
    return mapped;
  };

  const onEdit = (lookupVal: LookupValueV2Transformed | null, index: number | null) => {
    setCurrentLookupValue(lookupVal);
    setCurrentLookupIndex(index);
    setShowEditModal(true);
  };

  const onDelete = (index: number) => {
    customLookupValues.splice(index, 1);
    const answerJSON = JSON.stringify(customLookupValues);
    dispatch(saveIntermediateAnswer(taskId, workflowId, answerJSON));
  };

  const save = async (
    modifiedLookupValue: ModifiedLookupValue,
    taskSource: string,
    startPage?: number,
    endPage?: number
  ) => {
    const modifiedLookup = {
      ...currentLookupValue,
      fromDateTime:
        applyTimeZoneOffSet(modifiedLookupValue.fromDateTime)?.toISOString().slice(0, -5) || null,
      toDateTime:
        applyTimeZoneOffSet(modifiedLookupValue.toDateTime)?.toISOString().slice(0, -5) || null,
      actualValue: modifiedLookupValue.actualValue ? Number(modifiedLookupValue.actualValue) : 0,
      forecastValue: modifiedLookupValue.forecastValue
        ? Number(modifiedLookupValue.forecastValue)
        : undefined,
      propertyKey,
    };
    const intermediateSavedLookups: Record<string, unknown>[] = unpublishedLookupValues
      ? JSON.parse(unpublishedLookupValues)
      : [];

    if (!intermediateSavedLookups.length && modifiedLookup) {
      intermediateSavedLookups.push(modifiedLookup);
    } else if (currentLookupIndex !== null && modifiedLookup) {
      intermediateSavedLookups.splice(currentLookupIndex, 1, modifiedLookup);
    }
    setTaskMetaDataUpdateLoading(true);
    await updateTaskSource(workflowId, taskSource);
    setTaskMetaDataUpdateLoading(false);
    if (
      document?.sections[0]?.documentSectionId &&
      currentWorkflowState?.allowedActions?.includes(ActionType.UPDATE_DOCUMENT_SECTION)
    ) {
      dispatch(
        updateDocumentSection(
          document.documentId,
          document?.sections[0].documentSectionId,
          startPage,
          endPage
        )
      );
    }
    await dispatch(
      saveIntermediateAnswer(taskId, workflowId, JSON.stringify(intermediateSavedLookups))
    );
    setShowEditModal(false);
  };

  const onUploadDocument = (file: File) => {
    const data = new FormData();
    data.append('document', file, file.name);
    dispatch(uploadDocument(data, taskId, workflowId));
  };

  const mappedUnpublishedLookupValuesRows = () => {
    return customLookupValues.map((lookupValue, index) => (
      <Tr
        className={`${styles.row} border-top-3 border-info`}
        key={`actual-${lookupValue?.lookupId}`}
      >
        <Td className="text-center">{lookupValue?.index}</Td>
        <Td className="text-center">{lookupValue?.lookupId}</Td>
        <Td className="text-center">{lookupValue?.fromDateTime}</Td>
        <Td className="text-center">{lookupValue?.toDateTime}</Td>
        <Td className="text-center">{lookupValue?.actualValue}</Td>
        <Td className="text-center">{lookupValue?.forecastValue}</Td>
        {/* <Td className="text-center">{lookupValue?.forecastMethod}</Td> */}
        <Td className="text-center">{lookupValue?.lseForecastValue}</Td>
        <Td className="text-center">
          {(lookupValue.document || (document && !lookupValue.lookupId)) && (
            <IconButton
              aria-label="document"
              icon="DocumentInverted"
              onClick={e => {
                e.preventDefault();
                window.open(
                  lookupValue.document?.archiveUrl || document?.archiveUrl || '',
                  '_blank'
                );
              }}
            />
          )}
        </Td>
        <Td className="text-center">
          <IconButton
            aria-label="edit"
            disabled={
              (currentUser.username !== assignee && currentUser.username !== reviewer) ||
              ((currentUser.username === assignee || currentUser.username === reviewer) &&
                !currentWorkflowState?.allowedActions.includes(ActionType.SAVE)) ||
              lookupValue.isAutoGenerated
            }
            icon="Pencil"
            onClick={() => onEdit(lookupValue, index)}
          />
        </Td>
        {taskType === TaskType.EDIT_LOOKUP && (
          <Td className="text-center">
            <IconButton
              aria-label="delete"
              disabled={
                (currentUser.username !== assignee && currentUser.username !== reviewer) ||
                ((currentUser.username === assignee || currentUser.username === reviewer) &&
                  !currentWorkflowState?.allowedActions.includes(ActionType.SAVE)) ||
                lookupValue.isAutoGenerated
              }
              icon="UIDelete"
              onClick={() => onDelete(index)}
            />
          </Td>
        )}
      </Tr>
    ));
  };

  const renderRows = () => {
    if (!customLookupValues.length && taskType === TaskType.EDIT_LOOKUP) {
      return (
        <Tr>
          <Td className="text-center" colSpan={HEADERS.length}>
            No data available...
          </Td>
        </Tr>
      );
    } else if (
      !customLookupValues.length &&
      (taskType === TaskType.ADD_DOCUMENT || taskType === TaskType.ADD_LOOKUP)
    ) {
      return (
        <Tr>
          {HEADERS.map(key =>
            key.value === 'Document' && document ? (
              <Td key={key.value} className="text-center">
                <IconButton
                  aria-label="document"
                  icon="DocumentInverted"
                  onClick={e => {
                    e.preventDefault();
                    window.open(document.archiveUrl || '', '_blank');
                  }}
                />
              </Td>
            ) : (
              <Td key={key.value}></Td>
            )
          )}

          <Td className="text-center">
            <IconButton aria-label="edit" icon="Pencil" onClick={() => onEdit(null, null)} />
          </Td>
        </Tr>
      );
    }
    return <>{mappedUnpublishedLookupValuesRows()}</>;
  };

  const handleModalClose = () => {
    setShowEditModal(false);
  };

  return (
    <Container fluid className="p-4">
      <Row>
        <Table bordered striped hover>
          <thead>
            <Tr>{mappedHeaders()}</Tr>
          </thead>
          <tbody>
            {isLoading || lookupGapFillingLoading ? (
              <Tr className={`${styles.row}`}>
                <Td colSpan={HEADERS.length} className="text-center w-100">
                  <Loading />
                  Loading...
                </Td>
              </Tr>
            ) : (
              renderRows()
            )}
            {error ? (
              <Tr>
                <Td className="text-center" colSpan={HEADERS.length}>
                  <Alert variant="error">{error}</Alert>
                </Td>
              </Tr>
            ) : null}
          </tbody>
        </Table>
        {showEditModal && taskType != TaskType.ADD_DOCUMENT && (
          <LookupValueEditorModal
            propertyKey={propertyKey}
            show={showEditModal}
            isGlobalPropertyKey={isGlobalPropertyKey}
            onHide={handleModalClose}
            lookupValue={currentLookupValue}
            onSave={save}
            isSaving={
              taskMetaDataUpdateLoading || intermediateSaveLoading || updateDocumentSectionLoading
            }
            document={currentLookupValue?.document || document}
            editMode
          />
        )}
        {showEditModal && taskType === TaskType.ADD_DOCUMENT && (
          <LookupValueEditorModal
            propertyKey={propertyKey}
            isGlobalPropertyKey={isGlobalPropertyKey}
            show={showEditModal}
            onHide={handleModalClose}
            onSave={save}
            isSaving={false}
            onUploadDocument={onUploadDocument}
            isUploadingDocument={uploadLoading}
            uploadDocumentFailure={uploadError === '' ? false : true}
            document={document}
          />
        )}
      </Row>
    </Container>
  );
};

export default UnpublishedLookupValuesList;
