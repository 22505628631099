import { ResponseError, SingleResponse } from '@genability/api/dist/rest-client';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TariffApiV2Client } from '../../GenApiClient';
import { addNotification } from '../notification/notificationSlice';
import { LoadingState, handleUnexpectedThunkException } from '../reduxUtils';
import { RootState } from '../rootReducer';
import { Tariff } from '@genability/api/dist/types';

export interface unpublishTariffParamsType {
  buttonText: string;
  masterTariffId: number;
  tariffs: Tariff[];
}
export interface unpublishTariffType {
  status: LoadingState;
  results: void[];
  errors: ResponseError[];
}
const initialState: unpublishTariffType = {
  status: LoadingState.IDLE,
  results: [],
  errors: [],
};

export const unpublishLatestTariff = createAsyncThunk(
  'unpublish/unpublishTariff',
  async (params: unpublishTariffParamsType, { dispatch, rejectWithValue }) => {
    try {
      const client = await TariffApiV2Client();
      const response: SingleResponse<Tariff> = await client.tariffV2.unpublishLatest(
        params.masterTariffId
      );
      if (response.status == '403') {
        throw new Error(
          'Error:  Unpublish action can only be done by an user with SME role, please reach out to a SME user to unpublish this tariff'
        );
      }
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
      dispatch(
        addNotification(
          `Unpublished tariff version Id ${response.results[0].tariffId} and revived tariff version Id ${response.results[1].tariffId} also the associated task is now put back to posted status`,
          NotificationLevel.Success
        )
      );
      return response;
    } catch (err: any) {
      dispatch(addNotification(err.toString(), NotificationLevel.Error));
      return rejectWithValue(handleUnexpectedThunkException(err, 'UnpublishLatest', dispatch));
    }
  }
);

export const unpublishAllTariffs = createAsyncThunk(
  'unpublish/unpublishTariff',
  async (params: unpublishTariffParamsType, { dispatch, rejectWithValue }) => {
    try {
      const client = await TariffApiV2Client();
      const response: SingleResponse<void> = await client.tariffV2.unpublishAll(
        params.masterTariffId
      );
      if (response.status == '403') {
        throw new Error(
          'Error:  Unpublish action can only be done by an user with SME role, please reach out to a SME user to unpublish this tariff'
        );
      }
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
      dispatch(
        addNotification(
          `All versions of MTID ${params.masterTariffId} have been Unpublished(not deleted)`,
          NotificationLevel.Success
        )
      );
      return response;
    } catch (err: any) {
      dispatch(addNotification(err.toString(), NotificationLevel.Error));
      return rejectWithValue(handleUnexpectedThunkException(err, 'UnpublishAll', dispatch));
    }
  }
);

export const unpublishTariffSlice = createSlice({
  name: 'tariffsByMtid',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(unpublishAllTariffs.pending || unpublishLatestTariff.pending, state => {
        state.status = LoadingState.PENDING;
      })
      .addCase(
        unpublishAllTariffs.fulfilled || unpublishLatestTariff.fulfilled,
        (state, action) => {
          const { results, errors } = action.payload;
          if (errors) {
            state.status = LoadingState.FAILED;
            state.errors = errors;
          } else {
            state.status = LoadingState.SUCCEEDED;
            state.results = results;
          }
        }
      )
      .addCase(unpublishAllTariffs.rejected || unpublishLatestTariff.rejected, state => {
        state.status = LoadingState.FAILED;
      });
  },
});

export const selectUnpublishTariff = (state: RootState): unpublishTariffType | undefined => {
  return state.unpublishTariff;
};

export const { reset } = unpublishTariffSlice.actions;

export default unpublishTariffSlice.reducer;
