import { ChargeType } from '@genability/api/dist/types';

export const FORMULA_CONSUMPTION_UPPER_LIMIT = 'tariffRateBand.consumptionUpperLimit';
export const FORMULA_DEMAND_UPPER_LIMIT = 'tariffRateBand.demandUpperLimit';
export const FORMULA_PROPERTY_UPPER_LIMIT = 'tariffRateBand.propertyUpperLimit';

export const tierLimitChargeTypeMap: Map<ChargeType | undefined, string> = new Map<
  ChargeType,
  string
>()
  .set(ChargeType.CONSUMPTION_BASED, FORMULA_CONSUMPTION_UPPER_LIMIT)
  .set(ChargeType.DEMAND_BASED, FORMULA_DEMAND_UPPER_LIMIT)
  .set(ChargeType.QUANTITY, FORMULA_PROPERTY_UPPER_LIMIT);

export const tierLimitFieldMap: Map<
  string,
  'consumptionUpperLimit' | 'demandUpperLimit' | 'propertyUpperLimit'
> = new Map<string, 'consumptionUpperLimit' | 'demandUpperLimit' | 'propertyUpperLimit'>()
  .set(FORMULA_CONSUMPTION_UPPER_LIMIT, 'consumptionUpperLimit')
  .set(FORMULA_DEMAND_UPPER_LIMIT, 'demandUpperLimit')
  .set(FORMULA_PROPERTY_UPPER_LIMIT, 'propertyUpperLimit');

export const tierLimitLabelMap: Map<string, string> = new Map<string, string>()
  .set(FORMULA_CONSUMPTION_UPPER_LIMIT, 'Kwh Upper Limit')
  .set(FORMULA_DEMAND_UPPER_LIMIT, 'Kw Upper Limit')
  .set(FORMULA_PROPERTY_UPPER_LIMIT, 'Prop Upper Limit');

export default {
  tierLimitChargeTypeMap,
  tierLimitFieldMap,
  tierLimitLabelMap,
  FORMULA_CONSUMPTION_UPPER_LIMIT,
  FORMULA_DEMAND_UPPER_LIMIT,
  FORMULA_PROPERTY_UPPER_LIMIT,
};
