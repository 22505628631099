import React, { FC, useEffect, useState } from 'react';
import { Loader, Table } from '@arcadiapower/gen-react-lib';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import {
  fetchLoadServingEntityById,
  selectLoadServingEntity,
} from '../../../state/loadServingEntity/loadServingEntityByIdSlice';
import { BillingPeriodRepresentation } from '@genability/api/dist/types/load-serving-entity';
import { LoadingState } from '../../../state/reduxUtils';

export interface LoadServingEntityParams {
  lseId: string;
}

const LoadServingEntity: FC<RouteComponentProps<LoadServingEntityParams>> = props => {
  const lseId = Number(props.match.params?.lseId);
  const dispatch = useDispatch();
  const { lse, loading, errorMessage } = useSelector(selectLoadServingEntity);
  const [billingPeriod, setBillingPeriod] = useState<BillingPeriodRepresentation>();

  useEffect(() => {
    dispatch(fetchLoadServingEntityById(lseId));
  }, [lseId]);

  useEffect(() => {
    setBillingPeriod(
      Array.isArray(lse?.billingPeriodRepresentation)
        ? lse?.billingPeriodRepresentation[0]
        : lse?.billingPeriodRepresentation
    );
  }, [lse]);

  return (
    <Container fluid className="py-3">
      <h2 className="display-4 my-3 mb-2 mt-5">Load Serving Entity {lseId}</h2>
      {loading === LoadingState.PENDING && (
        <div className="text-center">
          <Loader />
        </div>
      )}
      {loading === LoadingState.FAILED && <div>{errorMessage}</div>}
      {loading === LoadingState.SUCCEEDED && (
        <Table>
          <tbody>
            <tr>
              <td>Name: </td>
              <td colSpan={2}>{lse?.name}</td>
            </tr>
            <tr>
              <td>Code: </td>
              <td colSpan={2}>{lse?.code}</td>
            </tr>
            <tr>
              <td>Website Home: </td>
              <td colSpan={2}>
                <Link target="_blank" to={{ pathname: lse?.websiteHome }}>
                  {lse?.websiteHome}
                </Link>
              </td>
            </tr>
            <tr>
              <td>Offering Type: </td>
              <td colSpan={2}>{lse?.offeringType}</td>
            </tr>
            <tr>
              <td>Ownership: </td>
              <td colSpan={2}>{lse?.ownership}</td>
            </tr>
            <tr>
              <td>Service Types: </td>
              <td colSpan={2}>{lse?.serviceTypes}</td>
            </tr>
            <tr>
              <td>Total Revenues: </td>
              <td colSpan={2}>{lse?.totalRevenues}</td>
            </tr>
            <tr>
              <td>Total Sales: </td>
              <td colSpan={2}>{lse?.totalSales}</td>
            </tr>
            <tr>
              <td>Total customers: </td>
              <td colSpan={2}>{lse?.totalCustomers}</td>
            </tr>
            <tr>
              <td>Residential Service Types: </td>
              <td colSpan={2}>{lse?.residentialServiceTypes}</td>
            </tr>
            <tr>
              <td>Residential Revenues: </td>
              <td colSpan={2}>{lse?.residentialRevenues}</td>
            </tr>
            <tr>
              <td>Residential Sales: </td>
              <td colSpan={2}>{lse?.residentialSales}</td>
            </tr>
            <tr>
              <td>Residential Customers: </td>
              <td colSpan={2}>{lse?.residentialCustomers}</td>
            </tr>
            <tr>
              <td>Commercial Service Types: </td>
              <td colSpan={2}>{lse?.commercialServiceTypes}</td>
            </tr>
            <tr>
              <td>Commercial Revenues: </td>
              <td colSpan={2}>{lse?.commercialRevenues}</td>
            </tr>
            <tr>
              <td>Commercial Sales: </td>
              <td colSpan={2}>{lse?.commercialSales}</td>
            </tr>
            <tr>
              <td>Commercial Customers: </td>
              <td colSpan={2}>{lse?.commercialCustomers}</td>
            </tr>
            <tr>
              <td>Industrial Service Types: </td>
              <td colSpan={2}>{lse?.industrialServiceTypes}</td>
            </tr>
            <tr>
              <td>Industrial Revenues: </td>
              <td colSpan={2}>{lse?.industrialRevenues}</td>
            </tr>
            <tr>
              <td>Industrial Sales: </td>
              <td colSpan={2}>{lse?.industrialSales}</td>
            </tr>
            <tr>
              <td>Industrial Customers: </td>
              <td colSpan={2}>{lse?.industrialCustomers}</td>
            </tr>
            <tr>
              <td>Transportation Service Types: </td>
              <td colSpan={2}>{lse?.transportationServiceTypes}</td>
            </tr>
            <tr>
              <td>Transportation Revenues: </td>
              <td colSpan={2}>{lse?.transportationRevenues}</td>
            </tr>
            <tr>
              <td>Transportation Sales: </td>
              <td colSpan={2}>{lse?.transportationSales}</td>
            </tr>
            <tr>
              <td>Transportation Customers: </td>
              <td colSpan={2}>{lse?.transportationCustomers}</td>
            </tr>
            <tr>
              <td rowSpan={4}>Billing Period Representation: </td>
            </tr>
            <tr>
              <td>From Date Offset: </td>
              <td>{billingPeriod?.fromDateOffset}</td>
            </tr>
            <tr>
              <td>To Date Offset: </td>
              <td>{billingPeriod?.toDateOffset}</td>
            </tr>
            <tr>
              <td>Style: </td>
              <td>{billingPeriod?.style}</td>
            </tr>
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default withRouter(LoadServingEntity);
