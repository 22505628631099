import { Modal, Select } from '@arcadiapower/shrike';
import { types } from '@genability/api';
import { PropertyDataType } from '@genability/api/dist/types';
import React, { FC, useState } from 'react';

interface PropertyKeyDataTypeSelectorProps {
  value: PropertyDataType;
  disabled?: boolean;
  className?: string;
  handleSelected: (dataType: PropertyDataType) => void;
}

const PropertyDataTypeSelector: React.FC<PropertyKeyDataTypeSelectorProps> = props => {
  const { handleSelected, value, disabled, className } = props;
  const [propertyDataType, setPropertyDataType] = useState(value);

  return (
    <>
      <Select
        value={propertyDataType}
        label="Property Data type"
        loadingMessage="Loading..."
        className={className}
        name="Property Data type"
        withEmptyPlaceholder
        required
        disabled={disabled}
        minHeight="50px"
        options={Object.keys(types.PropertyDataType).map(key => ({
          text: PropertyDataType[key as keyof typeof PropertyDataType],
          value: PropertyDataType[key as keyof typeof PropertyDataType],
        }))}
        onChange={value => {
          handleSelected(value as PropertyDataType);
          setPropertyDataType(value as PropertyDataType);
        }}
      />
    </>
  );
};

export default PropertyDataTypeSelector;
