import { restClient } from '@genability/api';
import { TaskV2DocumentSection } from '../types/TaskV2DocumentSection';
import { TaskV2Document } from '../types/TaskV2Document';

export interface UpdateDocumentSectionBody {
  startPage?: number;
  endPage?: number;
}

export class DocumentApi extends restClient.RestApiClient {
  public async getDocument(documentId: number): Promise<restClient.SingleResponse<TaskV2Document>> {
    return this.getSingle(`document/${documentId}`, { params: {} });
  }
  public async updateDocumentSection(
    documentId: number,
    sectionId: number,
    body: UpdateDocumentSectionBody
  ): Promise<restClient.SingleResponse<TaskV2DocumentSection>> {
    return this.put(`document/${documentId}/section/${sectionId}`, body);
  }
}
