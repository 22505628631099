import React, { FC, useState } from 'react';
import {
  TariffFilterContext,
  initialTariffFilterContext,
  TariffFilter,
} from '../../../context/tariffFilterContext';
import TariffsList from '../../../components/TariffList/TariffList';
import { Container, Row } from 'react-bootstrap';
import TariffsFilter from '../../../components/TariffsFilter/TariffsFilter';

const Tariffs: FC = () => {
  const [newFilter, setNewFilter] = useState<TariffFilter>(initialTariffFilterContext.filter);

  return (
    <TariffFilterContext.Provider
      value={{
        filter: newFilter,
        setFilter: setNewFilter,
        onFilterChange: () => {
          return;
        },
      }}
    >
      <Container fluid className="mt-5 py-3">
        <TariffsFilter />
        <Row>
          <TariffsList />
        </Row>
      </Container>
    </TariffFilterContext.Provider>
  );
};

export default Tariffs;
