import { SortOrder } from '@genability/api/dist/rest-client';
import { TaskApiV2Client } from '../GenApiClient';
import { GetLookupValuesRequest } from '../task-api/v2/api/task-api-v2';
import { LookupValueType } from '../task-api/v2/types/LookupValueType';
import { LookupValueV2 } from '../task-api/v2/types/LookupValueV2';
import { restClient } from '@genability/api';
import { LookupValueV2Transformed } from '../task-api/v2/types/LookupValueV2Transformed';

export async function isLookupAlreadyPresent(
  propertyKey: string,
  fromDate: string | undefined,
  toDate: string | undefined
): Promise<boolean> {
  if (!fromDate) return false;
  try {
    const request = new GetLookupValuesRequest();
    request.fromDateTime = fromDate;
    request.toDateTime = toDate;
    const client = await TaskApiV2Client();
    request.sortOrder = [SortOrder.ASC];
    request.sortOn = ['fromDateTime'];
    request.lookupValueType = LookupValueType.ACTUAL;
    request.pageStart = 0;
    request.pageCount = 25;
    const lookupValues: LookupValueV2[] = (
      await client.taskV2.getLookupValues(propertyKey, request)
    ).results;

    return lookupValues.some(lookupValue => lookupValue.propertyLookup.fromDateTime === fromDate);
  } catch (err) {
    console.error(
      'Error occured while checking if there exists another lookup with same from Date ' + err
    );
  }
  return false;
}

export async function updateTaskSource(workflowId: number | null, taskSource: string) {
  if (workflowId == null) {
    throw new Error('workflow Id is null');
  }
  try {
    const client = await TaskApiV2Client();
    await client.taskV2.updateWorkflowTaskMetaData(workflowId, { taskSource });
  } catch (err) {
    console.error('Error while updating the task source ' + err);
  }
}
export async function getFirstForecastLookup(
  propertyKey: string,
  foreCastedLookupStartDate?: string,
  foreCastedLookupEndDate?: string
) {
  try {
    const request = new GetLookupValuesRequest();
    const client = await TaskApiV2Client();
    request.sortOrder = [SortOrder.ASC];
    request.sortOn = ['fromDateTime'];
    request.lookupValueType = LookupValueType.FORECASTED;
    request.pageStart = 0;
    request.pageCount = 1;
    request.fromDateTime = foreCastedLookupStartDate;
    request.toDateTime = foreCastedLookupEndDate;
    const response: restClient.PagedResponse<LookupValueV2> = await client.taskV2.getLookupValues(
      propertyKey,
      request
    );

    const lookupValues: LookupValueV2Transformed[] = response.results.map(
      ({ propertyLookup, document }) => ({
        ...propertyLookup,
        document,
      })
    );
    return lookupValues[0];
  } catch (err) {
    console.error('Error occured while fetching first forecasted lookup ' + err);
  }
  return undefined;
}
