import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { restClient, types, restApis } from '@genability/api';
import { RootState } from '../rootReducer';
import { GenApiClient } from '../../GenApiClient';

export interface TariffState {
  tariff: types.Tariff | null;
  masterTariffId: number | null;
  error: string | null;
  currentRequestId: string | undefined;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

export const initialState: TariffState = {
  tariff: null,
  masterTariffId: null,
  error: null,
  currentRequestId: undefined,
  loading: 'idle',
};

const createTariffRequest = (
  params: Partial<restApis.GetTariffsRequest>
): restApis.GetTariffRequest => {
  const tariffRequest: restApis.GetTariffRequest = new restApis.GetTariffRequest();
  tariffRequest.populateProperties = true;
  tariffRequest.populateRates = true;
  tariffRequest.fields = restClient.Fields.EXTENDED;
  Object.keys(params).forEach((k: string) => {
    const prop = k as keyof restApis.GetTariffsRequest;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tariffRequest[prop] = params[prop] as never;
  });
  return tariffRequest;
};

export const fetchTariff = createAsyncThunk<
  restClient.SingleResponse<types.Tariff> | void,
  number,
  { state: { tariff: TariffState } }
>(
  'tariffSlice/fetchTariff',
  async (
    masterTariffId: number,
    { getState, requestId }
  ): Promise<restClient.SingleResponse<types.Tariff> | void> => {
    const { currentRequestId, loading } = getState().tariff;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return;
    }

    const tariffRequest = createTariffRequest({
      populateProperties: true,
      populateRates: true,
      populateDocuments: true,
      fields: restClient.Fields.EXTENDED,
    });

    const client = await GenApiClient();
    const response = await client.tariffs.getTariff(masterTariffId, tariffRequest);
    return { ...response } as restClient.SingleResponse<types.Tariff>;
  }
);

export const tariffSlice = createSlice({
  name: 'tariff',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchTariff.pending, (state, action) => {
        state.loading = 'pending';
        state.currentRequestId = action.meta.requestId;
        state.error = null;
        state.masterTariffId = action.meta.arg;
      })
      .addCase(fetchTariff.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.currentRequestId !== requestId) {
          return; // ignore stale
        }
        state.loading = 'idle';
        if (action.payload && action.payload.count) {
          state.tariff = action.payload.results[0];
        }
        state.currentRequestId = undefined;
        state.error = null;
      })
      .addCase(fetchTariff.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.currentRequestId != requestId) {
          return; // ignore state
        }
        state.loading = 'idle';
        state.tariff = null;
        state.currentRequestId = undefined;
        state.error = action.error?.message || String(action.error);
      });
  },
});

export default tariffSlice.reducer;

export const selectTariff = (state: RootState): TariffState => state.tariff;
