import { Button, Text } from '@arcadiapower/shrike';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ActualLookupValuesList from '../LookupValuesList/ActualLookupValuesList';
import ForeCastedLookupValuesList from '../LookupValuesList/ForeCastedLookupValuesList';
import { Icon } from '@arcadiapower/gen-react-lib';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLookupStats, selectLookupStats } from '../../state/lookupStats/lookupStatsSlice';
import UnpublishedLookupTasks from '../UnclosedLookupTasks/UnclosedLookupTasks';
import LookupValueEditorModal, {
  ModifiedLookupValue,
} from '../LookupValueEditorModal/LookupValueEditorModal';
import {
  createAddLookupTask,
  resetTaskState,
  selectTaskWorkflowEntity,
} from '../../state/tasksV2/CreateLookupTaskSlice';
import { selectUnpublishedLookupTasks } from '../../state/tasksV2/UnpublishedLookupTaskSlice';
import {
  saveIntermediateAnswer,
  selectIntermediateSavedData,
} from '../../state/tasksV2/intermediateSaveTaskSlice';
import { applyTimeZoneOffSet } from '../../utils/dateUtils';
import {
  fetchDefaultLookupDates,
  selectLookupValues,
} from '../../state/lookupValues/lookupValuesByPropertyKey';
import { GetLookupValuesRequest } from '../../task-api/v2/api/task-api-v2';
import { LookupValueV2Transformed } from '../../task-api/v2/types/LookupValueV2Transformed';
import { fillGaps } from '../../state/tasksV2/LookupsGapFillingSlice';
import { selectTaskWorkflowEntityPostAction } from '../../state/tasksV2/TasksActionSlice';
import { updateTaskSource } from '../../utils/lookupUtil';
import {
  selectDocumentSection,
  updateDocumentSection,
} from '../../state/document-v2/documentSectionSlice';
import { getFirstForecastLookup } from '../../utils/lookupUtil';

export interface LookupDetailProps {
  propertyKey: string;
  isGlobalPropertyKey: boolean;
}

const Divider: FC = () => <hr className="my-3 w-100" />;
const LookupDetail: FC<LookupDetailProps> = ({
  propertyKey,
  isGlobalPropertyKey,
}): ReactElement => {
  const dispatch = useDispatch();
  const { lookupStats, lookupStatsError } = useSelector(selectLookupStats);
  const { published } = useSelector(selectTaskWorkflowEntityPostAction);
  const { isLoading: updateDocumentSectionLoading } = useSelector(selectDocumentSection);
  const {
    actualLookupStartDate,
    actualLookupEndDate,
    foreCastedLookupStartDate,
    foreCastedLookupEndDate,
    defaultDatesError,
  } = useSelector(selectLookupValues);
  const { isLoading: createTaskLoading, error, document } = useSelector(selectTaskWorkflowEntity);
  const {
    taskId,
    workflowId,
    taskType,
    taskSource: currentTaskSource,
  } = useSelector(selectUnpublishedLookupTasks);
  const { isLoading: saveIntermediateAnswerLoading, taskAssignmentAnswer } = useSelector(
    selectIntermediateSavedData
  );
  const [showForecast, setShowForecast] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [taskMetaDataUpdateLoading, setTaskMetaDataUpdateLoading] = useState<boolean>(false);
  const [firstForecastLookup, setFirstForecastLookup] = useState<
    LookupValueV2Transformed | undefined
  >(undefined);

  useEffect(() => {
    dispatch(resetTaskState());
    dispatch(fetchLookupStats(propertyKey));
  }, []);

  useEffect(() => {
    if (published) {
      dispatch(fetchLookupStats(propertyKey));
    }
  }, [published]);

  useEffect(() => {
    if (lookupStats?.minFromDateTime && lookupStats?.maxToDateTime) {
      const request = new GetLookupValuesRequest();
      request.fromDateTime = lookupStats?.minFromDateTime.toString();
      request.toDateTime = lookupStats?.maxToDateTime.toString();
      dispatch(fetchDefaultLookupDates(propertyKey, request));
    }
  }, [lookupStats]);

  useEffect(() => {
    if (foreCastedLookupStartDate && foreCastedLookupEndDate) {
      const fetchFirstForecastLookup = async () => {
        const result = await getFirstForecastLookup(
          propertyKey,
          foreCastedLookupStartDate,
          foreCastedLookupEndDate
        );
        setFirstForecastLookup(() => result);
      };
      fetchFirstForecastLookup();
    }
  }, [foreCastedLookupStartDate, foreCastedLookupEndDate]);

  useEffect(() => {
    if (taskId && workflowId && taskAssignmentAnswer) {
      const intermediateSavedLookups: LookupValueV2Transformed[] = taskAssignmentAnswer?.answerValue
        ? JSON.parse(taskAssignmentAnswer?.answerValue)
        : [];
      dispatch(
        fillGaps(
          propertyKey,
          taskId,
          workflowId,
          taskType,
          intermediateSavedLookups,
          actualLookupStartDate,
          actualLookupEndDate
        )
      );
    }
  }, [taskId, taskAssignmentAnswer]);

  const uploadDocument = (file: File, taskSource: string) => {
    const data = new FormData();
    data.append('document', file, file.name);
    dispatch(createAddLookupTask(propertyKey, taskSource, isGlobalPropertyKey, data));
  };

  const save = async (
    modifiedLookupValues: ModifiedLookupValue,
    taskSource: string,
    startPage?: number,
    endPage?: number
  ) => {
    const lookupValues = [
      {
        fromDateTime:
          applyTimeZoneOffSet(modifiedLookupValues.fromDateTime)?.toISOString().slice(0, -5) ||
          null,
        toDateTime:
          applyTimeZoneOffSet(modifiedLookupValues.toDateTime)?.toISOString().slice(0, -5) || null,
        actualValue: Number(modifiedLookupValues.actualValue),
        forecastValue: modifiedLookupValues.forecastValue
          ? Number(modifiedLookupValues.forecastValue)
          : undefined,
        propertyKey,
      },
    ];
    const answerJSON = JSON.stringify(lookupValues);
    if (isGlobalPropertyKey && !taskId) {
      await dispatch(
        createAddLookupTask(propertyKey, taskSource, isGlobalPropertyKey, undefined, answerJSON)
      );
    }
    if (taskId && taskSource != currentTaskSource) {
      setTaskMetaDataUpdateLoading(true);
      await updateTaskSource(workflowId, taskSource);
      setTaskMetaDataUpdateLoading(false);
    }
    if (document?.sections[0]?.documentSectionId) {
      await dispatch(
        updateDocumentSection(
          document.documentId,
          document.sections[0].documentSectionId,
          startPage,
          endPage
        )
      );
    }
    if (taskId) await dispatch(saveIntermediateAnswer(taskId, workflowId, answerJSON));
    setShowAddModal(false);
  };

  return (
    <Container fluid className="p-4">
      <>
        <Divider />{' '}
        <UnpublishedLookupTasks
          propertyKey={propertyKey}
          isGlobalPropertyKey={isGlobalPropertyKey}
        />
      </>
      <Divider />
      <Row>
        <Col>
          <Text color="primary" opacity="high" tag="h3" textStyle="heading600">
            Lookup Values
          </Text>
        </Col>
        <Col xs={3} md={2}>
          <Button
            backgroundColor="primaryInverse"
            className=" float-right"
            data-testid="add-lookup-value-button"
            onClick={() => setShowAddModal(true)}
            disabled={taskId != null}
          >
            <Icon iconName="add" className="mr-2" /> Add Lookup Value
          </Button>
        </Col>
      </Row>
      <Row>
        {lookupStats && actualLookupStartDate && actualLookupEndDate ? (
          <ActualLookupValuesList
            propertyKey={propertyKey}
            isGlobalPropertyKey={isGlobalPropertyKey}
          />
        ) : (
          <Col>
            <Text color="primary" opacity="high" tag="p" textStyle="paragraph600">
              {'Lookup Data not found'}
            </Text>
          </Col>
        )}
      </Row>
      {lookupStats && (
        <Row className="justify-content-center">
          <Button
            className="mt-5 w-25"
            backgroundColor="accent2"
            onClick={() => setShowForecast(!showForecast)}
          >
            {showForecast ? 'Hide forecast Values' : 'Show forecast Values'}
          </Button>
        </Row>
      )}
      {showForecast && (
        <>
          <Divider />
          <Row>
            <Col>
              <Text color="primary" opacity="high" tag="h3" textStyle="heading600">
                Forecasted Lookup Values
              </Text>
            </Col>
            {foreCastedLookupStartDate && foreCastedLookupEndDate ? (
              <ForeCastedLookupValuesList propertyKey={propertyKey} />
            ) : (
              <Col>
                <Text color="primary" opacity="high" tag="p" textStyle="paragraph600">
                  {defaultDatesError ? defaultDatesError : 'Lookup Data not found'}
                </Text>
              </Col>
            )}
          </Row>
        </>
      )}
      {showAddModal && (
        <LookupValueEditorModal
          show={showAddModal}
          isGlobalPropertyKey={isGlobalPropertyKey}
          propertyKey={propertyKey}
          lookupValue={firstForecastLookup}
          onHide={() => setShowAddModal(false)}
          onSave={save}
          isSaving={
            taskId
              ? taskMetaDataUpdateLoading ||
                saveIntermediateAnswerLoading ||
                updateDocumentSectionLoading
              : createTaskLoading
          }
          onUploadDocument={!isGlobalPropertyKey ? uploadDocument : undefined}
          isUploadingDocument={createTaskLoading}
          uploadDocumentFailure={error != null && error?.length > 0}
          document={document}
        />
      )}
    </Container>
  );
};
export default LookupDetail;
