import { Input, Modal, Radio, Text } from '@arcadiapower/shrike';
import React, { FC, ReactElement, useState } from 'react';
import { CopyTariffSourceType } from '../../task-api/v2/types/CopyTariffSourceType';
import { TaskApiV2Client } from '../../GenApiClient';
import { TaskAssignment } from '../../task-api/v2/types/TaskV2';
import { SingleResponse } from '@genability/api/dist/rest-client';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../state/notification/notificationSlice';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { Col, Row } from 'react-bootstrap';

interface CopyTariffProps {
  taskAssignmentId: number;
  onHide: () => void;
  show: boolean;
  onTariffCopied: (taskCopied: boolean) => void;
}

const sourceTypeToNameMap: Record<CopyTariffSourceType, string> = {
  [CopyTariffSourceType.TARIFF]: 'Tariff Id',
  [CopyTariffSourceType.TASK]: 'Task Id',
  [CopyTariffSourceType.TASK_ASSIGNMENT]: 'TaskAssignment Id',
};

const CopyTariffModal: FC<CopyTariffProps> = ({
  taskAssignmentId,
  onHide,
  show,
  onTariffCopied,
}): ReactElement => {
  const [sourceType, setSourceType] = useState<CopyTariffSourceType>(CopyTariffSourceType.TARIFF);
  const [sourceId, setSourceId] = useState<number | undefined>(undefined);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const copyTask = async (sourceType: CopyTariffSourceType, sourceId: number | undefined) => {
    if (!sourceId) {
      throw new Error('source ID cannot be undefined');
    }
    const client = await TaskApiV2Client();
    setSubmitLoading(true);
    const response: SingleResponse<TaskAssignment> = await client.tariff.copyTariff({
      targetTaskAssignmentId: taskAssignmentId,
      sourceType: sourceType,
      sourceId: sourceId,
    });
    setSubmitLoading(false);
    if (response.errors) {
      const errorMessage = response.errors[0].message;
      dispatch(addNotification(errorMessage, NotificationLevel.Error));
    } else {
      onTariffCopied(true);
      dispatch(addNotification('Tariff has been successfully copied.', NotificationLevel.Success));
    }
  };
  return (
    <>
      <Modal aria-label="Modal" isOpen={show} size="medium">
        <Modal.Header title="Copy Tariff"></Modal.Header>
        <Modal.Content>
          <Row className="mb-4">
            <Text textStyle="heading500">Copy From</Text>
          </Row>
          <Row className="mb-4">
            {Object.values(CopyTariffSourceType).map(value => (
              <div
                key={value}
                style={{ display: 'inline-block', marginLeft: '15px', marginRight: '15px' }}
              >
                <Radio
                  name={value}
                  value={value}
                  onChange={val => setSourceType(val as CopyTariffSourceType)}
                  checked={sourceType == value}
                >
                  {sourceTypeToNameMap[value]}
                </Radio>
              </div>
            ))}
          </Row>
          <Row className="mb-4">
            <Input
              label="Source ID"
              type="number"
              inputMode="numeric"
              name="Source ID"
              value={sourceId?.toString()}
              onChange={val => setSourceId(parseInt(val))}
            />
          </Row>
        </Modal.Content>
        <Modal.Footer
          onSubmit={() => copyTask(sourceType, sourceId)}
          loading={submitLoading}
          disabled={!sourceId || !sourceType}
          onCancel={onHide}
        ></Modal.Footer>
      </Modal>
    </>
  );
};

export default CopyTariffModal;
