import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { withRouter, RouteComponentProps } from 'react-router';
import { useDispatch } from 'react-redux';
import { signOut } from '../state/currentUser/currentUserSlice';
import appConstants from '../app-constants';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import logo from '../logo.svg';
import styles from './GlobalNavBar.module.scss';
import { Icon } from '@arcadiapower/shrike';
import SideMenu from './SideMenu';

const GlobalNavBar: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
): React.ReactElement => {
  const pages = [
    {
      key: 'dashboard',
      display: 'Dashboard',
      route: appConstants.routes.dashboard,
      disabled: false,
    },
    {
      key: 'propertyDirectory',
      display: 'Property Directory',
      route: appConstants.routes.propertykeyDirectory,
      disabled: false,
    },
    {
      key: 'tariff',
      display: 'Tariff Directory',
      route: appConstants.routes.tariff,
      disabled: false,
    },
    {
      key: 'opsManagement',
      display: 'Ops Management',
      route: appConstants.routes.opsManagement,
      disabled: false,
    },
  ];
  const { history } = props;
  const [activeRoute] = React.useState(history.location.pathname);
  const dispatch = useDispatch();
  const [showSideMenu, setShowSideMenu] = useState(false);
  const hideSideMenu = () => setShowSideMenu(false);

  const handleSignOut = () => {
    dispatch(signOut());
  };
  return (
    <Navbar fixed="top" className={`${styles.navContainer} bg-dark font-weight-bold `}>
      <div className={styles.navLeft}>
        <span className={styles.logo}>
          <img src={logo} />
        </span>
        <span className={'text-white'}>Team Tariff</span>
      </div>
      <Nav fill className={styles.navCenter} activeKey={activeRoute}>
        {pages.map(page => {
          return (
            <LinkContainer
              key={page.key}
              to={page.route}
              className={`${styles.navLink} ${page.disabled ? styles.disabledLink : ''}`}
              activeClassName={styles.navLinkActive}
            >
              <Nav.Item>{page.display}</Nav.Item>
            </LinkContainer>
          );
        })}
      </Nav>
      <div className={styles.navRight}>
        <span>
          <Link to={appConstants.routes.settings} className={styles.leftPadding}>
            <Icon
              className="justify-content-end mr-2"
              color="primaryInverse"
              icon="UIAccountCircleInverted"
              opacity="high"
              scale={4}
              title="User Profile Tab"
            />
          </Link>
        </span>
        <span>
          <Icon
            className="justify-content-end mr-2"
            color="primaryInverse"
            icon="UIHamburgerMenu"
            opacity="high"
            scale={4}
            title="Load Side Menu"
            onClick={() => setShowSideMenu(!showSideMenu)}
          />
        </span>
        <span>
          <Icon
            className="justify-content-end"
            color="primaryInverse"
            icon="SignOut"
            opacity="high"
            scale={4}
            title="Logout Button"
            onClick={handleSignOut}
          />
        </span>
      </div>
      <SideMenu isOpen={showSideMenu} closeSideMenu={hideSideMenu} />
    </Navbar>
  );
};

export default withRouter(GlobalNavBar);
