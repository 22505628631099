import React, { ReactElement, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import TariffConfigTable from '../../components/OpsManagement/Config/TariffConfigTable';
import LookupConfigTable from '../../components/OpsManagement/Config/LookupConfigTable';
import DropdownSelect from '../../components/DropdownSelect/DropdownSelect';
import { selectCurrentUserWorkerData } from '../../state/currentUser/currentUserSlice';
import { OpsGlobalFilterProps } from './OpsManagementTabs';
import { useSelector } from 'react-redux';
import { EntityType } from '../../task-api/v2/types/EntityType';
import styles from './OpsManagement.module.scss';
import TariffConfigStatsTable from '../../components/OpsManagement/Config/TariffConfigStatsTable';
import LookupConfigStatsTable from '../../components/OpsManagement/Config/LookupConfigStatsTable';

const ConfigTab = ({
  filter,
  setFilter,
  currentUserIsSME,
  userListLoading,
}: OpsGlobalFilterProps): ReactElement => {
  const currentUser = useSelector(selectCurrentUserWorkerData);
  const [selectedEnityType, setSelectedEnityType] = useState<EntityType>(EntityType.TARIFF);

  return (
    <Container fluid className="">
      <Row className="d-flex pb-3 mb-4 mt-3 justify-content-between border-bottom">
        <span className="d-flex flex-column ">
          <h4 className="mb-3">
            Welcome <span className="ml-1">{currentUser?.userName.split('@')[0]},</span>
          </h4>
          <span className="d-flex align-items-center mt-1">
            <h5 className="mt-1">Select: </h5>
            <DropdownSelect
              popoverStyle={{ zIndex: 10 }}
              label=""
              onChange={val => {
                return;
              }}
              setSelected={value => setSelectedEnityType(value as EntityType)}
              selected={selectedEnityType}
              options={[EntityType.TARIFF, EntityType.LOOKUP]}
              className={`ml-4 ${styles.selectEntity}`}
            />
          </span>
        </span>
        {selectedEnityType === EntityType.TARIFF && <TariffConfigStatsTable />}
        {selectedEnityType === EntityType.LOOKUP && <LookupConfigStatsTable />}
      </Row>
      {selectedEnityType === EntityType.TARIFF && (
        <TariffConfigTable
          filter={filter[0]}
          setFilter={setFilter[0]}
          currentUserIsSME={currentUserIsSME}
          userListLoading={userListLoading}
        />
      )}
      {selectedEnityType === EntityType.LOOKUP && (
        <LookupConfigTable
          filter={filter[1]}
          setFilter={setFilter[1]}
          currentUserIsSME={currentUserIsSME}
          userListLoading={userListLoading}
        />
      )}
    </Container>
  );
};

export default ConfigTab;
