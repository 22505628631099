import React, { FC } from 'react';
import styles from './TariffRow.module.scss';

export enum RowColor {
  ColorVersion = styles.colorVersion,
  ColorNewRider = styles.colorNewRider,
  ColorLookupValue = styles.colorLookupvalue,
  ColorSeason = styles.colorSeason,
}

interface TariffRowProps {
  rowStyle: RowColor;
  link?: string;
  children: React.ReactNode;
}

const TariffRow: FC<TariffRowProps> = ({ link, rowStyle, children }) => {
  const contents = (
    <span className={`${styles.tariffRow} font-weight-bold ${rowStyle}`}>{children}</span>
  );
  return (
    <div className="my-2">
      {link ? (
        <a href={link} target="_blank" rel="noreferrer" className={styles.tariffLink}>
          {contents}
        </a>
      ) : (
        contents
      )}
    </div>
  );
};

export default TariffRow;
