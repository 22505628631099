import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { restClient } from '@genability/api';
import { TaskApiClient } from '../../GenApiClient';
import { handleUnexpectedThunkException } from '../reduxUtils';
import { RootState } from '../rootReducer';
import { IWorker } from '../../task-api/types/worker';

export interface WorkersState {
  apiStatus: 'idle' | 'pending' | 'notfound' | 'resolved' | 'rejected';
  results: IWorker[] | undefined;
  errors: restClient.ResponseError[] | undefined;
  count: number | undefined;
  pageCount: number | undefined;
  pageStart: number | undefined;
}

export const initialState: WorkersState = {
  apiStatus: 'idle',
  results: undefined,
  errors: undefined,
  count: 0,
  pageStart: 0,
  pageCount: 0,
};

interface WorkerOptionsProps {
  request?: restClient.DefaultPagedRequest;
}

export const fetchWorkers = createAsyncThunk(
  'worker/fetchWorkers',
  async (options: WorkerOptionsProps | undefined, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiClient();
      const response: restClient.PagedResponse<IWorker> = await client.worker.getWorkers(
        options?.request
      );
      return { ...response }; // using spread on the response avoids the 'non serializable payload detected' error from redux toolkit
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'Workers', dispatch));
    }
  }
);

export const WorkerSlice = createSlice({
  name: 'workers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchWorkers.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(
      fetchWorkers.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<IWorker>>) => {
        const { results, count, pageCount, pageStart, errors } = action.payload;
        if (errors) {
          state.apiStatus = 'rejected';
          state.errors = errors;
          state.results = undefined;
          state.count = undefined;
          state.pageCount = undefined;
          state.pageStart = undefined;
        } else {
          state.apiStatus = 'resolved';
          state.results = results;
          state.errors = undefined;
          state.count = count;
          state.pageCount = pageCount;
          state.pageStart = pageStart;
        }
      }
    );
    builder.addCase(fetchWorkers.rejected, state => {
      state.apiStatus = 'rejected';
      state.results = undefined;
      state.count = undefined;
      state.pageCount = undefined;
      state.pageStart = undefined;
    });
  },
});

export const selectWorkerApiStatus = (state: RootState): string => {
  return state.workers.apiStatus;
};

export const selectWorkers = (state: RootState): IWorker[] | undefined => {
  return state.workers.results;
};
export default WorkerSlice.reducer;
