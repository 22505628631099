import React, { FC } from 'react';
import { types } from '@genability/api';
import TariffRow, { RowColor } from '../TariffRow';

export interface SeasonChangesProps {
  seasonChanges: { seasonChange: string; seasons: types.Season[] };
}

const SeasonChanges: FC<SeasonChangesProps> = ({ seasonChanges }) => {
  const renderSeasonChange = (seasonChange: types.Season[]) => {
    return (
      <div>
        <TariffRow rowStyle={RowColor.ColorSeason}>
          <span className="mr-2">Season Change:</span>
          {seasonChange?.map((season, index) => (
            <span key={`Seasons-${index}`}>
              {index > 0 && <span className="mx-2">&rarr;</span>}
              {season?.seasonName}{' '}
              <span>
                ({season?.seasonFromMonth}/{season?.seasonFromDay} - {season?.seasonToMonth}/
                {season?.seasonToDay})
              </span>
            </span>
          ))}
        </TariffRow>
      </div>
    );
  };

  return <div className="my-2">{seasonChanges && renderSeasonChange(seasonChanges.seasons)}</div>;
};

export default SeasonChanges;
