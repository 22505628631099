import React, { useEffect, useState } from 'react';
import { Alert, Loading, Modal } from '@arcadiapower/shrike';
import { EllipsisPagination, Table, Td, Tr } from '@arcadiapower/gen-react-lib';
import { Tariff } from '@genability/api/dist/types';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'react-bootstrap';
import { fetchTariffsByMtid } from '../../state/tariffs/tariffsByMtid';
import { RootState } from '../../state/rootReducer';
import { Link } from 'react-router-dom';
import { LoadingState } from '../../state/reduxUtils';
import {
  reset,
  unpublishAllTariffs,
  unpublishLatestTariff,
} from '../../state/UnpublishTariffSlice/UnpublishTariffSlice';

interface tariffListProps {
  buttonText: string;
  setShowTariffListModal: (value: boolean) => void;
  showTariffListModal: boolean;
  masterTariffId: number;
  refreshTab: () => void;
}

interface messageType {
  'Unpublish Latest Version': string;
  'Unpublish All': string;
}

const TariffListModal = (props: tariffListProps) => {
  const dispatch = useDispatch();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const {
    results: tariffs,
    errors,
    loading,
    meta,
  } = useSelector((state: RootState) => state.tariffsByMtid);
  const { results: unpublishedTariffs, status } = useSelector(
    (state: RootState) => state.unpublishTariff
  );
  const [currentMeta, setCurrentMeta] = useState<any>({
    currentPage: 1,
  });

  const messages: messageType = {
    'Unpublish Latest Version': `This will unpublish tariff version Id ${
      tariffs?.length ? tariffs[tariffs.length - 1].tariffId : null
    } and revive tariff version Id ${
      tariffs?.length > 1 ? tariffs[tariffs.length - 2].tariffId : null
    }, also puts back the associated task in posted status you can reject it to delete the tariff or remodel it and publish it`,
    'Unpublish All': `This will Unpublish all versions of MTID ${props.masterTariffId}`,
  };

  const columnHeaders = ['TARIFF ID', 'EFF START DATE', 'EFF END DATE', 'PUBLISHED'];
  const columns = ['tariffId', 'effectiveDate', 'endDate'];

  const handleSubmit = () => {
    setShowConfirmationModal(true);
    if (props.buttonText != 'Unpublish All') {
      dispatch(
        fetchTariffsByMtid({
          masterTariffId: props.masterTariffId,
          pageCount: meta.count,
        })
      );
    }
  };

  const handleConfirmation = () => {
    if (props.buttonText == 'Unpublish All') {
      unpublishAllTariffsHandler();
    } else {
      unpublishLatestTariffHandler();
    }
  };
  const unpublishAllTariffsHandler = () => {
    dispatch(
      unpublishAllTariffs({
        buttonText: props.buttonText,
        masterTariffId: props.masterTariffId,
        tariffs,
      })
    );
  };
  const unpublishLatestTariffHandler = () => {
    dispatch(
      unpublishLatestTariff({
        buttonText: props.buttonText,
        masterTariffId: props.masterTariffId,
        tariffs,
      })
    );
  };

  useEffect(() => {
    if (currentMeta.currentPage == 0) return;
    dispatch(
      fetchTariffsByMtid({
        masterTariffId: props.masterTariffId,
        pageCount: 5,
        pageStart: currentMeta.currentPage - 1,
      })
    );
  }, [currentMeta]);

  useEffect(() => {
    if (status != 'idle') {
      setShowConfirmationModal(false);
      props.setShowTariffListModal(false);
      dispatch(reset());
      props.refreshTab();
    }
  }, [unpublishedTariffs]);

  return (
    <>
      <Modal aria-label="Modal" isOpen={props.showTariffListModal} size="large">
        {!showConfirmationModal && (
          <>
            <Modal.Header title="Tariff List"></Modal.Header>
            <Modal.Content>
              <Table>
                <thead>
                  <Tr>
                    <Td>
                      <b className="mr-3">MTID : {props.masterTariffId}</b>(
                      {tariffs?.length ? tariffs[0].tariffName : '-'})
                    </Td>
                    <Td>
                      <b className="mr-3">LSE ID : {tariffs?.length ? tariffs[0].lseId : 'null'}</b>
                      ({tariffs?.length ? tariffs[0].lseName : '-'})
                    </Td>
                    <Td>
                      <b className="mr-2">TARIFF TYPE : </b>
                      {tariffs?.length ? tariffs[0].tariffType : '-'}
                    </Td>
                  </Tr>
                  <Tr>
                    {columnHeaders.map(column => (
                      <Td key={column}>
                        <b>{column}</b>
                      </Td>
                    ))}
                  </Tr>
                </thead>
                <tbody>
                  {loading === LoadingState.PENDING ? (
                    <Loading />
                  ) : tariffs?.length ? (
                    tariffs.map(tariff => (
                      <Tr key={tariff.tariffId}>
                        {columns.map(column => (
                          <Td key={column}>
                            {column == 'tariffId' ? (
                              <Link
                                target="_blank"
                                to={{
                                  pathname: `${process.env.REACT_APP_MOTHER_URL}/lses/${tariff.lseId}/tariffs/${tariff.tariffId}`,
                                }}
                              >
                                {tariff.tariffId}
                              </Link>
                            ) : (
                              tariff[column as keyof Tariff]
                            )}
                          </Td>
                        ))}
                        <Td>True</Td>
                      </Tr>
                    ))
                  ) : (
                    <p className="ml-3">No data available</p>
                  )}
                </tbody>
              </Table>
              <Row className="justify-content-center">
                <EllipsisPagination
                  count={meta.count}
                  page={meta.page}
                  pageCount={meta.pageCount}
                  setCurrentMeta={setCurrentMeta}
                />
              </Row>
            </Modal.Content>
            <Modal.Footer
              onSubmit={handleSubmit}
              loading={loading === LoadingState.PENDING}
              primaryText={props.buttonText}
              onCancel={() => {
                props.setShowTariffListModal(false);
              }}
            ></Modal.Footer>
          </>
        )}
        {showConfirmationModal && (
          <>
            <Modal.Header title="Confirmation"></Modal.Header>
            <Modal.Content>
              {loading === LoadingState.PENDING && <Loading />}
              {loading !== LoadingState.PENDING && (
                <>
                  <p>{messages[props.buttonText as keyof messageType]}</p>
                  <Alert>This is an irreversible action</Alert>
                </>
              )}
            </Modal.Content>
            <Modal.Footer
              onSubmit={handleConfirmation}
              loading={status === LoadingState.PENDING || loading === LoadingState.PENDING}
              primaryText={'Confirm Unpublish'}
              onCancel={() => props.setShowTariffListModal(status === LoadingState.PENDING)}
            ></Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default TariffListModal;
