import { Th, Tr, Table } from '@arcadiapower/gen-react-lib';
import React, { FC, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { format, parseISO } from 'date-fns';
import { Text } from '@arcadiapower/shrike';
export interface TaskDetailHeaderParams {
  postedDate?: string | null;
  completedDate?: string | null;
  reviewedDate?: string | null;
  publishedDate?: string | null;
}

const TaskStatusDetails: FC<TaskDetailHeaderParams> = ({
  postedDate,
  completedDate,
  reviewedDate,
  publishedDate,
}) => {
  const dateFormatter = useCallback((date: string) => format(parseISO(date), 'dd-MMM-yyyy'), []);
  return (
    <>
      <Row>
        <Text color="primary" opacity="high" tag="h3" textStyle="heading700" className="ml-4 mt-3">
          Task Timeline
        </Text>
      </Row>
      <Row className="mt-3 ml-2">
        <Col xs={9} className="text-center">
          <Table bordered hover>
            <thead>
              <tr>
                <Th key="tsksts_postedDate">Posted Date</Th>
                <Th key="ttsksts_completedDate">Completed Date</Th>
                <Th key="tsksts_reviewedDate">Review Date</Th>
                <Th key="tsksts_publishedDate">Published Date</Th>
              </tr>
            </thead>
            <tbody>
              {!postedDate && !completedDate && !reviewedDate && !publishedDate ? (
                <Tr>
                  <td colSpan={4}>No results found</td>
                </Tr>
              ) : (
                <Tr>
                  <td>{postedDate ? dateFormatter(String(postedDate)) : null}</td>
                  <td>{completedDate ? dateFormatter(String(completedDate)) : null}</td>
                  <td>{reviewedDate ? dateFormatter(String(reviewedDate)) : null}</td>
                  <td>{publishedDate ? dateFormatter(String(publishedDate)) : null}</td>
                </Tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default TaskStatusDetails;
