import { restClient } from '@genability/api';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OpsManagementApiClient } from '../../../GenApiClient';
import { handleUnexpectedThunkException } from '../../reduxUtils';
import { RootState } from '../../rootReducer';

export interface LookupConfigStatsType {
  totalLse: number;
  totalLookups: number;
  assigendLookups: number;
  unAssigendLookups: number;
  totalLseAssignedToCurrentUser: number;
  totalLookupsAssignedToCurrentUser: number;
}
export interface LookupConfigStatsResponse {
  results: LookupConfigStatsType[];
  apiStatus: 'idle' | 'pending' | 'resolved' | 'rejected';
  errors: restClient.ResponseError[] | undefined;
  count: number;
}
const initialState: LookupConfigStatsResponse = {
  results: [],
  apiStatus: 'pending',
  count: 0,
  errors: undefined,
};

export const fetchLookupConfigStats = createAsyncThunk(
  'opsManagement/config/stats/lookup',
  async (request: any, { dispatch, rejectWithValue }) => {
    try {
      const client = await OpsManagementApiClient();
      const response: restClient.PagedResponse<LookupConfigStatsType> =
        await client.opsData.lookupConfigStats();
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
      return response;
    } catch (err) {
      return rejectWithValue(
        handleUnexpectedThunkException(err, 'LookupConfigStatsSlice', dispatch)
      );
    }
  }
);

export const LookupConfigStatsSlice = createSlice({
  name: 'LookupConfigStatsSlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchLookupConfigStats.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(
      fetchLookupConfigStats.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<LookupConfigStatsType>>) => {
        const { count, results, errors } = action.payload;
        if (errors) {
          state.apiStatus = 'rejected';
          state.errors = errors;
        } else {
          state.apiStatus = 'resolved';
          state.count = count;
          state.results = results;
          state.errors = undefined;
        }
      }
    );
    builder.addCase(fetchLookupConfigStats.rejected, state => {
      state.apiStatus = 'rejected';
    });
  },
});

export default LookupConfigStatsSlice.reducer;

export const selectLookupConfigStats = (state: RootState): LookupConfigStatsResponse => {
  return state.lookupConfigStatsData;
};
