export enum UserMonitoringStatusType {
  NO_UPDATES = 'NO_UPDATES',
  RATE_UPDATED = 'RATE_UPDATED',
  RATE_EXTENDED = 'RATE_EXTENDED',
  RATE_NOT_RELEASED = 'RATE_NOT_RELEASED',
  QUERY = 'QUERY',
  DOCUMENT_NOT_AVAILABLE = 'DOCUMENT_NOT_AVAILABLE',
  UTILITY_OUTREACH = 'UTILITY_OUTREACH',
  TARIFF_NEWLY_ADDED = 'TARIFF_NEWLY_ADDED',
  YET_TO_REVISE = 'YET_TO_REVISE',
  TARIFF_OR_RIDER_EXPIRED = 'TARIFF_OR_RIDER_EXPIRED',
}
