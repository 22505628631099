import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Formula, restClient, types } from '@genability/api';
import { Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  Button,
  ChargeTypeIcon,
  CheckInput,
  FilterChipGroup,
  RadioInput,
  SelectInput,
  Form as GenForm,
  TextInput,
} from '@arcadiapower/gen-react-lib';
import { useForm, FormProvider } from 'react-hook-form';
import useTariffRateAnswer from '../../utils/useTariffRateAnswer';
import PropertyKeyPicker from '../PropertyKeyPicker/PropertyKeyPicker';
import SeasonDropdown from '../SeasonDropdown/SeasonDropdown';
import TimeOfUseDropdown from '../TimeOfUseDropdown/TimeOfUseDropdown';
import { selectTimeOfUseGroupsByLseId } from '../../state/timeOfUses/timeOfUseSlice';
import { selectAllPropertyKeysByKeyName } from '../../state/propertyKeys/propertyKeysSlice';
import { cloneDeep, pick } from 'lodash';
import styles from './EditTariffRateForm.module.scss';
import { ControlledPropertyKeyInput } from '../PropertyKeyInput/PropertyKeyInput';
import {
  FORMULA_CONSUMPTION_UPPER_LIMIT,
  FORMULA_DEMAND_UPPER_LIMIT,
  FORMULA_PROPERTY_UPPER_LIMIT,
  tierLimitChargeTypeMap,
} from '../../utils/rateFormConstants';
import { mapApiErrors, withErrors } from '../../utils/formUtils';
import StructureRow from './components/StructureRow';
import Territories from './components/Territories';
import RateBands from './components/RateBands';
import { ChargeType } from '@genability/api/dist/types';
import ApiFormError, { FormErrorObject } from '../ApiFormError/ApiFormError';
import {
  PropertyKeyPickerContextType,
  usePropertyKeyPickerContext,
} from '../../context/propertyKeyPickerContext';
import { resetDQCheckOverride, selectDQCheckOverrides } from '../../state/dqCheck/dqCheckSlice';
import { useAppDispatch } from '../../state/store';
import { selectSeasons } from '../../state/loadServingEntity/loadServingEntitySlice';
import { updateRateEditAnalytics } from '../../utils/analyticsUtil';

interface EditTariffRateFormProps {
  tariffRate: types.TariffRate;
  taskAssignmentId?: number;
  onCancel: (tariffRateId: number) => void;
  lseId: number;
  currency?: string;
  className?: string;
  onSubmit: (modifyRate: types.TariffRate) => void;
  apiErrors?: restClient.ResponseError[];
  setIsRateDirty?: Dispatch<SetStateAction<boolean>>;
  rateIndex?: number;
}

const EditTariffRateForm: React.FC<EditTariffRateFormProps> = ({
  tariffRate,
  taskAssignmentId,
  lseId,
  onCancel,
  onSubmit,
  currency,
  className,
  apiErrors,
  setIsRateDirty = val => val,
  rateIndex,
}: EditTariffRateFormProps) => {
  const {
    tariffRateAnswer,
    resetAnswer,
    regroupBands,
    setRateField,
    resetSeason,
    resetTou,
    removeSeasonAndTou,
    addRateBand,
    deleteRateBand,
    removeTiers,
    setVariableLimitKey,
    hasTiers,
    addTiers,
    hasApplicability,
    addApplicability,
    removeApplicability,
    changeApplicabilityValue,
  } = useTariffRateAnswer(tariffRate);

  if (!tariffRateAnswer) return null;

  const [isDirty, setIsDirty] = useState(false);
  const [nextSpin, setNextSpin] = useState(false);

  const seasonGroups: types.SeasonGroup[] = useSelector(selectSeasons(lseId));

  const touGroups: types.TimeOfUseGroup[] = useSelector(selectTimeOfUseGroupsByLseId(lseId));

  const propertyKeys = useSelector(selectAllPropertyKeysByKeyName);

  const dqOverrides = useSelector(selectDQCheckOverrides);
  const dispatch = useAppDispatch();

  if (!seasonGroups || !touGroups || !propertyKeys) return null;

  const rateFields: (keyof types.TariffRate)[] = [
    'rateName',
    'edgePredominance',
    'transactionType',
    'chargePeriod',
    'rateBands',
    'territory',
    'variableLimitKey',
    'applicabilityKey',
    'variableRateKey',
    'variableFactorKey',
    'quantityKey',
  ];
  const rateFieldsLiteral = [...rateFields] as const;
  interface TariffRateFields extends Pick<types.TariffRate, (typeof rateFieldsLiteral)[number]> {
    hasEdgePredominance: boolean;
    hasSeasonChecked: boolean;
    hasTouChecked: boolean;
    hasNoneChecked: boolean;
    hasFactorChecked: boolean;
    hasVariableChecked: boolean;
    hasTieredChecked: boolean;
    hasCriteriaChecked: boolean;
    seasonId?: number | string;
    touId?: number | string;
  }

  const useFormMethods = useForm<TariffRateFields>({
    mode: 'onChange',
    defaultValues: {
      ...pick(tariffRateAnswer, rateFields),
      hasEdgePredominance: !!tariffRateAnswer.edgePredominance,
      hasSeasonChecked: !!tariffRateAnswer.season,
      hasTouChecked: !!tariffRateAnswer.timeOfUse,
      hasNoneChecked: !tariffRateAnswer.season && !tariffRate.timeOfUse,
      hasFactorChecked: types.hasVariableOrCalculationFactor(tariffRate),
      hasVariableChecked: !!tariffRate.variableRateKey,
      hasTieredChecked:
        tariffRateAnswer.rateBands &&
        (hasTiers(tariffRateAnswer?.rateBands) ||
          !!tariffRateAnswer.variableLimitKey ||
          (tariffRateAnswer.rateBands.length > 1 && !hasApplicability(tariffRateAnswer.rateBands))),
      hasCriteriaChecked: tariffRate.applicabilityKey
        ? tariffRate.applicabilityKey !== ''
          ? true
          : false
        : false,
      seasonId: tariffRateAnswer.season?.seasonId || '',
      touId: tariffRateAnswer.timeOfUse?.touId || '',
    },
  });

  const {
    register: parentRegister,
    handleSubmit,
    formState,
    watch,
    setValue,
    control,
    getValues,
    setError,
    clearErrors,
  } = useFormMethods;

  const { errors, dirtyFields } = formState;

  const register = withErrors(parentRegister, { errors, errorPrefix: `rates.${rateIndex}` });

  const specialChargeTypes = [
    types.ChargeType.CONSUMPTION_BASED,
    types.ChargeType.DEMAND_BASED,
    types.ChargeType.QUANTITY,
    types.ChargeType.NET_EXCESS_GENERATION,
  ];

  const { picker, setPicker } = usePropertyKeyPickerContext() as PropertyKeyPickerContextType;

  // Set tier limit fields based on either variableLimitKey or chargeType
  const getTierLimitKeyInputs = (variableLimitKey: string | undefined): Set<string> => {
    let formulaProperties: Set<string> = Formula.getProperties(
      propertyKeys[variableLimitKey || '']?.formulaDetail as string
    );

    if (formulaProperties.size === 0) {
      formulaProperties = new Set<string>().add(
        tierLimitChargeTypeMap.get(tariffRateAnswer.chargeType) || ''
      );
    }

    return formulaProperties;
  };

  const [tierLimitKeyInputs, setTierLimitKeyInputs] = useState(
    getTierLimitKeyInputs(tariffRateAnswer?.variableLimitKey)
  );

  const renderSeasonDropDown = () => {
    return (
      <SeasonDropdown
        label="Season"
        seasonGroups={seasonGroups}
        {...register('seasonId', {
          validate: (val: number | undefined) => !!parseInt(`${val}`) || 'Field is required',
          valueAsNumber: true,
          shouldUnregister: true,
        })}
      />
    );
  };

  const renderTouDropDown = (seasonChecked: boolean) => {
    return (
      <TimeOfUseDropdown
        label="Time Of Use"
        disableSeasonal={seasonChecked}
        touGroups={touGroups}
        {...register('touId', {
          validate: (val: number | undefined) => !!parseInt(`${val}`) || 'Field is required',
          valueAsNumber: true,
          shouldUnregister: true,
        })}
      />
    );
  };

  const handleChargeClassChange = (selected: string[]) => {
    setRateField('chargeClass', selected as types.ChargeClass[]);
    setIsDirty(true);
    setIsRateDirty(true);
  };

  const handleProrationRulesChange = (selected: string[]) => {
    const tempProration: types.ProrationRule[] = [];
    if (selected.includes('SINGLE_DEMAND_SEASON_CHANGE')) {
      tempProration.push(types.ProrationRule.SINGLE_DEMAND_SEASON_CHANGE);
    }
    if (selected.includes('SPLIT_DEMAND_VERSION_CHANGE')) {
      tempProration.push(types.ProrationRule.SPLIT_DEMAND_VERSION_CHANGE);
    }
    setRateField('prorationRules', tempProration);
    setIsDirty(true);
    setIsRateDirty(true);
  };

  const getTou = (touId: number | undefined) => {
    let selectedTou: types.TimeOfUse | undefined;
    touGroups.forEach((touGroup: types.TimeOfUseGroup) => {
      touGroup.timeOfUses?.forEach((tou: types.TimeOfUse) => {
        if (tou.touId === touId) {
          selectedTou = tou;
        }
      });
    });
    return selectedTou;
  };

  const getSeason = (seasonId: number | undefined) => {
    let selectedSeason: types.Season | undefined;
    seasonGroups.forEach((seasonGroup: types.SeasonGroup) => {
      seasonGroup.seasons?.forEach((season: types.Season) => {
        if (season.seasonId === seasonId) {
          selectedSeason = season;
        }
      });
    });
    return selectedSeason;
  };
  const onSave = (data: TariffRateFields) => {
    setNextSpin(true);
    const updatedRate: types.TariffRate = {
      ...tariffRateAnswer,
      ...pick(data, rateFields),
      season: getSeason(Number(data.seasonId)),
      timeOfUse: getTou(Number(data.touId)),
    };

    updateRateEditAnalytics(
      'rate_edit_clicked',
      taskAssignmentId,
      updatedRate.rateBands?.[0].tariffRateBandId,
      updatedRate.rateName
    );

    onSubmit(updatedRate);
    setIsRateDirty(true);
  };

  const cancelChange = () => {
    // Reset to the old state if we cancel
    resetAnswer(cloneDeep(tariffRate));
    onCancel(tariffRate.tariffRateId as number);
    setIsDirty(false);
  };

  const [
    hasEdgePredominance,
    hasSeasonChecked,
    hasTouChecked,
    hasNoneChecked,
    hasFactorChecked,
    hasVariableChecked,
    hasTieredChecked,
    hasCriteriaChecked,
    variableLimitKey,
    variableFactorKey,
    variableRateKey,
    seasonId,
    touId,
    quantityKey,
    rateBands,
  ] = watch([
    'hasEdgePredominance',
    'hasSeasonChecked',
    'hasTouChecked',
    'hasNoneChecked',
    'hasFactorChecked',
    'hasVariableChecked',
    'hasTieredChecked',
    'hasCriteriaChecked',
    'variableLimitKey',
    'variableFactorKey',
    'variableRateKey',
    'seasonId',
    'touId',
    'quantityKey',
    'rateBands',
  ]);

  useEffect(() => {
    setVariableLimitKey(variableLimitKey ? propertyKeys[variableLimitKey] : undefined);
    setTierLimitKeyInputs(getTierLimitKeyInputs(variableLimitKey));
  }, [variableLimitKey]);

  useEffect(() => {
    if (hasFactorChecked === false && !!variableFactorKey) {
      setValue('variableFactorKey', undefined, { shouldDirty: true });
    }
  }, [hasFactorChecked]);

  useEffect(() => {
    if ((hasSeasonChecked === true || hasTouChecked === true) && hasNoneChecked) {
      setValue('hasNoneChecked', false, { shouldDirty: true });
    }
  }, [hasSeasonChecked, hasTouChecked]);

  useEffect(() => {
    if (hasNoneChecked === true) {
      hasSeasonChecked && setValue('hasSeasonChecked', false, { shouldDirty: true });
      hasTouChecked && setValue('hasTouChecked', false, { shouldDirty: true });
      seasonId && setValue('seasonId', undefined, { shouldDirty: true });
      touId && setValue('touId', undefined, { shouldDirty: true });
      removeSeasonAndTou();
    }
  }, [hasNoneChecked]);

  useEffect(() => {
    if (hasSeasonChecked === false) {
      seasonId && setValue('seasonId', undefined, { shouldDirty: true });
      resetSeason();
    }
  }, [hasSeasonChecked]);

  useEffect(() => {
    if (hasTouChecked === false) {
      touId && setValue('touId', undefined, { shouldDirty: true });
      resetTou();
    }
  }, [hasTouChecked]);

  useEffect(() => {
    if (hasTieredChecked === false) {
      if ((rateBands && hasTiers(rateBands)) || !!variableLimitKey) {
        unsetTiers();
      }
    } else if (hasTieredChecked) {
      rateBands && !hasTiers(rateBands) && setTiers();
    }
  }, [hasTieredChecked]);

  useEffect(() => {
    if (hasVariableChecked === false) {
      variableRateKey && setValue('variableRateKey', undefined, { shouldDirty: true });
    } else {
      // "Credit" should not appear when "Variable" is checked
      tariffRateAnswer.rateBands?.forEach((_, index) => {
        setValue(`rateBands.${index}.isCredit`, false, { shouldDirty: true });
      });
    }
    // This was ported over but it doesn't make a lot of sense,
    // you can't check tiered if variable is checked and vice versa
    if (hasTieredChecked && hasVariableChecked) {
      unsetTiers();
      setValue('hasTieredChecked', false, { shouldDirty: true });
    }
  }, [hasVariableChecked]);

  useEffect(() => {
    if (rateBands && hasApplicability(rateBands) && hasCriteriaChecked === false) {
      unsetApplicability(hasTieredChecked);
    }
  }, [hasCriteriaChecked]);

  // To update rateBands on add and delete of rateBands
  useEffect(() => {
    if (rateBands?.length !== tariffRateAnswer.rateBands?.length) {
      setValue('rateBands', tariffRateAnswer.rateBands, { shouldDirty: true });
    }
  }, [tariffRateAnswer.rateBands]);

  const handleRemoveRateBand = (rateSequenceNumber: number | undefined) => {
    if (rateSequenceNumber) {
      deleteRateBand(rateSequenceNumber);
    }
  };

  const handleAddRateBand = (
    defaults: Partial<types.TariffRateBand>,
    rateSequenceNumber?: number
  ) => {
    if (hasTieredChecked) {
      defaults.hasConsumptionLimit = tierLimitKeyInputs.has(FORMULA_CONSUMPTION_UPPER_LIMIT);
      defaults.hasDemandLimit = tierLimitKeyInputs.has(FORMULA_DEMAND_UPPER_LIMIT);
      defaults.hasPropertyLimit = tierLimitKeyInputs.has(FORMULA_PROPERTY_UPPER_LIMIT);
    }
    addRateBand(defaults, rateSequenceNumber && rateSequenceNumber + 1, getValues('rateBands'));
  };

  const setTiers = () => {
    Object.entries(addTiers()).forEach(([key, value]) => {
      setValue(key as keyof TariffRateFields, value, { shouldDirty: true });
    });
  };

  const unsetTiers = () => {
    Object.entries(removeTiers()).forEach(([key, value]) => {
      setValue(key as keyof TariffRateFields, value, { shouldDirty: true });
    });
  };

  const setApplicability = (propertyKey: types.GenPropertyKey) => {
    Object.entries(addApplicability(propertyKey)).forEach(([key, value]) => {
      setValue(key as keyof TariffRateFields, value, { shouldDirty: true });
    });
  };

  const unsetApplicability = (preserveTiers = false) => {
    Object.entries(removeApplicability(preserveTiers)).forEach(([key, value]) => {
      setValue(key as keyof TariffRateFields, value, { shouldDirty: true });
    });
  };

  const handleRegroupBands = (rateBands: types.TariffRateBand[]) => {
    const regroupedBands = regroupBands(rateBands);
    setValue('rateBands', regroupedBands, { shouldDirty: true });
  };

  // Render form components
  const renderRateName = () => {
    return (
      <Form.Row>
        <Col className={styles.chargeTypeSection} lg="auto" md="auto" sm="auto">
          {tariffRate.chargeType && (
            <ChargeTypeIcon
              chargeType={tariffRate.chargeType}
              tooltipText={
                tariffRate.chargeType == ChargeType.DEMAND_BASED
                  ? tariffRate.quantityKey
                  : undefined
              }
            />
          )}
        </Col>
        <Col>
          <div className={styles.rateNameSection}>
            <TextInput
              type="text"
              controlId="rateName"
              label={'Rate Name'}
              placeholder={'Enter Rate Name'}
              {...register('rateName', { required: 'Field is required' })}
            />
          </div>
        </Col>
        {!hasVariableChecked && !hasTieredChecked && !hasCriteriaChecked && (
          <RateBands
            lseId={lseId}
            handleRemoveRateBand={handleRemoveRateBand}
            handleAddRateBand={handleAddRateBand}
            tierLimitKeyInputs={tierLimitKeyInputs}
            currency={currency}
            chargeType={tariffRateAnswer.chargeType}
            addApplicability={setApplicability}
            regroupBands={handleRegroupBands}
            changeApplicabilityValue={changeApplicabilityValue}
          />
        )}
      </Form.Row>
    );
  };

  const renderChargePeriod = () => {
    return (
      <Row>
        <Col>
          <Row>
            <Col>
              {[
                { label: 'Monthly', value: types.ChargePeriod.MONTHLY },
                { label: 'Daily', value: types.ChargePeriod.DAILY },
                { label: 'Hourly', value: types.ChargePeriod.HOURLY },
                { label: 'One Time', value: types.ChargePeriod.ONE_TIME },
                { label: 'Quarterly', value: types.ChargePeriod.QUARTERLY },
                { label: 'Annually', value: types.ChargePeriod.ANNUALLY },
              ].map(function (option) {
                return (
                  <RadioInput
                    id={`id-${option.value}-${tariffRateAnswer.rateName}`}
                    key={option.value}
                    value={option.value}
                    label={option.label}
                    {...register('chargePeriod', {
                      required: 'Field is required',
                    })}
                    feedback={undefined}
                    inline
                  />
                );
              })}
            </Col>
          </Row>
          <Row>
            <Col>
              {errors.chargePeriod?.message && (
                <div className={styles.formErrorFeedBack}>{errors.chargePeriod?.message}</div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const renderChargeClass = () => {
    return (
      <Row className={`${styles.autoWidth} ${styles.marginBottom}`}>
        <Col>
          <FilterChipGroup
            label="Charge Class"
            options={types.ChargeClass}
            placeholder="Select Charge Class"
            variant="outline-primary"
            onChange={(selectedChips: string[]) => handleChargeClassChange(selectedChips)}
            selected={tariffRateAnswer.chargeClass && tariffRateAnswer.chargeClass}
            size={'sm'}
          />
        </Col>
      </Row>
    );
  };

  const renderTransactionRow = () => {
    return (
      <Row>
        <Col>
          <SelectInput
            label="Transaction Type"
            controlId="transactionType"
            {...register('transactionType', { required: 'Field is required' })}
          >
            {Object.values(types.TransactionType).map(function (option) {
              return (
                <option key={option} value={option}>
                  {option}
                </option>
              );
            })}
          </SelectInput>
        </Col>
      </Row>
    );
  };

  const showTransactionRow = () => {
    return tariffRateAnswer.chargeType && specialChargeTypes.includes(tariffRateAnswer.chargeType);
  };

  const renderQuantityKeyRow = (chargeType = tariffRateAnswer.chargeType) => {
    const propertyKeyObject = quantityKey ? propertyKeys[quantityKey] : null;
    let label = '';
    const keyName = propertyKeyObject?.keyName;
    const quantityUnit = propertyKeyObject?.quantityUnit ? propertyKeyObject?.quantityUnit : '';
    let linkText = `${keyName} ${quantityUnit}`;

    let title = '';
    let propertyDataTypes: types.PropertyDataType[] = [];
    if (chargeType === types.ChargeType.CONSUMPTION_BASED) {
      label = 'Consumption Key';
      if (!keyName) {
        linkText = 'Consumption (kWh) (default)';
      }
      title = 'Consumption Property Key Picker';
      propertyDataTypes = [types.PropertyDataType.DECIMAL];
    } else if (chargeType === types.ChargeType.DEMAND_BASED) {
      label = 'Demand Key';
      if (!keyName) {
        linkText = 'None set';
      }
      title = 'Demand Property Key Picker';
      propertyDataTypes = [types.PropertyDataType.DEMAND];
    } else if (chargeType === types.ChargeType.QUANTITY) {
      label = 'Quantity Key';
      if (!keyName) {
        linkText = 'None set';
      }
      title = 'Quantity Property Key Picker';
      propertyDataTypes = [
        types.PropertyDataType.INTEGER,
        types.PropertyDataType.DEMAND,
        types.PropertyDataType.FORMULA,
        types.PropertyDataType.DECIMAL,
      ];
    } else if (chargeType === types.ChargeType.NET_EXCESS_GENERATION) {
      label = 'Net Excess Genaration Key';
      title = 'Net Excess Genaration Key Picker';
      linkText = 'netExcessGenaration';
    }

    return (
      <ControlledPropertyKeyInput
        control={control}
        name="quantityKey"
        label={label}
        emptyValue={linkText}
        clearable={true}
        renderPicker={props => {
          if (picker?.title !== title) {
            setPicker({
              title,
              propertyDataTypes,
              keySpaces: undefined, // TODO
              family: undefined, // TODO
              globalProperties: true,
            });
          }
          return <PropertyKeyPicker lseId={lseId} {...props} />;
        }}
      />
    );
  };

  const renderProrationRow = () => {
    return (
      <Row className={styles.autoWidth}>
        <Col>
          <FilterChipGroup
            label="Proration Rules"
            options={types.ProrationRule}
            placeholder="Select Proration Rules"
            variant="outline-primary"
            onChange={(selectedChips: string[]) => handleProrationRulesChange(selectedChips)}
            selected={tariffRateAnswer.prorationRules ? tariffRateAnswer.prorationRules : []}
            size={'sm'}
          />
        </Col>
      </Row>
    );
  };

  const showProrationRow = () => {
    return tariffRateAnswer.chargeType === types.ChargeType.DEMAND_BASED;
  };

  const renderTimePeriodRow = () => {
    return (
      <>
        {tariffRate.chargeType && (
          <Row className={styles.radioSection}>
            <Col className={styles.displayFlex}>
              <CheckInput
                id={'hasNoneChecked'}
                key={'hasNoneChecked'}
                disabled={false}
                label={'None'}
                {...register('hasNoneChecked')}
                inline
              />
              <CheckInput
                id={'hasSeasonChecked'}
                key={'hasSeasonChecked'}
                disabled={seasonGroups.length === 0}
                label={'Season'}
                {...register('hasSeasonChecked')}
                inline
              />
              <CheckInput
                id={'hasTouChecked'}
                key={'hasTouChecked'}
                disabled={touGroups.length === 0}
                label={'TOU'}
                {...register('hasTouChecked')}
                inline
              />
              {hasSeasonChecked && renderSeasonDropDown()}
              {hasTouChecked && renderTouDropDown(hasSeasonChecked)}
            </Col>
          </Row>
        )}
      </>
    );
  };

  const renderPredominanceRow = () => {
    return (
      <Row>
        <Col>
          <SelectInput
            label="Predominance Rule"
            controlId={'predominanceSelector'}
            placeholder="Select Predominance Rule..."
            {...register('edgePredominance', {
              validate: (val: types.PredominanceRule) =>
                (val && Object.keys(types.PredominanceRule).includes(val)) || 'Field is required',
              shouldUnregister: true,
            })}
          >
            {Object.keys(types.PredominanceRule).map((rule, i) => {
              return (
                <option key={i} value={rule}>
                  {rule}
                </option>
              );
            })}
          </SelectInput>
        </Col>
      </Row>
    );
  };

  const hasSpecialChargeType = (): boolean => {
    return (
      !!tariffRateAnswer.chargeType && specialChargeTypes.includes(tariffRateAnswer.chargeType)
    );
  };

  const renderFactorKeyRow = () => {
    const title = 'Factor Property Key Picker';
    return (
      <ControlledPropertyKeyInput
        control={control}
        name="variableFactorKey"
        label="Factor Key:"
        renderPicker={props => {
          if (picker?.title !== title) {
            setPicker({
              title,
              propertyDataTypes: [types.PropertyDataType.FORMULA],
              keySpaces: undefined, // TODO
              family: undefined, // TODO
              globalProperties: true,
            });
          }
          return <PropertyKeyPicker lseId={lseId} {...props} />;
        }}
      />
    );
  };

  const renderVariableRateKeyRow = () => {
    const title = 'Variable Index Property Key Picker';
    return (
      <Row>
        <Col>
          <ControlledPropertyKeyInput
            control={control}
            name="variableRateKey"
            label="Variable Rate Key:"
            required={true}
            clearable={false}
            onClosePicker={tariffProperty => {
              if (!tariffProperty) {
                setValue('hasVariableChecked', false);
              }
            }}
            renderPicker={props => {
              if (picker?.title !== title) {
                setPicker({
                  title,
                  propertyDataTypes: [types.PropertyDataType.LOOKUP],
                  keySpaces: undefined, // TODO
                  family: undefined, // TODO
                  globalProperties: true,
                });
              }
              return <PropertyKeyPicker lseId={lseId} {...props} />;
            }}
            errorProps={{
              errorType: 'DQFailure',
              propertyName: `rates.${rateIndex}.variableRateKey`,
            }}
          />
        </Col>
      </Row>
    );
  };

  const showRateBandList = () => {
    return (
      (rateBands && rateBands.length > 1) ||
      hasCriteriaChecked ||
      hasTieredChecked ||
      hasVariableChecked
    );
  };

  useEffect(() => {
    if (apiErrors?.length) {
      mapApiErrors<TariffRateFields>(apiErrors, setError, [...rateFields, 'seasonId']);
      setNextSpin(false);
    }
  }, [apiErrors]);

  useEffect(() => {
    dqOverrides.forEach(({ propertyName, isDirty }) => {
      if (propertyName.startsWith(`rates.${rateIndex}.`) && isDirty) {
        clearErrors(propertyName.replace(`rates.${rateIndex}.`, '') as never);
        setIsDirty(true);
        dispatch(resetDQCheckOverride(propertyName));
      }
    });
  }, [dqOverrides]);

  return (
    <FormProvider {...useFormMethods}>
      <ApiFormError apiErrors={errors as FormErrorObject} />
      <GenForm>
        <Col className={`${styles.tariffRateFomSection} ${className}`}>
          {renderRateName()}
          {renderChargePeriod()}
          {renderChargeClass()}
          <Territories lseId={lseId} />
          {showTransactionRow() && renderTransactionRow()}
          {showProrationRow() && renderProrationRow()}
          {renderTimePeriodRow()}
          {hasSpecialChargeType() && renderQuantityKeyRow()}
          <StructureRow chargeType={tariffRateAnswer.chargeType} />
          {hasEdgePredominance && renderPredominanceRow()}
          {hasFactorChecked && renderFactorKeyRow()}
          {hasVariableChecked && renderVariableRateKeyRow()}
          {showRateBandList() && (
            <RateBands
              lseId={lseId}
              handleRemoveRateBand={handleRemoveRateBand}
              handleAddRateBand={handleAddRateBand}
              tierLimitKeyInputs={tierLimitKeyInputs}
              currency={currency}
              chargeType={tariffRateAnswer.chargeType}
              addApplicability={setApplicability}
              regroupBands={handleRegroupBands}
              changeApplicabilityValue={changeApplicabilityValue}
            />
          )}
          <Row>
            <Col className={styles.buttonSection}>
              <Button
                variant="link"
                onClick={e => {
                  e.preventDefault();
                  cancelChange();
                }}
                disabled={!!apiErrors?.length}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                action={() => {
                  clearErrors(); // Need to reset errors to unlock submit process
                  handleSubmit(onSave)();
                }}
                // TODO: formsState.isDirty has issues with this form so for now
                // we are using dirtyFields instead.
                // Relevant Github issue: https://github.com/react-hook-form/react-hook-form/issues/3213

                // TODO: Remove component state isDirty by moving multi-select inputs to react-hook-form,
                // probably via controlled component.
                disabled={!isDirty && Object.keys(dirtyFields).length == 0}
                spin={nextSpin}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </GenForm>
    </FormProvider>
  );
};
export default EditTariffRateForm;
