import { restClient, restApis, types } from '@genability/api';
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GenApiClient } from '../../GenApiClient';
import { RootState } from '../rootReducer';
import { LoadingState } from '../reduxUtils';

export interface LoadServingEntity {
  code: string;
  lseId: number;
  name: string;
  websiteHome: string;
}

export interface LoadServingEntityState {
  search: string;
  lses: LoadServingEntity[];
  seasonsByLse: Record<number, Array<types.SeasonGroup>>;
  errorMessage: string | null;
  currentRequestId: string | undefined;
  loading: LoadingState;
}

const initialState: LoadServingEntityState = {
  lses: [],
  seasonsByLse: {},
  errorMessage: null,
  search: '',
  currentRequestId: undefined,
  loading: LoadingState.IDLE,
};

export const fetchLoadServingEntity = createAsyncThunk<
  restClient.PagedResponse<LoadServingEntity> | void,
  string,
  {
    state: { loadServingEntity: { loading: string; currentRequestId: string } };
  }
>(
  'loadServingEntity/fetchLoadServingEntity',
  async (
    searchTerm: string,
    { getState, requestId }
  ): Promise<restClient.PagedResponse<LoadServingEntity> | void> => {
    const { currentRequestId, loading } = getState().loadServingEntity;
    if (loading !== LoadingState.PENDING || requestId !== currentRequestId) {
      return;
    }
    const request = new restApis.GetLoadServingEntitiesRequest();
    request.search = searchTerm;

    const client = await GenApiClient();
    const response = await client.lses.getLoadServingEntities(request);
    return { ...response };
  }
);

export interface SeasonsResponse {
  lseId: number;
  seasons: Array<types.SeasonGroup>;
}

export const fetchSeasons = createAsyncThunk<
  restClient.SingleResponse<SeasonsResponse> | null,
  number,
  {
    state: { loadServingEntity: { loading: string; currentRequestId: string } };
  }
>(
  'loadServingEntity/fetchSeasons',
  async (
    lseId: number,
    { getState, requestId }
  ): Promise<restClient.SingleResponse<SeasonsResponse> | null> => {
    const { currentRequestId, loading } = getState().loadServingEntity;
    if (loading !== LoadingState.PENDING || requestId !== currentRequestId) {
      return null;
    }

    const client = await GenApiClient();
    const seasonsParams = new restApis.GetSeasonGroupsRequest();
    seasonsParams.lseId = lseId;
    const response: restClient.PagedResponse<types.SeasonGroup> =
      await client.seasons.getSeasonGroups(seasonsParams);
    return { ...response, result: { lseId, seasons: response.results }, results: [] };
  }
);

const buildFetchLseCases = (builder: ActionReducerMapBuilder<LoadServingEntityState>) => {
  builder
    .addCase(fetchLoadServingEntity.pending, (state, action) => {
      state.loading = LoadingState.PENDING;
      state.currentRequestId = action.meta.requestId;
      state.search = action.meta.arg;
      state.errorMessage = null;
    })
    .addCase(fetchLoadServingEntity.fulfilled, (state, action) => {
      const { requestId } = action.meta;
      if (state.currentRequestId !== requestId) {
        return; // ignore stale
      }
      state.loading = LoadingState.IDLE;
      if (action.payload) {
        state.lses = action.payload.results;
      }
      state.currentRequestId = undefined;
      state.errorMessage = null;
    })
    .addCase(fetchLoadServingEntity.rejected, (state, action) => {
      const { requestId } = action.meta;
      if (state.currentRequestId !== requestId) {
        return; // ignore stale
      }
      state.loading = LoadingState.IDLE;
      state.lses = [];
      state.currentRequestId = undefined;
      state.errorMessage = action.error?.message || String(action.error);
    });
};

const buildSeasonCases = (builder: ActionReducerMapBuilder<LoadServingEntityState>) => {
  builder
    .addCase(fetchSeasons.pending, (state, action) => {
      state.loading = LoadingState.PENDING;
      state.currentRequestId = action.meta.requestId;
      state.errorMessage = null;
    })
    .addCase(fetchSeasons.fulfilled, (state, action) => {
      const { requestId } = action.meta;
      const result = action.payload?.result;
      if (state.currentRequestId !== requestId) {
        return; // ignore stale
      }
      state.loading = LoadingState.IDLE;
      if (result) {
        state.seasonsByLse[result.lseId] = result.seasons;
        console.log(`added new state: ${JSON.stringify(state.seasonsByLse[result.lseId])}`);
      }
      state.currentRequestId = undefined;
      state.errorMessage = null;
    })
    .addCase(fetchSeasons.rejected, (state, action) => {
      const { requestId } = action.meta;
      if (state.currentRequestId !== requestId) {
        return; // ignore stale
      }
      state.loading = LoadingState.IDLE;
      state.lses = [];
      state.currentRequestId = undefined;
      state.errorMessage = action.error?.message || String(action.error);
    });
};

export const loadServingEntitySlice = createSlice({
  name: 'loadServingEntity',
  initialState,
  reducers: {},
  extraReducers: builder => {
    buildFetchLseCases(builder);
    buildSeasonCases(builder);
  },
});

export default loadServingEntitySlice.reducer;

export const selectSeasons = (lseId: number) => {
  return (state: RootState): Array<types.SeasonGroup> => {
    return Array.isArray(state.loadServingEntity.seasonsByLse[lseId])
      ? state.loadServingEntity.seasonsByLse[lseId]
      : [];
  };
};

export const selectLoadServingEntities = (state: RootState): LoadServingEntityState => {
  return state.loadServingEntity;
};
