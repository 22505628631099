import { WorkflowConfigApiState } from '../../utils/taskV2Utils';
import { TaskApiV2Client } from '../../GenApiClient';
import { handleUnexpectedThunkException } from '../reduxUtils';
import { RootState } from '../rootReducer';

import { restClient } from '@genability/api';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { WorkFlowConfigResponse } from '../../task-api/v2/types/WorkflowConfigResponse';
import { WorkflowStateResponse } from '../../task-api/v2/types/WorkflowStateResponse';

const initialState: WorkflowConfigApiState = {
  wfConfigApiStatus: 'idle',
  data: null,
  existingConfigDetails: {},
  errors: undefined,
  wfStatesApiStatus: 'idle',
  wfStatesCount: 0,
  wfStatesData: null,
  wfStateErrors: undefined,
};

export const fetchWorkFlowConfig = createAsyncThunk(
  '/workflow/configId',
  async (configId: string, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiV2Client();
      const response: restClient.PagedResponse<WorkFlowConfigResponse> =
        await client.taskV2.getWorkflowConfig(configId);
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      return response;
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'WorkflowConfig', dispatch));
    }
  }
);
export const fetchWorkFlowStates = createAsyncThunk(
  '/workflow/workflosState',
  async (workflowId: number, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiV2Client();
      const response: restClient.PagedResponse<WorkflowStateResponse> =
        await client.taskV2.getWorkFlowStatesByWorkflowId(workflowId);
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      return response;
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'WorkflowConfig', dispatch));
    }
  }
);

export const workFlowConfigSlice = createSlice({
  name: 'workFlowConfigSlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchWorkFlowConfig.pending, state => {
      state.wfConfigApiStatus = 'pending';
    });
    builder.addCase(
      fetchWorkFlowConfig.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<WorkFlowConfigResponse>>) => {
        const { results, errors } = action.payload;
        if (errors) {
          state.wfConfigApiStatus = 'rejected';
          state.errors = errors;
        } else {
          state.wfConfigApiStatus = 'resolved';
          state.data = results?.[0];
          state.existingConfigDetails[results?.[0]?.name] = results?.[0];
          state.errors = undefined;
        }
      }
    );
    builder.addCase(fetchWorkFlowStates.pending, state => {
      state.wfStatesApiStatus = 'pending';
    });
    builder.addCase(
      fetchWorkFlowStates.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<WorkflowStateResponse>>) => {
        const { count, results, errors } = action.payload;
        if (errors) {
          state.wfStatesApiStatus = 'rejected';
          state.wfStateErrors = errors;
        } else {
          state.wfStatesCount = count;
          state.wfStatesApiStatus = 'resolved';
          state.wfStatesData = results;
          state.wfStateErrors = undefined;
        }
      }
    );
  },
});

export const selectedWorkFlowConfigDetails = (state: RootState): WorkflowConfigApiState => {
  return state.workFlowConfig;
};

export default workFlowConfigSlice.reducer;
