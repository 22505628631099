import React, { FC } from 'react';
import { Button, Checkbox, Icon, Input, Link, Select, Text, Tooltip } from '@arcadiapower/shrike';
import { DETAIL_LEVELS, GROUP_BYS } from '../TariffCalculator.constants';
import { Col, Row } from 'react-bootstrap';
import { DateTime } from 'luxon';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export interface TariffFormControlsState {
  startDate?: DateTime;
  endDate?: DateTime;
  detailLevel?: string;
  groupBy?: string;
  isBillingPeriod: boolean;
}

interface TariffFormControlsProps {
  formState: TariffFormControlsState;
  setFormState: (value: Partial<TariffFormControlsState>) => void;
  calculationRangeInvalid?: boolean;
  resetForm: () => void;
  runCalculation: () => void;
  calculateDisabled: boolean;
}

const TariffFormControls: FC<TariffFormControlsProps> = props => {
  const {
    formState: { startDate, endDate, detailLevel, groupBy, isBillingPeriod },
    setFormState,
    calculationRangeInvalid,
    resetForm,
    runCalculation,
    calculateDisabled,
  } = props;

  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setFormState({
      startDate: start ? DateTime.fromJSDate(start) : undefined,
      endDate: end ? DateTime.fromJSDate(end) : undefined,
    });
  };

  return (
    <>
      <Row className="mb-3">
        <Col className="col-lg-4 col-12">
          <DatePicker
            selected={startDate?.toJSDate()}
            onChange={onChange}
            startDate={startDate?.toJSDate()}
            endDate={endDate?.toJSDate()}
            selectsRange
            isClearable
            monthsShown={2}
            customInput={
              <Input
                label="Calculation Range"
                name="calculation-range"
                onChange={() => undefined}
                errorText={calculationRangeInvalid ? ' ' : undefined}
              />
            }
            calendarClassName="d-flex"
          />
          <div className="my-3 d-flex">
            <Checkbox
              checked={isBillingPeriod}
              onChange={event => setFormState({ isBillingPeriod: event.target.checked })}
            >
              This is a Billing Period
            </Checkbox>
            <Tooltip
              label="About Billing Periods"
              tooltipId="billing-period-tooltip"
              content={`Calculations spanning a billing period have different amortization rules
                        than calculations spanning multiple billing periods or intra-billing
                        period. See Genability docs for details. Check this box in your date range
                        corresponds to a billing cycle.`}
            >
              <Icon color="primary" icon="InfoCircleInverted" className="mx-2 mb-1" />
            </Tooltip>
          </div>
        </Col>
        <Col className="col-lg-3 col-6">
          <Select
            label="Detail Level"
            name="detailLevel"
            options={DETAIL_LEVELS}
            value={detailLevel}
            onChange={detailLevel => setFormState({ detailLevel })}
            withEmptyPlaceholder
          />
        </Col>
        <Col className="col-lg-2 col-6">
          <Select
            label="Group By"
            name="groupBy"
            options={GROUP_BYS}
            value={groupBy}
            onChange={groupBy => setFormState({ groupBy })}
            errorText={calculationRangeInvalid ? ' ' : undefined}
            withEmptyPlaceholder
          />
        </Col>
        <Col className="col-lg-1 col-12 pt-5 mt-n2">
          <Link
            type="standalone"
            hideIcon
            color="secondary"
            onClick={(event: React.MouseEvent) => {
              event.preventDefault();
              resetForm();
            }}
          >
            Reset
          </Link>
        </Col>
        <Col className="col-lg-2 col-12 pt-4 d-flex flex-column">
          <Button onClick={runCalculation} color="error" disabled={calculateDisabled}>
            Calculate
          </Button>
          {calculateDisabled && calculationRangeInvalid && (
            <Text textStyle="paragraph400" color="error" className="mt-2">
              A calculation cannot be performed with this date range and grouping. Please try a
              smaller date range or a different grouping.
            </Text>
          )}
        </Col>
      </Row>
    </>
  );
};

export default TariffFormControls;
