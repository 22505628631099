import { PlatformTable } from '@arcadiapower/gen-react-lib';
import React, { FC, useEffect, useState } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useUtilityFilterContext } from '../../../context/utilityFilterContext';
import { selectMemoizedUtilities } from '../../../state/utilities/utilitiesSlice';
import { ColumnHelper, createColumnHelper } from '@tanstack/react-table';
import { MappedLoadServingEntity, UtilitiesHeaderDef } from './Utilities.types';
import { LoadingState } from '../../../state/reduxUtils';
import { numericFormatter } from 'react-number-format';

const UtilitiesList: FC = () => {
  const [currentMeta, setCurrentMeta] = useState({
    currentPage: 1,
    itemsPerPage: 25,
  });
  const { filter, onFilterChange } = useUtilityFilterContext();
  const dispatch = useDispatch();
  const { results: utilities, loading, errors } = useSelector(selectMemoizedUtilities);
  const [mappedUtilities, setMappedUtilities] = useState<MappedLoadServingEntity[]>([]);

  useEffect(() => {
    onFilterChange(dispatch, filter);
  }, [filter]);

  useEffect(() => {
    onFilterChange(dispatch, {
      ...filter,
      pageCount: currentMeta.itemsPerPage,
      pageStart: currentMeta.currentPage,
    });
  }, [currentMeta]);

  useEffect(() => {
    if (utilities?.length) {
      const formatProps = {
        thousandSeparator: true,
        decimalSeparator: ',',
        prefix: '$',
      };
      const mapped: MappedLoadServingEntity[] = utilities.map(utility => ({
        ...utility,
        totalRevenues: numericFormatter(`${utility.totalRevenues}`, formatProps),
        totalCustomers: numericFormatter(`${utility.totalRevenues}`, {
          ...formatProps,
          prefix: '',
        }),
        totalSales: numericFormatter(`${utility.totalRevenues}`, formatProps),
      }));
      setMappedUtilities(mapped);
    }
  }, [utilities?.length]);

  const utilitiesColumns = () => {
    const columnsHelper: ColumnHelper<MappedLoadServingEntity> =
      createColumnHelper<MappedLoadServingEntity>();

    const columns: UtilitiesHeaderDef = [
      columnsHelper.accessor('name', { id: 'Utility', sortDescFirst: true, enableSorting: true }),
      columnsHelper.accessor('lseId', { id: 'ID' }),
      columnsHelper.accessor('code', { id: 'Short Name' }),
      columnsHelper.accessor('ownership', { id: 'Ownership' }),
      columnsHelper.accessor('totalRevenues', { id: 'Revenues' }),
      columnsHelper.accessor('totalCustomers', { id: 'Customers' }),
      columnsHelper.accessor('totalSales', { id: 'Sales' }),
    ];

    return columns;
  };

  const mappedErrors = () => {
    return errors?.map(error => (
      <div key={error.code} className="alert alert-danger" data-testid={error.code}>
        Error: {error.message} <br />
        property: {error.propertyName}
      </div>
    ));
  };
  return (
    <Container fluid className="p-4">
      <Row className="border" />
      <Row className="my-2">
        <Col xs={6} sm={8} md={10} className="d-flex align-items-end">
          <h4 className="font-weight-bold">Utilities</h4>
        </Col>
      </Row>
      {errors?.length ? <Row>{mappedErrors()}</Row> : null}
      <Row className="border-top">
        {mappedUtilities?.length ? (
          <PlatformTable
            columns={utilitiesColumns()}
            data={mappedUtilities}
            loading={loading === LoadingState.PENDING}
          />
        ) : null}
      </Row>
    </Container>
  );
};

export default UtilitiesList;
