import React, { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import appConstants from '../app-constants';
import styles from './SideMenu.module.scss';

interface SideMenuProps {
  isOpen: boolean;
  closeSideMenu: () => void;
}

const SideMenu: React.FC<SideMenuProps> = ({ isOpen, closeSideMenu }) => {
  if (!isOpen) {
    return null;
  }
  const location = useLocation();

  const pages = [
    {
      key: 'draft',
      display: 'Draft',
      route: appConstants.routes.draft,
      disabled: false,
    },
    {
      key: 'do',
      display: 'Do',
      route: appConstants.routes.do,
      disabled: false,
    },
    {
      key: 'review',
      display: 'Review',
      route: appConstants.routes.review,
      disabled: false,
    },
    {
      key: 'completed',
      display: 'Completed',
      route: appConstants.routes.completed,
      disabled: false,
    },
  ];

  return (
    <div className={`${styles.sideMenu}  bg-dark`}>
      <ul className={styles.menuList}>
        {pages.map(page => (
          <li
            key={page.key}
            className={` ${
              location.pathname === page.route ? styles.activeMenuItem : styles.menuItem
            }`}
            onClick={() => closeSideMenu()}
          >
            <Link to={page.route}>{page.display}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideMenu;
