import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { restClient, types, restApis } from '@genability/api';
import { RootState } from '../rootReducer';
import { GenApiClient } from '../../GenApiClient';
import { LoadingState } from '../reduxUtils';

export type UtilityMeta = {
  count: number;
  pageCount: number;
  page: number;
};
export interface IUtilitiesState {
  results: types.LoadServingEntity[] | null;
  errors: restClient.ResponseError[] | null;
  currentRequestId: string | undefined;
  loading: LoadingState;
  meta: UtilityMeta;
}
export const initialState: IUtilitiesState = {
  results: [],
  errors: [],
  currentRequestId: undefined,
  loading: LoadingState.IDLE,
  meta: {
    count: 0,
    page: 0,
    pageCount: 25,
  },
};

const createUtilitiesRequest = (
  params: Partial<restApis.GetLoadServingEntitiesRequest>
): restApis.GetLoadServingEntitiesRequest => {
  const utilitiesRequest: restApis.GetLoadServingEntitiesRequest =
    new restApis.GetLoadServingEntitiesRequest();
  Object.keys(params).forEach((k: string) => {
    const prop = k as keyof restApis.GetLoadServingEntitiesRequest;
    utilitiesRequest[prop] = params[prop] as never;
  });
  utilitiesRequest.fields = restClient.Fields.EXTENDED;
  return utilitiesRequest;
};

export const getUtilities = createAsyncThunk<
  restClient.PagedResponse<types.LoadServingEntity> | void,
  Partial<restApis.GetLoadServingEntitiesRequest>,
  {
    state: { utilities: IUtilitiesState };
    rejectValue: restClient.ResponseError[];
  }
>(
  'utilitiesSlice/getUtilities',
  async (
    params: Partial<restApis.GetLoadServingEntitiesRequest>,
    { getState, requestId, rejectWithValue }
  ) => {
    const { currentRequestId, loading } = getState().utilities;
    if (loading !== LoadingState.PENDING || requestId !== currentRequestId) {
      return;
    }

    const utilitiesRequest = createUtilitiesRequest(params);

    const client = await GenApiClient();
    const response = await client.lses.getLoadServingEntities(utilitiesRequest);
    if (response?.errors?.length) {
      return rejectWithValue(response.errors);
    }
    return { ...response };
  }
);

export const utilitiesSlice = createSlice({
  name: 'utilities',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUtilities.pending, (state, action) => {
        state.loading = LoadingState.PENDING;
        state.currentRequestId = action.meta.requestId;
        state.errors = [];
      })
      .addCase(getUtilities.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.currentRequestId !== requestId) {
          return; // ignore stale
        }
        state.loading = LoadingState.SUCCEEDED;
        if (action.payload) {
          state.results = action.payload?.results;
        }
        state.currentRequestId = undefined;
        state.errors = [];
      })
      .addCase(getUtilities.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.currentRequestId != requestId) {
          return; // ignore state
        }
        state.loading = LoadingState.FAILED;
        state.results = [];
        state.currentRequestId = undefined;
        state.errors = action.payload || [];
      });
  },
});

export default utilitiesSlice.reducer;

// Memoized selector
export const selectMemoizedUtilities = createSelector(
  [(state: RootState) => state.utilities],
  utilities => {
    return utilities;
  }
);
