import React, { useEffect } from 'react';
import { Table, Th, Tr } from '@arcadiapower/gen-react-lib';
import { Row, Col } from 'react-bootstrap';
import { Loading, Text } from '@arcadiapower/shrike';

import { LOOKUPS, selectedTaskFilter } from '../../state/taskFilter/taskFilterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { TaskStatsRequest } from '../../task-api/v2/api/task-api-v2';
import { CurrentUser, selectCurrentUser } from '../../state/currentUser/currentUserSlice';
import { fetchTaskStats, selectTaskStats } from '../../state/taskStats/taskStatsSlice';

const Stats = () => {
  const dispatch = useDispatch();
  const { statsTable, activeTaskType, activeFilter } = useSelector(selectedTaskFilter);
  const { apiStatus, results } = useSelector(selectTaskStats);
  const currentUser: CurrentUser = useSelector(selectCurrentUser);
  const tableConfig = statsTable?.[activeFilter]?.tableConfig;
  const countInfo = results?.[0];

  useEffect(() => {
    if (!currentUser || currentUser?.username === '') {
      return;
    }
    const request = new TaskStatsRequest();
    request.dashboardMenu = activeFilter;
    request.taskTypeMenu = activeTaskType;
    request.assignee = currentUser.username;
    dispatch(fetchTaskStats(request));
  }, [dispatch, activeFilter, activeTaskType, currentUser?.username]);

  return (
    <Row>
      {apiStatus === 'idle' || apiStatus === 'pending' ? (
        <Loading backgroundColor="primary" className="mt-5" />
      ) : (
        <>
          <Col xs={4} md={3} lg={2} className="mx-3 mt-1">
            <Text opacity="medium" tag="h2" textStyle="paragraph600">
              {statsTable[activeFilter]?.title}
            </Text>
          </Col>
          <Col xs={6} md={7} lg={8} className="m-2">
            {Object.keys(tableConfig).length === 0 ? (
              <div className="no-results">No results</div>
            ) : (
              <Table bordered hover>
                <thead>
                  <tr>
                    {Object.keys(tableConfig).map(item => (
                      <Th className="text-center" key={item}>
                        {item}
                      </Th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Object.values(tableConfig).every(item => countInfo[item] === null) ? (
                    <Tr className="text-center">
                      <td colSpan={Object.keys(tableConfig).length}>No results found.</td>
                    </Tr>
                  ) : (
                    <Tr className="text-center">
                      {Object.values(tableConfig).map(item => (
                        <td className="text-center" key={item}>
                          {countInfo[item] ? countInfo[item] : 0}
                        </td>
                      ))}
                    </Tr>
                  )}
                </tbody>
              </Table>
            )}
          </Col>
        </>
      )}
    </Row>
  );
};

export default Stats;
