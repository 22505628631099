import { types } from '@genability/api';
import { IPropertySearchOption } from '@arcadiapower/gen-react-lib';
import { createContext, useContext } from 'react';
import { fetchPropertyKeys } from '../state/propertyKeys/propertyKeysSlice';
import { AppDispatch } from '../state/store';

export type PropertyKeyPickerContext =
  | `eligibility-${types.TariffPropertyType}`
  | `rate-quantity-key-${types.ChargeType}`
  | 'rate-factor-key'
  | 'rate-variable-rate-key'
  | 'rate-variable-limit-key'
  | 'rate-criteria-key';
export interface IPropertyPicker {
  title: string | undefined;
  propertyDataTypes: types.PropertyDataType[] | undefined;
  keySpaces: string[] | undefined;
  family: string[] | undefined;
  globalProperties: boolean | undefined;
  saved?: boolean;
}
export type PropertyKeyPickerContextType = {
  picker: IPropertyPicker;
  setPicker: (picker: IPropertyPicker) => void;
  onPropertySearch?: (dispatch: AppDispatch, options: IPropertySearchOption) => void;
};

export const initialPropertyKeyPickerContext: IPropertyPicker = {
  title: undefined,
  propertyDataTypes: undefined,
  keySpaces: undefined,
  family: undefined,
  globalProperties: true,
};

export const onPropertySearchHandler = (
  dispatch: AppDispatch,
  options: IPropertySearchOption
): void => {
  dispatch(fetchPropertyKeys(options));
};

export const PropertyKeyPickerContext = createContext<PropertyKeyPickerContextType | null>(null);

export const usePropertyKeyPickerContext = (): PropertyKeyPickerContextType | null =>
  useContext(PropertyKeyPickerContext);
