import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { restClient } from '@genability/api';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { TaskApiV2Client } from '../../GenApiClient';
import { addNotification } from '../notification/notificationSlice';
import { RootState } from '../rootReducer';
import { AppThunk } from '../store';
import { Task } from '../../task-api/v2/types/TaskV2';
import { CurrentWorkflowState } from '../../task-api/v2/types/CurrentWorkflowState';
import { TaskWorkflowEntity } from '../../task-api/v2/types/TaskWorkflowEntity';

export interface WorkflowState {
  tasks: Task[] | null;
  currentWorkflowState: CurrentWorkflowState | null;
  isLoading: boolean;
  error: string | null;
}

interface fetchWorkflowSuccessState {
  tasks: Task[] | null;
  currentWorkflowState: CurrentWorkflowState | null;
}

export const initialState: WorkflowState = {
  tasks: null,
  currentWorkflowState: null,
  isLoading: false,
  error: '',
};

function fetchWorkflowStartLoading(state: WorkflowState) {
  state.isLoading = true;
}

function fetchWorkflowLoadingFailed(state: WorkflowState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

export const task = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    fetchWorkflowStart: fetchWorkflowStartLoading,
    fetchWorkflowSuccess(state, { payload }: PayloadAction<fetchWorkflowSuccessState>) {
      const { tasks, currentWorkflowState } = payload;
      state.isLoading = false;
      state.error = null;
      state.tasks = tasks;
      state.currentWorkflowState = currentWorkflowState;
    },
    fetchWorkflowFailure: fetchWorkflowLoadingFailed,
  },
});

export const { fetchWorkflowStart, fetchWorkflowFailure, fetchWorkflowSuccess } = task.actions;

export default task.reducer;

export const fetchWorkflowByWorkflowId =
  (workflowId: number | null): AppThunk =>
  async dispatch => {
    if (workflowId == null) {
      throw new Error('Invalid input:  workflowId null');
    }
    try {
      dispatch(fetchWorkflowStart());
      const client = await TaskApiV2Client();
      const { results, errors }: restClient.SingleResponse<TaskWorkflowEntity> =
        await client.taskV2.getWorkFlowByWorkflowId(workflowId);
      if (errors) {
        throw new Error(errors[0].message);
      }
      const tasks = results[0] ? results[0].tasks : null;
      const currentWorkflowState = results[0] ? results[0].currentWorkflowState : null;
      dispatch(fetchWorkflowSuccess({ tasks, currentWorkflowState }));
    } catch (err) {
      if (err) {
        const errorMessage = (err instanceof Error && err.message) || String(err);
        dispatch(fetchWorkflowFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };

export const selectWorkflow = (state: RootState): WorkflowState => {
  return state.workflow;
};
