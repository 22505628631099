import React, { FC } from 'react';
import styles from './TariffItemTypeFilter.module.scss';

export const TariffItemTypes: Record<string, string> = {
  ALL: 'All',
  TARIFF_REVISIONS: 'Tariff Revisions',
  RIDER_REVISIONS: 'Rider Revisions',
  LOOKUPS: 'Lookups',
  SEASONS: 'Seasons',
};

export interface TariffItemTypeFilterProps {
  selectedFilters: Set<string>;
  onToggleFilter: (tariffType: string) => void;
}

const TariffItemTypeFilter: FC<TariffItemTypeFilterProps> = ({
  selectedFilters,
  onToggleFilter,
}) => {
  const isTariffTypeSelected = (tariffType: string) => {
    if (tariffType === TariffItemTypes.ALL && selectedFilters.size === 0) {
      return true;
    }
    return selectedFilters.has(tariffType);
  };
  return (
    <div className="btn-group btn-group-toggle" data-toggle="buttons">
      {Object.keys(TariffItemTypes).map(tariffTypeKey => (
        <a
          key={`${tariffTypeKey}`}
          href={`#${tariffTypeKey}`}
          className={`mr-4 ${styles.viewOption} ${
            !isTariffTypeSelected(TariffItemTypes[tariffTypeKey]) ? 'badge-light' : ''
          }`}
          onClick={() => onToggleFilter(TariffItemTypes[tariffTypeKey])}
        >
          {TariffItemTypes[tariffTypeKey]}
        </a>
      ))}
    </div>
  );
};

export default TariffItemTypeFilter;
