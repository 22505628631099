import { Checkbox } from '@arcadiapower/shrike';
import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { ITariffFilter } from '../../context/tariffFilterContext';
import { TariffType } from '@genability/api/dist/types';

export interface TariffTypeProps {
  onFilterChange: (update: Partial<ITariffFilter>) => void;
}
export interface TariffTypeFilters {
  [key: string]: boolean;
}
export const toCamelcase = (val: string) => val[0].toUpperCase() + val.slice(1).toLowerCase();
const TariffTypeFilter = (props: TariffTypeProps) => {
  const [selectedTariffTypes, setSelectedTariffTypes] = useState<TariffTypeFilters>({
    [TariffType.DEFAULT]: true,
    [TariffType.ALTERNATIVE]: true,
  });

  const handleChange = (tariffType: TariffType) => {
    setSelectedTariffTypes(prev => {
      return { ...prev, [tariffType]: !prev[tariffType] };
    });
  };

  useEffect(() => {
    props.onFilterChange({
      tariffTypes: Object.keys(selectedTariffTypes).filter(
        tariffType => selectedTariffTypes[tariffType]
      ) as TariffType[],
    });
  }, [selectedTariffTypes]);

  return (
    <>
      {Object.keys(TariffType).map((tariffType, index) => (
        <Row key={index}>
          <Checkbox
            onChange={() => handleChange(tariffType as TariffType)}
            checked={selectedTariffTypes[tariffType as TariffType]}
            className="ml-3"
          >
            {toCamelcase(tariffType)}
          </Checkbox>
        </Row>
      ))}
    </>
  );
};

export default TariffTypeFilter;
