import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAppDispatch } from '../../../state/store';
import { restClient, types } from '@genability/api';
import PanelNavigationButtons from '../PanelNavigationButtons/PanelNavigationButtons';
import { selectServiceTerritories } from '../../../state/territories/territoriesSlice';
import {
  ConfirmModal,
  Form,
  TextInput,
  SelectInput,
  CheckInput,
  NotificationLevel,
} from '@arcadiapower/gen-react-lib';
import {
  selectTaskAssignmentTariff,
  updateTaskAssignmentTariff,
} from '../../../state/taskAssignmentTariffs/taskAssignmentTariffsSlice';
import { DoStepPanelProps } from '../work-body';
import useTariffAnswer from '../../../utils/useTariffAnswer';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { mapApiErrors, withErrors } from '../../../utils/formUtils';
import { fetchPropertyKey, selectPropertyKey } from '../../../state/propertyKeys/propertyKeysSlice';
import { TaskTypeId } from '../../../task-api/types/task';
import ApiFormError, { FormErrorObject } from '../../ApiFormError/ApiFormError';
import {
  fetchDQCheck,
  selectDQCheckErrors,
  selectDQCheckOverrides,
  isDQCheckOverride,
  resetDQCheckOverride,
} from '../../../state/dqCheck/dqCheckSlice';
import { isErrorMapToField } from '../../../utils/errorUtils';
import { displayLinks } from '../../../utils/diffUtils';
import styles from './EditTariffHeaderPanel.module.scss';
import DQContextMenu from '../../DQOverride/DQContextMenu';
import { TaskTariff } from '../../../task-api/types/task';
import QuickSearch from '../../QuickSearchComponent/QuickSearch';
import { doQuickSearch } from '../../../state/QuickSearch/QuickSearchSlice';
import { TaskApiV2Client } from '../../../GenApiClient';
import { addNotification } from '../../../state/notification/notificationSlice';
import { Tariff } from '../../../task-api/v2/types/TariffDetails';

export const headerProperties = [
  'tariffName',
  'tariffBookName',
  'tariffCode',
  'tariffType',
  'customerClass',
  'timeZone',
  'currency',
  'territoryId',
  'effectiveDate',
  'endDate',
  'closedDate',
  'customerCount',
  'customerCountSource',
  'author.orgId',
  'author.name',
] as (keyof TaskTariff)[];
const EditTariffHeaderPanel: React.FC<DoStepPanelProps> = ({
  taskAssignment,
  onPrevious,
  onNext,
}: DoStepPanelProps) => {
  const serviceTerritories: Array<types.Territory> = useSelector(
    selectServiceTerritories(taskAssignment?.task?.lseId)
  );
  const dqCheckErrors = useSelector(selectDQCheckErrors);
  const dqOverrides = useSelector(selectDQCheckOverrides);
  const tariff: TaskTariff | undefined = useSelector(selectTaskAssignmentTariff);

  useEffect(() => {
    dispatch(fetchDQCheck(taskAssignment.taskAssignmentId));
  }, []);

  if (!tariff) {
    return null;
  }

  const { tariffAnswer, getTariffFields, setTariffFieldsFromForm, tariffFields } = useTariffAnswer(
    tariff,
    headerProperties
  );

  // TODO: Find a way to fix the return type of useTariffAnswer so we can guarantee that
  // if the second function parameter is supplied, tariffFields is guaranteed to be defined
  if (!tariffFields) {
    return null;
  }

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchPropertyKey('timeZone'));
    dispatch(fetchPropertyKey('currency'));
  }, [tariff]);

  const timeZoneProperty: types.GenPropertyKey | null = useSelector(selectPropertyKey('timeZone'));

  const currencyProperty: types.GenPropertyKey | null = useSelector(selectPropertyKey('currency'));

  interface HeaderFields extends Pick<TaskTariff, (typeof tariffFields)[number]> {
    hasBookName: boolean;
    hasClosedDate: boolean;
    confirmCreateHistoricTariffVersion: boolean;
    dqOverridesSaved: boolean;
  }

  const {
    register: useFormRegister,
    handleSubmit,
    trigger,
    formState,
    setError,
    watch,
    reset,
    resetField,
    setValue,
    getValues,
    clearErrors,
  } = useForm<HeaderFields>({
    mode: 'onChange',

    defaultValues: useMemo(() => {
      return {
        ...getTariffFields(),
        customerCountSource: tariffAnswer.customerCountSource || 'Genability Estimate',
        hasClosedDate: !!(tariffAnswer && tariffAnswer.closedDate),
        hasBookName:
          tariffAnswer &&
          tariffAnswer.tariffBookName != undefined &&
          tariffAnswer.tariffName != tariffAnswer.tariffBookName,
        confirmCreateHistoricTariffVersion: false,
        dqOverridesSaved: false,
      };
    }, [tariffAnswer]),
  });
  const { isDirty, errors } = formState;

  const register = withErrors(useFormRegister, { errors });

  const [nextSpin, setNextSpin] = useState(false);
  const [isRider, setIsRider] = useState(tariffAnswer?.tariffType === types.TariffType.RIDER);
  const [noChangesChecked, setNoChangesChecked] = useState(false);
  const [isEffectiveDateBefore, setEffectiveDateBefore] = useState(false);
  const [showOrgIdSearch, setShowOrgIdSearch] = useState(true);

  // TODO should onPrevious reset changes, save changes, or no-op? (currently no-op)

  const handleNoChanges = async (checked: boolean) => {
    if (checked) {
      const results = await trigger(); // validate form
      if (results) {
        setNoChangesChecked(checked);
      }
    } else {
      setNoChangesChecked(checked);
    }
  };

  const task03_04 = taskAssignment?.taskTypeId === TaskTypeId['03_04'];

  const saveAndNext = async (data: HeaderFields) => {
    const updatedTariff = setTariffFieldsFromForm(data);
    const [id, orgName] = combinedValue?.split('==') || [];
    if (combinedValue?.length > 8) {
      updatedTariff.author = {
        orgId: id,
        name: orgName,
        refId: null,
        refType: null,
      };
    } else {
      updatedTariff.author = {
        orgId: null,
        name: null,
        refId: null,
        refType: null,
      };
    }

    if (taskAssignment) {
      // temp until this isn't undefined
      setNextSpin(true);

      const { result } = await dispatch(
        updateTaskAssignmentTariff({
          taskAssignmentId: taskAssignment.taskAssignmentId,
          tariff: updatedTariff,
          errorLinks: displayLinks(updatedTariff),
        })
      ).unwrap();

      if (result) {
        const dqCheck = await dispatch(fetchDQCheck(taskAssignment.taskAssignmentId)).unwrap();
        const isErrorsInForm = dqCheck.errors?.some(
          error => isErrorMapToField(getValues(), error) && !isDQCheckOverride(error, dqOverrides)
        );
        if (!isErrorsInForm) {
          onNext();
        }
      }

      setNextSpin(false);
    }
  };

  const [combinedValue, setCombinedValue] = useState('');

  const hasClosedDate = watch('hasClosedDate');
  const hasBookName = watch('hasBookName');
  const tariffType = watch('tariffType');
  const authorOrgId = watch('author.orgId');
  const authorName = watch('author.name');

  useEffect(() => {
    if (authorOrgId && authorName) {
      setCombinedValue(`${authorOrgId} == ${authorName}`);
    }
  }, [authorName]);

  useEffect(() => {
    if (!hasBookName) {
      setValue('tariffBookName', undefined);
    }
  }, [hasBookName]);

  useEffect(() => {
    if (!hasClosedDate) {
      setValue('closedDate', null);
    }
  }, [hasClosedDate]);

  useEffect(() => {
    const fetchTariff = async () => {
      const oneYearAgo = new Date();
      oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

      const client = await TaskApiV2Client();
      const { result, errors }: restClient.SingleResponse<Tariff> =
        await client.taskV2.getTariffByTariffId(tariff.masterTariffId);
      if (result && result.publishedDate) {
        if (tariff.privacy === 'PUBLIC' && new Date(result.publishedDate) <= oneYearAgo)
          setShowOrgIdSearch(false);
      }
      if (errors) {
        dispatch(addNotification(errors, NotificationLevel.Error));
      }
    };

    fetchTariff();
  }, [tariff.masterTariffId]);

  useEffect(() => {
    setIsRider(tariffType === 'RIDER');
  }, [tariffType]);

  useEffect(() => {
    dqCheckErrors.forEach(error => {
      const fieldName = error.propertyName;
      if (isDQCheckOverride(error, dqOverrides)) {
        clearErrors(fieldName as keyof HeaderFields);
      }
    });
    const dqCheckErrorsInHeader = dqCheckErrors.filter(error =>
      isErrorMapToField(getValues(), error)
    );
    if (dqCheckErrorsInHeader.length > 0) {
      dqCheckErrorsInHeader.forEach(({ propertyName }) => {
        const isOverrideDirty = dqOverrides.find(
          override => override.propertyName === propertyName && override.isDirty
        );
        if (isOverrideDirty) {
          setValue('dqOverridesSaved', true, { shouldDirty: true });
          dispatch(resetDQCheckOverride(propertyName));
        }
      });
    }
    mapApiErrors<HeaderFields>(
      dqCheckErrorsInHeader.filter(error => !isDQCheckOverride(error, dqOverrides)),
      setError,
      getTariffFields()
    );
  }, [dqCheckErrors, dqOverrides]);

  const handleOnSubmit = (d: FormEvent) => {
    d.preventDefault();
    clearErrors();
    handleSubmit(saveAndNext)();
  };

  return (
    <React.Fragment>
      <h2>{isRider ? 'Rider Header' : 'Tariff Header'}</h2>
      <p>Review the tariff documentation and make edits to any values that have changed.</p>
      <Form
        onSubmit={handleOnSubmit}
        onReset={e => {
          e.preventDefault();
          reset();
        }}
      >
        <ApiFormError apiErrors={errors as FormErrorObject} errorLinks={{}} />
        <Row>
          <Col>
            <TextInput
              type="text"
              label="Tariff Name"
              placeholder="Residential Service"
              {...register('tariffName', { required: 'Field is required' })}
              id="tariffName"
            />
            <CheckInput label="Use different Tariff Book name" {...register('hasBookName')} />
            {hasBookName && (
              <TextInput
                type="text"
                label="Tariff Book Name"
                placeholder="Tariff Book Name"
                {...register('tariffBookName', {
                  required: true,
                })}
                id="tariffBookName"
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              type="text"
              label="Tariff Code"
              placeholder="Tariff Code"
              {...register('tariffCode', { required: 'Field is required' })}
              id="tariffCode"
            />
          </Col>
        </Row>
        {task03_04 && (
          <>
            <Row>
              <Col>
                <SelectInput
                  label="Tariff Type"
                  {...register('tariffType', { required: true })}
                  id="tariffType"
                >
                  <option value="">select tariff type</option>
                  {Object.values(types.TariffType).map(option => {
                    return (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </SelectInput>
              </Col>
            </Row>
            <Row>
              <Col>
                <SelectInput
                  label="Customer Class"
                  {...register('customerClass', {
                    required: isRider ? false : 'Field is required',
                    setValueAs: value => {
                      if (value === '' && isRider) return null;
                      return value;
                    },
                  })}
                  id="customerClass"
                >
                  {isRider ? (
                    <option value="">ALL</option>
                  ) : (
                    <option value="" disabled>
                      select customer class
                    </option>
                  )}
                  {Object.values(types.CustomerClass).map(option => {
                    return (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </SelectInput>
              </Col>
            </Row>
            <Row>
              <Col>
                {timeZoneProperty?.choices && ( // Don't render until options are available
                  <SelectInput
                    label="Time Zone"
                    {...register('timeZone', { required: true })}
                    id="timeZone"
                  >
                    <option value="">select time zone</option>
                    {timeZoneProperty?.choices?.map(option => {
                      return (
                        <option key={option.dataValue} value={option.dataValue}>
                          {option.displayValue}
                        </option>
                      );
                    })}
                  </SelectInput>
                )}
              </Col>
              <Col>
                {currencyProperty?.choices && ( // Don't render until options are available
                  <SelectInput
                    label="Currency"
                    {...register('currency', { required: true })}
                    id="currency"
                  >
                    <option value="">select currency</option>
                    {currencyProperty?.choices?.map(option => {
                      return (
                        <option key={option.dataValue} value={option.dataValue}>
                          {option.displayValue}
                        </option>
                      );
                    })}
                  </SelectInput>
                )}
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col>
            <SelectInput label="Service Area Territory" {...register('territoryId')} id="lseCode">
              {serviceTerritories &&
                serviceTerritories.map(serviceTerritory => (
                  <option key={serviceTerritory.territoryId} value={serviceTerritory.territoryId}>
                    {serviceTerritory.territoryName}
                  </option>
                ))}
            </SelectInput>
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              label="Effective Date"
              type="date"
              placeholder="Effective Date"
              {...register('effectiveDate', {
                required: 'Field is required',
                validate: value => {
                  const isNewEffectiveDateBefore =
                    !!value && !!tariff.effectiveDate && value < tariff.effectiveDate;
                  setEffectiveDateBefore(
                    isNewEffectiveDateBefore && taskAssignment.taskTypeId === '03_03'
                  );
                  clearErrors('confirmCreateHistoricTariffVersion');
                  return true;
                },
              })}
              id="effectiveDate"
            />
            {isEffectiveDateBefore && (
              <div className={styles.effectiveDateBefore}>
                <CheckInput
                  label="This tariff version adds historic coverage"
                  {...register('confirmCreateHistoricTariffVersion', {
                    required: 'You have to confirm to create a tariff version',
                    onChange: async e => {
                      if (e.target.checked) {
                        const result = await ConfirmModal.show({
                          title: 'Warning',
                          question:
                            'You have entered an effective date in the past. This will create a tariff version that is effective before the tariff you are editing. Are you sure you want to do this?',
                          confirm: 'Yes',
                          cancel: 'No, reset effective date',
                        });
                        if (!result) {
                          resetField('effectiveDate');
                          resetField('confirmCreateHistoricTariffVersion');
                          setEffectiveDateBefore(false);
                        }
                      }
                    },
                  })}
                />
              </div>
            )}
          </Col>
          <Col>
            <TextInput
              label="End Date"
              type="date"
              placeholder="End Date"
              {...register('endDate')}
              id="endDate"
            />
          </Col>
        </Row>
        {showOrgIdSearch === true ? (
          <Row>
            <Col>
              <QuickSearch
                popoverStyle={{ zIndex: 15 }}
                value={combinedValue}
                label="Author Org ID"
                {...register('author.orgId')}
                id="author.orgId"
                onChange={value => {
                  if (authorName !== null && value === '') {
                    setCombinedValue(`${authorOrgId} == ${authorName}`);
                  } else {
                    setCombinedValue(value || '');
                    dispatch(doQuickSearch({ minChar: 2, key: 'orgId', value }));
                  }
                }}
                onSelect={async value => {
                  const result = await ConfirmModal.show({
                    title: 'Warning',
                    question: 'Are you sure you want to edit this Org Id and Org Name? ' + value,
                    confirm: 'Yes',
                    cancel: 'No',
                  });
                  if (result) {
                    resetField('author.orgId');
                    const id = value?.slice(0, 36);
                    const name = value?.slice(37, value?.length);
                    setCombinedValue(id + ' == ' + name);
                  }
                }}
              />
              {errors.author?.message && (
                <DQContextMenu propertyName="author" errorType="DQFailure">
                  {errors.author?.message}
                </DQContextMenu>
              )}
            </Col>
          </Row>
        ) : null}
        <Row className="mt-3">
          <Col>
            <CheckInput label="Closed to new customers" {...register('hasClosedDate')} />
            {!hasClosedDate && errors.closedDate?.message && (
              <DQContextMenu propertyName="closedDate" errorType="DQFailure">
                {errors.closedDate?.message}
              </DQContextMenu>
            )}
            {hasClosedDate && (
              <TextInput
                type="date"
                label="Closed Date"
                placeholder="Closed Date"
                {...register('closedDate', { required: true })}
                id="closedDate"
              />
            )}
          </Col>
        </Row>
        {isRider ? null : (
          <Row>
            <Col xs={5}>
              <TextInput
                type="number"
                label="Customer Count"
                placeholder="Customer Count"
                {...register('customerCount', { valueAsNumber: true })}
                id="customerCount"
              />
            </Col>
            <Col>
              <SelectInput
                label="Count Source"
                {...register('customerCountSource', {
                  required: 'Field is required',
                })}
                id="customerCountSource"
              >
                <option value="FERC Form 1">FERC Form 1</option>
                <option value="Genability Estimate">Genability Estimate</option>
              </SelectInput>
            </Col>
          </Row>
        )}
        <PanelNavigationButtons
          onClickPrevious={onPrevious}
          onClickReset={() => {
            setEffectiveDateBefore(false);
          }}
          onClickNext={isDirty ? () => true : onNext}
          nextLabel={isDirty ? 'Save & Next' : 'Next'}
          nextSpin={nextSpin}
          dirty={isDirty}
          noChangesChecked={noChangesChecked}
          onClickNoChanges={handleNoChanges}
        />
      </Form>
    </React.Fragment>
  );
};

export default EditTariffHeaderPanel;
