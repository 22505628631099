import { Tabs } from '@arcadiapower/shrike';
import React, { Dispatch, FC, ReactElement, SetStateAction, useEffect, useState } from 'react';
import ConfigTab from './ConfigTab';
import ReportingTab from './ReportingTab';
import { Container } from 'react-bootstrap';
import StatusMonitoringTab from './StatusMonitoringTab';
import { IWorker } from '../../task-api/types/worker';
import { fetchUserList, selectUserList } from '../../state/userList/userListSlice';
import { GetUserListsRequest } from '../../task-api/v2/api/task-api-v2';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUserWorkerData } from '../../state/currentUser/currentUserSlice';

export interface OpsGlobalFilter {
  owner: IWorker | undefined;
  modeller: IWorker | undefined;
  reviewer: IWorker | undefined;
  [key: string]: any;
}
export interface OpsFilterProps {
  filter: Partial<OpsGlobalFilter>;
  setFilter: Dispatch<SetStateAction<OpsGlobalFilter>>;
  currentUserIsSME: boolean;
  userListLoading: boolean;
}
export interface OpsGlobalFilterProps {
  filter: Partial<OpsGlobalFilter>[];
  setFilter: Dispatch<SetStateAction<OpsGlobalFilter>>[];
  currentUserIsSME: boolean;
  userListLoading: boolean;
}

const OpsManagement: FC = (): ReactElement => {
  const [activeTab, setActiveTab] = useState('Monitoring Status');
  const [tariffConfigFilter, setTariffConfigFilter] = useState<Partial<OpsGlobalFilter>>({});
  const [tariffStatusFilter, setTariffStatusFilter] = useState<Partial<OpsGlobalFilter>>({});
  const [lookupConfigFilter, setLookupConfigFilter] = useState<Partial<OpsGlobalFilter>>({});
  const [lookupStatusFilter, setLookupStatusFilter] = useState<Partial<OpsGlobalFilter>>({});
  const { results: userList } = useSelector(selectUserList);
  const currentUser = useSelector(selectCurrentUserWorkerData);
  const [currentUserIsSME, setCurrentUserIsSME] = useState<boolean>(false);
  const dispatch = useDispatch();

  const TABLES = [
    {
      name: 'Configuration',
      content: (
        <ConfigTab
          filter={[tariffConfigFilter, lookupConfigFilter]}
          setFilter={[setTariffConfigFilter, setLookupConfigFilter]}
          currentUserIsSME={currentUserIsSME}
          userListLoading={userList.length == 0}
        />
      ),
    },
    {
      name: 'Monitoring Status',
      content: (
        <StatusMonitoringTab
          filter={[tariffStatusFilter, lookupStatusFilter]}
          setFilter={[setTariffStatusFilter, setLookupStatusFilter]}
          currentUserIsSME={currentUserIsSME}
          userListLoading={userList.length == 0}
        />
      ),
    },
    {
      name: 'Reporting',
      content: (
        <ReportingTab
          filter={[]}
          setFilter={[]}
          currentUserIsSME={currentUserIsSME}
          userListLoading={userList.length == 0}
        />
      ),
    },
  ];

  useEffect(() => {
    const request = new GetUserListsRequest();
    request.haveRole = 'ROLE_MO_SME';
    dispatch(fetchUserList(request));
  }, []);

  useEffect(() => {
    if (userList.length && currentUser?.workerId) {
      for (let i = 0; i < userList.length; i++) {
        if (userList[i].userId === currentUser?.workerId) {
          setCurrentUserIsSME(true);
          return;
        }
      }
    }
  }, [userList, currentUser]);

  return (
    <Container fluid className="mt-5 pt-4 px-0">
      <Tabs activeTab={activeTab} onClick={tabName => setActiveTab(tabName)}>
        <Tabs.Bar aria-label="Task Content" className="mt-1">
          {TABLES.map(table => (
            <Tabs.Tab key={table.name} name={table.name}>
              <span style={{ minWidth: '182px', fontSize: '1.2rem' }}>{table.name}</span>
            </Tabs.Tab>
          ))}
        </Tabs.Bar>
        {TABLES.map(table => (
          <Tabs.Content key={table.name} contentFor={table.name}>
            {table.content}
          </Tabs.Content>
        ))}
      </Tabs>
    </Container>
  );
};

export default OpsManagement;
