import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TaskApiV2Client } from '../../GenApiClient';
import { GetWorkersRequest } from '../../task-api/v2/api/task-api-v2';
import { handleUnexpectedThunkException } from '../reduxUtils';
import { RootState } from '../rootReducer';
import { restClient } from '@genability/api';
import { IWorker } from '../../task-api/types/worker';

export interface WorkersV2State {
  apiStatus: 'idle' | 'pending' | 'notfound' | 'resolved' | 'rejected';
  count: number;
  results: IWorker[];
  errors: restClient.ResponseError[] | undefined;
}

const initialState: WorkersV2State = {
  apiStatus: 'idle',
  count: 0,
  results: [],
  errors: undefined,
};

export const fetchWorkers = createAsyncThunk(
  'v2/workers/',
  async (request: GetWorkersRequest, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiV2Client();
      const response: restClient.PagedResponse<IWorker> = await client.taskV2.getWorkers(request);
      return response;
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'Task', dispatch));
    }
  }
);

export const workersSlice = createSlice({
  name: 'workersV2',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchWorkers.pending, state => {
        state.apiStatus = 'pending';
      })
      .addCase(
        fetchWorkers.fulfilled,
        (state, action: PayloadAction<restClient.PagedResponse<IWorker>>) => {
          const { count, results, errors } = action.payload;
          if (errors) {
            state.apiStatus = 'rejected';
            state.results = [];
          } else {
            state.apiStatus = 'resolved';
            state.count = count;
            state.results = results;
          }
        }
      );
  },
});

export const selectWorkers = (state: RootState): WorkersV2State => {
  return state.workersV2; // Fix the state key here
};

export default workersSlice.reducer;
