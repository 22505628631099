import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NotificationAlert, Notification } from '@arcadiapower/gen-react-lib';
import {
  toggleNotificationRead,
  selectUnreadNotifications,
} from '../../state/notification/notificationSlice';
import styles from './NotificationQueue.module.scss';

interface NotificationQueueProps {
  maxAlerts: number;
}

function NotificationQueue(props: NotificationQueueProps): React.ReactElement {
  const allUnreadAlerts: Notification[] = useSelector(selectUnreadNotifications());
  const alertsToShow = allUnreadAlerts.filter(
    (notification: Notification, index: number) => index < props.maxAlerts
  );
  const dispatch = useDispatch();
  const handleOnCloseOrHide = (notification: Notification) => {
    dispatch(toggleNotificationRead(notification));
  };
  return (
    <div className={styles.notificationQueue}>
      {alertsToShow.reverse().map(notification => (
        <div
          key={notification.id}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            if ((event.target as HTMLElement).tagName === 'A') {
              setTimeout(() => handleOnCloseOrHide(notification), 300);
            }
          }}
        >
          <NotificationAlert
            notification={notification}
            onClose={() => handleOnCloseOrHide(notification)}
            onHide={() => handleOnCloseOrHide(notification)}
          />
        </div>
      ))}
    </div>
  );
}

export default NotificationQueue;
