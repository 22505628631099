import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { restClient } from '@genability/api';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { TaskApiV2Client } from '../../GenApiClient';
import { addNotification } from '../notification/notificationSlice';
import { RootState } from '../rootReducer';
import { AppThunk } from '../store';
import { TaskAssignmentAnswerV2 } from '../../task-api/v2/types/TaskAssignmentAnswerV2';

export interface IntermediateSaveState {
  taskAssignmentAnswer: TaskAssignmentAnswerV2 | null;
  isLoading: boolean;
  error: string | null;
}

interface intermediateTaskSuccess {
  taskAssignmentAnswer: TaskAssignmentAnswerV2 | null;
}

export const initialState: IntermediateSaveState = {
  taskAssignmentAnswer: null,
  isLoading: false,
  error: '',
};

function intermediateSaveStartLoading(state: IntermediateSaveState) {
  state.isLoading = true;
}

function intermediateSaveLoadingFailed(
  state: IntermediateSaveState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

export const task = createSlice({
  name: 'intermediateSave',
  initialState,
  reducers: {
    intermediateSaveStart: intermediateSaveStartLoading,
    intermediateSaveSuccess(state, { payload }: PayloadAction<intermediateTaskSuccess>) {
      const { taskAssignmentAnswer } = payload;
      state.isLoading = false;
      state.error = null;
      state.taskAssignmentAnswer = taskAssignmentAnswer;
    },
    intermediateSaveFailure: intermediateSaveLoadingFailed,
  },
});

export const { intermediateSaveStart, intermediateSaveSuccess, intermediateSaveFailure } =
  task.actions;

export default task.reducer;

export const saveIntermediateAnswer =
  (taskId: number | null, workflowId: number | null, answerJSON: string): AppThunk =>
  async dispatch => {
    if (taskId == null || workflowId == null) {
      throw new Error('Invalid input: taskId or workflowId null');
    }
    try {
      dispatch(intermediateSaveStart());
      const client = await TaskApiV2Client();
      const {
        results,
        errors: intermediateSaveErrors,
      }: restClient.SingleResponse<TaskAssignmentAnswerV2> =
        await client.taskV2.saveIntermediateAnswer(workflowId, taskId, { answerJSON });
      if (intermediateSaveErrors) {
        throw new Error(intermediateSaveErrors[0].message);
      }
      const taskAssignmentAnswer = results[0];
      dispatch(intermediateSaveSuccess({ taskAssignmentAnswer }));
    } catch (err) {
      if (err) {
        const errorMessage = (err instanceof Error && err.message) || String(err);
        dispatch(intermediateSaveFailure(errorMessage));
        dispatch(addNotification(errorMessage, NotificationLevel.Error));
      }
    }
  };

export const selectIntermediateSavedData = (state: RootState): IntermediateSaveState => {
  return state.intermediateSaveTask;
};
