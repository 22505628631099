import { restClient } from '@genability/api';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OpsManagementApiClient } from '../../../GenApiClient';
import { TariffStatusData } from '../../../components/OpsManagement/Status/TariffStatusTable';
import { addNotification } from '../../notification/notificationSlice';
import { handleUnexpectedThunkException } from '../../reduxUtils';
import { RootState } from '../../rootReducer';
import { TariffStatusDataResponse, tariffStatusRequestProps } from './TariffStatusSlice';

const initialState: TariffStatusDataResponse = {
  results: [],
  apiStatus: 'idle',
  count: 0,
  errors: undefined,
};

export const fetchMonitoringStatusSentinelDates = createAsyncThunk(
  'workflowManagement/status/tariff/sentinelDates',
  async ({ request, params }: tariffStatusRequestProps, { dispatch, rejectWithValue }) => {
    try {
      const client = await OpsManagementApiClient();
      const response: restClient.PagedResponse<TariffStatusData> =
        await client.opsData.fetchTariffStatusData(request, params);
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
      return response;
    } catch (err) {
      dispatch(addNotification('Fetching SentinelDates Failed', NotificationLevel.Error));
      return rejectWithValue(
        handleUnexpectedThunkException(err, 'monitoringStatusSentinelDates', dispatch)
      );
    }
  }
);

export const monitoringStatusSentinelDates = createSlice({
  name: 'MonitoringStatusSentinelDatesSlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchMonitoringStatusSentinelDates.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(
      fetchMonitoringStatusSentinelDates.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<TariffStatusData>>) => {
        const { count, results, errors } = action.payload;
        if (errors) {
          state.apiStatus = 'rejected';
          state.errors = errors;
        } else {
          state.apiStatus = 'resolved';
          state.count = count;
          state.results = results;
          state.errors = undefined;
        }
      }
    );
    builder.addCase(fetchMonitoringStatusSentinelDates.rejected, state => {
      state.apiStatus = 'rejected';
    });
  },
});

export default monitoringStatusSentinelDates.reducer;

export const selectMonitoringStatusSentinelDates = (state: RootState): TariffStatusDataResponse => {
  return state.monitoringStatusSentinelDates;
};
