import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TaskApiV2Client } from '../../GenApiClient';
import { TaskAssignmentRequest } from '../../task-api/v2/api/task-api-v2';
import { handleUnexpectedThunkException } from '../reduxUtils';
import { RootState } from '../rootReducer';
import {
  ASSIGNMENT_ERROR,
  ASSIGNMENT_SUCCESS,
  TaskAssignmentApiState,
} from '../../utils/taskV2Utils';
import { restClient } from '@genability/api';
import { addNotification } from '../notification/notificationSlice';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';

const initialState: TaskAssignmentApiState = {
  apiState: 'idle',
  apiResult: null,
  apiError: null,
};

export const assignTask = createAsyncThunk(
  'v2/tasks/assignTask',
  async (request: TaskAssignmentRequest, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiV2Client();
      let response: restClient.SingleResponse<void>;
      if (request.taskType === 'Tariff') {
        const bulkresponse = await client.taskV2.assignTariffTaskToUser(
          [request.taskId!],
          [''],
          request.username!,
          request.isReviewer!
        );
        response = bulkresponse as unknown as restClient.SingleResponse<void>;
        if (!bulkresponse.results[0].assignmentSuccessful) {
          response.errors = [];
        }
      } else {
        response = await client.taskV2.assignTaskToUser(
          request.workflowId!,
          request.taskId!,
          request.username!
        );
      }
      if (response.errors) {
        dispatch(addNotification(ASSIGNMENT_ERROR, NotificationLevel.Error));
      } else {
        dispatch(addNotification(ASSIGNMENT_SUCCESS, NotificationLevel.Success));
      }
      return response;
    } catch (err) {
      dispatch(addNotification(ASSIGNMENT_ERROR, NotificationLevel.Error));
      return rejectWithValue(handleUnexpectedThunkException(err, 'Task', dispatch));
    }
  }
);

export const taskAssignmentV2Slice = createSlice({
  name: 'taskAssignmentV2',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(assignTask.pending, state => {
      state.apiState = 'pending';
    });
    builder.addCase(
      assignTask.fulfilled,
      (state, action: PayloadAction<restClient.SingleResponse<void>>) => {
        const { errors } = action.payload;
        if (errors) {
          state.apiState = 'rejected';
          state.apiError = errors;
          state.apiResult = ASSIGNMENT_ERROR;
        } else {
          state.apiState = 'resolved';
          state.apiResult = ASSIGNMENT_SUCCESS;
          state.apiError = null;
        }
      }
    );
  },
});

export const taskAssignment = (state: RootState): TaskAssignmentApiState => {
  return state.taskAssignmentV2;
};

export default taskAssignmentV2Slice.reducer;
