import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { AppThunk } from '../store';
import { RootState } from '../rootReducer';
import { IWorker } from '../../task-api/types/worker';
import { TaskApiClient } from '../../GenApiClient';

export interface CurrentUser {
  username: string;
  email: string;
  genability_user_id: number;
  groups: string[];
  worker: IWorker | null;
}

export const initialState: CurrentUser = {
  username: '',
  email: '',
  genability_user_id: 0,
  groups: [],
  worker: null,
};

export const CurrentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    currentUserLoginSuccess: {
      reducer(_state, action: PayloadAction<CurrentUser>) {
        return action.payload;
      },
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      prepare(user: any) {
        const currentuser = {
          username: user.username ?? '',
          email: user.attributes.email ?? '',
          genability_user_id: Number(user.attributes['custom:genability_user_id']) ?? 0,
          groups: user.signInUserSession.accessToken.payload['cognito:groups'] ?? [],
          worker: user.worker,
        };
        return { payload: currentuser };
      },
    },
    signOutSuccess() {
      console.log('Signout success');
      window.location.reload();
    },
    addWorker: (state, action: PayloadAction<IWorker | null>) => {
      return {
        ...state,
        worker: action.payload,
      };
    },
  },
});

export const { currentUserLoginSuccess, signOutSuccess, addWorker } = CurrentUserSlice.actions;

export default CurrentUserSlice.reducer;

export const signOut = (): AppThunk => async dispatch => {
  try {
    await Auth.signOut();
    dispatch(signOutSuccess());
  } catch (err) {
    throw err; // refer to https://arcadiapower.atlassian.net/browse/TT-78
  }
};

export const selectCurrentUser = (state: RootState): CurrentUser => {
  return state.currentUser;
};

export const addWorkerData =
  (username: string): AppThunk =>
  async dispatch => {
    try {
      const client = await TaskApiClient();
      const { result } = await client.worker.getWorker(username);
      if (result) {
        analytics.identify(result.workerId.toString(), {
          firstName: result.firstName,
          lastName: result.lastName,
          email: username,
          qualifications: result.qualifications,
        });
      }
      dispatch(addWorker(result || null));
    } catch (err) {
      throw err; // refer to https://arcadiapower.atlassian.net/browse/TT-78
    }
  };

export const selectCurrentUserWorkerData = (state: RootState): IWorker | null => {
  return state.currentUser.worker;
};
