import React, { FC, useCallback, useEffect } from 'react';
import { ChargeType, ServiceType, CustomerClass } from '@genability/api/dist/types';
import {
  ChargeTypeFilter,
  CountrySelect,
  CustomerClassFilter,
  RateCriteriaFilter,
  ServiceTypeFilter,
  TextInput,
} from '@arcadiapower/gen-react-lib';
import { Button, FilteredSearchInput } from '@arcadiapower/shrike';
import { Col, Row } from 'react-bootstrap';
import { debounce } from 'lodash';
import { ITariffFilter, useTariffFilterContext } from '../../context/tariffFilterContext';
import TariffTypeFilter from '../TariffTypesFilter/TariffTypesFilter';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTariffs } from '../../state/tariffs/tariffsSlice';
import { RootState } from '../../state/rootReducer';
import { LoadingState } from '../../state/reduxUtils';
import styles from './TariffsFilter.module.scss';

const TariffsFilter: FC = () => {
  const { setFilter, filter } = useTariffFilterContext();
  const dispatch = useDispatch();
  const onFilterChange = (update: Partial<ITariffFilter>) => {
    setFilter(current => ({
      ...current,
      ...update,
    }));
  };
  const { loading } = useSelector((state: RootState) => state.tariffs);
  const debounceSearch = useCallback(
    debounce((data: Partial<ITariffFilter>) => {
      if (data) {
        setFilter(current => ({ ...current, ...data }));
      }
    }, 300),
    []
  );
  useEffect(() => {
    dispatch(fetchTariffs(filter));
  }, []);
  return (
    <>
      <Row className="mt-5">
        <Col>
          <FilteredSearchInput
            className={`${styles.tariffDirectorySearch}`}
            onEnter={() => {
              return;
            }}
            onChange={value => {
              setFilter(current => ({ ...current, search: value }));
            }}
            filterOptions={[
              {
                description: 'MTID',
                tag: 'masterTariffId',
              },
              {
                description: 'Tariff ID',
                tag: 'tariffId',
              },
              {
                description: 'LSE ID',
                tag: 'lseId',
              },
            ]}
            label="Type search key and press enter"
            name=""
            value={filter.search}
          />
        </Col>
        <Col className="mt-4">
          <TextInput
            label="Effective On"
            type="date"
            onChange={event =>
              setFilter(current => ({
                ...current,
                effectiveOn: event.target.value == '' ? undefined : event.target.value,
              }))
            }
          />
        </Col>
        <Col>
          <CountrySelect
            onChange={country =>
              setFilter(current => ({ ...current, country: country == '' ? undefined : country }))
            }
          />
        </Col>
      </Row>
      <Row className="ml-1">
        <Col>
          <ChargeTypeFilter
            onChange={chargeTypes =>
              setFilter(current => ({
                ...current,
                chargeTypes: chargeTypes.length > 0 ? (chargeTypes as ChargeType[]) : undefined,
              }))
            }
          />
        </Col>
        <Col>
          <RateCriteriaFilter
            onChange={rateCriteria =>
              setFilter(current => ({
                ...current,
                hasContractedRates:
                  rateCriteria != null ? rateCriteria.hasContractedRates : undefined,
                hasNetMetering: rateCriteria != null ? rateCriteria.hasNetMetering : undefined,
                hasTieredRates: rateCriteria != null ? rateCriteria.hasTieredRates : undefined,
                hasTimeOfUseRates:
                  rateCriteria != null ? rateCriteria.hasTimeOfUseRates : undefined,
              }))
            }
          />
        </Col>
        <Col>
          <CustomerClassFilter
            onChange={customerClasses =>
              setFilter(current => ({
                ...current,
                customerClasses:
                  customerClasses.length > 0 ? (customerClasses as CustomerClass[]) : undefined,
              }))
            }
          />
        </Col>
        <Col>
          <h5 className="font-weight-bold">Tariff Types</h5>
          <TariffTypeFilter onFilterChange={onFilterChange} />
        </Col>
        <Col>
          <ServiceTypeFilter
            onChange={serviceTypes =>
              setFilter(current => ({
                ...current,
                serviceTypes: serviceTypes.length > 0 ? (serviceTypes as ServiceType[]) : undefined,
              }))
            }
          />
        </Col>
        <Col xs={4}>
          <h4>Usage Limits</h4>
          <Row>
            <Col>
              <TextInput
                label="Consumption (KWH)"
                type="number"
                onChange={event =>
                  debounceSearch({
                    consumption:
                      Number(event.target.value) > 0 ? Number(event.target.value) : undefined,
                  })
                }
              />
              <TextInput
                label="Demand (KW)"
                type="number"
                onChange={event =>
                  debounceSearch({
                    demand: Number(event.target.value) > 0 ? Number(event.target.value) : undefined,
                  })
                }
              />
              <Button
                onClick={() => dispatch(fetchTariffs(filter))}
                loading={loading === LoadingState.PENDING}
              >
                Apply Filters
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default TariffsFilter;
