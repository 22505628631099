import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { types } from '@genability/api';
import { Icon, Table } from '@arcadiapower/gen-react-lib';
import {
  Operation,
  ChangeOperation,
  NestedOperation,
  ResourceDiff,
  ResourceDiffSection,
} from '../../task-api/types/resource-diff';
import ResourceDiffSummary from '../ResourceDiffSummary/ResourceDiffSummary';
import styles from './ResourceDiffSection.module.scss';

import { selectTaskAssignmentTariff } from '../../state/taskAssignmentTariffs/taskAssignmentTariffsSlice';
import {
  hasRiderId,
  isRateOperation,
  isHeaderSection,
  isPropertyTypesSection,
} from '../../utils/diffUtils';

type Patch = Operation | ChangeOperation<unknown> | NestedOperation;

/**
 * Slice the patch diff operations by section
 */
const getPatchBySection = (
  resourceDiffSection: ResourceDiffSection,
  patch: Patch[],
  tariff: types.Tariff | undefined
): Patch[] => {
  const { section } = resourceDiffSection;
  return patch.filter((operation: Operation) => {
    switch (section) {
      case 'Header':
        return isHeaderSection(operation);
      case 'Eligibility':
        return isPropertyTypesSection(operation, tariff, 'APPLICABILITY');
      case 'Rate Criteria':
        return isPropertyTypesSection(operation, tariff, 'RATE_CRITERIA');
      case 'Service Terms':
        return isPropertyTypesSection(operation, tariff, 'SERVICE_TERMS');
      case 'Info Properties':
        return isPropertyTypesSection(operation, tariff, 'INFO');
      case 'Rates':
        return isRateOperation(operation) && !hasRiderId(operation);
      case 'Riders':
        return isRateOperation(operation) && hasRiderId(operation);
      default:
        return false;
    }
  });
};

export interface ResourceDiffBySectionProps {
  resourceDiff: ResourceDiff;
}

/**
 * ResourceDiffBySection wraps Patch Diff by Sections.
 */
const ResourceDiffBySection: React.FC<ResourceDiffBySectionProps> = ({ resourceDiff }) => {
  const taskAssignmentTariff = useSelector(selectTaskAssignmentTariff);

  const [expandAll, setExpandAll] = useState(false);

  const columnStyleDefinition = [
    styles.headerSectionName,
    styles.headerSummaryData,
    styles.headerSummaryData,
    styles.headerSummaryData,
    styles.headerSummaryData,
    styles.headerSummaryData,
  ];

  return (
    <div className={styles.sectionHeaderTitle}>
      <Table>
        <colgroup>
          {columnStyleDefinition.map((columnClassName, index) => (
            <col key={index} className={columnClassName} />
          ))}
        </colgroup>
        <thead>
          <tr>
            <th
              onClick={() => setExpandAll(prevState => !prevState)}
              className={styles.clickableRow}
            >
              <Icon
                iconName={expandAll ? 'chevronDown' : 'chevronRight'}
                className={styles.chevronIcon}
              />
              Section
            </th>
            <th>Count</th>
            <th>Unchanged</th>
            <th>Changed</th>
            <th>Added</th>
            <th>Removed</th>
          </tr>
        </thead>
      </Table>
      {resourceDiff?.summary?.map((resourceDiffSection: ResourceDiffSection, index) => (
        <div key={`diff_${index}`}>
          <ResourceDiffSummary
            summary={resourceDiffSection}
            patch={getPatchBySection(
              resourceDiffSection,
              resourceDiff?.patch,
              taskAssignmentTariff
            )}
            columnClassNames={columnStyleDefinition}
            expanded={expandAll}
          />
        </div>
      ))}
    </div>
  );
};

export default ResourceDiffBySection;
