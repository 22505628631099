import { types } from '@genability/api';
import { useState } from 'react';
import { cloneDeep, pick } from 'lodash';
import appConstants from '../app-constants';
import { isSameTariffProperty } from './taskUtils';
import { TaskTariff } from '../task-api/types/task';

interface UseTariffAnswerReturned {
  tariffAnswer: TaskTariff;
  groupsAnswer: TariffRateGroup[];
  resetAnswer: (originalTariff: TaskTariff) => void;
  setHeaderField: (fieldName: string, value: string | null, isInt: boolean) => void;
  setHeaderFields: (fields: Partial<TaskTariff>) => void;
  addRate: (rate: types.TariffRate, tariffSequenceNumber?: number) => number | undefined;
  moveRate: (
    fromTariffSequenceNumber: number,
    toTariffSequenceNumber: number,
    toRateGroupName?: string
  ) => void;
  setRate: (rate: types.TariffRate) => void;
  deleteRate: (tariffRateId: number) => void;
  addRateGroup: (groupIndex: number) => void;
  moveRateGroup: (fromGroupIndex: number, toGroupIndex: number) => void;
  updateRateGroupName: (groupIndex: number, name: string) => void;
  deleteRateGroup: (groupIndex: number) => void;
  getTariffFields: () => Partial<TaskTariff>;
  extractTariffFields: (formData: Partial<TaskTariff>) => Partial<TaskTariff>;
  setTariffFieldsFromForm: (
    formData: Partial<TaskTariff>,
    tariffToUpdate?: TaskTariff
  ) => TaskTariff;
  addTariffProperty: (propertyToAdd: types.TariffProperty | undefined) => boolean;
  setTariffProperty: (selectedProperty: types.TariffProperty | undefined) => boolean;
  setTariffProperties: (selectedProperties: types.TariffProperty[] | undefined) => {
    didUpdate: boolean[];
    updatedTariffAnswer: TaskTariff;
  };
  deleteTariffProperty: (propertyToDelete: types.TariffProperty | undefined) => boolean;
  tariffFields?: readonly (keyof TaskTariff)[];
  persistReorderedTariff: boolean;
  setPersistReorderedTariff: (persist: boolean) => void;
}

export interface TariffRateGroup {
  groupIndex: number;
  groupName: string;
  hasRates: boolean;
  startSequenceNumber: number;
  endSequenceNumber: number;
}

const useTariffAnswer = (
  initialValue: TaskTariff,
  tariffFields?: (keyof TaskTariff)[]
): UseTariffAnswerReturned => {
  const init = (
    toInitialize: TaskTariff
  ): {
    initTariff: TaskTariff;
    initGroups: TariffRateGroup[];
  } => {
    const initTariff = cloneDeep(toInitialize);
    let initGroups: TariffRateGroup[] = [];
    if (initTariff.rates === undefined || initTariff.rates.length == 0) {
      initTariff.rates = [];
      initGroups[0] = {
        groupIndex: 0,
        groupName: 'New Group',
        hasRates: false,
        startSequenceNumber: 1,
        endSequenceNumber: 1,
      };
    } else {
      initGroups = refreshGroups(initTariff.rates);
    }
    return { initTariff, initGroups };
  };

  const refreshHighestRateId = (rates: types.TariffRate[]): number => {
    let highestRateId = 0;
    if (rates && rates.length > 0) {
      rates.forEach((rate: types.TariffRate) => {
        if (
          rate.tariffRateId &&
          rate.tariffRateId >= appConstants.addedTariffRateIds.min &&
          rate.tariffRateId <= appConstants.addedTariffRateIds.max &&
          rate.tariffRateId > highestRateId
        ) {
          highestRateId = rate.tariffRateId;
        }
      });
      rates.forEach((rate: types.TariffRate) => {
        if (!rate.tariffRateId) {
          // not set or zero
          highestRateId++;
          rate.tariffRateId = highestRateId;
        }
      });
    }
    return highestRateId;
  };

  const refreshGroups = (rates: types.TariffRate[]): TariffRateGroup[] => {
    const newGroups: TariffRateGroup[] = [];
    if (rates.length > 0) {
      rates.sort(compareTariffSequenceNumber);
      let prevGroup = {
        groupIndex: 0,
        groupName: rates[0].rateGroupName,
        hasRates: true,
        startSequenceNumber: rates[0].tariffSequenceNumber as number,
        endSequenceNumber: rates[0].tariffSequenceNumber as number,
      };
      rates.forEach((rate: types.TariffRate) => {
        if (rate.rateGroupName === prevGroup.groupName) {
          prevGroup.endSequenceNumber = rate.tariffSequenceNumber as number;
        } else {
          newGroups.push(prevGroup);
          prevGroup = {
            groupIndex: newGroups.length,
            groupName: rate.rateGroupName,
            hasRates: true,
            startSequenceNumber: rate.tariffSequenceNumber as number,
            endSequenceNumber: rate.tariffSequenceNumber as number,
          };
        }
      });
      newGroups.push(prevGroup);
    } else {
      newGroups[0] = {
        groupIndex: 0,
        groupName: 'New Group',
        hasRates: false,
        startSequenceNumber: 1,
        endSequenceNumber: 1,
      };
    }
    return newGroups;
  };

  const compareTariffSequenceNumber = (a: types.TariffRate, b: types.TariffRate): number => {
    if (a.tariffSequenceNumber === null) {
      if (b.tariffSequenceNumber === null) {
        return 0;
      }
      return -1;
    } else if (b.tariffSequenceNumber === null) {
      return 1;
    }
    if (a.tariffSequenceNumber < b.tariffSequenceNumber) {
      return -1;
    }
    if (a.tariffSequenceNumber > b.tariffSequenceNumber) {
      return 1;
    }
    return 0;
  };

  const { initTariff, initGroups } = init(initialValue);
  const [tariff, setTariff] = useState(initTariff);
  const [groups, setGroups] = useState(initGroups);
  const [highestRateId, setHighestRateId] = useState(
    refreshHighestRateId(initTariff.rates as types.TariffRate[])
  );
  const [persistReorderedTariff, setPersistReorderedTariff] = useState(false);

  /**
   * Use to revert pending changes or when server has been updated.
   * @param cleanTariff to reset back to
   */
  const resetAnswer = (cleanTariff: TaskTariff) => {
    const { initTariff, initGroups } = init(cleanTariff);
    setTariff(initTariff);
    setGroups(initGroups);
    setHighestRateId(refreshHighestRateId(initTariff.rates as types.TariffRate[]));
  };

  const setHeaderField = (fieldName: string, value: string | null, isInt: boolean) => {
    if (isInt && value) {
      setTariff({
        ...tariff,
        [fieldName]: parseInt(value),
      });
    } else {
      setTariff({
        ...tariff,
        [fieldName]: value,
      });
    }
  };

  const setHeaderFields = (fields: Partial<types.Tariff>) => {
    const updatedTariff = {
      ...tariff,
      ...fields,
    };
    setTariff(updatedTariff);
    return updatedTariff;
  };

  /**
   * Returns a partial tariff object containing only the defined fields.
   */
  const getTariffFields = () => {
    if (tariffFields) {
      return pick(tariff, tariffFields);
    }
    return tariff;
  };

  /**
   * Extracts defined header fields from an object containing non-tariff properties.
   * @param formData object including a partial set of Tariff header properties
   */
  const extractTariffFields = (formData: Partial<types.Tariff>) => {
    if (tariffFields) {
      return pick(formData, tariffFields);
    }
    return tariff;
  };

  /**
   * Takes an object with form data, extracts defined fields, and sets tariff.
   * @param formData object including a partial set of Tariff header properties
   */
  const setTariffFieldsFromForm = (formData: Partial<TaskTariff>, tariffToUpdate = tariff) => {
    const updatedTariff = {
      ...tariffToUpdate,
      ...extractTariffFields(formData),
    };
    setTariff(updatedTariff);
    return updatedTariff;
  };

  /**
   * If property doesnt exist then add it to tariff
   * return true if property is added or present false otherwise
   * @param propertyToAdd
   */
  const addTariffProperty = (propertyToAdd: types.TariffProperty | undefined) => {
    if (!propertyToAdd) return false;
    if (tariff.properties) {
      const index = tariff.properties.findIndex(prop => {
        return isSameTariffProperty(prop, propertyToAdd);
      });

      const allProperties = cloneDeep(tariff.properties);

      //Property to add doesnt already exists
      if (index == -1) {
        // Add new property
        allProperties.push(propertyToAdd);
      }
      setTariff({
        ...tariff,
        properties: allProperties,
      });
      return true;
    } else {
      const properties = [propertyToAdd];
      setTariff({
        ...tariff,
        properties: properties,
      });
      return true;
    }
  };

  /**
   * Updates a similar property with the passed in property
   * @param selectedProperty  the property to be set on tariff
   * return true if property is updated false otherwise
   */
  const setTariffProperty = (selectedProperty: types.TariffProperty | undefined) => {
    if (!selectedProperty || !tariff.properties) {
      //Nothing to update
      return false;
    }

    const allProperties = cloneDeep(tariff.properties);

    //Return -1 if property is not present
    const index = allProperties.findIndex(prop => {
      return isSameTariffProperty(prop, selectedProperty);
    });

    if (index >= 0) {
      // Replace property with updated version
      allProperties.splice(index, 1, selectedProperty);
      setTariff({
        ...tariff,
        properties: allProperties,
      });
      return true;
    }
    return false;
  };

  /**
   * Updates multiple passed-in properties in one state update
   * @param selectedProperties  the property to be set on tariff
   * return true if property is updated false otherwise
   */
  const setTariffProperties = (selectedProperties: types.TariffProperty[] | undefined) => {
    const didUpdate: boolean[] = [];

    if (!selectedProperties || !tariff.properties) {
      //Nothing to update
      return {
        didUpdate,
        updatedTariffAnswer: tariff,
      };
    }

    const allProperties = cloneDeep(tariff.properties);

    selectedProperties.forEach((selectedProperty: types.TariffProperty) => {
      //Return -1 if property is not present
      const index = allProperties.findIndex(prop => {
        return isSameTariffProperty(prop, selectedProperty);
      });

      if (index >= 0) {
        // Replace property with updated version
        allProperties.splice(index, 1, selectedProperty);
        didUpdate.push(true);
      }
    });

    const updatedTariffAnswer = {
      ...tariff,
      properties: allProperties,
    };

    setTariff(updatedTariffAnswer);

    return {
      didUpdate,
      updatedTariffAnswer,
    };
  };

  /**
   * Delete an existing property based on property object matching
   * @param propertyToDelete  the property to be deleted
   * return true if property is deleted or false otherwise
   */
  const deleteTariffProperty = (propertyToDelete: types.TariffProperty | undefined) => {
    if (!propertyToDelete || !tariff.properties) {
      // No Property or tariffProperties, nothing to delete
      return false;
    }
    const allProperties = cloneDeep(tariff.properties);

    // Get the property we want to delete
    const index = allProperties.findIndex(prop => {
      return isSameTariffProperty(prop, propertyToDelete);
    });
    //element present
    if (index >= 0) {
      // Additionally determine if the property is
      // in cleanProperties
      allProperties.splice(index, 1);
      setTariff({
        ...tariff,
        properties: allProperties,
      });
      return true;
    }
    //Will return false if element to delete not present
    return false;
  };

  /**
   * Add a new rate to the rates on the tariff
   * @param rate to add
   * @param tariffSequenceNumber optionally where you want it added (added to end if omitted)
   * @returns tariffRateId of added rate, or undefined if rate was not passed in
   */
  const addRate = (rate: types.TariffRate, tariffSequenceNumber?: number): number | undefined => {
    if (rate) {
      let isAddedRate = false;
      if (!rate.tariffRateId) {
        rate.tariffRateId = highestRateId + 1;
        isAddedRate = true;
      }
      const newRates = cloneDeep(tariff.rates) as types.TariffRate[];
      newRates.sort(compareTariffSequenceNumber);
      if (
        typeof tariffSequenceNumber !== 'number' ||
        !tariff.rates ||
        !tariff.rates.length ||
        tariff.rates.length < tariffSequenceNumber
      ) {
        let newSequenceNumber = tariffSequenceNumber;
        if (!newSequenceNumber) {
          if (
            newRates &&
            newRates.length &&
            newRates[newRates.length - 1].tariffSequenceNumber !== null
          ) {
            // TODO: Can't convince the compiler that tariffSequenceNumber can't be null in this condition
            newSequenceNumber =
              (newRates[newRates.length - 1].tariffSequenceNumber || newRates.length) + 1;
          } else {
            newSequenceNumber = newRates.length + 1;
          }
        }
        newRates.push(cloneDeep({ ...rate, tariffSequenceNumber: newSequenceNumber }));
      } else {
        const index = newRates.findIndex(
          item => item.tariffSequenceNumber === tariffSequenceNumber
        );
        const ratesToMove = newRates.splice(index, newRates.length);
        newRates.splice(
          index,
          0,
          cloneDeep({ ...rate, tariffSequenceNumber: tariffSequenceNumber }),
          ...ratesToMove
        );
        newRates.forEach((rate: types.TariffRate, position) => {
          rate.tariffSequenceNumber = position + 1;
        });
      }
      setTariff({
        ...tariff,
        rates: newRates,
      });
      setGroups(refreshGroups(newRates));
      if (isAddedRate) {
        setHighestRateId(rate.tariffRateId);
      }
      return rate.tariffRateId;
    }
    return undefined;
  };

  /**
   * Move a rate from its current tariffSequenceNumber to the one passed in.
   * @param tariffRateId of rate to move
   * @param toTariffSequenceNumber where to move it to
   * @param toRateGroupName optional, only needed if move is also moving groups
   * @returns
   */
  const moveRate = (
    tariffRateId: number,
    toTariffSequenceNumber: number,
    toRateGroupName?: string
  ) => {
    if (tariff.rates) {
      const rateToMove = cloneDeep(
        tariff.rates.find(item => {
          return item.tariffRateId === tariffRateId;
        })
      );
      if (rateToMove) {
        if (rateToMove.tariffSequenceNumber === toTariffSequenceNumber) {
          return;
        }
        const newRates = cloneDeep(tariff.rates);
        newRates.forEach(rate => {
          if (rate.tariffRateId === tariffRateId) {
            rate.tariffSequenceNumber = toTariffSequenceNumber;
            if (toRateGroupName) {
              rate.rateGroupName = toRateGroupName;
            }
          } else if (rate.tariffSequenceNumber && rateToMove.tariffSequenceNumber) {
            if (
              rateToMove.tariffSequenceNumber < toTariffSequenceNumber &&
              rate.tariffSequenceNumber > rateToMove.tariffSequenceNumber &&
              rate.tariffSequenceNumber <= toTariffSequenceNumber
            ) {
              rate.tariffSequenceNumber = rate.tariffSequenceNumber - 1;
            } else if (
              rateToMove.tariffSequenceNumber > toTariffSequenceNumber &&
              rate.tariffSequenceNumber < rateToMove.tariffSequenceNumber &&
              rate.tariffSequenceNumber >= toTariffSequenceNumber
            ) {
              rate.tariffSequenceNumber = rate.tariffSequenceNumber + 1;
            }
          }
        });
        newRates.sort(compareTariffSequenceNumber);
        setTariff({
          ...tariff,
          rates: newRates,
        });
        setGroups(refreshGroups(newRates));
      }
    }
  };

  /**
   * Updates a rate, except for its tariffRateId or its tariffSequenceNumber.
   * @param rate to update (needs tariffRateId set)
   */
  const setRate = (rate: types.TariffRate) => {
    if (rate && rate.tariffRateId && tariff.rates) {
      const index = tariff.rates.findIndex(item => item.tariffRateId === rate.tariffRateId);
      const newRates = cloneDeep(tariff.rates);
      newRates[index] = cloneDeep({
        ...rate,
        tariffSequenceNumber: newRates[index].tariffSequenceNumber,
      });
      setTariff({
        ...tariff,
        rates: newRates,
      });
    }
  };

  /**
   * Delete an existing rate based on its tariffRateId
   * @param tariffRateId of the rate to delete
   */
  const deleteRate = (tariffRateId: number) => {
    if (tariff.rates) {
      const index = tariff.rates.findIndex(item => item.tariffRateId === tariffRateId);
      const newRates = cloneDeep(tariff.rates);
      newRates.splice(index, 1);
      newRates.forEach((rate: types.TariffRate, position) => {
        rate.tariffSequenceNumber = position + 1;
      });
      setTariff({
        ...tariff,
        rates: newRates,
      });
      setGroups(refreshGroups(newRates));
      setHighestRateId(refreshHighestRateId(newRates));
    }
  };

  /**
   * Adds a new empty group, ready to add rates to
   * @param groupIndex where to add the group
   */
  const addRateGroup = (groupIndex: number) => {
    const thisIndex =
      groupIndex < groups.length
        ? groups[groupIndex].startSequenceNumber
        : groups[groups.length - 1].endSequenceNumber;
    const newGroups = cloneDeep(groups);
    newGroups.splice(groupIndex, 0, {
      groupIndex: groupIndex,
      groupName: 'New Group',
      hasRates: false,
      startSequenceNumber: thisIndex || 0,
      endSequenceNumber: thisIndex || 0,
    });
    newGroups.forEach((group: TariffRateGroup, position) => {
      group.groupIndex = position;
    });
    setGroups(newGroups);
  };

  /**
   * Moves a whole group and its rates to the position requested
   * @param fromGroupIndex where the group is now
   * @param toGroupIndex where you want it to be
   */
  const moveRateGroup = (fromGroupIndex: number, toGroupIndex: number) => {
    const fromGroup = groups[fromGroupIndex];
    if (fromGroup) {
      if (tariff.rates && fromGroup.hasRates) {
        // Move rates then refresh the groups
        const toGroup = groups[toGroupIndex];
        const newRates = cloneDeep(tariff.rates);
        const startIndex = tariff.rates.findIndex(
          item => item.tariffSequenceNumber === fromGroup.startSequenceNumber
        );
        const endIndex = tariff.rates.findIndex(
          item => item.tariffSequenceNumber === fromGroup.endSequenceNumber
        );
        const ratesToMove = newRates.splice(startIndex, endIndex - startIndex + 1);
        if (fromGroupIndex < toGroupIndex) {
          const toIndex = newRates.findIndex(
            item => item.tariffSequenceNumber === toGroup.endSequenceNumber
          );
          newRates.splice(toIndex + 1, 0, ...ratesToMove);
        } else {
          const toIndex = newRates.findIndex(
            item => item.tariffSequenceNumber === toGroup.startSequenceNumber
          );
          newRates.splice(toIndex, 0, ...ratesToMove);
        }
        newRates.forEach((rate: types.TariffRate, position) => {
          rate.tariffSequenceNumber = position + 1;
        });
        setTariff({
          ...tariff,
          rates: newRates,
        });
        setGroups(refreshGroups(newRates));
        setPersistReorderedTariff(true);
      } else {
        // just move the non-rate group
        const newGroups = cloneDeep(groups);
        newGroups.splice(fromGroupIndex, 1);
        newGroups.splice(toGroupIndex, 0, fromGroup);
        newGroups.forEach((group: TariffRateGroup, position) => {
          group.groupIndex = position;
        });
        setGroups(newGroups);
      }
    }
  };

  /**
   * Rename the groupName including all its rates.
   * @param groupIndex index of the group
   * @param name to change to
   */
  const updateRateGroupName = (groupIndex: number, name: string) => {
    const group = groups[groupIndex];
    if (group.hasRates && tariff.rates && tariff.rates.length > 0) {
      const newRates = cloneDeep(tariff.rates);
      newRates.forEach(rate => {
        if (
          rate.rateGroupName === group.groupName &&
          rate.tariffSequenceNumber &&
          rate.tariffSequenceNumber >= group.startSequenceNumber &&
          rate.tariffSequenceNumber <= group.endSequenceNumber
        ) {
          rate.rateGroupName = name;
        }
      });
      setTariff({
        ...tariff,
        rates: newRates,
      });
      group.groupName = name;
      setGroups(refreshGroups(newRates));
    } else {
      group.groupName = name;
      setGroups(groups);
    }
  };

  /**
   * Deletes the group and all of its rates.
   * @param groupIndex of group to delete
   */
  const deleteRateGroup = (groupIndex: number) => {
    const delGroup = groups[groupIndex];
    if (delGroup) {
      if (tariff.rates && delGroup.hasRates) {
        // Delete rates then refresh the groups
        const filteredRates = tariff.rates?.filter(rate => {
          return (
            rate.rateGroupName != delGroup.groupName ||
            !rate.tariffSequenceNumber ||
            rate.tariffSequenceNumber < delGroup.startSequenceNumber ||
            rate.tariffSequenceNumber > delGroup.endSequenceNumber
          );
        });
        const newRates = cloneDeep(filteredRates).sort(compareTariffSequenceNumber);
        newRates.forEach((rate: types.TariffRate, position) => {
          rate.tariffSequenceNumber = position + 1;
        });
        setTariff({
          ...tariff,
          rates: newRates,
        });
        setGroups(refreshGroups(newRates));
      } else {
        // just delete the non-rate group
        const newGroups = cloneDeep(groups);
        newGroups.splice(groupIndex, 1);
        newGroups.forEach((group: TariffRateGroup, position) => {
          group.groupIndex = position;
        });
        setGroups(newGroups);
      }
    }
  };

  return {
    tariffAnswer: tariff,
    groupsAnswer: groups,
    resetAnswer,
    setHeaderField,
    setHeaderFields,
    addRate,
    moveRate,
    setRate,
    deleteRate,
    addRateGroup,
    moveRateGroup,
    updateRateGroupName,
    deleteRateGroup,
    getTariffFields,
    extractTariffFields,
    setTariffFieldsFromForm,
    addTariffProperty,
    setTariffProperty,
    setTariffProperties,
    deleteTariffProperty,
    ...(!!tariffFields && { tariffFields: [...tariffFields] as const }),
    persistReorderedTariff,
    setPersistReorderedTariff,
  };
};

export default useTariffAnswer;
