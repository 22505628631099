import { useCallback, useState } from 'react';

const useModal = (): [boolean, () => void] => {
  const [isVisible, setVisible] = useState(false);

  const toggleVisible = useCallback(() => {
    setVisible(state => !state);
  }, [setVisible]);

  return [isVisible, toggleVisible];
};

export default useModal;
