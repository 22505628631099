export enum TaskType {
  ADD_TARIFF = 'ADD_TARIFF',
  REVISE_TARIFF = 'REVISE_TARIFF',
  EDIT_TARIFF = 'EDIT_TARIFF',
  REVISE_DOCUMENT = 'REVISE_DOCUMENT',
  ADD_DOCUMENT = 'ADD_DOCUMENT',
  ADD_LOOKUP = 'ADD_LOOKUP',
  EDIT_LOOKUP = 'EDIT_LOOKUP',
  UPSERT_LOOKUP = 'UPSERT_LOOKUP',
  ADD_PROPERTY = 'ADD_PROPERTY',
  EDIT_PROPERTY = 'EDIT_PROPERTY',
}
