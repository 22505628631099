import { Button, Modal } from '@arcadiapower/shrike';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assignTask, taskAssignment } from '../../state/taskAssignment_V2/taskAssignmentV2Slice';
import { TaskAssignmentRequest } from '../../task-api/v2/api/task-api-v2';
import { BackgroundColors } from '@arcadiapower/shrike/dist/types/src/types';
import WorkerInput from '../WorkerInput/WorkerInput';
import { IWorker } from '../../task-api/types/worker';
import { QualificationType } from '../../task-api/v2/types/QualifictionType';
import { TaskType } from '../../task-api/v2/types/TaskType';

export interface AssignTaskProps {
  taskType: TaskType;
  taskId: string;
  workflowId?: string;
  className?: string;
  reviewer?: boolean;
  text?: string;
  username?: string;
  size?: 'small' | 'medium' | undefined;
  color?: BackgroundColors;
}
const AssignTask: React.FC<AssignTaskProps> = ({
  taskId,
  workflowId,
  className,
  reviewer,
  username,
  text,
  size,
  taskType,
  color,
}) => {
  const dispatch = useDispatch();

  const { apiState } = useSelector(taskAssignment);
  const [showAssignmentModal, setShowAssignmentModal] = useState<boolean>(false);
  const [worker, setWorker] = useState<IWorker | undefined>(undefined);

  useEffect(() => {
    if (apiState == 'resolved') {
      setShowAssignmentModal(false);
      setWorker(undefined);
    }
  }, [apiState]);

  const getQualificationType: () => QualificationType | undefined = () => {
    if (taskType === TaskType.ADD_LOOKUP || taskType === TaskType.EDIT_LOOKUP) {
      return reviewer ? QualificationType.LOOKUP_REVIEWER : QualificationType.LOOKUP_MODELLER;
    } else if (taskType === TaskType.ADD_PROPERTY || taskType === TaskType.EDIT_PROPERTY) {
      return reviewer
        ? QualificationType.PROPERTY_KEY_REVIEWER
        : QualificationType.PROPERTY_KEY_MODELLER;
    } else if (
      taskType === TaskType.ADD_TARIFF ||
      taskType === TaskType.EDIT_TARIFF ||
      taskType == TaskType.REVISE_TARIFF
    ) {
      return reviewer ? QualificationType.TARIFF_REVIEWER : QualificationType.TARIFF_MODELLER;
    }
  };

  const assignTasktoWorker = async (userName: string | undefined) => {
    if (userName) {
      const request = new TaskAssignmentRequest();
      request.taskId = taskId;
      request.workflowId = workflowId;
      request.username = userName;
      request.isReviewer = reviewer || false;
      if ([TaskType.ADD_TARIFF, TaskType.EDIT_TARIFF, TaskType.REVISE_TARIFF].includes(taskType)) {
        request.taskType = 'Tariff';
      }
      dispatch(assignTask(request));
    }
  };

  return (
    <>
      <Button
        id={`${workflowId}_${taskId}}`}
        backgroundColor={color ? color : 'accent2'}
        onClick={() => {
          username ? assignTasktoWorker(username) : setShowAssignmentModal(true);
        }}
        loading={username ? apiState === 'pending' : false}
        data-testid="reassign-button"
        size={size ? size : 'small'}
        className={`${className} `}
      >
        {text ? text : 'Assign'}
      </Button>
      <Modal aria-label="Modal" data-testid="reassign-model" isOpen={showAssignmentModal}>
        <Modal.Header
          title="Assign Task"
          onClose={() => setShowAssignmentModal(false)}
        ></Modal.Header>
        <Modal.Content>
          <WorkerInput
            qualification={getQualificationType()}
            label={reviewer ? 'Reviewer' : 'User'}
            placeholder="Search"
            id="assignTask"
            selected={worker}
            setSelected={setWorker}
          />
        </Modal.Content>
        <Modal.Footer
          onSubmit={() => assignTasktoWorker(worker?.userName)}
          loading={apiState === 'pending'}
        ></Modal.Footer>
      </Modal>
    </>
  );
};
export default AssignTask;
