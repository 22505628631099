import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { restClient } from '@genability/api';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { OpsManagementApiClient } from '../../../GenApiClient';
import { addNotification } from '../../notification/notificationSlice';
import { handleUnexpectedThunkException } from '../../reduxUtils';
import { RootState } from '../../rootReducer';
import { MonitoringHistory } from '../../../ops-api/types/MonitoringHistory';
import { GetMonitoringHistoryRequest } from '../../../ops-api/api/ops-api';

export interface MonitoringHistoryState {
  results: MonitoringHistory[];
  fetchApiStatus: 'idle' | 'pending' | 'resolved' | 'rejected';
  errors: restClient.ResponseError[] | undefined;
  count: number;
}

export const initialState: MonitoringHistoryState = {
  results: [],
  fetchApiStatus: 'idle',
  count: 0,
  errors: undefined,
};

export const fetchMonitoringHistory = createAsyncThunk(
  'workflowManagement/status/history',
  async (request: GetMonitoringHistoryRequest, { dispatch, rejectWithValue }) => {
    try {
      const client = await OpsManagementApiClient();
      const response: restClient.PagedResponse<MonitoringHistory> =
        await client.opsData.fetchMonitoringHistory(request);
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
      return response;
    } catch (err) {
      dispatch(addNotification('Fetching Monitoring History Failed', NotificationLevel.Error));
      return rejectWithValue(handleUnexpectedThunkException(err, 'MonitoringHistory', dispatch));
    }
  }
);

export const MonitoringHistorySlice = createSlice({
  name: 'MonitoringHistory',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchMonitoringHistory.pending, state => {
      state.fetchApiStatus = 'pending';
    });
    builder.addCase(
      fetchMonitoringHistory.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<MonitoringHistory>>) => {
        const { count, results, errors } = action.payload;
        if (errors) {
          state.fetchApiStatus = 'rejected';
          state.errors = errors;
        } else {
          state.fetchApiStatus = 'resolved';
          state.count = count;
          state.results = results;
          state.errors = undefined;
        }
      }
    );
    builder.addCase(fetchMonitoringHistory.rejected, state => {
      state.fetchApiStatus = 'rejected';
    });
  },
});

export default MonitoringHistorySlice.reducer;

export const selectMonitoringHistory = (state: RootState): MonitoringHistoryState => {
  return state.monitoringHistory;
};
