import React, { useRef, useCallback, useImperativeHandle } from 'react';
import { ToString, types } from '@genability/api';
import { SelectInput } from '@arcadiapower/gen-react-lib';
import styles from './TimeOfUseDropdown.module.scss';
import { FormGroupProps } from 'react-bootstrap/FormGroup';
import { FormControlProps } from 'react-bootstrap/FormControl';
interface TouDropdownProp extends FormGroupProps, FormControlProps {
  touGroups: Array<types.TimeOfUseGroup>;
  size?: 'sm' | 'lg';
  disableSeasonal?: boolean;
  feedback?: string;
  label?: string;
}

const TimeOfUseDropdown: React.FC<TouDropdownProp> = React.forwardRef(
  (
    { touGroups, size, disableSeasonal = false, feedback, label, ...rest }: TouDropdownProp,
    forwardRef
  ): React.ReactElement => {
    const inputRef = useRef<HTMLDivElement | null>(null);
    useImperativeHandle(forwardRef, () => inputRef.current);
    const setRef = useCallback(
      input => {
        inputRef.current = input;
      },
      [inputRef]
    );
    const renderHeader = (touGroup: types.TimeOfUseGroup) => {
      return `TOU Group ${touGroup.touGroupId}`;
    };

    const isGroupSeasoanal = (touGroup: types.TimeOfUseGroup): boolean => {
      const timeOfUses = touGroup.timeOfUses || [];
      let seasonal = false;
      timeOfUses.forEach(tou => {
        if (tou.season) {
          seasonal = true;
        }
      });
      return seasonal;
    };

    const renderItems = (tou: types.TimeOfUse) => {
      const itemText = ToString.timeOfUse(tou);
      return (
        <option key={tou.touId} value={tou.touId}>
          {itemText}
        </option>
      );
    };
    return (
      <SelectInput
        placeholder="Select time of use..."
        size={size}
        ref={setRef}
        feedback={feedback}
        label={label}
        {...rest}
      >
        {touGroups.map((touGroup: types.TimeOfUseGroup) => {
          const timeOfUses = touGroup.timeOfUses || [];
          const disable = disableSeasonal ? isGroupSeasoanal(touGroup) : false;
          const disabledClassName = disable ? styles.disabled : '';
          return (
            <optgroup
              disabled={disable}
              key={touGroup.touGroupId}
              className={disabledClassName}
              label={renderHeader(touGroup)}
            >
              {timeOfUses.map(tou => {
                return renderItems(tou);
              })}
            </optgroup>
          );
        })}
      </SelectInput>
    );
  }
);

TimeOfUseDropdown.displayName = 'TimeOfUseDropdown';

export default TimeOfUseDropdown;
