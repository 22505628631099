import { Genability, restClient } from '@genability/api';
import { Auth } from 'aws-amplify';
import { GenabilityTaskApi } from './task-api/api';
import { GenabilityUploadApi } from './upload-api/api';
import { GenabilityApiV2 } from './task-api/v2/api';
import { GenabilityBetaApi } from './gen-beta-api/api';
import { GenabilityOpsManagementApi } from './ops-api/api';

export const GenApiClient = async function (): Promise<Genability> {
  const currentSession = await Auth.currentSession();
  const jwtToken = currentSession.getIdToken().getJwtToken();
  return Genability.configure({
    proxy: process.env.REACT_APP_AMPLIFY_PROXY_URL + 'signal/',
    credentials: {
      jwt: jwtToken,
    },
  });
};

export const GenBetaApiClient = async function (): Promise<GenabilityBetaApi> {
  const currentSession = await Auth.currentSession();
  const jwtToken = currentSession.getIdToken().getJwtToken();
  return GenabilityBetaApi.configure(process.env.REACT_APP_AMPLIFY_PROXY_URL + 'signal/rest/beta', {
    jwt: jwtToken,
  });
};

export const TaskApiClient = async function (): Promise<GenabilityTaskApi> {
  return GenabilityTaskApi.configure(
    process.env.REACT_APP_AMPLIFY_PROXY_URL + 'tasks/',
    async (): Promise<restClient.RestApiCredentialsObject> => {
      const currentSession = await Auth.currentSession();
      const jwtToken = currentSession.getIdToken().getJwtToken();
      return {
        jwt: jwtToken,
      };
    }
  );
};

export const TaskApiV2Client = async function (): Promise<GenabilityApiV2> {
  return GenabilityApiV2.configure(
    process.env.REACT_APP_AMPLIFY_PROXY_URL + 'tasks/v2/',
    async (): Promise<restClient.RestApiCredentialsObject> => {
      const currentSession = await Auth.currentSession();
      const jwtToken = currentSession.getIdToken().getJwtToken();
      return {
        jwt: jwtToken,
      };
    }
  );
};

export const TariffApiV2Client = async function (): Promise<GenabilityApiV2> {
  return GenabilityApiV2.configure(
    process.env.REACT_APP_AMPLIFY_PROXY_URL + 'tasks/v2/',
    async (): Promise<restClient.RestApiCredentialsObject> => {
      const currentSession = await Auth.currentSession();
      const jwtToken = currentSession.getIdToken().getJwtToken();
      return {
        jwt: jwtToken,
      };
    }
  );
};

export const OpsManagementApiClient = async function (): Promise<GenabilityOpsManagementApi> {
  return GenabilityOpsManagementApi.configure(
    process.env.REACT_APP_AMPLIFY_PROXY_URL + 'tasks/v2/',
    async (): Promise<restClient.RestApiCredentialsObject> => {
      const currentSession = await Auth.currentSession();
      const jwtToken = currentSession.getIdToken().getJwtToken();
      return {
        jwt: jwtToken,
      };
    }
  );
};

export const UploadApiClient = async function (): Promise<GenabilityUploadApi> {
  return GenabilityUploadApi.configure(
    process.env.REACT_APP_AMPLIFY_PROXY_URL + 'upload/',
    async (): Promise<restClient.RestApiCredentialsObject> => {
      const currentSession = await Auth.currentSession();
      const jwtToken = currentSession.getIdToken().getJwtToken();
      return {
        jwt: jwtToken,
      };
    }
  );
};
