import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import appConstants from '../../app-constants';
import styles from './Review.module.scss';
import { Helmet } from 'react-helmet';
import { Table, Th, Tr, useSortableData, IconButton } from '@arcadiapower/gen-react-lib';
import { Task } from '../../task-api/types/task';
import { GetTasksRequest } from '../../task-api/api/task-api';
import { onAt } from '../../utils/dateUtils';
import TaskAssignmentBadge from '../../components/TaskAssignmentBadge/TaskAssignmentBadge';
import { useDispatch } from 'react-redux';
import { fetchReviewTasks, selectTasks } from '../../state/tasks/tasksSlice';
import { selectCurrentUser } from '../../state/currentUser/currentUserSlice';
import { useSelector } from 'react-redux';

const Review = (): React.ReactElement => {
  const history = useHistory();
  const { results, apiStatus } = useSelector(selectTasks);
  const tasks: Task[] = results;
  const { sortedItems, sortConfig, handleSort } = useSortableData(tasks);
  const dispatch = useDispatch();
  const username: string = useSelector(selectCurrentUser).username;
  const [toggleRefresh, setToggleRefresh] = useState(true);

  useEffect(() => {
    const request = new GetTasksRequest();
    request.populateTaskAssignments = true;
    request.worker = username;
    dispatch(fetchReviewTasks(request));
  }, [dispatch, username, toggleRefresh]);

  const columns = [
    {
      key: 'taskId',
      label: 'Task ID',
    },
    {
      key: 'taskTypeName',
      label: 'Task Type',
      accessor: (task: Task) => task.taskType.taskTypeName,
    },
    {
      key: 'taskTitle',
      label: 'Details',
    },
    {
      key: 'lseId',
      label: 'LSE',
    },
    {
      key: 'postedDate',
      label: 'Posted On',
    },
    {
      label: 'Task Assignments',
    },
  ];

  return (
    <div className={`${styles.wrapper}`}>
      <Helmet>
        <title>Review</title>
      </Helmet>
      <div>
        {
          <>
            <div className="row">
              <div className={'col d-flex'}>
                <h1>Tasks in Review </h1>
              </div>
              <div className={'col d-flex justify-content-end'}>
                <div>
                  <IconButton
                    icon="refresh"
                    spin={apiStatus === 'idle' || apiStatus === 'pending'}
                    onClick={() => setToggleRefresh(!toggleRefresh)}
                  />
                </div>
              </div>
            </div>
            <Table hover>
              <thead>
                <tr>
                  {columns.map((column, idx) => {
                    <Th
                      key={idx}
                      sortConfig={sortConfig}
                      sortKey={column.key}
                      onClick={
                        column.key ? () => handleSort(column.key, column.accessor) : undefined
                      }
                    >
                      {column.label}
                    </Th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {sortedItems &&
                  sortedItems.map((task: Task) => (
                    <Tr
                      key={task.taskId}
                      onClick={() => history.push(`${appConstants.routes.review}/${task.taskId}`)}
                    >
                      <td>{task.taskId}</td>
                      <td>{task.taskType.taskTypeName}</td>
                      <td>{task.taskTitle}</td>
                      <td>{task.lseId}</td>
                      <td>{onAt(task.postedDate)}</td>
                      <td>
                        <TaskAssignmentBadge assignments={task.assignments} />
                      </td>
                    </Tr>
                  ))}
                {(!tasks || tasks.length === 0) && (
                  <tr>
                    <td className="no-results" colSpan={12}>
                      No results
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </>
        }
      </div>
    </div>
  );
};

export default Review;
