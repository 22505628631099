import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TaskApiV2Client } from '../../GenApiClient';
import { GetTasksRequestV2 } from '../../task-api/v2/api/task-api-v2';
import { handleUnexpectedThunkException } from '../reduxUtils';
import { RootState } from '../rootReducer';
import { Task } from '../../task-api/v2/types/TaskV2';
import { TasksApiState } from '../../utils/taskV2Utils';
import { restClient } from '@genability/api';

const initialState: TasksApiState = {
  apiStatus: 'idle',
  count: 0,
  results: [],
  errors: undefined,
  assignmentApiState: 'resolved',
  assignmentApiResult: null,
  assignmentApiError: null,
};

export const fetchPropertyKeyTasks = createAsyncThunk(
  'v2/propertyKeyTasks/fetchPropertyKeyTasks',
  async (request: GetTasksRequestV2, { dispatch, rejectWithValue }) => {
    try {
      const client = await TaskApiV2Client();
      const response: restClient.PagedResponse<Task> = await client.taskV2.getPropertyKeyTasks(
        request
      );
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
      return response;
    } catch (err) {
      return rejectWithValue(handleUnexpectedThunkException(err, 'Task', dispatch));
    }
  }
);

export const propertyKeyTasksSlice = createSlice({
  name: 'propertyKeyTasks',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPropertyKeyTasks.pending, state => {
      state.apiStatus = 'pending';
    });
    builder.addCase(
      fetchPropertyKeyTasks.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<Task>>) => {
        const { count, results, errors } = action.payload;
        if (errors) {
          state.apiStatus = 'rejected';
          state.errors = errors;
        } else {
          state.apiStatus = 'resolved';
          state.count = count;
          state.results = results;
          state.errors = undefined;
        }
      }
    );
  },
});

export const selectPropertyKeyTasks = (state: RootState): TasksApiState => {
  return state.propertykeyTasks;
};

export default propertyKeyTasksSlice.reducer;
