import { restClient, types } from '@genability/api';
import { flatten } from 'flat';
import { FormErrorObject } from '../components/ApiFormError/ApiFormError';

const { isResponseError } = restClient;

/**
 * Check if result is a System Error.
 * Note: Genability JS API transforms any generic error into a "SystemError".
 * https://github.com/Genability/genability-js/blob/develop/src/rest-client/contract.ts#L202
 */
export function isSystemError(error: restClient.ResponseError): boolean {
  return isResponseError(error) && error.code === 'SystemError';
}

/**
 * Find the first system error from response error array
 */
export function findSystemError(
  errors: restClient.ResponseError[] | undefined
): restClient.ResponseError | undefined {
  return errors?.find(error => isSystemError(error));
}

/**
 * Verifies if an error.keyName exists in propertyGroups
 */
export function isErrorMapToTariffProperty(
  propertyGroups: { [key: string]: types.TariffProperty[] },
  error: restClient.ResponseError
): boolean {
  return Object.values(propertyGroups)
    .flat()
    .some(property => error.propertyName === property.keyName);
}

/**
 * Verifies if an error.keyName exists in TariffRate
 */
export function isErrorMapToTariffRate(
  formData: types.TariffRate,
  error: restClient.ResponseError
): boolean {
  return Object.keys(formData).some(formField => error.propertyName === formField);
}

/**
 * Determine if an error.propertyName exists (equal or endsWith) in formFields or propertyGroups.
 * Ie. propertyName = 'rateName' have to match flatten field 'tariffRate[1].rateName'
 */
export function isErrorMapToField(
  formData: { [key: string]: any },
  error: restClient.ResponseError
): boolean {
  const { propertyGroups, ...formFields } = formData || {};
  const flatFormData = flatten(formFields) as Record<string, unknown>;
  return (
    Object.keys(flatFormData).some(
      flatField =>
        flatField === error.propertyName ||
        flatField?.endsWith(`.${error.propertyName}`) ||
        flatField?.startsWith(`${error.propertyName}.`)
    ) ||
    (propertyGroups !== undefined && isErrorMapToTariffProperty(propertyGroups, error))
  );
}

/**
 * Check if Error Rate Panel Level
 */
export const isErrorToRateLevel = ({ propertyName }: restClient.ResponseError): boolean =>
  propertyName?.startsWith('rates.') || false;

export const transformToFormError = (
  dqCheckErrors: restClient.ResponseError[],
  errorPrefix: string
): FormErrorObject => {
  return dqCheckErrors.reduce((errorsObject, error) => {
    // Map ResponseError to FormError
    errorsObject[`noMatchedProperty.${errorPrefix}.${error.propertyName}`] = {
      type: error.code,
      message: error.message,
    };
    return errorsObject;
  }, {} as FormErrorObject);
};
