import React, { ReactElement } from 'react';
import { types } from '@genability/api';
import { useSelector } from 'react-redux';
import toWords from 'split-camelcase-to-words';
import { selectTaskAssignmentTariff } from '../../state/taskAssignmentTariffs/taskAssignmentTariffsSlice';
import {
  ResourceDiff,
  Operation,
  ChangeOperation,
  Op,
  isNestedOperation,
  pathLastPosition,
} from '../../task-api/types/resource-diff';
import {
  getDisplayName,
  getNestedOpDisplay,
  getPreviousPositionDisplayName,
  getPropertyFromTariff,
  rateLinkDisplay,
  linkDisplay,
  tariffPropertyLinkDisplay,
} from '../../utils/diffUtils';
import styles from './ResourceDiffPatches.module.scss';
import { selectTaskAssignment } from '../../state/taskAssignment/taskAssignmentSlice';
import { NavHashLink as Link } from 'react-router-hash-link';

interface ResourceDiffPatchesProps {
  title: string;
  resourceDiff: ResourceDiff | undefined | null;
}

enum PropertyType {
  TARIFF_PROPERTY = 'TariffProperty',
  TARIFF_RATE = 'TariffRate',
  TIME_OF_USE = 'TimeOfUse',
  SEASON = 'Season',
  TARIFF_CHOICE = 'TariffChoice',
  TERRITORY = 'Territory',
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  STRING = 'string',
}

const ResourceDiffPatches: React.FC<ResourceDiffPatchesProps> = ({
  title,
  resourceDiff,
}: ResourceDiffPatchesProps): React.ReactElement => {
  const taskAssignmentTariff = useSelector(selectTaskAssignmentTariff);
  const taskAssignment = useSelector(selectTaskAssignment);
  const tariffPropertyValue = (tariffProperty: types.TariffProperty) => {
    return `propertyTypes:${tariffProperty.propertyTypes}; keyName:${tariffProperty.keyName};
      ${tariffProperty.quantityKey ? ' quantityKey:' + tariffProperty.quantityKey + ';' : ''}
      ${tariffProperty.period ? ' period:' + tariffProperty.period + ';' : ''}`;
  };

  const seasonValue = (season: types.Season) => {
    return `seasonId:${season.seasonId}; seasonGroupId:${season.seasonGroupId}`;
  };

  const touValue = (tou: types.TimeOfUse) => {
    return `touId:${tou.touId}; touGroupId:${tou.touGroupId};
      ${tou.touType ? ' touType:' + tou.touType + ';' : ''}`;
  };

  const pathTypeOf = (path: string) => {
    if (path.startsWith('/rates')) {
      return PropertyType.TARIFF_RATE;
    } else if (path.startsWith('/properties')) {
      return PropertyType.TARIFF_PROPERTY;
    } else if (path.startsWith('/timeOfUse')) {
      return PropertyType.TIME_OF_USE;
    } else if (path.startsWith('/season')) {
      return PropertyType.SEASON;
    } else if (path.startsWith('/choices')) {
      return PropertyType.TARIFF_CHOICE;
    } else if (path.startsWith('/territory')) {
      return PropertyType.TERRITORY;
    } else if (path.includes('/has') || path.includes('/is')) {
      return PropertyType.BOOLEAN;
    } else if (path.includes('Id') || path.includes('Amount')) {
      return PropertyType.NUMBER;
    }
    return PropertyType.STRING;
  };

  const opValuesToString = (operation: ChangeOperation<unknown>) => {
    let value: string;
    let fromValue: string;
    const pathType: string = pathTypeOf(operation.path);
    if (pathType == PropertyType.NUMBER) {
      value = (operation.value as number)?.toString();
      fromValue = 'fromValue' in operation ? (operation.fromValue as number).toString() : '';
    } else if (pathType == PropertyType.BOOLEAN) {
      value = String(operation.value as boolean);
      fromValue = 'fromValue' in operation ? String(operation.fromValue as boolean) : '';
    } else if (pathType == PropertyType.TARIFF_RATE) {
      value = 'Tariff Rate'; // TODO show the actual object at some point
      fromValue = 'fromValue' in operation ? 'Tariff Rate' : '';
    } else if (pathType == PropertyType.SEASON) {
      value = types.isSeason(operation.value as types.Season)
        ? seasonValue(operation.value as types.Season)
        : PropertyType.SEASON;
      fromValue = 'fromValue' in operation ? 'Season' : '';
    } else if (pathType == 'TimeOfUse') {
      value = types.isTimeOfUse(operation.value as types.TimeOfUse)
        ? touValue(operation.value as types.TimeOfUse)
        : 'Time of Use';
      fromValue = 'fromValue' in operation ? 'Time of Use' : '';
    } else if (pathType == PropertyType.TARIFF_PROPERTY) {
      // once https://github.com/Genability/genability-js/issues/220 is done, include isTariffProperty check here
      value = tariffPropertyValue(operation.value as types.TariffProperty);
      fromValue = 'fromValue' in operation ? 'Tariff Property' : '';
    } else if (pathType == PropertyType.TARIFF_CHOICE) {
      value = 'Tariff Choices'; // TODO show the actual object at some point
      fromValue = 'fromValue' in operation ? 'Tariff Choices' : '';
    } else if (pathType == PropertyType.TERRITORY) {
      value = PropertyType.TERRITORY;
      fromValue = 'fromValue' in operation ? PropertyType.TERRITORY : '';
    } else {
      if (operation.value && Array.isArray(operation.value)) {
        value = operation.value.join();
      } else if (operation.value && typeof operation.value === 'object') {
        value = 'Unexpected Object. Update code';
      } else {
        value = operation.value as string;
      }
      fromValue = 'fromValue' in operation ? (operation.fromValue as string) : '';
    }
    return {
      value,
      fromValue,
    };
  };

  const renderChangedValues = (operation: Operation) => {
    const unChanged =
      isNestedOperation(operation) && operation.nestedPatch.length ? '' : '(Unchanged) ';
    if (operation.op == Op.MOVE) {
      const display = getPreviousPositionDisplayName(operation, taskAssignmentTariff);
      return (
        <span>
          {operation.from == operation.path
            ? `Remains ${unChanged} at `
            : `Moved ${unChanged}from `}
          {`${display.displayName} at position ${display.from}`}
        </span>
      );
    } else if (operation.op == Op.COPY) {
      return <span>Copied from {operation.from}</span>;
    }
    const { value, fromValue } = opValuesToString(operation);
    if (operation.op == Op.REPLACE) {
      return (
        <span>
          <span className={`diff-removed-badge`}>-{fromValue}</span>{' '}
          <span className={`diff-added-badge`}>+{value}</span>
        </span>
      );
    } else if (operation.op == Op.ADD) {
      return <span>+{value}</span>;
    } else if (operation.op == Op.REMOVE) {
      return <span>-{value}</span>;
    }
    return <span>{operation.op}</span>;
  };

  const getRowStyle = (operation: Operation) => {
    if (operation.op == Op.REPLACE) {
      return styles.replaceRow;
    } else if (operation.op == Op.ADD) {
      return styles.addRow;
    } else if (operation.op == Op.REMOVE) {
      return styles.removeRow;
    } else if (operation.op == Op.MOVE) {
      return styles.moveRow;
    } else if (operation.op == Op.COPY) {
      return styles.copyRow;
    }
    return null;
  };

  const getFromPosition = (operation: Operation) => {
    if (operation.op == Op.REPLACE) {
      return pathLastPosition(operation.path);
    } else if (operation.op == Op.ADD) {
      return null;
    } else if (operation.op == Op.REMOVE) {
      return pathLastPosition(operation.path);
    } else if (operation.op == Op.MOVE && operation.from) {
      return pathLastPosition(operation.from);
    } else if (operation.op == Op.COPY && operation.from) {
      return pathLastPosition(operation.from);
    }
    return null;
  };

  const getToPosition = (operation: Operation) => {
    if (operation.op == Op.REPLACE) {
      return pathLastPosition(operation.path);
    } else if (operation.op == Op.ADD) {
      return pathLastPosition(operation.path);
    } else if (operation.op == Op.REMOVE) {
      return null;
    } else if (operation.op == Op.MOVE) {
      return pathLastPosition(operation.path);
    } else if (operation.op == Op.COPY) {
      return pathLastPosition(operation.path);
    }
    return null;
  };

  const renderJumpLink = (url: string, displayName: string) => (
    <Link smooth to={url} className={styles.jumpLink}>
      {displayName}
    </Link>
  );

  const renderDisplayText = (operation: Operation): string | ReactElement => {
    let displayText;
    const url =
      taskAssignment?.taskAssignmentId != undefined && `/do/${taskAssignment?.taskAssignmentId}`;
    const pathType: string = pathTypeOf(operation.path);
    if (pathType === PropertyType.TARIFF_PROPERTY) {
      const display = tariffPropertyLinkDisplay(operation, taskAssignmentTariff);
      displayText =
        url && display?.isLink
          ? renderJumpLink(`${url}/${display?.link}`, display?.displayName)
          : display?.displayName;
    } else if (pathType === PropertyType.TARIFF_RATE) {
      const display = rateLinkDisplay(operation, taskAssignmentTariff);
      displayText =
        url && display?.isLink
          ? renderJumpLink(`${url}/${display?.link}`, display?.displayName)
          : display?.displayName;
    } else if (pathType === PropertyType.STRING) {
      const display = linkDisplay(operation, taskAssignmentTariff);
      displayText =
        url && display?.isLink
          ? renderJumpLink(`${url}/${display?.link}`, display?.displayName)
          : display?.displayName;
    } else {
      const display = getDisplayName(operation, taskAssignmentTariff);
      displayText = display?.displayName;
    }
    return displayText || operation.path;
  };

  const renderDisplayTextNestedPath = (nestedOperation: Operation, operation: Operation) => {
    const pathType: string = pathTypeOf(operation.path);
    if (pathType === 'TariffProperty') {
      const tariffProperty = getPropertyFromTariff(taskAssignmentTariff, operation.path);
      const isRateCriteria =
        tariffProperty?.propertyTypes === 'RATE_CRITERIA' &&
        nestedOperation.path === '/propertyValue';
      return isRateCriteria ? 'Criteria Value' : toWords(nestedOperation.path.slice(1));
    }
    return getNestedOpDisplay(nestedOperation, taskAssignmentTariff)?.displayName;
  };

  const renderPatchRow = (operation: Operation, index: number) => {
    return (
      <tr key={index} className={getRowStyle(operation)}>
        <td className={styles.fromPositionCell}>{getFromPosition(operation)}</td>
        <td className={styles.toPositionCell}>{getToPosition(operation)}</td>
        <td className={styles.pathCell}> {renderDisplayText(operation)}</td>
        <td className={styles.changeCell}>{renderChangedValues(operation)}</td>
      </tr>
    );
  };

  const renderNestedPatchRows = (operation: Operation, index: number) => {
    if (isNestedOperation(operation) && operation.nestedPatch.length) {
      return operation.nestedPatch.map((nestedOperation: Operation, nestedIndex: number) => {
        return (
          <tr key={`${index}-${nestedIndex}`} className={getRowStyle(nestedOperation)}>
            <td className={styles.fromPositionCell}>{getFromPosition(nestedOperation)}</td>
            <td className={styles.toPositionCell}>{getToPosition(nestedOperation)}</td>
            <td className={`${styles.pathCell} ${styles.nested}`}>
              {renderDisplayTextNestedPath(nestedOperation, operation)}
            </td>
            <td className={styles.changeCell}>{renderChangedValues(nestedOperation)}</td>
          </tr>
        );
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>{title}</div>
      <div className={styles.body}>
        <table className={styles.table}>
          <tbody>
            {!resourceDiff?.patch?.length ? (
              <tr>
                <td colSpan={4}>No changes</td>
              </tr>
            ) : (
              resourceDiff?.patch.map((operation, index) => (
                <React.Fragment key={index}>
                  {renderPatchRow(operation, index)}
                  {renderNestedPatchRows(operation, index)}
                </React.Fragment>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ResourceDiffPatches;
