import React, { FC, ReactElement, useEffect, useState, useCallback } from 'react';
import { Pagination, Table, Td, Th, Tr, useSortableData } from '@arcadiapower/gen-react-lib';
import { Alert, Button, DatePicker, Icon, IconButton, Loading, Text } from '@arcadiapower/shrike';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './LookupValuesList.module.scss';
import {
  fetchActualLookupValues,
  selectLookupValues,
} from '../../state/lookupValues/lookupValuesByPropertyKey';
import { useDispatch, useSelector } from 'react-redux';
import LookupValueEditorModal, {
  ModifiedLookupValue,
} from '../LookupValueEditorModal/LookupValueEditorModal';
import { selectUnpublishedLookupTasks } from '../../state/tasksV2/UnpublishedLookupTaskSlice';
import {
  saveIntermediateAnswer,
  selectIntermediateSavedData,
} from '../../state/tasksV2/intermediateSaveTaskSlice';
import { TaskType } from '../../task-api/v2/types/TaskType';
import {
  createEditLookupTask,
  selectTaskWorkflowEntity,
} from '../../state/tasksV2/CreateLookupTaskSlice';
import { applyTimeZoneOffSet } from '../../utils/dateUtils';
import { GetLookupValuesRequest } from '../../task-api/v2/api/task-api-v2';
import { LookupValueV2Transformed } from '../../task-api/v2/types/LookupValueV2Transformed';
import { CurrentUser, selectCurrentUser } from '../../state/currentUser/currentUserSlice';
import { selectWorkflow } from '../../state/tasksV2/WorkflowStateSlice';
import { ActionType } from '../../task-api/v2/types/ActionType';
import { restClient } from '@genability/api';
import { selectTaskWorkflowEntityPostAction } from '../../state/tasksV2/TasksActionSlice';
import { updateTaskSource } from '../../utils/lookupUtil';
import { DateTime } from 'luxon';

export interface ActualLookupValuesProps {
  propertyKey: string;
  isGlobalPropertyKey: boolean;
}

type CustomLookupValue = LookupValueV2Transformed & {
  index: number;
  editable: boolean;
};

const HEADERS = [
  {
    property: 'index',
    value: '#',
  },
  {
    property: 'lookupId',
    value: 'Lookup Id',
  },
  {
    property: 'fromDateTime',
    value: 'From Date',
  },
  {
    property: 'toDateTime',
    value: 'To Date',
  },
  {
    property: 'actualValue',
    value: 'Actual Value',
  },
  {
    property: 'forecastValue',
    value: 'Forecast Value',
  },
  // { UTD-1215 Hiding the Forcase method from UI
  //   property: 'forecastMethod',
  //   value: 'Forecast Method',
  // },
  {
    property: 'lseForecastValue',
    value: 'LSE Forecast Value',
  },
  {
    property: 'createdDate',
    value: 'Created Date',
  },
  {
    property: 'createdUsername',
    value: 'Created By',
  },
  {
    property: 'lastUpdatedDate',
    value: 'Last Updated Date',
  },
  {
    property: 'lastUpdatedUsername',
    value: 'Last Updated By',
  },
  {
    property: 'document',
    value: 'Document',
  },
];

const ActualLookupValuesList: FC<ActualLookupValuesProps> = ({
  propertyKey,
  isGlobalPropertyKey,
}): ReactElement => {
  const dispatch = useDispatch();
  const {
    actualLookupValues,
    actualLookupCount,
    actualLookupLoading,
    actualLookupError,
    actualLookupStartDate,
    actualLookupEndDate,
  } = useSelector(selectLookupValues);

  const {
    taskId,
    unpublishedLookupValues,
    workflowId,
    taskType,
    assignee,
    reviewer,
    taskSource: currentTaskSource,
  } = useSelector(selectUnpublishedLookupTasks);
  const { currentWorkflowState } = useSelector(selectWorkflow);
  const currentUser: CurrentUser = useSelector(selectCurrentUser);
  const { isLoading: createTaskLoading } = useSelector(selectTaskWorkflowEntity);
  const { isLoading: intermediateSaveLoading } = useSelector(selectIntermediateSavedData);
  const { published } = useSelector(selectTaskWorkflowEntityPostAction);
  const [fromDate, setFromDate] = useState<string | undefined>(actualLookupStartDate);
  const [toDate, setToDate] = useState<string | undefined>(actualLookupEndDate);
  const [customLookupValues, setCustomLookupValues] = useState<CustomLookupValue[] | undefined>(
    undefined
  );
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [currentLookupValue, setCurrentLookupValue] = useState<LookupValueV2Transformed | null>(
    null
  );
  const [page, setPage] = useState(1);
  const [searchButtonClicked, setSearchButtonClicked] = useState<boolean>(false);
  const [pageClicked, setPageClicked] = useState<boolean>(false);
  const [toggleRefresh, setToggleRefresh] = useState<boolean>(false);
  const [taskMetaDataUpdateLoading, setTaskMetaDataUpdateLoading] = useState<boolean>(false);

  const pageCount = 30;
  const totalPageCount = Math.ceil(actualLookupCount / pageCount);

  const { sortedItems, sortConfig, handleSort } = useSortableData(actualLookupValues, {
    sortOn: ['fromDateTime'],
    sortOrder: [restClient.SortOrder.DESC],
  });

  const handleSelectPage = useCallback(selectedPage => {
    setPage(selectedPage);
    setPageClicked(prevState => !prevState);
  }, []);

  const handleSubmitButtonClick = useCallback(() => {
    setPage(1);
    setSearchButtonClicked(prevState => !prevState);
  }, []);

  const handleRefreshButtonClick = useCallback(async () => {
    setPage(1);
    setFromDate(actualLookupStartDate);
    setToDate(actualLookupEndDate);
    setToggleRefresh(toggleRefresh => !toggleRefresh);
  }, []);

  useEffect(() => {
    if (published) {
      setPage(1);
      setToggleRefresh(toggleRefresh => !toggleRefresh);
    }
  }, [published]);

  useEffect(() => {
    setPage(1);
    setFromDate(actualLookupStartDate);
    setToDate(actualLookupEndDate);
    setToggleRefresh(toggleRefresh => !toggleRefresh);
  }, [actualLookupStartDate, actualLookupEndDate]);

  useEffect(() => {
    (async () => {
      await getLookupValues();
    })();
  }, [searchButtonClicked, pageClicked, sortConfig, toggleRefresh]);

  useEffect(() => {
    // cache editable as it is not function of lookup values at the moment.
    const editable = !isLookupUneditable();

    const lookupValues: CustomLookupValue[] | undefined = sortedItems
      ? sortedItems.map((value, index) => ({
          ...value,
          index: (page - 1) * pageCount + index + 1,
          editable,
        }))
      : undefined;
    setCustomLookupValues(lookupValues);
  }, [sortedItems]);

  const isLookupUneditable = () => {
    const isUserDisallowed = currentUser.username !== assignee && currentUser.username !== reviewer;
    const isTaskNotInSaveState = !currentWorkflowState?.allowedActions.includes(ActionType.SAVE);
    const isAddDocumentOrAddLookupActive = [TaskType.ADD_DOCUMENT, TaskType.ADD_LOOKUP].includes(
      taskType as TaskType
    );

    return (
      (taskType && (isUserDisallowed || isTaskNotInSaveState)) || isAddDocumentOrAddLookupActive
    );
  };

  const getLookupValues = async () => {
    const request = new GetLookupValuesRequest();
    request.fromDateTime = fromDate ? fromDate : actualLookupStartDate;
    request.toDateTime = toDate ? toDate : actualLookupEndDate;
    request.sortOn = sortConfig.sortOn;
    request.sortOrder = sortConfig.sortOrder;
    request.pageCount = pageCount;
    request.pageStart = (page - 1) * pageCount;
    dispatch(fetchActualLookupValues(request, propertyKey));
  };

  const mappedHeaders = () => {
    const mapped = HEADERS.map(({ property, value }) => (
      <Th
        className="text-center"
        key={property}
        sortConfig={sortConfig}
        sortKey={property}
        onClick={property ? () => handleSort(property) : undefined}
      >
        {value}
      </Th>
    ));
    return mapped;
  };

  const onEdit = (lookupVal: LookupValueV2Transformed) => {
    setCurrentLookupValue(lookupVal);
    setShowEditModal(true);
  };

  const mappedActualValuesRows = () => {
    return customLookupValues?.length
      ? customLookupValues.map(lookupValue => (
          <Tr
            className={`${styles.row} border-top-3 border-info`}
            key={`actual-${lookupValue?.lookupId}`}
          >
            <Td className="text-center">{lookupValue?.index}</Td>
            <Td className="text-center">{lookupValue?.lookupId}</Td>
            <Td className="text-center">
              {lookupValue?.fromDateTime
                ? new Date(lookupValue.fromDateTime).toLocaleDateString()
                : ''}
            </Td>
            <Td className="text-center">
              {lookupValue?.toDateTime ? new Date(lookupValue.toDateTime).toLocaleDateString() : ''}
            </Td>
            <Td className="text-center">{lookupValue?.actualValue}</Td>
            <Td className="text-center">{lookupValue?.forecastValue}</Td>
            {/*  <Td className="text-center">{lookupValue?.forecastMethod}</Td> */}
            <Td className="text-center">{lookupValue?.lseForecastValue}</Td>
            <Td className="text-center">
              {lookupValue?.createdDateTime
                ? DateTime.fromMillis(lookupValue?.createdDateTime, { zone: 'utc' }).toFormat(
                    "yyyy-MM-dd'T'HH:mm:ss"
                  )
                : ''}
            </Td>
            <Td className="text-center">{lookupValue?.createdUsername}</Td>
            <Td className="text-center">
              {lookupValue?.lastUpdatedDateTime
                ? DateTime.fromMillis(lookupValue?.lastUpdatedDateTime, { zone: 'utc' }).toFormat(
                    "yyyy-MM-dd'T'HH:mm:ss"
                  )
                : ''}
            </Td>
            <Td className="text-center">{lookupValue?.lastUpdatedUsername}</Td>
            <Td className="text-center">
              {lookupValue.document && (
                <IconButton
                  aria-label="document"
                  icon="DocumentInverted"
                  onClick={e => {
                    e.preventDefault();
                    window.open(lookupValue.document?.archiveUrl || '', '_blank');
                  }}
                />
              )}
            </Td>
            <Td className="text-center">
              <IconButton
                aria-label="edit"
                icon="Pencil"
                disabled={lookupValue.editable === false}
                onClick={() => onEdit(lookupValue)}
              />
            </Td>
          </Tr>
        ))
      : null;
  };

  const renderRows = () => {
    if (!actualLookupError && !customLookupValues?.length) {
      return (
        <Tr>
          <Td className="text-center" colSpan={HEADERS.length}>
            No data available...
          </Td>
        </Tr>
      );
    }
    return <>{mappedActualValuesRows()}</>;
  };

  const handleModalClose = () => {
    setShowEditModal(false);
  };

  const getUpdatedIntermediateSavedLookups = (
    intermediateSavedLookups: LookupValueV2Transformed[],
    modifiedLookup: any
  ) => {
    if (!modifiedLookup) {
      return JSON.stringify(intermediateSavedLookups);
    }
    const lookupIndex = intermediateSavedLookups.findIndex(
      lookup => lookup.lookupId === modifiedLookup.lookupId
    );
    if (lookupIndex == -1) {
      intermediateSavedLookups.push(modifiedLookup);
    } else {
      intermediateSavedLookups.splice(lookupIndex, 1, modifiedLookup);
    }
    return JSON.stringify(intermediateSavedLookups);
  };

  const saveLookupValues = async (modifiedLookupValue: ModifiedLookupValue, taskSource: string) => {
    const modifiedLookup = currentLookupValue
      ? {
          ...currentLookupValue,
          fromDateTime:
            applyTimeZoneOffSet(modifiedLookupValue.fromDateTime)?.toISOString().slice(0, -5) ||
            null,
          toDateTime:
            applyTimeZoneOffSet(modifiedLookupValue.toDateTime)?.toISOString().slice(0, -5) || null,
          actualValue: modifiedLookupValue.actualValue
            ? Number(modifiedLookupValue.actualValue)
            : currentLookupValue.actualValue,
          forecastValue: modifiedLookupValue.forecastValue
            ? Number(modifiedLookupValue.forecastValue)
            : undefined,
        }
      : null;
    const intermediateSavedLookups: LookupValueV2Transformed[] = unpublishedLookupValues
      ? JSON.parse(unpublishedLookupValues)
      : [];

    const updatedIntermediateSavedLookups = getUpdatedIntermediateSavedLookups(
      intermediateSavedLookups,
      modifiedLookup
    );
    if (taskId) {
      if (currentTaskSource !== taskSource) {
        setTaskMetaDataUpdateLoading(true);
        await updateTaskSource(workflowId, taskSource);
        setTaskMetaDataUpdateLoading(false);
      }
      await dispatch(saveIntermediateAnswer(taskId, workflowId, updatedIntermediateSavedLookups));
    } else {
      await dispatch(
        createEditLookupTask(updatedIntermediateSavedLookups, propertyKey, taskSource)
      );
    }
    setShowEditModal(false);
  };

  return (
    <Container fluid className="p-4">
      <Row>
        <Col className="mb-4" md={2}>
          <DatePicker
            label="From Date"
            value={fromDate ? new Date(fromDate) : undefined}
            handleChange={updatedDate => {
              if (updatedDate)
                setFromDate(applyTimeZoneOffSet(updatedDate)?.toISOString().slice(0, -5));
            }}
          />
        </Col>
        <Col className="mb-4" md={2}>
          <DatePicker
            label="To Date"
            value={toDate ? new Date(toDate) : undefined}
            handleChange={updatedDate => {
              if (updatedDate)
                setToDate(applyTimeZoneOffSet(updatedDate)?.toISOString().slice(0, -5));
            }}
          />
        </Col>
        <Col className="mb-4" md={2}>
          <Button className="mt-4" onClick={handleSubmitButtonClick}>
            <Icon className="mr-2" icon="UISearch" color="primaryInverse" /> Search
          </Button>
        </Col>

        <Col className="d-flex align-items-center justify-content-end">
          {!actualLookupLoading && customLookupValues?.length && (
            <>
              <Text
                color="primary"
                opacity="high"
                tag="p"
                textStyle="paragraph400"
                className="mr-2 mb-3"
              >
                Showing {(page - 1) * pageCount + 1} -{' '}
                {Math.min(page * pageCount, actualLookupCount)} of
                {` ${actualLookupCount}`} entries
              </Text>

              <Pagination
                activePage={page}
                totalPages={totalPageCount}
                onSelectPage={handleSelectPage}
              />
              <IconButton
                className="ml-2 mb-3"
                aria-label="refresh"
                icon="UIRefresh"
                onClick={handleRefreshButtonClick}
              />
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Table bordered striped hover>
          <thead>
            <Tr>{mappedHeaders()}</Tr>
          </thead>
          <tbody>
            {actualLookupLoading ? (
              <Tr className={`${styles.row}`}>
                <Td colSpan={HEADERS.length} className="text-center w-100">
                  <Loading />
                </Td>
              </Tr>
            ) : (
              renderRows()
            )}
            {actualLookupError ? (
              <Tr>
                <Td className="text-center" colSpan={HEADERS.length}>
                  <Alert variant="error">{actualLookupError}</Alert>
                </Td>
              </Tr>
            ) : null}
          </tbody>
        </Table>
        {showEditModal ? (
          <LookupValueEditorModal
            show={showEditModal}
            isGlobalPropertyKey={isGlobalPropertyKey}
            propertyKey={propertyKey}
            onHide={handleModalClose}
            lookupValue={currentLookupValue}
            document={currentLookupValue?.document}
            editMode
            onSave={saveLookupValues}
            isSaving={
              taskId ? taskMetaDataUpdateLoading || intermediateSaveLoading : createTaskLoading
            }
          />
        ) : null}
      </Row>
    </Container>
  );
};

export default ActualLookupValuesList;
