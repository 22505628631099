import React, { FC, useCallback, useEffect, useState } from 'react';
import { Alert, Button, Link, Loading } from '@arcadiapower/shrike';
import { Container, Row } from 'react-bootstrap';

import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTaskDetails, selectTaskDetails } from '../../state/taskDetails/TaskDetailSlice';
import { TaskDetails } from '../../task-api/v2/types/TaskDetails';
import TaskHeader from '../../components/TaskDetails/TaskHeader/TaskHeader';
import TaskAttributes from '../../components/TaskDetails/TaskHeader/TaskAttributes';
import TaskAssignments from '../../components/TaskDetails/TaskAssignment/TaskAssignments';
import TaskStatusDetails from '../../components/TaskDetails/TaskStatusDetails/TaskStatusDetails';
import {
  fetchWorkFlowConfig,
  fetchWorkFlowStates,
  selectedWorkFlowConfigDetails,
} from '../../state/workflowConfig/WorkFlowConfigSlice';
import ProgressTracker from '../../components/ProgressTracker/ProgressTracker';
import {
  cancelTask,
  proceedToReview,
  proceedToWork,
  publishTask,
  selectTaskWorkflowEntityPostAction,
} from '../../state/tasksV2/TasksActionSlice';
import { CurrentUser, selectCurrentUser } from '../../state/currentUser/currentUserSlice';
import { ActionType } from '../../task-api/v2/types/ActionType';
import { WorkflowStateType } from '../../task-api/v2/types/WorkflowStatesTypes';
import TaskHistory from '../../components/TaskDetails/TaskHistory/TaskHistory';
import TaskComments from '../../components/TaskDetails/TaskComments/TaskComments';
import { taskAssignment } from '../../state/taskAssignment_V2/taskAssignmentV2Slice';
import DynamicModal, { ModalData } from '../../components/DynamicModal/DynamicModal';
import { ClosureCodesType } from '../../task-api/v2/types/ClosureCodesType';
import { TaskType } from '../../task-api/v2/types/TaskType';
import { PropertyKeyV2 } from '../../task-api/v2/types/PropertyKeyV2';
import { getUnpublishedAnswers } from '../../utils/taskV2Utils';
import PropertyKeyEditorModal from '../../components/PropertyKeyEditorModal/PropertyKeyEditorModal';
import {
  saveIntermediateAnswer,
  selectIntermediateSavedData,
} from '../../state/tasksV2/intermediateSaveTaskSlice';

export interface TaskDetailsParams {
  taskId: string;
}

const TaskDetail: FC<RouteComponentProps<TaskDetailsParams>> = props => {
  const taskId = props.match.params?.taskId;
  const dispatch = useDispatch();

  const { results, apiStatus } = useSelector(selectTaskDetails);
  const { existingConfigDetails, wfStatesData } = useSelector(selectedWorkFlowConfigDetails);
  const currentUser: CurrentUser = useSelector(selectCurrentUser);
  const {
    taskWorkflowEntityPostAction,
    publishLoading,
    cancelLoading,
    proceedToWorkLoading,
    proceedToReviewLoading,
    publishError,
    proceedToWorkError,
    proceedToReviewError,
  } = useSelector(selectTaskWorkflowEntityPostAction);
  const {
    error: intermediateSaveError,
    taskAssignmentAnswer,
    isLoading: intermediateSaveLoading,
  } = useSelector(selectIntermediateSavedData);
  const { apiResult: taskAssignmentApiResult } = useSelector(taskAssignment);
  const [refreshTaskDetails, setRefreshTaskDetails] = useState(false);

  const taskDetails: TaskDetails[] = results || [];
  const tasks = taskDetails?.[0]?.tasks;
  const taskDetail = tasks?.[tasks.length - 1];

  const currentWorkflowInstanceTasks =
    taskDetails?.[0]?.workflowInstanceTasks?.[taskDetails[0].workflowInstanceTasks.length - 1];

  const currentWorkflowState = taskDetails?.[0]?.currentWorkflowState;
  const workflowInstanceContext = taskDetails?.[0]?.workflowInstanceContext;
  const previousTask = taskDetails?.[0]?.previousTask;
  const workFlowID = currentWorkflowInstanceTasks?.workflowInstanceId;
  const wfDetails = workflowInstanceContext?.workflowConfigId
    ? existingConfigDetails[workflowInstanceContext.workflowConfigId]
    : null;
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [commentError, setCommentError] = useState<string>('');
  const [publish, setPublish] = useState<boolean>(false);
  const [initiateReview, setInititateReview] = useState<boolean>(false);
  const [propertyKey, setPropertyKey] = useState<PropertyKeyV2>();
  const [showPropertyKeyModal, setShowPropertyKeyModal] = useState<boolean>(false);
  const wfstates = wfDetails ? Object.keys(wfDetails.states).map(stateName => stateName) : null;

  useEffect(() => {
    dispatch(fetchTaskDetails(taskId));
  }, [taskId, refreshTaskDetails, taskAssignmentApiResult]);

  useEffect(() => {
    if (workFlowID && workFlowID > 0) dispatch(fetchWorkFlowStates(workFlowID));
  }, [workFlowID]);
  useEffect(() => {
    setRefreshTaskDetails(!refreshTaskDetails);
  }, [taskWorkflowEntityPostAction]);

  useEffect(() => {
    if (!initiateReview) {
      return;
    } else {
      if (!proceedToReviewError) {
        startReview();
      }
    }
  }, [proceedToReviewError, initiateReview]);

  useEffect(() => {
    const publishTaskAsync = async () => {
      if (!publish) {
        return;
      }
      if (!intermediateSaveError) {
        await dispatch(
          publishTask(taskDetail.taskId, taskDetail.workflowInstanceId, taskDetail.taskType)
        );
        setShowPropertyKeyModal(false);
      }
    };
    publishTaskAsync();
  }, [publish, intermediateSaveError]);

  const closeTask = async (data: ModalData) => {
    if (data.dropDownValue == ClosureCodesType.Other && !data.textAreaInput) {
      setCommentError('Please add Comment');
    } else {
      setCommentError('');
      await dispatch(
        cancelTask(
          taskDetail.taskId,
          taskDetail.workflowInstanceId,
          taskDetail.taskType,
          data.dropDownValue,
          data.textAreaInput
        )
      );
      setShowCancelModal(false);
    }
  };

  const startReview = () => {
    switch (taskDetail.taskType) {
      case TaskType.ADD_PROPERTY: {
        const taskAssignmentAnswer = getUnpublishedAnswers(taskDetail);
        const propertyKey: PropertyKeyV2 = taskAssignmentAnswer
          ? JSON.parse(taskAssignmentAnswer)
          : undefined;
        setPropertyKey(propertyKey);
        setShowPropertyKeyModal(true);
        break;
      }
      case TaskType.EDIT_PROPERTY: {
        const taskAssignmentAnswer = getUnpublishedAnswers(taskDetail);
        const propertyKey: PropertyKeyV2 = taskAssignmentAnswer
          ? JSON.parse(taskAssignmentAnswer)
          : undefined;
        setPropertyKey(propertyKey);
        setShowPropertyKeyModal(true);
        break;
      }
    }
  };

  const publishPropertyKey = async (modifiedPropertyKey: PropertyKeyV2) => {
    const answerJSON = JSON.stringify(modifiedPropertyKey);
    await dispatch(
      saveIntermediateAnswer(taskDetail.taskId, taskDetail.workflowInstanceId, answerJSON)
    );
    setPublish(true);
  };

  useEffect(() => {
    if (wfDetails == null && workflowInstanceContext?.workflowConfigId)
      dispatch(fetchWorkFlowConfig(workflowInstanceContext.workflowConfigId));
  }, [dispatch, wfDetails]);

  const refreshDetails = useCallback(
    () => setRefreshTaskDetails(!refreshTaskDetails),
    [refreshTaskDetails]
  );
  return (
    <>
      <Container fluid className="mt-5">
        {apiStatus === 'idle' || apiStatus === 'pending' ? (
          <Row className="mt-5">
            <Loading backgroundColor="primary" className="mt-5" />
          </Row>
        ) : (
          <>
            {apiStatus === 'rejected' && (
              <div className="mt-5">
                <Alert className="m-5" variant="error">
                  Issue while retrieving task details. Please refresh the page or navigate back to
                  <Link
                    backgroundColor="primaryInverse"
                    color="primary"
                    fontSize={500}
                    size="small"
                    type="standalone"
                    hideIcon
                    textAlign="string"
                    to="/dashboard"
                    className="text-wrap ml-1 mt-5"
                  >
                    Dashboard
                  </Link>
                </Alert>
              </div>
            )}
            {apiStatus === 'resolved' && (
              <>
                <TaskHeader
                  taskId={taskId}
                  lseName={taskDetail?.lseName}
                  taskType={taskDetail?.taskType}
                  refreshTaskDetails={refreshDetails}
                  taskStatus={taskDetail?.taskStatus}
                  lastUpdatedDate={taskDetail?.lastUpdatedDate}
                  propertyKey={
                    workflowInstanceContext?.sourceEntityId ||
                    workflowInstanceContext?.changedEntityIds
                  }
                />
                {wfstates && currentWorkflowState && (
                  <ProgressTracker
                    workflowStates={wfStatesData}
                    workflowConfig={wfDetails}
                    currentTaskState={taskDetail?.taskStatus}
                    fullWidth={false}
                  />
                )}

                <TaskAttributes
                  taskId={taskId}
                  lseId={taskDetail?.lseId}
                  lseName={taskDetail?.lseName}
                  oldEntityId={previousTask?.entityId?.toString()}
                  oldTaskId={previousTask?.taskId}
                  newEntityID={taskDetail?.entityId?.toString()}
                  taskSource={taskDetail?.taskSource}
                  workflowId={currentWorkflowInstanceTasks?.workflowInstanceId.toString()}
                  workFlowStatus={currentWorkflowState?.label}
                  closureCode={currentWorkflowInstanceTasks?.closureComment}
                  srcEntityType={currentWorkflowInstanceTasks?.sourceEntityType}
                  targetEntityType={currentWorkflowInstanceTasks?.targetEntityType}
                  srcEntityId={currentWorkflowInstanceTasks?.sourceEntityGlobalId?.toString()}
                  targetEntityId={currentWorkflowInstanceTasks?.targetEntityGlobalId?.toString()}
                  assigneeId={taskDetail?.assigneeId}
                  assignee={taskDetail?.assignee}
                  taskChannel={taskDetail?.taskChannel}
                  createdDate={taskDetail?.createdDate}
                />
                <TaskHistory
                  previousDocTask={previousTask?.previousTaskId}
                  previousDeTask={previousTask?.taskId}
                  currentDocTask={taskDetail?.previousTaskId}
                  currentDeTask={taskDetail?.taskId}
                />
                <TaskStatusDetails
                  postedDate={taskDetail?.postedDate}
                  completedDate={taskDetail?.completedDate}
                  reviewedDate={taskDetail?.reviewedDate}
                  publishedDate={taskDetail?.publishedDate}
                />
                {currentUser.username === taskDetail.assignee &&
                  currentWorkflowState?.name === WorkflowStateType.DE_OPEN && (
                    <Button
                      id={`processTaskToModeller`}
                      backgroundColor="primaryInverse"
                      onClick={() => {
                        dispatch(
                          proceedToWork(
                            taskDetail.taskId,
                            taskDetail.workflowInstanceId,
                            taskDetail?.taskType
                          )
                        );
                      }}
                      size="medium"
                      loading={proceedToWorkLoading}
                      className="m-2"
                    >
                      Process Task
                    </Button>
                  )}
                {(currentUser.username === taskDetail.assignee ||
                  currentUser.username === taskDetail.reviewer) &&
                  currentWorkflowState?.allowedActions?.includes(ActionType.CANCEL) && (
                    <Button
                      id={`cancelTask`}
                      backgroundColor="error"
                      onClick={() => {
                        setShowCancelModal(true);
                      }}
                      size="medium"
                      className="m-2"
                    >
                      {currentUser.username === taskDetail.reviewer ? 'Reject Task' : 'Cancel Task'}
                    </Button>
                  )}
                {currentUser.username === taskDetail.reviewer &&
                  currentWorkflowState?.name !== WorkflowStateType.DE_CLOSED && (
                    <Button
                      id={`processTaskToReviewer`}
                      backgroundColor="primaryInverse"
                      onClick={async () => {
                        if (currentWorkflowState?.name === WorkflowStateType.DE_READY_FOR_REVIEW) {
                          await dispatch(
                            proceedToReview(
                              taskDetail.taskId,
                              taskDetail.workflowInstanceId,
                              taskDetail?.taskType
                            )
                          );
                          setInititateReview(true);
                        } else {
                          startReview();
                        }
                      }}
                      size="medium"
                      loading={proceedToReviewLoading}
                      className="m-2"
                    >
                      Proceed To Review
                    </Button>
                  )}

                {currentUser.username === taskDetail.reviewer &&
                  currentWorkflowState?.allowedActions?.includes(ActionType.PUBLISH) && (
                    <Button
                      id={`publishTask`}
                      backgroundColor="primaryInverse"
                      onClick={() => {
                        dispatch(
                          publishTask(
                            taskDetail.taskId,
                            taskDetail.workflowInstanceId,
                            taskDetail?.taskType
                          )
                        );
                      }}
                      size="medium"
                      loading={publishLoading}
                      className="m-2"
                    >
                      Publish Task
                    </Button>
                  )}

                <TaskAssignments
                  taskType={taskDetail.taskType as TaskType}
                  taskId={taskId}
                  currentWorkflowStatus={currentWorkflowState?.name}
                  taskAssignments={taskDetail.taskAssignments}
                  workflowConfigId={wfDetails?.name}
                  reviewerId={taskDetail?.reviewerId}
                  reviewer={taskDetail?.reviewer}
                  workerId={taskDetail?.assigneeId}
                  worker={taskDetail?.assignee}
                  workflowId={currentWorkflowInstanceTasks?.workflowInstanceId.toString()}
                  refreshTaskDetails={refreshDetails}
                />

                <TaskComments
                  taskId={taskDetail.taskId}
                  taskComments={taskDetail?.comments || []}
                />
              </>
            )}
            {showCancelModal && (
              <DynamicModal
                title="Cancel Task"
                show={showCancelModal}
                testid="cancel-task-modal"
                onHide={() => setShowCancelModal(false)}
                onSubmit={closeTask}
                dropdown={{
                  title: 'Closure code',
                  options: Object.keys(ClosureCodesType)
                    .filter(
                      key =>
                        ClosureCodesType[key as keyof typeof ClosureCodesType] !==
                        ClosureCodesType.Completed
                    )
                    .map(key => ({
                      text: key,
                      value: ClosureCodesType[key as keyof typeof ClosureCodesType],
                    })),
                }}
                isLoading={cancelLoading}
                textArea={{
                  title: 'Comments',
                  error: commentError,
                }}
              />
            )}
            {showPropertyKeyModal && (
              <PropertyKeyEditorModal
                show={showPropertyKeyModal}
                reviewer
                taskType={taskDetail.taskType as TaskType}
                taskId={taskDetail.taskId}
                submitLoading={intermediateSaveLoading || publishLoading}
                editMode={(taskDetail.taskType as TaskType) === TaskType.EDIT_PROPERTY}
                propertyKey={propertyKey}
                onHide={() => setShowPropertyKeyModal(false)}
                onSubmit={publishPropertyKey}
              />
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default TaskDetail;
