import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  CheckInput,
  FamiliyFilter,
  Family,
  KeySpace,
  KeySpaceFilter,
  PropertyDataTypeFilter,
  PropertyKeySearchFilter,
  Table,
  Td,
  Tr,
  AlphabetSelector,
  Pagination,
  SelectInput,
  NotificationLevel,
} from '@arcadiapower/gen-react-lib';
import {
  PropertyKeyPickerContextType,
  usePropertyKeyPickerContext,
} from '../../context/propertyKeyPickerContext';
import { useAppDispatch } from '../../state/store';
import LseInput from '../LseInput/LseInput';
import { restClient, types } from '@genability/api';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Button, Icon, IconButton, Loading, Text, Tooltip } from '@arcadiapower/shrike';
import { getPropertyKeys, selectpropertyKeys } from '../../state/propertyKeys/propertyKeysV2Slice';
import { PropertyKeyV2 } from '../../task-api/v2/types/PropertyKeyV2';
import PropertyKeyEditorModal from '../PropertyKeyEditorModal/PropertyKeyEditorModal';
import { TaskApiV2Client } from '../../GenApiClient';
import { TaskWorkflowEntity } from '../../task-api/v2/types/TaskWorkflowEntity';
import { WorkflowConfigType } from '../../task-api/v2/types/WorkflowConfigType';
import { TaskType } from '../../task-api/v2/types/TaskType';
import { EntityType } from '../../task-api/v2/types/EntityType';
import { TaskSource } from '../../task-api/v2/types/TaskSource';
import { addNotification } from '../../state/notification/notificationSlice';

const Divider: FC = () => <hr className="my-3 w-100" />;

const PropertyKeyDirectory: FC = (): ReactElement => {
  const [includeGlobalPropertiesState, setIncludeGlobalProperties] = useState(false);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [startsWith, setStartsWith] = useState<boolean>(false);
  const [showPropertyKeyModal, setShowPropertyKeyModal] = useState<boolean>(false);
  const [propertyKeySpace, setPropertyKeySpace] = useState<KeySpace | null>(null);
  const [pageClicked, setPageClicked] = useState<boolean>(false);
  const [propertyFamily, setPropertyFamily] = useState<Family | null>(null);
  const [propertyDataType, setPropertyDataType] = useState<types.PropertyDataType | null>(null);
  const [lse, setLse] = useState<types.LoadServingEntity | undefined>(undefined);
  const [selectedAlphabet, setSelectedAlphabet] = useState<string>('');
  const [selectedScope, setSelectedScope] = useState<string>('ALL');
  const { propertyKeys, count, isLoading } = useSelector(selectpropertyKeys);
  const { picker: defaults } = usePropertyKeyPickerContext() as PropertyKeyPickerContextType;
  const pageCount = 25;
  const [editMode, setEditMode] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [taskId, setTaskId] = useState<number | undefined>(undefined);
  const [taskType, setTaskType] = useState<TaskType>();
  const [currentPropertyKey, setCurrentPropertyKey] = useState<PropertyKeyV2>();
  const totalPageCount = Math.ceil(count / pageCount);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    doSearchWithParams();
  }, [pageClicked]);

  useEffect(() => {
    setPageNumber(0);
    (async () => {
      await doSearchWithParams();
    })();
  }, [
    lse,
    propertyDataType,
    propertyFamily,
    propertyKeySpace,
    includeGlobalPropertiesState,
    searchTerm,
    selectedAlphabet,
    selectedScope,
  ]);

  const handleSelectPage = useCallback(async selectedPage => {
    setPageNumber(selectedPage - 1);
    setPageClicked(pageClicked => !pageClicked);
  }, []);

  const doSearchWithParams = async () => {
    dispatch(
      getPropertyKeys({
        searchTerm: startsWith ? selectedAlphabet : searchTerm,
        startsWith,
        propertyDataType,
        propertyKeySpace,
        propertyFamily,
        includeGlobalProperties: includeGlobalPropertiesState,
        filterByLseId: !!lse?.lseId,
        lseId: lse?.lseId,
        scope: selectedScope,
        pageNumber,
      })
    );
  };

  const handleSearch = async (term: string) => {
    setPageNumber(0);
    setStartsWith(false);
    setSearchTerm(term);
    setSelectedAlphabet('');
  };

  const handleIncludeGlobalPropertiesChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeGlobalProperties(event.target.checked);
  };

  const onSelectAlphabet = (alphabet: string) => {
    setStartsWith(true);
    setSearchTerm('');
    setSelectedAlphabet(alphabet);
  };

  const handlePropertyDataTypeSelected = async (propertyDataName: types.PropertyDataType) => {
    let propDataType = null;

    if (propertyDataName) {
      propDataType = types.PropertyDataType[propertyDataName];
    }
    setPropertyDataType(propDataType);
    setPageNumber(0);
  };

  const handlePropertyKeySpaceSelected = async (propertyKeySpace: KeySpace) => {
    setPropertyFamily(null);
    setPropertyKeySpace(propertyKeySpace);
    setPageNumber(0);
  };

  const handlePropertyFamilySelected = async (selectedFamily: Family) => {
    setPropertyFamily(selectedFamily);
    setPageNumber(0);
  };

  const handleScopeSelected = (value: string) => {
    setSelectedScope(value);
  };

  const mappedHeaders = () => (
    <Tr>
      <Td className="font-weight-bold">Key Name</Td>
      <Td className="font-weight-bold">Data Type</Td>
      <Td className="font-weight-bold">Scope</Td>
      <Td className="font-weight-bold">LSE Name</Td>
      <Td className="font-weight-bold">Frequency</Td>
    </Tr>
  );

  const fetchPropertyKey = async (keyName: string) => {
    const client = await TaskApiV2Client();
    const { result, errors }: restClient.SingleResponse<PropertyKeyV2> =
      await client.taskV2.getPropertyKey(keyName);
    if (result) {
      setCurrentPropertyKey(result);
    }
  };

  const onSubmit = async (modifiedPropertyKey: PropertyKeyV2) => {
    const taskAssignmentAnswer = JSON.stringify(modifiedPropertyKey);
    const client = await TaskApiV2Client();
    let createTaskBody;
    if (editMode) {
      createTaskBody = {
        workflowConfigId: WorkflowConfigType.EDIT_PROPERTY_KEY_AUTO,
        taskType: TaskType.EDIT_PROPERTY,
        entityType: EntityType.PROPERTY,
        sourceEntityId: modifiedPropertyKey.keyName,
        taskAssignmentAnswer: taskAssignmentAnswer,
      };
    } else {
      createTaskBody = {
        workflowConfigId: WorkflowConfigType.ADD_PROPERTY_KEY_AUTO,
        taskType: TaskType.ADD_PROPERTY,
        entityType: EntityType.PROPERTY,
        taskAssignmentAnswer: taskAssignmentAnswer,
      };
    }
    setSubmitLoading(true);
    const {
      results: createTaskResult,
      errors: createTaskErrors,
    }: restClient.SingleResponse<TaskWorkflowEntity> = await client.taskV2.createTask(
      createTaskBody,
      TaskSource.UI
    );
    if (createTaskErrors) {
      dispatch(addNotification(createTaskErrors[0].message, NotificationLevel.Error));
      setSubmitLoading(false);
      return;
    }
    const deTaskId = createTaskResult[0].tasks[0].taskId;
    setTaskId(deTaskId);
    setSubmitLoading(false);
  };

  const openPropertyKeyDetail = (keyName: string) => {
    history.push(`${location.pathname}/${keyName}`);
  };

  const renderRows = () => {
    if (propertyKeys.length == 0) {
      return (
        <Tr>
          <Td className="text-center" colSpan={5}>
            No data available...
          </Td>
        </Tr>
      );
    }
    return <>{mappedPropertyKeys()}</>;
  };

  const mappedPropertyKeys = () =>
    propertyKeys.map((propertyKey: PropertyKeyV2) => (
      <Tr
        key={propertyKey.keyName}
        onClick={() => openPropertyKeyDetail(propertyKey.keyName)}
        className="pe-auto"
      >
        <Td>{propertyKey.keyName}</Td>
        <Td>{propertyKey.dataType}</Td>
        <Td>{propertyKey.entityType || 'GLOBAL'}</Td>
        <Td>{propertyKey.entityName || 'Null'}</Td>
        <Td>{propertyKey.frequency || 'Unknown'}</Td>
        <Td className="text-center" onClick={e => e.stopPropagation()}>
          {propertyKey.taskId != null ? (
            <Tooltip
              backgroundColor="primary"
              content={'A task already exists for this property key: ' + propertyKey.taskId}
              label={'edit_property'}
              tooltipId={propertyKey.taskId.toString()}
              place="top"
              tooltippedElementWrapperWidth="fit-content"
              fontSize={400}
            >
              <IconButton aria-label="edit" icon="Pencil" disabled />
            </Tooltip>
          ) : (
            <IconButton
              aria-label="edit"
              icon="Pencil"
              onClick={async () => {
                await fetchPropertyKey(propertyKey.keyName);

                setEditMode(true);
                setTaskType(TaskType.EDIT_PROPERTY);
                setShowPropertyKeyModal(true);
              }}
            />
          )}
        </Td>
      </Tr>
    ));

  return (
    <>
      <Container fluid className=" pb-5 pr-5 pl-5 mt-5">
        <Row className="mb-5">
          <h2 className="font-weight-bold mt-5">Property Keys Directory</h2>
        </Row>
        <Row>
          <Col>
            <PropertyKeySearchFilter
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              propertyDataType={propertyDataType as types.PropertyDataType}
              includeGlobalPropertiesState={includeGlobalPropertiesState}
              pageNumber={pageNumber}
              onChange={handleSearch}
            />
          </Col>
          <Col>
            <PropertyDataTypeFilter
              handleSelected={handlePropertyDataTypeSelected}
              includePropertyDataTypes={defaults.propertyDataTypes as types.PropertyDataType[]}
            />
          </Col>
          <Col>
            <KeySpaceFilter
              handleSelected={handlePropertyKeySpaceSelected}
              includePropertyKeySpaces={defaults.keySpaces as KeySpace[]}
            />
          </Col>
          <Col>
            <FamiliyFilter
              handleSelected={handlePropertyFamilySelected}
              includePropertyFamily={defaults.family as Family[]}
              keySpace={propertyKeySpace || 'ALL'}
            />
          </Col>
          <Col>
            <SelectInput
              label="Scope"
              onChange={e => handleScopeSelected(e.target.value)}
              data-testid="scope-filter"
            >
              <option selected value="ALL">
                ALL
              </option>
              <option value="GLOBAL">GLOBAL</option>
              <option value="LSE">LSE</option>
            </SelectInput>
          </Col>
          <Col>
            <LseInput id="lse-input" label="LSE" selected={lse} setSelected={setLse} />
          </Col>
        </Row>
        <Row>
          <Col>
            <AlphabetSelector selected={selectedAlphabet} onSelectAlphabet={onSelectAlphabet} />
          </Col>
        </Row>
        <Divider />
        <Row className="p-3">
          <Col className="d-flex justify-content-end">
            <Button
              backgroundColor="primaryInverse"
              className="float-right"
              onClick={() => {
                setEditMode(false);
                setTaskType(TaskType.ADD_PROPERTY);
                setShowPropertyKeyModal(true);
                setCurrentPropertyKey(undefined);
              }}
            >
              <Icon icon="Plus" className="mr-2" color="primaryInverse" /> Add Property key
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            {propertyKeys && propertyKeys.length != 0 && (
              <>
                <Text
                  color="primary"
                  opacity="high"
                  tag="p"
                  textStyle="paragraph400"
                  className="mt-2 mr-2"
                >
                  Showing {pageNumber * pageCount + 1} -{' '}
                  {Math.min((pageNumber + 1) * pageCount, count)} of
                  {` ${count}`} entries
                </Text>

                <Pagination
                  activePage={pageNumber + 1}
                  totalPages={totalPageCount}
                  onSelectPage={handleSelectPage}
                />
              </>
            )}
          </Col>
        </Row>
        <Row className="p-3">
          <Table bordered striped hover>
            <thead>{mappedHeaders()}</thead>
            <tbody>
              {isLoading ? (
                <Tr>
                  <Td colSpan={5} className="text-center w-100">
                    <Loading />
                  </Td>
                </Tr>
              ) : (
                renderRows()
              )}
              {}
            </tbody>
          </Table>
        </Row>
        {showPropertyKeyModal && (
          <PropertyKeyEditorModal
            show={showPropertyKeyModal}
            taskId={taskId}
            taskType={taskType}
            submitLoading={submitLoading}
            editMode={editMode}
            propertyKey={editMode ? currentPropertyKey : undefined}
            onHide={() => {
              setShowPropertyKeyModal(false);
              setTaskId(undefined);
              doSearchWithParams();
            }}
            onSubmit={onSubmit}
          />
        )}
      </Container>
    </>
  );
};

export default PropertyKeyDirectory;
