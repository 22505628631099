import { types } from '@genability/api';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/rootReducer';
import { selectTariffTerritories } from '../../../state/territories/territoriesSlice';
import { Row, Col } from 'react-bootstrap';
import { SelectInput } from '@arcadiapower/gen-react-lib';
import { withErrors } from '../../../utils/formUtils';

interface TerritoriesProps {
  lseId: number;
}

const Territories: React.FC<TerritoriesProps> = ({ lseId }) => {
  const { watch, setValue, register: parentRegister, formState } = useFormContext();
  const { errors } = formState;
  const register = withErrors(parentRegister, { errors });

  const tariffTerritories: Array<types.Territory> = useSelector((state: RootState) => {
    const territories = selectTariffTerritories(state, lseId);
    return territories;
  });

  const territoryName = watch('territory.territoryName');

  useEffect(() => {
    if (territoryName) {
      // Fetch full territory using the selected name
      const territory = tariffTerritories.find(terr => terr.territoryName == territoryName);

      if (!!territory) {
        setValue('territory', territory);
      }
    }
  }, [territoryName]);

  return (
    <>
      {tariffTerritories && tariffTerritories[0] && (
        <Row>
          <Col>
            <SelectInput label="Territory" {...register('territory.territoryName')}>
              <option value={undefined}>No Territory</option>
              {tariffTerritories.map(function (option) {
                return (
                  <option key={option.territoryName} value={option.territoryName}>
                    {option.territoryName}
                  </option>
                );
              })}
            </SelectInput>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Territories;
