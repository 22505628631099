import React, { FC, forwardRef } from 'react';
import { kebabCase } from 'lodash';
import { Alert, Dropdown } from 'react-bootstrap';
import { IconButton } from '@arcadiapower/gen-react-lib';
import styles from './DQContextMenu.module.scss';
import DQOverrideReason from '../DQOverrideReason';
import DQOverrideDialog from '../DQOverrideDialog';
import useModal from '../useModal';
import { useDispatch } from 'react-redux';
import { storeDQOverride } from '../../../state/dqCheck/dqCheckSlice';

export interface DQContextMenuProps {
  propertyName: string;
  errorMessage?: string;
  errorType?: string;
  errorPrefix?: string;
  onSelect?: (menu: string | null) => void;
}

export const ERROR_DETAILS = 'Error Details';
export const OVERRIDE_ERROR = 'Override Error';

const MenuButton = forwardRef<never, { ariaControls: string; onClick: () => void }>(
  (props, ref) => (
    <IconButton
      icon="menu"
      size="sm"
      ref={ref}
      onClick={props.onClick}
      aria-haspopup
      aria-controls={props.ariaControls}
    ></IconButton>
  )
);
MenuButton.displayName = 'MenuButton';

const DQContextMenu: FC<DQContextMenuProps> = props => {
  const { propertyName, errorMessage, errorPrefix, onSelect, children } = props;
  const message = errorMessage ?? (typeof children === 'string' ? children : '');
  const [isVisibleOverride, toggleOverride] = useModal();
  const [isVisibleReason, toggleReason] = useModal();
  const dispatch = useDispatch();

  const onSave = (reason: string) => {
    dispatch(
      storeDQOverride({
        message,
        objectName: 'TaskAssignmentAnswer',
        propertyName:
          errorPrefix && !propertyName.startsWith(errorPrefix)
            ? `${errorPrefix}.${propertyName}`
            : propertyName,
        reason,
      })
    );
  };

  const onContinue = () => {
    toggleReason();
  };

  const handleSelectMenu = async (menu: string | null) => {
    if (onSelect) {
      onSelect(menu); // Call onSelect if defined
    }
    if (menu === OVERRIDE_ERROR) {
      toggleOverride();
    }
  };

  const renderDialogContent = (message: string) => {
    return (
      <>
        <p>You are attempting to override the following DQ check:</p>
        <div>
          <Alert variant="danger">{message}</Alert>
        </div>
        <p>You may only override DQ checks under these conditions:</p>
        <ul>
          <li>The DQ check is occurring in error, or this tariff is a special case</li>
          <li>The DQ check is blocking updates to a priority tariff</li>
        </ul>
      </>
    );
  };
  return (
    <Alert variant="danger" className={styles.dqAlert}>
      <div>{children}</div>
      <Dropdown
        onSelect={handleSelectMenu}
        onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
      >
        <Dropdown.Toggle as={MenuButton} ariaControls={`dqMenu-${kebabCase(propertyName)}`} />
        <Dropdown.Menu id={`dqMenu-${kebabCase(propertyName)}`}>
          <Dropdown.Item disabled eventKey={ERROR_DETAILS}>
            {ERROR_DETAILS}
          </Dropdown.Item>
          <Dropdown.Item eventKey={OVERRIDE_ERROR}>{OVERRIDE_ERROR}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <DQOverrideDialog
        onContinue={onContinue}
        isVisible={isVisibleOverride}
        hideModal={toggleOverride}
      >
        {renderDialogContent(message)}
      </DQOverrideDialog>
      <DQOverrideReason
        message={message}
        onSave={onSave}
        isVisible={isVisibleReason}
        hideModal={toggleReason}
      />
    </Alert>
  );
};

export default DQContextMenu;
