import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResponseError } from '@genability/api/dist/rest-client';
import {
  TariffDataRequestParams,
  TariffConfigData,
} from '../../../components/OpsManagement/Config/TariffConfigTable';
import { restClient } from '@genability/api';
import { NotificationLevel } from '@arcadiapower/gen-react-lib';
import { OpsManagementApiClient } from '../../../GenApiClient';
import { addNotification } from '../../notification/notificationSlice';
import { handleUnexpectedThunkException } from '../../reduxUtils';
import { RootState } from '../../rootReducer';

export interface UpdatedTariffConfigDataResults {
  tariffWorkFlowConfig: TariffConfigData;
  status: string;
  errors: ResponseError[] | null;
}
export interface UpdateTariffConfigRequest {
  selectAllMatchingRecords: boolean;
  showInActiveTariff?: boolean;
  showUnassignedTariff?: boolean;
  getTariffUserConfigRequest?: TariffConfigDataProps;
  userAssignmentConfig: UserAssignmentConfig[];
}
export interface UserAssignmentConfig {
  userMappingId: number | undefined;
  monitoringInventoryId: number;
  primaryOwnerId: number | undefined;
  modellerId: number | undefined;
  reviewerId: number | undefined;
}
export interface TariffConfigDataProps {
  paginationCriteria?: { pageCount: number; pageStart: number };
  sortCriteria: { sortOn: string[]; sortOrder: string[] };
  tariffUserConfigFilterCriteria: {
    lseNames?: string[];
    tariffName?: string[];
    tariffCode?: string[];
    tariffPriority?: string[];
    tariffTier?: string[];
    customerTier?: string[];
    mtid?: number[];
    primaryOwnerId?: number[];
    modellerId?: number[];
    reviewerId?: number[];
    lseId?: number[];
    apiCount?: string;
  };
}
export interface TariffDataRequestProps {
  request: TariffConfigDataProps;
  params: TariffDataRequestParams;
}
export interface TariffConfigDataResponse {
  results: TariffConfigData[];
  updatedEntityResults: UpdatedTariffConfigDataResults[];
  fetchApiStatus: 'idle' | 'pending' | 'resolved' | 'rejected';
  updateApiStatus: 'idle' | 'pending' | 'resolved' | 'rejected';
  errors: restClient.ResponseError[] | undefined;
  count: number;
}
export const initialState: TariffConfigDataResponse = {
  results: [],
  fetchApiStatus: 'idle',
  updateApiStatus: 'idle',
  count: 0,
  updatedEntityResults: [],
  errors: undefined,
};

export const updateTariffConfigData = createAsyncThunk(
  'workflowManagement/config/updateTariffConfig',
  async (request: UpdateTariffConfigRequest, { dispatch, rejectWithValue }) => {
    try {
      const client = await OpsManagementApiClient();
      let response: restClient.PagedResponse<UpdatedTariffConfigDataResults>;
      if (request.selectAllMatchingRecords) {
        response = await client.opsData.updateTariffConfigDataForAllMatches(request);
      } else {
        response = await client.opsData.updateTariffConfigData(request.userAssignmentConfig);
      }
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
      let successCount = 0;
      response.results.forEach(row => {
        if (!row.errors) {
          successCount += 1;
        }
      });
      dispatch(
        addNotification(
          successCount +
            ' of ' +
            response.results.length +
            ' Assignments Updated Successfully !!!!',
          NotificationLevel.Success
        )
      );
      return response;
    } catch (err) {
      dispatch(addNotification('Assignment Failed', NotificationLevel.Error));
      return rejectWithValue(handleUnexpectedThunkException(err, 'UpdateTariffConfig', dispatch));
    }
  }
);

export const fetchTariffConfigData = createAsyncThunk(
  'workflowManagement/config/tariffs',
  async ({ request, params }: TariffDataRequestProps, { dispatch, rejectWithValue }) => {
    try {
      const client = await OpsManagementApiClient();
      const response: restClient.PagedResponse<any> = await client.opsData.fetchTariffConfigData(
        request,
        params
      );
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }
      return response;
    } catch (err) {
      dispatch(addNotification('Fetching TariffConfigData Failed', NotificationLevel.Error));
      return rejectWithValue(handleUnexpectedThunkException(err, 'TariffConfig', dispatch));
    }
  }
);

export const ConfigAssignmentSlice = createSlice({
  name: 'ConfigAssignment',
  initialState,
  reducers: {
    startTariffLoading: state => {
      state.updateApiStatus = 'pending';
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchTariffConfigData.pending, state => {
      state.fetchApiStatus = 'pending';
    });
    builder.addCase(
      fetchTariffConfigData.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<TariffConfigData>>) => {
        const { count, results, errors } = action.payload;
        if (errors) {
          state.fetchApiStatus = 'rejected';
          state.errors = errors;
        } else {
          state.fetchApiStatus = 'resolved';
          state.count = count;
          state.results = results;
          state.errors = undefined;
        }
      }
    );
    builder.addCase(
      updateTariffConfigData.fulfilled,
      (state, action: PayloadAction<restClient.PagedResponse<UpdatedTariffConfigDataResults>>) => {
        const { count, results, errors } = action.payload;
        if (errors) {
          state.updateApiStatus = 'rejected';
          state.errors = errors;
        } else {
          state.fetchApiStatus = 'pending';
          state.updateApiStatus = 'resolved';
          state.count = count;
          state.updatedEntityResults = results;
          state.errors = undefined;
        }
      }
    );
    builder.addCase(fetchTariffConfigData.rejected || updateTariffConfigData.rejected, state => {
      state.fetchApiStatus = 'rejected';
      state.updateApiStatus = 'rejected';
    });
  },
});

export default ConfigAssignmentSlice.reducer;

export const { startTariffLoading } = ConfigAssignmentSlice.actions;

export const selectTariffConfigData = (state: RootState): TariffConfigDataResponse => {
  return state.tariffConfigData;
};
